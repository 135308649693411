<template>
    <form id="payment-form" @submit.prevent="paymentType">
        <div id="payment-element" />
        <p v-if="stripeError" id="error-message" class="error input-field">
            {{ stripeError }}
        </p>

        <div v-if="isLoaded && isCard" class="input-field">
            <div class="name">
                {{ $t('homeGame.cardName') }}
            </div>
            <input
                id="name"
                v-model.trim="$v.name.$model"
                type="text"
                name="name"
                @focus="scrollToBottom"
            />
            <small v-if="$v.name.$error && !$v.name.name" class="error">
                Invalid name
            </small>
        </div>
        <div v-if="isLoaded" class="input-field">
            <div class="name">
                {{ $t('homeGame.email') }}
            </div>
            <input
                id="email"
                v-model.trim="$v.email.$model"
                type="text"
                name="email"
                required="/"
            />

            <small v-if="$v.email.$error && !$v.email.required" class="error">
                {{ $t('homeGame.email') }}
            </small>
            <small v-if="$v.email.$error && !$v.email.email" class="error">
                {{ $t('homeGame.notValidEmail') }}
            </small>
            <small
                class="note"
                :class="{
                    invisible: hideNotes,
                }"
            >
                {{ $t('homeGame.weWill') }}
            </small>
        </div>

        <button
            v-if="(this.validated && !this.$data.$v.$invalid) || this.isLoaded"
            id="submit"
            :disabled="loading"
        >
            {{ btnText }}
        </button>
    </form>
</template>
<script>
import { required, minLength, email, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';

export default {
    props: {
        trialDays: { type: Number, default: null },
        hideNotes: { type: Boolean, default: false },
        productStripeInfo: { type: Object, required: true },
        discount: { type: Object, required: false },
        selectedChild: { type: Object, required: false },
        unauthorizedAccount: { type: Boolean, default: false },
        oneTimePurchase: { type: Boolean, default: false },
    },
    data() {
        return {
            $v: useVuelidate(),
            email: '',
            name: '',
            loading: false,
            validated: false,
            isLoaded: false,
            isCard: false,
        };
    },
    computed: {
        ...mapGetters('v2/stripe', [
            'stripeElements',
            'stripePaymentElement',
            'stripeError',
        ]),
        isLifetimeSubscription() {
            return this.productStripeInfo?.metadata?.lifetimeSubscription;
        },
        btnText() {
            if (this.loading) return 'Loading ...';
            if (this.oneTimePurchase || this.isLifetimeSubscription)
                return 'Pay now';
            return 'Subscribe now';
        },
        paymentType() {
            if (this.selectedChild) {
                return this.parentForKidSubscribe;
            }

            if (this.oneTimePurchase || this.isLifetimeSubscription) {
                return this.pay;
            }

            return this.unauthorizedAccount
                ? this.unauthorizedSubscribe
                : this.subscribe;
        },
        paymentAmount() {
            return moment(this.discount?.redeem_by * 1000).isAfter(moment())
                ? Number(
                      (
                          (this.productStripeInfo?.unit_amount *
                              this.discount.percent_off) /
                          100
                      ).toFixed(0),
                  )
                : this.productStripeInfo?.unit_amount;
        },
    },
    async created() {
        this.$store.commit('v2/stripe/setError', '');

        sessionStorage.setItem(
            'paymentInfo',
            JSON.stringify(this.productStripeInfo),
        );

        await this.$store.dispatch('v2/stripe/createPaymentElements', {
            amount: !this.trialDays ? this.paymentAmount : 0,
            currency: this.productStripeInfo?.currency,
            type: this.productStripeInfo?.type,
        });

        await this.$store.dispatch('v2/stripe/checkPaymentRedirect', {
            route: this.$route,
            emitter: this.$emitter,
        });

        this.stripePaymentElement.mount('#payment-element');

        this.stripePaymentElement.on('ready', () => {
            this.isLoaded = true;
        });

        this.stripePaymentElement.on('change', (event) => {
            this.isCard = event?.value?.type === 'card';

            this.validated = event?.complete;

            this.$store.commit(
                'v2/stripe/setPaymentMethodType',
                event?.value?.type,
            );
        });
    },
    methods: {
        async pay() {
            if ((this.validated && !this.$data.$v.$invalid) || !this.isCard) {
                this.loading = true;

                await this.$store.dispatch('v2/stripe/pay', {
                    name: this.name,
                    email: this.email,
                    priceInfo: this.productStripeInfo,
                    location:
                        this.$route?.query?.location ||
                        sessionStorage.getItem('locationName'),
                });

                this.loading = false;
            }
        },
        async subscribe() {
            if ((this.validated && !this.$data.$v.$invalid) || !this.isCard) {
                this.loading = true;

                await this.$store.dispatch('v2/stripe/subscribe', {
                    name: this.name,
                    email: this.email,
                    priceInfo: this.productStripeInfo,
                    trialDays: this.trialDays,
                    discount: this.discount,
                });

                this.loading = false;
            }
        },
        async unauthorizedSubscribe() {
            if ((this.validated && !this.$data.$v.$invalid) || !this.isCard) {
                this.loading = true;

                await this.$store.dispatch('v2/stripe/publicSubscribe', {
                    userId: this.$route.params.userId,
                    name: this.name,
                    email: this.email,
                    priceInfo: this.productStripeInfo,
                    trialDays: this.trialDays,
                    discount: this.discount,
                });

                this.loading = false;
            }
        },
        async parentForKidSubscribe() {
            if ((this.validated && !this.$data.$v.$invalid) || !this.isCard) {
                this.loading = true;

                await this.$store.dispatch('v2/stripe/parentSubscribeForKid', {
                    userId: this.selectedChild._id,
                    name: this.name,
                    email: this.email,
                    priceInfo: this.productStripeInfo,
                    trialDays: this.trialDays,
                });

                this.loading = false;
            }
        },
        scrollToBottom() {
            let objDiv = document.getElementById('payment-form');

            objDiv.scrollTop = objDiv.scrollHeight;
        },
    },
    validations: {
        name: {
            required,
            minLength: minLength(3),
        },
        email: {
            required: requiredIf(function () {
                return !this.oneTimePurchase;
            }),
            email,
        },
    },
};
</script>
<style lang="scss" scoped>
#payment-element {
    margin-top: 14px;
}

#error-message {
    display: flex;
    color: red;
    font-size: 16px;
    align-items: center;
}

form {
    margin-top: 14px;
    background: #ffffff;
    align-self: center;
    box-shadow:
        0 0 0 0.5px rgba(50, 50, 93, 0.2),
        0 2px 5px 0 rgba(50, 50, 93, 0.2),
        0 1px 1.5px 0 rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 40px;
}

.input-field {
    margin-top: 14px;
    color: #6d6e78;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .name {
        color: #000;
        padding-left: 10px;
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    input {
        height: 42px;
        width: 100%;
        background: #f1f1f1;
        border-radius: 6px;
        font-size: 20px;
        outline: none;
        border: none;
        padding: 4px 12px;
        box-sizing: border-box;

        &::placeholder {
            padding: 4px 12px;
        }
    }

    .error {
        margin-top: 4px;
        margin-bottom: 6px;
        color: red;
        padding-left: 10px;
    }

    .note {
        margin-top: 4px;
        padding-left: 10px;
    }
}
button {
    margin-top: 14px;
    background: #ffc75a;
    font-family: Arial, sans-serif;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
    width: 100%;
}

button:hover {
    filter: contrast(115%);
}

button:disabled {
    opacity: 0.5;
    cursor: default;
}
</style>
