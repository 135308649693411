import Api from '@/core/services/Api';
import moment from 'moment';
import lodash from 'lodash';
import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import { router } from '@/main';
import CONSTANTS from '@/core/helpers/constants';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { Base64 } from 'js-base64';
import { getQuestionKey, inProductionEnv } from '@/core/helpers/utils';
import CrashReportsApi from '@/core/services/AdminApi';

export default {
    async checkGameCode({ commit, getters, dispatch }, joinGameCode) {
        let gameCodeOutcome = 'notset';

        try {
            const response = await Api().get(`join/${joinGameCode}`);

            const { data } = response;

            const user = { ...getters.user };

            console.log('get join result data', data);

            if (data.success && data.data.canJoin) {
                const requireLogin = data.data.requireLogin;

                commit('setRequiresLogin', requireLogin);

                if (requireLogin && (!user || !user.userId)) {
                    // Defer in call stack to override
                    setTimeout(() => {
                        commit('setNextPath', {
                            path: `/join/${joinGameCode}`,
                            params: {
                                joinGameCode,
                            },
                        });
                    }, 0);

                    router.push({
                        name: 'auth.login',
                    });

                    return gameCodeOutcome;
                }

                // commit('setGameCode', joinGameCode);

                gameCodeOutcome = 'can-play';

                // live game store
                if (data.data.mode === 'live') {
                    // console.log('LIVE CAN', gameCodeOutcome);
                    // commit('v2/game/joinCode', joinGameCode);

                    commit('v2/game/code', data.data.code);
                    commit('v2/game/mode', 'live');
                    commit('v2/game/state', 'tbl/player/canJoin');

                    // await dispatch('v2/game/tblplayer/joinCode', data.data);
                    // return gameCodeOutcome;
                }

                const metaData = data.data.metaData;

                commit('setJoinGameMetaData', metaData);
                commit('setJoinGameCode', joinGameCode);

                if (metaData?.requireGoogleSignIn) {
                    commit('setGameCode', data.data.code);

                    return gameCodeOutcome;
                }

                // Check SPG info
                const gameCreatorRole = data.data.creatorRole;
                const gameType = data.data.gameType;

                if (
                    gameCreatorRole === 'teacher' &&
                    gameType === 'spg' &&
                    !(user || {}).userId
                ) {
                    dispatch('createGuestSessionId');
                }
            } else {
                console.debug('NOT get join', data);

                // commit('v2/game/joinCode', null);

                commit('v2/game/ioServer', null);
                commit('v2/game/code', null);
                commit('v2/game/mode', 'none');
                commit('v2/game/state', 'none');

                // await dispatch('v2/game/tblplayer/reset');
                // commit('setGameCodeError', data?.error?.message || true);

                gameCodeOutcome = 'cant-play';
            }
        } catch (error) {
            console.error('cGC error: ', error);
        }
        return gameCodeOutcome;
    },
    pickName(_store, name) {
        sessionStorage.setItem('playerName', name);
    },
    async joinGame({ commit, dispatch, getters, rootGetters }, playerName) {
        const userId = getters.getUserId;

        // because gameInfo was received on create it should exists
        const gameInfo =
            rootGetters['v2/game/mode'] === CONSTANTS.LIVE_GAME_MODE
                ? getters['v2/game/info']
                : { ...getters.getGameInfo };

        // console.log('joining game', playerName, gameInfo);

        // this will make gameCode correct - as joinGameCode, not gameCode
        // so joining will be successfull and player name will be defined
        // (this will fix playerName null bug)
        const joinGameCode =
            rootGetters['v2/game/mode'] === CONSTANTS.LIVE_GAME_MODE
                ? rootGetters['v2/game/code']
                : gameInfo && gameInfo.joinGameCode
                  ? gameInfo.joinGameCode
                  : getters.getJoinGameCode;

        const v2JoinCode =
            rootGetters['v2/game/mode'] === CONSTANTS.LIVE_GAME_MODE &&
            rootGetters['v2/game/code']
                ? rootGetters['v2/game/code']
                : joinGameCode;

        // console.log('joinGame', gameCode, v2JoinCode, playerName, gameInfo);
        // Make sure to reset the player name in the store,
        // so the automatic join watcher would get triggered.
        commit('setPlayerName', { name: null });

        if (!joinGameCode || !playerName) return false;

        sessionStorage.removeItem(`${joinGameCode}_finished`);

        const data = {
            name: playerName,
            joined: moment().format(),
            userId: userId ? userId : null,
            classCode: rootGetters['v2/user/getClassCode'],
        };

        if (getters.getUserSessionId) {
            data['sessionId'] = getters.getUserSessionId;
        }

        // console.log('joining game', gameCode, data);

        let response = null;

        dispatch('joinCallsMonitoring', v2JoinCode);

        try {
            response = await Api().post(`join/${v2JoinCode}`, data);
        } catch (err) {
            response = null;

            commit('setPlayerNameError', err);

            console.error('jG post request error: ', err);
        }

        console.log('join/', v2JoinCode, ' results', response);

        if (!response || !response.data?.data || !response.data?.success) {
            if (response && response.data && response.data.error) {
                if (response.data.error.message === 'GameIsFull') {
                    commit('setGameFullError', response.data.error);
                } else {
                    commit('setPlayerNameError', response.data.error);
                }

                console.error(
                    'jG post request data error: ',
                    response.data.error,
                );
            }

            return false;
        }
        if (
            response.data?.data &&
            response.data?.data?.success === false &&
            response.data?.data?.error
        ) {
            let joinError = response.data?.data?.error;

            await dispatch(
                'v2/ui/alert',
                `Failed: ${
                    joinError === 'KICKED'
                        ? 'Host removed you from game'
                        : joinError
                }`,
                {
                    root: true,
                },
            );

            dispatch('v2/game/leave', null, { root: true });

            return;
        }
        const joinData = response.data?.data;

        if (playerName !== data.playerName) {
            commit('setPlayerNameProfanityError', true);
        }

        commit('setGameMode', joinData.gameMode || joinData.mode);

        localStorage.removeItem('playedTime');

        commit('setPlayerName', { name: joinData.playerName });

        /* new code for live games */
        if (joinData.gameMode === CONSTANTS.LIVE_GAME_MODE && joinData.info) {
            await dispatch('v2/game/tbl/join', joinData);
        } else {
            commit('setGameCode', joinData.gameCode);
        }

        sessionStorage.setItem('playerName', joinData.playerName);

        if (userId) {
            let event;

            switch (getters.getGameMode) {
                case CONSTANTS.SELF_PACED_GAME_MODE:
                    event = EVENTS.SPG_JOINED_GAME;
                    break;
                case CONSTANTS.LIVE_GAME_MODE:
                    event = EVENTS.LIVE_GAME_JOINED_GAME;
                    break;
                case CONSTANTS.MATH_RUNNER_GAME_MODE:
                    event = EVENTS.JOINED_MATH_RUNNER;
                    break;
            }

            if (event) {
                const gameCode = getters.getGameCode || getters['v2/game/code'];

                const user = getters.user;

                const gameCreatorRole =
                    joinData.creatorRole || joinData.info.creatorRole;

                new TrackingService(user).track(event, {
                    gameCode,
                    gameCreatorRole,
                });
            }
        }

        if (joinData.gameMode !== CONSTANTS.LIVE_GAME_MODE) {
            commit('setGameCreatorRole', data.creatorRole);
        }

        return true;
    },
    async createGuestSessionId({ dispatch }) {
        const response = await Api().post('/auth/create-guest-token', {
            playerName: this.playerName,
        });

        const { data } = response;

        if (data.success) {
            dispatch('setPlayerGuestSession', data.data);
        }
    },
    async getPlayerGameStats({ commit, getters }) {
        const gameCode = getters.getGameCode;

        const playerName = getters.getPlayerName;

        const playerNameEncoded = Base64.encodeURI(playerName);

        return await SecureApi()
            .get(`game/${gameCode}/${playerNameEncoded}/stats`)
            .then((response) => {
                const { success, data, error } = response.data;

                if (success) {
                    commit('setPlayerStats', data);

                    if (data) {
                        commit('setPlayerScore', data.score);
                    }
                } else {
                    console.error('gPGS server error: ', error);
                }
            })
            .catch((error) => {
                console.error('gPGS request error: ', error);
            });
    },
    async generateBatchQuestions({ getters, dispatch, state }, { count }) {
        let gameInfo = getters.getGameInfo;

        if (!gameInfo || !gameInfo.type) {
            const selectedGameType =
                getters['v2/game/mode'] === CONSTANTS.LIVE_GAME_MODE
                    ? state.v2.game.info.gameType
                    : getters.getSelectedGameType;

            const { type, equationType } = selectedGameType;

            const numberGenerator = getters.getSelectedNumberGenerator;

            gameInfo = { type, numberGenerator, equationType };
        }

        const generatedQuestions = [];

        const questionKeys = [];

        const maxLimitToTryForUniqueQuestions = 50;

        let questionGenerationCounter = 0;

        let allowedDuplicateQuestionThreshold = 0;

        let numberOfQuestionsToGenerate = count;

        if (!numberOfQuestionsToGenerate) {
            console.error(
                'Missing a limit on how many questions to generate into the batch',
            );

            return false;
        }

        while (generatedQuestions.length < numberOfQuestionsToGenerate) {
            questionGenerationCounter++;

            const newQuestion = await dispatch(
                'generateSingleQuestion',
                gameInfo,
            );

            if (maxLimitToTryForUniqueQuestions < questionGenerationCounter) {
                const newQuestionKey = getQuestionKey(newQuestion);

                // Best effort algorithm to avoid duplicates in the batch.
                // But we have skills in which it's very hard to guarantee
                // that there are no duplicates in the batch.
                const duplicatesCount = questionKeys.filter(
                    (key) => key === newQuestionKey,
                ).length;

                if (
                    duplicatesCount > 0 &&
                    allowedDuplicateQuestionThreshold < duplicatesCount
                ) {
                    // Allow the next duplicate to get in.
                    allowedDuplicateQuestionThreshold++;

                    continue;
                }

                questionKeys.push(newQuestionKey);
            }

            generatedQuestions.push(newQuestion);
        }

        return generatedQuestions;
    },
    async generateSingleQuestion(_store, gameInfo) {
        let question;

        const { type, numberGenerator } = gameInfo;

        const GameTopic = TopicsFactory.getStaticTopicClass(type);

        if (GameTopic) {
            question = GameTopic.generateQuestion(numberGenerator);
        } else {
            console.error('generateSingleQuestion: no math topic');
        }

        return question;
    },
    async generateQuestion({ getters, dispatch }, passedGameInfo = null) {
        let gameInfo = passedGameInfo ? passedGameInfo : getters.getGameInfo;

        if (!gameInfo || !gameInfo.type) {
            const selectedGameType = getters.getSelectedGameType;

            const { type, equationType } = selectedGameType;

            const numberGenerator = getters.getSelectedNumberGenerator;

            gameInfo = { type, numberGenerator, equationType };
        }

        let question = await dispatch('generateSingleQuestion', gameInfo);

        // Check if next question is same as previous
        let previousQuestion =
            JSON.parse(
                sessionStorage.getItem('previousQuestion') !== 'undefined'
                    ? sessionStorage.getItem('previousQuestion')
                    : '{}',
            ) || [];

        if (lodash.isEqual(question, previousQuestion)) {
            return await dispatch('generateQuestion');
        } else {
            sessionStorage.setItem(
                'previousQuestion',
                JSON.stringify(question),
            );
            return question;
        }
    },
    setPlayerGuestSession({ commit }, sessionId) {
        if (!sessionId) {
            sessionStorage.removeItem('playerGuestSession');
        } else {
            sessionStorage.setItem('playerGuestSession', sessionId);
        }

        commit('setPlayerGuestSession', sessionId);
    },
    async createSPGPlayerSession({ commit, getters }) {
        const gameCode =
            getters.getGameCode || sessionStorage.getItem('gameCode');

        const playerName =
            getters.getPlayerName || sessionStorage.getItem('playerName');

        const identifierEncoded = getters.getUserId
            ? Base64.encodeURI(getters.getUserId)
            : Base64.encodeURI(playerName);

        try {
            const response = await Api().post(
                `self-paced-game/${gameCode}/player/${identifierEncoded}/session`,
            );

            const { success, data, error } = response.data;

            if (success) {
                commit('setSPGCurrentPlayerSession', data);
            } else {
                console.error('gPSPGI server error', error);
            }
        } catch (error) {
            console.error('gPSPGI request error: ', error);
        }
    },
    async updateSPGPlayerSession({ getters }, playerSessionData = {}) {
        const gameCode =
            getters.getGameCode || sessionStorage.getItem('gameCode');

        const playerName =
            getters.getPlayerName || sessionStorage.getItem('playerName');

        const playerSessionId = getters.getSPGCurrentPlayerSession;

        const identifierEncoded = getters.getUserId
            ? Base64.encodeURI(getters.getUserId)
            : Base64.encodeURI(playerName);

        try {
            const response = await Api().patch(
                `/self-paced-game/${gameCode}/player/${identifierEncoded}/session/${playerSessionId}`,
                {
                    ...playerSessionData,
                },
            );

            const { success, error } = response.data;

            if (!success) {
                console.error('gPSPGI server error', error);
            }
        } catch (error) {
            console.error('gPSPGI request error: ', error);
        }
    },
    async getPlayerSelfPacedGameInfo({ getters, commit }) {
        const gameCode =
            getters.getGameCode || sessionStorage.getItem('gameCode');

        const playerName =
            getters.getPlayerName || sessionStorage.getItem('playerName');

        const playerNameEncoded = Base64.encodeURI(playerName);

        try {
            const response = await Api().get(
                `self-paced-game/${gameCode}/player/${playerNameEncoded}`,
            );

            const { success, data, error } = response.data;

            if (success) {
                commit('setPlayerScore', data?.score);

                commit('setPlayerStats', data);
            } else {
                console.error('gPSPGI server error', error);
            }
        } catch (error) {
            console.error('gPSPGI request error: ', error);
        }
    },
    async retrieveSPGPlayerSession({ commit, getters }, playerSessionId = '') {
        const gameCode =
            getters.getGameCode || sessionStorage.getItem('gameCode');

        const playerName =
            getters.getPlayerName || sessionStorage.getItem('playerName');

        const playerNameEncoded = Base64.encodeURI(playerName);

        try {
            const response = await Api().get(
                `/self-paced-game/${gameCode}/player/${playerNameEncoded}/session/${playerSessionId}`,
            );

            const { success, data, error } = response.data;

            if (success) {
                commit('setSPGCurrentPlayerSession', data.id);

                commit('setPlayerSessionScore', data.score);

                commit('setPlayerSessionStats', { ...data.stats });
            } else {
                console.error('gPSPGI server error', error);
            }
        } catch (error) {
            console.error('gPSPGI request error: ', error);
        }
    },
    setPlayerSessionScore({ commit }, score = 0) {
        commit('setPlayerSessionScore', score);
    },
    setPlayerSessionStats({ commit }, payload = {}) {
        commit('setPlayerSessionStats', { ...payload });
    },
    addHostToGame({ getters, dispatch }) {
        const user = getters.user;

        const playerName = user.playerName || user.firstName;

        dispatch('pickName', playerName);
        dispatch('joinGame', playerName);
    },
    async addTotalTasksSolved({ getters, dispatch }, tasksSolvedToAdd) {
        if (tasksSolvedToAdd) {
            try {
                const response = await SecureApi().post(
                    `home-game-v10SimpleTreeSocial/add-total-tasks-solved/${tasksSolvedToAdd}`,
                );

                const { success, data, error } = response.data;

                if (success && data) {
                    dispatch('v2/user/update', { studentInfo: data });
                    sessionStorage.removeItem('addTasksSolved');
                } else {
                    console.error('addTasksSolved server error', error);
                }
            } catch (err) {
                console.error('addTasksSolved request error: ', err);
            }
        }
    },
    async getSelfPacedLeaderboard({ getters, commit }) {
        const gameCode = getters.getGameCode;

        try {
            console.log('try');

            const response = await SecureApi().get(
                `self-paced-game/${gameCode}/leaderboard`,
            );

            const { success, data } = response.data;

            if (success) {
                commit('setLeaderboard', data);

                return data;
            }
            return null;
        } catch (error) {
            console.error('gCL request error: ', error);
        }
    },
    setAdditionalQuestionsToTheGame({ commit }, data) {
        commit('setAdditionalQuestionsToTheGame', data);
    },
    joinCallsMonitoring({ dispatch }, joinGameCode) {
        if (inProductionEnv()) {
            if (joinGameCode?.length === CONSTANTS.MATH_RUNNER_GAME_CODE_LENGTH)
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/joinGame/runnerCalls`,
                );
            else if (
                joinGameCode?.length === CONSTANTS.LIVE_GAME_JOIN_CODE_LENGTH
            )
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/joinGame/tblCalls`,
                );
            else if (
                joinGameCode?.length ===
                CONSTANTS.SELF_PACED_GAME_JOIN_CODE_LENGTH
            )
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/joinGame/spgCalls`,
                );
        }
    },
};
