import store from '@/store';
import { getUserFromJwtToken } from '@/flows/Authentication/helpers/AuthHelpers';
import { BrowserDetect } from '@/core/mixins/BrowserDetect';
import CrashReportsApi from './AdminApi';

export const CrashReportTypes = {
    DEFAULT_NAME: 'Unlabelled',
    GAME_NOT_STARTING: 'Game not starting',
    SOCKET_NOT_CONNECTING: 'Socket not connecting',
    BROKEN_QUESTION: 'Broken question',
    BROKEN_ROUND_RESULTS: 'Broken round results',
    BROKEN_ROUND_PREPARE: 'Broken round prepare',
    TIMER_OUT_OF_SYNC_8: 'Timer out of sync 8 seconds',
    TIMER_OUT_OF_SYNC_30: 'Timer out of sync 30 seconds',
};

import { inProductionEnv, sizeof } from '@/core/helpers/utils.js';

export const CrashReportService = (function () {
    /** Constructor */
    function CrashReportService(userId = null, socket) {
        // if not passed, take from store, if not take from token
        if (!userId) {
            userId = store.getters.getUserId;

            if (!userId) {
                userId = getUserFromJwtToken(
                    localStorage?.getItem('userDataToken') || '',
                )?.userId;
            }
        }

        this.userId = userId;

        this.socket = socket;
    }

    CrashReportService.prototype.startSession = function (
        crashType = CrashReportTypes.DEFAULT_NAME,
    ) {
        if (!inProductionEnv()) {
            return;
        }
        // if flag is set to true keep logging
        // to stop user from going back and clearing the logs
        if (localStorage.getItem(crashType)) {
            return;
        }

        localStorage.removeItem(crashType);

        privateMethods.bindMouseTrackingListener();

        privateMethods.bindSocketListenersToConsole(this.socket);

        privateMethods.bindConsoleToEmitter();

        privateMethods.startListening(crashType);

        console.log(`CrashReport: Start session track for '${crashType}'`);

        localStorage.setItem(crashType, new Date());
    };

    CrashReportService.prototype.sendCrashReport = async function (
        crashType = CrashReportTypes.DEFAULT_NAME,
    ) {
        const inQA =
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'qa.99math.com' ||
            window.location.hostname === 'test.99math.com';

        if (inQA) {
            return;
        }

        const crashSessionStart = localStorage.getItem(crashType);

        if (!crashSessionStart) {
            console.error(
                `CrashReport: Could not find crash data for ${crashType}`,
            );

            return;
        }

        const crashId = localStorage.getItem(crashType + '_lastCrashReport');

        if (crashId) {
            console.log(`CrashReport: Already had sent crash report`);

            return;
        }
        console.log(
            `CrashReport: Detected crash ${crashType} and sending crash report`,
        );

        privateMethods.sendReport({
            crashType,
            userId: this.userId,
            data: privateMethods.getData(crashType),
            metaData: privateMethods.getMetaData(crashType),
        });
    };

    CrashReportService.prototype.ammendCrashReport = async function (
        crashType = CrashReportTypes.DEFAULT_NAME,
        reason,
    ) {
        if (!inProductionEnv()) {
            return;
        }

        console.log(`CrashReport: Ammending ${crashType} crash report`);

        privateMethods.ammendReport({
            crashType,
            userId: this.userId,
            data: privateMethods.getData(crashType),
            metaData: privateMethods.getMetaData(crashType),
            ammend: reason,
        });
    };

    CrashReportService.prototype.endSession = function (
        crashType = CrashReportTypes.DEFAULT_NAME,
    ) {
        const inQA =
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'qa.99math.com' ||
            window.location.hostname === 'test.99math.com';

        if (inQA) {
            return;
        }

        console.log(
            `CrashReport: Ending session track for crash '${crashType}'`,
        );

        privateMethods.closeSession(crashType, this.socket);
    };

    const privateMethods = {
        async sendReport({ crashType, userId, metaData, data }) {
            const response = await CrashReportsApi().post(
                'crash-reports/send',
                {
                    crashType,
                    userId,
                    metaData,
                    data,
                },
            );

            if (response.data.success) {
                const id = response.data.data;

                localStorage.setItem(crashType + '_lastCrashReport', id);
            }
        },

        async ammendReport({ crashType, userId, metaData, data, ammend }) {
            const crashId = localStorage.getItem(
                crashType + '_lastCrashReport',
            );

            if (!crashId) {
                return;
            }

            await CrashReportsApi().post('crash-reports/ammend', {
                crashId,
                crashType,
                userId,
                metaData,
                data,
                ammend,
            });

            localStorage.removeItem(crashType + '_lastCrashReport');
        },
        closeSession(crashType) {
            localStorage.removeItem(crashType);

            localStorage.removeItem(crashType + '_lastCrashReport');

            if (
                window.localCrashReportLogData &&
                window.localCrashReportLogData[crashType]
            ) {
                window.localCrashReportLogData[crashType] = [];
            }

            document.removeEventListener(
                'consoleEvent',
                window[crashType + 'eventListener'],
            );
        },
        bindConsoleToEmitter() {
            if (!console || console.collecting) {
                return;
            }

            // polyfill for IE
            (function () {
                if (typeof window.CustomEvent === 'function') {
                    return false;
                }

                function CustomEvent(event, params) {
                    params = params || {
                        bubbles: false,
                        cancelable: false,
                        detail: null,
                    };

                    const evt = document.createEvent('CustomEvent');

                    evt.initCustomEvent(
                        event,
                        params.bubbles,
                        params.cancelable,
                        params.detail,
                    );

                    return evt;
                }

                window.CustomEvent = CustomEvent;
            })();
            // polyfill for IE

            const logInstance = console.log;

            window.log = console.log;

            console.log = function () {
                document.dispatchEvent(
                    new CustomEvent('consoleEvent', {
                        detail: [
                            Date.now(),
                            ...Array.prototype.slice.call(arguments),
                            'log',
                        ],
                    }),
                );

                logInstance.apply(this, Array.prototype.slice.call(arguments));
            };

            const errorInstance = console.error;

            window.error = console.error;

            console.error = function () {
                document.dispatchEvent(
                    new CustomEvent('consoleEvent', {
                        detail: [
                            Date.now(),
                            ...Array.prototype.slice.call(arguments),
                            'error',
                        ],
                    }),
                );

                errorInstance.apply(
                    this,
                    Array.prototype.slice.call(arguments),
                );
            };

            console.collecting = true;
        },
        bindSocketListenersToConsole(socket) {
            if (window._onevent) {
                return;
            }

            const _emit = socket.emit;

            const _onevent = socket.onevent;

            window._emit = socket.emit;

            window._onevent = socket.onevent;

            socket.emit = function () {
                //Override outgoing
                console.log('socket.io', 'emit', arguments);

                _emit.apply(socket, arguments);
            };

            socket.onevent = function (packet) {
                //Override incoming
                if (
                    packet &&
                    packet.data &&
                    packet.data.length &&
                    packet.data[0] !== 'timerSync'
                ) {
                    console.log('socket.io', 'onevent', packet);
                }

                _onevent.call(socket, packet);
            };
        },
        bindMouseTrackingListener() {
            if (window._TrackMouse) {
                return;
            }

            const TrackMouse = function (mouseEvent) {
                const path =
                    mouseEvent.path ||
                    (mouseEvent.composedPath && mouseEvent.composedPath());

                console.log(
                    'User : ' +
                        mouseEvent.type +
                        ', X: ' +
                        mouseEvent.x +
                        ', Y: ' +
                        mouseEvent.y +
                        ', class: ' +
                        path[0].className +
                        ', ts: ' +
                        Date.now(),
                    '\n',
                );
            };
            window._TrackMouse = true;

            document.addEventListener('click', TrackMouse);
        },
        startListening(crashType) {
            if (!window.localCrashReportLogData) {
                window.localCrashReportLogData = {};
            }
            const eventHandler = (e) => {
                if (!window.localCrashReportLogData[crashType]) {
                    window.localCrashReportLogData[crashType] = [];
                }

                window.localCrashReportLogData[crashType].push(e.detail);
            };

            window[crashType + 'eventListener'] = eventHandler;

            document.addEventListener('consoleEvent', eventHandler);
        },
        getData() {
            return {
                gameCode: store.state?.v2?.game?.code,
                playerName: store.state?.v2?.user?.playerName,
                country: store.getters.user?.country,
                webSocketConnectionStatus: store.state?.v2?.io?.open,
                timerSync: store.state?.v2?.game?.timerSync,
                state: store.state?.v2?.game?.state,
                jwt: store.state?.v2?.game?.jwt,
                gameRounds: store.state?.v2?.game?.info?.gameRounds,
            };
        },
        getMetaData(crashType) {
            return {
                console: {
                    logs: this.getLogs(crashType),
                },
                sessionStart: localStorage.getItem(crashType),
                sessionEnd: new Date().toString(),
                device: {
                    browserInfo: BrowserDetect.methods.getBrowserInfo(),
                    operatingSystemInfo: BrowserDetect.methods.getOSInfo(),
                },
            };
        },
        getLogs(crashType) {
            const logsArray =
                window.localCrashReportLogData &&
                window.localCrashReportLogData[crashType]
                    ? window.localCrashReportLogData[crashType]
                    : [];

            const beforeMemory = sizeof(logsArray, 'mb');

            const avgLineMemory = beforeMemory / logsArray.length;

            const memToCut = beforeMemory - 0.8;

            const linesToCut = Math.ceil(memToCut / avgLineMemory);

            return logsArray.slice(linesToCut);
        },
    };

    return CrashReportService;
})();
