/* global mixpanel */
export const STUDENT_JOIN_GAME_EVENTS = {
    STUDENT_FOLLOWED_QR_CODE_LINK: 'studentFollowedQrCodeLink',
    STUDENT_JOINED_GAME_BY_QR_CODE: 'studentJoinedGameByQrCode',
};

export const STUDENT_JOIN_GAME_METHODS = {
    studentFollowedQrCodeLink(gameCode) {
        mixpanel.track('Student scanned and followed QR code link', {
            gameCode,
        });
    },
    studentJoinedGameByQrCode(gameCode) {
        mixpanel.track('Student joined game by QR code link', { gameCode });
    },
};
