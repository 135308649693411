<template>
    <div v-if="isOpen" class="overlay">
        <div v-click-outside="closed" class="payment-modal">
            <div class="content">
                <div class="close-button" @click="closed">+</div>
                <PriceTestB v-if="showPriceTestVersionB" @closed="closed" />
                <PriceTestA v-else @closed="closed" />
            </div>
        </div>
    </div>
</template>
<script>
import vClickOutside from 'click-outside-vue3';
import { mobileViewHeightFix } from '@/core/helpers/MobileHelpers.js';
import PriceTestA from './price-tests/PriceTestA/PriceTestA.vue';
import PriceTestB from './price-tests/PriceTestB/PriceTestB.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: { PriceTestA, PriceTestB },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
            user: 'user',
            priceTest: 'getUsersPriceTest',
        }),
        showPriceTestVersionB() {
            return this.priceTest?.name === 'PriceTestVersionB';
        },
    },
    async mounted() {
        mobileViewHeightFix();

        window.addEventListener('resize', mobileViewHeightFix);
    },
    beforeUnmount() {
        window.removeEventListener('resize', mobileViewHeightFix);
    },
    updated() {
        if (this.isOpen) {
            this.blurBackground();
        }

        if (!this.isOpen) {
            this.unblurBackground();
        }
    },

    methods: {
        ...mapActions(['fetchUsersPriceTest']),
        blurBackground() {
            const studentContainer =
                document.querySelector('.student-container');

            if (studentContainer) {
                studentContainer.classList.add('blurred');
            }

            const hostContainer = document.querySelector('.host-container');

            if (hostContainer) {
                hostContainer.classList.add('blurred');
            }
        },
        unblurBackground() {
            const studentContainer =
                document.querySelector('.student-container');

            if (studentContainer) {
                studentContainer.classList.remove('blurred');
            }

            const hostContainer = document.querySelector('.host-container');

            if (hostContainer) {
                hostContainer.classList.remove('blurred');
            }
        },

        closed() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.overlay {
    position: fixed;
    width: 100vw;
    z-index: 9999;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .payment-modal {
        position: relative;
        border-radius: 20px;

        .content {
            border-radius: 20px;
            position: relative;
            width: 364px;
            height: 644px;
            border: 2px solid #7448af;
            background-color: #7448af;

            .close-button {
                cursor: pointer;
                z-index: 5;
                top: -10px;
                transform: rotate(45deg);
                right: -10px;
                background-color: #0dbfbd;
                border-radius: 50%;
                font-size: 30px;
                height: 30px;
                width: 30px;
                position: absolute;
                font-weight: bold;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@include mq($w-max-phone, max) {
    .overlay {
        align-items: flex-start;
        margin-top: 12px;

        .payment-modal {
            .content {
                width: 94vw;
                height: 80vh;
            }
        }
    }
}
</style>
