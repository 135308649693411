import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
    KEYBOARD_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    randomArrayElement,
    randomIntFromRange,
    randomFromRange,
    getDecimalPlaces,
    round,
    range,
} from '@/core/helpers/utils';
import {
    getFractionLayout,
    isCorrectFractionAnswer,
} from '@/core/math-topics/utils/fractions';
import FractionsTopic from '@/core/math-topics/topics/FractionsTopic';

/**
 * @extends TopicsBaseClass
 */
export default class DecimalsTopic extends TopicsBaseClass {
    static code = 'TYPE_DECIMALS';

    static icon = '';

    static gameTypeNameTranslationKey = 'game.gameTypeTitle.decimals';

    static sampleQuestionCount = 3;

    /**
     * @param {Object} gameInfo
     * @returns {string}
     */
    static getNumberGeneratorName(gameInfo) {
        const {
            numberGenerator: { topic, subtopic, scale, intervals },
        } = gameInfo;

        const topicName = this.t(`game.gameTypeTitle.${topic}`);

        const locale = this.locale();

        if (topic === 'addition') {
            const scales = [
                ...new Set([...scale.addend1, ...scale.addend2]),
            ].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'host.create.decimals.ones',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }

        if (topic === 'subtraction') {
            const scales = [
                ...new Set([...scale.minuend, ...scale.subtrahend]),
            ].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'game.gameTypeTitle.integers',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }

        if (topic === 'multiplication' || topic === 'division') {
            let allScales = [];

            // Push each scale of each topic to array
            for (const taskType in scale) {
                for (const value of scale[taskType]) {
                    allScales.push(value);
                }
            }

            // Create a sorted array of unique values
            const scales = [...new Set([...allScales])].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 10:
                        return this.t(
                            'host.create.decimals.tens',
                        ).toLowerCase();
                    case 100:
                        return this.t(
                            'host.create.decimals.hundreds',
                        ).toLowerCase();
                    case 1000:
                        return this.t(
                            'host.create.decimals.thousands',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }

        if (topic === 'conversion') {
            if (!subtopic) {
                return '';
            }

            const isFractionDecimal =
                subtopic === 'fractionToDecimal' ||
                subtopic === 'decimalToFraction';

            if (isFractionDecimal) {
                return `
                    ${this.t(`host.create.fractions["${subtopic}"]`)}
                `;
            }
        }

        if (topic === 'rounding') {
            const textScale = [scale].flat().map((s) => {
                switch (s) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'host.create.decimals.ones',
                        ).toLowerCase();
                    case 10:
                        return this.t(
                            'host.create.decimals.tens',
                        ).toLowerCase();
                    case 100:
                        return this.t(
                            'host.create.decimals.hundreds',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${textScale.join(', ')}`;
        }

        if (topic === 'comparing') {
            if (Array.isArray(scale)) {
                const scale1 = scale[0];

                const scale2 = scale[1];

                let num1 = scale1 === 1 ? 1 : 1 / scale1;

                let num2 = scale2 === 1 ? 1 : 1 / scale2;

                num1 = num1.toLocaleString(locale);

                num2 = num2.toLocaleString(locale);

                return `${topicName}: ` + num1 + ' _ ' + num2;
            } else {
                let num = scale === 1 ? 1 : 1 / scale;

                num = num.toLocaleString(locale);

                return `${topicName}: ${num} _ ${num}`;
            }
        }

        if (topic === 'ordering') {
            const scaleString = [scale]
                .flat()
                .sort((a, b) => b - a)
                .map((value) => {
                    switch (value) {
                        case 0.001:
                            return this.t(
                                'host.create.fractions.thousandths',
                            ).toLowerCase();
                        case 0.01:
                            return this.t(
                                'host.create.fractions.hundredths',
                            ).toLowerCase();
                        case 0.1:
                            return this.t(
                                'host.create.fractions.tenths',
                            ).toLowerCase();
                        case 1:
                            return this.t(
                                'game.gameTypeTitle.integers',
                            ).toLowerCase();
                        default:
                            return;
                    }
                })
                .join(', ');

            return `${topicName}: ${scaleString}`;
        }

        if (topic === 'numberLine') {
            const interval = this.t('host.create.interval');

            const intervalString = intervals
                .map((s) => s.toLocaleString(locale))
                .join(', ');

            return `${topicName}: 0...${scale} - ${interval} ${intervalString}`;
        }

        if (topic === 'exponents') {
            const {
                numberGenerator: { base, exponents },
            } = gameInfo;

            const baseLocale = base.toLocaleString(locale);

            return (
                `${topicName}: ` +
                `${this.t('host.create.exponents.base')} 1...${baseLocale} - ` +
                `${this.t('host.create.exponents.exponent')} ${exponents.join(
                    ', ',
                )}`
            );
        }
    }

    /**
     * @param {Object} numberGenerator
     * @param {string} numberGenerator.topic
     * @param {string} numberGenerator.subtopic
     * @param {number|Object} numberGenerator.scale
     * @returns {Object}
     */
    static generateQuestion(numberGenerator) {
        switch (numberGenerator && numberGenerator.topic) {
            case 'addition':
                return this._genAdditionQuestion(numberGenerator);
            case 'subtraction':
                return this._genSubtractionQuestion(numberGenerator);
            case 'multiplication':
                return this._genMultiplicationQuestion(numberGenerator);
            case 'division':
                return this._genDivisionQuestion(numberGenerator);
            case 'conversion':
                return this._genConversionQuestion(numberGenerator);
            case 'rounding':
                return this._genRoundingQuestion(numberGenerator);
            case 'comparing':
                return this._genComparingQuestion(numberGenerator);
            case 'ordering':
                return this._genOrderingQuestion(numberGenerator);
            case 'numberLine':
                return this._genNumberLineQuestion(numberGenerator);
            case 'exponents':
                return this._genExponentsQuestion(numberGenerator);
            default:
                return;
        }
    }

    /**
     * @param {Object} question
     * @param {string} calledIn
     * @returns {string} HTML template string
     */
    static formatQuestion(question, skill, calledIn) {
        const topic = question?.topic || question?.data?.topic;
        // const locale = store.getters.getCurrentLanguage;

        switch (topic) {
            case 'addition':
                return this._formatAdditionQuestion(question, calledIn);
            case 'subtraction':
                return this._formatSubtractionQuestion(question, calledIn);
            case 'comparing':
                return this._formatComparingQuestion(question, calledIn);
            case 'ordering':
                return this._formatOrderingQuestion(question, calledIn);
            case 'conversion':
                return this._formatConversionQuestion(question, calledIn);
            case 'rounding':
                return this._formatRoundingQuestion(question, calledIn);
            case 'multiplication':
                return this._formatMultiplicationQuestion(question, calledIn);
            case 'division':
                return this._formatDivisionQuestion(question, calledIn);
            case 'numberLine':
                return this._formatNumberLineQuestion(question, calledIn);
            case 'exponents':
                return this._formatExponentsQuestion(question, calledIn);
        }
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        const { topic, subtopic } = numberGenerator;
        if (
            topic === 'addition' ||
            topic === 'subtraction' ||
            topic === 'multiplication' ||
            topic === 'division' ||
            topic === 'exponents' ||
            topic === 'rounding'
        ) {
            return Number(answer) === question.answer;
        }

        if (topic === 'comparing') {
            return answer === question.answer;
        }

        if (topic === 'ordering') {
            return question.answer.every(
                (number, index) => number === answer[index],
            );
        }

        if (topic === 'conversion') {
            if (subtopic === 'fractionToDecimal') {
                // In some languages comma is used
                // instead of dot to mark place values.
                const formattedPlayerAnswer =
                    typeof answer === 'object'
                        ? answer.float.replace(/,/, '.')
                        : answer.replace(/,/, '.');

                const playerFloat = parseFloat(formattedPlayerAnswer);

                return playerFloat === question.float;
            }

            return isCorrectFractionAnswer(answer, question);
        }

        if (topic === 'numberLine') {
            return Number(question.answer) === Number(answer);
        }

        return false;
    }

    /**/
    static answerData(question, answer, skill) {
        const { topic, subtopic } = skill.numberGenerator;

        if (topic === 'conversion') {
            const {
                numerator,
                denominator,
                float,
                remainder,
                wholeNumber,
                metric,
                shape,
                answer,
                wholeNumberOption,
                numberType,
            } = question;

            return {
                numerator,
                denominator,
                float,
                remainder,
                wholeNumber,
                topic,
                subtopic,
                wholeNumberOption,
                numberType,
            };
        }

        return super.answerData(question, answer, skill);
    }

    /**
     * @param {Object} answer
     * @param {Object} numberGenerator
     * @param {Object} question
     * @returns {string} HTML template string
     */
    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        const { topic, subtopic } =
            numberGenerator.numberGenerator || numberGenerator;

        const { numerator, denominator, wholeNumber, scale } = answer;

        const hasFractionAnswer = subtopic !== 'fractionToDecimal';

        const locale = this.locale();

        const isCorrectAnswer = question?.correct;

        const wrongSelector = isCorrectAnswer ? '' : 'wrong-answer';

        if (typeof answer !== 'object') {
            if (!Number.isNaN(Number(answer))) {
                const decimalPlaces =
                    topic === 'rounding' && scale >= 1
                        ? 0
                        : getDecimalPlaces(answer);

                const answ = Number(answer).toLocaleString(locale, {
                    minimumFractionDigits: decimalPlaces,
                });

                return ` = <span class="${wrongSelector}">${answ}</span>`;
            }

            return ` = <span class="${wrongSelector}">${answer}</span>`;
        }

        const floatAnswer = () => `
            = <span class="${wrongSelector} fraction d-inherit">
                ${answer.float}
            </span>
        `;

        if (topic === 'conversion') {
            if (!hasFractionAnswer) {
                return floatAnswer();
            }

            return getFractionLayout(answer, !isCorrectAnswer, true);
        }

        if (topic === 'ordering') {
            const numbers = answer.map((num) => this.formatNumber(num));

            return `=
                <span class="${wrongSelector}">
                    ${numbers.join('\xa0\xa0\xa0')}
                </span>
            `;
        }
    }

    static generateCorrectAnswerHtml(question) {
        if (
            question.topic === 'conversion' &&
            question.subtopic === 'decimalToFraction'
        ) {
            return this.generatePlayerAnswerHtml(question, question);
        }

        const conversionQuestionInTblHostSeeMistakesView =
            question.topic === 'conversion';

        if (conversionQuestionInTblHostSeeMistakesView) {
            return ` = <span class="correct-answer">${question.float}</span>`;
        }

        if (question.data?.topic === 'conversion') {
            return ` = <span class="correct-answer">${question.data.float}</span>`;
        }

        return ` = <span class="correct-answer">${question.answer}</span>`;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getAnswerInputType(numberGenerator) {
        const { topic, subtopic, wholeNumberOption, numberType } =
            numberGenerator;

        const isConversion = topic === 'conversion';

        const isComparing = topic === 'comparing';

        const isOrdering = topic === 'ordering';

        const isFractionConversion =
            subtopic === 'decimalToFraction' &&
            (wholeNumberOption === 'equal' ||
                (wholeNumberOption === 'greater' &&
                    numberType === 'improperFraction'));

        const showDecimalInput =
            isConversion && subtopic === 'fractionToDecimal';

        const showMixedInput =
            isConversion &&
            subtopic === 'decimalToFraction' &&
            wholeNumberOption === 'greater' &&
            numberType === 'mixedNumber';

        const showFractionInput = isConversion && isFractionConversion;

        const showBoxedInput = topic === 'numberLine';

        if (topic === 'shade') {
            return ANSWER_INPUT_TYPE.REVERSE_FRACTION;
        }

        if (isComparing) {
            return ANSWER_INPUT_TYPE.NONE;
        }

        if (isOrdering) {
            return ANSWER_INPUT_TYPE.ORDER;
        }

        if (showDecimalInput) {
            return ANSWER_INPUT_TYPE.DECIMAL_FRACTION;
        }

        if (showMixedInput) {
            return ANSWER_INPUT_TYPE.MIXED;
        }

        if (showFractionInput) {
            return ANSWER_INPUT_TYPE.IMPROPER_FRACTION;
        }

        if (showBoxedInput) {
            return ANSWER_INPUT_TYPE.BOXED;
        }

        return ANSWER_INPUT_TYPE.REGULAR;
    }

    static questionViewIsInline(skill) {
        return skill.numberGenerator.topic !== 'numberLine';
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getKeyboardType(numberGenerator) {
        const { topic } = numberGenerator;

        if (topic === 'comparing') {
            return KEYBOARD_TYPE.COMPARING;
        }

        if (topic === 'ordering') {
            return KEYBOARD_TYPE.ENTER;
        }

        return KEYBOARD_TYPE.NUMERIC;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {boolean}
     */
    static showArrowButtons(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        return !!(topic === 'conversion' && subtopic === 'decimalToFraction');
    }

    /**
     * @param {Object} numberGenerator
     * @param {Object} question
     * @returns {Object|string}
     */
    static resetPlayerAnswer(numberGenerator, question) {
        const { topic } = numberGenerator;

        if (topic === 'conversion') {
            if (numberGenerator.subtopic === 'fractionToDecimal') {
                return '';
            }

            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        if (topic === 'ordering') {
            if (!question) {
                return [];
            }

            return [question.number1, question.number2, question.number3];
        }

        return super.resetPlayerAnswer(numberGenerator, question);
    }

    static formatBoxedQuestion(question, skill) {
        if (skill?.numberGenerator?.topic === 'numberLine') {
            return '? = ';
        }

        return null;
    }

    /*
     *  Helpers from generate and format
     */

    static _genAdditionQuestion(numberGenerator) {
        const { topic, scale } = numberGenerator;

        let number1 = 0;

        let number2 = 0;

        const createFractional = (param) => {
            const dividend = 1 / param;

            const randomInt = randomIntFromRange(1, dividend - 1);

            const result = randomInt / dividend;

            return round(result, 4);
        };

        const createDecimal = (param) => {
            const dividend = 1 / param;

            const randomInt1 = randomIntFromRange(0, 9);

            const randomInt2 = randomIntFromRange(1, dividend - 1);

            const result = randomInt1 + randomInt2 / dividend;

            return round(result, 4);
        };

        const createNumbers = (params = []) => {
            // Flip a coin. true ==> 0.x, false ==> x.x;
            const headsOrTails = randomArrayElement([true, false]);

            const decimals = params.filter((n) => n !== 1);

            // Integer and decimal
            if (decimals.length === 1) {
                const decimal = params.find((n) => n !== 1);

                const int = randomIntFromRange(1, 9);

                return headsOrTails
                    ? [int, createFractional(decimal)]
                    : [int, createDecimal(decimal)];
            }

            // Only decimals
            if (decimals.length > 1) {
                const [x, y] = params;

                return headsOrTails
                    ? [createFractional(x), createFractional(y)]
                    : [createDecimal(x), createDecimal(y)];
            }
        };

        const { addend1, addend2 } = scale;

        const a = randomArrayElement(addend1);

        const b = randomArrayElement(addend2);

        [number1, number2] = createNumbers([a, b]);

        let answer = number1 + number2;

        // Round the answer because adding decimals may return
        // something like 1.2300000000001
        answer = round(answer, 6);

        number1 = number1.toFixed(getDecimalPlaces(a));

        number2 = number2.toFixed(getDecimalPlaces(b));

        return {
            topic,
            number1,
            number2,
            answer,
        };
    }

    static _genSubtractionQuestion(numberGenerator) {
        const { topic, scale } = numberGenerator;

        let number1 = 0;

        let number2 = 0;

        // ==> 0.x
        const createFractional = (param) => {
            const dividend = 1 / param;

            const randomInt = randomIntFromRange(1, dividend - 1);

            const result = randomInt / dividend;

            return round(result, 4);
        };

        // ==> (5, 9).x
        const minuendDecimal = (param) => {
            const int = randomIntFromRange(5, 9);

            return int + param;
        };

        // ==> (0, 4).x
        const subtrahendDecimal = (param) => {
            const int = randomIntFromRange(0, 4);

            return int + param;
        };

        const createNumbers = (params) => {
            const [minuend, subtrahend] = params;

            const dividendMinuend = 1 / minuend;

            const dividendSubtrahend = 1 / subtrahend;

            // Flip a coin
            const headsOrTails = randomArrayElement([true, false]);

            // x - y
            if (minuend === 1) {
                if (headsOrTails) {
                    // 1 - 0.x
                    const sub = createFractional(subtrahend);

                    return [minuend, sub];
                } else {
                    const fractional = createFractional(subtrahend);

                    const sub1 = fractional;

                    const sub2 = subtrahendDecimal(fractional);

                    // (1, 9) - (0.x)
                    const option1 = [randomIntFromRange(1, 9), sub1];

                    // (5, 9) - (0, 4).x
                    const option2 = [randomIntFromRange(5, 9), sub2];

                    return randomArrayElement([option1, option2]);
                }
            }

            // 0.x - y
            if (minuend === 0.1) {
                const randomIntX = randomIntFromRange(2, 9);

                const randomIntY = randomIntFromRange(1, randomIntX - 1);

                let a = randomIntX / dividendMinuend;

                let b = randomIntY / dividendMinuend;

                if (subtrahend === 1) {
                    const x = randomIntFromRange(1, 9);

                    a = x / dividendMinuend + randomIntX;

                    const y = Math.round(a) - 1;

                    b = randomIntFromRange(1, y);

                    a = round(a, 4);

                    b = round(b, 4);

                    return [a, b];
                }

                if (subtrahend === 0.01) {
                    const x = randomIntFromRange(1, 9);

                    b = b + x / dividendSubtrahend;
                }

                if (subtrahend === 0.001) {
                    const x = randomIntFromRange(1, 99);

                    b = b + x / dividendSubtrahend;
                }

                a = round(a, 4);

                b = round(b, 4);

                if (headsOrTails) {
                    a = minuendDecimal(a);

                    b = subtrahendDecimal(b);
                }

                return [a, b];
            }

            // 0.0x - y
            if (minuend === 0.01) {
                let randomIntX = randomIntFromRange(2, 9);

                if (subtrahend === 0.01 || subtrahend === 0.001) {
                    randomIntX = randomIntFromRange(2, 99);
                }

                const randomIntY = randomIntFromRange(1, randomIntX - 1);

                let a;

                let b;

                if (subtrahend === 1) {
                    a =
                        randomIntFromRange(1, 99) / dividendMinuend +
                        randomIntX;

                    const x = Math.round(a) - 1;

                    b = randomIntFromRange(1, x);

                    a = round(a, 4);

                    b = round(b, 4);

                    return [a, b];
                }

                if (subtrahend === 0.1) {
                    const x = randomIntFromRange(1, 9);

                    a = randomIntX / dividendSubtrahend + x / dividendMinuend;

                    b = randomIntY / dividendSubtrahend;
                }

                if (subtrahend === 0.01) {
                    a = randomIntX / dividendMinuend;

                    b = randomIntY / dividendMinuend;
                }

                if (subtrahend === 0.001) {
                    const x = randomIntFromRange(1, 9);

                    a = randomIntX / dividendMinuend;

                    b = randomIntY / dividendMinuend + x / dividendSubtrahend;
                }

                a = round(a, 4);

                b = round(b, 4);

                if (headsOrTails) {
                    a = minuendDecimal(a);

                    b = subtrahendDecimal(b);
                }

                return [a, b];
            }

            // 0.00x - y
            if (minuend === 0.001) {
                const randomIntX = randomIntFromRange(
                    2,
                    dividendSubtrahend - 1,
                );

                const randomIntY = randomIntFromRange(1, randomIntX - 1);

                let a;

                let b = randomIntY / dividendSubtrahend;

                if (subtrahend === 1) {
                    const x = randomIntFromRange(1, 999) / 1000;

                    a = x + randomIntFromRange(2, 9);

                    const y = Math.round(a) - 1;

                    b = randomIntFromRange(1, y);

                    a = round(a, 4);

                    b = round(b, 4);

                    return [a, b];
                }

                if (subtrahend === 0.1) {
                    a =
                        randomIntX / dividendSubtrahend +
                        randomIntFromRange(1, 99) / dividendMinuend;
                }

                if (subtrahend === 0.01) {
                    a =
                        randomIntX / dividendSubtrahend +
                        randomIntFromRange(1, 9) / dividendMinuend;
                }

                if (subtrahend === 0.001) {
                    a = randomIntX / dividendMinuend;
                }

                a = round(a, 4);

                b = round(b, 4);

                if (headsOrTails) {
                    a = minuendDecimal(a);

                    b = subtrahendDecimal(b);
                }

                return [a, b];
            }
        };

        const { minuend, subtrahend } = scale;

        const a = randomArrayElement(minuend);

        const b = randomArrayElement(subtrahend);

        [number1, number2] = createNumbers([a, b]);

        let answer = number1 - number2;

        answer = round(answer, 6);

        number1 = number1.toFixed(getDecimalPlaces(a));

        number2 = number2.toFixed(getDecimalPlaces(b));

        return {
            topic,
            number1,
            number2,
            answer,
        };
    }

    static _genComparingQuestion(numberGenerator) {
        const { scale, topic } = numberGenerator;

        const wholeNumber = randomIntFromRange(0, 9);

        const randomByScale = (max) => {
            return randomIntFromRange(1, max - 1);
        };

        const decimalsByScale = (max) => {
            return max.toString().split('1')[1].length;
        };

        let nr1, nr2, answer;

        // Single scale selection
        if (typeof scale === 'number') {
            if (scale === 1) {
                nr1 = randomIntFromRange(0, 9).toString();

                nr2 = randomIntFromRange(0, 9).toString();
            } else {
                const singleRandom1 = randomByScale(scale);

                const singleRandom2 = randomByScale(scale);

                const decimals = decimalsByScale(scale);

                nr1 = wholeNumber + singleRandom1 / scale;

                nr1 = nr1.toFixed(decimals);

                nr2 = wholeNumber + singleRandom2 / scale;

                nr2 = nr2.toFixed(decimals);
            }
        }

        // Multiple scale selections
        if (Array.isArray(scale)) {
            let scale1 = randomArrayElement(scale);

            const scale2 = randomArrayElement(scale);

            // Do not compare two whole numbers
            if (scale1 === 1 && scale2 === 1) {
                const decimalScale = scale.filter((s) => s !== 1);

                scale1 = randomArrayElement(decimalScale);
            }

            const scales = [scale1, scale2];

            const createFloat = (whole, relation) => {
                const decimals = decimalsByScale(relation);

                let res = whole + randomByScale(relation) / relation;

                return res.toFixed(decimals);
            };

            const createVaryingInt = (num) => {
                const n1 = randomIntFromRange(1, 10).toString();

                const n2 = Math.ceil(num).toString();

                const n3 = Math.floor(num).toString();

                return randomArrayElement([n1, n2, n3]);
            };

            if (scale1 === scale2) {
                nr1 = createFloat(wholeNumber, scale1);

                nr2 = createFloat(wholeNumber, scale1);
            }
            if (scales.includes(1) && scales.includes(10)) {
                nr1 = createFloat(wholeNumber, 10);

                nr2 = createVaryingInt(nr1);
            }
            if (scales.includes(1) && scales.includes(100)) {
                nr1 = createFloat(wholeNumber, 100);

                nr2 = createVaryingInt(nr1);
            }
            if (scales.includes(1) && scales.includes(1000)) {
                nr1 = createFloat(wholeNumber, 1000);

                nr2 = createVaryingInt(nr1);
            }
            if (scales.includes(10) && scales.includes(100)) {
                nr1 = createFloat(wholeNumber, 10);

                nr2 = createFloat(wholeNumber, 100);
            }
            if (scales.includes(10) && scales.includes(1000)) {
                nr1 = createFloat(wholeNumber, 10);

                nr2 = createFloat(wholeNumber, 1000);
            }
            if (scales.includes(100) && scales.includes(1000)) {
                nr1 = createFloat(wholeNumber, 100);

                nr2 = createFloat(wholeNumber, 1000);
            }
        }

        const nr1Number = parseFloat(nr1);

        const nr2Number = parseFloat(nr2);

        if (nr1Number === nr2Number) {
            answer = '=';
        } else if (nr1Number > nr2Number) {
            answer = '>';
        } else if (nr1Number < nr2Number) {
            answer = '<';
        }

        return {
            topic,
            number1: nr1,
            number2: nr2,
            answer: answer,
        };
    }

    static _genOrderingQuestion(numberGenerator) {
        const { scale, topic, order } = numberGenerator;

        let answer;

        const pickScales = (array) => {
            const el1 = randomArrayElement(array);

            const el2 = randomArrayElement(array);

            let el3 = randomArrayElement(array);

            const allIntegers = [el1, el2, el3].every((el) => el === 1);

            // // Avoid picking all integer scales
            if (allIntegers) {
                const filtered = array.filter((item) => item !== 1);

                el3 = randomArrayElement(filtered);
            }

            return [el1, el2, el3];
        };

        const createNumber = (number, exclude = false) => {
            if (exclude !== false) {
                exclude = Array.isArray(exclude)
                    ? exclude.map((n) => Number(n))
                    : Number(exclude);
            }

            if (number === 1) {
                const arr = range(0, 9, exclude);

                return randomArrayElement(arr);
            } else {
                const decimalPart = 1 / number - 1;

                const fixed = `${decimalPart}`.length;

                const max = parseFloat(`9.${decimalPart}`);

                const nr = randomFromRange(number, max, fixed);

                // Generate each number once
                return [exclude].flat().includes(Number(nr))
                    ? createNumber(number, exclude)
                    : nr;
            }
        };

        const createNumbers = () => {
            let num1, num2, num3;

            if (Array.isArray(scale)) {
                const [scale1, scale2, scale3] = pickScales(scale);

                num1 = createNumber(scale1);

                num2 = createNumber(scale2, num1);

                num3 = createNumber(scale3, [num1, num2]);
            } else {
                num1 = createNumber(scale);

                num2 = createNumber(scale, num1);

                num3 = createNumber(scale, [num1, num2]);
            }

            return [num1, num2, num3];
        };

        const [nr1, nr2, nr3] = createNumbers(scale);

        const randomOrder = randomArrayElement(order);

        if (randomOrder === 'ascending') {
            answer = [nr1, nr2, nr3].sort((a, b) => a - b);
        } else {
            answer = [nr1, nr2, nr3].sort((a, b) => b - a);
        }

        return {
            topic,
            order: randomOrder,
            number1: nr1,
            number2: nr2,
            number3: nr3,
            answer,
        };
    }

    static _genConversionQuestion(numberGenerator) {
        const { scale, topic, subtopic, numberType, wholeNumberOption } =
            numberGenerator;

        let numerator;

        let denominator;

        let wholeNumber;

        let remainder;

        let float;

        if (!subtopic) {
            return;
        }

        switch (subtopic) {
            case 'decimalToFraction':
                denominator = randomArrayElement(scale.denominator);

                const decimalPlaces = getDecimalPlaces(denominator);

                const random1 = randomIntFromRange(1, 9);

                const random2 = randomIntFromRange(10, 99);

                const random3 = randomIntFromRange(100, 999);

                let random;

                if (wholeNumberOption === 'equal') {
                    switch (denominator) {
                        case 10:
                            numerator = random1;

                            break;
                        case 100:
                            numerator = randomArrayElement([random1, random2]);

                            break;
                        case 1000:
                            numerator = randomArrayElement([
                                random1,
                                random2,
                                random3,
                            ]);

                            break;
                        default:
                            break;
                    }

                    wholeNumber = 0;

                    float = (numerator / denominator).toFixed(decimalPlaces);
                }

                if (wholeNumberOption === 'greater') {
                    if (numberType === 'improperFraction') {
                        switch (denominator) {
                            case 10:
                                wholeNumber = randomIntFromRange(1, 10);

                                random = random1;

                                break;
                            case 100:
                                wholeNumber = randomIntFromRange(1, 5);

                                random = randomArrayElement([random1, random2]);

                                break;
                            case 1000:
                                wholeNumber = randomIntFromRange(1, 2);

                                random = randomArrayElement([
                                    random1,
                                    random2,
                                    random3,
                                ]);

                                break;
                            default:
                                break;
                        }

                        float = (wholeNumber + random / denominator).toFixed(
                            decimalPlaces,
                        );

                        numerator = Math.round(+float * denominator);
                    }

                    if (numberType === 'mixedNumber') {
                        switch (denominator) {
                            case 10:
                                wholeNumber = randomIntFromRange(1, 10);

                                numerator = random1;

                                break;
                            case 100:
                                wholeNumber = randomIntFromRange(1, 5);

                                numerator = randomArrayElement([
                                    random1,
                                    random2,
                                ]);

                                break;
                            case 1000:
                                wholeNumber = randomIntFromRange(1, 2);

                                numerator = randomArrayElement([
                                    random1,
                                    random2,
                                    random3,
                                ]);

                                break;
                            default:
                                break;
                        }

                        float = (wholeNumber + numerator / denominator).toFixed(
                            decimalPlaces,
                        );
                    }
                }

                break;

            case 'fractionToDecimal':
                denominator = randomArrayElement(scale.denominator);

                if (wholeNumberOption === 'equal') {
                    numerator = randomIntFromRange(1, denominator) - 1;
                    wholeNumber = 0;
                    float = numerator / denominator;
                }

                if (wholeNumberOption === 'greater') {
                    numerator = randomIntFromRange(
                        denominator + 1,
                        denominator * 10 - 1,
                    );

                    float = numerator / denominator;

                    wholeNumber = float.toString().split('.')[0];

                    wholeNumber = parseInt(wholeNumber);

                    remainder = numerator - denominator * wholeNumber;
                }

                break;

            default:
                break;
        }

        return {
            topic,
            subtopic,
            numerator,
            denominator,
            wholeNumber,
            remainder,
            float,
            numberType,
            wholeNumberOption,
        };
    }

    static _genRoundingQuestion(numberGenerator) {
        const { scale, topic } = numberGenerator;

        let selectedScale = scale;

        // Multiple scale selections
        if (Array.isArray(scale)) {
            selectedScale = randomArrayElement(scale);
        }

        let decimal;
        let answer;
        let decimal1;
        let decimal2;
        let decimal3;
        let random1;
        let random2a;
        let random2b;
        let random2c;

        switch (selectedScale) {
            case 0.001:
                random1 = randomIntFromRange(0, 10);

                random2a = randomIntFromRange(1, 10000);

                decimal = (random1 + random2a / 10000).toFixed(4);
                break;
            case 0.01:
                random1 = randomIntFromRange(0, 10);

                random2a = randomIntFromRange(1, 1000);

                random2b = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 1000;

                decimal2 = random1 + random2b / 10000;

                decimal = randomArrayElement([
                    decimal1.toFixed(3),
                    decimal2.toFixed(4),
                ]);

                break;
            case 0.1:
                random1 = randomIntFromRange(0, 10);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([
                    decimal1.toFixed(2),
                    decimal2.toFixed(3),
                    decimal3.toFixed(4),
                ]);

                break;
            case 1:
                random1 = randomIntFromRange(1, 20);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([
                    decimal1.toFixed(2),
                    decimal2.toFixed(3),
                    decimal3.toFixed(4),
                ]);

                break;
            case 10:
                random1 = randomIntFromRange(1, 1000);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([
                    decimal1.toFixed(2),
                    decimal2.toFixed(3),
                    decimal3.toFixed(4),
                ]);

                break;
            case 100:
                random1 = randomIntFromRange(1, 1000);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([
                    decimal1.toFixed(2),
                    decimal2.toFixed(3),
                    decimal3.toFixed(4),
                ]);

                break;
            default:
                break;
        }

        // In some cases the simple way of rounding isn't returning
        // the right result, do some extra manipulation with numbers
        if (selectedScale < 1) {
            answer =
                Math.round(+decimal / (selectedScale / 100)) *
                (selectedScale / 100);

            const decimalPlaces = getDecimalPlaces(selectedScale);

            answer = Number(answer.toFixed(decimalPlaces));
        } else {
            answer = Math.round(+decimal / selectedScale) * selectedScale;

            answer = Number(answer.toFixed(0));
        }

        return {
            topic,
            scale: selectedScale,
            decimal,
            answer,
        };
    }

    static _generateMultiplicationQuestion(topicId, scales) {
        const createDecimal = (dividend) => {
            // Create an array of integers excluding 10
            const arr = range(1, 12, 10);

            const int = randomArrayElement(arr);

            return int / dividend;
        };

        // decimal x decimal
        if (topicId === '1') {
            if (scales.includes(0.1) && scales.includes(0.01)) {
                const a = createDecimal(10);

                const b = createDecimal(100);

                return [a, b];
            }

            if (scales.includes(0.1)) {
                const a = createDecimal(10);

                const b = createDecimal(10);

                return [a, b];
            }

            if (scales.includes(0.01)) {
                const a = createDecimal(100);

                const b = createDecimal(100);

                return [a, b];
            }
        }

        // decimal x whole number
        if (topicId === '2') {
            const opt1 = () => {
                const a = randomIntFromRange(1, 12);

                const b = createDecimal(10);

                return [a.toFixed(), b.toFixed(1)];
            };

            const opt2 = () => {
                const a = randomIntFromRange(1, 12);

                const b = randomIntFromRange(1, 12) / 100;

                return [a.toFixed(), b.toFixed(2)];
            };

            if (scales.includes(0.1) && scales.includes(0.01)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(0.1)) {
                return opt1();
            }

            if (scales.includes(0.01)) {
                return opt2();
            }
        }

        // decimal x 0.1, 0.01, 0.001
        if (topicId === '3') {
            const opt1 = () => {
                const a1 = randomIntFromRange(1, 100);

                const a2 = randomIntFromRange(1, 1000) / 10;

                const a3 = randomIntFromRange(1, 1000) / 100;

                const a = randomArrayElement([a1, a2, a3]);

                const b = 1 / 10;

                return [a, b];
            };

            const opt2 = () => {
                const a1 = randomIntFromRange(1, 100);

                const a2 = randomIntFromRange(1, 1000) / 10;

                const a3 = randomIntFromRange(1, 1000) / 100;

                const a = randomArrayElement([a1, a2, a3]);

                const b = 1 / 100;

                return [a, b];
            };

            const opt3 = () => {
                const a1 = randomIntFromRange(1, 1000);

                const a2 = randomIntFromRange(1, 1000) / 10;

                const a = randomArrayElement([a1, a2]);

                const b = 1 / 1000;

                return [a, b];
            };

            if (
                scales.includes(0.1) &&
                scales.includes(0.01) &&
                scales.includes(0.001)
            ) {
                return randomArrayElement([opt1(), opt2(), opt3()]);
            }

            if (scales.includes(0.1) && scales.includes(0.01)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(0.1) && scales.includes(0.001)) {
                return randomArrayElement([opt1(), opt3()]);
            }

            if (scales.includes(0.01) && scales.includes(0.001)) {
                return randomArrayElement([opt2(), opt3()]);
            }

            if (scales.includes(0.1)) {
                return opt1();
            }

            if (scales.includes(0.01)) {
                return opt2();
            }

            if (scales.includes(0.001)) {
                return opt3();
            }
        }

        // decimal x 10, 100, 1000
        if (topicId === '4') {
            const intA = randomIntFromRange(1, 100);

            const a1 = intA / 10;

            const a2 = intA / 100;

            const a3 = intA / 1000;

            const a = randomArrayElement([a1, a2, a3]);

            if (
                scales.includes(10) &&
                scales.includes(100) &&
                scales.includes(1000)
            ) {
                const b = randomArrayElement([10, 100, 1000]);

                return [a, b];
            }

            if (scales.includes(10) && scales.includes(100)) {
                const b = randomArrayElement([10, 100]);

                return [a, b];
            }

            if (scales.includes(10) && scales.includes(1000)) {
                const b = randomArrayElement([10, 1000]);

                return [a, b];
            }

            if (scales.includes(100) && scales.includes(1000)) {
                const b = randomArrayElement([100, 1000]);

                return [a, b];
            }

            if (scales.includes(10)) {
                const b = 10;

                return [a, b];
            }

            if (scales.includes(100)) {
                const b = 100;

                return [a, b];
            }

            if (scales.includes(1000)) {
                const b = 1000;

                return [a, b];
            }
        }
    }

    static _genMultiplicationQuestion(numberGenerator) {
        const { topic, scale } = numberGenerator;

        const scaleKeys = Object.keys(scale);

        const randomTopic = randomArrayElement(scaleKeys);

        const scales = scale[randomTopic];

        const [number1, number2] = this._generateMultiplicationQuestion(
            randomTopic,
            scales,
        );

        let answer = Number(number1) * Number(number2);

        answer = round(answer, 6);

        return {
            topic,
            number1,
            number2,
            answer,
            randomTopic,
        };
    }

    static _generateDivisionQuestion(topicId, scales) {
        // decimal x decimal
        if (topicId === '1') {
            const opt1 = () => {
                const arr = range(1, 12, 10);

                const b = (randomArrayElement(arr) / 10).toFixed(1);

                const ab1 = randomIntFromRange(1, 12);

                const ab2 = randomArrayElement(arr) / 10;

                const ab = randomArrayElement([ab1, ab2]);

                const a = (ab * b).toLocaleString('en', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                });

                return [a, b];
            };

            const opt2 = () => {
                const arr = range(1, 12, 10);

                const b = (randomArrayElement(arr) / 100).toFixed(2);

                const ab1 = randomArrayElement(arr) / 10;

                const ab2 = randomIntFromRange(1, 12) / 100;

                const ab = randomArrayElement([ab1, ab2]);

                const a = (ab * b).toLocaleString('en', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 4,
                });

                return [a, b];
            };

            if (scales.includes(0.1) && scales.includes(0.01)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(0.1)) {
                return opt1();
            }

            if (scales.includes(0.01)) {
                return opt2();
            }
        }

        // decimal x whole number
        if (topicId === '2') {
            const opt1 = () => {
                const arr = range(1, 12, 10);

                const b = randomIntFromRange(1, 12).toString();

                const ab = randomArrayElement(arr) / 10;

                const a = (ab * b).toFixed(1);

                return [a, b];
            };

            const opt2 = () => {
                const b = randomIntFromRange(1, 12).toString();

                const ab = randomIntFromRange(1, 12) / 100;

                const a = (ab * b).toFixed(2);

                return [a, b];
            };

            if (scales.includes(0.1) && scales.includes(0.01)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(0.1)) {
                return opt1();
            }

            if (scales.includes(0.01)) {
                return opt2();
            }
        }

        // decimal x 0.1, 0.01, 0.001
        if (topicId === '3') {
            const opt1 = () => {
                const int = randomIntFromRange(1, 100);

                const a1 = int.toString();

                const a2 = (int / 10).toFixed(1);

                let a3 = int / 100;

                a3 = a3 === 1 ? a3.toString() : a3.toFixed(2);

                const a = randomArrayElement([a1, a2, a3]);

                const b = (1 / 10).toFixed(1);

                return [a, b];
            };

            const opt2 = () => {
                const int = randomIntFromRange(1, 100);

                const a1 = int.toString();

                const a2 = (int / 10).toFixed(1);

                let a3 = int / 100;

                a3 = a3 === 1 ? a3.toString() : a3.toFixed(2);

                let a4 = randomIntFromRange(1, 1000) / 1000;

                a4 = a4 === 1 ? a4.toString() : a4.toFixed(3);

                const a = randomArrayElement([a1, a2, a3, a4]);

                const b = (1 / 100).toFixed(2);

                return [a, b];
            };

            const opt3 = () => {
                const a1 = randomIntFromRange(1, 10).toString();

                const a2 = (randomIntFromRange(1, 100) / 10).toFixed(1);

                let a3 = randomIntFromRange(1, 100) / 100;

                a3 = a3 === 1 ? a3.toString() : a3.toFixed(2);

                let a4 = randomIntFromRange(1, 1000) / 1000;

                a4 = a4 === 1 ? a4.toString() : a4.toFixed(3);

                const a = randomArrayElement([a1, a2, a3, a4]);

                const b = (1 / 1000).toString();

                return [a, b];
            };

            if (
                scales.includes(0.1) &&
                scales.includes(0.01) &&
                scales.includes(0.001)
            ) {
                return randomArrayElement([opt1(), opt2(), opt3()]);
            }

            if (scales.includes(0.1) && scales.includes(0.01)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(0.1) && scales.includes(0.001)) {
                return randomArrayElement([opt1(), opt3()]);
            }

            if (scales.includes(0.01) && scales.includes(0.001)) {
                return randomArrayElement([opt2(), opt3()]);
            }

            if (scales.includes(0.1)) {
                return opt1();
            }

            if (scales.includes(0.01)) {
                return opt2();
            }

            if (scales.includes(0.001)) {
                return opt3();
            }
        }

        // decimal x 10, 100, 1000
        if (topicId === '4') {
            const opt1 = () => {
                const exclude = range(1, 9).map((n) => n * 10);

                const arr = range(1, 100, exclude);

                const int = randomArrayElement(arr);

                const a1 = int.toString();

                const a2 = (int / 10).toFixed(1);

                const a3 = (int / 100).toFixed(2);

                const a = randomArrayElement([a1, a2, a3]);

                const b = 10;

                return [a, b];
            };

            const opt2 = () => {
                const exclude = range(1, 9).map((n) => n * 100);

                const arr = range(1, 1000, exclude);

                const int = randomIntFromRange(1, 100);

                const a1 = randomArrayElement(arr).toString();

                const a2 = (int / 10).toFixed(1);

                const a3 = (int / 100).toFixed(2);

                const a = randomArrayElement([a1, a2, a3]);

                const b = 100;

                return [a, b];
            };

            const opt3 = () => {
                const a1 = randomIntFromRange(1, 999).toString();

                const a2 = (randomIntFromRange(1, 1000) / 10).toFixed(1);

                const a = randomArrayElement([a1, a2]);

                const b = (1000).toString();

                return [a, b];
            };

            if (
                scales.includes(10) &&
                scales.includes(100) &&
                scales.includes(1000)
            ) {
                return randomArrayElement([opt1(), opt2(), opt3()]);
            }

            if (scales.includes(10) && scales.includes(100)) {
                return randomArrayElement([opt1(), opt2()]);
            }

            if (scales.includes(10) && scales.includes(1000)) {
                return randomArrayElement([opt1(), opt3()]);
            }

            if (scales.includes(100) && scales.includes(1000)) {
                return randomArrayElement([opt2(), opt3()]);
            }

            if (scales.includes(10)) {
                return opt1();
            }

            if (scales.includes(100)) {
                return opt2();
            }

            if (scales.includes(1000)) {
                return opt3();
            }
        }
    }

    static _genDivisionQuestion(numberGenerator) {
        const { topic, scale } = numberGenerator;

        const taskIds = Object.keys(scale);

        const randomTopic = randomArrayElement(taskIds);

        const scales = scale[randomTopic];

        const [number1, number2] = this._generateDivisionQuestion(
            randomTopic,
            scales,
        );

        const answer = round(number1 / number2, 4);

        return {
            topic,
            number1,
            number2,
            answer,
            randomTopic,
        };
    }

    static _genNumberLineQuestion(numberGenerator) {
        const { type, topic, scale, intervals, hideSomeNumber } =
            numberGenerator;

        const numbersToGenerate = 5;

        const interval = intervals[randomIntFromRange(0, intervals.length - 1)];

        const decimals = getDecimalPlaces(interval);

        const dotLocation = randomIntFromRange(1, numbersToGenerate);

        const arr = range(
            interval,
            scale - numbersToGenerate * interval,
            false,
            interval,
        );

        const nr1 = arr.length ? randomArrayElement(arr).toFixed(decimals) : 0;

        const numbers = { nr1 };

        numbers.nr2 = (+numbers.nr1 + interval).toFixed(decimals);

        numbers.nr3 = (+numbers.nr2 + interval).toFixed(decimals);

        numbers.nr4 = (+numbers.nr3 + interval).toFixed(decimals);

        numbers.nr5 = (+numbers.nr4 + interval).toFixed(decimals);

        const answer = numbers[`nr${dotLocation}`];

        numbers[`nr${dotLocation}`] = '?';

        if (hideSomeNumber) {
            const numbersToHide = randomIntFromRange(1, 2);

            for (let i = 0; i < numbersToHide; i++) {
                let dotToHide = randomIntFromRange(1, numbersToGenerate);

                if (dotToHide === dotLocation) {
                    dotToHide =
                        dotToHide + 1 >= 5 ? dotToHide - 1 : dotToHide + 1;
                }

                numbers[`nr${dotToHide}`] = '-';
            }
        }

        return {
            type,
            topic,
            ...numbers,
            answer,
        };
    }

    static _createExponentNumbers(base, exponents = [], baseMax) {
        const randomExponent = randomArrayElement(exponents);

        // Set base range to 0.1...1 if exponent is 3
        if (randomExponent === 3) {
            baseMax = 1;

            const randomBase = randomFromRange(0.1, baseMax, 1);

            return [randomExponent, randomBase];
        }

        if (base === 1 || base === 0.1) {
            const baseMin = base / 10;

            const fixed = getDecimalPlaces(baseMin);

            const randomBase = randomFromRange(baseMin, baseMax, fixed);

            return [randomExponent, randomBase];
        }

        if (base === 2 || base === 0.2) {
            const baseMin = base / 20;

            const fixed = getDecimalPlaces(baseMin);

            const randomBase = randomFromRange(baseMin, baseMax, fixed);

            return [randomExponent, randomBase];
        }
    }

    static _genExponentsQuestion(numberGenerator) {
        const { type, topic, exponents, base } = numberGenerator;

        const [randomExponent, randomBase] = this._createExponentNumbers(
            base,
            exponents,
            base,
        );

        let answer = Number(randomBase) ** Number(randomExponent);

        answer = round(answer, 6);

        return {
            type,
            topic,
            base: randomBase,
            exponent: randomExponent,
            answer,
        };
    }

    /*
     *  Format helpers
     */

    static _formatAdditionQuestion(question) {
        let { number1, number2 } = question;
        number1 = Number(number1).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(number1),
        });

        number2 = Number(number2).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(number2),
        });

        return `${number1} + ${number2}`;
    }

    static _formatSubtractionQuestion(question) {
        let { number1, number2 } = question;

        number1 = Number(number1).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(number1),
        });

        number2 = Number(number2).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(number2),
        });

        return `${number1} - ${number2}`;
    }

    static _formatComparingQuestion(question, calledIn) {
        let { number1, number2, nr1, nr2, answer, playerAnswer } = question;

        if (!number1 || number1 === 0) {
            number1 = nr1;

            number2 = nr2;
        }

        if (!answer) {
            answer = playerAnswer;
        }

        if (calledIn === 'report') {
            return `${nr1} ${answer} ${nr2}`;
        }

        return `${this._formatDecimalNumbers(
            number1,
        )} _ ${this._formatDecimalNumbers(number2)}`;
    }

    static _formatOrderingQuestion(question, calledIn) {
        const { number1, number2, number3, order } = question;

        const nr1String = this.formatNumber(number1);

        const nr2String = this.formatNumber(number2);

        const nr3String = this.formatNumber(number3);

        if (calledIn === 'inPhoneStudentViewExample') {
            return `
                <div class="drag-and-drop-example">
                    <div class="item">${nr1String}</div>
                    <div class="item">${nr2String}</div>
                    <div class="item">${nr3String}</div>
                </div>
            `;
        }

        if (calledIn === 'inGame') {
            return `
                <div class="margin-top-bottom-20">${
                    order === 'ascending'
                        ? this.t('host.create.arrangeNumbersAsc')
                        : this.t('host.create.arrangeNumbersDesc')
                }</div>
            `;
        }

        // Report
        // Example tasks
        const numbers = [nr1String, nr2String, nr3String];

        return `
            <div class="question--ordering">
                ${numbers.join('\xa0\xa0\xa0')}
            </div>
        `;
    }

    static _formatConversionQuestion(question, calledIn) {
        return FractionsTopic.formatQuestion(question, null, calledIn);
    }

    static _formatRoundingQuestion(question) {
        const { decimal, scale } = question;

        const number = Number(decimal).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(scale),
            maximumFractionDigits: 4,
        });

        const translationScale = scale.toString().split('.').join('');

        return `
            <span class="type-decimals-rounding text-center">
                ${this.t('host.create.rounding.roundToTheNearest')} ${this.t(
                    'host.create.rounding.' + translationScale,
                )} ${number}
            </span>
        `;
    }

    static _formatMultiplicationQuestion(question) {
        let { number1, number2, randomTopic } = question;

        if (randomTopic === '1' || randomTopic === '3' || randomTopic === '4') {
            number1 = Number(number1).toLocaleString(this.locale(), {
                minimumFractionDigits: 1,
            });
        } else {
            number1 = Number(number1).toLocaleString(this.locale());
        }
        if (randomTopic === '1' || randomTopic === '2' || randomTopic === '3') {
            number2 = Number(number2).toLocaleString(this.locale(), {
                minimumFractionDigits: 1,
            });
        } else {
            number2 = Number(number2).toLocaleString(this.locale());
        }

        return `${number1} x ${number2}`;
    }

    static _formatDivisionQuestion(question) {
        let { number1, number2, randomTopic } = question;

        number1 = Number(number1).toLocaleString(this.locale(), {
            minimumFractionDigits: 1,
        });

        if (randomTopic === '1' || randomTopic === '3') {
            number2 = [10, 100, 1000].includes(+number2)
                ? number2
                : Number(number2).toLocaleString(this.locale(), {
                      minimumFractionDigits: 1,
                  });
        } else {
            number2 = [10, 100, 1000].includes(+number2)
                ? number2
                : Number(number2).toLocaleString(this.locale());
        }

        return `${number1} ÷ ${number2}`;
    }

    static _formatNumberLineQuestion(question, calledIn) {
        const textColor = ['report', 'spLiveAnswer'].includes(calledIn)
            ? 'black'
            : 'white';

        const inGame = calledIn === 'inGame';

        let { nr1, nr2, nr3, nr4, nr5 } = question;

        const numberLength = nr1
            ? nr1.toString().length - 1
            : nr2
              ? nr2.toString().length - 1
              : 2;

        const startOfDigits = 30 - numberLength;

        let digitsX = inGame
            ? [
                  startOfDigits,
                  70 - numberLength,
                  110 - numberLength,
                  150 - numberLength,
                  190 - numberLength,
              ]
            : [startOfDigits, 70, 110, 150, 190];

        digitsX = digitsX.map((x) => x + numberLength);

        const shouldDrawDot = (num) => {
            const number = num !== undefined && num !== null && !isNaN(num);

            if (number || num === '?') {
                return isNaN(num) ? num : (+num).toLocaleString(this.locale());
            } else {
                return '';
            }
        };

        const textY = (order) => {
            return order % 2 === 0 ? '97' : '82';
        };

        return `
            ${
                inGame
                    ? '<h3 class="margin-top-bottom">' +
                      this.t('player.numberLine.findNumber') +
                      '</h3>'
                    : ''
            }
            <svg
                height="100"
                width="225"
                viewBox="0 0 225 100"
                font-size="${inGame ? '1.1rem' : '1rem'}"
            >
                <path d="M10 50 L210 50 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M30 35 L30 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M70 35 L70 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M110 35 L110 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M150 35 L150 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M190 35 L190 65 Z" stroke="${textColor}" stroke-width="3"/>
                <text
                    text-anchor="middle"
                    x="${digitsX[0]}"
                    y="${textY(1)}"
                    fill="${inGame && nr1 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(nr1)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[1]}"
                    y="${textY(2)}"
                    fill="${inGame && nr2 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(nr2)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[2]}"
                    y="${textY(3)}"
                    fill="${inGame && nr3 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(nr3)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[3]}"
                    y="${textY(4)}"
                    fill="${inGame && nr4 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(nr4)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[4]}"
                    y="${textY(5)}"
                    fill="${inGame && nr5 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(nr5)}
                </text>
                Sorry, your browser does not support inline SVG.
            </svg>
        `;
    }

    static _formatExponentsQuestion(question) {
        const { base, exponent } = question;

        return `<div>${this._formatDecimalNumbers(
            base,
        )}<sup>${exponent}</sup></div>`;
    }

    static _formatDecimalNumbers(rawNumber) {
        return Number(rawNumber).toLocaleString(this.locale(), {
            minimumFractionDigits: getDecimalPlaces(rawNumber),
        });
    }
}
