import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { App } from '@capacitor/app';

export const isLandscape = async () =>
    (await ScreenOrientation.orientation()).type.match(/landscape/) ||
    screen.orientation?.type.match(/landscape/);

export async function handleStatusBarOnOrientationChange(isAndroid) {
    isAndroid &&
        (await StatusBar.setBackgroundColor({ color: '#a166f0' }).catch((e) =>
            console.log('setBackground error', e),
        )); // a constant for color?
    await StatusBar.setStyle({ style: Style.Dark });
    isAndroid &&
        (await StatusBar.setOverlaysWebView({ overlay: true }).catch((e) =>
            console.log('setOverlaysWebView error', e),
        )); // a constant for color?;
    await ScreenOrientation.unlock();
    const hideStatusBarOnLandscape = async () => {
        if (await isLandscape()) {
            await StatusBar.hide();
        } else {
            await StatusBar.show();
            // await StatusBar.setStyle({ style: Style.Dark });
            isAndroid &&
                (await StatusBar.setOverlaysWebView({ overlay: true }));
        }
    };

    await hideStatusBarOnLandscape();
    App.addListener('resume', async () => await hideStatusBarOnLandscape());
    ScreenOrientation.addListener('screenOrientationChange', async (e) => {
        console.log('=== Screen Orientation Changed', e);
        await hideStatusBarOnLandscape();
    });
}
