<template>
    <div v-if="productStripeInfo" class="content">
        <Stripe
            :hide-notes="true"
            :unauthorized-account="unauthorizedAccount"
            :product-stripe-info="productStripeInfo"
            :trial-days="trialDays"
            :discount="canApplyDiscount ? discount : null"
        />

        <div class="price-info">
            {{ priceText }}
        </div>

        <div class="cancel-anytime-text">
            {{ $t('homeGame.cancelAnytimeText') }}
            <a class="tos" :href="$getTermsOfUseLink" target="_blank">
                {{ $t('footer.termsOfUse') }}
            </a>
        </div>
        <div class="stripe-container">
            <a href="https://stripe.com" target="_blank">
                <img src="/images/stripe.svg" alt="Powered by Stripe" />
            </a>
        </div>
    </div>
</template>
<script>
import Stripe from '@/student/widgets/Stripe.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    components: { Stripe },
    props: {
        unauthorizedAccount: { type: Boolean, default: false },
        productStripeInfo: { type: Object, required: true },
        trialDays: { type: Number, default: null },
        discount: { type: Object, required: false },
    },
    emits: ['closed'],
    computed: {
        ...mapGetters(['user']),
        isLifetimeSubscription() {
            return !!this.productStripeInfo?.metadata?.lifetimeSubscription;
        },
        numberOfMonths() {
            return this.productStripeInfo.recurring.interval === 'year'
                ? this.productStripeInfo.recurring.interval_count * 12
                : this.productStripeInfo.recurring.interval_count;
        },
        isWeekly() {
            return this.productStripeInfo?.recurring?.interval === 'week';
        },
        monthsText() {
            return this.numberOfMonths >= 2 ? 'months' : 'month';
        },
        isFacebookSignup() {
            return this.user?.flags?.parentSignupFacebook;
        },
        paymentAmount() {
            return this.isFacebookSignup
                ? 'Free 7 day trial and then billed'
                : 'Payment amount';
        },
        priceText() {
            if (this.isWeekly) {
                return `${this.paymentAmount}: ${this.getTotalAmount(
                    this.productStripeInfo,
                )} for 1 week ($${this.getTotalAmount(
                    this.productStripeInfo,
                )}/week)`;
            }

            return this.isLifetimeSubscription
                ? `${this.paymentAmount}: ${this.getTotalAmount(
                      this.productStripeInfo,
                  )} for lifetime`
                : `${this.paymentAmount}: ${this.getTotalAmount(
                      this.productStripeInfo,
                  )} for ${this.numberOfMonths} ${
                      this.monthsText
                  } ($${this.getPerMonth(this.productStripeInfo)}/mo)`;
        },
        canApplyDiscount() {
            return (
                this.discount?.metadata?.subscriptionIntervals
                    ?.split(',')
                    ?.includes(this.productStripeInfo?.recurring?.interval) &&
                moment(this.discount?.redeem_by * 1000).isAfter(moment())
            );
        },
        discountAmount() {
            return (
                Number(this.canApplyDiscount) *
                    this.discount?.percent_off *
                    0.01 || 1
            );
        },
    },
    methods: {
        closed() {
            this.$emit('closed');
        },
        getPerMonth(stripeItem) {
            const months =
                stripeItem.recurring.interval === 'year'
                    ? stripeItem.recurring.interval_count * 12
                    : stripeItem.recurring.interval_count;

            return (stripeItem.unit_amount / 100 / months)
                .toString()
                .match(/^\d+(?:\.\d{0,2})?/)[0];
        },
        getTotalAmount(stripeItem) {
            return ((stripeItem.unit_amount * this.discountAmount) / 100)
                .toString()
                .match(/^\d+(?:\.\d{0,2})?/)[0];
        },
    },
};
</script>
<style lang="scss" scoped>
.stripe-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    img {
        width: 100%;
        max-width: 160px;
    }

    @media screen and (min-width: $w-min-tablet) and (max-width: $w-max-desktop) {
        margin-top: 18px;
    }
}
.sub-cta {
    background: #14c1bf;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 18px 20px;
    height: 70px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
    font-size: 30px;
    font-weight: bold;
    border: none;
    color: #fff;

    @include mq($w-max-tablet, max) {
        font-size: 16px;
        height: 40px;
        width: 90%;
    }

    @media screen and (min-width: $w-min-tablet) and (max-width: $w-max-desktop) {
        width: 500px;
        height: 46px;
        padding: 13px 20px;
        font-size: 24px;
        line-height: 28px;
    }

    .text {
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
    }

    &:disabled {
        background-color: #bad9d8;
        pointer-events: none;
    }

    @include mq($w-min-desktop, min) {
        max-width: 600px;
        margin: auto;
    }

    &:hover {
        background-color: darken($color: #14c1bf, $amount: 8);
        cursor: pointer;
    }
}
.price-info {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 50px rgba(255, 245, 0, 0.5);
    margin-bottom: 1rem;

    @include mq($w-max-tablet, max) {
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (min-width: $w-min-tablet) and (max-width: $w-max-desktop) {
        font-size: 20px;
        line-height: 22px;
    }
}
.cancel-anytime-text {
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 50px rgba(255, 245, 0, 0.5);
    margin: 40px 15px 15px;
    font-size: 20px;

    @include mq($w-max-tablet, max) {
        font-size: 14px;
        line-height: 16px;
        margin-top: 1rem;
    }

    @media screen and (min-width: $w-min-tablet) and (max-width: $w-max-desktop) {
        margin: 14px;
        font-size: 18px;
        line-height: 20px;
    }

    .tos {
        color: #fff;
        text-decoration: underline;
    }

    a:active,
    a:visited,
    a:hover {
        color: white;
    }
}
@include mq($w-max-phone, max) {
    .form-wrapper {
        .credit-card-form {
            padding: 8px;

            .input-field {
                margin-bottom: 8px;

                .name {
                    margin-bottom: 5px;
                    font-size: 16px;
                }

                input {
                    height: 38px;
                }

                .error {
                    margin-top: 2px;
                    margin-bottom: 4px;
                }

                .note {
                    margin-top: 2px;
                }
            }

            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 14px;
            }
        }
    }
}
</style>
