import {
    DEFAULT_LANGUAGE,
    LANGUAGES,
    SUPPORTED_LANGUAGES,
} from '@/lang/languages';

export default {
    getCurrentLanguage({ language }) {
        return language;
    },
    getCurrentLanguageDetails({ language }) {
        return LANGUAGES[language];
    },
    getOtherLanguages({ language }) {
        // eslint-disable-next-line
        const { [language]: _, ...otherLanguages } = LANGUAGES;

        return otherLanguages;
    },
    getAllLanguages() {
        return LANGUAGES;
    },
    getClientBrowserLanguage() {
        const browserLanguage = navigator.language;

        const languageCode = browserLanguage && browserLanguage.split('-')[0];

        if (SUPPORTED_LANGUAGES.includes(languageCode)) {
            return languageCode;
        }

        return DEFAULT_LANGUAGE;
    },
};
