/* global mixpanel */
export const HOST_SPPLAYLIST_MP_EVENTS = {
    SPPLAYLIST_CREATED: 'createdSPPlaylist',
};

export const HOST_SPPLAYLIST_MP_METHODS = {
    createdSPPlaylist({ gameCode, taskTypes, deadline }) {
        mixpanel.track('Created Self Paced Playlist', {
            Gamecode: gameCode,
            'Number of skills': taskTypes.length,
            'List of topics': taskTypes,
            'Local timestamp': Date.now(),
            Deadline: deadline,
        });
    },
};
