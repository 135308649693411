import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    randomArrayElement,
    randomIntFromRange,
    round,
} from '@/core/helpers/utils';
import { i18n } from '@/lang/translator';
import store from '@/store/index';

/**
 * @extends TopicsBaseClass
 */
export default class MoneyTopic extends TopicsBaseClass {
    static code = 'TYPE_MONEY';
    static icon = '';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.money';
    static sampleQuestionCount = 3;
    static coinMap = {
        '5cent': 0.05,
        '10cent': 0.1,
        '20cent': 0.2,
        '50cent': 0.5,
        penny: 0.01,
        nickel: 0.05,
        dime: 0.1,
        quarter: 0.25,
        'half-dollar': 0.5,
    };

    static coinMapInverse = (function () {
        const ret = {};

        for (const key in MoneyTopic.coinMap) {
            ret[MoneyTopic.coinMap[key]] = key;
        }

        return ret;
    })();

    static getNumberGeneratorName(gameInfo) {
        const {
            numberGenerator: { topic, subtopic, scale },
        } = gameInfo;

        const topicName = i18n.t(`host.create.money.${topic}`);

        if (topic === 'identify') {
            const scaleString = scale.map((s) => `...${s}`).join(', ');

            return `${topicName}: ${subtopic} ${scaleString}`;
        }

        if (['multiplication', 'division', 'conversions'].includes(topic)) {
            return `${topicName}: ${subtopic}`;
        }
    }

    static _createUnits(scale, config) {
        const { bills, coins } = config;

        const coinValues = Object.values(coins);

        const values = {};

        Object.entries(coins).forEach(([key, val]) => {
            values[val] = key;
        });

        const MAX_BILLS = 4;

        const MAX_COINS = 6;

        const minBillValue = bills.sort((a, b) => a - b)[0];

        const billScale = scale >= minBillValue ? scale - 1 : scale;

        const billCount =
            scale <= minBillValue ? 0 : randomIntFromRange(0, MAX_BILLS);

        const billArray = billCount
            ? bills.filter((bill) => bill <= scale)
            : [];

        const billUnits = billCount
            ? this._getUnits(billScale, billCount, billArray)
            : {};

        const coinScale = Object.entries(billUnits)
            .map(([key, value]) => parseInt(key) * value)
            .reduce((acc, curr) => acc - curr, scale - 0.01);

        const minCoinCount = billCount ? 0 : 1;

        const coinCount = randomIntFromRange(minCoinCount, MAX_COINS);

        const coinArray = coinValues.filter((coin) => coin <= coinScale);

        const coinUnits = this._getUnits(coinScale, coinCount, coinArray);

        const newCoins = {};

        Object.entries(coinUnits).map(([key, val]) => {
            newCoins[MoneyTopic.coinMapInverse[key]] = val;
        });

        return [billUnits, newCoins];
    }

    // Builds an object with money units and the count of them
    static _getUnits(maxSum, count, unitArr, unitObj = {}) {
        const possibleUnits = unitArr.filter((unit) => unit <= maxSum);

        const unit = randomArrayElement(possibleUnits);

        if (!maxSum || !count || !unitArr.length || !unit) {
            return unitObj;
        }

        const unitCounter = unitObj[unit] + 1 || 1;

        const result = { ...unitObj, [unit]: unitCounter };

        return this._getUnits(maxSum - unit, count - 1, possibleUnits, result);
    }

    static _calcSum(unitObj, typeCurrency = 'bills') {
        let _unitObj = unitObj;

        if (typeCurrency === 'coins') {
            _unitObj = MoneyTopic.mapCoinNamesToCoinValues(unitObj);
        }

        return Object.entries(_unitObj)
            .map(([key, value]) => round(parseFloat(key) * value, 2))
            .reduce((acc, curr) => round(acc + curr, 2), 0);
    }

    static generateQuestion(numberGenerator) {
        const { type, topic, subtopic, scale, config } = numberGenerator;

        if (topic === 'identify') {
            const [bills, coins] = this._createUnits(scale[0], config);

            const sumOfBills = this._calcSum(bills);

            const sumOfCoins = this._calcSum(coins, 'coins');

            const totalSum = round(sumOfBills + sumOfCoins, 2);

            const answer = {
                mainUnits: parseInt(totalSum),
                fractionalUnits: parseInt(
                    (totalSum % 1).toFixed(2).substring(2),
                ),
            };

            return {
                type,
                topic,
                subtopic,
                symbol: config.symbol,
                scale: scale[0],
                bills,
                coins,
                answer,
            };
        }

        if (topic === 'multiplication') {
            const getAnswer = (nr1, nr2, nr3) => {
                const mainUnits =
                    nr2 * nr3 >= 100
                        ? nr1 * nr3 + parseInt((nr2 * nr3) / 100)
                        : nr1 * nr3;

                const fractionalUnits =
                    nr2 * nr3 >= 100
                        ? nr2 * nr3 - parseInt((nr2 * nr3) / 100) * 100
                        : nr2 * nr3;

                return { mainUnits, fractionalUnits };
            };

            const mainUnits = randomIntFromRange(0, 10);

            const fMin = mainUnits === 0 ? 1 : 0;

            const f1 = randomIntFromRange(fMin, 9);

            const f2 = f1 * 10;

            const fractionalUnits = randomArrayElement([f1, f2]);

            const multiplier = randomIntFromRange(1, 10);

            const answer = getAnswer(mainUnits, fractionalUnits, multiplier);

            return {
                type,
                topic,
                subtopic,
                symbol: config.symbol,
                mainUnits,
                fractionalUnits,
                multiplier,
                answer,
            };
        }

        if (topic === 'division') {
            const getAnswer = (nr1, nr2, nr3) => {
                const mainUnits = nr1 / nr3;

                const fractionalUnits = nr2 / nr3;

                return { mainUnits, fractionalUnits };
            };

            const divisor = randomIntFromRange(1, 10);

            const mainUnits = randomIntFromRange(0, 10) * divisor;

            const fMin = mainUnits === 0 ? 1 : 0;

            const fractionalUnits = randomIntFromRange(fMin, 10) * divisor;

            const answer = getAnswer(mainUnits, fractionalUnits, divisor);

            return {
                type,
                topic,
                subtopic,
                symbol: config.symbol,
                mainUnits,
                fractionalUnits,
                divisor,
                answer,
            };
        }
        if (topic === 'conversions') {
            const { conversions } = numberGenerator;

            const conversion = randomArrayElement(conversions);

            const currencyAndCentsFormula = (mainUnitsMax, centsMax) => {
                const mainUnits = randomIntFromRange(1, mainUnitsMax);

                const cents = randomIntFromRange(1, centsMax);

                const answer = {
                    cents: 100 * mainUnits + cents,
                };

                return { mainUnits, cents, answer };
            };

            const justCentsFormula = (mainUnitsMax, centsMax) => {
                const answerMainUnits = randomIntFromRange(1, mainUnitsMax);

                const answerCents = randomIntFromRange(1, centsMax);

                const cents = 100 * answerMainUnits + answerCents;

                const answer = {
                    mainUnits: answerMainUnits,
                    cents: answerCents,
                };

                return { cents, answer };
            };

            let values = {};

            if (conversion === 'fromCurrencyAndCents') {
                switch (scale[0]) {
                    case 10:
                        values = currencyAndCentsFormula(9, 99);
                        break;
                    case 50:
                        values = currencyAndCentsFormula(49, 99);
                        break;
                    case 100:
                        values = currencyAndCentsFormula(99, 99);
                        break;
                    case 1000:
                        values = currencyAndCentsFormula(999, 99);
                        break;
                }
            } else if (conversion === 'fromJustCents') {
                switch (scale[0]) {
                    case 10:
                        values = justCentsFormula(9, 99);
                        break;
                    case 50:
                        values = justCentsFormula(49, 99);
                        break;
                    case 100:
                        values = justCentsFormula(99, 99);
                        break;
                    case 1000:
                        values = justCentsFormula(999, 99);
                        break;
                }
            }

            return {
                type,
                topic,
                subtopic,
                answer: values.answer,
                data: {
                    conversion,
                    mainUnits: values.mainUnits,
                    cents: values.cents,
                    symbol: config.symbol,
                },
            };
        }
    }

    static formatQuestion(question, skill, calledIn) {
        const topic = question?.topic || skill?.numberGenerator?.topic;

        const symbol =
            question?.data?.symbol ||
            skill?.numberGenerator?.config?.symbol ||
            question.symbol;

        const locale = store.getters.getCurrentLanguage;

        if (topic === 'conversions') {
            const { mainUnits, cents, conversion } = question.data;

            const unitsString = `${MoneyTopic.getMainUnit(
                locale,
                mainUnits,
                symbol,
            )} ${MoneyTopic.getFractionalUnit(locale, cents)}`;

            if (calledIn === 'examples') {
                let answerString;

                if (conversion === 'fromCurrencyAndCents') {
                    answerString = `__${MoneyTopic.getFractionalUnitSymbol(
                        locale,
                    )}`;
                } else {
                    if (symbol === '€') {
                        answerString = `__${symbol} __${MoneyTopic.getFractionalUnitSymbol(
                            locale,
                        )}`;
                    } else {
                        answerString = `${symbol}__ __${MoneyTopic.getFractionalUnitSymbol(
                            locale,
                        )}`;
                    }
                }

                return `${unitsString} = ${answerString}`;
            }

            if (['playerStats', 'report', 'spLiveAnswer'].includes(calledIn)) {
                return unitsString;
            }

            return `${unitsString} = ?`;
        }

        if (
            ['playerStats', 'report'].includes(calledIn) ||
            (!calledIn && !question.bills)
        ) {
            const { answer, data } = question;

            if (topic === 'identify') {
                const { mainUnits, fractionalUnits } = answer;

                const mainUnit = MoneyTopic.getMainUnit(
                    locale,
                    mainUnits,
                    symbol,
                );

                const fractionalUnit = MoneyTopic.getFractionalUnit(
                    locale,
                    fractionalUnits,
                );

                return `${mainUnit} ${fractionalUnit}`;
            }
            if (topic === 'multiplication' || topic === 'division') {
                const { mainUnits, fractionalUnits, multiplier, divisor } =
                    data?.mainUnits ? data : question;

                const mainUnit = MoneyTopic.getMainUnit(
                    locale,
                    mainUnits,
                    symbol,
                );

                const fractionalUnit = MoneyTopic.getFractionalUnit(
                    locale,
                    fractionalUnits,
                );

                return topic === 'multiplication'
                    ? `${mainUnit} ${fractionalUnit} x ${multiplier}`
                    : `${mainUnit} ${fractionalUnit} : ${divisor}`;
            }
        }
        if (topic === 'identify') {
            const { subtopic } = question;

            const bills = question.bills || question.data?.bills;
            const coins = question.coins || question.data?.coins;

            const billStacks = bills
                ? Object.entries(bills).map(([key, value]) =>
                      Array(value).fill(key),
                  )
                : {};
            // new way, legacy is with decimal numbers (refactor because DB dot notation)
            let coinsToDisplay = coins
                ? MoneyTopic.mapCoinNamesToCoinValues(coins)
                : {};

            const coinStacks = Object.entries(coinsToDisplay).map(
                ([key, value]) => Array(value).fill(key.replace('.', '')),
            );

            const createBillStacks = () => {
                if (!bills || !Object.keys(bills).length) {
                    return '';
                }

                return `
                <div class="bills">
                    ${billStacks
                        .map((stack) => {
                            return `
                                <div class="bill-stack">
                                    ${stack
                                        .map((bill) => {
                                            const src =
                                                '/images/money/bills/' +
                                                `${subtopic}-${bill}` +
                                                '.jpg';
                                            return `
                                                <div class="bill">
                                                    <img src="${src}" alt='' />
                                                </div>
                                            `;
                                        })
                                        .join('')}
                                </div>
                            `;
                        })
                        .join('')}
                </div>`;
            };

            const createCoinStacks = () => {
                if (!coins || !Object.keys(coins).length) {
                    return '';
                }

                return `
                <div class="coins">
                    ${coinStacks
                        .map((stack) => {
                            return `
                                <div class="coin-stack">
                                    ${stack
                                        .map((coin) => {
                                            const src =
                                                '/images/money/coins/' +
                                                `${subtopic}-${coin}` +
                                                '.png';
                                            return `
                                                <div class="coin ${subtopic}-${coin}">
                                                    <img src="${src}" alt=''/>
                                                </div>
                                            `;
                                        })
                                        .join('')}
                                </div>
                            `;
                        })
                        .join('')}
                </div>`;
            };

            return `
                <div class="money">
                    ${createBillStacks()}
                    ${createCoinStacks()}
                </div>
            `;
        }

        if (topic === 'multiplication' || topic === 'division') {
            const { mainUnits, fractionalUnits, multiplier, divisor } =
                question;

            const mainUnit = MoneyTopic.getMainUnit(locale, mainUnits, symbol);

            const fractionalUnit = MoneyTopic.getFractionalUnit(
                locale,
                fractionalUnits,
            );

            return topic === 'multiplication'
                ? `${mainUnit} ${fractionalUnit} x ${multiplier}`
                : `${mainUnit} ${fractionalUnit} : ${divisor}`;
        }
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.topic === 'conversions') {
            if (question.data.conversion === 'fromJustCents') {
                return (
                    Number(answer.mainUnits) === question.answer.mainUnits &&
                    Number(answer.fractionalUnits) === question.answer.cents
                );
            }

            return Number(answer.fractionalUnits) === question.answer.cents;
        }

        return (
            Number(answer.mainUnits) === question.answer.mainUnits &&
            Number(answer.fractionalUnits) === question.answer.fractionalUnits
        );
    }

    static answerData(question, answer, skill) {
        const {
            bills,
            coins,
            symbol,
            mainUnits,
            fractionalUnits,
            multiplier,
            divisor,
        } = question;

        let data = {
            symbol,
            bills,
            coins,
            mainUnits,
            fractionalUnits,
            multiplier,
            divisor,
        };

        if (skill.topic === 'conversions') {
            data = { ...data, ...question.data };
        }

        return data;
    }

    static generatePlayerAnswerHtml(
        playerAnswer,
        generatePlayerAnswerHtml,
        question,
    ) {
        const { topic } = question;

        if (topic === 'identify') {
            const locale = store.getters.getCurrentLanguage;

            const { mainUnits, fractionalUnits: cents } = playerAnswer;

            const mainUnitSymbol = question.symbol;

            const mainUnit =
                question.data &&
                question.data.conversion === 'fromCurrencyAndCents'
                    ? ''
                    : MoneyTopic.getMainUnit(locale, mainUnits, mainUnitSymbol);

            const fractionalUnit = MoneyTopic.getFractionalUnit(locale, cents);

            return ` =
                <span class="wrong-answer">
                    ${mainUnit} ${fractionalUnit}
                </span>`;
        }

        return '<span class="wrong-answer"></span>';
    }

    static generateCorrectAnswerHtml(question, skill) {
        const topic = question?.topic || skill?.numberGenerator?.topic;

        if (topic === 'identify') {
            const locale = store.getters.getCurrentLanguage;

            const { mainUnits, fractionalUnits } = question.answer;

            const gameInfo = store.getters.getGameInfo;

            const mainUnitSymbol =
                gameInfo?.numberGenerator?.config?.symbol ||
                skill?.numberGenerator?.config?.symbol;

            const mainUnit =
                question.data &&
                question.data.conversion === 'fromCurrencyAndCents'
                    ? ''
                    : MoneyTopic.getMainUnit(locale, mainUnits, mainUnitSymbol);

            const fractionalUnit = MoneyTopic.getFractionalUnit(
                locale,
                fractionalUnits,
            );

            return `<span class="correct-answer">
                ${mainUnit} ${fractionalUnit}
            </span>`;
        }
        if (topic === 'conversions') {
            if (!question.answer.mainUnits && question.answer.cents) {
                return `<span class="correct-answer">${question.answer.cents}¢</span>`;
            }

            if (question.answer.mainUnits && question.answer.cents) {
                return `<span class="correct-answer">$${question.answer.mainUnits} ${question.answer.cents}¢</span>`;
            }
        }
        if (topic === 'multiplication' || topic === 'division') {
            const locale = store.getters.getCurrentLanguage;

            const { mainUnits, fractionalUnits } = question.answer;

            const symbol =
                question?.data?.symbol ||
                skill?.numberGenerator?.config?.symbol ||
                question.symbol;

            const mainUnit = MoneyTopic.getMainUnit(locale, mainUnits, symbol);

            const fractionalUnit = MoneyTopic.getFractionalUnit(
                locale,
                fractionalUnits,
            );

            return `<span class="correct-answer">${mainUnit} ${fractionalUnit}<span>`;
        }

        return '<span class="correct-answer"></span>';
    }

    static getAnswerInputType() {
        return ANSWER_INPUT_TYPE.MONEY;
    }

    static showArrowButtons() {
        return true;
    }

    static resetPlayerAnswer() {
        return {
            mainUnits: '',
            fractionalUnits: '',
        };
    }

    static mapCoinNamesToCoinValues(coins) {
        if (
            Object.keys(coins).some((key) =>
                [
                    'penny',
                    'nickel',
                    'dime',
                    'quarter',
                    'half-dollar',
                    '5cent',
                    '10cent',
                    '20cent',
                    '50cent',
                ].includes(key),
            )
        ) {
            const coinNamesToCoinValues = {};

            Object.entries(coins).forEach(
                ([key, val]) =>
                    (coinNamesToCoinValues[MoneyTopic.coinMap[key]] = val),
            );

            return coinNamesToCoinValues;
        }

        return coins;
    }

    static getFractionalUnitSymbol(locale) {
        switch (locale) {
            case 'et':
                return 's';
            default:
                return '¢';
        }
    }

    static getFractionalUnit(locale, number) {
        if (!number || Number(number) === 0) {
            return '';
        }

        const fractionalSymbol = MoneyTopic.getFractionalUnitSymbol(locale);

        return Number(number).toLocaleString(locale) + fractionalSymbol;
    }

    static getMainUnit(locale, mainUnits, symbol) {
        switch (locale) {
            case 'et':
                return (mainUnits || 0) + symbol;
            default:
                return symbol + (mainUnits || 0);
        }
    }
}
