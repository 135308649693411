import { randomArrayElement, randomIntFromRange } from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class EquationTopic extends TopicsBaseClass {
    static code = 'TYPE_EQUATION';
    static icon = '';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.equation';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        return this.getGameTopicTypeName();
    }

    static generateQuestion(numberGenerator) {
        if (!numberGenerator) {
            return;
        }

        const { type, config, scale } = numberGenerator;

        const letters = ['a', 'b', 'k', 'n', 's', 'm', 'y', 'z'];

        type !== 'TYPE_MULTIPLICATION' ? letters.push('x') : null;

        let nr1 = null;
        let nr2 = null;
        let nr3 = null;
        let q;
        let answer = null;

        const nrs = scale.split('..');

        const min = parseInt(nrs[0]);

        const max = parseInt(nrs[1]);

        const minuend = config.find(
            (conf) => conf === 'minuend' || conf === 'dividend',
        );

        const subtrahend = config.find(
            (conf) => conf === 'subtrahend' || conf === 'divisor',
        );

        if ((!minuend && subtrahend) || (minuend && !subtrahend)) {
            if (subtrahend) {
                nr1 = randomIntFromRange(min, max - 1);
                nr2 = randomArrayElement(letters);
            } else {
                nr1 = randomArrayElement(letters);
                nr2 = randomIntFromRange(min, max - 1);
            }
        } else {
            // 50% - 50% split
            if (Math.random() >= 0.5) {
                nr1 = randomIntFromRange(min, max - 1);

                nr2 = randomArrayElement(letters);
            } else {
                nr1 = randomArrayElement(letters);

                nr2 = randomIntFromRange(min, max - 1);
            }
        }
        switch (type) {
            case 'TYPE_ADDITION':
                nr3 = Number.isInteger(nr1)
                    ? randomIntFromRange(nr1, max)
                    : randomIntFromRange(nr2, max);

                answer = nr3 - (Number.isInteger(nr1) ? nr1 : nr2);

                break;
            case 'TYPE_SUBTRACTION':
                switch (scale) {
                    case '1..10':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            primary: true,
                            name: '1to10',
                            nr1: 1,
                            nr2: 10,
                            nr1r: 2,
                            nr2r: 1,
                            nr3r: 1,
                        });

                        break;
                    case '1..20':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            primary: true,
                            name: '1to20',
                            nr1: 1,
                            nr2: 20,
                            nr1r: 2,
                            nr2r: 1,
                            nr3r: 1,
                        });

                        break;
                    case '10..20':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            primary: true,
                            name: '10to20',
                            nr1: 10,
                            nr2: 20,
                            nr1r: 12,
                            nr2r: 1,
                            nr3r: 10,
                        });

                        break;
                    case '20..100':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            primary: true,
                            name: '20to100',
                            nr1: 20,
                            nr1r: 30,
                            nr2: 100,
                            nr2r: 10,
                            nr3r: 1,
                        });

                        break;
                    case '100..1000':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            primary: true,
                            name: '100to1000',
                            nr1: 100,
                            nr1r: 220,
                            nr2: 1000,
                            nr2r: 100,
                            nr3r: 100,
                        });

                        break;
                }

                if (Number.isInteger(nr1)) {
                    nr1 = q.number1;

                    answer = q.number2;

                    nr3 = q.answer;
                } else {
                    answer = q.number1;

                    nr2 = q.number2;

                    nr3 = q.answer;
                }
                break;
            case 'TYPE_MULTIPLICATION':
                switch (scale) {
                    case '1..10':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            name: '1to10',
                            nr1: 1,
                            nr2: 10,
                        });

                        break;
                    case '1..12':
                        q = this.generateTopicQuestion(type, {
                            type: 'scale',
                            name: '1to12',
                            nr1: 1,
                            nr2: 12,
                        });

                        break;
                }

                if (Number.isInteger(nr1)) {
                    nr1 = q.number1;

                    answer = q.number2;

                    nr3 = q.answer;
                } else {
                    answer = q.number1;

                    nr2 = q.number2;

                    nr3 = q.answer;
                }
                break;
            case 'TYPE_DIVIDING':
                q = this.generateTopicQuestion(type, {
                    type: 'dividing',
                    dividend: max,
                    divisor: null,
                });

                if (Number.isInteger(nr1)) {
                    nr1 = q.number1;

                    answer = q.number2;

                    nr3 = q.answer;
                } else {
                    answer = q.number1;

                    nr2 = q.number2;

                    nr3 = q.answer;
                }
                break;
        }

        return {
            number1: nr1,
            type,
            number2: nr2,
            number3: nr3,
            answer,
        };
    }

    static getAnswerInputType(numberGenerator) {
        return ANSWER_INPUT_TYPE.BOXED;
    }

    static formatBoxedQuestion(question, skill) {
        const letter =
            typeof question?.number1 === 'number'
                ? question?.number2
                : question?.number1;

        return `${letter} = `;
    }

    static formatQuestion(questionData, skill, calledIn) {
        let { number1, type, number2, number3 } = questionData;

        if (!number1) {
            number1 = questionData.nr1;
            number2 = questionData.nr2;
            number3 = questionData.nr3;
        }

        if (type === 'TYPE_EQUATION') {
            type = questionData.data.type;
        }

        return `${number1} ${this.getTopicIcon(type)} ${number2} = ${number3}`;
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        let char;

        if (typeof question.nr1 === 'string') {
            char = question.nr1;
        }

        if (typeof question.nr2 === 'string') {
            char = question.nr2;
        }

        if (typeof question.nr3 === 'string') {
            char = question.nr3;
        }

        if (typeof question.number1 === 'string') {
            char = question.number1;
        }

        if (typeof question.number2 === 'string') {
            char = question.number2;
        }

        if (typeof question.number3 === 'string') {
            char = question.number3;
        }

        return ` <br />${char} ${super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        )}`;
    }

    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        let char;

        if (typeof question.nr1 === 'string') {
            char = question.nr1;
        }

        if (typeof question.nr2 === 'string') {
            char = question.nr2;
        }

        if (typeof question.nr3 === 'string') {
            char = question.nr3;
        }

        if (typeof question.number1 === 'string') {
            char = question.number1;
        }

        if (typeof question.number2 === 'string') {
            char = question.number2;
        }

        if (typeof question.number3 === 'string') {
            char = question.number3;
        }

        return ` <br />${char} ${super.generatePlayerAnswerHtml(
            answer,
            numberGenerator,
            question,
        )}`;
    }

    static answerData(question, answer, skill) {
        return { type: question.type };
    }
}
