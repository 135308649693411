export default {
    namespaced: true,
    state: () => ({
        showChat: false,
        chatInitialized: false,
    }),
    getters: {
        isChatInitialized: (state) => {
            return state.chatInitialized;
        },
    },
    mutations: {
        showChat: (state, next) => {
            state.showChat = next;

            if (next) {
                window.$crisp.push(['do', 'chat:show']);
            } else {
                window.$crisp.push(['do', 'chat:hide']);
            }
        },
        chatInitialized: (state, next) => {
            state.chatInitialized = next;
        },
    },
    actions: {
        init: async () => {},
        setUser: (store) => {
            const profile = store.rootGetters.user;

            if (!profile) return;

            if (window.$crisp) {
                window.$crisp.push(['set', 'user:email', [profile.email]]);

                window.$crisp.push([
                    'set',
                    'user:nickname',
                    [`${profile.firstName} ${profile.lastName}`],
                ]);
            }
        },
        initCrispChat: () => {
            // if (store.getters.isChatInitialized) {
            //     return store.commit('showChat', true);
            // }
            //
            // return new Promise((resolve) => {
            //     window.$crisp = [];
            //
            //     window.CRISP_WEBSITE_ID =
            //         'ee26c066-f523-44d8-b45c-953107774013';
            //
            //     const profile = store.rootGetters.user;
            //
            //     if (!profile) return;
            //
            //     window.CRISP_TOKEN_ID = profile.email;
            //
            //     const chatScript = document.createElement('script');
            //
            //     chatScript.src = 'https://client.crisp.chat/l.js';
            //
            //     chatScript.async = 1;
            //
            //     chatScript.onload = () => {
            //         store.dispatch('setUser');
            //
            //         console.log('Crisp loaded: ' + window.CRISP_TOKEN_ID);
            //
            //         store.commit('chatInitialized', true);
            //
            //         store.commit('showChat', true);
            //
            //         resolve();
            //     };
            //
            //     const body = document.getElementsByTagName('body')[0];
            //
            //     body.appendChild(chatScript);
            // });
        },
        destroyChat: () => {
            if (window.$crisp) {
                window.$crisp.push(['do', 'chat:close']);

                window.$crisp.push(['do', 'chat:hide']);
            }
        },
        removeSession: () => {
            if (window.$crisp) {
                window.$crisp.push(['do', 'session:reset']);
            }
        },
        sendEvent: async (store, { eventName }) => {
            return new Promise((resolve) => {
                if (window.$crisp) {
                    console.log('Sent ' + eventName);

                    window.$crisp.push([
                        'set',
                        'session:event',
                        [`${eventName}`],
                    ]);
                }

                resolve();
            });
        },
    },
};
