/* global mixpanel */
export const MY_AVATAR_EVENTS = {
    AVATAR_CLICKED_MATH_PASS: 'avatarClickedMathPass',
};

export const MY_AVATAR_METHODS = {
    avatarClickedMathPass({ type, id }) {
        mixpanel.track('My Avatar: Clicked Get Math Pass', { [type]: id });
    },
};
