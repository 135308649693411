/* global mixpanel */
export const TEACHER_REFERRAL_MP_EVENTS = {
    TEACHER_REFERRAL_VIEWED_PAGE: 'viewedReferralPage',
    TEACHER_REFERRAL_FIRST_REWARD_CLAIMED: 'firstRewardClaimed',
    TEACHER_REFERRAL_NTH_REWARD_CLAIMED: 'nthRewardClaimed',
    TEACHER_REFERRAL_OPENED_COLLEAGUE_ACCORDION: 'openedColleagueAccordion',
    TEACHER_REFERRAL_SENT_REFERRAL_EMAIL_COLLEAGUE:
        'sentReferralEmailColleague',
    TEACHER_MATH_MADNESS_SENT: 'sentMathMadnessEmails',
    TEACHER_REFERRAL_COPIED_COLLEAGUE_REFERRAL_MESSAGE:
        'copiedColleagueReferralMessage',
    TEACHER_REFERRAL_EXPANDED_COLLEAGUE_EMAIL_MESSAGE:
        'expandedColleagueEmailMessage',
    TEACHER_REFERRAL_COPIED_PERSONAL_REFERRAL_LINK:
        'copiedPersonalReferralLink',
    TEACHER_REFERRAL_OPENED_PRINCIPAL_ACCORDION: 'openedPrincipalAccordion',
    TEACHER_REFERRAL_SENT_REFERRAL_EMAIL_PRINCIPAL:
        'sentReferralEmailToPrincipal',
    TEACHER_REFERRAL_COPIED_PRINCIPAL_REFERRAL_MESSAGE:
        'copiedPrincipalReferralMessage',
    TEACHER_REFERRAL_EXPANDED_PRINCIPAL_REFERRAL_MESSAGE:
        'expandedPrincipalEmailMessage',
    TEACHER_CLICKED_NATIVE_SHARE: 'clickedNativeShare',
    TEACHER_CLICKED_MESSENGER_SHARE: 'clickedMessengerShare',
    TEACHER_CLICKED_WHATSAPP_SHARE: 'clickedWhatsappShare',
    TEACHER_CLICKED_TWITTER_SHARE: 'clickedTwitterShare',
    TEACHER_CLICKED_FACEBOOK_SHARE: 'clickedFacebookShare',
    TEACHER_CLICKED_JOIN_FACEBOOK_GROUP_BUTTON:
        'clickedJoinFacebookGroupButton',
};

export const TEACHER_REFERRAL_MP_METHODS = {
    viewedReferralPage() {
        mixpanel.track('Viewed referral page');
    },
    firstRewardClaimed() {
        mixpanel.track('Referral - claimed swag');
    },
    nthRewardClaimed(rewardGoal) {
        const rewardGoalMap = {
            1: 'Swag',
            3: 'Stickers',
            5: '1 month of 99math Premium',
            10: 'Ambassador',
            20: 'Personalized 99math mug',
            50: 'Personalized 99math trophy',
        };
        mixpanel.track('Claimed referral reward', {
            Reward: rewardGoalMap[rewardGoal],
        });
    },
    openedColleagueAccordion() {
        mixpanel.track('Opened colleague accordion');
    },
    sentReferralEmailColleague({ NrOfEmails }) {
        mixpanel.track('Sent referral e-mail to colleague', {
            'Nr of emails submitted': NrOfEmails,
        });
    },
    sentMathMadnessEmails({ NrOfEmails }) {
        mixpanel.track('Sent math madness emails', {
            'Nr of emails submitted': NrOfEmails,
        });
    },
    copiedColleagueReferralMessage() {
        mixpanel.track('Copied colleague referral message');
    },
    expandedColleagueEmailMessage() {
        mixpanel.track('Expanded colleague e-mail message');
    },
    copiedPersonalReferralLink(location) {
        mixpanel.track('Copied personal referral link', {
            Location: location,
        });
    },
    openedPrincipalAccordion() {
        mixpanel.track('Opened principal accordion');
    },
    sentReferralEmailToPrincipal({ NrOfEmails }) {
        mixpanel.track('Sent referral e-mail to principal', {
            'Nr of emails submitted': NrOfEmails,
        });
    },
    copiedPrincipalReferralMessage() {
        mixpanel.track('Copied principal referral message');
    },
    expandedPrincipalEmailMessage() {
        mixpanel.track('Expanded principal e-mail message');
    },
    clickedNativeShare(location) {
        mixpanel.track('Clicked on More sharing options referral button', {
            Location: location,
        });
    },
    clickedMessengerShare(location) {
        mixpanel.track('Clicked on Messenger referral button', {
            Location: location,
        });
    },
    clickedWhatsappShare(location) {
        mixpanel.track('Clicked on Whatsapp referral button', {
            Location: location,
        });
    },
    clickedTwitterShare(location) {
        mixpanel.track('Clicked on Twitter referral button', {
            location,
        });
    },
    clickedFacebookShare(location) {
        mixpanel.track('Clicked on Facebook referral button', {
            Location: location,
        });
    },
    clickedJoinFacebookGroupButton() {
        mixpanel.track('Clicked to join Facebook group');
    },
};
