import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { CAPACITOR_CODE_BUNDLE_VERSION_KEY } from '@/capacitor/init';

export const setCapgoChannel = async (channel) => {
    await CapacitorUpdater.setChannel({ channel });
};

export const initCapacitorUpdates = async () => {
    const { bundle } = await CapacitorUpdater.notifyAppReady();
    localStorage.setItem(
        CAPACITOR_CODE_BUNDLE_VERSION_KEY,
        bundle.version || 'initial',
    );

    console.log('=== App is ready', bundle);

    for (const event of [
        'updateAvailable',
        'updateFailed',
        'download',
        'downloadComplete',
        'downloadFailed',
    ]) {
        CapacitorUpdater.addListener(event, (data) => {
            console.log('=== ', event, data);
        });
    }
};
