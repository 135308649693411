<template>
    <Action
        :color="ActionColor.Red"
        :pressed="pressed"
        :disabled="disabled"
        :image-url="`ui/game/${iconPx}/icon_close.png`"
        :size="size"
        @click="$emit('click')"
    />
</template>
<script>
import Action, { ActionSize, ActionColor } from './Action.vue';

export default {
    components: {
        Action,
    },
    props: {
        disabled: {
            default: false,
        },
        pressed: {
            default: false,
        },
        size: {
            type: String,
            default: ActionSize.Small,
        },
    },
    emits: ['click'],
    data() {
        return {
            ActionSize,
            ActionColor,
        };
    },
    computed: {
        iconPx() {
            return this.size === ActionSize.Small ? 24 : 32;
        },
    },
};
</script>
