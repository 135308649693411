import {
    randomArrayElement,
    randomIntFromRange,
    round,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class PerimeterAndAreaTopic extends TopicsBaseClass {
    static code = 'TYPE_PERIMETER_AND_AREA';
    static icon = 'x';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.perimeterAndArea';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const { numberGenerator } = skill;

        if (numberGenerator.shape !== 'rectangle') {
            return '1..10';
        } else {
            return (
                '- ' +
                numberGenerator.metrics
                    .map((m) =>
                        m === 'area'
                            ? `${this.t('host.create.perimeterAndArea.area')}${
                                  numberGenerator.scaleArea
                              }`
                            : `${this.t(
                                  'host.create.perimeterAndArea.perimeter',
                              )}${numberGenerator.scalePerimeter}`,
                    )
                    .join(', ')
            );
        }
    }

    static getAnswerInputType(numberGenerator) {
        return ANSWER_INPUT_TYPE.BOXED;
    }

    static generateQuestion(numberGenerator) {
        if (!numberGenerator) {
            return;
        }

        const trianglePerimeter = () => {
            let loop = true;

            while (loop) {
                const maxAngleSum = (n) => (n - 2) * 180;

                const A = {
                    x: randomIntFromRange(1, 10),
                    y: randomIntFromRange(1, 10),
                };

                const B = {
                    x: randomIntFromRange(1, 10),
                    y: randomIntFromRange(1, 10),
                };

                const C = {
                    x: randomIntFromRange(1, 10),
                    y: randomIntFromRange(1, 10),
                };

                AB = this.eucDist(A, B);

                BC = this.eucDist(B, C);

                CA = this.eucDist(C, A);

                // if same dot or all equal in one dimension (line) try again
                if (
                    [Math.round(AB), Math.round(BC), Math.round(CA)].includes(
                        0,
                    ) ||
                    Math.abs(Math.round(AB) - Math.round(BC)) > 4 ||
                    Math.abs(Math.round(BC) - Math.round(CA)) > 4 ||
                    Math.abs(Math.round(CA) - Math.round(AB)) > 4 ||
                    (A.x === B.x && B.x === C.x) ||
                    (A.y === B.y && B.y === C.y)
                ) {
                    continue;
                }

                AB2 = this.dist(A, B);

                BC2 = this.dist(B, C);

                CA2 = this.dist(C, A);

                let alpha = Math.acos((BC2 + CA2 - AB2) / (2 * BC * CA));

                let beta = Math.acos((AB2 + CA2 - AB2) / (2 * AB * CA));

                let gamma = Math.acos((AB2 + BC2 - CA2) / (2 * AB * BC));

                alpha = (alpha * 180) / Math.PI;

                beta = (beta * 180) / Math.PI;

                gamma = (gamma * 180) / Math.PI;

                const innerAngleSum = alpha + beta + gamma;

                const isStraightLine =
                    Math.abs(AB + BC - CA) < 1 ||
                    Math.abs(BC + CA - AB) < 1 ||
                    Math.abs(CA + AB - BC) < 1;

                if (innerAngleSum > maxAngleSum(3) || isStraightLine) {
                    continue;
                } else {
                    return {
                        number1: Math.round(AB),
                        number2: Math.round(BC),
                        number3: Math.round(CA),
                        metric: 'perimeter',
                        answer:
                            Math.round(AB) + Math.round(BC) + Math.round(CA),
                        data: {
                            A,
                            B,
                            C,
                        },
                        shape: 'triangle',
                    };
                }
            }
        };

        const { shape, shapes, metrics, scaleArea, scalePerimeter } =
            numberGenerator;

        let scale, metric, width, height;

        let AB, BC, CA, CD, DA, DE, EA;

        let AB2, BC2, CA2;

        let center = {};

        let A, B, C, D, E;

        if (shape === 'triangle') {
            metric = randomArrayElement(metrics);

            if (metric === 'perimeter') {
                return trianglePerimeter();
            }

            const formula1Or2 = randomIntFromRange(1, 2);

            let a, h;

            switch (scaleArea) {
                case '1..10':
                    a = randomIntFromRange(1, 10);

                    h = randomIntFromRange(1, 5) * 2;

                    break;
                case '1..12':
                    a = randomIntFromRange(1, 12);

                    h = randomIntFromRange(1, 6) * 2;

                    break;
                case '1..20':
                    a = randomIntFromRange(1, 20);

                    h = randomIntFromRange(1, 10) * 2;

                    break;
            }
            if (formula1Or2 === 2) {
                const temp = h;

                h = a;

                a = temp;
            }

            return {
                metric: 'area',
                answer: (a * h) / 2,
                data: {
                    a,
                    h,
                },
                shape: 'triangle',
            };
        } else if (shape === 'rectangle') {
            metric = randomArrayElement(metrics);

            if (metric === 'area') {
                scale = scaleArea;
            }

            if (metric === 'perimeter') {
                scale = scalePerimeter;
            }

            const scaleVars = scale
                .replace(/[()]/g, '')
                .split('x')
                .map((side) => side.split('..').map((n) => parseInt(n)));

            const minA = scaleVars[0][0];

            const maxA = scaleVars[0][1];

            const minB = scaleVars[1][0];

            const maxB = scaleVars[1][1];

            A = randomIntFromRange(minA, maxA);

            B = randomIntFromRange(minB, maxB);

            return {
                number1: A,
                number2: B,
                metric,
                answer: metric === 'area' ? A * B : 2 * (A + B),
                shape,
            };
        } else {
            const selectShape = randomArrayElement(shapes);

            let i = 1;

            switch (selectShape) {
                case 'triangle':
                    return trianglePerimeter();
                case 'tetragon':
                    // this center we get max 10 rounded side
                    width = 14;

                    height = 14;

                    center = {
                        x: width / 2,
                        y: height / 2,
                    };

                    A = {
                        x: center.x,
                        y: center.y - randomIntFromRange(1, 6),
                    };

                    B = {
                        x: center.x + randomIntFromRange(1, 7),
                        y: center.y,
                    };

                    C = {
                        x: center.x,
                        y: center.y + randomIntFromRange(1, 7),
                    };

                    D = {
                        x: center.x - randomIntFromRange(1, 6),
                        y: center.y,
                    };

                    AB = this.eucDist(A, B);

                    BC = this.eucDist(B, C);

                    CD = this.eucDist(C, D);

                    DA = this.eucDist(D, A);

                    return {
                        number1: Math.round(AB),
                        number2: Math.round(BC),
                        number3: Math.round(CD),
                        number4: Math.round(DA),
                        metric: 'perimeter',
                        answer:
                            Math.round(AB) +
                            Math.round(BC) +
                            Math.round(CD) +
                            Math.round(DA),
                        data: {
                            A,
                            B,
                            C,
                            D,
                        },
                        shape: 'tetragon',
                    };
                case 'pentagon':
                    // this center we get max 10 rounded side
                    width = 12;

                    height = 12;

                    // splits
                    const x1 = width / 3;

                    const x2 = (width / 3) * 2;

                    const y1 = width / 3;

                    const y2 = (width / 3) * 2;

                    center = {
                        x: width / 2,
                        y: height / 2,
                    };

                    while (i < 15) {
                        i++;

                        A = {
                            x: randomIntFromRange(x1, x2),
                            y: randomIntFromRange(0, y1),
                        };

                        B = {
                            x: randomIntFromRange(0, x1),
                            y: randomIntFromRange(y1, y2),
                        };

                        C = {
                            x: randomIntFromRange(0, x1),
                            y: randomIntFromRange(y2, height),
                        };

                        D = {
                            x: randomIntFromRange(x2, width),
                            y: randomIntFromRange(y2, height),
                        };

                        E = {
                            x: randomIntFromRange(x2, width),
                            y: randomIntFromRange(y1, y2),
                        };

                        AB = this.eucDist(A, B);

                        BC = this.eucDist(B, C);

                        CD = this.eucDist(C, D);

                        DE = this.eucDist(D, E);

                        EA = this.eucDist(E, A);

                        const maxLenghtSide = 10;

                        if (
                            [AB, BC, CD, DE, EA].find(
                                (dist) => dist > maxLenghtSide,
                            )
                        ) {
                            continue;
                        }

                        return {
                            number1: Math.round(AB),
                            number2: Math.round(BC),
                            number3: Math.round(CD),
                            number4: Math.round(DE),
                            number5: Math.round(EA),
                            metric: 'perimeter',
                            answer:
                                Math.round(AB) +
                                Math.round(BC) +
                                Math.round(CD) +
                                Math.round(DE) +
                                Math.round(EA),
                            data: {
                                A,
                                B,
                                C,
                                D,
                                E,
                            },
                            shape: 'pentagon',
                        };
                    }

                    break;
            }
        }

        return null;
    }

    static formatBoxedQuestion(question, skill) {
        const metricletter =
            question?.metric === 'perimeter'
                ? this.t('host.create.perimeterAndArea.P')
                : this.t('host.create.perimeterAndArea.A');

        return `${metricletter} = `;
    }

    static formatQuestion(question, skill, calledIn) {
        return this.drawShape(skill, question, calledIn);
    }

    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        if (question.shape !== 'triangle')
            return super.generatePlayerAnswerHtml(
                answer,
                numberGenerator,
                question,
            );

        const area = this.t('host.create.perimeterAndArea.A');

        const perim = this.t('host.create.perimeterAndArea.P');

        return question.metric === 'area'
            ? `${area} = <span class="wrong-answer">${answer}</span>`
            : `${perim} = <span class="wrong-answer">${answer}</span>`;
    }

    /**
     *    Helpers
     */
    static eucDist(A, B) {
        let deltaX = A.x - B.x;

        let deltaY = A.y - B.y;

        return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    }

    static dist(A, B) {
        let deltaX = A.x - B.x;

        let deltaY = A.y - B.y;

        return deltaX * deltaX + deltaY * deltaY;
    }

    static relativeLength(axis, sideLength, lengths) {
        const side = lengths.a;

        const height = lengths.h;

        if (side === height) {
            return sideLength;
        }

        const sideIsLonger = side > height;

        const sideHeightDiff = sideIsLonger
            ? round(side / height, 2)
            : round(height / side, 2);

        switch (axis) {
            case 'y':
                return sideIsLonger
                    ? (sideLength + (sideHeightDiff / sideLength) * 100) / 2
                    : sideLength;
            case 'x':
                return sideIsLonger
                    ? sideLength
                    : (sideLength - (sideHeightDiff / sideLength) * 100) / 2;
            default:
                return sideLength;
        }
    }

    static drawShape(skill, question, calledIn) {
        const globalScale = calledIn === 'inGame' ? 2 : 1;

        const textColor = ['playerStats', 'report', 'spLiveAnswer'].includes(
            calledIn,
        )
            ? 'black'
            : 'white';

        const numberGenerator = skill.numberGenerator;

        let {
            number1,
            number2,
            number3,
            number4,
            number5,
            metric,
            shape,
            data,
        } = question;

        if (!number1) {
            number1 = question.nr1;
            number2 = question.nr2;
            number3 = question.nr3;
            number4 = question.nr4;
            number5 = question.nr5;
        }

        if (shape === 'rectangle') {
            // drawing rectangles needs to be proportionate. Between 10 and 20
            const padding = 30;

            const reSize =
                (number1 <= 5 && number2 <= 5 ? 8 : 2.5) * globalScale;

            const sideA = number1 * reSize;

            const sideB = number2 * reSize;

            const canvasWidth = sideA + padding * 2;

            const canvasHeight = sideB + padding * 2;

            const centerX = canvasWidth / 2;

            const centerY = canvasHeight / 2;

            return `<svg class="perimeter-and-area-shape"  width="${canvasWidth}" height="${
                canvasHeight + 20
            }" viewBox="0 0 ${canvasWidth} ${canvasHeight + 20}">
            <rect class="rectangle" width="${sideA}" height="${sideB}" x="${padding}" y="${padding}"
            style="fill:#BE88F4;stroke="none" />
            <text x="${padding / 2}" y="${centerY}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number2}</text>
            <text x="${canvasWidth - padding / 2}" y="${centerY}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number2}</text>

            <text x="${centerX}" y="${canvasHeight - padding / 2}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number1}</text>
            <text x="${centerX}" y="${padding / 2}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number1}</text>
            ${
                calledIn !== 'inGame'
                    ? `<text x="${canvasWidth / 2 - 15}" y="${
                          canvasHeight + 10
                      }"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${
                        metric === 'perimeter'
                            ? `${this.t('host.create.perimeterAndArea.P')} = `
                            : `${this.t('host.create.perimeterAndArea.A')} = `
                    }</text>
                    <rect x="${canvasWidth / 2 + 5}" y="${
                        canvasHeight - 5
                    }" width="20" height="20" rx="4" fill="#E5E5E5"/>`
                    : ''
            }
            Sorry, your browser does not support inline SVG.
            </svg>`;
        }

        if (shape === 'triangle') {
            const scale =
                metric === 'area' && calledIn === 'inGame'
                    ? 4 * globalScale
                    : 7 * globalScale;

            const padding = 20;

            const svgHeight = scale * 10 + padding * 2 + 20;

            const svgWidth = scale * 10 + padding * 2;

            const metricSymbol =
                metric === 'area'
                    ? this.t('host.create.perimeterAndArea.A')
                    : this.t('host.create.perimeterAndArea.P');

            if (metric === 'area') {
                const getPointsOfTriangle = (scale, typeOfTriangle) => {
                    const TOP_POSITION = 20;

                    if (typeOfTriangle === 'right') {
                        const bottomPosition = 10 * scale + TOP_POSITION;

                        const posX = this.relativeLength(
                            'x',
                            bottomPosition,
                            data,
                        );

                        const posY = this.relativeLength(
                            'y',
                            TOP_POSITION,
                            data,
                        );

                        const A = {
                            x: TOP_POSITION + 3,
                            y: TOP_POSITION + (posY > 45 ? 45 : posY),
                        };

                        const B = {
                            x: TOP_POSITION + 3,
                            y: bottomPosition,
                        };

                        const C = {
                            x: B.x + posX,
                            y: bottomPosition,
                        };

                        return [A, B, C];
                    }

                    if (typeOfTriangle === 'acute') {
                        const bottomPosition = 10 * scale + TOP_POSITION;

                        const posX = this.relativeLength(
                            'x',
                            bottomPosition,
                            data,
                        );

                        const posY = this.relativeLength(
                            'y',
                            TOP_POSITION,
                            data,
                        );

                        const A = {
                            x: TOP_POSITION * 2,
                            y: TOP_POSITION + posY,
                        };

                        const B = {
                            x: TOP_POSITION + 3,
                            y: bottomPosition,
                        };

                        const C = {
                            x: B.x + posX,
                            y: bottomPosition,
                        };

                        return [A, B, C];
                    }

                    if (typeOfTriangle === 'obtuse') {
                        const bottomPosition = 10 * scale + TOP_POSITION;

                        const posY = this.relativeLength(
                            'y',
                            TOP_POSITION,
                            data,
                        );

                        const A = {
                            x: TOP_POSITION + 3,
                            y: TOP_POSITION + posY,
                        };

                        const B = {
                            x: TOP_POSITION + 10,
                            y: bottomPosition,
                        };

                        const posX = this.relativeLength(
                            'x',
                            bottomPosition - 20 - B.x,
                            data,
                        );

                        const C = {
                            x: B.x + posX,
                            y: bottomPosition,
                        };

                        return [A, B, C];
                    }

                    throw new TypeError('Invalid type of triangle');
                };

                const typesOfTriangles = ['right', 'acute', 'obtuse'];

                const typeOfTriangle = randomArrayElement(typesOfTriangles);

                const [A, B, C] = getPointsOfTriangle(scale, typeOfTriangle);

                const bottomLeftCorner = 10 * scale + padding;

                // prettier-ignore
                return `
                <div>
                    <svg
                        class="triangle perimeter-and-area-shape"
                        height="${svgHeight}"
                        width="${svgWidth}"
                        viewBox="0 0 ${svgHeight} ${svgWidth}"
                    >
                        <text
                            x="7"
                            y="${(svgHeight) / 2}"
                            font-family="sans-serif"
                            font-size="1rem"
                            text-anchor="middle"
                            fill="${textColor}"
                        >
                            ${data.h}
                        </text>
                        <text
                            x="${svgWidth / 3}"
                            y="${svgHeight - 20}"
                            font-family="sans-serif"
                            font-size="1rem"
                            text-anchor="middle"
                            fill="${textColor}"
                        >
                            ${data.a}
                        </text>
                        ${
                            typeOfTriangle !== 'right'
                                ? `<line
                                    x1="${padding}"
                                    x2="${C.x < 40 ? 40 : C.x}"
                                    y1="${svgHeight - padding * 2}"
                                    y2="${svgHeight - padding * 2}"
                                    stroke="black"
                                />
                                <line
                                    x1="${padding}"
                                    x2="${padding}"
                                    y1="${A.y}"
                                    y2="${svgHeight - padding * 2}"
                                    stroke="black"
                                />`
                                : ''
                        }
                        <polygon
                            points="${A.x},${A.y} ${B.x},${B.y} ${C.x},${C.y}"
                            style="fill:#BE88F4;stroke:#BE88F4;stroke-width:2"
                        ></polygon>
                        ${
                            typeOfTriangle === 'right'
                                ? `<path
                                        d="M ${padding},${bottomLeftCorner - padding}
                                            A ${padding},${padding} 0 0 1 ${padding * 2},${bottomLeftCorner}"
                                        fill="none"
                                        stroke="white"
                                        stroke-width="2"
                                    />
                                    <circle
                                        cx="${padding + 9}"
                                        cy="${bottomLeftCorner - (padding / 2) + 2}"
                                        r="2" fill="white"
                                    />`
                                : ''
                        }
                        ${ calledIn === 'examples' || calledIn === 'inPhoneStudentViewExample'
                            ? `
                                <text
                                    x="${(scale * 10 + padding * 2) / 2}"
                                    y="${scale * 10 + padding * 2 + 15}"
                                    font-family="sans-serif"
                                    font-size="1rem"
                                    text-anchor="middle"
                                    fill="${textColor}"
                                    class="perimeter-and-area-shape__task-text"
                                >
                                    ${`${this.t('host.create.perimeterAndArea.A')} = `}
                                </text>
                                <rect
                                    x="${(scale * 10 + padding * 2) / 2 + 15}"
                                    y="${scale * 10 + padding * 2}"
                                    width="20"
                                    height="20"
                                    rx="4"
                                    fill="#E5E5E5"
                                    class="perimeter-and-area-shape__task-text-input"
                                />`
                            : ''
                        }
                    </svg>
                    ${ calledIn === 'examples'
                        ? `<div class="perimeter-and-area-shape__task-text-2 text-center" style="font-size: 1rem;">
                                ${metricSymbol} = ?
                            </div>`
                        : ''
                    }
                </div>
            `;
            }

            const A = {
                x: data.A.x * scale + padding,
                y: data.A.y * scale + padding,
            };

            const B = {
                x: data.B.x * scale + padding,
                y: data.B.y * scale + padding,
            };

            const C = {
                x: data.C.x * scale + padding,
                y: data.C.y * scale + padding,
            };

            const nr1 = {
                x: Math.abs(A.x + B.x) / 2,
                y: Math.abs(A.y + B.y) / 2,
            };

            const nr2 = {
                x: Math.abs(B.x + C.x) / 2,
                y: Math.abs(B.y + C.y) / 2,
            };

            const nr3 = {
                x: Math.abs(C.x + A.x) / 2,
                y: Math.abs(C.y + A.y) / 2,
            };

            const center = {
                x: (nr1.x + nr2.x + nr3.x) / 3,
                y: (nr1.y + nr2.y + nr3.y) / 3,
            };

            const distance = (P, center) => {
                const distX = P.x - center.x;

                const distY = P.y - center.y;

                const signX = distX < 0 ? -1 : 1;

                const signY = distY < 0 ? -1 : 1;

                const offsetValX = 25;

                const offsetValY = 30;

                return {
                    x:
                        (Math.abs(distX) /
                            (Math.abs(distX) + Math.abs(distY))) *
                        signX *
                        offsetValX,
                    y:
                        (Math.abs(distY) /
                            (Math.abs(distX) + Math.abs(distY))) *
                        signY *
                        offsetValY,
                };
            };

            const offset = {
                nr1: {
                    ...distance(nr1, center),
                },
                nr2: {
                    ...distance(nr2, center),
                },
                nr3: {
                    ...distance(nr3, center),
                },
            };

            // prettier-ignore
            return `
            <div>
                <svg
                    class="triangle perimeter-and-area-shape"
                    height="${svgHeight}"
                    width="${svgWidth}"
                    viewBox="0 0 ${svgHeight} ${svgWidth}"
                >
                    <polygon
                        points="${A.x},${A.y} ${B.x},${B.y} ${C.x},${C.y}"
                        style="fill:#BE88F4;stroke="none"
                    />

                    <text
                        x="${nr1.x + offset.nr1.x}"
                        y="${nr1.y + offset.nr1.y}"
                        font-family="sans-serif"
                        font-size="1rem"
                        text-anchor="middle"
                        fill="${textColor}"
                    >
                        ${number1}
                    </text>
                    <text
                        x="${nr2.x + offset.nr2.x}"
                        y="${nr2.y + offset.nr2.y}"
                        font-family="sans-serif"
                        font-size="1rem"
                        text-anchor="middle"
                        fill="${textColor}"
                    >
                        ${number2}
                    </text>
                    <text
                        x="${nr3.x + offset.nr3.x}"
                        y="${nr3.y + offset.nr3.y}"
                        font-family="sans-serif"
                        font-size="1rem"
                        text-anchor="middle"
                        fill="${textColor}"
                    >
                        ${number3}
                    </text>

                    ${ calledIn === 'examples' || calledIn === 'inPhoneStudentViewExample'
                        ? `
                            <text
                                x="${(scale * 10 + padding * 2) / 2}"
                                y="${scale * 10 + padding * 2 + 15}"
                                font-family="sans-serif"
                                font-size="1rem"
                                text-anchor="middle"
                                fill="${textColor}"
                                class="perimeter-and-area-shape__task-text"
                            >
                                ${`${this.t('host.create.perimeterAndArea.P')} = `}
                            </text>
                            <rect
                                x="${(scale * 10 + padding * 2) / 2 + 15}"
                                y="${scale * 10 + padding * 2}"
                                width="20"
                                height="20"
                                rx="4"
                                fill="#E5E5E5"
                                class="perimeter-and-area-shape__task-text-input"
                            />`
                        : ''
                    }
                    Sorry, your browser does not support inline SVG.
                </svg>
                ${ calledIn === 'examples'
                    ? `<div class="perimeter-and-area-shape__task-text-2 text-center" style="font-size: 1rem;">
                            ${metricSymbol} = ?
                        </div>`
                    : ''
                }
            </div>
        `;
        }

        if (shape === 'tetragon') {
            const scale = 5 * globalScale;

            const A = {
                x: data.A.x * scale,
                y: data.A.y * scale,
            };

            const B = {
                x: data.B.x * scale,
                y: data.B.y * scale,
            };

            const C = {
                x: data.C.x * scale,
                y: data.C.y * scale,
            };

            const D = {
                x: data.D.x * scale,
                y: data.D.y * scale,
            };

            const nr1 = {
                x: Math.abs(A.x + B.x) / 2 + 5,
                y: Math.abs(A.y + B.y) / 2 - 5,
            };

            const nr2 = {
                x: Math.abs(B.x + C.x) / 2 + 10,
                y: Math.abs(B.y + C.y) / 2 + 10,
            };

            const nr3 = {
                x: Math.abs(C.x + D.x) / 2 - 10,
                y: Math.abs(C.y + D.y) / 2 + 10,
            };

            const nr4 = {
                x: Math.abs(D.x + A.x) / 2 - 5,
                y: Math.abs(D.y + A.y) / 2 - 5,
            };

            return `<svg class="tetragon perimeter-and-area-shape" height="${
                scale * 14 + 20
            }" width="${scale * 14}" viewBox="0 0 ${scale * 14} ${
                scale * 14 + 20
            } ">
<polygon points="${A.x},${A.y} ${B.x},${B.y} ${C.x},${C.y} ${D.x},${D.y}"
            style="fill:#BE88F4;stroke="none" />

            <text x="${nr1.x}" y="${nr1.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number1}</text>
            <text x="${nr2.x}" y="${nr2.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number2}</text>
            <text x="${nr3.x}" y="${nr3.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number3}</text>
            <text x="${nr4.x}" y="${nr4.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number4}</text>
${
    calledIn !== 'inGame'
        ? `
<text
x="${7 * scale - 5}" y="${13 * scale + 20}"
font-family="sans-serif"
font-size="1rem"
text-anchor="middle"
fill="${textColor}">${` ${this.t('host.create.perimeterAndArea.P')} = `}</text>

<rect x="${7 * scale + 10}"
y="${13 * scale + 5}" width="20" height="20" rx="4" fill="#E5E5E5"/>
`
        : ''
}

                        Sorry, your browser does not support inline SVG.
                    </svg>`;
        }

        if (shape === 'pentagon') {
            const scale = 5 * globalScale;

            const width = 12;

            const height = 12;

            const padding = 15;

            const A = {
                x: data.A.x * scale + padding,
                y: data.A.y * scale + padding,
            };

            const B = {
                x: data.B.x * scale + padding,
                y: data.B.y * scale + padding,
            };

            const C = {
                x: data.C.x * scale + padding,
                y: data.C.y * scale + padding,
            };

            const D = {
                x: data.D.x * scale + padding,
                y: data.D.y * scale + padding,
            };

            const E = {
                x: data.E.x * scale + padding,
                y: data.E.y * scale + padding,
            };

            const nr1 = {
                x: Math.abs(A.x + B.x) / 2 - 4,
                y: Math.abs(A.y + B.y) / 2 - 5,
            };

            const nr2 = {
                x: Math.abs(B.x + C.x) / 2 - 9,
                y: Math.abs(B.y + C.y) / 2 + 7,
            };

            const nr3 = {
                x: Math.abs(C.x + D.x) / 2,
                y: Math.abs(C.y + D.y) / 2 + 14,
            };

            const nr4 = {
                x: Math.abs(D.x + E.x) / 2 + 8,
                y: Math.abs(D.y + E.y) / 2 + 8,
            };

            const nr5 = {
                x: Math.abs(E.x + A.x) / 2 + 3,
                y: Math.abs(E.y + A.y) / 2 - 7,
            };

            return `<svg class="pentagon perimeter-and-area-shape" height="${
                height * scale + 10 + padding * 2
            }" width="${width * scale + padding * 2}" viewBox="0 0 ${
                width * scale + padding * 2
            } ${height * scale + 10 + padding * 2}">
<polygon points="${A.x},${A.y} ${B.x},${B.y} ${C.x},${C.y} ${D.x},${D.y} ${
                E.x
            },${E.y}"
            style="fill:#BE88F4;stroke="none" />

            <text x="${nr1.x}" y="${nr1.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number1}</text>
            <text x="${nr2.x}" y="${nr2.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number2}</text>
            <text x="${nr3.x}" y="${nr3.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number3}</text>
            <text x="${nr4.x}" y="${nr4.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number4}</text>
            <text x="${nr5.x}" y="${nr5.y}"
                    font-family="sans-serif"
                    font-size="1rem"
                    text-anchor="middle"
                    fill="${textColor}">${number5}</text>
${
    calledIn !== 'inGame'
        ? `
<text
x="${(width / 2) * scale + padding - 5}"
y="${height * scale + 8 + padding + 15}"
font-family="sans-serif"
font-size="1rem"
text-anchor="middle"
fill="${textColor}">${`${this.t('host.create.perimeterAndArea.P')} = `}</text>


<rect
x="${(width / 2) * scale + padding + 10}"
y="${height * scale + 5 + padding}"
width="20" height="20" rx="4" fill="#E5E5E5"/>
`
        : ''
}
                        Sorry, your browser does not support inline SVG.
                    </svg>`;
        }
        return `${number1} ${this.icon} ${number2} ${
            number3 ? `${this.icon} ${number3}` : ''
        } ${number4 ? `${this.icon} ${number4}` : ''} ${
            number5 ? `${this.icon} ${number5}` : ''
        } ${metric === 'perimeter' ? 'P' : 'A'}= _`;
    }
}
