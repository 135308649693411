import CONSTANTS from '@/core/helpers/constants';
import {
    convertObjectToUrlQueryParams,
    inProductionEnv,
    isLiveGame,
    isSelfPacedGame,
    roundUpToNearest10,
} from '@/core/helpers/utils';
import TopicsBaseClass from '@/core/math-topics/TopicsBaseClass';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { GameTypeNames } from '@/core/mixins/GameTypeNames';
import CrashReportsApi from '@/core/services/AdminApi';
import Api from '@/core/services/Api';
import { Hotjar } from '@/core/services/Hotjar';
import IPGeoLocation from '@/core/services/IPGeoLocation';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import curriculumSet from '@/core/static-json/curriculumSet.json';
import gameTypes from '@/core/static-json/gameTypes.json';
import topicPickerTree from '@/core/static-json/topicPickerTree.json';
import {
    fetchAndUpdateUser,
    isStudent,
} from '@/flows/Authentication/helpers/AuthHelpers';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { router } from '@/main';
import { Base64 } from 'js-base64';
import lodash from 'lodash';
import moment from 'moment';

export default {
    setPlayAgainGame({ commit }, payload) {
        commit('setGameAsPlayedAgain', payload);
    },
    async generateLiveGameQuestions({ dispatch }, roundDuration) {
        const numberOfQuestionsToGenerate = roundUpToNearest10(
            roundDuration / CONSTANTS.LIVE_GAME_SECONDS_PER_TASK,
        );

        return await dispatch('generateBatchQuestions', {
            count: numberOfQuestionsToGenerate,
        });
    },
    /**
     * @deprecated use action 'v2/game/create' instead.
     *
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload
     * @returns {Promise<* | undefined | void>}
     */
    async createGame({ commit, getters, dispatch }, payload) {
        const cancelRedirect = payload.cancelRedirect;

        const user = getters.user;

        const { userId, role } = user;

        const { ...gameInfo } = payload;

        const roundCount = gameInfo.roundCount || gameInfo.rounds;

        const roundDuration = gameInfo.roundDuration;

        const selectedGameType = getters.getSelectedGameType;

        const { type, equationType } = selectedGameType;

        const numberGenerator = getters.getSelectedNumberGenerator;

        const gameType = {
            type,
            equationType,
            numberGenerator,
            language: getters.getCurrentLanguage,
        };

        const roundData = { rounds: roundCount, roundDuration };

        const presetName = getters.getSelectedGamePresetName;

        const gameOriginInUI = getters.getGameOriginInUI;

        const gameOriginInUIDetail = getters.getGameOriginInUIDetail;
        const studentAccounts = false;
        const requireLogin = false;

        const questions = {
            round1: await dispatch('generateLiveGameQuestions', roundDuration),
        };

        const gameData = {
            requireLogin,
            gameType,
            round: roundData,
            userId,
            creatorRole: role,
            demo: payload.demo || false,
            classCode: gameInfo.classCode || null,
            metaData: {
                presetName,
                gameOriginInUI,
                gameOriginInUIDetail,
                avatars: true,
                studentAccounts,
            },
            questions,
        };

        if (inProductionEnv()) {
            void CrashReportsApi().post(
                'crash-reports/increase-live-game-daily-counter/createEndpointCalls',
            );
        }

        return SecureApi()
            .post('game/create', gameData)
            .then(async (response) => {
                const { success, data, error } = response.data;

                if (success) {
                    console.log('live game created!', response.data);

                    commit('setGameCode', data.code);

                    commit('selectGameType', data.gameType);

                    const realGameMode = payload.playWithFriends
                        ? 'self-paced'
                        : 'live';

                    commit('setGameMode', realGameMode);

                    if (payload.demo) {
                        return data.code;
                    }

                    new TrackingService().track(EVENTS.LIVE_GAME_CREATED, {
                        gameCode: data.code,
                        fromPlayAgain: payload.fromPlayAgain,
                        numberOfSkillSelected:
                            payload.gameType.skillsList?.length || 1,
                    });

                    if (payload.playWithFriends) {
                        // commit('setGameCode', data.joinGameCode);
                        dispatch('addHostToGame');
                    }

                    if (isStudent(user)) {
                        Hotjar.tagRecording(['Student created Live Game']);
                    }

                    const notInAbTests =
                        user &&
                        ((user.abTests &&
                            Object.keys(user.abTests).length === 0) ||
                            !user.abTests);

                    const inOldDesktopTests =
                        user &&
                        user.abTests &&
                        (user.abTests.teacherDashboard1 ||
                            user.abTests.teacherDashboard2 ||
                            user.abTests.teacherDashboard3 ||
                            user.abTests.teacherDashboard4 ||
                            user.abTests.teacherDashboard5 ||
                            user.abTests.teacherDashboard6 ||
                            user.abTests.teacherDashboard7 ||
                            user.abTests.teacherDashboard8 ||
                            user.abTests.teacherDashboard9);

                    const inNewDesktopTests =
                        user &&
                        user.abTests &&
                        (user.abTests.teacherDashboard10 ||
                            user.abTests.teacherDashboard11 ||
                            user.abTests.teacherDashboard12);

                    if (cancelRedirect) {
                        console.log('ReDirect Cancelled');
                    } else if (
                        user.abTests &&
                        (user.abTests.mobileActivation7 ||
                            notInAbTests ||
                            inOldDesktopTests ||
                            inNewDesktopTests) &&
                        window.innerWidth <= 800
                    ) {
                        router.push({
                            name: 'live-lobby',
                            params: {
                                gameCode: data.code,
                            },
                        });
                    } else {
                        router.push({
                            name: payload.playWithFriends
                                ? 'host.lobby-self-play'
                                : 'host.lobby',
                            params: {
                                gameCode: data.code,
                                justCreatedGame: !payload.playWithFriends,
                            },
                        });
                    }

                    window.scrollTo(0, 0);
                } else {
                    alert(
                        'Unable to create a game. Please try refreshing the page and try again. If that does not help, please contact us.',
                    );

                    console.error('cG server error: ', error);
                }
            })
            .catch((error) => {
                alert(
                    'Unable to create a game. Please try refreshing the page and try again. If that does not help, please contact us.',
                );

                console.error('cG request error: ', error);
            });
    },
    async createSelfPacedGame({ getters, commit, dispatch }, payload) {
        const cancelRedirect = payload.cancelRedirect;

        const user = getters.user;

        const { userId, email, role } = user;

        const selectedGameType = getters.getSelectedGameType;

        const { type, equationType } = selectedGameType;

        const numberGenerator = getters.getSelectedNumberGenerator;

        const language = getters.getCurrentLanguage;

        const deadline = payload.deadline;

        const { options, classCode } = payload;

        const presetName = getters.getSelectedGamePresetName;

        const gameOriginInUI = getters.getGameOriginInUI;

        const gameOriginInUIDetail = getters.getGameOriginInUIDetail;

        const topic = payload.topic;

        const isSchoolGame = payload.isSchoolGame;

        const gameType = {
            type,
            equationType,
            numberGenerator,
            language,
        };

        const gameData = {
            userId,
            gameType,
            options,
            creatorRole: role,
            deadline,
            classCode: classCode,
            metaData: {
                presetName,
                gameOriginInUI,
                gameOriginInUIDetail,
                topic,
                isSchoolGame,
            },
        };

        if (
            window.location.hostname !== 'localhost' &&
            window.location.hostname !== 'qa.99math.com' &&
            window.location.hostname !== 'test.99math.com'
        ) {
            void CrashReportsApi().post(
                `crash-reports/increase-daily-counter/selfPacedGame/createCalls`,
            );
        }

        return await SecureApi()
            .post('self-paced-game/create', gameData)
            .then((response) => {
                const { success, error, data } = response.data;

                if (success) {
                    const game = data.game;

                    const gameInfo = data.gameInfo;

                    commit('setGameCode', game.code);

                    dispatch('getReports', -1);

                    commit('setGameMode', 'self-paced');

                    commit('setGameInfo', gameInfo);

                    const taskScale =
                        GameTypeNames.methods.getNumberGeneratorName(
                            gameType,
                            false,
                        );

                    new TrackingService().track(EVENTS.SPG_CREATED, {
                        gameCode: game.code,
                        gameMode: gameData.gameMode,
                        options: gameData.options,
                        taskType: gameType.type,
                        taskScale,
                        fromPlayAgain: payload.fromPlayAgain,
                        location: gameOriginInUI,
                        locationDetail: gameOriginInUIDetail,
                    });

                    if (payload.justPlay) {
                        dispatch('addHostToGame');
                    }

                    if (isStudent(user)) {
                        Hotjar.tagRecording(['Student created HW']);
                    }

                    const notInAbTests =
                        user &&
                        ((user.abTests &&
                            Object.keys(user.abTests).length === 0) ||
                            !user.abTests);

                    const inOldDesktopTests =
                        user &&
                        user.abTests &&
                        (user.abTests.teacherDashboard1 ||
                            user.abTests.teacherDashboard2 ||
                            user.abTests.teacherDashboard3 ||
                            user.abTests.teacherDashboard4 ||
                            user.abTests.teacherDashboard5 ||
                            user.abTests.teacherDashboard6 ||
                            user.abTests.teacherDashboard7 ||
                            user.abTests.teacherDashboard8 ||
                            user.abTests.teacherDashboard9);

                    const inNewDesktopTests =
                        user &&
                        user.abTests &&
                        (user.abTests.teacherDashboard10 ||
                            user.abTests.teacherDashboard11 ||
                            user.abTests.teacherDashboard12);

                    if (cancelRedirect) {
                        console.log('Cancelled redirect');
                    } else if (
                        user.role === 'teacher' &&
                        user.abTests &&
                        (user.abTests.mobileActivation7 ||
                            notInAbTests ||
                            inOldDesktopTests ||
                            inNewDesktopTests) &&
                        window.innerWidth <= 800
                    ) {
                        router.push({
                            name: 'spg-lobby',
                            params: {
                                gameCode: game.code,
                            },
                        });
                    } else if (email === 'experimental@99math.com') {
                        router.push({
                            name: 'host.self-paced-lobby-experimental',
                            params: {
                                gameCode: game.code,
                            },
                        });
                    } else if (payload.justPlay) {
                        if (selectedGameType.achievements) {
                            if (isStudent(user) && payload.fromMap) {
                                // do nothing
                            } else if (
                                isStudent(user) &&
                                payload.fromLevelPicker
                            ) {
                                router.push({
                                    name: 'game.pregame',
                                    params: {
                                        code: game.code,
                                        level: payload.level,
                                    },
                                });
                            } else {
                                router.push({
                                    name: 'game.pregame',
                                    params: {
                                        code: game.code,
                                        level: payload.level,
                                    },
                                });
                            }
                        } else {
                            router.push({
                                name: 'game.pregame',
                                params: {
                                    code: game.code,
                                    level: payload.level,
                                },
                            });
                        }
                    } else {
                        router.push({
                            name: 'host.self-paced-lobby',
                            params: {
                                gameCode: game.code,
                            },
                        });
                    }

                    window.scrollTo(0, 0);
                } else {
                    alert('Unable to create a game. Please contact us.');
                    console.error('cG server error: ', error);
                }
            })
            .catch((error) => {
                console.error('cSPG error: ', error);
            });
    },
    // @todo: move to another store(teacher, game.spg)
    async createSelfPacedPlaylist({ getters, commit, dispatch }, payload) {
        const playlist = JSON.parse(sessionStorage.getItem('spgPlaylist'));

        const { userId, role } = getters.user;

        const deadline = payload.deadline;

        const gameMode = payload.gameMode;

        const classCode = payload.classCode;

        const populatePlaylist = playlist.map((game) => {
            return {
                ...game,
                userId,
                deadline,
                creatorRole: role,
                gameMode,
                classCode,
            };
        });

        if (inProductionEnv()) {
            void CrashReportsApi().post(
                `crash-reports/increase-daily-counter/selfPacedGame/createCalls`,
            );
        }

        return await SecureApi()
            .post('self-paced-playlist/create', {
                selfPacedGamesSettings: populatePlaylist,
            })
            .then((response) => {
                const { success, error, data } = response.data;

                const playlist = data;

                if (success) {
                    const firstGamesCode = playlist.selfPacedPlaylist[0].code;
                    commit('setGameCode', firstGamesCode);
                    dispatch('getReports', -1);
                    commit('setGameMode', 'self-paced-playlist');
                    commit('setGameInfo', playlist.selfPacedPlaylist[0]);

                    sessionStorage.removeItem('spgPlaylist');
                    localStorage.removeItem(
                        `getReportsFromSkillsNew/${userId}/${
                            classCode && classCode !== '-1' ? classCode : null
                        }`,
                    );

                    new TrackingService().track(EVENTS.SPPLAYLIST_CREATED, {
                        gameCode: firstGamesCode,
                        taskTypes: playlist.selfPacedPlaylist.map(
                            (game) => game.gameType.type,
                        ),
                        // old UI gives moment() object, new UI ISO string
                        deadline:
                            deadline?.toISOString?.() || deadline || false,
                    });

                    router.push({
                        name: 'host.self-paced-lobby',
                        params: {
                            gameCode: firstGamesCode,
                        },
                    });
                } else {
                    alert('Unable to create a game. Please contact us.');

                    console.error('cSPGP server error: ', error);
                }
            })
            .catch((error) => {
                console.error('cSPGP error: ', error);
            });
    },
    async addGameToSelfPacedPlaylist({ getters }, payload) {
        const { userId, role } = getters.user;

        const gameData = {
            userId,
            creatorRole: role,
            ...payload.gameData,
        };

        return await SecureApi()
            .post(`self-paced-playlist/add/${payload.playlistId}`, gameData)
            .then((response) => {
                const { success, error, data } = response.data;

                if (success) {
                    console.log(data);
                } else {
                    console.error('cSPGP server error: ', error);
                }
            })
            .catch((error) => {
                console.error('cSPGP error: ', error);
            });
    },
    async fetchPlayers({ commit, getters }) {
        await SecureApi()
            .get(`game/${getters.getGameCode}/players`)
            .then((response) => {
                const { success, data, error } = response.data;

                if (success) {
                    commit('addPlayer', data);
                } else {
                    console.error('fP server error: ', error);
                }
            })
            .catch((error) => {
                console.error('fP request error: ', error);
            });
    },
    async getLatestRound({ dispatch, getters }, payload) {
        console.log(`Fetch getLatestRound: ${payload}`);

        const gameCode = payload.gameCode || getters.getGameCode;

        await Api()
            .get(`game/${gameCode}/round`)
            .then((response) => {
                const { success, data, error } = response.data;

                if (success) {
                    console.log('Fetch getLatestRound success');
                    dispatch('startRound', data);
                } else {
                    console.error('gLR server error: ', error);
                }
            })
            .catch((error) => {
                console.error('gLR request error: ', error);
            });
    },
    // todo: rename to load* and refactor usages
    async getGameTypes({ commit }) {
        commit('setGameTypes', gameTypes);
    },
    // todo: rename to load* and refactor usages
    async getTopicPickerTree({ commit }) {
        commit('setTopicPickerTree', topicPickerTree);
    },
    async getSelfPacedPlaylist({ commit }, gameCode) {
        await SecureApi()
            .get(`self-paced-playlist/playlist/code/${gameCode}`)
            .then((response) => {
                const { success, data } = response.data;

                if (success) {
                    commit('setSelfPacedPlaylist', data.selfPacedPlaylist);
                }
            })
            .catch((error) => {
                console.error('gSPP error: ', error);
            });
    },
    async getGameInfo({ getters, commit, dispatch }, path = null) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            if (!path) {
                switch (getters.getGameMode) {
                    case 'self-paced':
                        path = `self-paced-game/gameType/${getters.getGameCode}`;
                        break;
                    default:
                        path = `game/gameType/${getters.getGameCode}`;
                }
            }

            console.log('getGameInfo try ', path);

            await SecureApi()
                .get(path)
                .then((response) => {
                    const { success, data } = response.data;

                    if (success) {
                        // live games new store
                        if (path.startsWith('game/gameType')) {
                            dispatch('v2/game/setInfo', data);

                            commit('setGameInfo', data);

                            commit('setGameStarted', data.started);
                        } else {
                            // used by RouteHelpers for any type of games
                            // v2 store commit it on action setInfo automatically
                            commit('setGameInfo', data);

                            commit('setGameStarted', data.started);
                        }

                        if (!getters.user) {
                            dispatch('setLanguage', data.language);
                        }

                        resolve();
                    }
                })
                .catch((error) => {
                    console.error('gGT error: ', error);

                    reject();
                });
        });
    },
    setGameOriginInUI({ commit }, origin) {
        commit('setGameOriginInUI', origin);
    },
    setGameOriginInUIDetail({ commit }, origin) {
        commit('setGameOriginInUIDetail', origin);
    },
    setGamePresetName({ commit }, name) {
        commit('setGamePresetName', name);
    },
    selectGameType({ commit }, { gameType, autoselect = false, location }) {
        console.debug('Selecting topic', gameType, `autoselect ${autoselect}`);

        if (gameType && !autoselect && location !== 'curriculumSet') {
            new TrackingService(this.user).track(
                EVENTS.CHOSE_TOPIC,
                gameType.type,
            );
        }

        commit('selectGameType', gameType);
    },
    selectNumberGenerator(
        { getters, commit, dispatch },
        { numberGenerator, autoselect = false, location },
    ) {
        commit('selectNumberGenerator', numberGenerator);

        if (!numberGenerator) {
            return commit('setExampleQuestions', []);
        }

        const taskScale = GameTypeNames.methods.getNumberGeneratorName(
            { ...getters.getSelectedGameType, numberGenerator },
            false,
        );

        if (!autoselect) {
            const taskType = getters.getSelectedGameType?.type;

            if (location === 'curriculumSet') {
                console.log(getters.getSelectedGameType);

                const skillName = getters.getSelectedGameType?.name;

                new TrackingService(this.user).track(EVENTS.BROWSE_SKILL, {
                    taskScale,
                    taskType,
                    skillName,
                });
            } else {
                new TrackingService(this.user).track(
                    EVENTS.CHOSE_LEVEL,
                    taskScale,
                    taskType,
                );
            }
        }

        dispatch('generateSampleQuestions');
    },
    fetchPlayersOverSocket({ commit, getters }, socketConnection) {
        socketConnection.client.emit(
            'fetchPlayers',
            getters.getGameCode,
            (data) => {
                commit('addPlayer', data);
            },
        );
    },
    generateSampleQuestions({ commit, state }, payload = false) {
        const { selectedGameType, selectedNumberGenerator } = !payload
            ? state.createGame
            : payload;

        if (selectedGameType === null) {
            return;
        }

        const { type } = selectedGameType;

        const GameTopic = TopicsFactory.getStaticTopicClass(type);

        let questionCount = GameTopic
            ? GameTopic.sampleQuestionCount
            : TopicsBaseClass.sampleQuestionCount;

        switch (type) {
            case 'TYPE_NUMBER_LINE':
                questionCount = 1;

                break;
            case 'TYPE_COMPARING_NUMBERS':
                if (selectedNumberGenerator.subtopic === 'ordering') {
                    questionCount = 2;
                }
                break;
            case 'TYPE_DECIMALS':
                if (selectedNumberGenerator.topic === 'ordering') {
                    questionCount = 2;
                }
                break;
            case 'TYPE_ORDER_OF_OPERATIONS':
                if (selectedNumberGenerator.topic === 'decimals') {
                    questionCount = 2;
                }
                break;
            case 'TYPE_MONEY':
                if (selectedNumberGenerator.topic === 'identify') {
                    questionCount = 2;
                }

                break;
        }

        let exampleQuestions = [];

        let i = 0;

        let j = 0;

        while (i < questionCount && j < 10) {
            i++;

            j++;

            const GameTopic = TopicsFactory.getStaticTopicClass(type);

            const question = GameTopic.generateQuestion(
                selectedNumberGenerator,
            );

            if (exampleQuestions.length > 0) {
                const lastItem = exampleQuestions.pop();

                exampleQuestions.push(lastItem);

                if (!lodash.isEqual(lastItem, question)) {
                    exampleQuestions.push(question);
                } else {
                    i--;
                }
            } else {
                exampleQuestions.push(question);
            }
        }

        commit('setExampleQuestions', exampleQuestions);
    },
    async getLeaderboard(
        { commit, dispatch },
        { gameCode, questions, gameMode, topN },
    ) {
        const leaderboard = await dispatch('fetchLeaderboard', {
            gameCode,
            questions,
            gameMode,
            topN,
        });

        commit('setLeaderboard', leaderboard);
    },
    async fetchLeaderboard(_, { gameCode, questions, gameMode, topN }) {
        try {
            let module = 'game';

            switch (gameMode) {
                case 'self-paced':
                    module = 'self-paced-game';

                    break;
                default:
                    module = 'game';
            }

            const query = `?${questions ? 'questions=1&' : ''}${
                topN ? `topN=${topN}` : ''
            }`;

            const path = `/${module}/${gameCode}/leaderboard${query}`;

            const response = await Api().get(path);

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('gLB error: ', error);
        }
        return [];
    },
    async getSelfPacedLeaderboard({ commit }, { gameCode, questions }) {
        try {
            const query = questions ? '?questions=1' : '';

            const path = `/self-paced-game/${gameCode}/leaderboard${query}`;

            const response = await Api().get(path);

            const { success, data } = response.data;

            if (success) {
                commit('setLeaderboard', data);
            }
        } catch (error) {
            console.error('gLB error: ', error);
        }
    },
    async saveResultsToDB(store, gameCode) {
        await SecureApi()
            .get(`game/${gameCode}/results`)
            .then((response) => {
                const { error } = response.data;
                if (error) {
                    console.error('sRTDB server error: ', error);
                }
            })
            .catch((error) => {
                console.error('sRTDB request error: ', error);
            });
    },
    setLeaderboard({ commit }, data) {
        commit('setLeaderboard', data);
    },
    addPlayer({ commit }, playerName) {
        commit('addPlayer', playerName);
    },
    addPlayerPublic({ commit }, playerName) {
        commit('addPlayerPublic', playerName);
    },
    removePlayer({ commit }, playerName) {
        commit('removePlayer', playerName);
    },
    removePlayerPostGame({ getters, commit }, { playerName, gameMode }) {
        const gameCode = getters.getGameCode;

        const module = gameMode === 'self-paced' ? 'self-paced-game' : 'game';

        const playerNameEncoded = Base64.encodeURI(playerName);

        return SecureApi()
            .post(`${module}/remove-player/${gameCode}/${playerNameEncoded}`)
            .then((response) => {
                const { success } = response.data;

                if (success) {
                    commit('removePlayerPostGame', playerName);
                }
            })
            .catch((error) => {
                console.error('rPPG error: ', error);
            });
    },
    async getPlayedTypeGames({ getters, commit }) {
        const user = getters.user;

        if (user !== null) {
            return SecureApi()
                .get(`game-reports/reports/game-types`)
                .then((response) => {
                    const { success, data } = response.data;

                    if (success) {
                        commit('setReportCategories', data);
                    }

                    return response;
                })
                .catch((error) => {
                    console.error('gR error: ', error);
                });
        }
    },
    setReports({ commit }, data) {
        commit('setReports', data);
    },
    async getReports(
        { getters, commit },
        { page, filters, forceReplace = false },
    ) {
        if (!page || page < 1) {
            page = 1;
        }

        const user = getters.user;

        const reports = getters.getReports;

        const filtersString = convertObjectToUrlQueryParams(filters);

        if (user !== null) {
            return SecureApi()
                .get(`game-reports/reports/${page}${filtersString}`)
                .then((response) => {
                    const { success, data } = response.data;

                    if (success) {
                        const filteredData = data.filter((game) => !game.demo);

                        if (page === 1 || forceReplace) {
                            commit('setReports', filteredData);
                        } else {
                            commit('setReports', [...reports, ...filteredData]);
                        }
                    }

                    return response;
                })
                .catch((error) => {
                    console.error('gR error: ', error);
                });
        }
    },
    setReportsFromSkills({ commit }, data) {
        commit('setReportsFromSkills', data);
    },
    async getReportsFromSkills({ getters, commit }, { classCode, skillData }) {
        const user = getters.user;

        const uid = user ? user.userId : null;

        let cachedReportsFromSkills = localStorage.getItem(
            `getReportsFromSkillsNew/${uid}/${classCode}`,
        );

        cachedReportsFromSkills = cachedReportsFromSkills
            ? JSON.parse(cachedReportsFromSkills)
            : [];

        // filter out only skills which we don't have cached
        const filteredSkillData = skillData.filter(
            (skill) =>
                !cachedReportsFromSkills.some(
                    (cachedSkill) =>
                        cachedSkill.type === skill.type &&
                        lodash.isEqual(
                            cachedSkill.numberGenerator,
                            skill.numberGenerator,
                        ),
                ),
        );

        if (uid && filteredSkillData && filteredSkillData.length === 0) {
            commit(
                'setReportsFromSkills',
                JSON.parse(
                    localStorage.getItem(
                        `getReportsFromSkillsNew/${uid}/${classCode}`,
                    ),
                ),
            );

            return;
        }
        if (user !== null) {
            return SecureApi()
                .post(
                    `game-reports/game-stats-per-skill-improved/${
                        classCode ? classCode : ''
                    }`,
                    JSON.stringify(filteredSkillData),
                )
                .then((response) => {
                    const { success, data } = response.data;

                    if (success) {
                        if (uid) {
                            const cachedData =
                                JSON.parse(
                                    localStorage.getItem(
                                        `getReportsFromSkillsNew/${uid}/${classCode}`,
                                    ),
                                ) || [];

                            cachedData.push(
                                ...data.filter((report) => report?._id),
                            );

                            localStorage.setItem(
                                `getReportsFromSkillsNew/${uid}/${classCode}`,
                                JSON.stringify(cachedData),
                            );

                            commit('setReportsFromSkills', cachedData);
                        }
                    }

                    return response;
                })
                .catch((error) => {
                    console.error('gR error: ', error);
                });
        }
    },
    async deleteUserGame({ commit }, { gameCode, gameMode }) {
        const module = gameMode === 'self-paced' ? 'self-paced-game' : 'game';

        return SecureApi()
            .post(`/game-reports/set/user/${module}/deleted/${gameCode}`)
            .then((response) => {
                const responseData = response.data;

                if (responseData.success) {
                    commit('v2/teacher/removeReport', gameCode);
                } else {
                    console.error(
                        'dLU server error: ',
                        responseData.error.message,
                    );

                    return { success: false, data: responseData.error.message };
                }
            })
            .catch((error) => {
                console.error('dLU request error: ', error);
            });
    },
    async getCountry({ commit }) {
        try {
            if (localStorage.getItem('country_code3')) {
                commit('setCountry', localStorage.getItem('country_code3'));

                return localStorage.getItem('country_code3');
            }

            const response = await IPGeoLocation().get();

            if (!response.data) {
                return;
            }

            const { country_code3 } = response.data;

            localStorage.setItem('country_code3', country_code3);

            commit('setCountry', country_code3);

            return country_code3;
        } catch (error) {
            console.error('fL request error: ' + error);

            return 'USA';
        }
    },
    async sendPixelEvent(_state, event) {
        window.fbq('trackCustom', event);
    },
    async sendSelfPacedGameEndedMsg({ getters }) {
        const gameCode = getters.getGameCode;

        const playerName = getters.getPlayerName;

        const identifierEncoded = getters.getUserId
            ? Base64.encodeURI(getters.user.username)
            : Base64.encodeURI(playerName);

        try {
            await Api().patch(
                `self-paced-game/game-ended/${gameCode}/${identifierEncoded}`,
            );
        } catch (error) {
            console.error('gSPGI request error: ', error);
        }
    },
    async rateSelfPacedGame({ getters }, reaction) {
        const gameCode = getters.getGameCode;

        const playerName = getters.getPlayerName;

        const playerNameEncoded = Base64.encodeURI(playerName);

        try {
            await Api().patch(
                `self-paced-game/rate-game/${gameCode}/${playerNameEncoded}/${reaction}`,
            );
        } catch (error) {
            console.error('rSPG request error: ', error);
        }
    },
    async addReferredByUser({ getters }) {
        try {
            let refId = getters.getReferringUserId;

            // @see: UserInviteLink.vue method setReferralAndRedirectHome():
            if (refId?.includes('-forFeature')) {
                refId = refId.split('-forFeature')[0];
            }

            const signupId = getters.getUserId;

            if (!refId || !signupId) {
                return;
            }

            const response = await SecureApi().post(
                `referrals/add/${refId}/${signupId}`,
            );

            const { success, data, error } = response.data;

            if (success) {
                return data;
            } else {
                console.error('sRbUID server error: ' + error);
            }
        } catch (error) {
            console.error('sRbUID request error: ' + error);
        }
    },
    async saveMyReferralsStatus({ getters }, referralData) {
        try {
            const user = getters.user;

            const response = await SecureApi().patch(
                'referrals/save-referral-status',
                {
                    ...referralData,
                },
            );

            const { success, data } = response.data;

            if (success) {
                fetchAndUpdateUser(user);

                return data;
            } else {
                return;
            }
        } catch (error) {
            console.error('gMR request error: ' + error);
        }
    },
    async setPremiumOnStudentByReferral(_, { studentId }) {
        try {
            const response = await SecureApi().post(
                'home-game/set-premium-on-student-by-referral',
                { studentId },
            );

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('Premium plan request error: ' + error);
        }
    },
    async notifyStudentsAboutPremiumPlan(_, classCode) {
        try {
            const response = await SecureApi().post(
                `class-lists/${classCode}/notify-students-premium-plan`,
            );

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('Premium plan request error: ' + error);
        }
    },
    async getMyReferrals({ getters }) {
        try {
            const refId = getters.getUserId;

            if (!refId) {
                return false;
            }

            const response = await SecureApi().get(`referrals/get/${refId}`);

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('gMR request error: ' + error);
        }
    },
    async getReferralsLeaderboard() {
        try {
            const response = await SecureApi().get(`referrals/leaderboard/`);

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('gMR request error: ' + error);
        }
    },
    async getReferralsLeaderboardUser({ getters }) {
        try {
            const refId = getters.getUserId;

            const response = await SecureApi().get(
                `referrals/leaderboard/${refId}`,
            );

            const { success, data } = response.data;

            if (success) {
                return data;
            }
        } catch (error) {
            console.error('gMR request error: ' + error);
        }
    },
    async setTeachingGradesToUser({ getters }, grades) {
        const user = getters.user;

        try {
            const response = await SecureApi().post(
                `auth/set/user/teachingGrades`,
                {
                    email: user.email,
                    teachingGrades: grades.map((g) => g + ''),
                },
            );

            const { success } = response.data;

            if (success) {
                new TrackingService(user).track(EVENTS.TEACHING_GRADES, grades);

                fetchAndUpdateUser(user);
            }
        } catch (error) {
            console.error('sTG request error: ', error);
        }
    },
    async sendReferralEmails({ getters }, { emails, type }) {
        try {
            const refId = getters.getUserId;

            const response = await SecureApi().post(
                `referrals/email/${type}/${refId}`,
                emails,
            );

            const { success, data, error } = response.data;

            if (success) {
                return data;
            } else {
                console.error('sRE server error: ' + error);
            }
        } catch (error) {
            console.error('sRE request error: ' + error);
        }
    },
    async sendMathMadnessEmails({ getters }, { emails }) {
        try {
            const refId = getters.getUserId;

            const response = await SecureApi().post(
                `referrals/email-math-madness/${refId}`,
                emails,
            );

            const { success, data, error } = response.data;

            if (success) {
                return data;
            } else {
                console.error('sRE server error: ' + error);
            }
        } catch (error) {
            console.error('sRE request error: ' + error);
        }
    },
    async updateGameClassCode(
        { getters },
        { classCode, forGameCode, requireLogin },
    ) {
        const gameCode = forGameCode || getters.getGameCode;

        let forGameMode;

        if (gameCode) {
            if (isSelfPacedGame(String(gameCode).length)) {
                forGameMode = CONSTANTS.SELF_PACED_GAME_MODE;
            } else if (isLiveGame(String(gameCode).length)) {
                forGameMode = CONSTANTS.LIVE_GAME_MODE;
            }
        }

        const gameMode = forGameMode || getters.getGameMode;

        let path;

        if (gameMode === CONSTANTS.SELF_PACED_GAME_MODE) {
            path = `/self-paced-game/${gameCode}/update-class`;
        } else if (gameMode === CONSTANTS.LIVE_GAME_MODE) {
            path = `/game/${gameCode}/update-class`;
        } else {
            throw new Error(
                'uGCC: Unable to figure out what game mode are we in',
            );
        }

        try {
            const response = await SecureApi().post(path, {
                classCode,
                requireLogin,
            });

            const { success, error } = response.data;

            if (success) {
                return true;
            } else {
                console.error('uGCC server error: ' + error);

                return false;
            }
        } catch (error) {
            console.error('uGCC request error: ' + error);

            return false;
        }
    },
    async unassignGameClassCode({ getters }, { forGameCode }) {
        const gameCode = forGameCode || getters.getGameCode;

        let forGameMode;

        if (gameCode) {
            if (isSelfPacedGame(String(gameCode).length)) {
                forGameMode = CONSTANTS.SELF_PACED_GAME_MODE;
            } else if (isLiveGame(String(gameCode).length)) {
                forGameMode = CONSTANTS.LIVE_GAME_MODE;
            }
        }
        const gameMode = forGameMode || getters.getGameMode;

        let path;

        if (gameMode === CONSTANTS.SELF_PACED_GAME_MODE) {
            path = `/self-paced-game/${gameCode}/unassign-class`;
        } else if (gameMode === CONSTANTS.LIVE_GAME_MODE) {
            path = `/game/${gameCode}/unassign-class`;
        } else {
            throw new Error(
                'uaGCC: Unable to figure out what game mode are we in',
            );
        }
        if (!path) {
            console.log('path for unassigning class not found', {
                gameCode,
                gameMode,
                path,
            });

            return;
        }
        try {
            const response = await SecureApi().post(path);

            const { success, error } = response.data;

            if (success) {
                return true;
            } else {
                console.error('uaGCC server error: ' + error);

                return false;
            }
        } catch (error) {
            console.error('uaGCC request error: ' + error);

            return false;
        }
    },
    async getCurriculumSet({ commit }) {
        commit('setCurriculumSet', curriculumSet);
    },
    async getPlayerHighScores({ getters }, { gameType, numberGenerator }) {
        const user = getters.user;

        if (user !== null) {
            try {
                const response = await SecureApi().post(
                    `player-high-scores/topic/${user.userId}/${gameType}`,
                    numberGenerator,
                );

                const { success, data, error } = response.data;

                if (success) {
                    return data;
                } else {
                    console.error('sRE server error: ' + error);
                }
            } catch (error) {
                console.error('sRE request error: ' + error);
            }
        }
    },
    async setLiveLeaderboardSeeStudents({ commit, getters }, value) {
        try {
            commit('setLiveLeaderboardSeeStudents', value);

            const response = await SecureApi().post(
                `user/setLiveLeaderboardSeeStudents/${value}`,
            );

            const { success, data, error } = response.data;

            if (success) {
                await fetchAndUpdateUser(getters.user);

                return data;
            } else {
                console.error('sRE server error: ' + error);
            }
        } catch (error) {
            console.error('sRE request error: ' + error);
        }
    },
    async getClassPage(_store, { classCode, rank = false }) {
        try {
            const classInStore = _store.getters['v2/user/classes'][classCode];

            if (
                classInStore?.commitedAt &&
                moment().diff(moment(classInStore?.commitedAt), 'seconds') < 600
            ) {
                return classInStore;
            }

            const response = await SecureApi().get(
                `home-game-v10SimpleTreeSocial/class/${classCode}?rank=${Number(
                    rank,
                )}`,
            );

            if (response.data.success && response.data?.data) {
                _store.commit('v2/user/userClass', {
                    ...response.data.data,
                    commitedAt: moment(),
                });

                return response.data.data;
            } else {
                console.error('API error: ' + response.data.error?.message);
            }
        } catch (e) {
            console.error('API request error: ' + e);
        }
    },
    async getUserClasses(_store, { rank = false, update = false } = {}) {
        const classLists = _store.state.user?.studentInfo?.classLists || {};

        const classCodes = Object.keys(classLists);

        if (classCodes.length === 0) {
            return [];
        }

        return Promise.all(
            classCodes.map((classCode) =>
                _store.dispatch('getClassPage', { classCode, rank, update }),
            ),
        );
    },
    async getUsersInfo(_store, { userIds, options }) {
        try {
            const response = await SecureApi().get(
                `user/info?userIds=${userIds}&includeToResponse=${options}`,
            );

            if (response.data.success && response.data.data) {
                return response.data.data;
            } else {
                console.error('API error: ' + response.data.error?.message);
            }
        } catch (e) {
            console.error('API request error: ' + e);
        }
    },
};
