import { i18n } from '@/lang/translator';
import { randomIntFromRange } from '@/core/helpers/utils';

const shuffle = (array) =>
    array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

export function getRandomBotName() {
    const botNames = i18n.messages[i18n.locale].botNames;
    const numberOfNames = Object.keys(botNames).length;

    const randomBotIndex = randomIntFromRange(1, numberOfNames);

    return i18n.tc('botNames.bot' + randomBotIndex);
}

export function botsNamesShuffled() {
    return shuffle(Object.keys(i18n.messages[i18n.locale].botNames)).map(
        (key) => i18n.tc(`botNames.${key}`),
    );
}

export function avatarsShuffled() {
    const avatars = [
        'gamer-girl-2',
        'dark-haired-gamer',
        'gamer-girl',
        'gamer',
        'space-cleopatra',
        'gamer-boi',
        'mustache-gamer',
        'sniper',
        'blue-king',
        'future-girl',
        'knight-2',
        'redhead-gamer',
        'rabbit',
        'young-knight',
        'golden-queen',
        'deer',
        'monk',
        'princess',
        'owl',
        'good-robot',
        'ghost-girl',
        'elephant',
        'mustache-guy',
        'elf-girl',
        'buffalo',
        'space-girl',
        'eye-patch-bill',
        'dark-prince',
        'green-mask',
        'robot',
        'blue-hair',
        'panda',
        'blue-diamond',
        'blue-astronaut',
        'wise-man',
        'wizard',
        'bearded-king',
        'silver-hair',
        'grizzly',
        'silver-robot',
        'cyber-gamer',
        'knight',
        'samurai',
        'cyber-punk',
        'spikey',
        'lion',
        'orange-hood',
        'violet-siren',
        'hooded-grey',
        'green-hood',
        'voodoo-girl',
        'grey-mask',
        'husky',
        'dragon',
        'unicorn',
        'hooded',
        'orange-robot',
        'pastel-girl',
        'pink-hood',
        'red-skull',
        'space-cat',
        'unicorn-2',
    ];
    return shuffle(avatars);
}
