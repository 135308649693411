import {
    randomIntFromRange,
    randomArrayElement,
    round,
    getDecimalPlaces,
    hasOwnProp,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    isCorrectFractionAnswer,
    getFractionLayout,
    FractionsOperations,
} from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class SubtractionTopic extends TopicsBaseClass {
    static code = 'TYPE_SUBTRACTION';
    static icon = '-';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.subtraction';

    static generateQuestion = (numberGenerator) => {
        const { topic, scale } = numberGenerator;

        const isFractionsTopic = topic === 'fractions';

        let number1 = 0;

        let number2 = 0;

        let fractionOperationAnswer = 0;

        switch (numberGenerator.type) {
            case 'custom':
                const { wholeNumber } = numberGenerator;

                if (wholeNumber) {
                    number1 = randomIntFromRange(2, 10) * wholeNumber;

                    number2 =
                        randomIntFromRange(1, number1 / wholeNumber - 1) *
                        wholeNumber;
                } else {
                    number1 = randomIntFromRange(
                        parseInt(numberGenerator.nr1Min),
                        parseInt(numberGenerator.nr1Max),
                    );

                    number2 = randomIntFromRange(
                        parseInt(numberGenerator.nr2Min),
                        parseInt(numberGenerator.nr2Max),
                    );
                }

                break;
            case 'scale':
            default:
                number1 = randomIntFromRange(
                    numberGenerator.nr1r,
                    numberGenerator.nr2,
                );

                number2 = randomIntFromRange(
                    numberGenerator.nr2r,
                    number1 - numberGenerator.nr3r,
                );

                break;
        }

        if (topic === 'decimals') {
            // ==> 0.x
            const createFractional = (param) => {
                const dividend = 1 / param;

                const randomInt = randomIntFromRange(1, dividend - 1);

                const result = randomInt / dividend;

                return round(result, 4);
            };

            // ==> (5, 9).x
            const minuendDecimal = (param) => {
                const int = randomIntFromRange(5, 9);

                return int + param;
            };

            // ==> (0, 4).x
            const subtrahendDecimal = (param) => {
                const int = randomIntFromRange(0, 4);

                return int + param;
            };

            const createNumbers = (params) => {
                const [minuend, subtrahend] = params;

                const dividendMinuend = 1 / minuend;

                const dividendSubtrahend = 1 / subtrahend;

                // Flip a coin
                const headsOrTails = randomArrayElement([true, false]);

                // x - y
                if (minuend === 1) {
                    if (headsOrTails) {
                        // 1 - 0.x
                        const sub = createFractional(subtrahend);

                        return [minuend, sub];
                    } else {
                        const fractional = createFractional(subtrahend);

                        const sub1 = fractional;

                        const sub2 = subtrahendDecimal(fractional);

                        // (1, 9) - (0.x)
                        const option1 = [randomIntFromRange(1, 9), sub1];

                        // (5, 9) - (0, 4).x
                        const option2 = [randomIntFromRange(5, 9), sub2];

                        return randomArrayElement([option1, option2]);
                    }
                }

                // 0.x - y
                if (minuend === 0.1) {
                    const randomIntX = randomIntFromRange(2, 9);

                    const randomIntY = randomIntFromRange(1, randomIntX - 1);

                    let a = randomIntX / dividendMinuend;

                    let b = randomIntY / dividendMinuend;

                    if (subtrahend === 1) {
                        const x = randomIntFromRange(1, 9);

                        a = x / dividendMinuend + randomIntX;

                        const y = Math.round(a) - 1;

                        b = randomIntFromRange(1, y);

                        a = round(a, 4);

                        b = round(b, 4);

                        return [a, b];
                    }

                    if (subtrahend === 0.01) {
                        const x = randomIntFromRange(1, 9);

                        b = b + x / dividendSubtrahend;
                    }

                    if (subtrahend === 0.001) {
                        const x = randomIntFromRange(1, 99);

                        b = b + x / dividendSubtrahend;
                    }

                    a = round(a, 4);

                    b = round(b, 4);

                    if (headsOrTails) {
                        a = minuendDecimal(a);

                        b = subtrahendDecimal(b);
                    }

                    return [a, b];
                }

                // 0.0x - y
                if (minuend === 0.01) {
                    let randomIntX = randomIntFromRange(2, 9);

                    if (subtrahend === 0.01 || subtrahend === 0.001) {
                        randomIntX = randomIntFromRange(2, 99);
                    }

                    const randomIntY = randomIntFromRange(1, randomIntX - 1);

                    let a;

                    let b;

                    if (subtrahend === 1) {
                        a =
                            randomIntFromRange(1, 99) / dividendMinuend +
                            randomIntX;

                        const x = Math.round(a) - 1;

                        b = randomIntFromRange(1, x);

                        a = round(a, 4);

                        b = round(b, 4);

                        return [a, b];
                    }

                    if (subtrahend === 0.1) {
                        const x = randomIntFromRange(1, 9);

                        a =
                            randomIntX / dividendSubtrahend +
                            x / dividendMinuend;
                        b = randomIntY / dividendSubtrahend;
                    }

                    if (subtrahend === 0.01) {
                        a = randomIntX / dividendMinuend;

                        b = randomIntY / dividendMinuend;
                    }

                    if (subtrahend === 0.001) {
                        const x = randomIntFromRange(1, 9);

                        a = randomIntX / dividendMinuend;

                        b =
                            randomIntY / dividendMinuend +
                            x / dividendSubtrahend;
                    }

                    a = round(a, 4);

                    b = round(b, 4);

                    if (headsOrTails) {
                        a = minuendDecimal(a);

                        b = subtrahendDecimal(b);
                    }

                    return [a, b];
                }

                // 0.00x - y
                if (minuend === 0.001) {
                    const randomIntX = randomIntFromRange(
                        2,
                        dividendSubtrahend - 1,
                    );

                    const randomIntY = randomIntFromRange(1, randomIntX - 1);

                    let a;

                    let b = randomIntY / dividendSubtrahend;

                    if (subtrahend === 1) {
                        const x = randomIntFromRange(1, 999) / 1000;

                        a = x + randomIntFromRange(2, 9);

                        const y = Math.round(a) - 1;

                        b = randomIntFromRange(1, y);

                        a = round(a, 4);

                        b = round(b, 4);

                        return [a, b];
                    }

                    if (subtrahend === 0.1) {
                        a =
                            randomIntX / dividendSubtrahend +
                            randomIntFromRange(1, 99) / dividendMinuend;
                    }

                    if (subtrahend === 0.01) {
                        a =
                            randomIntX / dividendSubtrahend +
                            randomIntFromRange(1, 9) / dividendMinuend;
                    }

                    if (subtrahend === 0.001) {
                        a = randomIntX / dividendMinuend;
                    }

                    a = round(a, 4);

                    b = round(b, 4);

                    if (headsOrTails) {
                        a = minuendDecimal(a);

                        b = subtrahendDecimal(b);
                    }

                    return [a, b];
                }
            };

            const { minuend, subtrahend } = scale;

            const a = randomArrayElement(minuend);

            const b = randomArrayElement(subtrahend);

            [number1, number2] = createNumbers([a, b]);

            number1 = number1.toFixed(getDecimalPlaces(a));

            number2 = number2.toFixed(getDecimalPlaces(b));
        }

        if (topic === 'fractions') {
            const fractionsOperations = new FractionsOperations(
                numberGenerator,
            );

            const { d1, d2 } =
                fractionsOperations.getDenominators(numberGenerator);

            const { n1, n2 } =
                fractionsOperations.getNominators(numberGenerator);

            const { w1, w2 } =
                fractionsOperations.getWholeNumber(numberGenerator);

            const { subtractNumber1, subtractNumber2 } =
                fractionsOperations.avoidNegativeSubtractResult(
                    { numerator: n1, denominator: d1, wholeNumber: w1 },
                    { numerator: n2, denominator: d2, wholeNumber: w2 },
                );

            const { fractionNumber1, fractionNumber2 } =
                fractionsOperations.avoidNegativeFractionPartResult(
                    subtractNumber1,
                    subtractNumber2,
                );

            fractionOperationAnswer =
                fractionsOperations.getFractionSubtractAnswer(
                    fractionNumber1,
                    fractionNumber2,
                );

            number1 = fractionNumber1;

            number2 = fractionNumber2;
        }

        // Round the answer because subtracting decimals may return
        // something like 1.2300000000001
        let answer = +number1 - +number2;

        answer = round(answer, 4);

        return {
            topic,
            number1,
            number2,
            answer: isFractionsTopic ? fractionOperationAnswer : answer,
        };
    };

    static formatQuestion(questionData, skill, calledIn) {
        const isFractionsTopic =
            questionData?.topic === 'fractions' ||
            questionData?.data?.topic === 'fractions';

        if (isFractionsTopic) {
            const number1 =
                questionData?.number1 || questionData?.data?.number1;

            const number2 =
                questionData?.number2 || questionData?.data?.number2;

            return `
                <div style='width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 5px'>
                    ${getFractionLayout(number1, false, false, calledIn)}
                    -
                    ${getFractionLayout(number2, false, false, calledIn)}
                </div>
                `;
        }

        return super.formatQuestion(questionData, skill, calledIn);
    }

    static getAnswerInputType(numberGenerator) {
        const { topic } = numberGenerator;

        const isMixedAnswerInput = topic === 'fractions';

        return isMixedAnswerInput
            ? ANSWER_INPUT_TYPE.MIXED
            : ANSWER_INPUT_TYPE.REGULAR;
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.topic === 'fractions') {
            return isCorrectFractionAnswer(answer, question.answer);
        }

        return super.isAnswerCorrect(question, answer, numberGenerator);
    }

    static answerData(question, answer, skill) {
        const {
            type,
            numberGenerator: { topic, subtopic },
        } = skill;

        if (topic === 'fractions') {
            const { answer, number1, number2 } = question;

            return {
                number1,
                number2,
                answer,
                topic,
                subtopic,
            };
        }

        return super.answerData(question, answer, skill);
    }

    static generatePlayerAnswerHtml(playerAnswer, skill, question) {
        const isWrongAnswer = !question?.correct;

        let numberGenerator = skill;

        if (hasOwnProp(skill, 'numberGenerator')) {
            // Needed for at least "topic === 'fractions'".
            numberGenerator = skill.numberGenerator;
        }

        if (numberGenerator.topic === 'fractions') {
            return getFractionLayout(playerAnswer, isWrongAnswer, true);
        }

        return super.generatePlayerAnswerHtml(
            playerAnswer,
            numberGenerator,
            question,
        );
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        const topic = question?.topic || numberGenerator?.topic;

        if (topic === 'fractions') {
            const correctAnswer = question?.answer || question.data?.answer;

            return getFractionLayout(correctAnswer, false, true, calledIn);
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static showArrowButtons(numberGenerator) {
        return numberGenerator.topic === 'fractions';
    }

    static resetPlayerAnswer(numberGenerator) {
        const isFractionsTopic = numberGenerator.topic === 'fractions';

        if (isFractionsTopic) {
            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        return '';
    }

    static getNumberGeneratorName(gameInfo) {
        const { numberGenerator } = gameInfo;

        const topicName = this.t(`game.gameTypeTitle.${numberGenerator.topic}`);

        if (numberGenerator.topic === 'decimals') {
            const scales = [
                ...new Set([
                    ...numberGenerator.scale.minuend,
                    ...numberGenerator.scale.subtrahend,
                ]),
            ].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'game.gameTypeTitle.integers',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }

        if (numberGenerator.topic === 'integers') {
            return (
                topicName +
                ': ' +
                numberGenerator.nr1 +
                '...' +
                numberGenerator.nr2
            );
        }

        return numberGenerator.nr1 + '...' + numberGenerator.nr2;
    }
}
