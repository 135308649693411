/* global mixpanel */
import moment from 'moment';
import store from '@/store';

export const AUTH_MP_EVENTS = {
    SIGN_UP: 'signUp',
    LOG_IN: 'logIn',
    SET_LAST_REFERRED_BY: 'setLastReferredBy',
    LOG_OUT: 'logOut',
    COMPLETED_WALKTHROUGH: 'completedWalkthrough',
    SKIPPED_WALKTHROUGH: 'skippedWalkthrough',
    CLOSED_INTRO_VIDEO_MODAL: 'closedIntroVideoModal',
    SET_USER_ID: 'setUserId',
};

export const AUTH_MP_METHODS = {
    signUp(data) {
        mixpanel.register_once({
            email: this.user.email,
        });

        mixpanel.register({
            name: `${this.user.firstName} ${this.user.lastName}`,
            role: this.user.role,
        });

        let commonData = {
            $name: `${this.user.firstName} ${this.user.lastName}`,
            $date: new Date().toISOString(),
            $role: this.user.role,
        };
        if (this.user.role === 'teacher') {
            mixpanel.people.set({
                ...commonData,
                'Teaching Grades': this.user.teachingGrades,
                'Phone Number': this.user.phoneNumber,
            });
        } else {
            mixpanel.people.set(commonData);
        }

        mixpanel.people.set_once({
            $email: this.user.email,
            $method: data.loginType,
            referred_by: data.referredBy || '',
            'Tests saved': 0,
        });

        if (data.publicAbTest?.registrationSlideTest_2) {
            mixpanel.people.set_once({
                'publicAbTest.registrationSlideTest_2':
                    data.publicAbTest.registrationSlideTest_2,
            });
        }

        mixpanel.track('Sign up', {
            method: data.loginType,
        });
    },
    logIn(data) {
        if (data.role === 'student') {
            mixpanel.people.set({
                isSubscribed:
                    data.subscriptionStatus === 'active' ||
                    data.subscriptionStatus === 'trialing',
            });
        }
        if (
            data.role === 'student' &&
            data.referredBy === 'teacherCreatedAccount' &&
            !data.flags?.hasSentSignUpEvent
        ) {
            const startSendingSignupEvent = moment('2022-02-14T13:16:48+01:00');
            if (moment(data.created).isAfter(startSendingSignupEvent)) {
                mixpanel.people.set({
                    'Referred by': data.referredBy,
                    // 'Teacher name': data.teacherName,
                    // 'Teacher email': data.teacherEmail,
                    // 'Class name': data.studentInfo?.className,
                });
                mixpanel.track('Sign up', {
                    method: data.loginType,
                });
                store.dispatch('flipFlag', 'hasSentSignUpEvent');
                return;
            }
        }
        if (data.lastReferredBy) {
            mixpanel.people.set({
                lastReferredBy: data.lastReferredBy,
            });
        }
        mixpanel.people.increment('logins');

        mixpanel.track('Log in', {
            method: data.loginType,
        });
    },
    setLastReferredBy(lastReferredBy) {
        mixpanel.people.set({
            lastReferredBy,
        });
    },
    logOut() {
        mixpanel.track('Log out');
        mixpanel.reset();
    },
    completedWalkthrough() {
        mixpanel.track('Completed walktrough');
    },
    skippedWalkthrough(activeModal) {
        mixpanel.track('Skipped walktrough', {
            'Active modal': activeModal,
        });
    },
    closedIntroVideoModal(watchedVideo) {
        mixpanel.track('Closed introduction video modal', {
            'Watched video': watchedVideo,
        });
    },
    setUserId(userId) {
        mixpanel.people.set({
            'User ID': userId.toString(),
        });
    },
};
