<template>
    <div class="c-modal" :class="classesProp">
        <div
            v-click-outside="handleClickOutside"
            class="c-modal--content"
            :class="`${classesProp}--content`"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        classesProp: {
            type: String,
            default: '',
        },
        clickOutsideClose: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['modalClosed'],
    methods: {
        handleClickOutside() {
            if (this.clickOutsideClose) {
                this.$emit('modalClosed');
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/capacitor/mobile';

.c-modal {
    width: 100%;
    height: 100%;
    background-color: rgba(171, 94, 248, 0.6);
    position: fixed;
    overflow: auto;
    @include sticky-top;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 10001;

    @include mq($w-min-tablet, min) {
        align-items: center;
    }

    &--content {
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $secondary-theme-color;

        @include mq($w-min-tablet, min) {
            align-self: center;
            max-width: 400px;
            height: fit-content;
        }
    }
}
</style>
