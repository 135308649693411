import {
    getDecimalPlaces,
    isNumeric,
    randomArrayElement,
    randomIntFromRange,
    round,
} from '@/core/helpers/utils';
import TopicsBaseClass from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class OrderOfOperationsTopic extends TopicsBaseClass {
    static code = 'TYPE_ORDER_OF_OPERATIONS';
    static icon = '';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.orderOfOperations';

    static resetPlayerAnswer(numberGenerator) {
        return '';
    }

    static generateQuestion(numberGenerator) {
        let number1 = 0;

        let number2 = 0;

        let number3 = 0;

        let answer = 0;

        const expressionCount = numberGenerator.expressions.length;

        const randomNumber = randomIntFromRange(0, expressionCount - 1);

        const { scale, topic, type } = numberGenerator;

        let expression = numberGenerator.expressions[randomNumber];

        if (topic === 'decimals') {
            // Only a : b * c has just hundredths
            if (expressionCount === 3 && scale.every((s) => s === 0.01)) {
                expression = 'a : b * c';
            }

            // a * b : c does not calculate just tenths
            if (scale.every((s) => s === 0.1)) {
                const filtered = numberGenerator.expressions.filter(
                    (exp) => exp !== 'a * b : c',
                );

                expression = randomArrayElement(filtered);
            }

            const getScales = (scales) => {
                // Only addition and subtraction type has thousandths
                if (type !== 'additionAndSubtraction') {
                    scales = scales.filter((s) => s !== 0.001);
                }

                // Take 1 scale value
                const opt1 = (arr) => {
                    const filtered = arr.filter((s) => s !== 1);

                    return [randomArrayElement(filtered)];
                };

                // Take 2 scale values
                const opt2 = (arr) => {
                    const scale1 = randomArrayElement(arr);

                    let scale2 = randomArrayElement(arr);

                    // Do not take two whole numbers
                    if (scale1 === 1 && scale2 === 1) {
                        const decimalScale = arr.filter((s) => s !== 1);

                        scale2 = randomArrayElement(decimalScale);
                    }

                    const result = [scale1, scale2];

                    // Avoid pairing with thousandths
                    if (
                        result.includes(0.001) &&
                        (result.includes(0.1) || result.includes(0.01))
                    ) {
                        const filtered = arr.filter((s) => s !== scale2);

                        scale2 = randomArrayElement(filtered);

                        return opt2(result);
                    }

                    return result;
                };

                // additionAndSubtraction exception
                if (
                    type === 'additionAndSubtraction' &&
                    expression === 'a + b + c'
                ) {
                    // Take 3 decimal scale values, or
                    // take 2 scale values if cannot take 3
                    const opt3 = (arr) => {
                        const filtered = arr.filter((s) => s !== 1);

                        if (filtered.length === 3) {
                            return filtered;
                        } else {
                            return opt2(arr);
                        }
                    };

                    // Return 1 of the 3 options
                    return randomArrayElement([
                        opt1(scales),
                        opt2(scales),
                        opt3(scales),
                    ]);
                }

                // multiplicationAndDividing exceptions
                if (type === 'multiplicationAndDividing') {
                    if (expression === 'a * b * c') {
                        // Only allow 0.1 as one options since we
                        // do not generate anything for just hundredths
                        const tenths = scales.filter((s) => s === 0.1);

                        if (!tenths.length) {
                            return scales;
                        }

                        if (
                            scales.length === 2 &&
                            scales.includes(0.1) &&
                            scales.includes(0.01)
                        ) {
                            return [0.1];
                        }

                        const opt3 = (arr) => {
                            const scale1 = randomArrayElement(arr);

                            let scale2 = randomArrayElement(arr);

                            if (scale1 === 1) {
                                const filtered = arr.filter((s) => s !== 1);

                                scale2 = randomArrayElement(filtered);
                            }

                            if (scale1 === 0.1) {
                                const filtered = arr.filter((s) => s !== 0.01);

                                scale2 = randomArrayElement(filtered);
                            }

                            // Do not take just whole hundredths or
                            // hundredths and tenths
                            if (scale1 === 0.01) {
                                const filtered = arr.filter(
                                    (s) => s !== scale1 && s !== 0.1,
                                );

                                scale2 = randomArrayElement(filtered);
                            }

                            return [scale1, scale2];
                        };

                        return randomArrayElement([
                            tenths,
                            opt3(scales),
                            scales,
                        ]);
                    }

                    if (expression === 'a * b : c') {
                        const scale1 = randomArrayElement(scales);

                        const filtered = scales.filter((s) => s !== scale1);

                        const scale2 =
                            (filtered.length && randomArrayElement(filtered)) ||
                            scale1;

                        return [scale1, scale2];
                    }
                }

                // Return 1 of the 2 options
                return randomArrayElement([opt1(scales), opt2(scales)]);
            };

            const scales = getScales(scale);

            if (type === 'additionAndSubtraction') {
                if (expression === 'a + b + c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        if (decimalScale === 0.1) {
                            let a = randomIntFromRange(1, 99) / dividend;

                            let b = randomIntFromRange(1, 9) / dividend;

                            let c = randomIntFromRange(1, 9) / dividend;

                            a = a.toFixed(1);

                            b = b.toFixed(1);

                            c = c.toFixed(1);

                            return [a, b, c];
                        }

                        const a1 = randomIntFromRange(1, 9) / dividend;

                        const a2 = randomIntFromRange(1, 10);

                        let a = a1 + a2;

                        let b = randomIntFromRange(1, 99) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 10);

                        let b = randomIntFromRange(1, dividend - 1) / dividend;

                        let c =
                            randomIntFromRange(1, dividend * 10 - 1) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        if (decimalScale === 0.1) {
                            return [a, b, c];
                        }

                        if (decimalScale === 0.01) {
                            return [a, c, b];
                        }

                        if (decimalScale === 0.001) {
                            const x1 = randomIntFromRange(0, 9);

                            const x2 = randomIntFromRange(1, 9) / dividend;

                            const x = (x1 + x2).toFixed(3);

                            return [a, b, x];
                        }
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01) ||
                        scales.every((s) => s === 0.001)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 3 decimal scale selections
                    if (scales.length === 3) {
                        const rand1 = randomIntFromRange(0, 9);

                        const rand2 = randomIntFromRange(1, 9) / 1000;

                        number1 = randomIntFromRange(1, 99) / 10;

                        number1 = number1.toFixed(1);

                        number2 = randomIntFromRange(1, 99) / 100;

                        number2 = number2.toFixed(2);

                        number3 = rand1 + rand2;

                        number3 = number3.toFixed(3);
                    } else {
                        // Tenths and hundredths
                        if (scales.includes(0.1) && scales.includes(0.01)) {
                            number1 = randomIntFromRange(1, 99) / 100;

                            number2 = randomIntFromRange(1, 9) / 10;

                            number3 = randomIntFromRange(1, 99) / 100;

                            number1 = number1.toFixed(2);

                            number2 = number2.toFixed(1);

                            number3 = number3.toFixed(2);
                        }

                        // Tenths and thousandths
                        if (scales.includes(0.1) && scales.includes(0.001)) {
                            const headsOrTails = randomArrayElement([1, 0]);

                            number1 = randomIntFromRange(1, 99) / 10;

                            number1 = number1.toFixed(2);

                            const b1 = randomIntFromRange(1, 9) / 10;

                            const b2 = randomIntFromRange(1, 9) / 1000;

                            number2 = headsOrTails
                                ? b1.toFixed(1)
                                : b2.toFixed(3);

                            number3 = randomIntFromRange(1, 99) / 1000;

                            number3 = number3.toFixed(3);
                        }

                        // 2 mixed scale selections
                        if (scales.includes(1) && scales.includes(0.1)) {
                            [number1, number2, number3] = createMixed(0.1);
                        }

                        if (scales.includes(1) && scales.includes(0.01)) {
                            [number1, number2, number3] = createMixed(0.01);
                        }

                        if (scales.includes(1) && scales.includes(0.001)) {
                            [number1, number2, number3] = createMixed(0.001);
                        }
                    }

                    answer =
                        Number(number1) + Number(number2) + Number(number3);

                    answer = round(answer, 3);
                }

                if (expression === 'a + b - c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const rand1 = randomIntFromRange(1, 9);

                        const rand2 = randomIntFromRange(1, 9) / dividend;

                        const rand3 = randomIntFromRange(1, 99) / dividend;

                        let a, b, c;

                        if (decimalScale === 0.1) {
                            a = rand3;

                            b = rand2;

                            c = randomIntFromRange(1, (a + b) * 10) / 10;

                            return [a, b, c];
                        }

                        if (decimalScale === 0.01) {
                            a = rand1 + rand2;

                            b = rand3;

                            const n = a + b - 1 / 10;

                            const x = randomIntFromRange(0, n);

                            c = x + rand2;
                        }

                        if (decimalScale === 0.001) {
                            a = rand1 + rand2;

                            b = rand3;

                            const x = randomIntFromRange(1, a + b);

                            c = x + rand2;
                        }

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 10);

                        let b = randomIntFromRange(1, dividend - 1) / dividend;

                        let c =
                            randomIntFromRange(1, a) +
                            randomIntFromRange(1, b * dividend) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        if (decimalScale === 0.001) {
                            const x1 = randomIntFromRange(1, a);

                            const x2 = randomIntFromRange(1, 9) / dividend;

                            const x = (x1 + x2).toFixed(3);

                            return [a, b, x];
                        }

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01) ||
                        scales.every((s) => s === 0.001)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // Tenths and hundredths
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(1, 99) / 10;

                        number2 = randomIntFromRange(1, 9) / 100;

                        const rand1 = randomIntFromRange(0, number1);

                        const rand2 = randomIntFromRange(1, 9) / 100;

                        number3 = rand1 + rand2;

                        number1 = number1.toFixed(1);

                        number2 = number2.toFixed(2);

                        number3 = number3.toFixed(2);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }
                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }
                    if (scales.includes(1) && scales.includes(0.001)) {
                        [number1, number2, number3] = createMixed(0.001);
                    }

                    answer =
                        Number(number1) + Number(number2) - Number(number3);

                    answer = round(answer, 3);
                }

                if (expression === 'a - b + c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a =
                            randomIntFromRange(dividend, dividend * 10 - 1) /
                            dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 10);

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, dividend - 1) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01) ||
                        scales.every((s) => s === 0.001)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // Tenths and hundredths
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(100, 999) / 100;

                        number2 = randomIntFromRange(1, 9) / 100;

                        number3 = randomIntFromRange(1, 9) / 10;

                        number1 = number1.toFixed(2);

                        number2 = number2.toFixed(2);

                        number3 = number3.toFixed(1);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    if (scales.includes(1) && scales.includes(0.001)) {
                        [number1, number2, number3] = createMixed(0.001);
                    }

                    answer =
                        Number(number1) - Number(number2) + Number(number3);

                    answer = round(answer, 3);
                }

                if (expression === 'a - b - c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        if (decimalScale === 0.1) {
                            let a = randomIntFromRange(20, 99) / 10;

                            let b = randomIntFromRange(1, 9) / 10;

                            let c = randomIntFromRange(1, 9) / 10;

                            a = a.toFixed(1);

                            b = b.toFixed(1);

                            c = c.toFixed(1);

                            return [a, b, c];
                        }

                        let a =
                            randomIntFromRange(dividend, dividend * 10 - 1) /
                            dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(2, 10);

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, dividend - 1) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01) ||
                        scales.every((s) => s === 0.001)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // Tenths and hundredths
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(10, 99) / 10;

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 10;

                        number1 = number1.toFixed(1);

                        number2 = number2.toFixed(2);

                        number3 = number3.toFixed(2);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    if (scales.includes(1) && scales.includes(0.001)) {
                        [number1, number2, number3] = createMixed(0.001);
                    }

                    answer =
                        Number(number1) - Number(number2) - Number(number3);

                    answer = round(answer, 3);
                }
            }

            if (type === 'multiplicationAndDividing') {
                if (expression === 'a * b * c') {
                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const a1 = randomArrayElement([10, 100, 1000]);

                        const a2 = randomIntFromRange(1, 5);

                        let a = randomArrayElement([a1, a2]);

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    // Tenths
                    if (scales.every((s) => s === 0.1)) {
                        number1 = randomIntFromRange(1, 5) / 10;

                        number1 = number1.toFixed(1);

                        number2 = randomIntFromRange(1, 9) / 10;

                        number2 = number2.toFixed(1);

                        number3 = randomIntFromRange(1, 9) / 10;

                        number3 = number3.toFixed(1);
                    }

                    // Mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    if (
                        scales.includes(1) &&
                        scales.includes(0.1) &&
                        scales.includes(0.01)
                    ) {
                        number1 = randomArrayElement([10, 100, 1000]);

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 100;

                        number1 = number1.toString();

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(2);
                    }

                    answer =
                        Number(number1) * Number(number2) * Number(number3);

                    answer = round(answer, 3);
                }

                if (expression === 'a * b : c') {
                    if (scales.includes(1) && scales.includes(0.1)) {
                        number2 = randomArrayElement([1 / 10, 10, 100]);

                        const c1 = randomIntFromRange(1, 9) / 10;

                        const c2 = randomIntFromRange(1, 9);

                        number3 = randomArrayElement([c1, c2]);

                        const a1 = randomIntFromRange(1, 9) * number3;

                        const a2 = (randomIntFromRange(1, 9) / 10) * number3;

                        number1 = randomArrayElement([a1, a2]);

                        number1 = round(number1, 2).toString();

                        number2 = number2.toString();

                        number3 = number3.toString();
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        const headsOrTails = randomArrayElement([1, 0]);

                        number2 = randomArrayElement([1 / 100, 10, 100]);

                        const c1 = randomIntFromRange(1, 9) / 100;

                        const c2 = randomIntFromRange(1, 9);

                        number3 = headsOrTails ? c1 : c2;

                        const a1 = randomIntFromRange(1, 9);

                        const a2 = (randomIntFromRange(1, 9) / 100) * number3;

                        number1 = headsOrTails ? a1 : a2;

                        number1 = round(number1, 2).toString();

                        number2 = number2.toString();

                        number3 = number3.toString();
                    }

                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const headsOrTails = randomArrayElement([1, 0]);

                        number2 = randomIntFromRange(1, 9) / 10;

                        const c1 = randomIntFromRange(1, 9) / 10;

                        const c2 = randomIntFromRange(1, 9) / 100;

                        number3 = headsOrTails ? c1 : c2;

                        const a1 = (randomIntFromRange(1, 9) / 10) * number3;

                        const a2 = randomIntFromRange(1, 9) * number3;

                        number1 = headsOrTails ? a1 : a2;

                        number1 = round(number1, 2).toString();

                        number2 = number2.toString();

                        number3 = number3.toFixed(2);
                    }
                }

                if (expression === 'a : b * c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        let a = randomIntFromRange(1, 10) * b;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const random =
                            decimalScale === 0.1
                                ? randomArrayElement([1, 2])
                                : randomArrayElement([1, 2, 3]);

                        const c1 = randomIntFromRange(1, 9) / dividend;

                        const c2 = randomIntFromRange(1, 10);

                        let c = randomArrayElement([c1, c2]);

                        const b1 = randomIntFromRange(1, 10);

                        const b2 = randomIntFromRange(1, 9) / dividend;

                        const b3 = randomIntFromRange(1, 10) / 100;

                        let b;

                        if (random === 1) {
                            b = b1;
                        } else if (random === 2) {
                            b = b2;
                        } else {
                            b = b3;
                        }

                        const a1 = (randomIntFromRange(1, 9) / dividend) * b;

                        const a2 = randomIntFromRange(1, 9) * b;

                        const a3 = a2 * 100;

                        let a;

                        if (random === 1) {
                            a = a1;
                        } else if (random === 2) {
                            a = a2;
                        } else {
                            a = a3;
                        }

                        a = round(a, 2).toString();

                        b = round(b, 2).toString();

                        c = round(c, 2).toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal scale selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const headsOrTails = randomArrayElement([1, 0]);

                        const c1 = randomIntFromRange(1, 9) / 10;

                        const c2 = randomIntFromRange(1, 9) / 100;

                        number3 = randomArrayElement([c1, c2]);

                        const b1 = randomIntFromRange(1, 9) / 100;

                        const b2 = randomIntFromRange(1, 9) / 10;

                        number2 = headsOrTails ? b1 : b2;

                        const a1 = randomIntFromRange(1, 10) * number2 * 10;

                        const a2 = (randomIntFromRange(1, 10) / 10) * number2;

                        number1 = headsOrTails ? a1 : a2;

                        number1 = round(number1, 2).toString();

                        number2 = round(number2, 2).toString();

                        number3 = round(number3, 3).toString();
                    }

                    answer =
                        (Number(number1) / Number(number2)) * Number(number3);

                    answer = round(answer, 4);
                }
            }

            if (type === 'operationsMix') {
                if (expression === 'a + b * c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a;

                        if (decimalScale === 0.1) {
                            a = randomIntFromRange(1, 99) / dividend;
                        }

                        if (decimalScale === 0.01) {
                            const a1 = randomIntFromRange(1, 9) / dividend;

                            const a2 = a1 + randomIntFromRange(1, 9);

                            a = randomArrayElement([a1, a2]);
                        }

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const a1 = randomIntFromRange(1, 9) / dividend;

                        const a2 = randomIntFromRange(1, 10);

                        let a = randomArrayElement([a1, a2]);

                        let b = randomIntFromRange(1, 9) / dividend;

                        const c1 = randomIntFromRange(1, 9) / dividend;

                        const c2 = randomIntFromRange(1, 10);

                        let c = randomArrayElement([c1, c2]);

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const a1 = randomIntFromRange(1, 9) / 100;

                        const a2 = randomIntFromRange(1, 10) + a1;

                        number1 = randomArrayElement([a1, a2]);

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 10;

                        number1 = number1.toFixed(2);

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(1);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    answer =
                        Number(number1) + Number(number2) * Number(number3);

                    answer = round(answer, 4);
                }

                if (expression === 'a * b + c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        if (decimalScale === 0.01) {
                            const b2 = b + randomIntFromRange(1, 9);

                            b = randomArrayElement([b, b2]);
                        }

                        const n = 1000 / 100 - 1;

                        let c = randomIntFromRange(1, n) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const a1 = randomIntFromRange(1, 9) / dividend;

                        const a2 = randomIntFromRange(1, 10);

                        let a = randomArrayElement([a1, a2]);

                        let b = randomIntFromRange(1, 9) / dividend;

                        const c1 = randomIntFromRange(1, 9) / dividend;

                        const c2 = randomIntFromRange(1, 10);

                        let c = randomArrayElement([c1, c2]);

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(1, 9) / 10;

                        number2 = randomIntFromRange(1, 9) / 10;

                        const c1 = randomIntFromRange(1, 9) / 100;

                        const c2 = randomIntFromRange(1, 10) + c1;

                        number3 = randomArrayElement([c1, c2]);

                        number1 = number1.toFixed(1);

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(2);
                    }

                    answer =
                        Number(number1) * Number(number2) + Number(number3);

                    answer = round(answer, 4);
                }

                if (expression === '(a + b) * c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomArrayElement([
                            randomIntFromRange(1, 10),
                            100,
                            1000,
                        ]);

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }
                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(1, 9) / 10;

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 100;

                        number1 = number1.toFixed(1);

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(2);
                    }

                    answer =
                        (Number(number1) + Number(number2)) * Number(number3);

                    answer = round(answer, 4);
                }

                if (expression === 'a * (b + c)') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomArrayElement([
                            randomIntFromRange(1, 10),
                            100,
                            1000,
                        ]);

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(1, 9) / 100;

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 10;

                        number1 = number1.toFixed(2);

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(1);
                    }

                    answer =
                        Number(number1) * (Number(number2) + Number(number3));

                    answer = round(answer, 4);
                }

                if (expression === 'a - b * c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        let a;

                        if (decimalScale === 0.1) {
                            const n = round(b * c * 10, 1);

                            const a1 = randomIntFromRange(n, 9) / 10;

                            const a2 = a1 + randomIntFromRange(1, 10);

                            a = randomArrayElement([a1, a2]);
                        }

                        if (decimalScale === 0.01) {
                            a = randomIntFromRange(1, 9) / dividend;
                        }

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let c = randomIntFromRange(1, 10);

                        if (decimalScale === 0.1) {
                            const c2 = randomIntFromRange(1, 9) / 10;

                            c = randomArrayElement([c, c2]);
                        }

                        const n = round(b * c, 2);

                        let a = randomIntFromRange(n, 10);

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }
                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const a1 = randomIntFromRange(1, 9) / 100;

                        const a2 = randomIntFromRange(1, 10) + a1;

                        number1 = randomArrayElement([a1, a2]);

                        number2 = randomIntFromRange(1, 9) / 10;

                        number3 = randomIntFromRange(1, 9) / 10;

                        number1 = number1.toFixed(2);

                        number2 = number2.toFixed(1);

                        number3 = number3.toFixed(1);
                    }

                    answer =
                        Number(number1) - Number(number2) * Number(number3);

                    answer = round(answer, 4);
                }

                if (expression === '(a - b) * c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const n = decimalScale === 0.1 ? 1 : 2;

                        let a = randomIntFromRange(n, 9) / dividend;

                        let b = randomIntFromRange(1, a * dividend) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(2, 9) / dividend;

                        let b = randomIntFromRange(1, a * dividend) / dividend;

                        let c = randomIntFromRange(1, 10);

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number1 = randomIntFromRange(2, 9) / 10;

                        number2 = randomIntFromRange(1, number1 * 10) / 10;

                        number3 = randomIntFromRange(1, 9) / 100;

                        number1 = number1.toFixed(1);

                        number2 = round(number2, 2).toString();

                        number3 = number3.toFixed(2);
                    }

                    answer =
                        (Number(number1) - Number(number2)) * Number(number3);

                    answer = round(answer, 4);
                }

                if (expression === 'a : b + c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let b = randomIntFromRange(1, 9) / dividend;

                        let a = randomIntFromRange(1, 9) * b;

                        let c = randomIntFromRange(1, dividend - 1) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const headsOrTails = randomArrayElement([1, 0]);

                        const b1 = randomIntFromRange(1, 10);

                        const b2 = randomIntFromRange(1, 9) / dividend;

                        let b = headsOrTails ? b1 : b2;

                        const a1 = (randomIntFromRange(1, 9) / dividend) * b;

                        const a2 = randomIntFromRange(1, 10) * b;

                        let a = headsOrTails ? a1 : a2;

                        let c = randomIntFromRange(1, 9) / dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toString();

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const b1 = randomIntFromRange(1, 9) / 10;

                        const b2 = randomIntFromRange(1, 9) / 100;

                        number2 = randomArrayElement([b1, b2]);

                        number1 = randomIntFromRange(1, 9) * number2;

                        const c1 = randomIntFromRange(1, 9) / 10;

                        const c2 = randomIntFromRange(1, 9) / 100;

                        number3 = randomArrayElement([c1, c2]);

                        number1 = round(number1, 2).toString();

                        number2 = number2.toString();

                        number3 = number3.toString();
                    }

                    answer =
                        Number(number1) / Number(number2) + Number(number3);

                    answer = round(answer, 2);
                }

                if (expression === 'a + b : c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let a = randomIntFromRange(1, dividend - 1) / dividend;

                        let c = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) * c;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const headsOrTails = randomArrayElement([1, 0]);

                        let a = randomIntFromRange(1, 9) / dividend;

                        const c1 = randomIntFromRange(1, 10);

                        const c2 = randomIntFromRange(1, 9) / dividend;

                        let c = headsOrTails ? c1 : c2;

                        const b1 = (randomIntFromRange(1, 9) / dividend) * c;

                        const b2 = randomIntFromRange(1, 10) * c;

                        let b = headsOrTails ? b1 : b2;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }
                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const a1 = randomIntFromRange(1, 9) / 10;

                        const a2 = randomIntFromRange(1, 99) / 100;

                        number1 = randomArrayElement([a1, a2]);

                        const c1 = randomIntFromRange(1, 9) / 10;

                        const c2 = randomIntFromRange(1, 9) / 100;

                        number3 = randomArrayElement([c1, c2]);

                        number2 = randomIntFromRange(1, 9) * number3;

                        number1 = number1.toString();

                        number2 = round(number2, 2).toString();

                        number3 = number3.toString();
                    }

                    answer =
                        Number(number1) + Number(number2) / Number(number3);

                    answer = round(answer, 2);
                }

                if (expression === 'a - b : c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const n = dividend * 10 - 1;

                        let c = randomIntFromRange(1, 9) / dividend;

                        let b = randomIntFromRange(1, 9) * c;

                        let a =
                            randomIntFromRange((dividend * b) / c, n) /
                            dividend;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const headsOrTails = randomArrayElement([1, 0]);

                        const c1 = randomIntFromRange(1, 10);

                        const c2 = randomIntFromRange(1, 9) / dividend;

                        let c = headsOrTails ? c1 : c2;

                        const b1 = (randomIntFromRange(1, 9) / dividend) * c;

                        const b2 = randomIntFromRange(1, 10) * c;

                        let b = headsOrTails ? b1 : b2;

                        let a = randomIntFromRange(b / c, 10);

                        a = a.toString();

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number3 = randomIntFromRange(1, 9) / 10;

                        const b1 = randomIntFromRange(1, 9) * number3;

                        const b2 = b1 / 10;

                        number2 = randomArrayElement([b1, b2]);

                        number1 =
                            randomIntFromRange((100 * number2) / number3, 99) /
                            100;

                        number1 = number1.toFixed(2);

                        number2 = round(number2, 2).toString();

                        number3 = number3.toFixed(1);
                    }

                    answer =
                        Number(number1) - Number(number2) / Number(number3);

                    answer = round(answer, 2);
                }

                if (expression === '(a + b) : c') {
                    const createNths = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        let c = randomIntFromRange(1, 9) / dividend;

                        let a = randomIntFromRange(1, 9) * c;

                        let b = randomIntFromRange(1, 9) * c;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toFixed(getDecimalPlaces(dividend));

                        return [a, b, c];
                    };

                    const createMixed = (decimalScale) => {
                        const dividend = 1 / decimalScale;

                        const headsOrTails = randomArrayElement([1, 0]);

                        const n = dividend * 10 - 1;

                        const c1 = randomIntFromRange(1, 10);

                        const c2 =
                            decimalScale === 0.1
                                ? randomArrayElement([100, 1000])
                                : 100;

                        let c = headsOrTails ? c1 : c2;

                        const b1 = (randomIntFromRange(1, 9) / dividend) * c;

                        const b2 = randomIntFromRange(1, 9) / dividend;

                        let b = headsOrTails ? b1 : b2;

                        const a1 = (randomIntFromRange(1, 9) / dividend) * c;

                        const a2 = randomIntFromRange(1, n) / dividend;

                        let a = headsOrTails ? a1 : a2;

                        a = a.toFixed(getDecimalPlaces(dividend));

                        b = b.toFixed(getDecimalPlaces(dividend));

                        c = c.toString();

                        return [a, b, c];
                    };

                    // 1 decimal scale selection
                    if (
                        scales.every((s) => s === 0.1) ||
                        scales.every((s) => s === 0.01)
                    ) {
                        [number1, number2, number3] = createNths(scales[0]);
                    }

                    // 2 mixed scale selections
                    if (scales.includes(1) && scales.includes(0.1)) {
                        [number1, number2, number3] = createMixed(0.1);
                    }

                    if (scales.includes(1) && scales.includes(0.01)) {
                        [number1, number2, number3] = createMixed(0.01);
                    }

                    // 2 decimal selections
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        number3 = randomIntFromRange(1, 9) / 100;

                        number2 = randomIntFromRange(1, 9) * number3 * 10;

                        number1 = randomIntFromRange(1, 9) * number3 * 10;

                        number1 = number1.toFixed(2);

                        number2 = number2.toFixed(2);

                        number3 = number3.toFixed(2);
                    }

                    answer =
                        (Number(number1) + Number(number2)) / Number(number3);

                    answer = round(answer, 4);
                }
            }

            return {
                expression,
                number1,
                number2,
                number3,
                answer,
            };
        }
        // Natural numbers
        switch (type) {
            case 'additionAndSubtraction':
                switch (expression) {
                    case 'a + b + c':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(1, 8);

                                number2 = randomIntFromRange(
                                    1,
                                    10 - number1 - 1,
                                );

                                number3 = randomIntFromRange(
                                    1,
                                    10 - number1 - number2,
                                );

                                break;
                            case '1..20':
                                number1 = randomIntFromRange(1, 18);

                                number2 = randomIntFromRange(
                                    1,
                                    20 - number1 - 1,
                                );

                                number3 = randomIntFromRange(
                                    1,
                                    20 - number1 - number2,
                                );

                                break;
                            case '10..20':
                                number1 = randomIntFromRange(1, 18);

                                number2 = randomIntFromRange(
                                    1,
                                    20 - number1 - 1,
                                );

                                if (number1 + number2 < 10) {
                                    number3 = randomIntFromRange(
                                        10 - number1 - number2,
                                        20 - number1 - number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        20 - number1 - number2,
                                    );
                                }

                                break;
                            case '20..100':
                                number1 = randomIntFromRange(1, 98);

                                number2 = randomIntFromRange(
                                    1,
                                    100 - number1 - 1,
                                );

                                if (number1 + number2 < 20) {
                                    number3 = randomIntFromRange(
                                        20 - number1 - number2,
                                        100 - number1 - number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        100 - number1 - number2,
                                    );
                                }

                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(1, 998);

                                number2 = randomIntFromRange(
                                    1,
                                    1000 - number1 - 1,
                                );

                                if (number1 + number2 < 100) {
                                    number3 = randomIntFromRange(
                                        100 - number1 - number2,
                                        1000 - number1 - number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        1000 - number1 - number2,
                                    );
                                }

                                break;
                        }

                        answer = number1 + number2 + number3;

                        break;
                    case 'a + b - c':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(1, 9);

                                number2 = randomIntFromRange(
                                    1,
                                    10 - (number1 - 1),
                                );

                                number3 = randomIntFromRange(
                                    1,
                                    number1 + number2,
                                );

                                break;
                            case '1..20':
                                number1 = randomIntFromRange(1, 19);

                                number2 = randomIntFromRange(
                                    1,
                                    20 - (number1 - 1),
                                );

                                number3 = randomIntFromRange(
                                    1,
                                    number1 + number2,
                                );

                                break;
                            case '10..20':
                                number1 = randomIntFromRange(1, 19);
                                if (number1 < 10) {
                                    number2 = randomIntFromRange(
                                        10,
                                        20 - number1,
                                    );
                                } else {
                                    number2 = randomIntFromRange(
                                        1,
                                        20 - number1,
                                    );
                                }

                                number3 = randomIntFromRange(
                                    1,
                                    number1 + number2 - 10,
                                );

                                break;
                            case '20..100':
                                number1 = randomIntFromRange(1, 99);

                                if (number1 < 20) {
                                    number2 = randomIntFromRange(
                                        20,
                                        100 - number1,
                                    );
                                } else {
                                    number2 = randomIntFromRange(
                                        1,
                                        100 - number1,
                                    );
                                }

                                number3 = randomIntFromRange(
                                    1,
                                    number1 + number2 - 20,
                                );

                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(1, 999);

                                if (number1 < 100) {
                                    number2 = randomIntFromRange(
                                        100,
                                        1000 - number1,
                                    );
                                } else {
                                    number2 = randomIntFromRange(
                                        1,
                                        1000 - number1,
                                    );
                                }

                                number3 = randomIntFromRange(
                                    1,
                                    number1 + number2 - 100,
                                );

                                break;
                        }

                        answer = number1 + number2 - number3;
                        break;
                    case 'a - b + c':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(1, 9);

                                number2 = randomIntFromRange(1, number1);

                                number3 = randomIntFromRange(
                                    1,
                                    10 - number1 + number2,
                                );

                                break;
                            case '1..20':
                                number1 = randomIntFromRange(1, 19);

                                number2 = randomIntFromRange(1, number1);

                                number3 = randomIntFromRange(
                                    1,
                                    20 - number1 + number2,
                                );

                                break;
                            case '10..20':
                                number1 = randomIntFromRange(10, 19);

                                number2 = randomIntFromRange(1, number1);

                                if (number1 - number2 < 10) {
                                    number3 = randomIntFromRange(
                                        10,
                                        20 - number1 + number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        20 - number1 + number2,
                                    );
                                }

                                break;
                            case '20..100':
                                number1 = randomIntFromRange(1, 99);

                                number2 = randomIntFromRange(1, number1);

                                if (number1 - number2 < 10) {
                                    number3 = randomIntFromRange(
                                        10,
                                        100 - number1 + number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        100 - number1 + number2,
                                    );
                                }

                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(1, 999);

                                number2 = randomIntFromRange(1, number1);

                                if (number1 - number2 < 50) {
                                    number3 = randomIntFromRange(
                                        50,
                                        1000 - number1 + number2,
                                    );
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        1000 - number1 + number2,
                                    );
                                }

                                break;
                        }

                        answer = number1 - number2 + number3;

                        break;
                    case 'a - b - c':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(3, 10);
                                break;
                            case '1..20':
                                number1 = randomIntFromRange(3, 20);
                                break;
                            case '10..20':
                                number1 = randomIntFromRange(10, 20);
                                break;
                            case '20..100':
                                number1 = randomIntFromRange(20, 100);
                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(100, 1000);
                                break;
                        }

                        number2 = randomIntFromRange(1, number1 - 1);

                        number3 = randomIntFromRange(1, number1 - number2);

                        answer = number1 - number2 - number3;

                        if (answer === 0) {
                            return this.generateQuestion(numberGenerator);
                        }

                        break;
                    case 'a + (b - c)':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(1, 9);

                                number2 = randomIntFromRange(1, 10 - number1);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '1..20':
                                number1 = randomIntFromRange(1, 19);

                                number2 = randomIntFromRange(1, 20 - number1);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '10..20':
                                number1 = randomIntFromRange(10, 19);

                                number2 = randomIntFromRange(20 - number1, 20);

                                number3 = randomIntFromRange(
                                    number1 + number2 - 20,
                                    number2,
                                );

                                break;
                            case '20..100':
                                number1 = randomIntFromRange(20, 99);

                                number2 = randomIntFromRange(
                                    100 - number1,
                                    100,
                                );

                                number3 = randomIntFromRange(
                                    number1 + number2 - 100,
                                    number2,
                                );

                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(100, 999);

                                number2 = randomIntFromRange(
                                    1000 - number1,
                                    1000,
                                );

                                number3 = randomIntFromRange(
                                    number1 + number2 - 1000,
                                    number2,
                                );

                                break;
                        }

                        answer = number1 + (number2 - number3);

                        break;
                    case 'a - (b - c)':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(1, 10);

                                number2 = randomIntFromRange(1, number1);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '1..20':
                                number1 = randomIntFromRange(1, 20);

                                number2 = randomIntFromRange(1, number1);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '10..20':
                                number1 = randomIntFromRange(11, 20);

                                number2 = randomIntFromRange(1, number1 - 10);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '20..100':
                                number1 = randomIntFromRange(21, 100);

                                number2 = randomIntFromRange(1, number1 - 20);

                                number3 = randomIntFromRange(1, number2);

                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(101, 1000);

                                number2 = randomIntFromRange(1, number1 - 100);

                                number3 = randomIntFromRange(1, number2);

                                break;
                        }

                        answer = number1 - (number2 - number3);

                        break;
                    case 'a - (b + c)':
                        switch ([scale].flat()[0]) {
                            case '1..10':
                                number1 = randomIntFromRange(3, 10);
                                break;
                            case '1..20':
                                number1 = randomIntFromRange(3, 20);
                                break;
                            case '10..20':
                                number1 = randomIntFromRange(10, 20);
                                break;
                            case '20..100':
                                number1 = randomIntFromRange(20, 100);
                                break;
                            case '100..1000':
                                number1 = randomIntFromRange(100, 1000);
                                break;
                        }

                        number2 = randomIntFromRange(1, number1 - 1);

                        number3 = randomIntFromRange(1, number1 - number2);

                        answer = number1 - (number2 + number3);

                        if (answer === 0) {
                            return this.generateQuestion(numberGenerator);
                        }

                        break;
                }
                break;
            case 'multiplicationAndDividing':
                switch (expression) {
                    case 'a * b * c':
                        switch ([scale].flat()[0]) {
                            case '1..20':
                                number1 = randomIntFromRange(1, 5);

                                number2 = randomIntFromRange(1, 4);

                                number3 = randomIntFromRange(
                                    1,
                                    Math.floor(20 / (number1 * number2)),
                                );

                                break;
                            case '1..50':
                                number1 = randomIntFromRange(1, 5);

                                number2 = randomIntFromRange(2, 10);

                                number3 = randomIntFromRange(
                                    1,
                                    Math.floor(50 / (number1 * number2)),
                                );

                                break;
                            case '1..100':
                                number1 = randomIntFromRange(2, 5);

                                number2 = randomIntFromRange(2, 10);

                                number3 = randomIntFromRange(
                                    1,
                                    Math.floor(100 / (number1 * number2)),
                                );

                                break;
                            case '10..5000':
                                number1 = randomIntFromRange(2, 5);

                                const number2Options = [10, 25, 50];

                                number2 =
                                    number2Options[
                                        randomIntFromRange(
                                            0,
                                            number2Options.length - 1,
                                        )
                                    ];

                                const number3Options = [2, 4, 6, 8, 10];

                                number3 =
                                    number3Options[
                                        randomIntFromRange(
                                            0,
                                            number3Options.length - 1,
                                        )
                                    ];

                                break;
                        }

                        answer = number1 * number2 * number3;

                        break;
                    case 'a * b : c':
                        number3 = randomIntFromRange(1, 10);

                        number2 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..20':
                                number1 = randomIntFromRange(1, 2) * number3;
                                break;
                            case '1..50':
                                number1 = randomIntFromRange(1, 5) * number3;
                                break;
                            case '1..100':
                                number1 = randomIntFromRange(1, 10) * number3;
                                break;
                            case '10..5000':
                                number3 = randomIntFromRange(2, 10);

                                number2 = randomIntFromRange(2, 10) * 10;

                                number1 = randomIntFromRange(2, 10) * number3;

                                break;
                        }

                        answer = (number1 * number2) / number3;

                        break;
                    case 'a : b * c':
                        number3 = randomIntFromRange(1, 10);

                        number2 = randomIntFromRange(2, 10);

                        switch ([scale].flat()[0]) {
                            case '1..20':
                                number1 = randomIntFromRange(1, 2) * number2;
                                break;
                            case '1..50':
                                number1 = randomIntFromRange(1, 5) * number2;
                                break;
                            case '1..100':
                                number1 = randomIntFromRange(1, 10) * number2;
                                break;
                            case '10..5000':
                                number3 = randomIntFromRange(2, 10) * 10;

                                number2 = randomIntFromRange(2, 10) * 10;

                                number1 = randomIntFromRange(2, 10) * number2;

                                answer = (number1 / number2) * number3;

                                if (answer > 5000) {
                                    return this.generateQuestion(
                                        numberGenerator,
                                    );
                                }

                                break;
                        }

                        answer = (number1 / number2) * number3;

                        break;
                }

                break;
            case 'operationsMix':
                switch (expression) {
                    case 'a + b * c':
                        number2 = randomIntFromRange(2, 9);

                        if (number2 < 5) {
                            number3 = randomIntFromRange(1, 10);
                        } else {
                            number3 = randomIntFromRange(1, 10 - number2);
                        }

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number1 =
                                    50 -
                                    randomIntFromRange(number2 * number3, 49);
                                break;
                            case '1..100':
                                number1 =
                                    100 -
                                    randomIntFromRange(number2 * number3, 99);
                                break;
                            case '10..1000':
                                number2 = randomIntFromRange(2, 12);

                                if (number2 < 10) {
                                    number3 = randomIntFromRange(2, 10) * 10;
                                } else {
                                    number3 = randomIntFromRange(2, 10);
                                }

                                number1 = randomIntFromRange(
                                    1,
                                    1000 - number2 * number3,
                                );

                                break;
                        }

                        answer = number1 + number2 * number3;

                        break;
                    case 'a * b + c':
                        number1 = randomIntFromRange(2, 9);

                        if (number1 < 5) {
                            number2 = randomIntFromRange(1, 10);
                        } else {
                            number2 = randomIntFromRange(1, 10 - number1);
                        }

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number3 =
                                    50 -
                                    randomIntFromRange(number1 * number2, 49);
                                break;
                            case '1..100':
                                number3 =
                                    100 -
                                    randomIntFromRange(number1 * number2, 99);
                                break;
                            case '10..1000':
                                number1 = randomIntFromRange(2, 12);

                                if (number1 < 10) {
                                    number2 = randomIntFromRange(2, 10) * 10;
                                } else {
                                    number2 = randomIntFromRange(2, 10);
                                }

                                number3 = randomIntFromRange(
                                    1,
                                    1000 - number1 * number2,
                                );

                                const randomize = randomIntFromRange(0, 1);

                                if (randomize) {
                                    const temp = number1;

                                    number1 = number2;

                                    number2 = temp;
                                }

                                break;
                        }

                        answer = number1 * number2 + number3;

                        break;
                    case '(a + b) * c':
                        number1 = randomIntFromRange(1, 9);

                        number2 = randomIntFromRange(1, 10 - number1);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                if (number1 + number2 < 5) {
                                    number3 = randomIntFromRange(1, 10);
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        50 / (number1 + number2),
                                    );
                                }

                                break;
                            case '1..100':
                                number3 = randomIntFromRange(2, 10);
                                break;
                            case '10..1000':
                                number1 = number1 * 10;

                                number2 = number2 * 10;

                                if (number1 + number2 < 100) {
                                    number3 = randomIntFromRange(2, 5);
                                } else {
                                    number3 = randomIntFromRange(2, 10);
                                }

                                break;
                        }

                        answer = (number1 + number2) * number3;

                        break;
                    case 'a * (b + c)':
                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 9);

                                number3 = randomIntFromRange(1, 10 - number2);

                                if (number2 + number3 < 5) {
                                    number1 = randomIntFromRange(1, 10);
                                } else {
                                    number1 = randomIntFromRange(
                                        1,
                                        50 / (number2 + number3),
                                    );
                                }

                                break;
                            case '1..100':
                                number1 = randomIntFromRange(2, 10);

                                number2 = randomIntFromRange(2, 9);

                                number3 = randomIntFromRange(1, 10 - number2);

                                break;
                            case '10..1000':
                                number2 = randomIntFromRange(1, 9) * 10;

                                number3 = randomIntFromRange(1, 10) * 10;

                                if (number2 + number3 > 100) {
                                    number1 = randomIntFromRange(2, 5);
                                } else {
                                    number1 = randomIntFromRange(2, 10);
                                }

                                break;
                        }

                        answer = number1 * (number2 + number3);

                        break;
                    case 'a - b * c':
                        number2 = randomIntFromRange(2, 9);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                if (number2 < 5) {
                                    number3 = randomIntFromRange(1, 10);
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        10 - number2,
                                    );
                                }

                                number1 = randomIntFromRange(
                                    number2 * number3,
                                    50,
                                );

                                break;
                            case '1..100':
                                number3 = randomIntFromRange(1, 10);

                                number1 = randomIntFromRange(
                                    number2 * number3,
                                    100,
                                );

                                break;
                            case '10..1000':
                                number2 = randomIntFromRange(2, 12);

                                number3 = randomIntFromRange(2, 10);

                                if (number2 < 10) {
                                    number3 = number3 * 10;
                                }

                                number1 = randomIntFromRange(
                                    number2 * number3,
                                    1000,
                                );

                                const randomize = randomIntFromRange(0, 1);

                                if (randomize) {
                                    const temp = number2;

                                    number2 = number3;

                                    number3 = temp;
                                }

                                break;
                        }

                        // prettier-ignore
                        answer = number1 - (number2 * number3);

                        break;
                    case 'a * b - c':
                        number1 = randomIntFromRange(2, 9);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                if (number1 < 5) {
                                    number2 = randomIntFromRange(1, 10);
                                } else {
                                    number2 = randomIntFromRange(
                                        1,
                                        10 - number1,
                                    );
                                }

                                number3 = randomIntFromRange(
                                    1,
                                    number1 * number2,
                                );

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 10);

                                number3 = randomIntFromRange(
                                    1,
                                    number1 * number2,
                                );

                                break;
                            case '10..1000':
                                number1 = number1 * 10;

                                number2 = randomIntFromRange(1, 10);

                                number3 = randomIntFromRange(
                                    1,
                                    number1 * number2,
                                );

                                break;
                        }

                        answer = number1 * number2 - number3;

                        if (answer === 0) {
                            return this.generateQuestion(numberGenerator);
                        }

                        break;
                    case '(a - b) * c':
                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 10);

                                number1 = randomIntFromRange(number2, 10);

                                if (number1 - number2 < 5) {
                                    number3 = randomIntFromRange(1, 10);
                                } else {
                                    number3 = randomIntFromRange(
                                        1,
                                        Math.floor(50 / (number1 - number2)),
                                    );
                                }

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 90);

                                number1 = number2 + randomIntFromRange(1, 10);

                                number3 = randomIntFromRange(1, 10);

                                break;
                            case '10..1000':
                                number2 = randomIntFromRange(1, 90);

                                number1 = number2 + randomIntFromRange(1, 10);

                                number3 = randomIntFromRange(1, 10) * 10;

                                break;
                        }

                        answer = (number1 - number2) * number3;

                        if (answer === 0) {
                            return this.generateQuestion(numberGenerator);
                        }

                        break;
                    case 'a : b + c':
                        number2 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number1 = randomIntFromRange(1, 5) * number2;

                                number3 = randomIntFromRange(
                                    1,
                                    Math.floor(50 - number1 / number2),
                                );

                                break;
                            case '1..100':
                                number1 = randomIntFromRange(1, 10) * number2;

                                number3 = randomIntFromRange(
                                    1,
                                    Math.floor(100 - number1 / number2),
                                );

                                break;
                            case '10..1000':
                                number2 = number2 * 10;

                                number1 = randomIntFromRange(1, 10) * number2;

                                number3 = randomIntFromRange(
                                    10,
                                    1000 - Math.floor(number1 / number2),
                                );

                                break;
                        }

                        answer = number1 / number2 + number3;

                        break;
                    case 'a + b : c':
                        number3 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 = randomIntFromRange(
                                    1,
                                    Math.floor(50 - number2 / number3),
                                );

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 10) * number3;

                                number1 = randomIntFromRange(
                                    1,
                                    Math.floor(100 - number2 / number3),
                                );

                                break;
                            case '10..1000':
                                number2 =
                                    randomIntFromRange(1, 10) * number3 * 10;

                                number1 = randomIntFromRange(
                                    1,
                                    Math.floor(1000 - number2 / number3),
                                );

                                break;
                        }

                        answer = number1 + number2 / number3;

                        break;
                    case 'a - b : c':
                        number3 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 = randomIntFromRange(
                                    number2 / number3,
                                    50,
                                );

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 10) * number3;

                                number1 = randomIntFromRange(
                                    number2 / number3,
                                    100,
                                );

                                break;
                            case '10..1000':
                                number2 =
                                    randomIntFromRange(1, 10) * number3 * 10;

                                number1 =
                                    randomIntFromRange(number2 / number3, 100) *
                                    10;

                                break;
                        }

                        // prettier-ignore
                        answer = number1 - (number2 / number3);

                        if (answer === 0) {
                            return this.generateQuestion(numberGenerator);
                        }

                        break;
                    case '(a + b) : c':
                        number3 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 = randomIntFromRange(1, 5) * number3;

                                if (number1 + number2 > 50) {
                                    return this.generateQuestion(
                                        numberGenerator,
                                    );
                                }

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 = randomIntFromRange(1, 5) * number3;

                                break;
                            case '10..1000':
                                number3 = number3 * 10;

                                number2 = randomIntFromRange(1, 10) * number3;

                                number1 = randomIntFromRange(1, 10) * number3;

                                if (number1 + number2 > 1000) {
                                    return this.generateQuestion(
                                        numberGenerator,
                                    );
                                }

                                break;
                        }

                        answer = (number1 + number2) / number3;

                        break;
                    case '(a - b) : c':
                        number3 = randomIntFromRange(1, 10);

                        switch ([scale].flat()[0]) {
                            case '1..50':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 =
                                    randomIntFromRange(1, 5) * number3 +
                                    number2;

                                if (number1 > 50) {
                                    return this.generateQuestion(
                                        numberGenerator,
                                    );
                                }

                                break;
                            case '1..100':
                                number2 = randomIntFromRange(1, 5) * number3;

                                number1 =
                                    randomIntFromRange(1, 5) * number3 +
                                    number2;

                                break;
                            case '10..1000':
                                number3 = number3 * 10;

                                number2 = randomIntFromRange(1, 10) * number3;

                                number1 =
                                    randomIntFromRange(1, 10) * number3 +
                                    number2;

                                if (number1 > 1000) {
                                    return this.generateQuestion(
                                        numberGenerator,
                                    );
                                }

                                break;
                        }

                        answer = (number1 - number2) / number3;

                        break;
                }

                break;
        }

        return {
            expression,
            number1,
            number2,
            number3,
            answer,
        };
    }

    static formatQuestion(questionData, skill, calledIn) {
        // console.log('FQ', questionData, skill);
        let { expression, number1, number2, number3 } = questionData;

        let { topic, scale } = skill.numberGenerator;

        if (!number3 && number3 !== 0) {
            number1 = questionData.nr1;

            number2 = questionData.nr2;

            number3 = questionData.nr3;

            expression = questionData.data.expression;
        }

        if (
            topic === 'decimals' &&
            !isNumeric(scale) &&
            [0.1, 0.01, 0.001].some((item) => scale.includes(item))
        ) {
            return expression
                .replaceAll(':', '÷')
                .replaceAll('*', 'x')
                .replace(
                    'a',
                    parseFloat(number1).toLocaleString(this.locale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 3,
                    }),
                )
                .replace(
                    'b',
                    parseFloat(number2).toLocaleString(this.locale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 3,
                    }),
                )
                .replace(
                    'c',
                    parseFloat(number3).toLocaleString(this.locale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 3,
                    }),
                );
        }

        return expression
            .replace(/:/g, '÷')
            .replace(/\*/g, 'x')
            .replace('a', parseFloat(number1).toLocaleString(this.locale()))
            .replace('b', parseFloat(number2).toLocaleString(this.locale()))
            .replace('c', parseFloat(number3).toLocaleString(this.locale()));
    }

    static answerData(question, answer, skill) {
        return {
            expression: question.expression,
        };
    }
}
