<template>
    <div v-if="!unauthorizedAccount" class="content">
        <div class="already-subscribed">You have unlocked the Math Pass!</div>
        <img
            src="/images/home-game/icon-battle-pass.svg"
            class="image"
            alt=""
        />

        <div class="cta" @click="$emit('closeModal')">
            <div class="text">
                {{ $t('homeGame.goPlay') }}
            </div>
        </div>
    </div>
    <div v-else class="content">
        <div class="already-subscribed">Thanks for buying the Math Pass!</div>
        <img
            src="/images/home-game/icon-battle-pass.svg"
            class="image"
            alt=""
        />
        <h2 class="already-subscribed-body">
            If your kid is playing, ask them to refresh to see the new Math Pass
            rewards.
        </h2>
    </div>
</template>
<script>
export default {
    props: {
        unauthorizedAccount: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closeModal'],
    created() {
        if (this.$route?.query?.redirect_status) {
            this.$router.replace(this.$route.path);
        }
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .already-subscribed {
        font-style: normal;
        font-weight: 900;
        font-size: 3.5rem;
        line-height: 4.5rem;
        text-align: center;
        color: #ffffff;
        text-shadow: 0 0 50px rgba(255, 245, 0, 0.5);
        position: relative;
        margin: 3rem 3rem 1.5rem;
    }

    .already-subscribed-body {
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        line-height: 3rem;
        max-width: 800px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0 0 50px rgba(255, 245, 0, 0.5);
        position: relative;
        margin: 3rem 3rem 1.5rem;
    }

    .image {
        width: 10vw;
        filter: drop-shadow(0 0 50px rgba(255, 245, 0, 0.5));
    }

    .cta {
        position: absolute;
        bottom: 30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        text-align: center;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }

        .text {
            font-size: 30px;
            font-weight: bold;
        }

        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .secondary {
            box-sizing: border-box;
            .before-after {
                margin-top: 10px;

                .free,
                .premium {
                    .package-name-text {
                        margin-bottom: 6px;
                        font-size: 16px;
                    }

                    img {
                        width: 110px;
                        height: 45px;
                    }
                }
            }
        }
    }
}
</style>
