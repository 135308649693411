<template>
    <div
        :class="{
            'c-loader--small': variation === 'small',
            'c-loader--xs-small': variation === 'xs-small',
            'c-loader--absolute': display === 'absolute',
            'c-loader--solid': solid,
        }"
        class="c-loader"
    >
        <div class="c-loader__wrapper">
            <div class="c-loader__wrapper--loading">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        variation: {
            type: String,
            required: false,
            validator: function (value) {
                return ['small', 'xs-small'].indexOf(value) !== -1;
            },
        },
        display: {
            type: String,
            required: false,
            default: 'absolute',
            validator: function (value) {
                return (
                    ['absolute', 'relative', 'initial'].indexOf(value) !== -1
                );
            },
        },
        solid: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.c-loader {
    z-index: 100000;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        height: 250px;
        width: 250px;
        background-color: $secondary-theme-color;
        border-radius: 100%;
        display: flex;
        align-items: center;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);

        &--loading {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 40px;

            div {
                position: absolute;
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background: #fff;
                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                &:nth-child(1) {
                    left: 6px;
                    animation: c-loader1 0.6s infinite;
                }

                &:nth-child(2) {
                    left: 6px;
                    animation: c-loader2 0.6s infinite;
                }

                &:nth-child(3) {
                    left: 78px;
                    animation: c-loader2 0.6s infinite;
                }

                &:nth-child(4) {
                    left: 135px;
                    animation: c-loader3 0.6s infinite;
                }
            }
        }
    }

    &--absolute {
        position: absolute;
        background-color: rgba($color: #be88f4, $alpha: 0.8);
        height: 100vh;
        min-height: 100%;

        .c-loader__wrapper {
            position: fixed;
            top: 40%;
        }
    }

    &--solid {
        position: absolute;
        background-color: #be88f4;
        height: 100vh;
        min-height: 100%;

        .c-loader__wrapper {
            position: fixed;
            top: 40%;
        }
    }

    &--small {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }

    &--xs-small {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px 0;

        .c-loader__wrapper {
            height: 64px;
            width: 64px;

            &--loading {
                margin-left: 5px;

                div {
                    width: 10px;
                    height: 10px;

                    &:nth-child(1) {
                        left: 2px;
                        animation: c-loader1 1s infinite;
                    }

                    &:nth-child(2) {
                        left: 16px;
                        animation: c-loader3 0.6s infinite;
                    }

                    &:nth-child(3) {
                        left: 30px;
                        animation: c-loader3 0.6s infinite;
                    }

                    &:nth-child(4) {
                        left: 45px;
                        animation: c-loader3 0.6s infinite;
                    }
                }
            }
        }
    }
}

@keyframes c-loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes c-loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes c-loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(57px, 0);
    }
}
</style>
