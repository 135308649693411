<template>
    <Modal v-if="this.showModal" class="idle-modal">
        <div v-if="!this.userInactive">
            You will become inactive in
            <br />
            <div class="time-left">{{ timeLeft }} seconds.</div>
            <br />
            Click anywhere if you want to continue
        </div>
        <div v-else-if="this.userInactive">
            You were inactive.
            <br />
            <br />
            Click anywhere to resume session
        </div>
    </Modal>
</template>

<script>
import Modal from '@/core/ui/Modal/Modal.vue';
import { debounce } from 'lodash';
import moment from 'moment';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            IDLE_TIMEOUT: 360,
            IDLE_PRE_TIMEOUT: 300,
            idleSecondsTimer: 0,
            timeLeft: 0,
            showModal: false,
            userInactive: false,
            allowedRouteNames: [
                'host',
                'old.host',
                'join',
                'home-game.v10.main-page',
            ],
            lastActivity: null,
        };
    },
    computed: {
        isAllowedPath() {
            return this.allowedRouteNames.includes(this.$route.name);
        },
    },
    watch: {
        $route() {
            this.checkPath();
        },
    },
    mounted() {
        this.getLocalStorageTimeouts();

        this.checkPath();
    },
    methods: {
        setCurrentTime() {
            this.showModal = false;

            this.lastActivity = moment();

            this.timeLeft = 0;

            this.checkPath();
        },
        onClickListener() {
            this.setCurrentTime();

            if (this.userInactive) {
                location.reload();
            }
        },
        onMouseMove: debounce(function () {
            this.setCurrentTime();
        }, 300),
        handleEventListeners(isRemove = false) {
            if (isRemove) {
                document.removeEventListener('click', this.onClickListener);

                document.removeEventListener('mousemove', this.onMouseMove);

                document.removeEventListener('keypress', this.setCurrentTime);
            } else {
                document.addEventListener('click', this.onClickListener);

                document.addEventListener('mousemove', this.onMouseMove);

                document.addEventListener('keypress', this.setCurrentTime);
            }
        },
        checkPath() {
            if (this.isAllowedPath) {
                this.lastActivity = moment();

                clearInterval(this.idleSecondsTimer);

                this.idleSecondsTimer = setInterval(this.checkIdleTime, 1000);

                this.handleEventListeners();
            } else {
                this.clearIdleInterval();

                this.handleEventListeners(true);
            }
        },
        checkIdleTime() {
            const currentTime = moment();

            const diff = currentTime.diff(this.lastActivity, 'seconds');

            if (diff >= this.IDLE_PRE_TIMEOUT) {
                this.showModal = true;

                this.timeLeft = this.IDLE_TIMEOUT - diff;
            }

            if (diff >= this.IDLE_TIMEOUT) {
                this.showModal = true;

                this.userInactive = true;

                this.clearIdleInterval();
            }
        },
        clearIdleInterval() {
            clearInterval(this.idleSecondsTimer);

            this.idleSecondsTimer = 0;

            this.timeLeft = 0;
        },
        getLocalStorageTimeouts() {
            const localTimeout = localStorage?.getItem('IDLE_TIMEOUT') || 0;
            const localPreTimeout =
                localStorage?.getItem('IDLE_PRE_TIMEOUT') || 0;

            if (localTimeout) {
                this.IDLE_TIMEOUT = +localTimeout;
            }

            if (localPreTimeout) {
                this.IDLE_PRE_TIMEOUT = +localPreTimeout;
            }
        },
    },
};
</script>

<style lang="scss">
.idle-modal {
    .c-modal--content {
        border-radius: 1rem;
        padding: 1rem;
        font-size: 1.5rem;
        font-style: normal;
        overflow: hidden;

        .time-left {
            margin-top: 20px;
            border-radius: 1rem;
            padding: 1rem;
            font-size: 1.5rem;
            background: rgba(255, 255, 255, 0.4);
        }
    }
}
</style>
