<template>
    <div class="reporting-tech-issue">
        <h2>Reporting a technical issue</h2>

        <template v-if="!user || !user.email">
            <p>Contact email (*)</p>
            <input v-model="email" :class="{ error: emailError }" type="text" />
        </template>

        <template v-if="isTeacher">
            <p>Does it happen to you as a teacher or to students?</p>
            <select v-model="issueForWho">
                <option value="teacher">To me</option>
                <option value="students">To students</option>
            </select>

            <p>What devices are being used?</p>
            <input v-model="devicesUsed" type="text" />
        </template>

        <p>Has it happened before? How often?</p>

        <input v-model="happenedBefore" type="text" />

        <p>Please describe the issue as detailed as possible (*)</p>
        <textarea
            v-model="issueDescription"
            :class="{ error: issueDescriptionError }"
        />

        <p v-if="ticketCreationError" class="error-text">
            Something went wrong. Please check the form or reach out to us via
            hello@99math.com
        </p>
        <p v-if="issueDescriptionError" class="error-text">
            Issue description is required. It helps us understand what went
            wrong.
        </p>
        <p v-if="emailError" class="error-text">
            Email is required. It helps us reach back to you.
        </p>
        <MathButton @click="createTicket">Submit</MathButton>
    </div>
</template>

<script>
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';
import { mapGetters } from 'vuex';
import { isTeacher } from '@/flows/Authentication/helpers/AuthHelpers';
export default {
    components: { MathButton },
    props: {
        ticketCreationError: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['ticketDataSubmit'],
    data() {
        return {
            email: this.user?.email,
            emailError: false,
            issueForWho: 'teacher',
            devicesUsed: null,
            happenedBefore: null,
            issueDescription: null,
            issueDescriptionError: false,
            applicationVersion: import.meta.env.VITE_APP_VERSION,
        };
    },
    computed: {
        ...mapGetters(['user']),
        isTeacher() {
            return isTeacher(this.user);
        },
    },
    watch: {
        issueDescription(newVal) {
            this.issueDescriptionError = newVal === '';
        },
        email(newVal) {
            this.emailError = newVal === '';
        },
    },
    mounted() {
        if (this.user) {
            if (!this.email) {
                this.email = this.user.email;
            }

            if (!this.isTeacher) {
                this.issueForWho = 'students';
            }
        }
    },
    methods: {
        async createTicket() {
            if (!this.issueDescription) {
                this.issueDescriptionError = true;
            }

            if (!this.email) {
                this.emailError = true;
            }

            if (this.issueDescriptionError || this.emailError) {
                return;
            }

            this.$emit('ticketDataSubmit', {
                email: this.email,
                issueForWho: this.issueForWho,
                devicesUsed: this.devicesUsed,
                happenedBefore: this.happenedBefore,
                issueDescription: this.issueDescription,
                applicationVersion: this.applicationVersion,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.reporting-tech-issue {
    text-align: center;
    max-width: 90vw;
    width: 100%;
    box-sizing: border-box;

    * {
        color: #000;
    }

    h2 {
        margin-top: 3rem;
    }

    select,
    input,
    textarea {
        min-width: 340px;
        box-sizing: border-box;
        border: 1px solid #e3e3e3;
        min-height: 40px;
        border-radius: 4px;
        background-color: #fff;
        padding: 0 10px;
        height: 2rem;
        width: 100%;
        box-shadow: 0 4px 4px rgb(0 0 0 / 20%);

        @include mq($w-max-phone, max) {
            min-width: 280px;
        }
    }

    .error {
        border: 2px red;

        &-text {
            color: red;
        }
    }

    textarea {
        height: 3.5rem;
    }

    p {
        margin: 1.5rem 0 0.5rem;
    }

    .math-button {
        margin: 1rem auto;
        color: #fff;
    }
}
</style>
