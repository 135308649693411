import ZingTouch from 'zingtouch';

export function initGestures() {
    const gestureArea = document.getElementById('app');
    // new ZingTouch.Region(gestureArea).bind(
    //     gestureArea,
    //     new ZingTouch.Tap({
    //         numInputs: 4,
    //         minDelay: 2000,
    //     }),
    //     function (e) {
    //         console.log('=== 3tap', e);
    //     },
    // );

    bindGestureSequence(
        new ZingTouch.Region(gestureArea, false, false),
        gestureArea,
        [
            new ZingTouch.Tap({ numInputs: 4 }),
            new ZingTouch.Tap({ numInputs: 4 }),
        ],
        () => {
            console.log('=== 2x4tap, handlers.length', gestureHandlers.length);
            gestureHandlers.forEach((handler) => handler());
        },
    );
}

const gestureHandlers = [];
// this should be used externally to consume the gesture event
export const onDouble4tapGesture = (handler) => {
    gestureHandlers.push(handler);
};

export function bindGestureSequence(
    region,
    area,
    gestureArray,
    handlerOnAllGestures,
    timeout = 300,
) {
    let currentGestureIndex = 0;
    let gestureTimeout;

    const clearGestureSequence = () => {
        clearTimeout(gestureTimeout);
        currentGestureIndex = 0;
    };

    const sequenceHandler = (e) => {
        console.log(
            '=== sub gesture handler',
            gestureArray[currentGestureIndex]?.constructor?.name,
            e,
        );
        // Clear the previous timeout
        clearTimeout(gestureTimeout);

        // Check if the gesture is the expected one in the sequence
        if (
            gestureArray[currentGestureIndex] /* &&
            e.detail.type === gestureArray[currentGestureIndex].type*/
        ) {
            currentGestureIndex++;

            // Check if the entire sequence has been completed
            if (currentGestureIndex >= gestureArray.length) {
                clearGestureSequence();
                handlerOnAllGestures();
                return;
            }

            // Set up the next gesture in the sequence
            setupNextGesture();
        } else {
            // Reset if the gesture is not as expected
            clearGestureSequence();
        }
    };

    const setupNextGesture = () => {
        const currentGesture = gestureArray[currentGestureIndex];
        region.unbind(area, currentGesture); // Unbind the previous gesture
        region.bind(area, currentGesture, sequenceHandler);

        // Set a timeout to reset the sequence if the next gesture doesn't occur in time
        gestureTimeout = setTimeout(() => {
            clearGestureSequence();
            setupNextGesture(); // Rebind the first gesture to start over
        }, timeout);
    };

    // Start the sequence by binding the first gesture
    setupNextGesture();
}
