import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
    KEYBOARD_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    randomArrayElement,
    randomIntFromRange,
    getDecimalPlaces,
    hasOwnProp,
} from '@/core/helpers/utils';
import { isCorrectFractionAnswer } from '@/core/math-topics/utils/fractions';
import {
    createRectangleFraction,
    createPie,
} from '@/core/math-topics/utils/draw';
import { getFractionLayout } from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class FractionsTopic extends TopicsBaseClass {
    static code = 'TYPE_FRACTIONS';
    static icon = '';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.fractions';

    /**
     * @param {Object} gameInfo
     * @returns {string}
     */
    static getNumberGeneratorName(gameInfo) {
        const {
            numberGenerator: { scale, topic, subtopic },
        } = gameInfo;

        if (!topic) {
            return '';
        }

        const createSubtopicName = (subtopic) => {
            switch (subtopic) {
                case 'improperFractionToMixedNumber':
                    return 'improperToMixed';
                case 'mixedNumberToImproperFraction':
                    return 'mixedToImproper';
                default:
                    return subtopic;
            }
        };
        try {
            if (topic === 'conversion') {
                if (!subtopic) {
                    return '';
                }

                const subtopicName = createSubtopicName(subtopic);

                if (
                    subtopicName === 'improperToMixed' ||
                    subtopicName === 'mixedToImproper'
                ) {
                    return `${this.t(
                        `host.create.fractions["${subtopicName}"]`,
                    )} ${`...${scale}`}`;
                }

                if (
                    subtopicName === 'fractionToDecimal' ||
                    subtopicName === 'decimalToFraction'
                ) {
                    return `
                    ${this.t(`host.create.fractions["${subtopicName}"]`)}
                `;
                }
            }

            if (topic === 'fractionOfWhole') {
                const numeratorScale = scale.numerator.base2
                    ? `${scale.numerator.base1}..${scale.numerator.base2}`
                    : scale.numerator.base1;

                const denominatorScale = scale.denominator.base2
                    ? `${scale.denominator.base1}..${scale.denominator.base2}`
                    : scale.denominator.base1;

                return `${this.t(
                    `host.create.fractions["${topic}"]`,
                )} ${`${numeratorScale}/${denominatorScale}`}`;
            }

            return `${this.t(`host.create.fractions["${topic}"]`)} ${
                scale === '1' ? scale : `1..${scale}`
            }`;
        } catch (e) {
            console.log('Legacy games');
        }
    }

    /**
     * @param {Object} numberGenerator
     * @param {string} numberGenerator.topic
     * @param {string} numberGenerator.subtopic
     * @param {number} numberGenerator.scale
     * @returns {Object}
     */
    static generateQuestion(numberGenerator) {
        switch (numberGenerator.topic) {
            case 'conversion':
                return genConversionQuestion();
            case 'fractionOfWhole':
                return genFractionOfWholeQuestion();
            default:
                return genShapeQuestion();
        }

        function genShapeQuestion() {
            const { scale, topic } = numberGenerator;

            const shapes = ['square', 'circle'];

            const chosenShape =
                shapes[Math.floor(Math.random() * shapes.length)];

            const numeratorBase = scale;

            const denominatorMax = numeratorBase === 5 ? 5 : 10;

            const denominator = randomIntFromRange(2, denominatorMax);

            const numerator =
                numeratorBase === 1
                    ? 1
                    : randomIntFromRange(1, denominator - 1);

            return {
                topic,
                numerator,
                denominator,
                shape: chosenShape,
            };
        }

        function genConversionQuestion() {
            const { scale, topic, subtopic, numberType, wholeNumberOption } =
                numberGenerator;

            const NUMERATOR_BASE = 1;

            const DENOMINATOR_BASE = 2;

            const WHOLE_NUMBER_BASE = 1;
            let numerator;

            let denominator;

            let wholeNumber;

            let remainder;

            let float;

            if (!subtopic) {
                return;
            }

            switch (subtopic) {
                case 'improperFractionToMixedNumber':
                    denominator = randomIntFromRange(DENOMINATOR_BASE, scale);

                    numerator =
                        denominator *
                            randomIntFromRange(NUMERATOR_BASE, scale) +
                        randomIntFromRange(1, denominator - 1);

                    wholeNumber = parseInt(numerator / denominator);

                    remainder = numerator - denominator * wholeNumber;

                    break;

                case 'mixedNumberToImproperFraction':
                    denominator = randomIntFromRange(DENOMINATOR_BASE, scale);

                    numerator = randomIntFromRange(1, denominator - 1);

                    wholeNumber = randomIntFromRange(WHOLE_NUMBER_BASE, scale);

                    remainder = null;

                    break;

                case 'decimalToFraction':
                    denominator = randomArrayElement(scale.denominator);

                    const decimalPlaces = getDecimalPlaces(denominator);

                    const random1 = randomIntFromRange(1, 9);

                    const random2 = randomIntFromRange(10, 99);

                    const random3 = randomIntFromRange(100, 999);

                    let random;

                    if (wholeNumberOption === 'equal') {
                        switch (denominator) {
                            case 10:
                                numerator = random1;

                                break;
                            case 100:
                                numerator = randomArrayElement([
                                    random1,
                                    random2,
                                ]);

                                break;
                            case 1000:
                                numerator = randomArrayElement([
                                    random1,
                                    random2,
                                    random3,
                                ]);

                                break;
                            default:
                                break;
                        }

                        wholeNumber = 0;

                        float = numerator / denominator;
                    }

                    if (wholeNumberOption === 'greater') {
                        if (numberType === 'improperFraction') {
                            switch (denominator) {
                                case 10:
                                    wholeNumber = randomIntFromRange(1, 10);

                                    random = random1;

                                    break;
                                case 100:
                                    wholeNumber = randomIntFromRange(1, 5);

                                    random = randomArrayElement([
                                        random1,
                                        random2,
                                    ]);

                                    break;
                                case 1000:
                                    wholeNumber = randomIntFromRange(1, 2);

                                    random = randomArrayElement([
                                        random1,
                                        random2,
                                        random3,
                                    ]);

                                    break;
                                default:
                                    break;
                            }

                            float = wholeNumber + random / denominator;

                            numerator = Math.round(float * denominator);
                        }

                        if (numberType === 'mixedNumber') {
                            switch (denominator) {
                                case 10:
                                    wholeNumber = randomIntFromRange(1, 10);

                                    numerator = random1;

                                    break;
                                case 100:
                                    wholeNumber = randomIntFromRange(1, 5);

                                    numerator = randomArrayElement([
                                        random1,
                                        random2,
                                    ]);

                                    break;
                                case 1000:
                                    wholeNumber = randomIntFromRange(1, 2);

                                    numerator = randomArrayElement([
                                        random1,
                                        random2,
                                        random3,
                                    ]);

                                    break;
                                default:
                                    break;
                            }

                            float = wholeNumber + numerator / denominator;
                        }
                    }

                    float = float.toFixed(decimalPlaces);

                    break;

                case 'fractionToDecimal':
                    denominator = randomArrayElement(scale.denominator);

                    if (wholeNumberOption === 'equal') {
                        numerator = randomIntFromRange(1, denominator) - 1;

                        wholeNumber = 0;

                        float = numerator / denominator;
                    }

                    if (wholeNumberOption === 'greater') {
                        numerator = randomIntFromRange(
                            denominator + 1,
                            denominator * 10 - 1,
                        );

                        float = numerator / denominator;

                        wholeNumber = float.toString().split('.')[0];

                        wholeNumber = parseInt(wholeNumber);

                        remainder = numerator - denominator * wholeNumber;
                    }

                    break;

                default:
                    break;
            }

            return {
                topic,
                subtopic,
                numerator,
                denominator,
                wholeNumber,
                remainder,
                float,
                numberType,
                wholeNumberOption,
            };
        }

        function genFractionOfWholeQuestion() {
            const { topic, scale } = numberGenerator;

            const numeratorScale = scale.numerator;

            const denominatorScale = scale.denominator;

            const TEN = 10;

            const HUNDRED = 100;

            let numerator;

            let denominator;

            let wholeNumber;

            let answer;

            numerator = numeratorScale.base2
                ? randomIntFromRange(numeratorScale.base1, numeratorScale.base2)
                : numeratorScale.base1;

            denominator = denominatorScale.base2
                ? randomIntFromRange(
                      denominatorScale.base1,
                      denominatorScale.base2,
                  )
                : denominatorScale.base1;

            // Numerator = 1..10 || 1..12
            // Denominator = 10 || 100 || 2..10 || 2..12
            if (numeratorScale.base2) {
                wholeNumber =
                    randomIntFromRange(
                        numeratorScale.base1,
                        numeratorScale.base2,
                    ) * denominator;

                answer = (numerator * wholeNumber) / denominator;
            } else {
                // Numerator = 1
                // Denominator = 2..10 || 2..12
                if (denominatorScale.base2) {
                    const random1 =
                        randomIntFromRange(
                            numeratorScale.base1,
                            denominatorScale.base2,
                        ) * denominator;

                    const random2 =
                        randomIntFromRange(numeratorScale.base1, TEN) *
                        TEN *
                        denominator;

                    wholeNumber = randomArrayElement([random1, random2]);
                }
                // Numerator = 1
                // Denominator = 10 || 100
                else {
                    const random1 =
                        randomIntFromRange(numeratorScale.base1, HUNDRED) * TEN;

                    const random2 =
                        randomIntFromRange(numeratorScale.base1, TEN) * HUNDRED;

                    wholeNumber =
                        denominatorScale.base1 === 10
                            ? randomArrayElement([random1, random2])
                            : randomIntFromRange(
                                  numeratorScale.base1,
                                  denominatorScale.base1,
                              ) * denominatorScale.base1;
                }

                answer = wholeNumber / denominator;
            }

            return {
                topic,
                numerator,
                denominator,
                wholeNumber,
                answer,
            };
        }
    }

    /**
     * @param {Object} question
     * @param {Object} skill
     * @param {string} calledIn
     * @returns {string} HTML template string
     */
    static formatQuestion(question, skill, calledIn) {
        let {
            shape,
            topic,
            subtopic,
            numerator,
            denominator,
            wholeNumber,
            remainder,
            float,
            numberType,
            wholeNumberOption,
            data,
        } = question;

        if (data && !numerator && numerator !== 0) {
            topic = data.topic;
            subtopic = data.subtopic;
            numerator = data.numerator;
            denominator = data.denominator;
            remainder = data.remainder;
            float = data.float;
            numberType = data.numberType;
            wholeNumber = data.wholeNumber;
            wholeNumberOption = data.wholeNumberOption;
        }

        const separator = `fraction__separator${
            ['inGame', 'spLiveAnswer'].includes(calledIn) ? '-white' : ''
        }`;

        const fractionQuestion = () => `
            <div class="fraction">
                <div class="fraction__numerator">
                    ${numerator}
                </div>
                <div class="${separator}"></div>
                <div class="fraction__denominator">
                    ${denominator}
                </div>
            </div>
            ${
                calledIn === 'inGame' ||
                calledIn === 'inPhoneStudentViewExample'
                    ? `<div class="conversion-equals"> = ?</div>`
                    : ''
            }
        `;

        if (topic === 'shade') {
            return `${
                calledIn === 'inGame' ||
                calledIn === 'inPhoneStudentViewExample'
                    ? `<div class="fraction-question" style="font-size: 0.8rem; padding: 1rem 0; text-align: center">${this.t(
                          'host.create.fractions.shadePlayerInstructions',
                      )}</div>`
                    : ''
            }<div class="fraction">
                <div class="fraction__numerator">${numerator}</div>
                <div class="${separator}"></div>
                <div class="fraction__denominator">${denominator}</div>
            </div>`;
        }

        if (
            topic === 'conversion' &&
            subtopic === 'improperFractionToMixedNumber'
        ) {
            return `
                <div class="fraction">
                    <div class="fraction__numerator">${numerator}</div>
                    <div class="${separator}"></div>
                    <div class="fraction__denominator">${denominator}</div>
                </div>
                ${
                    calledIn === 'inGame' ||
                    calledIn === 'inPhoneStudentViewExample'
                        ? `<div class="conversion-equals"> = ?</div>`
                        : ''
                }
            `;
        }

        if (
            topic === 'conversion' &&
            subtopic === 'mixedNumberToImproperFraction'
        ) {
            return `
                <div class="mixed-fraction">
                    <div class="whole-number">${wholeNumber}</div>
                    <div class="improper-fraction">
                        <div class="fraction__numerator">${numerator}</div>
                        <div class="${separator}"></div>
                        <div class="fraction__denominator">${denominator}</div>
                    </div>
                </div>
                ${
                    calledIn === 'inGame' ||
                    calledIn === 'inPhoneStudentViewExample'
                        ? `<div class="conversion-equals"> = ?</div>`
                        : ''
                }
            `;
        }

        if (topic === 'fractionOfWhole') {
            return `
                <div class="question--inline">
                    <div class="fraction">
                        <div class="fraction__numerator">${numerator}</div>
                        <div class="${separator}"></div>
                        <div class="fraction__denominator">${denominator}</div>
                    </div>
                    <div>${this.t('host.create.fractions.ofNumber', false, {
                        number: wholeNumber,
                    })}</div>
                </div>
            `;
        }

        if (subtopic === 'decimalToFraction') {
            const locale = this.locale();

            const floatStr = float.toLocaleString(locale);

            return `
                <div class="mixed-fraction">
                    <div class="whole-number">
                        ${floatStr}
                    </div>
                </div>
                ${
                    calledIn === 'inGame' ||
                    calledIn === 'inPhoneStudentViewExample'
                        ? `<div class="conversion-equals"> = ?</div>`
                        : ''
                }
            `;
        }

        if (subtopic === 'fractionToDecimal') {
            if (wholeNumberOption === 'greater') {
                if (numberType === 'improperFraction') {
                    return fractionQuestion();
                }

                if (numberType === 'mixedNumber') {
                    return `
                        <div class="mixed-fraction">
                            ${
                                !wholeNumber || wholeNumber === 0
                                    ? ''
                                    : `<div class="whole-number">
                                        ${wholeNumber}
                                    </div>`
                            }
                            <div class="improper-fraction">
                                <div class="fraction__numerator">
                                    ${remainder}
                                </div>
                                <div class="${separator}"></div>
                                <div class="fraction__denominator">
                                    ${denominator}
                                </div>
                            </div>
                        </div>
                        ${
                            calledIn === 'inGame' ||
                            calledIn === 'inPhoneStudentViewExample'
                                ? `<div class="conversion-equals"> = ?</div>`
                                : ''
                        }
                    `;
                }
            }

            if (wholeNumberOption === 'equal') {
                return fractionQuestion();
            }
        }

        const R = calledIn === 'inGame' ? 30 : 15;

        const width = calledIn === 'inGame' ? 150 : 70;

        const height = calledIn === 'inGame' ? 40 : 20;

        switch (shape) {
            case 'square':
                return createRectangleFraction(
                    width,
                    height,
                    denominator,
                    numerator,
                    calledIn !== 'inGame',
                );
            case 'circle':
                return createPie(
                    R + 5,
                    R + 5,
                    R,
                    denominator,
                    numerator,
                    calledIn !== 'inGame',
                );
        }
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        if (topic === 'fractionOfWhole') {
            return question.answer === parseInt(answer);
        }

        if (subtopic === 'fractionToDecimal') {
            // In some languages comma is used
            // instead of dot to mark place values.
            const formattedPlayerAnswer =
                typeof answer === 'object'
                    ? answer.float.replace(/,/, '.')
                    : answer.replace(/,/, '.');

            const playerFloat = parseFloat(formattedPlayerAnswer);

            return playerFloat === question.float;
        }

        return isCorrectFractionAnswer(answer, question);
    }

    /**
     */
    static answerData(question, playerAnswer, skill) {
        const {
            numerator,
            denominator,
            float,
            remainder,
            wholeNumber,
            wholeNumberOption,
            numberType,
        } = question;

        const {
            numberGenerator: { topic, subtopic },
        } = skill;

        const isCorrectAnswer = this.isAnswerCorrect(
            question,
            playerAnswer,
            skill.numberGenerator,
        );

        return {
            numerator,
            denominator,
            float,
            remainder,
            wholeNumber,
            topic,
            subtopic,
            wholeNumberOption,
            numberType,
            correct: isCorrectAnswer,
            playerAnswer,
        };
    }

    /**
     * @param {Object} answer
     * @param {Object} skill
     * @param {Object} question
     * @param {Object} calledIn
     * @returns {string} HTML template string
     */
    static generatePlayerAnswerHtml(answer, skill, question, calledIn) {
        let numberGenerator = skill;

        if (hasOwnProp(skill, 'numberGenerator')) {
            // Needed for at least "topic === 'shade'".
            numberGenerator = skill.numberGenerator;
        }

        const { topic, subtopic } = numberGenerator;

        const { numerator, denominator, remainder, wholeNumber, float, ids } =
            answer;

        const hasFractionAnswer = subtopic !== 'fractionToDecimal';

        const numeratorNumber = remainder ? remainder : numerator;

        const numeratorTotal = wholeNumber
            ? wholeNumber * denominator + numerator
            : numerator;

        const floatNumber = float ? float : wholeNumber;

        const wholeNumberTotal =
            numerator && denominator
                ? (wholeNumber * numerator) / denominator
                : wholeNumber;

        const isWrongAnswer = question
            ? !isCorrectFractionAnswer(answer, question)
            : answer?.questionStats?.wrongAnswers;
        const wrongSelector = isWrongAnswer ? 'wrong-answer' : '';

        const fractionWrongSelector = '';

        if (typeof answer !== 'object') {
            return ` = <span class="${wrongSelector}">${answer}</span>`;
        }

        if (topic === 'shade') {
            // Answer is used in TBL "See correct answer" feature, where we don't
            // use the student's answer, but use the "correct" answer instead.
            if (question?.shape === 'square' || answer?.shape === 'square') {
                return (
                    '=' +
                    createRectangleFraction(
                        50,
                        30,
                        denominator,
                        numeratorNumber,
                        false,
                        ids,
                    )
                );
            }
            return (
                '=' +
                createPie(
                    30 + 5,
                    30 + 5,
                    30,
                    denominator,
                    numeratorNumber,
                    false,
                    ids,
                )
            );
        }

        if (topic === 'fractionOfWhole') {
            return `
                = <span class="${wrongSelector} mixed-fraction">
                    ${wholeNumberTotal}
                </span>
            `;
        }

        const mixedAnswer = () => `
            = <div class="${wrongSelector} mixed-fraction">
                <div class="fraction fraction__whole">${wholeNumber}</div>
                <div class="fraction ${fractionWrongSelector}">
                    <div class="fraction__numerator">${numeratorNumber}</div>
                    <div class="fraction__separator${
                        calledIn === 'inGame' ? '-white' : ''
                    }"></div>
                    <div class="fraction__denominator">${denominator}</div>
                </div>
            </div>
        `;

        const fractionsAnswer = () => `
            = <div class="${wrongSelector} fraction">
                <div class="fraction__numerator">${numeratorNumber}</div>
                <div class="fraction__separator${
                    calledIn === 'inGame' ? '-white' : ''
                }"></div>
                <div class="fraction__denominator">${denominator}</div>
            </div>
        `;

        const fractionsAnswerCalculated = () =>
            getFractionLayout(
                { numerator: numeratorTotal, denominator },
                isWrongAnswer,
                true,
                calledIn,
            );

        const floatAnswer = () =>
            `
            = <span class="${wrongSelector} fraction">
                ${floatNumber}
            </span>
        `;

        if (topic === 'conversion') {
            if (subtopic === 'mixedNumberToImproperFraction') {
                return fractionsAnswerCalculated();
            }

            if (!hasFractionAnswer || (!numerator && !denominator)) {
                return floatAnswer();
            }

            if (wholeNumber) {
                return mixedAnswer();
            }

            return fractionsAnswer();
        }

        return fractionsAnswer();
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        return this.generatePlayerAnswerHtml(
            question,
            numberGenerator,
            question,
            calledIn,
        );
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getAnswerInputType(numberGenerator) {
        const { topic, subtopic, wholeNumberOption, numberType } =
            numberGenerator;

        const isConversion = topic === 'conversion';

        const isMixedDecimalToFraction =
            subtopic === 'decimalToFraction' &&
            wholeNumberOption === 'greater' &&
            numberType === 'mixedNumber';

        const isFractionConversion =
            subtopic === 'decimalToFraction' &&
            (wholeNumberOption === 'equal' ||
                (wholeNumberOption === 'greater' &&
                    numberType === 'improperFraction'));

        const showDecimalInput =
            topic === 'fractionOfWhole' ||
            (isConversion && subtopic === 'fractionToDecimal');

        const showMixedInput =
            isConversion &&
            (subtopic === 'improperFractionToMixedNumber' ||
                isMixedDecimalToFraction);

        const showFractionInput =
            topic === 'identify' ||
            (isConversion &&
                (subtopic === 'mixedNumberToImproperFraction' ||
                    isFractionConversion));

        if (topic === 'shade') {
            return ANSWER_INPUT_TYPE.REVERSE_FRACTION;
        }

        if (showDecimalInput) {
            return ANSWER_INPUT_TYPE.DECIMAL_FRACTION;
        }

        if (showMixedInput) {
            return ANSWER_INPUT_TYPE.MIXED;
        }

        if (showFractionInput) {
            return ANSWER_INPUT_TYPE.IMPROPER_FRACTION;
        }

        return ANSWER_INPUT_TYPE.REGULAR;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getKeyboardType(numberGenerator) {
        const { topic } = numberGenerator;

        if (topic === 'shade') {
            return KEYBOARD_TYPE.ENTER;
        }

        return KEYBOARD_TYPE.NUMERIC;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {boolean}
     */
    static showArrowButtons(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        return !!(
            topic !== 'shade' &&
            topic !== 'fractionOfWhole' &&
            !(topic === 'conversion' && subtopic === 'fractionToDecimal')
        );
    }

    /**
     * @param {Object} numberGenerator
     * @returns {Object|string}
     */
    static resetPlayerAnswer(numberGenerator, question = null) {
        if (numberGenerator.topic === 'fractionOfWhole') {
            return super.resetPlayerAnswer(numberGenerator, question);
        }

        if (numberGenerator.topic === 'conversion') {
            if (numberGenerator.subtopic === 'fractionToDecimal') {
                return '';
            }

            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        if (numberGenerator.topic === 'shade') {
            return {
                wholeNumber: null,
                numerator: '',
                denominator: question ? question.denominator : '',
            };
        }

        return { numerator: '', denominator: '' };
    }
}
