<template>
    <div>
        <h3>Thank you!</h3>
        <p>
            We have received your information, we'll reach out to you as soon as
            possible.
        </p>
        <p>Thank you for helping us make 99math better!</p>
        <MathButton @click="$emit('close')">Close</MathButton>
    </div>
</template>

<script>
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';

export default {
    name: 'TicketCreated',
    components: { MathButton },
    emits: ['close'],
};
</script>

<style scoped lang="scss">
* {
    color: #000;
}
.math-button {
    margin: 2rem auto 1rem;
}
</style>
