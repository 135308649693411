export const DisplayExponentiation = {
    methods: {
        displayExponentiation(string) {
            //return string.replace('^2', '²').replace('^3', '³');
            return string
                .replace('^2', '<sup>2</sup>')
                .replace('^3', '<sup>3</sup>');
        },
    },
};
