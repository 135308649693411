/**
 * Hotjar integration.
 *
 * This class allows interacting with Hotjar service.
 *
 * https://help.hotjar.com/hc/en-us
 */
import store from '@/store';
import { inLocalEnv } from '@/core/helpers/utils';

export const Hotjar = {
    tagRecording: function (tags) {
        if (inLocalEnv()) return;

        if (tags.length < 0) {
            return window.hj('tagRecording', tags);
        }
    },
    tag: function (tag) {
        if (inLocalEnv()) return;

        return window.hj('tagRecording', tag);
    },
    triggerTeacherSignup: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'teacher_signup');
    },
    triggerUSTeacherSignup: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'us_teacher_signup');

        return window.hj('tagRecording', ['us_teacher_signup']);
    },
    triggerCleverTeacherSignup: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'clever_teacher_signup');

        return window.hj('tagRecording', ['clever_teacher_signup']);
    },
    triggerUSTeacherTBLStart: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'us_teacher_tbl_start');
    },
    triggerStudentSignup: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'student_signup');
    },
    triggerCvcStep2: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'cvc_step2');
    },
    triggerPartnershipFb: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'partnerlink_fb');
    },
    triggerLandingpageEnglish: function () {
        if (inLocalEnv()) return;

        window.hj =
            window.hj ||
            function () {
                (window.hj.q = window.hj.q || []).push(arguments);
            };

        const currentLanguage = store.getters.getCurrentLanguage;

        if (currentLanguage === 'es') {
            return window.hj('trigger', 'langingpage_spanish');
        }

        return window.hj('trigger', 'landingpage_english');
    },
    triggerTeacherWithNoGamesInDashboard: function () {
        if (inLocalEnv()) return;

        const currentLanguage = store.getters.getCurrentLanguage;

        if (currentLanguage === 'es') {
            return window.hj(
                'trigger',
                'teacher_nogameplayed_in_dashboard_spanish',
            );
        }

        return window.hj('trigger', 'teacher_nogameplayed_in_dashboard');
    },
    triggerTeacherWithNoGamesInLiveGameLobby: function () {
        if (inLocalEnv()) return;

        const currentLanguage = store.getters.getCurrentLanguage;

        if (currentLanguage === 'es') {
            return window.hj(
                'trigger',
                'teacher_nogameplayed_in_lobby_spanish',
            );
        }

        return window.hj('trigger', 'teacher_nogameplayed_in_lobby');
    },
    triggerTeacherWithNoGamesInHomeworkGameLobby: function () {
        if (inLocalEnv()) return;

        const currentLanguage = store.getters.getCurrentLanguage;

        if (currentLanguage === 'es') {
            return window.hj(
                'trigger',
                'teacher_nogameplayed_in_hw_lobby_spanish',
            );
        }

        return window.hj('trigger', 'teacher_nogameplayed_in_hw_lobby');
    },
    triggerNewTeacherViewLiveGameSteps: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'new_teacher_live_game_steps');
    },
    triggerNewTeacherViewSpgSteps: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'new_teacher_spg_steps');
    },
    reportFeedback: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'report_feedback');
    },
    triggerStudentLogin: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'student_login');
    },
    triggerTeacherRegister: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'teacher_register');
    },
    triggerAssignPracticeButton: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'assign_practice_button');
    },
    triggerNewDashboardOldUsers: function () {
        if (inLocalEnv()) return;

        return window.hj('trigger', 'us_new_dashboard_old_users');
    },
    triggerTeacherInviteModal: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'us_teacher_invite_modal');

        return window.hj('tagRecording', ['us_teacher_invite_modal']);
    },
    triggerHomeGameV10Session: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'homegame_v10_session');

        return window.hj('tagRecording', ['homegame_v10_session']);
    },
    triggerTeacherLobbyView: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'teacher_lobby');

        return window.hj('tagRecording', ['teacher_lobby']);
    },
    triggerHomeGamePaymentModal: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'home_game_payment_modal');

        return window.hj('tagRecording', ['home_game_payment_modal']);
    },
    triggerClickedImportFromGoogleClassroom: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'clicked_import_from_google_classroom');

        return window.hj('tagRecording', [
            'clicked_import_from_google_classroom',
        ]);
    },
    triggerClickedImportFromCleverClassroom: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'clicked_import_from_clever_classroom');

        return window.hj('tagRecording', [
            'clicked_import_from_clever_classroom',
        ]);
    },
    triggerStudentJoinedAssignment: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_joined_assignment');

        return window.hj('tagRecording', ['student_joined_assignment']);
    },
    triggerEnlargeSocial: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'enlarge_social');

        return window.hj('tagRecording', ['enlarge_social']);
    },
    triggerHasRunnerInLobby: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'hasRunnerInLobby');

        return window.hj('tagRecording', ['hasRunnerInLobby']);
    },
    triggerHasRunnerInGame: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'hasRunnerInGame');

        return window.hj('tagRecording', ['hasRunnerInGame']);
    },
    triggerTeacherIndependentWork: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'teacher_independent_work');

        return window.hj('tagRecording', ['teacher_independent_work']);
    },
    triggerTeacherIndependentWorkData: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'teacher_independent_work_with_data');

        return window.hj('tagRecording', [
            'teacher_independent_work_with_data',
        ]);
    },
    triggerStudentProgramPage: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_program_page');

        return window.hj('tagRecording', ['student_program_page']);
    },
    triggerStudentChestMainPage: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_chest_main_page');

        return window.hj('tagRecording', ['student_chest_main_page']);
    },
    triggerStudentChestEndGame: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_chest_end_game');

        return window.hj('tagRecording', ['student_chest_end_game']);
    },
    triggerStudentBlueActivityLog: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_blue_activity_log');

        return window.hj('tagRecording', ['student_blue_activity_log']);
    },
    triggerStudentPlayingAtHome: function () {
        if (inLocalEnv()) return;

        window.hj('trigger', 'student_playing_at_home');

        return window.hj('tagRecording', ['student_playing_at_home']);
    },
};
