import { randomIntFromRange } from '@/core/helpers/utils';

export const getMathAnalogClockHtml = (hours, minutes) => {
    const randomId = randomIntFromRange(0, 10000);

    const updateTime = () => {
        const getHourAngle = (hr, min) => {
            const base = hr * 30;

            if (min <= 15) {
                return base;
            } else if (min > 15 && min < 30) {
                return base + 8;
            } else {
                return base + 15;
            }
        };

        const minangle = minutes * 6;

        const hourangle = getHourAngle(hours, minutes);

        // Get SVG elements for the hands of the clock
        const minhand = document.querySelector(`#clock${randomId} .minutehand`);

        const hourhand = document.querySelector(`#clock${randomId} .hourhand`);

        // Set an SVG attribute on them to move them around the clock face
        minhand.setAttribute('transform', `rotate(${minangle},50,50)`);

        hourhand.setAttribute('transform', `rotate(${hourangle},50,50)`);
    };

    // Mount the the html to the document, so that we can call updateTime().
    const el = document.createElement('div');

    document.body.appendChild(el);

    el.innerHTML = `<svg id="clock${randomId}" class="clock" viewBox="0 0 100 100">
        <!-- the clock face -->
        <circle class="face" cx="50" cy="50" r="45" />

        <g class="ticks">
            <!-- 12 hour tick marks -->
            <line x1="50" y1="5.000" x2="50.00" y2="10.00" />
            <line x1="72.50" y1="11.03" x2="70.00" y2="15.36" />
            <line x1="88.97" y1="27.50" x2="84.64" y2="30.00" />
            <line x1="95.00" y1="50.00" x2="90.00" y2="50.00" />
            <line x1="88.97" y1="72.50" x2="84.64" y2="70.00" />
            <line x1="72.50" y1="88.97" x2="70.00" y2="84.64" />
            <line x1="50.00" y1="95.00" x2="50.00" y2="90.00" />
            <line x1="27.50" y1="88.97" x2="30.00" y2="84.64" />
            <line x1="11.03" y1="72.50" x2="15.36" y2="70.00" />
            <line x1="5.000" y1="50.00" x2="10.00" y2="50.00" />
            <line x1="11.03" y1="27.50" x2="15.36" y2="30.00" />
            <line x1="27.50" y1="11.03" x2="30.00" y2="15.36" />
        </g>

        <g class="numbers">
            <!-- Number the cardinal directions-->
            <text x="50" y="18">12</text>
            <text x="67" y="23">1</text>
            <text x="80" y="35">2</text>
            <text x="85" y="53">3</text>
            <text x="80" y="70">4</text>
            <text x="67" y="83">5</text>
            <text x="50" y="88">6</text>
            <text x="33" y="83">7</text>
            <text x="20" y="70">8</text>
            <text x="15" y="53">9</text>
            <text x="20" y="35">10</text>
            <text x="33" y="23">11</text>
        </g>

        <!-- Draw hands pointing straight up. We rotate them in the code. -->
        <g ref="hands">
            <!-- Add shadows to the hands -->
            <line
                class="hourhand"
                x1="50"
                y1="50"
                x2="50"
                y2="30"
            />
            <line
                class="minutehand"
                x1="50"
                y1="50"
                x2="50"
                y2="20"
            />
        </g>
    </svg>`;

    updateTime();

    // Remove from body now that the updateTime has updated the clock hands.
    document.body.removeChild(el);

    return el.innerHTML;
};
