<template>
    <div class="wtm-wrapper">
        <div class="wtm-container">
            <img
                src="/images/quests-widget/close-btn.svg"
                alt="close"
                class="wtm-close-btn"
                @click="closeModal"
            />
            <div class="wtm-header">
                <img src="/images/crown.svg" alt="crown" />
                <span>Welcome to your 7-day trial of 99math Premium!</span>
            </div>
            <div class="wtm-video-wrapper">
                <iframe
                    src="https://www.loom.com/embed/d4261d0ce05b46d3bc25335a5f7fa3a1?sid=6a0c5c73-a1a9-43bc-bc93-b07fc613a560"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    "
                />
            </div>
            <span class="wtm-sub-header">
                Try out Premium features to skyrocket your class progress:
            </span>
            <div class="wtm-content-container">
                <div
                    v-for="item in contentArray"
                    :key="item.id"
                    class="wtm-content-item"
                    @click="goToRoute(item.id, item.routeName)"
                >
                    <div class="wtm-content-item-container">
                        <div class="wtm-content-item-img">
                            <img
                                :src="`/images/teacher-premium/trial-modal/${item.id}.svg`"
                                :alt="item.heading"
                            />
                        </div>
                        <div class="wtm-content-item-text-container">
                            <span class="wtm-content-item-text-heading">
                                {{ item.heading }}
                            </span>
                            <span class="wtm-content-item-text">
                                {{ item.text }}
                            </span>
                        </div>
                    </div>
                    <img
                        src="/images/teacher-premium/trial-modal/arrow-right.svg"
                        alt="arrow right"
                        class="wtm-content-item-arrow"
                    />
                </div>
            </div>
            <span class="wtm-get-admin-text">Get your admin in the loop!</span>
            <ShareWithAdminBlock />
            <SeePricingBlock />
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import ShareWithAdminBlock from '@/teacher/flows/TeacherPremium/Modals/components/ShareWithAdminBlock.vue';
import SeePricingBlock from '@/teacher/flows/TeacherPremium/Modals/components/SeePricingBlock.vue';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    components: { SeePricingBlock, ShareWithAdminBlock },
    data() {
        return {
            contentArray: [
                {
                    id: 'mixing-skills',
                    heading: 'Mixing skills in live game',
                    text: 'for faster progress and more fun',
                    routeName: 'host.dashboard',
                },
                {
                    id: 'solo-mode',
                    heading: 'Personalized Solo Mode',
                    text: 'for adaptive and exciting fluency practice',
                    routeName: 'independent-work',
                },
                {
                    id: 'printable-reports',
                    heading: 'Printable reports',
                    text: 'to share progress with admins, students, and parents',
                    routeName: 'host.reports',
                },
                {
                    id: 'most-common-mistakes',
                    heading: 'Most common mistakes over time',
                    text: 'to help you understand where to focus',
                    routeName: 'host.reports',
                },
            ],
        };
    },
    created() {
        new TrackingService().track(EVENTS.OPEN_PREMIUM_WELCOME_MODAL, {
            location: this.$route.path,
        });
    },
    methods: {
        ...mapActions('v2/teacherPremium', ['setShowWelcomePremiumModal']),
        closeModal() {
            this.setShowWelcomePremiumModal(null);
        },
        goToRoute(id, routeName) {
            this.closeModal();

            this.$router.push({
                name: routeName,
                query: id === 'mixing-skills' ? { mixMultipleGlow: true } : '',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.wtm-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
}
.wtm-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 780px;
    padding-bottom: 16px;
    border-radius: 10px;
    background: linear-gradient(180deg, #6f24d0 0%, #360773 34%);

    @media screen and (max-width: $w-max-desktop) {
        height: 580px;
        padding-bottom: 10px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 90%;
        height: 90%;
    }
}
.wtm-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: translate(-10px, 10px);
    cursor: pointer;

    @media screen and (max-width: $w-max-phone) {
        width: 30px;
        height: 30px;
    }
}
.wtm-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 16px 0 0;

    @media screen and (max-width: $w-max-phone) {
        gap: 8px;
        margin: 10px 0;
    }

    img {
        width: 34px;
        height: 26px;

        @media screen and (max-width: $w-max-phone) {
            width: 28px;
            height: 20px;
        }
    }

    span {
        color: #fff;
        text-align: center;
        font-family: $inter-font-family;
        font-size: 27px;
        font-weight: 700;

        @media screen and (max-width: $w-max-desktop) {
            font-size: 24px;
        }

        @media screen and (max-width: $w-max-phone) {
            max-width: 200px;
            font-size: 17px;
        }
    }
}
.wtm-sub-header {
    margin-bottom: 8px;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 18px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 12px;
    }
}
.wtm-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: $w-max-desktop) {
        gap: 8px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: $w-max-phone) {
        margin-bottom: 10px;
    }
}
.wtm-content-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    height: 80px;
    padding: 0 12px;
    border-radius: 10px;
    background: var(--UI-Deep-Purple, #572598);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;

    @media screen and (max-width: $w-max-desktop) {
        width: 400px;
        height: 50px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 90%;
        height: 70px;
        padding: 0 8px;
    }
}
.wtm-content-item-container {
    display: flex;
    align-items: center;
    gap: 11px;

    @media screen and (max-width: $w-max-phone) {
        gap: 4px;
    }
}
.wtm-content-item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    min-width: 47px;
}
.wtm-content-item-text-container {
    display: flex;
    flex-direction: column;
}
.wtm-content-item-text-heading {
    color: #fff;
    font-family: $inter-font-family;
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 16px;
        line-height: 20px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 14px;
        line-height: 18px;
    }
}
.wtm-content-item-text {
    opacity: 0.8;
    color: #fff;
    font-family: $inter-font-family;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 12px;
        line-height: 20px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 12px;
        line-height: 14px;
    }
}
.wtm-get-admin-text {
    margin-bottom: 10px;
    opacity: 0.9;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 14px;
    }
}
.wtm-video-wrapper {
    position: relative;
    height: 260px;
    min-height: 260px;
    width: 502px;
    max-width: 80%;
    margin: 10px 0 16px;

    @media screen and (max-width: $w-max-desktop) {
        height: 160px;
        min-height: 160px;
        width: 308px;
    }
}
</style>
