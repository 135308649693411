<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.yourEnergy') }}
            </div>
            <div class="lives">
                <math-lives theme="dark" />
            </div>
        </div>
        <div class="secondary">
            <div class="text">{{ $t('homeGame.getMoreEnergy') }}!</div>
            <div class="before-after">
                <div class="free">
                    <div class="package-name-text">
                        {{ $t('homeGame.free') }}
                    </div>
                    <img src="/images/free-example.svg" alt="Free" />
                </div>
                <div class="comparator">
                    <img src="/images/comparison-arrow-icon.svg" alt="vs" />
                </div>
                <div class="premium">
                    <div class="package-name-text">
                        {{ $t('homeGame.premium') }}
                    </div>
                    <img src="/images/premium-example.svg" alt="Premium" />
                </div>
            </div>
        </div>
        <div class="package-name">
            99math
            <div class="focused">
                {{ $t('homeGame.premium') }}
            </div>
        </div>

        <div class="price">
            <span class="price__text">
                {{ $t('homeGame.startingFrom') }}
            </span>
            <div class="dollar-sign">$</div>
            <div class="number">
                {{
                    (productStripeInfo.unit_amount_decimal || '').slice(
                        0,
                        (productStripeInfo.unit_amount_decimal || '').length -
                            2,
                    )
                }}
            </div>
        </div>

        <div class="helper">
            {{ $t('homeGame.infoMom') }}
        </div>

        <div class="cta" @click="$emit('advance')">
            <div class="text">
                {{ $t('homeGame.parentsSee') }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        productStripeInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['advance'],
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        padding: 20px 0;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            font-size: 24px;
            font-weight: bold;
        }

        .lives {
            margin-top: 1rem;
            margin-left: 0.5rem;
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: #482c6e;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .secondary {
        width: 100%;
        position: relative;
        background-color: rgba($color: #fff, $alpha: 0.2);
        padding: 54px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            font-size: 29px;
            font-weight: bold;
        }

        .before-after {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 30px 1fr;
            align-items: center;
            justify-content: center;

            .comparator {
                display: flex;
                justify-content: center;
                align-self: end;
                padding-bottom: 20px;
            }

            .free,
            .premium {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .package-name-text {
                    margin-bottom: 10px;
                    font-size: 20px;
                }

                img {
                    width: 140px;
                    height: 60px;
                }
            }
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .package-name {
        font-size: 27px;
        font-weight: bold;
        margin-top: 40px;

        .focused {
            display: inline-block;
            padding: 4px 12px;
            border-radius: 20px;
            background-color: rgba($color: #000, $alpha: 0.25);
            text-transform: uppercase;
        }
    }

    .price {
        font-size: 26px;
        margin-bottom: 12px;
        margin-top: 12px;

        &__text {
            font-size: 20px;
        }

        .dollar-sign {
            display: inline-block;
            font-size: 36px;
        }

        .number {
            display: inline-block;
            font-size: 36px;
            font-weight: bold;
        }
    }

    .helper {
        font-size: 18px;
        border-radius: 20px;
        background-color: rgba($color: #fff, $alpha: 0.2);
        padding: 8px 14px;
        margin-top: auto;
        margin-bottom: 38px;
    }

    .cta {
        position: absolute;
        bottom: -30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        text-align: center;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }
        .text {
            font-size: 30px;
            font-weight: bold;
        }
        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .secondary {
            box-sizing: border-box;
            .before-after {
                margin-top: 10px;

                .free,
                .premium {
                    .package-name-text {
                        margin-bottom: 6px;
                        font-size: 16px;
                    }
                    img {
                        width: 110px;
                        height: 45px;
                    }
                }
            }
        }

        .package-name {
            margin-top: 22px;
        }
    }
}
</style>
