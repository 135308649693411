<template>
    <div v-if="showConnectionLost" class="connection connection--lost">
        {{ $t('internetConnection.connectionLost') }}
    </div>
    <div
        v-else-if="showConnectionRestored"
        class="connection connection--restored"
    >
        {{ $t('internetConnection.connectionRestored') }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RedirectPlayerIfNeededMixin } from '@/core/components/IsConnectedToBackend/mixins/RedirectPlayerIfNeededMixin';
import {
    CrashReportService,
    CrashReportTypes,
} from '@/core/services/CrashReportService';

export default {
    mixins: [RedirectPlayerIfNeededMixin],
    data() {
        return {
            showConnectionLost: false,
            showConnectionRestored: false,
            timeout: null,
            crs: new CrashReportService(null, this.$socket),
            v2reconnects: 0,
        };
    },
    computed: {
        ...mapGetters({
            reconnectAttempts: 'getWebsocketReconnectionAttemptCount',
            currentSocket: 'v2/io/currentSocket',
            clientType: 'getClientType',
        }),
        shouldBeConnected() {
            return this.$v2
                ? this.currentSocket?.socket.open
                : this.$store.getters.shouldWebsocketBeConnected;
        },
        reconnects() {
            return this.currentSocket?.reconnects || 0;
        },
        socket() {
            return this.currentSocket?.socket;
        },
        open() {
            return this.currentSocket?.open;
        },
        connected() {
            return this.currentSocket?.connected;
        },
    },
    watch: {
        reconnects(reconnects) {
            console.log('ICTBVWS.watcher.socket reconnects', reconnects);

            if (reconnects > 2) {
                if (reconnects === 3) {
                    this.crs.sendCrashReport(
                        CrashReportTypes.SOCKET_NOT_CONNECTING,
                    );
                }
                this.showConnectionLost = true;
            }

            if (!reconnects && this.v2reconnects) {
                this.showConnectionLost = false;

                this.crs.ammendCrashReport(
                    CrashReportTypes.SOCKET_NOT_CONNECTING,
                    `reconnected after ${this.v2reconnects} attempts`,
                );

                this.checkIfUserShouldBeRedirectedNow();

                this.showConnectionLost = false;

                this.showConnectionRestored = true;

                this.timeout = setTimeout(() => {
                    this.showConnectionRestored = false;
                }, 3000);
            }

            this.v2reconnects = reconnects;
        },
        socket(socket) {
            console.log(
                'ICTBVWS.watcher.socket cached connection?',
                this.currentSocket?.cacheConnect,
            );

            if (!socket || !this.currentSocket?.cacheConnect) return;

            // console.log('ICTBVWS.watcher.socket cached connection!');
            socket.on('game-not-found', () => {
                console.log('ICTBVWS::io.game-not-found!');
                // come here because load cache works before initialization
                // and if previous cache contained game already not exists
                // in redis (expired CACHE_TTL_GAME=7200 = 2h in BE env) and mongo
                // so game was not played and removed as empty
                // so just clear cache (all = v2/io + v2/game)

                this.$store.dispatch('v2/io/leave');

                // reset also do cache clearing dispatch
                this.$store.dispatch('v2/game/reset');

                this.$router.push({ name: 'home' });
            });
            socket.on('game-finished', () => {
                console.log('ICTBVWS::io.game-finished!');

                this.$store.dispatch('v2/io/leave');

                this.checkIfUserShouldBeRedirectedNow();
            });
        },
        open(isOpen) {
            console.log('ICTBVWS.watcher.open', isOpen);

            if (!isOpen) {
                this.showConnectionLost = false;

                this.showConnectionRestored = false;

                if (this.timeout) {
                    clearTimeout(this.timeout);

                    this.timeout = null;
                }
            }
        },
        connected() {
            if (!this.open) return;

            this.v2reconnects = 0;

            console.log('ICTBVWS.watcher.connected io.open?', this.open);
        },
    },
    mounted() {
        this.socket?.on('reconnect_attempt', () => {
            // console.log('reconnect attempt event');
            if (this.shouldBeConnected) {
                this.$store.commit('increaseWebsocketReconnectCounter');

                if (this.reconnectAttempts > 2) {
                    this.crs.sendCrashReport(
                        CrashReportTypes.SOCKET_NOT_CONNECTING,
                    );

                    this.showConnectionLost = true;
                }
            }
        });

        this.socket?.on('reconnect', () => {
            this.$store.commit('setWebsocketConnectionStatus', true);

            this.ammendCrashReportNoSocketConnection();

            // No matter what, make sure that the user now is on
            // the correct view, since they had a slight moment
            // when they were not connected.
            this.checkIfUserShouldBeRedirectedNow();

            if (this.showConnectionLost) {
                this.showConnectionRestored = true;

                this.showConnectionLost = false;

                this.timeout = setTimeout(() => {
                    this.showConnectionRestored = false;
                }, 3000);
            }
        });
    },
    beforeUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },
    methods: {
        /**
         * The user had the server connection (prolly internet as well) lost
         * for a while. So let's make sure that now they are back, they still
         * are in the correct place in the flow.
         *
         * Basically proactively trying to help the user.
         */
        checkIfUserShouldBeRedirectedNow() {
            // console.log('this.clientType: ', this.clientType);

            if (this.clientType === this.$CONSTANTS.CLIENT_TYPES.PLAYER) {
                this.redirectPlayerIfNeeded();
            }
        },
        ammendCrashReportNoSocketConnection() {
            this.crs.ammendCrashReport(
                CrashReportTypes.SOCKET_NOT_CONNECTING,
                'reconnected after ' + this.reconnectAttempts + ' attempts',
            );
        },
    },
};
</script>

<style scoped lang="scss">
.connection {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    min-height: 75px;
    padding: 0.5rem;

    &--lost {
        background-color: #e5e500;
        color: #790404;
        display: flex;
    }

    &--restored {
        background-color: #54cd29;
        color: #fff;
        display: flex;
    }
}
</style>
