<template>
    <teleport to="#app">
        <div class="demo-modal">
            <div class="demo-modal__content">
                <div class="back-arrow" @click="closeModal">
                    <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.41653 9.41654C8.36933 10.4637 8.36934 12.1602 9.41653 13.2074L14.3957 18.1866L9.41653 23.1658C8.36933 24.213 8.36933 25.9095 9.41653 26.9567C10.4637 28.0039 12.1602 28.0039 13.2074 26.9567L18.1866 21.9775L23.1658 26.9567C24.213 28.0039 25.9095 28.0039 26.9567 26.9567C28.0039 25.9095 28.0039 24.213 26.9567 23.1658L21.9775 18.1866L26.9567 13.2074C28.0039 12.1602 28.0039 10.4637 26.9567 9.41654C25.9095 8.36934 24.213 8.36934 23.1658 9.41654L18.1866 14.3957L13.2074 9.41654C12.1602 8.36934 10.4637 8.36934 9.41653 9.41654Z"
                            fill="#979797"
                            stroke="white"
                            stroke-width="0.5"
                        />
                    </svg>
                </div>
                <div v-if="!videoEnded" :key="'top'" class="video">
                    <Youtube
                        v-if="!videoEnded"
                        ref="how99mathWorks"
                        data-hj-allow-iframe="true"
                        :player-vars="playerVars"
                        :video-id="videoId"
                        :resize="true"
                        :fit-parent="true"
                        allowfullscreen="0"
                        class="video"
                        @ended="handleVideoEnd"
                    />
                </div>
                <div v-else class="video" />
            </div>
        </div>
    </teleport>
</template>
<script>
import Youtube from 'vue-youtube';
import { mapGetters, mapMutations } from 'vuex';
import { MobileScreenWidthDetect } from '@/core/mixins/MobileScreenWidthDetect';
import {
    AnonymousTrackingService,
    ANONYMOUSEVENTS,
} from '@/core/services/TrackingService/AnonymousTrackingService';

export default {
    components: {
        Youtube,
    },
    mixins: [MobileScreenWidthDetect],
    props: {
        videoId: {
            type: String,
            default: '0PJpSVaf3Os',
        },
    },
    emits: ['startDemo', 'close'],
    data() {
        return {
            playerVars: {
                origin: location.origin,
                disablekb: 1,
                showinfo: 0,
                modestbranding: 1,
                fs: 0,
                autoplay: 1,
            },
            videoEnded: false,
            demoChosen: false,
            loading: false,
            wasPlaying: null,
        };
    },
    computed: {
        ...mapGetters({
            gameInfo: 'getGameInfo',
            gameTypes: 'getGameTypes',
            playerName: 'getPlayerName',
            user: 'user',
            audio: 'getAudio',
        }),
    },

    mounted() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';

        this.wasPlaying = !this.audio?.paused;

        if (this.wasPlaying) {
            this.audio?.pause();
        }

        new AnonymousTrackingService().track(
            ANONYMOUSEVENTS.TEACHER_ONBOARDING_WATCHED_LINKED_VIDEO,
        );
    },
    beforeUnmount() {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
    },
    methods: {
        ...mapMutations(['setIsOnboardingDemo']),
        closeModal() {
            if (this.wasPlaying) {
                this.audio?.play();
            }

            this.$emit('close');
        },
        handleVideoEnd() {
            new AnonymousTrackingService().track(
                ANONYMOUSEVENTS.TEACHER_ONBOARDING_WATCHED_LINKED_VIDEO_TILL_END,
            );

            this.$emit('close');
        },
        watchVideoAgain() {
            this.videoEnded = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.demo-modal {
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        background: #ffffff;
        border-radius: 15px;
        position: relative;
        max-width: 1097px;
        width: calc(100% - 2rem);
        padding: 1rem 0;
        min-height: 618px;

        @include mq($w-max-phone, max) {
            min-height: 0;
        }

        &--demo-game {
            max-width: 812px;
            min-height: auto;
            padding: 4rem 2rem 2.5rem;
            border-top: 20px solid #f08a4f;
            color: #333;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            .title {
                font-size: 32px;
                line-height: 150%;
                color: #333;
                font-weight: 700;
            }

            .game-info {
                display: flex;
                align-items: flex-end;
                margin-top: 3rem;
                margin-bottom: 4rem;

                img {
                    width: auto;
                    height: 150px;
                }

                &__content {
                    padding-left: 2rem;

                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .label {
                        margin-bottom: 0.5rem;
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 400;
                    }

                    .info {
                        font-size: 24px;
                        line-height: 1;
                        font-weight: 700;

                        + .label {
                            margin-top: 1.5rem;
                        }
                    }
                }
            }

            .choice-btn {
                max-width: 330px;
                height: 58px;
                font-size: 24px;
                line-height: 1;
                box-shadow: inset 0 -6px 0 #d47943;

                &:hover {
                    background-color: #d47943;
                }
            }
        }
    }
}

.back-arrow {
    position: absolute;
    z-index: 600;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.video {
    margin: 4rem 0 2rem 0;
    width: 100%;
    height: auto;
    background: #333333;

    @include mq($w-max-phone, max) {
        margin: 2.5rem 0 0 0;
    }
}
.choice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    position: absolute;
    background: #fff;
    border-radius: 15px;
    left: calc(50% - 250px);
    top: calc(50% - 150px);
    padding: 1rem 0;
    width: 100%;
    max-width: 520px;
    min-height: 300px;

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        color: #333333;
        opacity: 0.9;
        margin-bottom: 1.5rem;
    }

    .or {
        color: rgb(77, 77, 77);
        border-bottom: 1px solid #c4c4c4;
        max-width: 340px;
        width: 100%;
        margin: 2rem auto;
        display: block;
        position: relative;

        span {
            position: absolute;
            top: -0.5rem;
            left: calc(50% - 1rem);
            background: #fff;
            color: #000;
            padding: 0 0.5rem;
        }
    }
}
.choice-btn {
    border: none;
    cursor: pointer;
    border-radius: 7px;
    color: #fff;
    padding: 10px 45px;
    max-width: 298px;
    height: 48px;
    width: 100%;
    margin: 0.5rem auto;
    display: block;

    &--orange {
        background: rgb(240, 138, 79);
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;

        &:hover {
            background: darken(rgb(240, 138, 79), 10%);
        }
    }

    &--green {
        background: #14c1bf;

        &:hover {
            background: darken(#14c1bf, 10%);
        }
    }

    &--gray {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        background: #ffffff;
        border: 2px solid #828282;
        color: #828282;

        &:hover {
            background: darken($color: #fff, $amount: 10%);
        }
    }
}
.choice-button-explain {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #828282;
    opacity: 0.9;
    margin: 0.5rem auto -0.5rem;
}
.watch-video-again {
    position: absolute;
    left: 1rem;
    top: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 20px;
        margin-right: 0.5rem;
    }

    &:hover {
        background: darken(#fff, 10%);
    }
}
.experience {
    margin: auto;

    .text {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
        text-align: center;
        color: #333333;
        max-width: 80%;
        margin: 2rem auto;
    }
}
</style>
