import axios from 'axios';
import {
    getDate,
    getFormattedLocalTime,
    getLocalTimezone,
} from '@/core/helpers/utils';

// Api now absolutely same as "secure api"
// but to keep changes minimalistic - let's keep it as separate file for now
export default () => {
    const userToken = localStorage.getItem('authToken');

    const guestToken = localStorage.getItem('guestToken');

    const jwt = userToken || guestToken;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Local-Time': getFormattedLocalTime(),
        'X-Timezone': getLocalTimezone(),
        'X-Date': getDate(),
    };

    if (jwt) {
        headers.Authorization = `Bearer ${jwt}`;
    }

    return axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        withCredentials: false,
        headers,
    });
};
