import axios from 'axios';

export default () => {
    return axios.create({
        baseURL:
            'https://api.ipgeolocation.io/ipgeo?apiKey=5861bbe4569c4e3a83c079679444415c&fields=geo',
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
};
