import GameTypes from '@/core/static-json/gameTypes.json';

import DecimalsTopic from '@/core/math-topics/topics/DecimalsTopic';
import ExponentsTopic from '@/core/math-topics/topics/ExponentsTopic';
import FractionsTopic from '@/core/math-topics/topics/FractionsTopic';
import TimeTopic from '@/core/math-topics/topics/TimeTopic';
import MoneyTopic from '@/core/math-topics/topics/MoneyTopic';
import MultiplicationTopic from '@/core/math-topics/topics/MultiplicationTopic';
import AdditionTopic from '@/core/math-topics/topics/AdditionTopic';
import SubtractionTopic from '@/core/math-topics/topics/SubtractionTopic';
import DividingTopic from '@/core/math-topics/topics/DividingTopic';
import EquationTopic from '@/core/math-topics/topics/EquationTopic';
import ComparingNumbersTopic from '@/core/math-topics/topics/ComparingNumbersTopic';
import OrderOfOperationsTopic from '@/core/math-topics/topics/OrderOfOperationsTopic';
import NumberLineTopic from '@/core/math-topics/topics/NumberLineTopic';
import RoundingTopic from '@/core/math-topics/topics/RoundingTopic';
import ConversionTopic from '@/core/math-topics/topics/ConversionTopic';
import PercentageTopic from '@/core/math-topics/topics/PercentageTopic';
import PerimeterAndAreaTopic from '@/core/math-topics/topics/PerimeterAndAreaTopic';
import MixMultipleTopic from '@/core/math-topics/topics/MixMultipleTopic';

/**
 * Map of available game topic classes.
 *
 * @type {array.<object>}
 */
export const TOPICS = [
    DecimalsTopic,
    ExponentsTopic,
    FractionsTopic,
    MoneyTopic,
    MultiplicationTopic,
    TimeTopic,
    AdditionTopic,
    SubtractionTopic,
    DividingTopic,
    EquationTopic,
    ComparingNumbersTopic,
    OrderOfOperationsTopic,
    NumberLineTopic,
    RoundingTopic,
    ConversionTopic,
    PercentageTopic,
    PerimeterAndAreaTopic,
    MixMultipleTopic,
];

/**
 * Helper to get the Topic class by the type/code.
 *
 * @param {string} code - TYPE_ADDITION, TYPE_FRACTIONS etc
 * @returns {function|null}
 */
export const getStaticTopicClass = (code) =>
    TOPICS.reduce(
        (acc, t) => (t.code === code || t.secondCode === code ? t : acc),
        null,
    );

/**
 * Factory class that is used to call the specific topic class.
 */
const TopicsFactory = {
    /**
     * @param {string} type - TYPE_ADDITION, TYPE_FRACTIONS etc
     * @returns {function|null}
     */
    getStaticTopicClass: (type) => {
        return getStaticTopicClass(type);
    },
    /**
     *
     * @param skill
     * @returns {null|object}
     */
    getTopicObject: (skill) => {
        if (!skill) {
            return null;
        }

        const TopicClass = getStaticTopicClass(skill.type);

        return new TopicClass(skill);
    },
    getIcon: (type) => {
        const TopicClass = getStaticTopicClass(type);

        return TopicClass
            ? this.gameTypes.find((game) => game.type === TopicClass.code)?.icon
            : '';
    },
    gameTypes: (noExperimental = false) => {
        if (!noExperimental) {
            return GameTypes;
        }

        return GameTypes.filter((gameType) => {
            return !gameType.experimental;
        });
    },
    gameTypeName: (type) => {
        const GameTopic = TopicsFactory.getStaticTopicClass(type);

        if (GameTopic) {
            return GameTopic.getGameTopicTypeName();
        }

        console.error('gameTypeName no game topic!', type);

        return '';
    },
};

TOPICS.forEach((t, i) => {
    TOPICS[i].generateTopicQuestion = (topic, numberGenerator) => {
        const GameTopic = TopicsFactory.getStaticTopicClass(topic);

        if (!GameTopic) {
            return null;
        }

        return GameTopic.generateQuestion(numberGenerator);
    };
    TOPICS[i].getTopicIcon = (topic) => {
        const GameTopic = TopicsFactory.getStaticTopicClass(topic);

        if (!GameTopic) {
            return null;
        }

        return GameTopic.icon;
    };
});

export default TopicsFactory;
