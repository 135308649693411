import TopicsBaseClass from '@/core/math-topics/TopicsBaseClass';
import {
    randomFromRange,
    randomIntFromRange,
    randomArrayElement,
    getDecimalPlaces,
    round,
} from '@/core/helpers/utils';
import store from '@/store/index';
import { i18n } from '@/lang/translator';

/**
 * @extends TopicsBaseClass
 */
export default class ExponentsTopic extends TopicsBaseClass {
    static code = 'TYPE_EXPONENTS';
    static icon = '^';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.exponents';

    static getNumberGeneratorName(gameInfo) {
        const {
            numberGenerator: { topic, base, exponents },
        } = gameInfo;

        const topicName = i18n.t(`game.gameTypeTitle.${topic}`);

        const locale = store.getters.getCurrentLanguage;

        const baseLocale = base.toLocaleString(locale);

        return (
            `${topicName}: ` +
            `${i18n.t('host.create.exponents.base')} 1...${baseLocale} - ` +
            `${i18n.t('host.create.exponents.exponent')} ${exponents.join(
                ', ',
            )}`
        );
    }

    static generateQuestion(numberGenerator) {
        const { type, topic, exponents, base } = numberGenerator;

        const [randomExponent, randomBase] = createNumbers(exponents, base);

        let answer = Number(randomBase) ** Number(randomExponent);

        answer = round(answer, 6);

        function createNumbers(exponents = [], baseMax) {
            const randomExponent = randomArrayElement(exponents);

            if (topic === 'naturalNumbers') {
                // Set maximum base to 2 if exponent is >= 4
                if (randomExponent >= 4) {
                    baseMax = 2;

                    const randomBase = randomIntFromRange(0, baseMax);

                    return [randomExponent, randomBase];
                }

                // Set maximum base to 10 if exponent is >= 3
                if (randomExponent >= 3) {
                    baseMax = baseMax > 10 ? 10 : baseMax;

                    const randomBase = randomIntFromRange(0, baseMax);

                    return [randomExponent, randomBase];
                }

                // Set maximum base from 1 to 20 if exponent is = 0
                if (randomExponent === 0) {
                    baseMax = baseMax > 20 ? 20 : baseMax;

                    const randomBase = randomIntFromRange(1, baseMax);

                    return [randomExponent, randomBase];
                }

                const randomBase = randomIntFromRange(0, baseMax);

                return [randomExponent, randomBase];
            }

            if (topic === 'decimals') {
                // Set base range to 0.1...1 if exponent is 3
                if (randomExponent === 3) {
                    baseMax = 1;

                    const randomBase = randomFromRange(0.1, baseMax, 1);

                    return [randomExponent, randomBase];
                }

                if (base === 1 || base === 0.1) {
                    const baseMin = base / 10;

                    const fixed = getDecimalPlaces(baseMin);

                    const randomBase = randomFromRange(baseMin, baseMax, fixed);

                    return [randomExponent, randomBase];
                }

                if (base === 2 || base === 0.2) {
                    const baseMin = base / 20;

                    const fixed = getDecimalPlaces(baseMin);

                    const randomBase = randomFromRange(baseMin, baseMax, fixed);

                    return [randomExponent, randomBase];
                }
            }
        }

        return {
            type,
            topic,
            base: randomBase,
            exponent: randomExponent,
            answer,
        };
    }

    static formatQuestion(question) {
        const { topic, base, exponent } = question;

        const locale = store.getters.getCurrentLanguage;

        if (topic === 'decimals') {
            return `<div>${parseFloat(base).toLocaleString(locale, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 2,
            })}<sup>${exponent}</sup></div>`;
        }

        return `<div>${parseFloat(base).toLocaleString(
            locale,
        )}<sup>${exponent}</sup></div>`;
    }

    static isAnswerCorrect(question, answer) {
        return question.answer === Number(answer);
    }

    static generatePlayerAnswerHtml(answer) {
        const locale = store.getters.getCurrentLanguage;

        if (!Number.isNaN(Number(answer))) {
            const answ = Number(answer).toLocaleString(locale);

            return ` = <span class="wrong-answer">${answ}</span>`;
        }

        return ` = <span class="wrong-answer">${answer}</span>`;
    }
}
