import EventEmitter from 'events';
import Api from '@/core/services/Api';
import moment from 'moment';
import { io } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';
import { objectHash } from '@/core/helpers/utils';

export class Bot extends EventEmitter {
    constructor(name, avatar) {
        super();
        this._gameCode = null;
        this._name = name;
        this._avatar = avatar;
        this._socket = null;
        this._joinResolve = null;
        this._client = null;
        this._api = Api();
        this._play = false;
        this._questions = [];
        this._info = null;
    }

    id() {
        return this._client.clientId;
    }

    // uncomment for debugging
    log(/*...msg*/) {
        // console.log(`BOT [${this._name}] log:`, ...msg);
    }

    error(...msg) {
        console.error(`BOT [${this._name}] error:`, ...msg);
    }

    async join(gameCode) {
        this._gameCode = gameCode;
        await this.waitMs(this.randomInt(50, 200));
        const canJoin = await this._api.get(`/join/${gameCode}`);
        if (
            !canJoin.data ||
            !canJoin.data.success ||
            !canJoin.data.data ||
            !canJoin.data.data.canJoin
        ) {
            this.error('cant join game', gameCode);
            return false;
        }
        await this.waitMs(this.randomInt(50, 200));
        const joinData = await this._api.post(`/join/${gameCode}`, {
            joined: moment().format(),
            name: this._name,
            userId: null,
            bot: true,
        });
        if (!joinData.data.success || !joinData.data.data) {
            this.error('join game error', joinData);
            return false;
        }
        try {
            this._client = jwtDecode(joinData.data.data.token);
        } catch (jwtError) {
            this.error('jwt decoding error', jwtError);
            return false;
        }
        this.log('joined', this._client);
        this._gameMode = joinData.data.data.gameMode;
        this._info = joinData.data.data.info;
        this._gameCode = joinData.data.data.info.code;
        this._wsIoUrl =
            joinData.data.data.wsServerUrl ||
            import.meta.env[`VITE_${this._client.region}_TBL_API_URL`];
        let ioUrl = this._wsIoUrl;
        if (!ioUrl.endsWith('/')) ioUrl += '/';
        ioUrl += 'live';
        this._socket = io(ioUrl, {
            autoConnect: false,
            transports: ['websocket'],
            query: {
                jwt: joinData.data.data.token,
            },
            forceNew: true,
        });
        this._socket.on('connect', async () => {
            this.log('connected');
            if (this._joinResolve) {
                this._socket.emit('selectedAvatar', this._avatar);
                this._joinResolve(true);
                this._joinResolve = null;
            }
        });
        this._socket.on('disconnect', () => {
            this.log('disconnected');
        });
        this._socket.on('game-info', (data) => {
            this.log('game-info', data);
            this._info = data;
        });
        this._socket.on('leaderboard', (data) => {
            this.log('leaderboard', data);
        });
        this._socket.on('roundPrepared', (data) => {
            this.log(`round ${data.round}/${data.rounds} prepared`);
        });
        this._socket.on('mongoCode', () => {
            this.close();
        });
        this._socket.on('startRound', (data) => {
            this.log('startRound', data);
            this._play = true;
            this.playRound(4000);
            setTimeout(() => {
                this._play = false;
            }, this._info.round.roundDuration * 1000);
        });
        await this.waitMs(this.randomInt(50, 200));
        this._socket.open();
        return new Promise((resolve) => {
            this._joinResolve = resolve;
        });
    }

    async playRound(timeOut, answerCount = 0) {
        await this.waitMs(timeOut);
        if (!this._play || !this._socket) return;
        this.log('playRound');
        const round = this._info.gameRounds.length - 1;
        const questions = this._info.gameRounds[round].questions;
        const questionIndex = this.randomInt(0, questions.length - 1);
        const question = questions[questionIndex];
        const time = this.randomInt(1200, 5200);
        await this.waitMs(time);
        const answer =
            this.randomInt(0, 100) > 25 ? question.answer : question.answer + 1;
        if (this._socket) {
            const questionBody = {
                ...question,
                nr1: question.number1,
                nr2: question.number2,
                nr3: question.number3,
                data: question.data ? { ...question.data } : { ...question },
            };
            const hash = await objectHash(questionBody);
            questionBody.time = time;
            questionBody.questionHash = hash;
            questionBody.playerAnswer = answer;
            questionBody.correct = answer === question.answer;
            this._socket.emit('playerAnswer', {
                answerCount,
                questionIndex,
                round: round + 1,
                gameCode: this._info.code,
                playerName: this._name,
                userId: null,
                questionBody,
            });
        }
        this.playRound(this.randomInt(1500, 3500), answerCount + 1);
    }

    close() {
        if (this._socket) {
            this._socket.offAny();
            this._socket.close();
            this._socket = null;
        }
    }

    waitMs(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    randomInt(from, to) {
        const delta = to - from;
        return Math.floor(Math.random() * (delta + 1)) + from;
    }
}
