export default {
    setRoundTimeLeft({ gameState }, payload) {
        gameState.round.roundTimeLeft = payload;
    },
    resetRoundTimer({ gameState }) {
        gameState.round.roundTimeLeft = null;
    },
    setRoundTimeLeftInSeconds({ gameState }, payload) {
        gameState.round.roundTimeLeftInSeconds = payload;
    },
};
