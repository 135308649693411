<template>
    <Modal v-if="!!this.storeUrl" class="update-required-modal">
        <div>
            <br />
            App version you're using is outdated. Please update to the latest
            version.
            <br />
            <button class="update-required-modal-btn" @click="openStore">
                Go to the store
            </button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/core/ui/Modal/Modal.vue';
import { mobileAppUpdateRequiredFromUrl } from '@/capacitor/init.js';

export default {
    components: {
        Modal,
    },
    data() {
        console.log('=== storeUrl', this.storeUrl);
        return {
            storeUrl: '',
        };
    },
    async mounted() {
        this.storeUrl = await mobileAppUpdateRequiredFromUrl();
    },
    methods: {
        openStore() {
            window.location.href = this.storeUrl;
        },
    },
};
</script>

<style lang="scss">
.update-required-modal {
    .c-modal--content {
        border-radius: 1rem;
        padding: 1rem;
        font-size: 1.5rem;

        font-style: normal;
        overflow: hidden;
    }
    .update-required-modal-btn {
        width: 100%;
        height: 50px;
        max-width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 10px auto;
        text-transform: capitalize;
        background: #f08a4f;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        color: #ffffff;
        border: 0;
        font-size: 18px;
        line-height: 14px;

        font-weight: 700;
        cursor: pointer;
    }
}
</style>
