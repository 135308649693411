export async function adjustVolume(
    element,
    newVolume,
    options = {
        duration: 3000,
        easing: swing,
        interval: 13,
    },
) {
    const { duration, easing, interval } = options;

    const originalVolume = element.volume;

    const delta = newVolume - originalVolume;

    if (!delta || !duration || !easing || !interval) {
        element.volume = newVolume;

        return Promise.resolve();
    }

    const ticks = Math.floor(duration / interval);

    let tick = 1;

    return new Promise((resolve) => {
        const timer = setInterval(() => {
            element.volume = originalVolume + easing(tick / ticks) * delta;
            if (++tick === ticks + 1) {
                clearInterval(timer);
                resolve();
            }
        }, interval);
    });
}

export function swing(p) {
    return 0.5 - Math.cos(p * Math.PI) / 2;
}
