<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.getPremium') }}
            </div>
            <div class="secondary-text">
                {{ $t('homeGame.andBoost') }}
            </div>
            <div class="price-text">
                ${{
                    (productStripeInfo.unit_amount_decimal || '').slice(
                        0,
                        (productStripeInfo.unit_amount_decimal || '').length -
                            2,
                    )
                }}
                {{ $t('homeGame.perMonth') }}
            </div>
            <div class="cancel-text">
                {{ $t('homeGame.cancel') }}
            </div>
        </div>
        <Stripe
            ref="stripe"
            :price-id="productStripeInfo.id"
            @ready="formReadyHandler"
            @loading="loadingHandler"
            @subscribed="subscribedHandler"
        />

        <button class="cta" :disabled="!enableCta" @click="subscribe">
            <div class="text">
                {{ ctaMessage }}
            </div>
        </button>
    </div>
</template>
<script>
import {
    TrackingService,
    EVENTS,
} from '@/core/services/TrackingService/TrackingService.js';
import Stripe from '@/student/widgets/Stripe.vue';

export default {
    components: {
        Stripe,
    },
    props: {
        productStripeInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['advance'],
    data() {
        return {
            enableCta: false,
            ctaMessage: this.$t('homeGame.getPremium'),
            priceId: this.productStripeInfo.id,
        };
    },
    async mounted() {
        await new TrackingService().track(
            EVENTS.VIEW_PREMIUM_CREDIT_CARD_SCREEN,
        );
    },
    methods: {
        formReadyHandler(value) {
            this.enableCta = value;
        },
        loadingHandler(isLoading) {
            this.ctaMessage = isLoading
                ? 'Loading...'
                : this.$t('homeGame.getPremium');
        },
        subscribedHandler() {
            new TrackingService().track(EVENTS.PARENT_BUYS_PREMIUM, 'monthly5');

            this.$emit('advance');
        },
        async subscribe() {
            this.$refs.stripe.subscribe();
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        padding: 30px 0 0;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .text {
            font-size: 29px;
            font-weight: bold;
        }

        .secondary-text {
            font-size: 21px;
            margin-top: 10px;
        }

        .price-text {
            margin-top: 24px;
            font-size: 21px;
            font-weight: 500;
        }

        .cancel-text {
            margin-top: 6px;
            font-size: 17px;
            color: rgba($color: #fff, $alpha: 0.6);
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: #482c6e;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .normal-text {
        margin: 15px 0;
    }

    .form-wrapper {
        position: relative;
        widows: 100%;

        .credit-card-form {
            padding: 14px;

            .input-field {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-bottom: 20px;

                .name {
                    padding-left: 10px;
                    font-size: 17px;
                    font-weight: 400;
                    margin-bottom: 8px;
                }

                input {
                    height: 42px;
                    width: 100%;
                    border-radius: 6px;
                    font-size: 20px;
                    outline: none;
                    border: none;
                    padding: 4px 12px;
                    box-sizing: border-box;

                    &::placeholder {
                        padding: 4px 12px;
                    }
                }

                .error {
                    margin-top: 4px;
                    margin-bottom: 6px;
                    color: red;
                    padding-left: 10px;
                }

                .note {
                    margin-top: 4px;
                    padding-left: 10px;
                }
            }

            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 14px;
            }
        }
    }

    .cta {
        position: absolute;
        bottom: -30px;
        background-color: darken($color: #ef8a4f, $amount: 5);
        box-sizing: border-box;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
        font-size: 30px;
        font-weight: bold;
        border: none;
        color: #fff;

        &:disabled {
            background-color: #ddb097;
            pointer-events: none;
        }

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 8);
            cursor: pointer;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .primary {
            padding: 10px 0 0;

            .price-text {
                margin-top: 14px;
            }

            &::after {
                bottom: -10px;
                height: 10px;
            }
        }

        .form-wrapper {
            .credit-card-form {
                padding: 8px;

                .input-field {
                    margin-bottom: 8px;

                    .name {
                        margin-bottom: 5px;
                        font-size: 16px;
                    }

                    input {
                        height: 38px;
                    }

                    .error {
                        margin-top: 2px;
                        margin-bottom: 4px;
                    }

                    .note {
                        margin-top: 2px;
                    }
                }

                .row {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 14px;
                }
            }
        }
    }
}
</style>
