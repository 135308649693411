import {
    getDecimalPlaces,
    randomArrayElement,
    randomIntFromRange,
    round,
} from '@/core/helpers/utils';
import TopicsBaseClass from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class RoundingTopic extends TopicsBaseClass {
    static code = 'TYPE_ROUNDING';
    static icon = '~';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.rounding';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const { numberGenerator } = skill;

        const { topic, placeValues, scale } = numberGenerator;

        const topicName = this.t(`game.gameTypeTitle.${topic}`);

        if (topic === 'naturalNumbers') {
            const values = placeValues
                .map((value) => this.t(`host.create.rounding.${value}`))
                .join(', ');

            return `${topicName}: ${values}`;
        }

        if (topic === 'decimals') {
            const textScale = [scale].flat().map((s) => {
                switch (s) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'host.create.decimals.ones',
                        ).toLowerCase();
                    case 10:
                        return this.t(
                            'host.create.decimals.tens',
                        ).toLowerCase();
                    case 100:
                        return this.t(
                            'host.create.decimals.hundreds',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${textScale.join(', ')}`;
        }

        // legacy
        return `: ${placeValues
            .map((value) => this.t(`host.create.rounding.${value}`))
            .join(', ')}`;
    }

    // eslint-disable-next-line no-inner-declarations
    static _generate(scaleValue) {
        let decimal;
        let answer;
        let decimal1;
        let decimal2;
        let decimal3;
        let random1;
        let random2a;
        let random2b;
        let random2c;

        switch (scaleValue) {
            case 0.001:
                decimal = (Math.random() * 10.0).toFixed(4);
                break;
            case 0.01:
                random1 = randomIntFromRange(0, 10);

                random2a = randomIntFromRange(1, 1000);

                random2b = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 1000;

                decimal2 = random1 + random2b / 10000;

                decimal = randomArrayElement([decimal1, decimal2]);

                decimal = round(decimal, 4);

                break;
            case 0.1:
                random1 = randomIntFromRange(0, 10);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([decimal1, decimal2, decimal3]);

                decimal = round(decimal, 4);

                break;
            case 1:
                random1 = randomIntFromRange(1, 20);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([decimal1, decimal2, decimal3]);

                decimal = round(decimal, 4);

                break;
            case 10:
                random1 = randomIntFromRange(1, 1000);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([decimal1, decimal2, decimal3]);

                decimal = round(decimal, 4);

                break;
            case 100:
                random1 = randomIntFromRange(1, 1000);

                random2a = randomIntFromRange(1, 100);

                random2b = randomIntFromRange(1, 1000);

                random2c = randomIntFromRange(1, 10000);

                decimal1 = random1 + random2a / 100;

                decimal2 = random1 + random2b / 1000;

                decimal3 = random1 + random2c / 10000;

                decimal = randomArrayElement([decimal1, decimal2, decimal3]);

                decimal = round(decimal, 4);

                break;
            default:
                break;
        }

        if (scaleValue < 1) {
            const decimalPlaces = getDecimalPlaces(scaleValue);

            answer = round(decimal, decimalPlaces);

            answer = Number(answer.toFixed(decimalPlaces));
        } else {
            answer = Math.round(decimal / scaleValue) * scaleValue;

            answer = Number(answer.toFixed(0));
        }

        return [decimal, answer];
    }

    static generateQuestion(numberGenerator) {
        const { topic } = numberGenerator;

        if (topic === 'naturalNumbers' || !topic) {
            let number = 0;

            const operators = numberGenerator.placeValues;

            const operator = parseInt(
                operators[Math.floor(Math.random() * operators.length)],
            );

            number = randomIntFromRange(
                parseInt(operator / 10),
                parseInt(numberGenerator.scale),
            );

            const answer = Math.round(number / operator) * operator;

            return {
                topic,
                number: number,
                operator: operator,
                answer: answer,
            };
        }

        if (topic === 'decimals') {
            const { scale, topic } = numberGenerator;

            let selectedScale = scale;

            let decimal;

            let answer;

            // Single scale selection
            if (typeof scale === 'number') {
                [decimal, answer] = this._generate(scale);
            }

            // Multiple scale selections
            if (Array.isArray(scale)) {
                selectedScale = randomArrayElement(scale);

                [decimal, answer] = this._generate(selectedScale);
            }

            return {
                topic,
                scale: selectedScale,
                decimal,
                answer,
            };
        }

        return super.generateQuestion(numberGenerator);
    }

    static formatQuestion(question, skill, calledIn) {
        let { topic, number, operator, decimal, scale } = question;

        const locale = this.locale();

        if (!topic || topic === 'naturalNumbers') {
            if (!number) {
                number = question.nr1;

                operator = question.data.operator;
            }

            return `
                        <div class="text-center">
                            ${this.t('host.create.rounding.roundToTheNearest')}
                            <br />
                            ${this.t(`host.create.rounding.${operator}`)}
                            <br />
                            ${parseFloat(number).toLocaleString(locale)}
                        </div>
                    `;
        }

        if (topic === 'decimals') {
            if (!scale || !decimal) {
                scale = question.data.scale;

                decimal = question.data.decimal;
            }

            const fractional = decimal.toString().split('.')[1];

            const decimalPlaces = (fractional && fractional.length) || 0;

            const localeNumber = decimal.toLocaleString(locale, {
                minimumFractionDigits: decimalPlaces,
            });

            const translationScale = scale.toString().split('.').join('');

            return `
                        <div class="text-center">
                            <div>
                                ${this.t(
                                    'host.create.rounding.roundToTheNearest',
                                )}
                            </div>
                            <div>
                                ${this.t(
                                    'host.create.rounding.' + translationScale,
                                )}
                            </div>
                            <div>${localeNumber}</div>
                        </div>
                    `;
        }
    }

    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        if (question.topic !== 'decimals')
            return super.generatePlayerAnswerHtml(
                answer,
                numberGenerator,
                question,
            );

        const decimalPlaces = getDecimalPlaces(answer);

        return ` = <span class="wrong-answer">
                ${
                    isNaN(answer)
                        ? answer
                        : Number(answer).toLocaleString(this.locale, {
                              minimumFractionDigits: decimalPlaces,
                          })
                }
            </span>
        `;
    }

    static answerData(question, answer, skill) {
        if (!question.topic || question.topic === 'naturalNumbers') {
            return {
                operator: question.operator,
            };
        }

        if (question.topic === 'decimals') {
            return {
                scale: question.scale,
                decimal: question.decimal,
            };
        }

        return super.answerData(question, answer, skill);
    }
}
