import { Bot } from '@/core/helpers/bots/TblBot';
import {
    botsNamesShuffled,
    avatarsShuffled,
} from '@/core/helpers/bots/botsUtils';

export default {
    namespaced: true,
    state: {
        gameCode: null,
        bots: {},
        teacherAvatar: null,
    },
    getters: {
        teacherAvatar: (state) => state.teacherAvatar,
        bots: (state) => state.bots,
    },
    mutations: {
        addBot: (state, bot) => {
            state.bots[bot.id()] = bot;
        },
        despawnBots: (state) => {
            Object.keys(state.bots || {}).forEach((botId) => {
                state.bots[botId].close();
                delete state.bots[botId];
            });

            state.bots = {};
        },
        setTeacherAvatar: (state, avatar) => {
            state.teacherAvatar = avatar;
        },
    },
    actions: {
        spawnBots: async (store, { gameCode, amount }) => {
            console.log('spawning bots', gameCode, amount);

            const names = botsNamesShuffled();

            const avatars = avatarsShuffled();

            if (amount > names.length) amount = names.length;

            let avatarI = 0;

            const joins = [];

            for (let i = 0; i < amount; i++) {
                if (avatarI >= avatars.length) avatarI -= avatars.length;

                const bot = new Bot(names[i], avatars[avatarI]);

                const join = bot.join(gameCode).then((joinOk) => {
                    if (joinOk) store.commit('addBot', bot);
                });

                joins.push(join);

                avatarI++;
            }

            await Promise.all(joins);
        },
    },
};
