<template>
    <p>
        <svg
            id="etwuK0pyakV1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 90 90"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
        >
            <g
                id="etwuK0pyakV2"
                transform="translate(25.799999 41.550001)"
                opacity="0"
            >
                <path
                    d="M16.6,41.9v1.2c0,1.3,1,2.3,2.3,2.3h.6c1.3,0,2.3-1,2.3-2.3v-1.2h-5.2Z"
                    fill="#7fb3fa"
                />
                <path
                    d="M19.1,43.1v-1.2h-2.5v1.2c0,1.3,1,2.3,2.3,2.3h.6c.3,0,.6-.1.9-.2-.7-.4-1.3-1.2-1.3-2.1Z"
                    fill="#64a6f4"
                />
                <path
                    d="M22.9,38.9h-7.3c-.1,0-.1,0-.2,0v2.1c0,.6.5,1.1,1.1,1.1h5.8c.4,0,.7-.3.7-.7v-2.4c0-.1-.1-.1-.1-.1Z"
                    fill="#b5dcff"
                />
                <path
                    d="M18.7,41v-2.1h-3.1c-.1,0-.1,0-.2,0v2.1c0,.6.5,1.1,1.1,1.1h3.3c-.6,0-1.1-.5-1.1-1.1Z"
                    fill="#8bcaff"
                />
                <path
                    d="M22.8,36h-7.1c-.4,0-.7-.1-.9-.4v2.7c0,.5.4.8.8.8h7.3c.5,0,.8-.4.8-.8v-2.7c-.2.2-.6.4-.9.4Z"
                    fill="#dbedff"
                />
                <path
                    d="M22.8,36h-7.1c-.4,0-.7-.1-.9-.4v2.7c0,.5.4.8.8.8h7.3c.5,0,.8-.4.8-.8v-2.7c-.2.2-.6.4-.9.4Z"
                    fill="#dbedff"
                />
                <path
                    d="M18.6,38.3v-2.3h-2.9c-.4,0-.7-.1-.9-.4v2.7c0,.5.4.8.8.8h3.8c-.5,0-.8-.4-.8-.8Z"
                    fill="#b5dcff"
                />
                <path
                    d="M19.4,7.1C12.8,7,7.3,12.3,7.2,18.9c-.1,4,1.8,7.6,4.8,9.9c1.4,1.1,2.3,2.8,2.3,4.5v1.4c0,.8.6,1.4,1.4,1.4h7.1c.8,0,1.4-.6,1.4-1.4v-1.4c0-1.8.8-3.4,2.2-4.5c2.9-2.2,4.9-5.7,4.9-9.7c0-6.6-5.3-11.9-11.9-12Z"
                    fill="#ffe07d"
                />
                <path
                    d="M18.8,34.7v-1.4c0-1.8-.9-3.5-2.3-4.5-3-2.3-4.9-5.9-4.8-9.9.1-5.8,4.3-10.5,9.8-11.6-.7-.1-1.4-.2-2.1-.2C12.8,7,7.3,12.3,7.2,18.9c-.1,4,1.8,7.6,4.8,9.9c1.4,1.1,2.3,2.8,2.3,4.5v1.4c0,.8.6,1.4,1.4,1.4h4.5c-.8.1-1.4-.6-1.4-1.4Z"
                    fill="#ffc861"
                />
                <path
                    d="M19.2,13c-.9,0-1.7.7-1.7,1.7v6.9c0,.9.7,1.7,1.7,1.7.9,0,1.7-.7,1.7-1.7v-6.9c0-1-.7-1.7-1.7-1.7Z"
                    fill="#ff8086"
                />
                <path
                    d="M19.2,25.5c-.9,0-1.7.7-1.7,1.7v.3c0,.9.7,1.7,1.7,1.7.9,0,1.7-.7,1.7-1.7v-.3c0-.9-.7-1.7-1.7-1.7Z"
                    fill="#ff8086"
                />
                <path
                    d="M19.5,21.5v-6.9c0-.6.3-1.1.7-1.4-.3-.2-.6-.3-.9-.3-.9,0-1.7.7-1.7,1.7v6.9c0,.9.7,1.7,1.7,1.7.3,0,.7-.1.9-.3-.5-.3-.7-.8-.7-1.4Z"
                    fill="#e5646e"
                />
                <path
                    d="M19.5,27.5v-.3c0-.6.3-1.1.7-1.4-.3-.2-.6-.3-.9-.3-.9,0-1.7.7-1.7,1.7v.3c0,.9.7,1.7,1.7,1.7.3,0,.7-.1.9-.3-.5-.3-.7-.8-.7-1.4Z"
                    fill="#e5646e"
                />
                <path
                    id="etwuK0pyakV16"
                    d="M34.1,9.8l-2.3,1.3c-.3.2-.4.6-.2.9s.6.4.9.2l2.3-1.3c.3-.2.4-.6.2-.9-.1-.3-.5-.4-.9-.2Z"
                />
                <path
                    id="etwuK0pyakV17"
                    d="M5.9,26.1L3.6,27.4c-.3.2-.4.6-.2.9s.6.4.9.2l2.3-1.3c.3-.2.4-.6.2-.9-.1-.3-.6-.4-.9-.2Z"
                />
                <path
                    id="etwuK0pyakV18"
                    d="M19.9,4.2v-2.6c0-.4-.3-.7-.7-.7s-.7.3-.7.7v2.6c0,.4.3.7.7.7s.7-.3.7-.7Z"
                />
                <path
                    id="etwuK0pyakV19"
                    d="M6.6,11.1L4.3,9.8c-.3-.2-.7-.1-.9.2s-.1.7.2.9l2.3,1.3c.3.2.7.1.9-.2s.1-.7-.2-.9Z"
                />
                <path
                    id="etwuK0pyakV20"
                    d="M32.5,26.1c-.3-.2-.7-.1-.9.2s-.1.7.2.9l2.3,1.3c.3.2.7.1.9-.2s.1-.7-.2-.9l-2.3-1.3Z"
                />
                <path
                    d="M16.9,27.2v.3c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3v-.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3Zm3.3,0v.3c0,.6-.5,1-1,1-.6,0-1-.5-1-1v-.3c0-.6.5-1,1-1c.6,0,1,.5,1,1Z"
                />
                <path
                    d="M11.6,29.3c1.3,1,2,2.5,2,4v1.4c0,.5.2,1,.5,1.3v2.2c0,.5.3,1,.7,1.2v1.6c0,.8.5,1.4,1.2,1.6v.4c0,1.6,1.3,3,3,3h.6c1.6,0,3-1.3,3-3v-.4c.7-.1,1.2-.7,1.2-1.4v-1.8c.4-.3.7-.7.7-1.2v-2.2c.3-.4.5-.8.5-1.3v-1.4c0-1.6.7-3.1,2-4c3.1-2.3,5.1-6,5.1-10.2c0-6.4-4.8-11.8-11.1-12.6-.4,0-.7.2-.7.6s.2.7.6.7c5.7.7,9.9,5.6,9.9,11.3c0,3.6-1.7,7-4.6,9.1-1.6,1.2-2.5,3.1-2.5,5v1.4c0,.4-.3.8-.8.8h-7.1c-.4,0-.8-.3-.8-.8v-1.4c0-2-1-3.9-2.6-5.1-2.9-2.2-4.6-5.7-4.5-9.4.1-5.6,4.4-10.3,9.9-11c.4,0,.6-.4.6-.7s-.4-.5-.8-.4c-6.1.8-10.9,6-11.1,12.3-.1,4.1,1.8,8,5.1,10.4Zm9.6,13.8c0,.9-.7,1.7-1.7,1.7h-.6c-.9,0-1.7-.7-1.7-1.7v-.3h3.9l.1.3v0Zm1.2-1.8c0,.1-.1.1,0,0-3.7.1,1.5.1-5.9.1-.2,0-.4-.2-.4-.4v-1.2h6.3v1.5v0Zm-7-4.5c.1,0,.2,0,.3,0h7.1c.1,0,.2,0,.3,0v1.5c0,.1-.1.2-.2.2h-7.3c-.1,0-.2-.1-.2-.2v-1.5v0Z"
                />
                <path
                    d="M16.9,14.7v6.9c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3v-1.9c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1.9c0,.6-.5,1-1,1-.6,0-1-.5-1-1v-6.9c0-.6.5-1,1-1c.6,0,1,.5,1,1v1.9c0,.4.3.7.7.7s.7-.3.7-.7v-1.9c0-1.3-1-2.3-2.3-2.3-1.3-.1-2.3,1-2.3,2.3Z"
                />
            </g>
        </svg>
    </p>
</template>
<script>
export default {
    name: 'Heureka',
    // https://www.svgator.com/help/getting-started/add-animated-svg-to-vue-js
    mounted() {
        (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
            (a = document.getElementById(i.root)).svgatorPlayer = {
                ready: (function (a) {
                    b = [];
                    return function (c) {
                        return c ? (b.push(c), a.svgatorPlayer) : b;
                    };
                })(a),
            };
            w[o] = w[o] || {};
            w[o][s] = w[o][s] || [];
            w[o][s].push(i);
            e = d.createElementNS(n, t);
            e.async = true;
            e.setAttributeNS(
                x,
                'href',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            e.setAttributeNS(
                null,
                'src',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            p = d.getElementsByTagName(t)[0];
            p.parentNode.insertBefore(e, p);
        })(
            '91c80d77',
            {
                root: 'etwuK0pyakV1',
                version: '2022-05-04',
                animations: [
                    {
                        elements: {
                            etwuK0pyakV2: {
                                transform: {
                                    data: { t: { x: -19.25, y: -23.449999 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 200,
                                                v: {
                                                    x: 45.049999,
                                                    y: 65,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 500,
                                                v: {
                                                    x: 45.049999,
                                                    y: 40,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                        s: [
                                            { t: 500, v: { x: 1, y: 1 } },
                                            { t: 700, v: { x: 1.4, y: 1.4 } },
                                            { t: 900, v: { x: 1, y: 1 } },
                                            { t: 3000, v: { x: 1.5, y: 1.5 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 200, v: 0 },
                                    { t: 500, v: 1 },
                                    { t: 2800, v: 1 },
                                    { t: 3000, v: 0 },
                                ],
                            },
                            etwuK0pyakV16: {
                                transform: {
                                    data: { t: { x: -33.3, y: -11 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 33.3,
                                                    y: 11,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 1400,
                                                v: {
                                                    x: 37.3,
                                                    y: 8,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 500, v: 1 },
                                    { t: 1400, v: 0 },
                                ],
                            },
                            etwuK0pyakV17: {
                                transform: {
                                    data: { t: { x: -5.1, y: -27.3 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 5.1,
                                                    y: 27.3,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 1400,
                                                v: {
                                                    x: 2.1,
                                                    y: 30.3,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 500, v: 1 },
                                    { t: 1400, v: 0 },
                                ],
                            },
                            etwuK0pyakV18: {
                                transform: {
                                    data: { t: { x: -19.199999, y: -2.9 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 19.199999,
                                                    y: 2.9,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 1400,
                                                v: {
                                                    x: 19.199999,
                                                    y: -1.1,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 500, v: 1 },
                                    { t: 1400, v: 0 },
                                ],
                            },
                            etwuK0pyakV19: {
                                transform: {
                                    data: { t: { x: -5.1, y: -11 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 5.1,
                                                    y: 11,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 1400,
                                                v: {
                                                    x: 1.1,
                                                    y: 8,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 500, v: 1 },
                                    { t: 1400, v: 0 },
                                ],
                            },
                            etwuK0pyakV20: {
                                transform: {
                                    data: { t: { x: -33.300003, y: -27.3 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 33.300003,
                                                    y: 27.3,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 1400,
                                                v: {
                                                    x: 36.300003,
                                                    y: 30.3,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 500, v: 1 },
                                    { t: 1400, v: 0 },
                                ],
                            },
                        },
                        s: 'MDDA1M2M2NmRhZYmMwYmRhY2JmVYjRiYWI5NmQJ4NTdlN2I3YjNdiNzc2ZGFmYHjRGYmRiMGFlUYmZiNGJhYjkR2ZDg1N2M3N1EE2ZGI0YmZiMCGJkYWNiZmI0RYmFiOWJlNmQM4NTdjRzc3NmERiMWI0YjdiNK0w2ZDg1N2M3BNzZkYWNiN2JJmU2IwYmRiOWFFjVWJmYjA2ZWDg1YjFhY2I3PYmViMDc3NmRYiZWJiUWIwYjJBhZjZkODU3YA2M4',
                    },
                ],
                options:
                    'MDQAxMDgyMjk3YDTdiRjY4NzlMJN2JZMjk0MTIL5NzM3NjY4NmRIyOVc4NA|',
            },
            'https://cdn.svgator.com/ply/',
            '__SVGATOR_PLAYER__',
            '2022-05-04',
            window,
            document,
            'script',
            'http://www.w3.org/2000/svg',
            'http://www.w3.org/1999/xlink',
        );
    },
};
</script>
