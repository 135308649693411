<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.improveSkills') }}
            </div>
            <div class="secondary-text">
                {{ $t('homeGame.foundationForSuccess') }}
            </div>
        </div>
        <div class="secondary">
            <ul>
                <li>{{ $t('homeGame.removeBarrier2') }}</li>
                <li>{{ $t('homeGame.unlockExciting2') }}</li>
            </ul>
        </div>

        <div class="sample-report">
            <img src="/images/sample-report.png" alt="Sample Report" />
        </div>
        <div class="small-text">
            {{ $t('homeGame.socialProof') }}
        </div>

        <div class="cta" @click="$emit('advance')">
            <div class="text">
                {{ $t('homeGame.choosePackage') }}
            </div>
        </div>
    </div>
</template>
<script>
import {
    TrackingService,
    EVENTS,
} from '@/core/services/TrackingService/TrackingService.js';
export default {
    emits: ['advance'],
    async mounted() {
        await new TrackingService().track(EVENTS.VIEW_PREMIUM_PARENT_SCREEN);
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        box-sizing: border-box;
        text-align: center;
        padding: 40px 20px 20px;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            font-size: 28px;
            font-weight: bold;
        }

        .secondary-text {
            font-size: 16px;
            margin-top: 16px;
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: #482c6e;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .secondary {
        width: 100%;
        position: relative;
        background-color: rgba($color: #fff, $alpha: 0.2);
        padding: 40px 0 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        ul {
            margin: 0;
            padding-inline-start: 30px;

            li {
                font-size: 15px;
                padding-right: 8px;
                margin-bottom: 5px;
                line-height: 1.3;
            }
        }

        .text {
            font-size: 29px;
            font-weight: bold;
        }

        .before-after {
            width: 120px;
            height: 60px;
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .small-text {
        font-size: 15px;
        margin-top: 8px;
        font-weight: normal;
    }

    .sample-report {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .heading {
            font-weight: bold;
            margin-bottom: 14px;
        }

        img {
            width: 320px;
        }
    }

    .price {
        font-size: 26px;

        .dollar-sign {
            display: inline-block;
            font-size: 36px;
        }

        .number {
            margin-top: 30px;
            display: inline-block;
            font-size: 36px;
            font-weight: bold;
        }
    }

    .normal-text {
        margin: 15px 0;
    }

    .cta {
        position: absolute;
        bottom: -30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }

        .text {
            font-size: 30px;
            font-weight: bold;
        }

        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .primary {
            padding: 20px 0 0;
        }

        .secondary {
            padding: 28px 0 0;
            margin-bottom: 24px;
        }

        .normal-text {
            margin: 6px 0;
        }

        .sample-report {
            margin-top: 12px;

            .heading {
                margin-bottom: 8px;
            }

            img {
                width: 212px;
            }
        }
    }
}
</style>
