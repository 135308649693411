import { isObject } from '@/core/helpers/utils';

/**
 * Google Analytics integration.
 *
 * NB! Not to confuse with Google Tag Manager integration.
 *
 * This class allows sending event data Google Analytics,
 * Google Ads, and Google Marketing Platform.
 *
 * https://developers.google.com/analytics/devguides/collection/gtagjs
 */
export class Gtag {}

Gtag.teacherSignUp = function () {
    return window.gtag('event', 'teacher_sign_up', {
        send_to: 'default',
        event_action: 'Teacher Signed Up',
    });
};

Gtag.referredSignupEvent = function (params) {
    if (!isObject(params)) {
        return;
    }

    let signupParams = {
        ...params,
        send_to: 'default',
    };

    if (Object.prototype.hasOwnProperty.call(params, 'referral')) {
        signupParams = {
            ...signupParams,
            event_action: `Referral sign up: ${params.referral}`,
        };
    }

    return window.gtag('event', 'sign_up', signupParams);
};

Gtag.linkClickEvent = function (params) {
    if (!isObject(params)) {
        return;
    }

    let linkClickParams = {
        ...params,
        send_to: 'default',
        event_category: 'Link click',
    };

    if (Object.prototype.hasOwnProperty.call(params, 'referredByWho')) {
        linkClickParams = {
            ...linkClickParams,
            event_action: `Link click for: ${params.referredByWho}`,
        };
    }

    return window.gtag('event', 'link_click', linkClickParams);
};
