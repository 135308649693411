export const ANIMAL_NAMES = [
    'panda',
    'giraffe',
    'elephant',
    'tiger',
    'lion',
    'raccoon',
    'cheetah',
    'zebra',
    'monkey',
    'parrot',
    'camel',
    'rhino',
    'meerkat',
    'kangaroo',
    'turtle',
    'duck',
    'frog',
    'mouse',
    'alpaca',
    'bear',
    'bat',
    'cat',
    'koala',
    'deer',
    'moose',
    'owl',
    'hedgehog',
    'rabbit',
];
