// cache helpers
export const toCache = (key, value) => {
    localStorage.setItem(`math99/cache/${key}`, JSON.stringify(value));
};

export const fromCache = (key, defaultValue) => {
    const src = localStorage.getItem(`math99/cache/${key}`);

    if (typeof src !== 'string') {
        return defaultValue;
    }

    try {
        return JSON.parse(src);
    } catch (err) {
        console.error('utils::fromCache error', key, err);
    }

    return defaultValue;
};

export const delCache = (key) => {
    localStorage.removeItem(`math99/cache/${key}`);
};

export const toSession = (key, value) => {
    sessionStorage.setItem(`math99/cache/${key}`, JSON.stringify(value));
};

export const fromSession = (key, defaultValue) => {
    const src = sessionStorage.getItem(`math99/cache/${key}`);

    if (typeof src !== 'string') {
        return defaultValue;
    }

    try {
        return JSON.parse(src);
    } catch (err) {
        console.error('utils::fromCache error', key, err);
    }

    return defaultValue;
};

export const delSession = (key) => {
    sessionStorage.removeItem(`math99/cache/${key}`);
};

// end cache helpers
