<template>
    <div class="ui-dialog-background">
        <div class="ui-dialog-window">
            <h2 class="ui-dialog-message">
                {{ $store.state.v2.ui.dialogs.choice.message }}
            </h2>
            <div class="ui-dialog-buttons">
                <MathButton
                    v-for="(btn, i) of Object.keys(buttons)"
                    :key="i"
                    class="ui-dialog-button"
                    :theme="buttons[btn].theme || 'primary'"
                    @click="resolveDialog(btn)"
                >
                    {{ buttons[btn].label }}
                </MathButton>
                <MathButton
                    v-if="withCancel"
                    class="ui-dialog-button"
                    theme="plain"
                    @click="resolveDialog(null)"
                >
                    Cancel
                </MathButton>
            </div>
        </div>
    </div>
</template>

<script>
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';

export default {
    name: 'DialogChoice',
    components: {
        MathButton,
    },
    computed: {
        withCancel() {
            return this.$store.state.v2.ui.dialogs.choice.withCancel;
        },
        buttons() {
            return this.$store.state.v2.ui.dialogs.choice.options;
        },
    },
    methods: {
        resolveDialog(val) {
            this.$store.commit('v2/ui/resolveChoice', val);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/components/components.ui-dialogs';
</style>
