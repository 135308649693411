/* global mixpanel */
export const AI_TUTOR_MP_EVENTS = {
    CLICKED_OPEN_AI_TUTOR_BTN: 'clickedOpenAiTutor',
    CLICKED_START_AI_TUTOR_BTN: 'clickedStartAiTutorBtn',
    SEES_AI_TUTOR: 'seesAITutor',
    SEES_AI_TUTOR_FIRST_INSTRUCTION: 'seesAiFirstInstruction',
    ASKED_AI_TUTOR_TO_EXPLAIN_DIFFERENTLY: 'askedAiToExplainDifferently',
    ANSWERED_CORRECT_WITH_AI_TUTOR_OPEN: 'answeredCorrectWithAiTutorOpen',
    ANSWERED_INCORRECT_WITH_AI_TUTOR_OPEN: 'answeredInCorrectWithAiTutorOpen',
    CLOSED_AI_TUTOR: 'closedAiTutor',
    SEES_AI_TUTOR_ON_PAUSE: 'seesAiTutorOnPause',
    NOT_ENOUGH_AI_CREDITS_TO_START: 'notEnoughAiCreditsToStart',
    OPENED_BUY_MORE_AI_CREDITS_MODAL: 'openedBuyMoreAiCreditsModal',
    SET_AI_CREDITS: 'setAICredits',
    REPLIED_AI_TUTOR_FEEDBACK: 'repliedAiTutorFeedback',
};

export const AI_TUTOR_MP_METHODS = {
    clickedOpenAiTutor({ topic }) {
        mixpanel.track('Clicked open AI tutor button', {
            Topic: topic,
        });
    },
    clickedStartAiTutorBtn() {
        mixpanel.track('Clicked start AI tutor button', {
            'Accessed from': 'aiAccessModal',
        });
    },
    seesAITutor({ topic }) {
        mixpanel.track('Sees AI Tutor', {
            Topic: topic,
        });
    },
    seesAiFirstInstruction() {
        mixpanel.track('Sees First Instruction in AI Tutor');
    },
    answeredCorrectWithAiTutorOpen({ topic }) {
        mixpanel.track('Answered correct with AI Tutor open', {
            Topic: topic,
        });
    },
    answeredInCorrectWithAiTutorOpen({ topic }) {
        mixpanel.track('Answered wrong with AI Tutor open', {
            Topic: topic,
        });
    },
    askedAiToExplainDifferently({ topic }) {
        mixpanel.track('Asked AI Tutor to explain differently', {
            Topic: topic,
        });
    },
    closedAiTutor(manually) {
        mixpanel.track('Closed AI Tutor', {
            Manually: manually,
        });
    },
    seesAiTutorOnPause({ topic, chatStage }) {
        mixpanel.track('Sees AI Tutor on pause', {
            Topic: topic,
            'Chat stage': chatStage,
        });
    },
    notEnoughAiCreditsToStart() {
        mixpanel.track('Not enough credits to start AI Tutor');
    },
    openedBuyMoreAiCreditsModal() {
        mixpanel.track('Opened buy more Ai Credits modal');
    },
    setAICredits(aiCredits) {
        mixpanel.people.set({ AICredits: aiCredits });
        mixpanel.register({ AICredits: aiCredits });
    },
    repliedAiTutorFeedback({ messageIndex, helpful }) {
        const event = helpful
            ? 'Replied helpful for AI Tutor answer'
            : 'Replied unhelpful for AI Tutor answer';

        mixpanel.track(event, { messageIndex });
    },
};
