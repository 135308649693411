import SecureApi from '@/flows/Authentication/services/SecureApi';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    async createClass(_store, payload) {
        try {
            const response = await SecureApi().post(
                'class-lists/create',
                payload,
            );

            if (response.data.success) {
                // clear localStorage classes list cache on new class successfully created.
                const uid = _store.state.user ? _store.state.user.userId : null;

                if (uid) {
                    sessionStorage.removeItem(`getClassList/${uid}`);
                }

                const { classCode } = response.data.data;

                new TrackingService().track(EVENTS.CREATED_CLASS, {
                    className: payload.className,
                    grade: payload.grade.toString(),
                });

                return classCode;
            } else {
                console.error(
                    'cC server error: ' + response.data.error.message,
                );

                return false;
            }
        } catch (e) {
            console.error('cC request error: ' + e);

            return false;
        }
    },
    async viewGoogleClassroom(_store, payload) {
        try {
            const response = await SecureApi().post(
                'class-lists/view-google-classroom',
                payload,
            );

            if (response.data.success) {
                return response.data.data;
            } else {
                const { message } = response.data.error;

                if (message === 'NoClassesInGoogleClassroom') {
                    alert(
                        'Selected Google Account does not have any Classes in Google Classroom',
                    );

                    return;
                }

                console.error('vGC server error: ' + message);
            }
        } catch (e) {
            console.error('vGC request error: ' + e);
        }
    },
    async importGoogleClassroom(_store, payload) {
        try {
            const response = await SecureApi().post(
                'class-lists/import-google-classroom',
                payload,
            );

            if (response.data.success) {
                const result = response.data.data;

                await _store.dispatch('v2/teacher/loadClassLists', {
                    skipLocalCache: true,
                });

                return result;
            } else {
                console.error(
                    'iGC server error: ' + response.data.error.message,
                );

                return response?.data?.error?.message;
            }
        } catch (e) {
            console.error('iGC request error: ' + e);
        }
    },
    async importCleverClassroom(_store, payload) {
        try {
            const response = await SecureApi().post(
                'class-lists/import-clever-classroom',
                payload,
            );

            if (response.data.success) {
                const result = response.data.data;

                await _store.dispatch('v2/teacher/loadClassLists', {
                    skipLocalCache: true,
                });

                return result;
            } else {
                console.error(
                    'iCC server error: ' + response.data.error.message,
                );

                return response?.data?.error?.message;
            }
        } catch (e) {
            console.error('iCC request error: ' + e);
        }
    },
    async getCleverSectionsFromAPI(_, token) {
        console.log('getCleverSEctions tok/id', token);

        const response = await SecureApi().post(
            '/class-lists/clever/sections',
            {
                token,
            },
        );

        const { data } = response;

        return data.success ? data.data : [];
    },
    async viewCleverClassroom({ dispatch }, code) {
        const cleverResponse = await dispatch('retrieveCleverAuthToken', code);

        if (cleverResponse.data) {
            console.log('retrievedCleverAuthToken', cleverResponse.data);

            const token = cleverResponse.data.access_token;

            const sectionsResponse = await dispatch(
                'getCleverSectionsFromAPI',
                token,
            );

            console.log('clever flows info', sectionsResponse);

            return sectionsResponse;
        }
        return {};
    },
    async getClassOverview(store, classCode) {
        try {
            if (!classCode) {
                return;
            }

            const response = await SecureApi().get(`class-lists/${classCode}`);

            if (response.data.success) {
                store.commit('v2/teacher/setSelectedClass', response.data.data);

                return response.data.data;
            } else {
                const errorMsg = response.data.error.message;

                // 404 is an OK error in this case.
                if (errorMsg.statusCode !== 404) {
                    console.error(
                        'gCO server error: ' +
                            JSON.stringify(response.data.error.message),
                    );
                }

                return false;
            }
        } catch (e) {
            console.error('gCO request error: ' + e);

            return false;
        }
    },
    async getClassFeed(_store, classCode, page) {
        try {
            const url = `class-feed/${classCode}?page=${page || 1}`;

            const response = await SecureApi().get(url);

            if (response?.data?.success) {
                return response.data.data;
            } else {
                console.error(
                    'gCF server error: ' + response.data.error.message,
                );

                return false;
            }
        } catch (error) {
            console.error('gCF request error: ' + error);

            return false;
        }
    },
};
