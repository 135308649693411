import { createStore } from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { DEFAULT_LANGUAGE } from '@/lang/languages';
import { jclone } from '@/core/helpers/utils';
import v2 from './v2';

export const initialState = {
    createGame: {
        selectedGameType: null,
        selectedNumberGenerator: null,
        gameTypes: [],
        topicPickerTree: [],
        error: null,
        exampleQuestions: [],
        options: null,
        playedAgain: false,
        curriculumSet: [],
        selectedGamePresetName: null,
        gameOriginInUI: null,
        gameOriginInUIDetail: null,
        preGameMode: '',
        liveGameOptions: null,
    },
    gameCode: null,
    joinGameCode: null,
    gameCodeError: null,
    gameState: {
        mode: null,
        started: null,
        variant: null,
        options: {},
        gameInfo: {
            type: null,
            numberGenerator: null,
            metaData: {},
        },
        players: [],
        numberOfPlayers: 0,
        round: {
            currentRound: null,
            roundFinished: false,
            endTime: null,
            roundTimeLeft: null,
            roundTimeLeftInSeconds: null,
            roundsCount: 0,
        },
        gameStatistics: [],
        joinGameMetaData: {},
        requiresLogin: null,
        selfPacedPlaylist: [],
    },
    playerState: {
        playerName: null,
        playerNameError: null,
        gameIsFullError: null,
        playerNameProfanityError: null,
        playerGuestSession: null,
        score: 0,
        multiplierScore: 0,
        currentPlayerSession: {
            id: null,
            score: 0,
            stats: {
                answeredQuestions: 0,
                playedTime: 0,
                correctAnswers: 0,
                wrongAnswers: 0,
                results: [],
            },
        },
        stats: {
            answeredQuestions: 0,
            playedTime: 0,
            correctAnswers: 0,
            wrongAnswers: 0,
        },
        activeInputType: null,
    },
    leaderboard: [],
    maxScore: null,
    user: null,
    clientType: null,
    audio: null,
    audioPlayers: [],
    language: DEFAULT_LANGUAGE,
    reports: [],
    isOnboardingDemo: false,
    reportsFromSkills: [],
    reportCategories: {},
    referral: null,
    referralUserId: null,
    nextPath: { path: null, params: null },
    lastActive: null,
    notifications: null,
    classLists: [],
    homeGame: null,
    gameCreatorRole: null,
    country: null,
    websocket: {
        status: {
            isConnected: false,
            shouldBeConnected: false,
            hasBeenConnected: false,
            reconnectCounter: 0,
        },
    },
    publicAbTest: null,
    forcedBefore3PM: null,
    gameSoundPlaying: false,
};

const store = createStore({
    modules: {
        v2,
    },
    // We need a deep clone of the object, {...var} is a shallow clone.
    state: jclone(initialState),
    getters,
    actions,
    mutations,
});

export default store;
