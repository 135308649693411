import { DisplayExponentiation } from './DisplayExponentiation';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { i18n } from '@/lang/translator';
import { mapGetters } from 'vuex';
import gameTypes from '@/core/static-json/gameTypes';

// This Mixin is called outside of components as well,
// so we can't use the simple this.$t for translations,
// as that is only available inside of the component.
export const GameTypeNames = {
    mixins: [DisplayExponentiation],
    computed: {
        ...mapGetters({
            locale: 'getCurrentLanguage',
        }),
    },
    methods: {
        getGameTypeName(type) {
            const GameTopic = TopicsFactory.getStaticTopicClass(type);
            if (GameTopic) {
                return GameTopic.getGameTopicTypeName();
            }

            switch (type) {
                /*
                case 'TYPE_SUBTRACTION':
                    return i18n.tc('game.gameTypeTitle.subtraction');
                case 'TYPE_DIVIDING':
                    return i18n.tc('game.gameTypeTitle.dividing');
                case 'TYPE_PERCENTAGE':
                    return i18n.tc('game.gameTypeTitle.percentage');
                case 'TYPE_ADDITION':
                    return i18n.tc('game.gameTypeTitle.addition');
                case 'TYPE_CONVERSION':
                    return i18n.tc('game.gameTypeTitle.conversion');
                case 'TYPE_ORDER_OF_OPERATIONS':
                    return i18n.tc('game.gameTypeTitle.orderOfOperations');
                case 'TYPE_COMPARING_NUMBERS':
                    return i18n.tc('game.gameTypeTitle.comparingAndOrdering');
                case 'TYPE_PERIMETER_AND_AREA':
                    return i18n.tc('game.gameTypeTitle.perimeterAndArea');
                case 'TYPE_ROUNDING':
                    return i18n.tc('game.gameTypeTitle.rounding');
                case 'TYPE_EQUATION':
                    return i18n.tc('game.gameTypeTitle.equation');
                case 'TYPE_NUMBER_LINE':
                    return i18n.tc('game.gameTypeTitle.numberLine');
                case 'TYPE_DECIMALS':
                    return i18n.tc('game.gameTypeTitle.decimals');
                case 'TYPE_FRACTIONS':
                    return i18n.tc('game.gameTypeTitle.fractions');
                case 'TYPE_MONEY':
                    return i18n.tc('game.gameTypeTitle.money');
                case 'TYPE_TIME':
                    return i18n.tc('game.gameTypeTitle.time');
                case 'TYPE_EXPONENTS':
                    return i18n.tc('game.gameTypeTitle.exponents');
                */
                // do next games types exists?
                case 'TYPE_CONVERSION_DECIMALS':
                    return i18n.tc('game.gameTypeTitle.conversionDecimals');
                case 'TYPE_IDENTIFYING_FRACTIONS':
                    return i18n.tc('game.gameTypeTitle.identifyingFractions');
                case 'TYPE_CONVERSION_FRACTIONS':
                    return i18n.tc('game.gameTypeTitle.conversionFractions');
                case 'TYPE_NUMBER_LINE_DECIMALS':
                    return i18n.tc('game.gameTypeTitle.numberLineDecimals');
                case 'TYPE_NUMBER_LINE_FRACTIONS':
                    return i18n.tc('game.gameTypeTitle.numberLineFractions');
                case 'TYPE_COMPARING_DECIMALS':
                    return i18n.tc('game.gameTypeTitle.comparingDecimals');
                case 'TYPE_NATURAL_NUMBERS':
                    return i18n.tc('game.gameTypeTitle.naturalNumbers');
                case 'TYPE_FRACTION':
                    return (
                        i18n.tc('game.gameTypeTitle.newFor') +
                        i18n.tc('game.gameTypeTitle.fractions')
                    );
                case 'TYPE_INTEGERS':
                    return i18n.tc('game.gameTypeTitle.integers');
                case 'TYPE_CONVERSIONS':
                    return i18n.tc('game.gameTypeTitle.conversion');
                case 'TYPE_NUMBER_SEQUENCE':
                    return i18n.tc('game.gameTypeTitle.numberSequence');
                default:
                    break;
            }
        },
        getNumberGeneratorName(gameInfo, convertSpecialChars = true) {
            if (gameInfo.userId && gameInfo.gameType) {
                gameInfo = gameInfo.gameType;
            }

            if (gameInfo.type) {
                const GameTopic = TopicsFactory.getStaticTopicClass(
                    gameInfo.type,
                );

                if (GameTopic) {
                    return GameTopic.getNumberGeneratorName(gameInfo);
                }
            }

            const { numberGenerator } = gameInfo;

            if (!numberGenerator) {
                return;
            }

            switch (numberGenerator.subtopic) {
                case 'convertingLength':
                case 'convertingWeight':
                case 'convertingArea':
                case 'convertingVolume':
                    if (
                        !numberGenerator.conversions ||
                        !numberGenerator.conversions.length
                    ) {
                        return;
                    }

                    let unitsString;

                    if (convertSpecialChars) {
                        unitsString = numberGenerator.conversions
                            .map(
                                (conversion) =>
                                    `${this.displayExponentiation(
                                        conversion.unit1,
                                    )} <-> ${this.displayExponentiation(
                                        conversion.unit2,
                                    )}`,
                            )
                            .join(', ');
                    } else {
                        unitsString = numberGenerator.conversions
                            .map(
                                (conversion) =>
                                    `${conversion.unit1} <-> ${conversion.unit2}`,
                            )
                            .join(', ');
                    }

                    if (
                        ['addition', 'subtraction'].includes(
                            numberGenerator.taskType,
                        )
                    ) {
                        return `- ${i18n.t(
                            'host.create.conversion.' +
                                numberGenerator.taskType +
                                'WithUnits',
                            { units: unitsString },
                        )}`;
                    }

                    return unitsString;
            }

            switch (numberGenerator.type) {
                case 'dividing':
                    if (
                        numberGenerator.divisor &&
                        typeof numberGenerator.divisor === 'object'
                    ) {
                        return `(${numberGenerator.divisor.nr2}..1) / (${numberGenerator.divisor.nr1}..1)`;
                    } else if (numberGenerator.divisor) {
                        return `(${numberGenerator.divisor}..1) / (10..1)`;
                    }

                    return `(${numberGenerator.dividend}..1) / (10..1)`;
                case 'custom':
                    const equationType = gameInfo.equationType
                        ? gameInfo.equationType
                        : this.getGameTypeEquationIcon(gameInfo.type);

                    return (
                        numberGenerator.nr1Min +
                        '...' +
                        numberGenerator.nr1Max +
                        ' ' +
                        equationType +
                        ' ' +
                        numberGenerator.nr2Min +
                        '...' +
                        numberGenerator.nr2Max
                    );
                case 'fixedNumber':
                    return numberGenerator.number + ' * 10';
                case 'additionAndSubtraction':
                case 'multiplicationAndDividing':
                case 'operationsMix':
                    return (
                        '(' +
                        numberGenerator.expressions
                            .join(', ')
                            .replace(/:/g, '÷')
                            .replace(/\*/g, 'x') +
                        ')'
                    );
                case 'scale':
                default:
                    if (gameInfo.equationType === '/') {
                        return null;
                    }

                    return numberGenerator.nr1 + '...' + numberGenerator.nr2;
            }
        },
        getGameTypeHeader(gameInfo) {
            const availableTranslations = ['es', 'et'];

            if (
                !gameInfo.metaData?.presetName ||
                availableTranslations.includes(this.locale)
            ) {
                const typeName = this.getGameTypeName(
                    gameInfo.type || gameInfo.gameType?.type,
                );

                const numberGenName = this.getNumberGeneratorName(gameInfo);

                return `${typeName} ${numberGenName}`;
            }

            return gameInfo.metaData.presetName;
        },
        getGameTypeWithDifficulty(gameInfo) {
            const typeName = this.getGameTypeName(gameInfo.type);

            const difficulty = this.getDifficulty(gameInfo);

            if (!typeName || !difficulty) {
                return null;
            }

            return `${typeName} ${i18n.tc('game.level.' + difficulty)}`;
        },
        getDifficulty(gameInfo) {
            let difficulty;
            switch (gameInfo.type) {
                case 'TYPE_ADDITION':
                case 'TYPE_SUBTRACTION':
                    switch (gameInfo.numberGenerator.name) {
                        case '1to10':
                            difficulty = 'easy';
                            break;
                        case '1to20':
                            difficulty = 'easyPlus';
                            break;
                        case '20to100':
                            difficulty = 'medium';
                            break;
                        case '100to1000':
                            difficulty = 'pro';
                            break;
                    }
                    break;
                case 'TYPE_MULTIPLICATION':
                    switch (gameInfo.numberGenerator.name) {
                        case '2, 3, 4, 5 & 10':
                            difficulty = 'medium';
                            break;
                        case '6, 7, 8 & 9':
                            difficulty = 'mediumPlus';
                            break;
                        case '1to10':
                            difficulty = 'pro';
                            break;
                        case '1to12':
                            difficulty = 'proPlus';
                            break;
                        default:
                            difficulty = 'easy';
                    }
                    break;
                case 'TYPE_DIVIDING':
                    switch (gameInfo.numberGenerator.dividend) {
                        case 20:
                            difficulty = 'easy';
                            break;
                        case 50:
                            difficulty = 'easyPlus';
                            break;
                        case 100:
                            difficulty = 'medium';
                            break;
                        case 144:
                            difficulty = 'pro';
                            break;
                    }
                    break;
                case 'TYPE_ORDER_OF_OPERATIONS':
                    difficulty = gameInfo.numberGenerator.name;
                    break;
            }

            return difficulty;
        },
        getRounds(gameInfo) {
            if (gameInfo.duration) {
                return `1 ${i18n.tc('game.rounds')} x ${
                    gameInfo.duration * 60
                } ${i18n.tc('general.seconds')}`;
            }

            return gameInfo.rounds && gameInfo.roundDuration
                ? `${gameInfo.rounds} ${i18n.tc('game.rounds')} x ${
                      gameInfo.roundDuration
                  } ${i18n.tc('general.seconds')}`
                : null;
        },
        getGameTypeIconPath(gameType) {
            const name = gameType.name
                ? gameType.name
                : gameType.type
                      .replace('TYPE_', '')
                      .replace('_TYPE', '')
                      .replace(/_/g, '-');

            const formattedName = name.toLowerCase().replace(/ /g, '-');

            return `./images/topic-icons/${formattedName}.svg`;
        },
        getGameTypeEquationIcon(gameTypeString) {
            console.debug('gameTypeString: ', gameTypeString);
            const currentGameType = gameTypes.find(
                (gameType) => gameType.type === gameTypeString,
            );
            console.debug('currentGameType: ', currentGameType);
            return currentGameType?.equationType || '';
        },
    },
};
