<template>
    <div class="arena-out-of-energy-modal">
        <div class="arena-out-of-energy-modal-content">
            <div class="arena-out-of-energy-modal-header">
                <div class="text-body-l">
                    YOU NEED MORE
                    <img
                        class="energy-icon"
                        src="/images/lightning-vertical.svg"
                        alt="energy"
                    />
                </div>
                <ActionClose
                    :size="ActionSize.Small"
                    class="modal-close"
                    @click="closeModal"
                />
            </div>
            <div class="arena-out-of-energy-modal-body">
                <div class="row text text-body-l">
                    YOU NEED
                    <div class="text boxed text-body-l">
                        <img
                            class="energy-icon"
                            src="/images/lightning-vertical.svg"
                            alt="energy"
                        />
                        1
                    </div>
                    TO PLAY
                </div>
                <div class="modal-content">
                    <div class="content text">
                        <div class="row boxed text-display">
                            <img
                                class="energy-icon-big"
                                src="/images/lightning-vertical.svg"
                                alt="energy"
                            />
                            {{ currentEnergy }}
                        </div>
                        <div class="row text-body-s">
                            +1
                            <img
                                class="energy-icon"
                                src="/images/lightning-vertical.svg"
                                alt="energy"
                            />
                            in
                            <RevampArenaEnergy show-timer />
                        </div>
                    </div>
                </div>
                <ButtonMedium
                    v-if="!subscribedUser"
                    :text="'GO TO SHOP'"
                    :image-url="'/ui/game/64/icon_shop.svg'"
                    @click="goToShop"
                />
                <ButtonMedium v-else :text="`I'll wait`" @click="closeModal" />
            </div>
        </div>
    </div>
</template>
<script>
import ButtonMedium from '@/student/ui/button/ButtonMedium.vue';
import ActionClose from '@/student/ui/action/ActionClose.vue';
import { ActionSize } from '@/student/ui/action/Action.vue';
import { mapGetters } from 'vuex';
import RevampArenaEnergy from '@/flows/MathRunner/components/RevampArenaEnergy.vue';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService.js';

export default {
    components: { ButtonMedium, ActionClose, RevampArenaEnergy },
    emits: ['close'],
    data() {
        return {
            ActionSize,
        };
    },
    computed: {
        ...mapGetters(['user', 'isBefore3PM', 'subscribedUser']),
        currentEnergy() {
            return this.user?.studentInfo?.arenaEnergy?.current || 0;
        },
    },
    mounted() {
        new TrackingService().track(EVENTS.SEES_OUT_OF_ENERGY);
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        goToShop() {
            new TrackingService().track(EVENTS.CLICKS_CTA_IN_OUT_OF_ENERGY);

            this.$emitter.emit(
                'openHomeGamePaymentModal',
                'arena-out-of-energy',
            );

            this.closeModal();
        },
    },
};
</script>
<style lang="scss" scoped>
.modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
}
.arena-out-of-energy-modal {
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
    background: rgba(0, 0, 0, 0.8);
    z-index: 4000;
    display: flex;
    justify-content: center;
}
.arena-out-of-energy-modal-content {
    max-width: 760px;
    max-height: 464px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.arena-out-of-energy-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 64px;
    min-height: 50px;
    gap: 8px;
    flex-shrink: 0;
    background: var(--Purple-700, #450c54);
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.25);
    position: relative;
}
.arena-out-of-energy-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 1;
    gap: 0.5rem;
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    height: calc(100% - 2rem - 50px);
    position: relative;
    background: var(
        --Purple-soft_gradient,
        linear-gradient(180deg, #e34fd0 0%, #6e0080 100%)
    );
}
.modal-content {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 401;
    background: linear-gradient(180deg, #6e0080 0%, rgba(110, 0, 128, 0) 100%);
    padding: 1.5rem 0 1rem;

    @include mq(400px, max, height) {
        gap: 0.5rem;
        padding: 1rem 0 0.5rem;
    }
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .boxed {
        padding: 0 10px;
    }
}
.energy-icon-big {
    width: 40px;
}
</style>
