import { isTeacher } from '@/flows/Authentication/helpers/AuthHelpers';

import store from '@/store/index';
import CONSTANTS from '@/core/helpers/constants';

export async function requireGameOwner(gameCode) {
    if (!gameCode) {
        return false;
    }

    const { dispatch, getters } = store;

    const user = getters.user;

    if (!user) {
        // @todo: figure out whats the correct way to handle this edge case.
        // There might be a scenario where the user is not yet in store.
        // @solution: cuz now routing happens upper level - just return false
        return false;
    }

    const existingGame = store.state.v2.game.tbl.info;

    if (!existingGame || existingGame.code !== gameCode) {
        console.log('all bad is here', gameCode, existingGame);

        if (
            store.state.v2.game.mode === CONSTANTS.LIVE_GAME_MODE &&
            store.state.v2.game.code &&
            store.getters['v2/game/tbl/ioOpen']
        ) {
            await dispatch('v2/game/syncronize');
        }
    } else {
        return user?.userId === existingGame?.userId;
    }

    const checkGame = store.state.v2.game.tbl.info || store.getters.getGameInfo;

    return user?.userId === checkGame?.userId;
}

export function homePageRoute() {
    const { getters } = store;

    // homePageRoute checks after jwt
    // so user exists (logged in) or not (guest)
    const user = getters.user;

    if (user && (user.email || user.username)) {
        if (isTeacher(user)) {
            return { name: 'host' };
        }

        return { name: 'home-game.v10.main-page' };
    }

    return { name: 'home' };
}

export function requireTeacherRole() {
    if (!store.getters.user) {
        return '/';
    }

    if (isTeacher(store.getters.user)) {
        return null;
    }

    return '/home-game';
}
