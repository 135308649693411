<template>
    <div class="esm-wrapper">
        <div class="esm-container">
            <img
                src="/images/quests-widget/close-btn.svg"
                alt="close"
                class="esm-close-btn"
                @click="closeModal"
            />
            <span class="esm-heading">Email sent!</span>
            <span class="esm-subheading">
                The email has been successfully sent!
            </span>
            <button class="esm-btn" @click="goToPremiumPage">
                Visit Premium
            </button>
            <span class="esm-text">
                Visit our Premium page to get more information
            </span>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('v2/teacherPremium', [
            'setShowEmailSentModal',
            'goToPremiumPage',
        ]),
        closeModal() {
            this.setShowEmailSentModal(null);
        },
    },
};
</script>
<style lang="scss" scoped>
.esm-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
}
.esm-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 706px;
    height: 546px;
    border-radius: 10px;
    border: 2px solid rgba(255, 208, 91, 0.5);
    background: linear-gradient(180deg, #360773 60%, #582699 80%);

    @media screen and (max-width: $w-max-desktop) {
        height: 500px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 90%;
        height: 90%;
    }
}
.esm-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: translate(-10px, 10px);
    cursor: pointer;
}
.esm-heading {
    margin: 40px 0;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 30px;
    font-weight: 700;
}
.esm-subheading {
    margin-bottom: 80px;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 18px;
    font-weight: 600;

    @media screen and (max-width: $w-max-phone) {
        font-size: 16px;
    }
}
.esm-text {
    margin: 26px 0;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 18px;
    font-weight: 600;

    @media screen and (max-width: $w-max-phone) {
        font-size: 16px;
    }
}
.esm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 42px;
    border-radius: 8px;
    border: unset;
    background: var(--Identity-Accent-Primary-CTA, #f08a4f);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    color: var(--UI-White, #fff);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
</style>
