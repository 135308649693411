export default {
    namespaced: true,
    state: {
        source: null,
        volume: 0,
        loop: false,
        autoplay: false,
        paused: false,
    },
    mutations: {
        update: (state, next) => {
            if (!next || !next.source) {
                state.source = null;

                state.volume = 0;

                state.loop = false;

                state.autoplay = false;

                state.paused = false;

                return;
            }

            state.source = next.source;

            state.volume = next.volume;

            state.loop = next.loop;

            state.autoplay = next.autoplay;

            state.paused = next.paused;
        },
    },
    actions: {
        init: async () => {
            console.log('store::ui/audio::init');
        },
        update: (store, audio) => {
            store.commit('update', audio);
        },
        stop: (store, allSounds = false) => {
            store.commit('update', null);
        },
    },
};
