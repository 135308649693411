// import { jclone } from '@/core/helpers/utils';
import io from 'socket.io-client';

const store = {
    namespaced: true,
    state: {
        io: {}, // socket-io.client Manager instances by region
        ioServer: null, // backend socket.io server managing game
        socket: null, // socket-io.client game socket instance
        nsp: null, // namespace (game type)
        open: false, // was opened and wasn't closed
        connected: false, // connected or not. open && !connected = reconnect require
        reconnects: 0, // number of reconnects for current socket
        connectResolve: null, // promise resolve for async join
        cacheConnect: false, // flag to check if connection was done by cached data
        leaveOnReconnect: false, // flag to leave on reconnect
    },
    getters: {
        connected: (state) => state.connected,
        socket: (state) => state.socket,
        open: (state) => state.open,
    },
    mutations: {
        setIo: (state, { io, region }) => {
            state.io[region] = io;
        },
        ioServer: (state, next) => {
            state.ioServer = next || null;
        },
        resetReconnects: (state, next = 0) => {
            state.reconnects = next;
        },
        incReconnects: (state) => {
            state.reconnects++;
        },
        updateConnectionQuery: (state) => {
            const key = state.ioServer || state.region;
            if (key) {
                state.io[key].opts.transports = ['websocket'];

                state.io[key].opts.query.CC = state.reconnects;

                const userJwt = localStorage.getItem('authToken');

                const guestJwt = localStorage.getItem('guestToken');

                state.io[key].opts.query.jwt = userJwt || guestJwt;
            }
        },
        nsp: (state, next) => {
            // console.log('store::mathRunnerIo/nsp', next);
            state.nsp = next;
        },
        open: (state, next) => {
            // console.log('store::mathRunnerIo/open', next);
            state.open = next;
        },
        leaveOnReconnect: (state, next) => {
            state.leaveOnReconnect = next;
        },
        setConnected: (state, next) => {
            state.connected = next;

            if (next && state.connectResolve) {
                state.connectResolve();

                state.connectResolve = null;
            }
        },
        setSocket: (state, socket) => {
            if (state.socket) {
                // disconnect any existing listeners
                state.socket.off();
            }

            state.socket = socket;

            state.connected = socket ? socket.connected : false;
        },
        connectResolve: (state, next) => {
            state.connectResolve = next;
        },
        cacheConnect: (state, next) => {
            state.cacheConnect = next;
        },
        noCache: (state, next) => {
            state.noCache = next;
        },
    },
    actions: {
        init: async (store) => {
            console.log('store::mathRunner::io init');

            store.commit('updateConnectionQuery');

            store.commit('nsp', 'math-runner');

            if (store.state.nsp && !store.state.socket) {
                await store.dispatch('join', store.state.nsp);
            }
        },
        saveCache: (store) => {
            if (store.state.noCache) return;

            const state = {
                nsp: store.state.nsp,
                ioServer: store.state.ioServer,
                open: store.state.open,
                // timerSync: store.state.timerSync,
                reconnects: store.state.reconnectes,
            };

            const str = JSON.stringify(state);

            sessionStorage.setItem('store/cache/mathRunnerIo', str);

            // console.log('store::mathRunnerIo cache saved');
        },
        loadCache: (store) => {
            console.log('store::mathRunnerIo loading cache');

            const str = sessionStorage.getItem('store/cache/mathRunnerIo');

            let cache = null;

            try {
                cache = JSON.parse(str);
            } catch (err) {
                // console.log('store uncaching error', 'io', str, err);
                cache = null;
            }

            if (!cache) return;

            store.commit('nsp', cache.nsp);

            store.dispatch('setIoServer', cache.ioServer);

            store.commit('open', cache.open);

            store.commit('resetReconnects', cache.reconnects);

            // store.commit('timerSync', cache.timerSync);

            store.commit('updateConnectionQuery');

            console.log('store::mathRunnerIo cache loaded');
        },
        clearCache: () => {
            console.log('store::mathRunnerIo clear cache');

            sessionStorage.removeItem('store/cache/mathRunnerIo');
        },
        setIoServer: (store, url) => {
            if (url && !store.state.io[url]) {
                console.log(`store::mathRunnerIo new io manager ${url}`);
                // new io for server
                const ioManager = io(url, {
                    autoConnect: false,
                    transports: ['websocket'],
                    cors: [url],
                    // query fields JWT and CC are applyed in store/modules/io.js
                    // but query object should be defined for easy later access
                    // sending JWT in query, cuz
                    // "In a browser environment, the extraHeaders option will be ignored if you only enable the WebSocket transport, since the WebSocket API in the browser does not allow providing custom headers." (https://socket.io/docs/v4/client-options/#extraheaders)
                    // but token should be accessible on allowRequest
                    query: {},
                    multiplex: false,
                    forceNew: true,
                });

                ioManager.on('reconnect_attempt', () => {
                    console.log(
                        `store::mathRunnerIo.socket reconnect attempt in setIoServer ${url}`,
                    );

                    store.commit('incReconnects');

                    store.commit('updateConnectionQuery');
                });

                store.commit('setIo', {
                    io: ioManager.io,
                    region: url,
                });
            }

            store.commit('ioServer', url);
        },
        join: (store, nsp) => {
            const ioServer = import.meta.env.VITE_API_URL;

            // saving null token if had before
            store.dispatch('setIoServer', ioServer);

            if (
                store.state.open &&
                store.state.socket &&
                store.state.nsp === nsp
            ) {
                return;
            }

            if (!store.state.cacheConnect) {
                store.commit('resetReconnects', 0);
            }

            store.commit('updateConnectionQuery');

            const socket = store.state.io[ioServer].socket(`/${nsp}`, {
                forceNew: true,
            });

            store.commit('setSocket', socket);

            store.commit('nsp', nsp);

            store.commit('open', true);

            store.commit('updateConnectionQuery');

            socket.on('connect', async () => {
                socket.emit('join', store.rootState.v2.mathRunner.gameCode);

                // console.log('io::store joined0', nsp);

                store.commit('setConnected', true);

                if (store.state.leaveOnReconnect) {
                    store.dispatch('leave');

                    return;
                }

                store.commit('setWebsocketConnectionStatus', true, {
                    root: true,
                });

                store.commit('setWebsocketShouldBeConnected', true, {
                    root: true,
                });

                // console.log('io::store joined', nsp);

                store.dispatch('saveCache');
            });
            socket.on('disconnect', async () => {
                store.commit('setConnected', false);

                store.commit('cacheConnect', false);

                store.commit('setWebsocketConnectionStatus', false, {
                    root: true,
                });

                store.dispatch('saveCache');
            });
            socket.on('playerJoined', async (data) => {
                await store.commit('v2/mathRunner/addPlayer', data, {
                    root: true,
                });

                store.dispatch('v2/mathRunner/saveCache', data, { root: true });
            });

            socket.on('relayPendingPlayerAnswer', (data) => {
                store.dispatch(
                    'v2/mathRunner/otherPlayersPendingAnswer',
                    data,
                    {
                        root: true,
                    },
                );
            });

            socket.on('updatedPlayersStats', (data) => {
                store.commit('v2/mathRunner/players', data, {
                    root: true,
                });
            });

            socket.on('relayPlayerAnswer', (data) => {
                store.dispatch('v2/mathRunner/otherPlayersAnswer', data, {
                    root: true,
                });
            });

            socket.on('playerLostLife', async (data) => {
                await store.commit('v2/mathRunner/playerLostLife', data, {
                    root: true,
                });

                store.dispatch('v2/mathRunner/saveCache', data, { root: true });
            });

            socket.on('playerOutOfLives', (data) => {
                store.dispatch('v2/mathRunner/playerFinished', data, {
                    root: true,
                });
            });

            socket.on('magicTimeStarted', (data) => {
                store.dispatch('v2/mathRunner/startMagicTime', data, {
                    root: true,
                });
            });

            socket.on('magicTimeEnded', (data) => {
                store.dispatch('v2/mathRunner/endMagicTime', data, {
                    root: true,
                });
            });

            socket.on('gameEnded', (data) => {
                store.dispatch('v2/mathRunner/endTheGame', data, {
                    root: true,
                });
            });

            socket.on('questionsUpdate', async (data) => {
                await store.commit('v2/mathRunner/incrementBatch', null, {
                    root: true,
                });

                store.dispatch('v2/mathRunner/updateQuestions', data, {
                    root: true,
                });
            });

            socket.on('allPlayersOutOfLives', (data) => {
                store.dispatch('v2/mathRunner/endTheGame', data, {
                    root: true,
                });
            });

            socket.on('playerUsedSpell', (data) => {
                store.dispatch('v2/mathRunner/syncronize', data.gameCode, {
                    root: true,
                });

                store.dispatch('v2/mathRunner/playerUsedSpell', data, {
                    root: true,
                });
            });

            socket.on('playerLeftGame', (data) => {
                store.dispatch('v2/mathRunner/playerLeftGame', data, {
                    root: true,
                });
            });

            socket.on('hostLeftGame', (data) => {
                store.dispatch('v2/mathRunner/hostLeftGame', data, {
                    root: true,
                });
            });

            console.log('store::MathRunnerIo.join socket binded');

            return new Promise((resolve) => {
                store.commit('connectResolve', resolve);

                socket.open();
                // Debug helper to test disconnections.
                /*function createChaos() {
                    console.log('createChaos()')
                    setTimeout(() => {
                        if (socket.io.engine) {
                            console.log('chaos')
                            socket.io.engine.close(); // close the WebSocket connection (the client will then try to reconnect after a short delay)
                        }
                        createChaos();
                    }, Math.floor(Math.random() * 10000) + 1000);
                }

                createChaos();*/
            });
        },
        reset: (store) => {
            console.log('store::MathRunnerIo.reset');

            store.dispatch('leave');
        },
        leave: (store) => {
            const socket = store.state.socket;

            if (socket) {
                socket.emit('leave', store.rootState.v2.mathRunner.gameCode);

                socket.close();

                socket.off();

                store.commit('setSocket', null);
            }

            store.commit('open', false);

            store.commit('setSocket', null);

            store.commit('nsp', null);

            store.commit('resetReconnects', 0);

            store.commit('leaveOnReconnect', false);

            store.commit('setWebsocketShouldBeConnected', false, {
                root: true,
            });

            store.dispatch('v2/saveCache', null, { root: true });
        },
        emit: (store, payload) => {
            if (!store.state.socket) {
                console.log(
                    'store::mathRunnerIo emit error: NO SOCKET',
                    payload,
                );

                return;
            }

            let args = [];

            if (typeof payload === 'string') {
                args.push(payload);
            }

            if (typeof payload === 'object') {
                if (payload.data) {
                    if (Array.isArray(payload.data)) {
                        args = [...payload.data];
                    } else {
                        args = [payload.data];
                    }
                }

                args.unshift(payload.event);
            }

            store.state.socket.emit(...args);
        },
        request: (store, payload) =>
            new Promise((resolve, reject) => {
                if (!store.state.socket) {
                    console.error('NO_SOCKET request', payload);

                    return reject('NO_SOCKET');
                }

                let args = [];

                if (typeof payload === 'string') {
                    args.push(payload);
                }

                if (typeof payload === 'object') {
                    if (payload.data) {
                        if (Array.isArray(payload.data)) {
                            args = [...payload.data];
                        } else {
                            args = [payload.data];
                        }
                    }

                    args.unshift(payload.event);
                }

                store.state.socket.emit(...args, (result) => {
                    resolve(result);
                });
            }),
    },
};

export default store;
