/* global mixpanel */
export const TEACHER_ONBOARDING_SURVEY_MP_EVENTS = {
    TEACHER_SURVEY_VIEWED_PAGE: 'viewedTeacherSurveyPage',
    TEACHER_SURVEY_ANSWERED_GRADES: 'teacherSurveyAnsweredGradesStep',
    TEACHER_SURVEY_ANSWERED_FIRST_STEP: 'teacherSurveyAnsweredFirstStep',
    TEACHER_SURVEY_ANSWERED_SECOND_STEP: 'teacherSurveyAnsweredSecondStep',
    TEACHER_SURVEY_FINISHED: 'finishesTeacherSurvey',
    TEACHER_SURVEY_CLICKED_ON_DASHBOARD_CTA:
        'teacherSurveyClickedOnDashboardBannerCta',
};

export const TEACHER_ONBOARDING_SURVEY_MP_METHODS = {
    viewedTeacherSurveyPage() {
        mixpanel.track('Teacher survey - viewed page');
    },
    teacherSurveyAnsweredGradesStep(data) {
        mixpanel.track('Teacher survey - answered grades step', data);
    },
    teacherSurveyAnsweredFirstStep(data) {
        mixpanel.track('Teacher survey - answered first step', data);
    },
    teacherSurveyAnsweredSecondStep(data) {
        mixpanel.track('Teacher survey - answered second step', data);
    },
    finishesTeacherSurvey(data) {
        mixpanel.track('Teacher survey - completes it', data.event);
        const userData = data.user;

        mixpanel.people.set({
            'Survey: Grades': userData.grades,
            'Survey: Classes': userData.classes,
            'Survey: Teaching mode': userData.teachingMode,
            'Survey: Devices in school': userData.inSchoolDevices,
            'Survey: Remote teaching': userData.inRemoteLearning,
            'Survey: Online homework': userData.onlineHomework,
            'Survey: Devices access': userData.devicesAccess,
        });
    },
    teacherSurveyClickedOnDashboardBannerCta() {
        mixpanel.track(
            'Teacher survey - clicked see video on dashboard banner',
        );
    },
};
