import {
    randomIntFromRange,
    randomArrayElement,
    round,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    isCorrectFractionAnswer,
    getFractionLayout,
    FractionsOperations,
} from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class MultiplicationTopic extends TopicsBaseClass {
    static code = 'TYPE_MULTIPLICATION';
    static icon = 'x';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.multiplication';

    // eslint-disable-next-line no-unused-vars
    static getNumberGeneratorName(gameInfo, convertSpecialChars = true) {
        const { numberGenerator } = gameInfo;

        const { topic, scale, range } = numberGenerator;

        const topicName = this.t(`game.gameTypeTitle.${topic}`);

        if (!topic || topic === 'integers') {
            const genName = () => {
                switch (numberGenerator.type) {
                    case 'custom':
                        return (
                            numberGenerator.nr1Min +
                            '...' +
                            numberGenerator.nr1Max +
                            ' * ' +
                            numberGenerator.nr2Min +
                            '...' +
                            numberGenerator.nr2Max
                        );
                    case 'fixedNumber':
                        return numberGenerator.number + ' * 10';
                    case 'range':
                        return (
                            range.slice(0, -1).join(', ') +
                            ' & ' +
                            range.slice(0, -1)
                        );
                    case 'scale':
                    default:
                        return (
                            numberGenerator.nr1 + '...' + numberGenerator.nr2
                        );
                }
            };

            return !topic ? genName() : `${topicName}: ${genName()}`;
        }

        if (topic === 'decimals') {
            let allScales = [];

            // Push each scale of each topic to array
            for (const taskType in scale) {
                for (const value of scale[taskType]) {
                    allScales.push(value);
                }
            }

            // Create a sorted array of unique values
            const scales = [...new Set([...allScales])].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'host.create.decimals.wholeNumbers',
                        ).toLowerCase();
                    case 10:
                        return this.t(
                            'host.create.decimals.tens',
                        ).toLowerCase();
                    case 100:
                        return this.t(
                            'host.create.decimals.hundreds',
                        ).toLowerCase();
                    case 1000:
                        return this.t(
                            'host.create.decimals.thousands',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }
    }

    static generateQuestion(numberGenerator) {
        const { topic, scale, range } = numberGenerator;

        const isFractionsTopic = topic === 'fractions';

        let fractionOperationAnswer = 0;

        let number1;

        let number2;

        if (!topic || topic === 'integers') {
            switch (numberGenerator.type) {
                case 'fixedNumber':
                    number1 = parseInt(numberGenerator.number);

                    number2 = randomIntFromRange(1, 10);

                    break;
                case 'custom':
                    number1 = randomIntFromRange(
                        parseInt(numberGenerator.nr1Min),
                        parseInt(numberGenerator.nr1Max),
                    );

                    number2 = randomIntFromRange(
                        parseInt(numberGenerator.nr2Min),
                        parseInt(numberGenerator.nr2Max),
                    );

                    break;
                case 'range':
                    number1 = randomArrayElement(range);

                    number2 = randomArrayElement(range);

                    break;
                case 'scale':
                default:
                    number1 = randomIntFromRange(
                        numberGenerator.nr1,
                        numberGenerator.nr2,
                    );

                    number2 = randomIntFromRange(1, 10);

                    break;
            }
        }

        if (topic === 'decimals') {
            const scaleKeys = Object.keys(scale);

            const randomTopic = randomArrayElement(scaleKeys);

            const scales = scale && scale[randomTopic];

            [number1, number2] = generate(randomTopic, scales);

            // eslint-disable-next-line no-inner-declarations
            function generate(topicId, scales) {
                const createDecimal = (dividend) => {
                    const int1 = randomIntFromRange(1, 9);

                    const int2 = randomIntFromRange(11, 12);

                    return randomArrayElement([int1, int2]) / dividend;
                };

                // decimal x decimal
                if (topicId === '1') {
                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        const a = createDecimal(10);

                        const b = createDecimal(100);

                        return [a, b];
                    }

                    if (scales.includes(0.1)) {
                        const a = createDecimal(10);

                        const b = createDecimal(10);

                        return [a, b];
                    }

                    if (scales.includes(0.01)) {
                        const a = createDecimal(100);

                        const b = createDecimal(100);

                        return [a, b];
                    }
                }

                // decimal x whole number
                if (topicId === '2') {
                    const opt1 = () => {
                        const a = randomIntFromRange(1, 12);

                        const b = createDecimal(10);

                        return [a.toFixed(), b.toFixed(1)];
                    };

                    const opt2 = () => {
                        const a = randomIntFromRange(1, 12);

                        const b = randomIntFromRange(1, 12) / 100;

                        return [a.toFixed(), b.toFixed(2)];
                    };

                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        return randomArrayElement([opt1(), opt2()]);
                    }

                    if (scales.includes(0.1)) {
                        return opt1();
                    }

                    if (scales.includes(0.01)) {
                        return opt2();
                    }
                }

                // decimal x 0.1, 0.01, 0.001
                if (topicId === '3') {
                    const opt1 = () => {
                        const a1 = randomIntFromRange(1, 100);

                        const a2 = randomIntFromRange(1, 1000) / 10;

                        const a3 = randomIntFromRange(1, 1000) / 100;

                        const a = randomArrayElement([a1, a2, a3]);

                        const b = 1 / 10;

                        return [a, b];
                    };

                    const opt2 = () => {
                        const a1 = randomIntFromRange(1, 100);

                        const a2 = randomIntFromRange(1, 1000) / 10;

                        const a3 = randomIntFromRange(1, 1000) / 100;

                        const a = randomArrayElement([a1, a2, a3]);

                        const b = 1 / 100;

                        return [a, b];
                    };

                    const opt3 = () => {
                        const a1 = randomIntFromRange(1, 1000);

                        const a2 = randomIntFromRange(1, 1000) / 10;

                        const a = randomArrayElement([a1, a2]);

                        const b = 1 / 1000;

                        return [a, b];
                    };

                    if (
                        scales.includes(0.1) &&
                        scales.includes(0.01) &&
                        scales.includes(0.001)
                    ) {
                        return randomArrayElement([opt1(), opt2(), opt3()]);
                    }

                    if (scales.includes(0.1) && scales.includes(0.01)) {
                        return randomArrayElement([opt1(), opt2()]);
                    }

                    if (scales.includes(0.1) && scales.includes(0.001)) {
                        return randomArrayElement([opt1(), opt3()]);
                    }

                    if (scales.includes(0.01) && scales.includes(0.001)) {
                        return randomArrayElement([opt2(), opt3()]);
                    }

                    if (scales.includes(0.1)) {
                        return opt1();
                    }

                    if (scales.includes(0.01)) {
                        return opt2();
                    }

                    if (scales.includes(0.001)) {
                        return opt3();
                    }
                }

                // decimal x 10, 100, 1000
                if (topicId === '4') {
                    const intA = randomIntFromRange(1, 100);

                    const a1 = intA / 10;

                    const a2 = intA / 100;

                    const a3 = intA / 1000;

                    const a = randomArrayElement([a1, a2, a3]);

                    if (
                        scales.includes(10) &&
                        scales.includes(100) &&
                        scales.includes(1000)
                    ) {
                        const b = randomArrayElement([10, 100, 1000]);

                        return [a, b];
                    }

                    if (scales.includes(10) && scales.includes(100)) {
                        const b = randomArrayElement([10, 100]);

                        return [a, b];
                    }

                    if (scales.includes(10) && scales.includes(1000)) {
                        const b = randomArrayElement([10, 1000]);

                        return [a, b];
                    }

                    if (scales.includes(100) && scales.includes(1000)) {
                        const b = randomArrayElement([100, 1000]);

                        return [a, b];
                    }

                    if (scales.includes(10)) {
                        const b = 10;

                        return [a, b];
                    }

                    if (scales.includes(100)) {
                        const b = 100;

                        return [a, b];
                    }

                    if (scales.includes(1000)) {
                        const b = 1000;

                        return [a, b];
                    }
                }
            }
        }

        if (topic === 'fractions') {
            const fractionsOperations = new FractionsOperations(
                numberGenerator,
            );

            const { d1, d2 } =
                fractionsOperations.getDenominators(numberGenerator);

            const { n1, n2 } =
                fractionsOperations.getNominators(numberGenerator);

            const { w1, w2 } =
                fractionsOperations.getWholeNumber(numberGenerator);

            fractionOperationAnswer =
                fractionsOperations.getFractionMultiplicationAnswer(
                    { n1, d1, w1 },
                    { n2, d2, w2 },
                );

            number1 = { numerator: n1, denominator: d1, wholeNumber: w1 };

            number2 = { numerator: n2, denominator: d2, wholeNumber: w2 };
        }

        let answer = Number(number1) * Number(number2);

        answer = round(answer, 6);

        return {
            topic,
            number1,
            number2,
            answer: isFractionsTopic ? fractionOperationAnswer : answer,
        };
    }

    static formatQuestion(questionData, skill, calledIn) {
        const isFractionsTopic =
            questionData?.topic === 'fractions' ||
            questionData?.data?.topic === 'fractions';

        if (isFractionsTopic) {
            const number1 =
                questionData?.number1 || questionData?.data?.number1;

            const number2 =
                questionData?.number2 || questionData?.data?.number2;

            // TODO create separate function for fraction html generation
            return `
                <div style='width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 5px'>
                    ${getFractionLayout(number1, false, false, calledIn)}
                    ×
                    ${getFractionLayout(number2, false, false, calledIn)}
                </div>
                `;
        }

        return super.formatQuestion(questionData, skill, calledIn);
    }

    static getAnswerInputType(numberGenerator) {
        const { topic } = numberGenerator || {};

        const isMixedAnswerInput = topic === 'fractions';

        return isMixedAnswerInput
            ? ANSWER_INPUT_TYPE.MIXED
            : ANSWER_INPUT_TYPE.REGULAR;
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.topic === 'fractions') {
            return isCorrectFractionAnswer(answer, question.answer);
        }

        return super.isAnswerCorrect(question, answer, numberGenerator);
    }

    static answerData(question, playerAnswer, skill) {
        if (skill.numberGenerator.topic === 'fractions') {
            const { answer, number1, number2 } = question;

            const {
                type,
                numberGenerator: { topic, subtopic },
            } = skill;

            return {
                number1,
                number2,
                answer,
                topic,
                subtopic,
            };
        }

        return super.answerData(question, playerAnswer, skill);
    }

    static generatePlayerAnswerHtml(playerAnswer, numberGenerator, question) {
        const isWrongAnswer = !question?.correct;

        if (numberGenerator.topic === 'fractions') {
            return getFractionLayout(playerAnswer, isWrongAnswer, true);
        }

        return super.generatePlayerAnswerHtml(
            playerAnswer,
            numberGenerator,
            question,
        );
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        const topic = question?.topic || numberGenerator?.topic;

        if (topic === 'fractions') {
            const correctAnswer = question.answer || question.data.answer;

            return getFractionLayout(correctAnswer, false, true, calledIn);
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static displayCorrectAnswer(question, numberGenerator, calledIn) {
        const topic = question?.topic || numberGenerator?.topic;

        if (topic === 'fractions') {
            const correctAnswer = question.data.answer;

            return getFractionLayout(correctAnswer, false, true, calledIn);
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static showArrowButtons(numberGenerator) {
        return numberGenerator.topic === 'fractions';
    }

    static resetPlayerAnswer(numberGenerator) {
        const isFractionsTopic = numberGenerator.topic === 'fractions';

        if (isFractionsTopic) {
            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        return '';
    }
}
