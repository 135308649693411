<template>
    <p>
        <svg
            id="esHrlopcQvX1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 90 90"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
        >
            <path
                id="esHrlopcQvX2"
                d="M12.7,31.1v0l-1.6,7.7v0c-.2.9.4,1.9,1.3,2.1v0l7.7,1.6c0,0,0,0,0,0c.9.2,1.9-.4,2.1-1.3v0l1.6-7.7v0c.2-.9-.4-1.9-1.3-2.1v0l-7.7-1.6c0,0,0,0,0,0-.9-.3-1.9.3-2.1,1.3Z"
                transform="matrix(.5 0 0 0.5 30.4084 40.932888)"
                opacity="0"
                fill="#fbd560"
                stroke="#000"
                stroke-width="2"
            />
            <path
                id="esHrlopcQvX3"
                d="M10.6,22.8c2.6-1.8,3.2-5.3,1.4-7.9s-5.3-3.2-7.9-1.4l.6.8-.6-.8c-2.6,1.8-3.2,5.3-1.4,7.9c1.8,2.5,5.3,3.2,7.9,1.4Z"
                transform="matrix(.5 0 0 0.5 35.3584 29.935818)"
                opacity="0"
                fill="#ff444a"
                stroke="#000"
                stroke-width="2"
            />
            <path
                id="esHrlopcQvX4"
                d="M24.1,14.7v0c.4.9,1.5,1.2,2.3.8v0l7-3.5v0c.9-.4,1.2-1.5.8-2.3l-3.5-7v0c-.4-.9-1.5-1.2-2.3-.8c0,0,0,0,0,0l-7,3.5v0c-.9.4-1.2,1.5-.8,2.3c0,0,0,0,0,0l3.5,7Z"
                transform="matrix(.5 0 0 0.5 35.383401 27.217627)"
                opacity="0"
                fill="#51e3f0"
                stroke="#000"
                stroke-width="2"
            />
            <path
                id="esHrlopcQvX5"
                d="M37.7,21c1.6-.9,2.3-.2,3.1,1s3.9,6,3.9,6v0c.5.8.3,1.9-.6,2.4v0l-6.6,4.1c0,0,0,0,0,0-.8.5-1.9.3-2.4-.6v0L31,27.3v0c-.5-.8-.3-1.9.6-2.4-.1,0,4.5-3,6.1-3.9Z"
                transform="matrix(.5 0 0 0.5 40.6084 36.707441)"
                opacity="0"
                fill="#ff444a"
                stroke="#000"
                stroke-width="2"
            />
        </svg>
    </p>
</template>
<script>
export default {
    name: 'Confetti',
    // https://www.svgator.com/help/getting-started/add-animated-svg-to-vue-js
    mounted() {
        (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
            w[o] = w[o] || {};
            w[o][s] = w[o][s] || [];
            w[o][s].push(i);
            e = d.createElementNS(n, t);
            e.async = true;
            e.setAttributeNS(
                x,
                'href',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            e.setAttributeNS(
                null,
                'src',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            p = d.getElementsByTagName(t)[0];
            p.parentNode.insertBefore(e, p);
        })(
            '5c7f360c',
            {
                root: 'esHrlopcQvX1',
                version: '2022-05-04',
                animations: [
                    {
                        elements: {
                            esHrlopcQvX2: {
                                transform: {
                                    data: { t: { x: -17.45, y: -36.130521 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 300,
                                                v: {
                                                    x: 39.1334,
                                                    y: 58.998148,
                                                    type: 'cusp',
                                                    end: {
                                                        x: 27.179021,
                                                        y: 62.977299,
                                                    },
                                                },
                                            },
                                            {
                                                t: 2300,
                                                v: {
                                                    x: 24.960311,
                                                    y: 75.560251,
                                                    type: 'cusp',
                                                    start: {
                                                        x: 24.960312,
                                                        y: 68.595046,
                                                    },
                                                },
                                            },
                                        ],
                                        r: [
                                            { t: 300, v: 0 },
                                            { t: 2300, v: 80 },
                                        ],
                                        s: [
                                            { t: 300, v: { x: 0.5, y: 0.5 } },
                                            { t: 700, v: { x: 2, y: 2 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 200, v: 0 },
                                    { t: 400, v: 1 },
                                    { t: 2300, v: 1 },
                                    { t: 2600, v: 0 },
                                ],
                            },
                            esHrlopcQvX3: {
                                transform: {
                                    data: { t: { x: -7.35, y: -18.136381 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 500,
                                                v: {
                                                    x: 39.0334,
                                                    y: 39.004008,
                                                    type: 'cusp',
                                                    end: {
                                                        x: 32.848384,
                                                        y: 27.20892,
                                                    },
                                                },
                                            },
                                            {
                                                t: 2100,
                                                v: {
                                                    x: 14.194772,
                                                    y: 40.435768,
                                                    type: 'cusp',
                                                    start: {
                                                        x: 20.566796,
                                                        y: 24.894837,
                                                    },
                                                },
                                            },
                                        ],
                                        s: [
                                            { t: 500, v: { x: 0.5, y: 0.5 } },
                                            { t: 900, v: { x: 2, y: 2 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 400, v: 0 },
                                    { t: 600, v: 1 },
                                    { t: 2100, v: 1 },
                                    { t: 2400, v: 0 },
                                ],
                            },
                            esHrlopcQvX4: {
                                transform: {
                                    data: {
                                        t: { x: -27.400002, y: -8.699999 },
                                    },
                                    keys: {
                                        o: [
                                            {
                                                t: 900,
                                                v: {
                                                    x: 49.083402,
                                                    y: 31.567626,
                                                    type: 'cusp',
                                                    end: {
                                                        x: 54.124728,
                                                        y: 20.598003,
                                                    },
                                                },
                                            },
                                            {
                                                t: 2700,
                                                v: {
                                                    x: 73.533474,
                                                    y: 32.317145,
                                                    type: 'cusp',
                                                    start: {
                                                        x: 69.501611,
                                                        y: 21.632678,
                                                    },
                                                },
                                            },
                                        ],
                                        r: [
                                            { t: 900, v: 0 },
                                            { t: 2700, v: 140 },
                                        ],
                                        s: [
                                            { t: 900, v: { x: 0.5, y: 0.5 } },
                                            { t: 2700, v: { x: 2, y: 2 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 800, v: 0 },
                                    { t: 1000, v: 1 },
                                    { t: 2700, v: 1 },
                                    { t: 3000, v: 0 },
                                ],
                            },
                            esHrlopcQvX5: {
                                transform: {
                                    data: { t: { x: -37.85, y: -27.679627 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 100,
                                                v: {
                                                    x: 59.5334,
                                                    y: 50.547254,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 2400,
                                                v: {
                                                    x: 67.498738,
                                                    y: 73.472115,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                        r: [
                                            { t: 100, v: 0 },
                                            { t: 2400, v: 200 },
                                        ],
                                        s: [
                                            { t: 100, v: { x: 0.5, y: 0.5 } },
                                            { t: 2400, v: { x: 2, y: 2 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 0, v: 0 },
                                    { t: 200, v: 1 },
                                    { t: 2400, v: 1 },
                                    { t: 2700, v: 0 },
                                ],
                            },
                        },
                        s: 'MBDA1M2IxNThEOWFhYmEE4OTdhYTlmTGE1YTRGFNThXNzA2OTY2NjY2GNjYyNTg5YTlmYTg5YEjk5YWFXOWZhNWE0NTMg3MDY3NjI1ODlmYWFHPRTliYTg5N2FhOWZhMNWE0YTk1OEE3MDY3NVjI1ODljOWZOYTJhMjNU4NzA2NzYyNTg5N2EDyYWE5YmE4YTQ5N2FhHOWJENTg3MDljOTdPYKTJhOTliNjI1OGE5T2BE2OWJDOWI5YTU4NzAL2N2Iz',
                    },
                ],
                options:
                    'MADAxMDg4MmZBODA4MTOZlN2Y4MTJmNDcyZjcJ5N2M2ZUM3MTJmOGE/Y',
            },
            'https://cdn.svgator.com/ply/',
            '__SVGATOR_PLAYER__',
            '2022-05-04',
            window,
            document,
            'script',
            'http://www.w3.org/2000/svg',
            'http://www.w3.org/1999/xlink',
        );
    },
};
</script>
