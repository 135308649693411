import { getStaticTopicClass } from '@/core/math-topics/TopicsFactory';
// import TopicsFactory from '@/core/math-topics/TopicsFactory';
import { isEqual } from 'lodash';
import { i18n } from '@/lang/translator';
import { getLocale, jclone } from '@/core/helpers/utils';

export default class MixMultipleTopic {
    constructor(skill) {
        this._skill = skill;

        this._topicsArray = skill?.skillsList?.map((skill) => {
            const TopicClass = getStaticTopicClass(skill.type);

            return new TopicClass(skill);
        });
    }

    static code = 'TYPE_MULTI_TOPICS';

    static secondCode = 'MULTI_TOPICS_TYPE';

    static icon = '';

    topicName() {
        return 'Mix multiple';
    }

    static getGameTopicTypeName() {
        return 'MIXED';
    }

    static getNumberGeneratorName() {
        return 'Mix multiple';
    }

    getIcon() {
        return '';
    }

    skill(next) {
        if (typeof next !== 'undefined') {
            this._skill = next;
        }

        return this._skill;
    }

    isType(type) {
        return this._skill.type === type;
    }

    isSkill(skill) {
        return (
            this.isType(skill.type) &&
            isEqual(skill.skillsList, this._skill.skillsList)
        );
    }

    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    generateQuestions(amount, noRepeats = false) {
        const list = [];

        if (!this._skill) {
            return list;
        }

        while (list.length < amount) {
            const randomClassIndex = this.randomIntFromInterval(
                0,
                this._topicsArray.length - 1,
            );

            const RandomClass = this._topicsArray[randomClassIndex];

            const question = RandomClass.constructor.generateQuestion(
                RandomClass._skill.numberGenerator,
            );

            const questionData = {
                ...question,
                skillIndex: randomClassIndex,
                // skill: {
                //     name: RandomClass._skill.name,
                //     numberGenerator: RandomClass._skill.numberGenerator,
                //     type: RandomClass._skill.type,
                // },
            };

            if (noRepeats) {
                // all questions should be different
                if (!list.some((q) => isEqual(q, question))) {
                    list.push(questionData);
                }
            } else {
                // question can not be same as previous generated only
                // but can be repeated
                if (!isEqual(question, list[list.length - 1])) {
                    list.push(questionData);
                }
            }
        }

        return list;
    }

    numberGeneratorName() {
        return 'Mix multiple';
    }

    findTopicForQuestion(question) {
        const skillIndex = question.skillIndex;

        if (typeof skillIndex === 'number') {
            return this._topicsArray[skillIndex];
        } else {
            return this._topicsArray.find(
                (item) => item?._skill?.type === question?.skill?.type,
            );
        }
    }

    formatQuestionO(question, calledIn = 'inGame') {
        const math = this.findTopicForQuestion(question);

        return math.formatQuestionO(question, calledIn);
    }

    formatQuestionChecked(question, calledIn = 'inGame') {
        const math = this.findTopicForQuestion(question);

        return this.formatQuestionWithCheck(question, math._skill, calledIn);
    }

    boxedQuestionFormat(question) {
        const math = this.findTopicForQuestion(question);

        return (
            math.constructor.formatBoxedQuestion(question, math._skill) || null
        );
    }

    displayAnswer(questionBody) {
        const math = this.findTopicForQuestion(questionBody);

        const playerAnswer =
            questionBody.playerAnswer ??
            questionBody.answer ??
            questionBody.data.playerAnswer ??
            questionBody.data.answer;

        return math.constructor.generatePlayerAnswerHtml(
            playerAnswer,
            math._skill.numberGenerator,
            questionBody,
        );
    }

    displayCorrectAnswer(questionBody, calledIn = 'inGame') {
        const math = this.findTopicForQuestion(questionBody);

        return math.constructor
            .generateCorrectAnswerHtml(questionBody, math._skill, calledIn)
            .trim();
    }

    checkAnswer(question, answer) {
        const math = this.findTopicForQuestion(question);

        const currentAnswer =
            typeof answer === 'string' ? answer.replace(/,/g, '.') : answer;

        return math.constructor.isAnswerCorrect(
            question,
            currentAnswer,
            math._skill.numberGenerator,
        );
    }

    answerInputType(question) {
        const math = this.findTopicForQuestion(question);

        return math.constructor.getAnswerInputType(math._skill.numberGenerator);
    }

    keyboardInputType(question) {
        const math = this.findTopicForQuestion(question);

        return math.constructor.getKeyboardType(math._skill.numberGenerator);
    }

    keyboardWithArrows(question) {
        const math = this.findTopicForQuestion(question);

        return math.constructor.showArrowButtons(math._skill.numberGenerator);
    }

    static keyboardAnswerInput(
        answer,
        input,
        numberGenerator = undefined,
        activeInput = undefined,
    ) {
        let src = answer;
        let obj = false;

        if (
            typeof answer === 'object' &&
            activeInput &&
            Object.keys(answer || {}).includes(activeInput)
        ) {
            src = answer[activeInput];

            obj = true;
        }
        if (input === 'delete') {
            if (!src || src === '' || !src.length) {
                src = '';
            } else {
                src = src.slice(0, -1);
            }
        } else if (input === 'submit') {
            // Do nothing.
        } else {
            src = `${src || ''}${input}`;
        }

        if (!obj) {
            return src;
        }

        return { ...answer, [activeInput]: src };
    }

    onKeyboard(action, answer, activeInput, question) {
        const math = this.findTopicForQuestion(question);

        return this.constructor.keyboardAnswerInput(
            answer,
            action,
            math._skill.numberGenerator,
            activeInput,
        );
    }

    resetAnswer(question = null) {
        let math = this.findTopicForQuestion(question);

        return math.constructor.resetPlayerAnswer(
            math._skill.numberGenerator,
            question,
        );
    }

    static questionViewClasses(skill) {
        const list = [];

        list.push('c-questions-wrap__question');

        list.push(`c-questions-wrap__question--${skill.type}`);

        if (skill.numberGenerator.topic) {
            list.push(
                `c-questions-wrap__question--${skill.numberGenerator?.topic}`,
            );
        }

        if (skill.numberGenerator.subtopic) {
            list.push(
                `c-questions-wrap__question--${skill.numberGenerator?.subtopic}`,
            );
        }

        return list;
    }

    viewClasses(question) {
        const math = this.findTopicForQuestion(question);

        return this.constructor.questionViewClasses(math._skill);
    }

    $t(...args) {
        return i18n.tc(...args);
    }

    static checkIfQuestionIsBroken(
        formattedQuestion,
        attemptCounter = 1,
        originQuestion,
        skill,
    ) {
        const brokenIndicationStrings = ['undefined', 'null', 'NaN'];

        if (
            brokenIndicationStrings.some((faultyString) =>
                formattedQuestion.includes(faultyString),
            )
        ) {
            if (attemptCounter === 1) {
                // No need to spam an extra crash report for the same crash,
                // as it's still broken, so only sending for 1st attempt.
                // this.crs.startSession(CrashReportTypes.BROKEN_QUESTION);

                console.log('Broken question', formattedQuestion);
                console.log('skill: ', skill);
                console.log('question data: ', originQuestion);
                console.trace('stack trace');

                // const gameType = skill.type;
                // const numberGenerator = skill.numberGenerator;
                // console.log('Current route: ', this.$route.name);

                // this.crs.sendCrashReport(CrashReportTypes.BROKEN_QUESTION);
                // this.crs.endSession(CrashReportTypes.BROKEN_QUESTION);
            }

            return true;
        }

        return false;
    }

    formatQuestionWithCheck(question, skill, calledIn = 'inGame') {
        if (!question) {
            return '';
        }
        // to be sure that question will not mutate during rendering
        const clonedQuestion = jclone(question);

        const math = this.findTopicForQuestion(clonedQuestion);

        let formattedQuestion = math.constructor.formatQuestion(
            clonedQuestion,
            skill,
            calledIn,
        );

        if (
            this.constructor.checkIfQuestionIsBroken(
                formattedQuestion,
                1,
                question,
                skill,
            )
        ) {
            // Try once more, maybe it's a fluke.
            // I did not do recursion, as most likely if it's broken once,
            // it'll be broken still.
            formattedQuestion = math.constructor.formatQuestion(
                clonedQuestion,
                skill,
                calledIn,
            );

            if (
                this.constructor.checkIfQuestionIsBroken(
                    formattedQuestion,
                    2,
                    question,
                    skill,
                )
            ) {
                console.error(
                    'Format Question Broken!',
                    question,
                    skill,
                    formattedQuestion,
                );
            }
        }

        return formattedQuestion;
    }

    static locale() {
        return getLocale();
    }

    static formatNumber(num, locale = null) {
        if (!num && num !== 0) {
            return;
        }

        if (!locale) locale = this.locale();

        const decimals = num.toString().split('.')[1];

        const decimalPlaces = (decimals && decimals.length) || 0;

        return Number(num)
            .toLocaleString(locale, {
                minimumFractionDigits: decimalPlaces,
            })
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
