import moment from 'moment/moment';
import { router } from '@/main';

const defaultTrialMinutes = 7 * 24 * 60;

export default {
    namespaced: true,
    state: {
        trialMinutes: defaultTrialMinutes,
        premiumModalMode: null,
        showWelcomePremiumModal: false,
        showPremiumTrialEndModal: false,
        showEmailSentModal: false,
        hasSchoolPremiumTrial: false,
        premiumTrialTimeLeft: null,
    },
    getters: {
        premiumModalMode: (state) => {
            return state.premiumModalMode;
        },
        showWelcomePremiumModal: (state) => {
            return state.showWelcomePremiumModal;
        },
        showPremiumTrialEndModal: (state) => {
            return state.showPremiumTrialEndModal;
        },
        showEmailSentModal: (state) => {
            return state.showEmailSentModal;
        },
        trialMinutes: (state) => {
            return state.trialMinutes;
        },
        hasSchoolPremiumTrial: (state) => {
            return state.hasSchoolPremiumTrial;
        },
        premiumTrialTimeLeft: (state) => {
            return state.premiumTrialTimeLeft;
        },
    },
    mutations: {
        setPremiumModalMode: (state, next) => {
            state.premiumModalMode = next;
        },
        setShowWelcomePremiumModal: (state, next) => {
            state.showWelcomePremiumModal = next;
        },
        setShowPremiumTrialEndModal: (state, next) => {
            state.showPremiumTrialEndModal = next;
        },
        setShowEmailSentModal: (state, next) => {
            state.showEmailSentModal = next;
        },
        setTrialMinutes: (state, next) => {
            state.trialMinutes = next;
        },
        setHasSchoolPremiumTrial: (state, next) => {
            state.hasSchoolPremiumTrial = next;
        },
        setPremiumTrialTimeLeft: (state, next) => {
            state.premiumTrialTimeLeft = next;
        },
    },
    actions: {
        reset: ({ commit }) => {
            commit('setPremiumModalMode', null);
            commit('setShowWelcomePremiumModal', false);
            commit('setShowPremiumTrialEndModal', false);
            commit('setShowEmailSentModal', false);
            commit('setTrialMinutes', defaultTrialMinutes);
            commit('setHasSchoolPremiumTrial', false);
            commit('setPremiumTrialTimeLeft', null);
        },
        setPremiumModalMode: ({ commit }, next) => {
            commit('setPremiumModalMode', next);
        },
        setShowWelcomePremiumModal: ({ commit }, next) => {
            commit('setShowWelcomePremiumModal', next);
        },
        setShowPremiumTrialEndModal: ({ commit }, next) => {
            commit('setShowPremiumTrialEndModal', next);
        },
        setShowEmailSentModal: ({ commit }, next) => {
            commit('setShowEmailSentModal', next);
        },
        setTrialMinutes: ({ commit }, next) => {
            commit('setTrialMinutes', next);
        },
        setHasSchoolPremiumTrial: ({ commit }, next) => {
            commit('setHasSchoolPremiumTrial', next);
        },
        calcPremiumTrialTimeLeft: ({ commit, getters, rootGetters }) => {
            const user = rootGetters.user;

            if (user) {
                const trialEndTime = moment(user.premium.activatedAt).add(
                    getters.trialMinutes,
                    'minutes',
                );

                const diff = trialEndTime.diff(moment());

                const duration = moment.duration(diff);

                let result;

                const daysLeft = Math.round(duration.asDays());

                const hours = Math.round(duration.asHours());

                const minutes = Math.round(duration.asMinutes());

                const seconds = Math.round(duration.asSeconds());

                if (daysLeft > 0) {
                    result = `${daysLeft} days left`;
                } else if (hours > 0) {
                    result = `${hours} hours left`;
                } else if (minutes > 0) {
                    result = `${minutes} minutes left`;
                } else if (seconds > 0) {
                    if (seconds === 1) {
                        result = '1 second left';
                    } else {
                        result = `${seconds} seconds left`;
                    }
                } else {
                    result = 'Trial expired';
                }

                commit('setPremiumTrialTimeLeft', result);
            } else {
                commit('setPremiumTrialTimeLeft', null);
            }
        },
        goToPremiumPage: (store) => {
            store.dispatch('setPremiumModalMode', null);

            store.dispatch('setShowWelcomePremiumModal', null);

            store.dispatch('setShowPremiumTrialEndModal', null);

            router.push({ name: 'premium' });
        },
    },
};
