/* global mixpanel */
export const PROFILE_MP_EVENTS = {
    VIEWS_MY_PROGRESS: 'viewsMyProgress',
    EXPANDS_MY_PROGRESS: 'expandsMyProgressView',
    VIEWS_MY_PROFILE: 'viewsMyProfile',
    VIEWS_ANOTHER_PROFILE: 'viewsAnotherProfile',
    SET_FIRST_GAME_SURVEY_SCORE: 'setFirstGameSurveyScore',
    SET_FIRST_GAME_SURVEY_COMMENTS: 'setFirstGameSurveyComments',
    ROLE_CHANGED_ON_PROFILE: 'changeRoleOnProfile',
};

export const PROFILE_MP_METHODS = {
    viewsMyProgress() {
        mixpanel.track('Views my progress view');
    },
    expandsMyProgressView() {
        mixpanel.track('Expands my progress view');
    },
    viewsMyProfile() {
        mixpanel.track('Views my profile');
    },
    viewsAnotherProfile() {
        mixpanel.track('Views another player profile');
    },
    setFirstGameSurveyScore(score = 0) {
        if (score) {
            mixpanel.track('Set first game survey score', {
                'First game Survey Score': score,
            });
            mixpanel.people.set({
                'First game Survey Score': score,
            });
        }
    },
    setFirstGameSurveyComments(comments = '') {
        if (comments) {
            mixpanel.track('Set first game survey comments', {
                'First game Survey Comments': comments,
            });
            mixpanel.people.set({
                'First game Survey Comments': comments,
            });
        }
    },
    changeRoleOnProfile({ fromRole = '', toRole = '' }) {
        const fromRoleToRole = `${fromRole} -> ${toRole}`;
        mixpanel.track('Role changed on profile', {
            'Role change': fromRoleToRole,
        });
    },
};
