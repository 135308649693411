/* global mixpanel */
export const HOME_GAME_MP_EVENTS = {
    CHOSE_SINGLE_PLAYER: 'choseSinglePlayer',
    CHOSE_MAP_LEVEL: 'choseMapLevel',
    STARTED_SPG: 'startedSpg',
    SIGN_UP_HOME_GAME: 'signUpHomeGame',
    ENERGY_UPDATED: 'energyUpdated',
    VIEW_BUY_ENERGY_SCREEN: 'buyEnergyScreen',
    VIEW_PREMIUM_PARENT_SCREEN: 'premiumParentScreen',
    VIEW_PREMIUM_CREDIT_CARD_SCREEN: 'premiumCreditCardScreen',
    PARENT_BUYS_PREMIUM: 'parentBuysPremium',
    LANDED_ON_LEVEL_MAP: 'landedOnLevelMap',
    VIEW_PRICE_PACKAGE_SCREEN: 'viewedPricePackageScreen',
    SET_USER_PRICE_TEST: 'setPriceTestForUser',
    SET_SKILLS_LEVEL_LOCK: 'setSkillsLevelLockForUser',
};

export const HOME_GAME_MP_METHODS = {
    choseSinglePlayer({ map }) {
        mixpanel.track('Chose "Single player"', { Map: map });
    },
    choseMapLevel({ map, levelNr }) {
        mixpanel.track('Chose level', { Map: map, 'Level nr': levelNr });
    },
    startedSpg({ energy }) {
        mixpanel.people.set({ '$Energy Left': energy });
    },
    signUpHomeGame() {
        mixpanel.people.set({ '$Premium Package': 'Free' });
    },
    energyUpdated({ energy }) {
        mixpanel.people.set('Energy updated', { '$Energy Left': energy });
    },
    buyEnergyScreen(location) {
        mixpanel.track('Buy energy screen', { Location: location });
    },
    premiumParentScreen() {
        mixpanel.track('Premium parent screen');
    },
    premiumCreditCardScreen() {
        mixpanel.track('Premium credit card screen');
    },
    parentBuysPremium(productName) {
        mixpanel.track('Parent buys Premium', {
            'Premium Package': productName,
        });
        mixpanel.people.set({
            '$Premium Package': productName,
        });
    },
    landedOnLevelMap() {
        mixpanel.track('Landed on level map');
    },
    viewedPricePackageScreen() {
        mixpanel.track('Viewed price package screen');
    },
    setPriceTestForUser({ priceTestVersion }) {
        mixpanel.people.set({
            'Price test version': priceTestVersion,
        });
    },
    setSkillsLevelLockForUser({ isLevelLocked }) {
        mixpanel.people.set({
            isLevelLockEnabled: isLevelLocked,
        });
    },
};
