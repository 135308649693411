import Api2 from '@/core/services/Api2';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import moment from 'moment';
import CONSTANTS from '@/core/helpers/constants';

import { toSession, fromSession, delSession } from '@/core/helpers/utils.cache';

import tbl from './game.tbl';
import spg from './game.spg';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { router } from '@/main';

/**
 *  game store contains "very common data" - game code, mode, state and joinMetaData
 *  all "game mode related" state, getters, mutations and actions are moved to separate
 *  store modules:
 *
 *    live (tbl) - @/store/modules/game.tbl.js
 *    spg - @/store/modules/game.spg.js
 *    home - @/store/modules/game.home.js
 *
 *  tbl and spg uses 'state' field of current module, and home games module is
 *  "more independent", not uses current module and injected into store on student login
 */
const store = {
    namespaced: true,
    modules: {
        tbl,
        spg,
    },
    state: {
        // state code for game state machine
        // used by "all" (tbl and spg) games
        // to store currect game state in single line
        // examples: 'tbl/host/round' or 'spg/player/play'

        // this is important variable, because
        // 'root game component' watchs for changes of this variable
        // to decide show/hide of some elements or routing

        // state combined by logig {MODE}/{+-ROLE}/{STATE} and can be
        // tested in different ways, like `state.endsWith('player/canJoin')`
        // to test that check game code was complete for any tbl or spg
        state: 'none',
        initialized: false,
        // flag to prevent cache saving if few calls asyncronusly overlap
        noCache: false,
        code: null,
        joinGameMetaData: null,
        // none, tbl, spg
        mode: 'none',
    },
    getters: {
        mode: (state) => state.mode,
        code: (state) => state.code,
        joinCode: (state) => state.code,
        info: (state, getters, rootState, rootGetters) => {
            return state.mode === 'tbl'
                ? state.tbl.info
                : rootGetters['v2/game/spg/game'];
        },
        clientType: (state, getters) => {
            return getters.mode === 'tbl' && getters['tbl/isHost']
                ? CONSTANTS.CLIENT_TYPES.HOST
                : CONSTANTS.CLIENT_TYPES.PLAYER;
        },
    },
    mutations: {
        state: (state, next) => {
            if (state.state === next || (!next && state.state === 'none')) {
                return;
            }

            console.debug(
                `Changing game state from "${state.state}" to "${next}"`,
            );

            state.state = next || 'none';
        },
        initialized: (state, next) => {
            if (state.initialized === next) return;

            state.initialized = next;
        },
        noCache: (state, next) => {
            if (state.noCache === next) return;

            state.noCache = next;
        },
        code: (state, next) => {
            if (state.code === next) return;

            // because join game code is numbers based - it
            // may be recognized automatically as number type
            // so manual convertion to string required
            if (typeof next === 'number') next = `${next}`;

            state.code = next;
        },
        mode: (state, next) => {
            if (state.mode === next) return;

            // console.log('store::game/setMode', next, state);

            state.mode = next;
        },
        joinGameMetaData: (state, next) => {
            state.joinGameMetaData = next;
        },
        hostWsTimeoutId: (state, payload) => {
            if (payload === null) {
                clearTimeout(state.hostWsTimeoutId);
            }

            state.hostWsTimeoutId = payload;
        },
    },
    actions: {
        init: async (store) => {
            await store.dispatch('tbl/init');

            await store.dispatch('spg/init');

            store.commit('initialized', true);

            console.debug('store::game initialized');
        },
        reset: async (store) => {
            console.debug('store::game reset');

            await Promise.all([
                store.commit('state', 'none'),
                store.commit('joinGameMetaData', null),
                store.dispatch('clearCache'),
                store.dispatch('tbl/reset'),
                store.dispatch('spg/reset'),
            ]);
        },
        saveCache: (store, force = false) => {
            if (store.state.noCache && !force) {
                // console.log('store::game.saveCache NOCACHE');

                return;
            }

            // console.log('store::game.saveCache');

            store.commit('noCache', true);

            toSession('game', store.state);

            store.dispatch('tbl/saveCache');

            store.dispatch('spg/saveCache');

            setTimeout(() => {
                store.commit('noCache', false);
            }, CONSTANTS.CACHE_SAVE_DELAY_IN_MS);
        },
        loadCache: (store) => {
            const cache = fromSession('game', null);

            // console.log('store::game loading cache', cache);

            store.dispatch('tbl/loadCache');

            store.dispatch('spg/loadCache');

            if (!cache) return;

            store.commit('noCache', true);

            store.commit('state', cache.state);

            store.commit('mode', cache.mode);

            store.commit('code', cache.code);

            store.commit('noCache', false);
        },
        clearCache: (store) => {
            delSession('game');

            store.dispatch('tbl/clearCache');

            store.dispatch('spg/clearCache');
        },
        state: (store, next) => {
            if (store.state === next) return;

            store.commit('state', next);

            store.dispatch('saveCache', true); // force save on state change
        },
        create: async (store, payload) => {
            // console.log('store::game::create', payload);

            if ([CONSTANTS.LIVE_GAME_MODE, 'live'].includes(payload.mode)) {
                return await store.dispatch('tbl/create', payload);
            }

            console.error(
                'store::game other games creation is not defined yet! how did you get here?',
            );

            return false;
        },
        // check game code join (GET /join/${code})
        checkCode: async (store, joinGameCode) => {
            const check = await Api2.get(
                `join/${joinGameCode}`,
                null,
                true,
            ).catch((error) => {
                console.info(error.message);
            });
            if (!check || check?.error || !check.canJoin) {
                console.log(
                    'store::game::checkCode failed',
                    joinGameCode,
                    check,
                );

                // store.commit('ioServer', null);

                store.commit('code', null);

                store.commit('mode', 'none');

                if (
                    check?.requireLogin ||
                    check?.error?.message === 'Not logged in user'
                ) {
                    store.commit('state', 'check/error/requireLogin');
                } else {
                    store.commit('state', 'check/error/gameCode');
                }

                return false;
            }

            if (!['tbl', 'spg', 'runner'].includes(check?.gameMode)) {
                return false;
            }

            // console.log('store::game::checkCode result', check);

            store.commit('code', check.gameCode);

            store.commit('mode', check.gameMode);

            store.commit('joinGameMetaData', check.metaData || null);

            store.commit('state', `${check.gameMode}/player/canJoin`);

            store.dispatch('saveCache');

            return true;
        },
        // join game (POST /join/${code})
        join: async (store, playerName) => {
            const joinCode = store.state.code || null;

            // console.log('store::game::join', { playerName, joinCode });

            if (
                !store.state.state.endsWith('player/canJoin') ||
                !playerName ||
                !joinCode
            ) {
                return false;
            }

            const response = await SecureApi().post(`join/${joinCode}`, {
                name: playerName,
                joined: moment().format(),
            });

            const { data, serverTime, error } = response.data;

            // console.log('store::game::join POST join/${joinCode}', response);

            if (!data || error) {
                if (error?.message === 'GameIsFull') {
                    store.commit('state', 'join/error/gameIsFull');
                } else {
                    store.commit('state', 'join/error/nameError');
                }

                if (error) {
                    console.log('store::game::join response error', data);

                    await store.dispatch(
                        'v2/ui/alert',
                        `Failed: ${
                            error === 'KICKED'
                                ? 'Host removed you from game'
                                : error
                        }`,
                        {
                            root: true,
                        },
                    );

                    store.dispatch('leave');
                }

                return false;
            }

            // mixpanel event for any game types
            const user = store.rootGetters.user;

            const events = {
                tbl: EVENTS.LIVE_GAME_JOINED_GAME,
                spg: EVENTS.SPG_JOINED_GAME,
                runner: EVENTS.JOINED_MATH_RUNNER,
            };

            if (user && events[store.state.mode]) {
                const gameCreatorRole =
                    data?.info?.creatorRole || data?.creatorRole;

                new TrackingService(user).track(events[store.state.mode], {
                    gameCode: store.state.code,
                    gameCreatorRole,
                });
            }

            if (data.playerName) {
                store.commit('v2/user/playerName', data.playerName, {
                    root: true,
                });
            }

            // TBL game
            if (data.gameMode === CONSTANTS.LIVE_GAME_MODE && data.info) {
                console.debug('store::game::join to TBL', data);

                await store.commit('state', `${data.gameMode}/player/join`);

                await store.dispatch('tbl/join', data);

                return true;
            }

            // SPG game/playlist
            if (
                data.gameMode === CONSTANTS.SELF_PACED_GAME_MODE ||
                data.gameMode === CONSTANTS.SELF_PACED_PLAYLIST_MODE
            ) {
                console.log('store::game::join to SPG(-P?)', data);

                store.commit('v2/user/playerName', data.playerName, {
                    root: true,
                });

                await store.dispatch('spg/join', data);

                await store.commit('state', `${data.gameMode}/player/join`);

                await store.dispatch('saveCache', true);

                return true;
            }

            // Math Runner game
            if (data.gameMode === CONSTANTS.MATH_RUNNER_GAME_MODE) {
                console.log('store::game::join to MathRunner', data);

                await store.dispatch(
                    'v2/mathRunner/setTimeCorrection',
                    serverTime,
                    { root: true },
                );

                await store.commit('state', `${data.gameMode}/player/join`);

                return true;
            }

            console.log('store::game::join UNKNOWN GAME MODE!!!', data);

            return false;
        },
        leave: async (store) => {
            console.debug('store::game::leave');

            if (store.state.mode === CONSTANTS.LIVE_GAME_MODE) {
                await store.dispatch('tbl/leave');
            }
        },
        incrementGameDaysCounter: async (store) => {
            // console.log('store:game.incrementGameDaysCounter');

            const response = await Api2.post('user/update-game-days-counter');

            return response || {};
        },
        goToEnterCode: async (store) => {
            store.dispatch('v2/ui/playClickSecondSound');

            await store.commit('setJoinGameCode', null);

            return router.push({ name: 'join' });
        },
    },
};

export default store;
