import {
    create,
    createUnitDependencies,
    evaluateDependencies,
    largerDependencies,
    roundDependencies,
    unitDependencies,
} from 'mathjs';
import {
    randomIntFromRange,
    randomArrayElement,
    round,
    hasOwnProp,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class ConversionTopic extends TopicsBaseClass {
    static code = 'TYPE_CONVERSION';

    static icon = '&#8594;';

    static gameTypeNameTranslationKey = 'game.gameTypeTitle.conversion';

    static generateQuestion(numberGenerator) {
        const {
            topic,
            taskType,
            subtopic,
            biggestConvertedUnitNumber,
            conversions,
        } = numberGenerator;

        if (!conversions || !conversions.length) {
            return;
        }

        const math = create(
            {
                unitDependencies,
                evaluateDependencies,
                roundDependencies,
                largerDependencies,
                createUnitDependencies,
            },
            {},
        );

        math.createUnit('ha', '1 hectare');

        math.createUnit('a', '100 m2');

        math.createUnit('ts', '100 kg');

        math.createUnit('c', '0.5 pt'); // 2 cups = 1 pint.

        const conversion = randomArrayElement(conversions);

        if (['addition', 'subtraction'].includes(taskType)) {
            const { unit1, unit2 } = conversion;

            const generateABiggerSmallNumber = randomIntFromRange(0, 1);

            const biggerUnit = {
                unit: null,
                nr1: null,
                nr2: null,
            };

            const smallerUnit = {
                unit: null,
                nr1: null,
                nr2: null,
            };

            const answer = {
                biggerUnit: null,
                smallerUnit: null,
            };

            if (taskType === 'addition') {
                /**
                 * Generic formula to generate the unit values.
                 *
                 * @param biggerNumber
                 * @param smallerNumber
                 * @param generateABiggerNumberScale
                 */
                const generateUnitValues = (
                    biggerNumber,
                    smallerNumber,
                    generateABiggerNumberScale = false,
                ) => {
                    biggerUnit.nr1 = randomIntFromRange(
                        biggerNumber.nr1.min,
                        biggerNumber.nr1.max,
                    );

                    smallerUnit.nr1 = randomIntFromRange(
                        smallerNumber.nr1.min,
                        smallerNumber.nr1.max,
                    );

                    // Both first numbers can't be 0.
                    if (smallerUnit.nr1 === 0 && biggerUnit.nr1 === 0) {
                        biggerUnit.nr1 = randomIntFromRange(
                            biggerNumber.nr1.min + 1,
                            biggerNumber.nr1.max,
                        );
                    }

                    if (generateABiggerNumberScale) {
                        smallerUnit.nr1 =
                            smallerUnit.nr1 * generateABiggerNumberScale;

                        biggerUnit.nr2 = randomIntFromRange(
                            biggerNumber.nr2.min,
                            biggerNumber.nr2.max - biggerUnit.nr1,
                        );
                    } else {
                        biggerUnit.nr2 = randomIntFromRange(
                            biggerNumber.nr2.min,
                            biggerNumber.nr2.max,
                        );
                    }

                    if (biggerUnit.nr2 === 0) {
                        biggerUnit.nr2 += randomIntFromRange(1, 10);
                    }

                    smallerUnit.nr2 = randomIntFromRange(
                        smallerNumber.nr2.min,
                        smallerNumber.nr2.max,
                    );
                };

                /**
                 * Generates the answer once units are generated.
                 * @param limit
                 */
                const generateAnswer = (limit) => {
                    answer.biggerUnit =
                        smallerUnit.nr1 + smallerUnit.nr2 >= limit
                            ? biggerUnit.nr1 + biggerUnit.nr2 + 1
                            : biggerUnit.nr1 + biggerUnit.nr2;

                    answer.smallerUnit =
                        smallerUnit.nr1 + smallerUnit.nr2 >= limit
                            ? smallerUnit.nr1 + smallerUnit.nr2 - limit
                            : smallerUnit.nr1 + smallerUnit.nr2;
                };

                if (subtopic === 'convertingLength') {
                    if (unit1 === 'km' && unit2 === 'm') {
                        biggerUnit.unit = 'km';

                        smallerUnit.unit = 'm';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 999,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 10 : 100;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(1000);
                    } else if (unit1 === 'm' && unit2 === 'cm') {
                        biggerUnit.unit = 'm';

                        smallerUnit.unit = 'cm';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 99,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 10 : 1;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(100);
                    } else if (
                        (unit1 === 'm' && unit2 === 'dm') ||
                        (unit1 === 'dm' && unit2 === 'cm') ||
                        (unit1 === 'cm' && unit2 === 'mm')
                    ) {
                        if (unit1 === 'dm' && unit2 === 'cm') {
                            biggerUnit.unit = 'dm';

                            smallerUnit.unit = 'cm';
                        } else if (unit1 === 'cm' && unit2 === 'mm') {
                            biggerUnit.unit = 'cm';

                            smallerUnit.unit = 'mm';
                        } else {
                            biggerUnit.unit = 'm';

                            smallerUnit.unit = 'dm';
                        }

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: 20,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(10);
                    } else if (unit1 === 'ft' && unit2 === 'in') {
                        biggerUnit.unit = 'ft';

                        smallerUnit.unit = 'in';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 11,
                            },
                            nr2: {
                                min: 0,
                                max: 11,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(12);
                    } else if (unit1 === 'yd' && unit2 === 'ft') {
                        biggerUnit.unit = 'yd';

                        smallerUnit.unit = 'ft';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 2,
                            },
                            nr2: {
                                min: 0,
                                max: 2,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(3);
                    }
                } else if (subtopic === 'convertingWeight') {
                    if (
                        (unit1 === 't' && unit2 === 'kg') ||
                        (unit1 === 'kg' && unit2 === 'g')
                    ) {
                        if (unit1 === 'kg' && unit2 === 'g') {
                            biggerUnit.unit = 'kg';
                            smallerUnit.unit = 'g';
                        } else {
                            biggerUnit.unit = 't';
                            smallerUnit.unit = 'kg';
                        }

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 999,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 10 : 100;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(1000);
                    } else if (unit1 === 'ts' && unit2 === 'kg') {
                        biggerUnit.unit = 'ts';

                        smallerUnit.unit = 'kg';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 99,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 1 : 10;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(100);
                    } else if (unit1 === 't' && unit2 === 'ts') {
                        biggerUnit.unit = 't';

                        smallerUnit.unit = 'ts';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: 20,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 9,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(10);
                    } else if (unit1 === 'lb' && unit2 === 'oz') {
                        biggerUnit.unit = 'lb';

                        smallerUnit.unit = 'oz';

                        const biggerNumber = {
                            nr1: {
                                min: 0,
                                max: 10,
                            },
                            nr2: {
                                min: 0,
                                max: 10,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 15,
                            },
                            nr2: {
                                min: 0,
                                max: 15,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(16);
                    }
                }
            } else if (taskType === 'subtraction') {
                /**
                 * Generic formula to generate the unit values.
                 *
                 * @param biggerNumber
                 * @param smallerNumber
                 * @param generateABiggerNumberScale
                 */
                const generateUnitValues = (
                    biggerNumber,
                    smallerNumber,
                    generateABiggerNumberScale = false,
                ) => {
                    biggerUnit.nr1 = randomIntFromRange(
                        biggerNumber.nr1.min,
                        biggerNumber.nr1.max,
                    );

                    smallerUnit.nr1 = randomIntFromRange(
                        smallerNumber.nr1.min,
                        smallerNumber.nr1.max,
                    );

                    // Both first numbers can't be 0.
                    if (smallerUnit.nr1 === 0 && biggerUnit.nr1 === 0) {
                        biggerUnit.nr1 = randomIntFromRange(
                            biggerNumber.nr1.min + 1,
                            biggerNumber.nr1.max,
                        );
                    }

                    biggerUnit.nr2 = randomIntFromRange(
                        biggerNumber.nr2.min,
                        biggerUnit.nr1 - 1,
                    );

                    if (biggerUnit.nr2 === 0) {
                        smallerUnit.nr2 = randomIntFromRange(
                            1,
                            smallerNumber.nr2.max,
                        );
                    } else {
                        smallerUnit.nr2 = randomIntFromRange(
                            smallerNumber.nr2.min,
                            smallerNumber.nr2.max,
                        );
                    }

                    if (generateABiggerNumberScale) {
                        smallerUnit.nr2 =
                            smallerUnit.nr2 * generateABiggerNumberScale;
                    }
                };

                /**
                 * Generates the answer once units are generated.
                 * @param limit
                 */
                const generateAnswer = (limit) => {
                    answer.biggerUnit =
                        smallerUnit.nr1 - smallerUnit.nr2 < 0
                            ? biggerUnit.nr1 - biggerUnit.nr2 - 1
                            : biggerUnit.nr1 - biggerUnit.nr2;

                    answer.smallerUnit =
                        smallerUnit.nr1 - smallerUnit.nr2 < 0
                            ? smallerUnit.nr1 - smallerUnit.nr2 + limit
                            : smallerUnit.nr1 - smallerUnit.nr2;
                };

                if (subtopic === 'convertingLength') {
                    if (unit1 === 'km' && unit2 === 'm') {
                        biggerUnit.unit = 'km';

                        smallerUnit.unit = 'm';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 999,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 10 : 10;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        const useAlternativesForSmallerUnitNr2 =
                            randomIntFromRange(0, 2);

                        if (useAlternativesForSmallerUnitNr2 !== 1) {
                            if (useAlternativesForSmallerUnitNr2 === 2) {
                                smallerUnit.nr2 = smallerUnit.nr2 * 10;
                            } else {
                                smallerUnit.n2 = randomIntFromRange(1, 9);
                            }
                        }

                        generateAnswer(1000);
                    } else if (unit1 === 'm' && unit2 === 'cm') {
                        biggerUnit.unit = 'm';

                        smallerUnit.unit = 'cm';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 99,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 1 : 10;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(100);
                    } else if (
                        (unit1 === 'm' && unit2 === 'dm') ||
                        (unit1 === 'dm' && unit2 === 'cm') ||
                        (unit1 === 'cm' && unit2 === 'mm')
                    ) {
                        if (unit1 === 'dm' && unit2 === 'cm') {
                            biggerUnit.unit = 'dm';
                            smallerUnit.unit = 'cm';
                        } else if (unit1 === 'cm' && unit2 === 'mm') {
                            biggerUnit.unit = 'cm';
                            smallerUnit.unit = 'mm';
                        } else {
                            biggerUnit.unit = 'm';
                            smallerUnit.unit = 'dm';
                        }

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 99,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(10);
                    } else if (unit1 === 'ft' && unit2 === 'in') {
                        biggerUnit.unit = 'ft';

                        smallerUnit.unit = 'in';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 11,
                            },
                            nr2: {
                                min: 0,
                                max: 11,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(12);
                    } else if (unit1 === 'yd' && unit2 === 'ft') {
                        biggerUnit.unit = 'yd';

                        smallerUnit.unit = 'ft';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 2,
                            },
                            nr2: {
                                min: 0,
                                max: 2,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(3);
                    }
                } else if (subtopic === 'convertingWeight') {
                    if (
                        (unit1 === 't' && unit2 === 'kg') ||
                        (unit1 === 'kg' && unit2 === 'g')
                    ) {
                        if (unit1 === 'kg' && unit2 === 'g') {
                            biggerUnit.unit = 'kg';
                            smallerUnit.unit = 'g';
                        } else {
                            biggerUnit.unit = 't';
                            smallerUnit.unit = 'kg';
                        }

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 999,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 10 : 10;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        const useAlternativesForSmallerUnitNr2 =
                            randomIntFromRange(0, 2);

                        if (useAlternativesForSmallerUnitNr2 !== 1) {
                            if (useAlternativesForSmallerUnitNr2 === 2) {
                                smallerUnit.nr2 = smallerUnit.nr2 * 10;
                            } else {
                                smallerUnit.n2 = randomIntFromRange(1, 9);
                            }
                        }

                        generateAnswer(1000);
                    } else if (unit1 === 'ts' && unit2 === 'kg') {
                        biggerUnit.unit = 'ts';

                        smallerUnit.unit = 'kg';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 99,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        const generateABiggerNumberScale =
                            generateABiggerSmallNumber ? 1 : 10;

                        generateUnitValues(
                            biggerNumber,
                            smallerNumber,
                            generateABiggerNumberScale,
                        );

                        generateAnswer(100);
                    } else if (unit1 === 't' && unit2 === 'ts') {
                        biggerUnit.unit = 't';
                        smallerUnit.unit = 'ts';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 99,
                            },
                            nr2: {
                                min: 0,
                                max: 9,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(10);
                    } else if (unit1 === 'lb' && unit2 === 'oz') {
                        biggerUnit.unit = 'lb';

                        smallerUnit.unit = 'oz';

                        const biggerNumber = {
                            nr1: {
                                min: 1,
                                max: 20,
                            },
                            nr2: {
                                min: 0,
                                max: null,
                            },
                        };

                        const smallerNumber = {
                            nr1: {
                                min: 0,
                                max: 15,
                            },
                            nr2: {
                                min: 0,
                                max: 15,
                            },
                        };

                        generateUnitValues(biggerNumber, smallerNumber);

                        generateAnswer(16);
                    }
                }
            }
            return {
                data: {
                    biggerUnit,
                    smallerUnit,
                },
                answer,
            };
        }

        const randomUnitToConvertFrom = randomIntFromRange(1, 2);

        const convertFromUnit =
            randomUnitToConvertFrom === 1 ? conversion.unit1 : conversion.unit2;

        const convertToUnit =
            randomUnitToConvertFrom === 1 ? conversion.unit2 : conversion.unit1;

        const biggerUnit = math.evaluate(
            `1 ${convertFromUnit} > 1 ${convertToUnit}`,
        )
            ? convertFromUnit
            : convertToUnit;

        const smallerUnit =
            biggerUnit === convertFromUnit ? convertToUnit : convertFromUnit;

        if (topic === 'decimals') {
            const getDecimalsBaseNumber = () => {
                if (subtopic === 'convertingLength') {
                    /**
                     * Metric units
                     */
                    if (convertFromUnit === 'm' && convertToUnit === 'cm') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (convertFromUnit === 'km' && convertToUnit === 'm') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const base1 = randomIntFromRange(1, 100) / 10;

                            const base2 = randomIntFromRange(1, 1000) / 100;

                            const base3 = randomIntFromRange(1, 1000) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (
                        (convertFromUnit === 'cm' && convertToUnit === 'mm') ||
                        (convertFromUnit === 'dm' && convertToUnit === 'cm') ||
                        (convertFromUnit === 'm' && convertToUnit === 'dm')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 9);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }
                    }

                    if (convertFromUnit === 'm' && convertToUnit === 'km') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 1000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 10000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }
                    }

                    if (
                        (convertFromUnit === 'mm' && convertToUnit === 'cm') ||
                        (convertFromUnit === 'cm' && convertToUnit === 'dm') ||
                        (convertFromUnit === 'dm' && convertToUnit === 'm')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 9);

                            const base1 = random;

                            const base2 = random / 10;

                            return randomArrayElement([base1, base2]);
                        }
                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random / 10;

                            return randomArrayElement([base1, base2]);
                        }
                    }

                    if (convertFromUnit === 'mm' && convertToUnit === 'm') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 10);

                        const base1 = random;

                        const base2 = random * 10;

                        const base3 = random * 100;

                        const base4 = randomIntFromRange(1, 1000);

                        return randomArrayElement([base1, base2, base3, base4]);
                    }

                    if (convertFromUnit === 'cm' && convertToUnit === 'm') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random / 10;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = randomIntFromRange(1, 1000);

                            const base2 = random;

                            const base3 = random / 10;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (convertFromUnit === 'm' && convertToUnit === 'mm') {
                        // biggestConvertedUnitNumber = 1
                        const base1 = randomIntFromRange(1, 9) / 10;

                        const base2 = randomIntFromRange(1, 10) / 100;

                        const base3 = randomIntFromRange(1, 10) / 1000;

                        return randomArrayElement([base1, base2, base3]);
                    }

                    if (convertFromUnit === 'mm' && convertToUnit === 'dm') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 100);

                        const base1 = random;

                        const base2 = random / 10;

                        return randomArrayElement([base1, base2]);
                    }

                    if (convertFromUnit === 'dm' && convertToUnit === 'mm') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 10);

                        const base1 = random / 10;

                        const base2 = random / 100;

                        return randomArrayElement([base1, base2]);
                    }

                    /**
                     * Imperial units
                     */
                    if (convertFromUnit === 'ft' && convertToUnit === 'in') {
                        return randomIntFromRange(1, 12) / 10;
                    }

                    if (convertFromUnit === 'yd' && convertToUnit === 'ft') {
                        return randomIntFromRange(1, 20) / 10;
                    }

                    if (convertFromUnit === 'in' && convertToUnit === 'ft') {
                        const random = randomIntFromRange(1, 10) / 10;

                        return round(12 * random, 4);
                    }

                    if (convertFromUnit === 'ft' && convertToUnit === 'yd') {
                        const random = randomIntFromRange(1, 20) / 10;

                        return round(3 * random, 4);
                    }
                }

                if (subtopic === 'convertingWeight') {
                    /**
                     * Metric units
                     */
                    if (
                        (convertFromUnit === 't' && convertToUnit === 'kg') ||
                        (convertFromUnit === 'kg' && convertToUnit === 'g')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 9) / 10;

                            const base2 = randomIntFromRange(1, 100) / 100;

                            const base3 = randomIntFromRange(1, 1000) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                        if (biggestConvertedUnitNumber === 10) {
                            const base1 = randomIntFromRange(1, 100) / 10;

                            const base2 = randomIntFromRange(1, 1000) / 100;

                            const base3 = randomIntFromRange(1, 1000) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (convertFromUnit === 't' && convertToUnit === 'ts') {
                        // biggestConvertedUnitNumber = 1
                        const base1 = randomIntFromRange(1, 10) / 10;

                        const base2 = randomIntFromRange(1, 10) / 100;

                        return randomArrayElement([base1, base2]);
                    }

                    if (convertFromUnit === 'ts' && convertToUnit === 'kg') {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 10) / 10;

                            const base2 = randomIntFromRange(1, 100) / 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (
                        (convertFromUnit === 'kg' && convertToUnit === 't') ||
                        (convertFromUnit === 'g' && convertToUnit === 'kg')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 1000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 10000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }
                    }

                    if (convertFromUnit === 'ts' && convertToUnit === 't') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 9);

                        const base1 = random;

                        const base2 = random / 10;

                        return randomArrayElement([base1, base2]);
                    }

                    if (convertFromUnit === 'kg' && convertToUnit === 'ts') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random / 10;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const base1 = randomIntFromRange(1, 1000);

                            const base2 = randomIntFromRange(1, 100);

                            const base3 = randomIntFromRange(1, 100) / 10;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    /**
                     * Imperial units
                     */
                    if (convertFromUnit === 'g' && convertToUnit === 'mg') {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 9) / 10;

                            const base2 = randomIntFromRange(1, 10) / 100;

                            const base3 = randomIntFromRange(1, 10) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 1000);

                            const base1 = randomIntFromRange(1, 100) / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (convertFromUnit === 'mg' && convertToUnit === 'g') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 1000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 10000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }
                    }

                    if (
                        (convertFromUnit === 'lb' && convertToUnit === 'oz') ||
                        (convertFromUnit === 't' && convertToUnit === 'lb')
                    ) {
                        // biggestConvertedUnitNumber = 1
                        return randomIntFromRange(1, 10) / 10;
                    }

                    if (convertFromUnit === 'oz' && convertToUnit === 'lb') {
                        // biggestConvertedUnitNumber = 1
                        return (randomIntFromRange(1, 5) * 16) / 10;
                    }

                    if (convertFromUnit === 'lb' && convertToUnit === 't') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 10) * 2000;

                        const base1 = random / 10;

                        const base2 = random / 100;

                        const base3 = random / 1000;

                        return randomArrayElement([base1, base2, base3]);
                    }
                }

                if (subtopic === 'convertingVolume') {
                    /**
                     * Metric units
                     */
                    if (
                        (convertFromUnit === 'mm^3' &&
                            convertToUnit === 'cm^3') ||
                        (convertFromUnit === 'ml' && convertToUnit === 'l') ||
                        (convertFromUnit === 'cm^3' && convertToUnit === 'dm^3')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 1000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random * 10;

                            const base3 = random * 100;

                            const base4 = randomIntFromRange(1, 10000);

                            return randomArrayElement([
                                base1,
                                base2,
                                base3,
                                base4,
                            ]);
                        }
                    }

                    if (
                        (convertFromUnit === 'cm^3' &&
                            convertToUnit === 'mm^3') ||
                        (convertFromUnit === 'dm^3' && convertToUnit === 'l') ||
                        (convertFromUnit === 'dm^3' &&
                            convertToUnit === 'cm^3') ||
                        (convertFromUnit === 'l' && convertToUnit === 'dm^3') ||
                        (convertFromUnit === 'l' && convertToUnit === 'ml')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 10) / 10;

                            const base2 = randomIntFromRange(1, 100) / 100;

                            const base3 = randomIntFromRange(1, 1000) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 1000);

                            const base1 = randomIntFromRange(1, 100) / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (convertFromUnit === 'dm^3' && convertToUnit === 'm^3') {
                        // biggestConvertedUnitNumber = 1
                        const random = randomIntFromRange(1, 10);

                        const base1 = random;

                        const base2 = random * 10;

                        const base3 = random * 100;

                        const base4 = randomIntFromRange(1, 1000);

                        return randomArrayElement([base1, base2, base3, base4]);
                    }

                    if (convertFromUnit === 'm^3' && convertToUnit === 'dm^3') {
                        const base1 = randomIntFromRange(1, 10) / 10;

                        const base2 = randomIntFromRange(1, 100) / 100;

                        const base3 = randomIntFromRange(1, 1000) / 1000;

                        return randomArrayElement([base1, base2, base3]);
                    }

                    /**
                     * Imperial units
                     */
                    if (
                        (convertFromUnit === 'gal' && convertToUnit === 'qt') ||
                        (convertFromUnit === 'qt' && convertToUnit === 'c') ||
                        (convertFromUnit === 'gal' && convertToUnit === 'pt') ||
                        (convertFromUnit === 'c' && convertToUnit === 'floz')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            return randomIntFromRange(1, 10) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return randomIntFromRange(1, 50) / 10;
                        }
                    }

                    if (convertFromUnit === 'qt' && convertToUnit === 'pt') {
                        if (biggestConvertedUnitNumber === 1) {
                            return randomIntFromRange(1, 10) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return randomIntFromRange(1, 100) / 10;
                        }
                    }

                    if (
                        (convertFromUnit === 'pt' && convertToUnit === 'qt') ||
                        (convertFromUnit === 'c' && convertToUnit === 'pt')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            return (randomIntFromRange(1, 10) * 2) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return (randomIntFromRange(1, 100) * 2) / 10;
                        }
                    }

                    if (
                        (convertFromUnit === 'qt' && convertToUnit === 'gal') ||
                        (convertFromUnit === 'c' && convertToUnit === 'qt')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            return (randomIntFromRange(1, 10) * 4) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return (randomIntFromRange(1, 50) * 4) / 10;
                        }
                    }

                    if (
                        (convertFromUnit === 'pt' && convertToUnit === 'gal') ||
                        (convertFromUnit === 'floz' && convertToUnit === 'c')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            return (randomIntFromRange(1, 10) * 8) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return (randomIntFromRange(1, 50) * 8) / 10;
                        }
                    }

                    if (convertFromUnit === 'c' && convertToUnit === 'gal') {
                        if (biggestConvertedUnitNumber === 1) {
                            return (randomIntFromRange(1, 10) * 16) / 10;
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            return (randomIntFromRange(1, 20) * 16) / 10;
                        }
                    }

                    if (
                        (convertFromUnit === 'pt' && convertToUnit === 'c') ||
                        (convertFromUnit === 'gal' && convertToUnit === 'c')
                    ) {
                        // biggestConvertedUnitNumber = 1
                        return randomIntFromRange(1, 10);
                    }
                }

                if (subtopic === 'convertingArea') {
                    if (
                        (convertFromUnit === 'mm^2' &&
                            convertToUnit === 'cm^2') ||
                        (convertFromUnit === 'cm^2' &&
                            convertToUnit === 'dm^2') ||
                        (convertFromUnit === 'ha' &&
                            convertToUnit === 'km^2') ||
                        (convertFromUnit === 'dm^2' &&
                            convertToUnit === 'm^2') ||
                        (convertFromUnit === 'm^2' && convertToUnit === 'a')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random;

                            const base2 = random / 10;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = randomIntFromRange(1, 1000);

                            const base2 = random;

                            const base3 = random / 10;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (
                        (convertFromUnit === 'cm^2' &&
                            convertToUnit === 'mm^2') ||
                        (convertFromUnit === 'dm^2' &&
                            convertToUnit === 'cm^2') ||
                        (convertFromUnit === 'a' && convertToUnit === 'm^2') ||
                        (convertFromUnit === 'm^2' && convertToUnit === 'dm^2')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (
                        (convertFromUnit === 'm^2' &&
                            convertToUnit === 'cm^2') ||
                        (convertFromUnit === 'ha' && convertToUnit === 'm^2')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 10) / 10;

                            const base2 = randomIntFromRange(1, 100) / 100;

                            const base3 = randomIntFromRange(1, 1000) / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            const base3 = random / 1000;

                            return randomArrayElement([base1, base2, base3]);
                        }
                    }

                    if (
                        (convertFromUnit === 'm^2' && convertToUnit === 'ha') ||
                        (convertFromUnit === 'cm^2' && convertToUnit === 'm^2')
                    ) {
                        if (biggestConvertedUnitNumber === 1) {
                            const base1 = randomIntFromRange(1, 100) * 10;

                            const base2 = randomIntFromRange(1, 1000) * 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 100);

                            const base1 = random * 100;

                            const base2 = random * 1000;

                            return randomArrayElement([base1, base2]);
                        }
                    }

                    if (convertFromUnit === 'km^2' && convertToUnit === 'ha') {
                        if (biggestConvertedUnitNumber === 1) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }

                        if (biggestConvertedUnitNumber === 10) {
                            const random = randomIntFromRange(1, 10);

                            const base1 = random / 10;

                            const base2 = random / 100;

                            return randomArrayElement([base1, base2]);
                        }
                    }
                }
            };

            const baseNumber = getDecimalsBaseNumber();

            if (!baseNumber) {
                return;
            }
            const baseUnit =
                convertFromUnit === biggerUnit
                    ? math.unit(baseNumber, biggerUnit)
                    : math.unit(baseNumber, smallerUnit);

            const answer =
                convertFromUnit === biggerUnit
                    ? math.round(baseUnit.to(smallerUnit).toNumber(), 4)
                    : math.round(baseUnit.to(biggerUnit).toNumber(), 4);

            return {
                convertFromUnit,
                convertToUnit,
                baseNumber,
                answer,
            };
        }

        const baseNumber = randomIntFromRange(1, biggestConvertedUnitNumber);

        const baseUnit = math.unit(baseNumber, biggerUnit);

        const answer = math.round(baseUnit.to(smallerUnit).toNumber(), 1);

        return {
            convertFromUnit,
            convertToUnit,
            baseNumber: biggerUnit === convertFromUnit ? baseNumber : answer,
            answer: biggerUnit === convertFromUnit ? answer : baseNumber,
        };
    }

    static displayExponentiation(string) {
        return string
            .replaceAll('^2', '<sup>2</sup>')
            .replaceAll('^3', '<sup>3</sup>');
    }

    static getSolveEquationsVertically(icon, biggerUnit, smallerUnit) {
        return `<div id="rid-${new Date().getTime()}"
        class="vertical-equation ${
            biggerUnit ? 'vertical-equation--three-column' : ''
        }"
    >
        <div class="row">
            <div class="icon"></div>
            ${
                biggerUnit
                    ? `<div class="unit unit--bigger unit--nr1">
                ${
                    biggerUnit.nr1 > 0
                        ? ` ${biggerUnit.nr1}
                    ${biggerUnit.unit ? biggerUnit.unit : ''}`
                        : ''
                }
                </div>`
                    : ''
            }
            <div class="unit unit--smaller unit--nr1">
                ${
                    smallerUnit.nr1 > 0
                        ? `${smallerUnit.nr1}
                    ${smallerUnit.unit ? smallerUnit.unit : ''}`
                        : ''
                }
            </div>
        </div>
        <div class="row">
            <div class="icon">
                ${icon}
            </div>
            ${
                biggerUnit
                    ? `<div class="unit unit--bigger unit--nr2">
            ${
                biggerUnit.nr2 > 0
                    ? `${biggerUnit.nr2} ${
                          biggerUnit.unit ? biggerUnit.unit : ''
                      }`
                    : ''
            }
            </div>`
                    : ''
            }

            <div class="unit unit--smaller unit--nr2">
            ${
                smallerUnit.nr2 > 0
                    ? `${smallerUnit.nr2} ${
                          smallerUnit.unit ? smallerUnit.unit : ''
                      }`
                    : ''
            }
            </div>
        </div>
    </div>`;
    }

    static formatQuestion(question, skill, calledIn) {
        if (
            ['addition', 'subtraction'].includes(skill.numberGenerator.taskType)
        ) {
            const icon =
                skill.numberGenerator.taskType === 'addition' ? '+' : '-';

            const { biggerUnit, smallerUnit } = question.data;

            if (calledIn === 'playerStats') {
                const biggerNr1 =
                    biggerUnit.nr1 > 0
                        ? `${biggerUnit.nr1} ${biggerUnit.unit}`
                        : '';

                const smallerNr1 =
                    smallerUnit.nr1 > 0
                        ? `${smallerUnit.nr1} ${smallerUnit.unit}`
                        : '';

                const biggerNr2 =
                    biggerUnit.nr2 > 0
                        ? `${biggerUnit.nr2} ${biggerUnit.unit}`
                        : '';

                const smallerNr2 =
                    smallerUnit.nr2 > 0
                        ? `${smallerUnit.nr2} ${smallerUnit.unit}`
                        : '';

                // prettier-ignore
                return `${biggerNr1} ${smallerNr1}
                    ${icon}
                    ${biggerNr2} ${smallerNr2}`;
            }

            return this.getSolveEquationsVertically(
                icon,
                biggerUnit,
                smallerUnit,
            );
        }

        let convertFromUnit;

        let convertToUnit;

        if (hasOwnProp(question, 'data')) {
            convertFromUnit = question.data.convertFromUnit;

            convertToUnit = question.data.convertToUnit;
        } else {
            convertFromUnit = question.convertFromUnit;

            convertToUnit = question.convertToUnit;
        }
        return `
            <div class="conversion">
                ${parseFloat(
                    question.baseNumber || question.nr1,
                ).toLocaleString(this.locale())}
                ${this.displayExponentiation(convertFromUnit)}
                ${this.icon}
                ${this.displayExponentiation(convertToUnit)}
            </div>
        `;
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        numberGenerator = numberGenerator?.numberGenerator || numberGenerator;

        if (['addition', 'subtraction'].includes(numberGenerator.taskType)) {
            const answer = question.answer;

            return `
            ${answer.biggerUnit ? answer.biggerUnit : 0}
            ${question.data.biggerUnit.unit}
            ${answer.smallerUnit ? answer.smallerUnit : 0}
            ${question.data.smallerUnit.unit}`;
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        if (['addition', 'subtraction'].includes(numberGenerator.taskType)) {
            return ` = <span class="wrong-answer">
                    ${answer.biggerUnit ? answer.biggerUnit : 0}
                    ${question.data.biggerUnit.unit}
                    ${answer.smallerUnit ? answer.smallerUnit : 0}
                    ${question.data.smallerUnit.unit}
                </span>`;
        }

        return super.generatePlayerAnswerHtml(
            answer,
            numberGenerator,
            question,
        );
    }

    static answerData(question, answer, skill) {
        if (
            ['addition', 'subtraction'].includes(skill.numberGenerator.taskType)
        ) {
            return { ...question.data };
        }

        return {
            convertFromUnit: question.convertFromUnit,
            convertToUnit: question.convertToUnit,
        };
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (['addition', 'subtraction'].includes(numberGenerator.taskType)) {
            return (
                question.answer.biggerUnit === Number(answer.biggerUnit) &&
                question.answer.smallerUnit === Number(answer.smallerUnit)
            );
        }

        return question.answer === Number(answer);
    }

    static resetPlayerAnswer(numberGenerator, question = null) {
        if (['addition', 'subtraction'].includes(numberGenerator.taskType)) {
            return {
                biggerUnit: '',
                smallerUnit: '',
            };
        }

        return super.resetPlayerAnswer(numberGenerator, question);
    }

    static showArrowButtons(numberGenerator) {
        const { taskType } = numberGenerator;

        return ['addition', 'subtraction'].includes(taskType);
    }

    static getAnswerInputType(numberGenerator) {
        const { taskType } = numberGenerator;

        if (['addition', 'subtraction'].includes(taskType))
            return ANSWER_INPUT_TYPE.VERTICAL_EQUATATION;
        return ANSWER_INPUT_TYPE.REGULAR;
    }
}
