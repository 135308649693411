/* global mixpanel */
export const CERTIFICATE_PRINTOUT_EVENTS = {
    TEACHER_VIEWED_PRINT_CERTIFICATES_PAGE: 'teacherViewedCertificatePrint',
    STUDENT_READ_QR_FROM_CERTIFICATE: 'qrCodeOfTheCertificateWasRead',
};

export const CERTIFICATE_PRINTOUT_METHODS = {
    teacherViewedCertificatePrint(gameCode) {
        mixpanel.track('Teacher viewed the certificates print preview page', {
            gameCode,
        });
    },
    qrCodeOfTheCertificateWasRead() {
        mixpanel.track('The QR code of printed Certificate was scanned');
    },
};
