import { i18n } from '@/lang/translator.js';
import { firstToUpper, getLocale } from '@/core/helpers/utils';

export const scaleAndColor = (calledIn) => {
    const globalScale = calledIn === 'inGame' ? 3 : 1;

    const textColor = ['report', 'spLiveAnswer'].includes(calledIn)
        ? 'black'
        : 'white';

    return [globalScale, textColor];
};

export const drawNumberLine = (
    numberGenerator,
    question,
    calledIn,
    globalScale,
    textColor,
) => {
    const inGame = calledIn === 'inGame';

    if (
        numberGenerator.numberLineBase === 'integer' ||
        numberGenerator.numberLineBase === 'realIntegers' ||
        numberGenerator.numberLineBase === 'decimal'
    ) {
        let { number1, number2, number3, number4, number5, data } = question;

        if (
            (number1 === null || number1 === undefined) &&
            (number2 === null || number2 === undefined)
        ) {
            number1 = question.nr1;
            number2 = question.nr2;
            number3 = question.nr3;
            number4 = question.nr4;
            number5 = question.nr5;
            data = question.data;
        }

        const numberLength = number1
            ? number1.toString().length - 1
            : number2
              ? number2.toString().length - 1
              : 2;

        const startOfDigits = (inGame ? 34 : 30) - numberLength;

        let digitsX = inGame
            ? [
                  startOfDigits,
                  74 - numberLength,
                  114 - numberLength,
                  154 - numberLength,
                  194 - numberLength,
              ]
            : [startOfDigits, 70, 110, 150, 190];

        digitsX = digitsX.map((x) => x + numberLength);

        const shouldDrawDot = (num) =>
            (num !== undefined && !isNaN(num) && num !== null) || num === '?'
                ? num.toLocaleString(getLocale())
                : '';

        const digitsY = (order) => {
            if (numberGenerator.numberLineBase === 'decimal') {
                return order % 2 === 0 ? '100' : '83';
            } else {
                return '100';
            }
        };

        const drawDistanceBetweenDotsLetters = (yOffset) => {
            if (data?.Alocation && data?.Blocation) {
                return `<text
                    text-anchor="middle"
                    x="${digitsX[data.Alocation - 1]}"
                    y="${yOffset}"
                    fill="${inGame ? '#4FC6D7' : textColor}"
                >
                    A
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[data.Blocation - 1]}"
                    y="${yOffset}"
                    fill="${inGame ? '#4FC6D7' : textColor}"
                >
                    B
                </text>`;
            }
            return '';
        };

        return `
            ${
                inGame && !drawDistanceBetweenDotsLetters()
                    ? '<h3 class="margin-top-bottom">' +
                      i18n.tc('player.numberLine.findNumber') +
                      '</h3>'
                    : ''
            }
            <svg
                height="100"
                width="200"
                viewBox="0 0 225 100"
                font-size="${inGame ? '1.1rem' : '1rem'}"
                class='number-line-svg'
            >
                <path d="M10 50 L210 50 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M30 35 L30 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M70 35 L70 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M110 35 L110 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M150 35 L150 65 Z" stroke="${textColor}" stroke-width="3"/>
                <path d="M190 35 L190 65 Z" stroke="${textColor}" stroke-width="3"/>

                <text
                    text-anchor="middle"
                    x="${digitsX[0]}"
                    y="${digitsY(1)}"
                    fill="${inGame && number1 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(number1)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[1]}"
                    y="${digitsY(2)}"
                    fill="${inGame && number2 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(number2)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[2]}"
                    y="${digitsY(3)}"
                    fill="${inGame && number3 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(number3)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[3]}"
                    y="${digitsY(4)}"
                    fill="${inGame && number4 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(number4)}
                </text>
                <text
                    text-anchor="middle"
                    x="${digitsX[4]}"
                    y="${digitsY(5)}"
                    fill="${inGame && number5 === '?' ? '#4FC6D7' : textColor}"
                >
                    ${shouldDrawDot(number5)}
                </text>

                ${drawDistanceBetweenDotsLetters(30)}
            Sorry, your browser does not support inline SVG.
            </svg>`;
    }

    let { wholeNumber, nextWholeNumber, partsBetween, pointLocation, answer } =
        question;

    if (typeof wholeNumber === 'undefined') {
        wholeNumber = question.data.wholeNumber;

        nextWholeNumber = question.data.nextWholeNumber;

        partsBetween = question.data.partsBetween;

        pointLocation = question.data.pointLocation;
    }

    const answerIsLessThan1 = answer.numerator / answer.denominator < 1;

    return `${
        inGame
            ? '<h4>' +
              i18n.tc(
                  `player.numberLine.find${
                      answerIsLessThan1
                          ? ''
                          : firstToUpper(numberGenerator.fractionType)
                  }Fraction`,
              ) +
              '</h4>'
            : ''
    }<svg height="100" width="200" viewBox="0 0 280 100" font-size="${
        inGame ? '1.5rem' : '1rem'
    }" class='number-line-svg'>
            <path d="M0 50 L260 50 Z" stroke="${textColor}" stroke-width="4"/>
            ${generateNumberLineDots(partsBetween, textColor)}

            ${generateNumberLineLabels(
                partsBetween,
                textColor,
                wholeNumber,
                nextWholeNumber,
                pointLocation,
                inGame,
            )}

        Sorry, your browser does not support inline SVG.
        </svg>`;
};

const generateNumberLineDots = (partsBetween, textColor) => {
    // First dot.
    let string = `<path d="M10 25 L10 75 Z" stroke="${textColor}" stroke-width="4"/>`;

    const widthBetweenDots = getNumberLineWidthBetweenDots(partsBetween);

    let prevLocation = 10;

    for (let i = 1; i <= partsBetween - 1; i++) {
        const dotLocation = prevLocation + widthBetweenDots;

        prevLocation = dotLocation;

        string += `<path d="M${dotLocation} 35 L${dotLocation} 65 Z" stroke="${textColor}" stroke-width="3"/>`;
    }

    string += `<path d="M250 25 L250 75 Z" stroke="${textColor}" stroke-width="4"/>`;

    return string;
};

const generateNumberLineLabels = (
    partsBetween,
    textColor,
    wholeNumber,
    nextWholeNumber,
    pointLocation,
    inGame,
) => {
    let prevLocation = inGame ? 3 : 8;

    let string = `<text x="${prevLocation}" y="100" fill="${textColor}">${wholeNumber}</text>`;

    const widthBetweenDots = getNumberLineWidthBetweenDots(partsBetween);

    for (let i = 1; i <= partsBetween - 1; i++) {
        const dotLocation = prevLocation + widthBetweenDots;

        prevLocation = dotLocation;

        string += `<text x="${dotLocation}" y="100" fill="${
            inGame && pointLocation === i ? '#4FC6D7' : textColor
        }">${pointLocation === i ? '?' : ''}</text>`;
    }

    string += `<text x="248" y="100" fill="${textColor}">${nextWholeNumber}</text>`;

    return string;
};

const getNumberLineWidthBetweenDots = (partsBetween) => {
    const svgWidth = 240;

    return Math.round(svgWidth / partsBetween);
};

export const createPie = (
    cx,
    cy,
    r,
    slices,
    numberOfColoredSlices,
    isExample,
    ids,
) => {
    let fromAngle, toAngle, fromCoordX, fromCoordY, toCoordX, toCoordY, path, d;

    let paths = [];

    let coloredSlices = 0;

    for (let i = 0; i < slices; i++) {
        path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        fromAngle = (i * 360) / slices;

        toAngle = ((i + 1) * 360) / slices;

        fromCoordX = cx + r * Math.cos((fromAngle * Math.PI) / 180);

        fromCoordY = cy + r * Math.sin((fromAngle * Math.PI) / 180);

        toCoordX = cx + r * Math.cos((toAngle * Math.PI) / 180);

        toCoordY = cy + r * Math.sin((toAngle * Math.PI) / 180);

        d =
            'M' +
            cx +
            ',' +
            cy +
            ' L' +
            fromCoordX +
            ',' +
            fromCoordY +
            ' A' +
            r +
            ',' +
            r +
            ' 0 0,1 ' +
            toCoordX +
            ',' +
            toCoordY +
            'z';

        const coloredClass = ids
            ? ids.includes(`slice-${i}`)
                ? 'class="colored"'
                : 'class="non-colored"'
            : coloredSlices < numberOfColoredSlices
              ? 'class="colored"'
              : 'class="non-colored"';

        coloredSlices++;

        path = `<path d="${d}" id="slice-${i}" ${coloredClass}></path>`;

        paths.push(path);
    }
    return `<svg class="pie-fraction ${
        isExample ? 'pie-fraction--example' : ''
    }" width="${cx * 2}" height="${cy * 2}">
            ${paths.join('')}
                </svg>`;
};

export const createRectangleFraction = (
    cx,
    cy,
    slices,
    numberOfColoredSlices,
    isExample,
    ids,
) => {
    let pieces = [];

    const padding = 2;

    const widthPerPiece = Math.floor(cx / slices);

    let coloredSlices = 0;

    for (let i = 0; i < slices; i++) {
        const coloredClass = ids
            ? ids.includes(`slice-${i}`)
                ? 'class="colored"'
                : 'class="non-colored"'
            : coloredSlices < numberOfColoredSlices
              ? 'class="colored"'
              : 'class="non-colored"';

        coloredSlices++;

        pieces.push(
            `<rect id="slice-${i}" x="${
                widthPerPiece * i + padding
            }" width="${widthPerPiece}" height="${cy}" ${coloredClass} />`,
        );
    }
    return `
<svg width="${cx + padding * 2}" height="${cy}" class="rectangle-fraction ${
        isExample ? 'rectangle-fraction--example' : ''
    }">
${pieces.join('')}
</svg>`;
};
