/* global mixpanel */
export const REACTIONS_SPG_MP_EVENTS = {
    EXTENDED_STUDENT_FEEDBACK_LIST: 'extendedStudentFeedbackList',
    STUDENT_GAVE_FEEDBACK: 'studedntGaveFeedback',
};

export const REACTIONS_SPG_MP_METHODS = {
    extendedStudentFeedbackList() {
        mixpanel.track('Expanded student feedback list');
    },
    studedntGaveFeedback(reactions) {
        mixpanel.track('Student gave feedback after game', {
            'Nr of reactions chosen': reactions.length,
            'Reactions chosen': reactions,
        });
    },
};
