/* global mixpanel */

export const BUNDLE_SALES_EVENTS = {
    BUNDLE_SALES_CLICK_ON_BUY_BTN: 'bundleSalesClickOnBuyBtn',
    BUNDLE_SALES_BOUGHT_MAY_BUNDLE: 'bundleSalesBoughtMayBundle',
    BUNDLE_SALES_BOUGHT_JUNE_BUNDLE: 'bundleSalesBoughtJuneBundle',
};

export const BUNDLE_SALES_METHODS = {
    bundleSalesClickOnBuyBtn() {
        mixpanel.track('Student click on Bundle Sales buy button');
    },
    bundleSalesBoughtMayBundle() {
        mixpanel.track('Student bought May 31.05 Bundle Sales');
    },
    bundleSalesBoughtJuneBundle() {
        mixpanel.track('Student bought June 31.06 Bundle Sales');
    },
};
