import { io } from 'socket.io-client';
import { Bot } from '@/core/helpers/bots/Bot';
import CONSTANTS from '@/core/helpers/constants';
import store from '@/store';
import { objectHash } from '@/core/helpers/utils';
import { isEqual } from 'lodash';
import {
    MATH_RUNNER_CONSTANTS,
    MATH_RUNNER_GAME_STATES,
} from '@/store/modules/math-runner';

export class MathRunnerBot extends Bot {
    constructor(name, avatar) {
        super(name, avatar);
        this._myStats = null;
        this._gameMode = CONSTANTS.MATH_RUNNER_GAME_MODE;
        this._wsIoUrl = `${import.meta.env.VITE_API_URL}math-runner`;
        this._userId = name;
        this._store = store;
        this._unsubscribe = null;
        this._answeredQuestionsCount = 0;
        this._pendingAnswerIsCorrect = null;
        this._currentQuestion = null;
        this._currentGameState = null;
        this._previousGameState = null;
        this._magicTimeCounter = 0;
    }

    id() {
        return this._client.clientId;
    }
    // uncomment for debugging
    log(...msg) {
        console.log(`BOT [${this._name}] log:`, ...msg);
    }

    async setupSocket() {
        this.log('setting up ws');
        this._socket = io(this._wsIoUrl, {
            autoConnect: false,
            transports: ['websocket'],
            query: {
                jwt: localStorage.getItem('authToken'),
            },
            multiplex: false,
            forceNew: true,
        });
        this._socket.on('connect', async () => {
            this._socket.emit('join', this._gameCode);
            this.log('connected');
            if (this._joinResolve) {
                this._socket.emit('selectedAvatar', this._avatar);
                this._joinResolve(true);
                this._joinResolve = null;
            }
        });
        this._socket.on('disconnect', () => {
            this.log('disconnected');
        });
        this._socket.on('relayPlayerAnswer', (data) => {
            this.checkIfShouldBotShouldLoseALife();
            const playerAnswerCount = data.answerCount;
            if (
                playerAnswerCount === this._answeredQuestionsCount &&
                this._pendingAnswerIsCorrect !== null
            ) {
                return;
            }
            const spawnedQuestions =
                store.getters['v2/mathRunner/spawnedQuestions'];
            const index = playerAnswerCount;
            const indexInSpawned = spawnedQuestions.findIndex(
                (q) => q.index === index,
            );
            const question = spawnedQuestions[indexInSpawned];
            if (question) {
                this.answerQuestion(question, index);
            }
        });
        await this.waitMs(this.randomInt(50, 200));
        this.log('open ws connection');
        this._socket.open();
        return new Promise((resolve) => {
            this._joinResolve = resolve;
        });
    }

    async join(gameCode) {
        await super.join(gameCode);
        void this.setupSocket();
        this.setupBotsGamePlay();
    }

    async resumeBot(gameCode) {
        this._gameCode = gameCode;
        void this.setupSocket();
        this.setupBotsGamePlay();
    }

    close() {
        if (this._unsubscribe) {
            this._unsubscribe();
            this._unsubscribe = null;
        }
        if (this._socket) {
            this._socket.emit('leaveGame', {
                gameCode: this._gameCode,
                userId: this._userId,
            });
            // Give ws time to send the leaveGame message.
            setTimeout(() => {
                this._socket.offAny();
                this._socket.close({ gameCode: this._gameCode });
                this._socket = null;
            }, 300);
        }
    }

    setupBotsGamePlay() {
        this.updateMyStats();
        this._unsubscribe = this._store.subscribe((mutation, state) => {
            if (mutation.type === 'v2/mathRunner/spawnQuestion') {
                const { payload } = mutation;
                const { index } = payload;
                const question = payload;
                // Only deal with the first question & the questions after magic
                // time, rest need to be through the relayPlayerAnswer event.
                const questionsWithNoPlayerAnswersYet = [
                    // First ever question.
                    0,
                    // All first questions after magic time.
                    ...MATH_RUNNER_CONSTANTS.MULTIPLAYER
                        .MAGIC_TIME_STARTS_ON_QUESTIONS,
                ];
                if (
                    questionsWithNoPlayerAnswersYet.some(
                        (questionIndex) =>
                            index === questionIndex &&
                            this._answeredQuestionsCount === questionIndex,
                    )
                ) {
                    this.answerQuestion(question, index);
                }
            } else if (mutation.type === 'v2/mathRunner/updatePlayer') {
                this.updateMyStats();
            } else if (mutation.type === 'v2/mathRunner/state') {
                const { payload } = mutation;

                this._previousGameState = this._currentGameState;
                this._currentGameState = payload;
                if (
                    this._currentGameState ===
                    MATH_RUNNER_GAME_STATES.MAGIC_TIME
                ) {
                    // Hack to force bots on the magic time question index.
                    this._answeredQuestionsCount =
                        MATH_RUNNER_CONSTANTS.MULTIPLAYER.MAGIC_TIME_STARTS_ON_QUESTIONS[
                            this._magicTimeCounter
                        ];
                    this._magicTimeCounter++;

                    this.chooseInMagicTime();
                }
            }
        });
    }

    updateMyStats() {
        const players = this._store.getters['v2/mathRunner/players'];
        this._myStats = players.find((p) => p.name === this._name);
        this.log(`Lives: ${this._myStats?.lives}`);
    }

    async answerQuestion(question, questionIndex) {
        if (isEqual(question, this._currentQuestion)) {
            return;
        }
        if (this._myStats?.lives <= 0) {
            this.log('Out of lives, unable to answer');
            return;
        }

        this.log('Answer a question: ', question);
        this._answeredQuestionsCount++;
        this._currentQuestion = question;

        const answer = this.generateRandomAnswer(question);

        const timeToAnswer =
            questionIndex === 0
                ? this.randomInt(2500, 6000)
                : this.randomInt(750, 3000);
        await this.waitMs(timeToAnswer);

        this._pendingAnswerIsCorrect = answer === question.answer;
        const questionBody = {
            ...question,
            nr1: question.number1,
            nr2: question.number2,
            nr3: question.number3,
            data: question.data ? { ...question.data } : { ...question },
        };
        const hash = await objectHash(questionBody);
        questionBody.time = timeToAnswer;
        questionBody.questionHash = hash;
        questionBody.playerAnswer = answer;
        questionBody.correct = this._pendingAnswerIsCorrect;

        this._socket.emit('pendingPlayerAnswer', {
            answerCount: this._answeredQuestionsCount,
            questionIndex,
            gameCode: this._gameCode,
            playerName: this._name,
            userId: this._userId,
            questionBody,
        });
    }

    checkIfShouldBotShouldLoseALife() {
        // Precise type check is important, because if its true or null
        // we don't want the bot to lose a life.
        if (this._pendingAnswerIsCorrect === false) {
            this.log('Losing a life for wrong answer');
            this._socket.emit('removeLife', {
                playerName: this._name,
                userId: this._userId,
                gameCode: this._gameCode,
            });
        }
        this._pendingAnswerIsCorrect = null;
    }

    selectableSpells() {
        const players = store.getters['v2/mathRunner/players'];

        return [
            {
                id: 'slowDown',
            },
            {
                id: 'healPlayer',
                heartCount: this.randomInt(1, 2),
                healTarget: players[this.randomInt(0, players.length - 1)],
            },
        ];
    }

    async chooseInMagicTime() {
        if (this._myStats?.lives > 0) {
            const spellsVariants = this.selectableSpells();

            const selectedSpell =
                spellsVariants[this.randomInt(0, spellsVariants.length - 1)];

            await store.dispatch('v2/mathRunner/botUseSpell', {
                playerName: this._name,
                userId: this._userId,
                spell: selectedSpell.id,
                usedOnPlayerId: selectedSpell?.healTarget?.userId || 'Me',
                healAmount: selectedSpell?.heartCount || 0,
            });
        }
    }
}
