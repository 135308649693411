<template>
    <span class="mastery-growth">
        Students average
        <span class="mastery-growth__gold">80-100% mastery growth</span>
        with 99math Premium in just one month.
    </span>
</template>
<style lang="scss" scoped>
.mastery-growth {
    margin-bottom: 32px;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 17px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 14px;
    }

    @media screen and (max-width: $w-max-phone) {
        margin-bottom: 10px;
        font-size: 12px;
    }

    &__gold {
        color: var(--Identity-Accent-Live-games, #ffc75a);
    }
}
</style>
