export default {
    getPlayerName({ playerState }) {
        return playerState.playerName;
    },
    getUserSessionId({ playerState }) {
        return playerState.playerGuestSession;
    },
    getPlayerNameError({ playerState }) {
        return playerState.playerNameError;
    },
    getGameIsFullError({ playerState }) {
        return playerState.gameIsFullError;
    },
    getPlayerScore({ playerState }, getters) {
        if (getters.isSelfPacedGameCreatedByATeacher) {
            return playerState.currentPlayerSession.score;
        }

        return playerState.score;
    },
    // getPlayerMultiplierScore({ playerState }) {
    //     return playerState.multiplierScore;
    // },
    getPlayerStats({ playerState }, getters) {
        if (getters.isSelfPacedGameCreatedByATeacher) {
            return { ...playerState.currentPlayerSession.stats };
        }

        return playerState.stats;
    },
    getGameCodeError({ gameCodeError }) {
        return gameCodeError;
    },
    getAnsweredQuestionsCount({ playerState }, getters) {
        if (getters.isSelfPacedGameCreatedByATeacher) {
            return playerState?.currentPlayerSession?.stats?.answeredQuestions;
        }

        return playerState.stats?.answeredQuestions;
    },
    getActiveInputType({ playerState }) {
        return playerState.activeInputType;
    },
    getGameCreatorRole(_store) {
        return _store.gameCreatorRole;
    },
    getSPGCurrentPlayerSession({ playerState }) {
        return playerState.currentPlayerSession.id;
    },
    getCurrentPlayerSessionStats({ playerState }) {
        return { ...playerState.currentPlayerSession.stats };
    },
};
