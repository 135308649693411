<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.progressx2') }}
            </div>
        </div>

        <div class="normal-text">
            {{ $t('homeGame.supportYourKid') }}
        </div>

        <div class="packages">
            <div
                v-for="priceItem in priceTest.products"
                :key="priceItem.stripe.id"
                class="package"
                :class="{
                    'selected-package': selected === priceItem.stripe.id,
                }"
                @click="selectPlan(priceItem.stripe.id)"
            >
                <div class="description">
                    <div class="duration">
                        {{ priceItem.stripe.recurring.interval_count }}
                        {{
                            priceItem.stripe.recurring.interval_count > 1
                                ? `${priceItem.stripe.recurring.interval}s`
                                : priceItem.stripe.recurring.interval
                        }}
                    </div>
                    <div class="helper-text">
                        <span
                            v-if="
                                priceItem.stripe.recurring.interval === 'week'
                            "
                        >
                            popular
                        </span>
                        <span
                            v-else-if="
                                priceItem.stripe.recurring.interval_count ===
                                    1 &&
                                priceItem.stripe.recurring.interval === 'month'
                            "
                        >
                            save -25%
                        </span>
                        <span
                            v-else-if="
                                priceItem.stripe.recurring.interval_count ===
                                    3 &&
                                priceItem.stripe.recurring.interval === 'month'
                            "
                        >
                            save -39%
                        </span>
                        <span v-else>save -52%</span>
                    </div>
                </div>
                <div class="pricing">
                    ${{
                        priceItem.stripe.unit_amount_decimal.slice(
                            0,
                            priceItem.stripe.unit_amount_decimal.length - 2,
                        )
                    }}
                </div>
            </div>
        </div>

        <div class="cancel-anytime-text">
            {{ $t('homeGame.cancelAnytimeText') }}
            <a class="tos" :href="$getTermsOfUseLink" target="_blank">
                {{ $t('footer.termsOfUse') }}
            </a>
        </div>

        <div class="cta" @click="$emit('advance')">
            <div class="text">
                {{ $t('homeGame.subscribe') }}
            </div>
        </div>
    </div>
</template>
<script>
import {
    TrackingService,
    EVENTS,
} from '@/core/services/TrackingService/TrackingService.js';
import { mapGetters } from 'vuex';
export default {
    emits: ['advance', 'selectedPlanChange'],
    data() {
        return {
            selected: 'weekly3',
        };
    },
    computed: {
        ...mapGetters({
            priceTest: 'getUsersPriceTest',
        }),
    },
    async mounted() {
        await new TrackingService().track(EVENTS.VIEW_PRICE_PACKAGE_SCREEN);

        const product = this.priceTest?.products.find(
            (product) => product.name === this.selected,
        );

        this.selected = product?.stripe.id;

        this.$emit('selectedPlanChange', this.selected);
    },
    methods: {
        selectPlan(planId) {
            this.selected = planId;
            this.$emit('selectedPlanChange', planId);
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        padding: 40px 0;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .text {
            font-size: 29px;
            font-weight: bold;
        }

        .secondary-text {
            font-size: 21px;
            margin-top: 10px;
        }
    }

    .normal-text {
        margin: 15px 0 20px;
        padding: 0 7%;
        text-align: center;
    }

    .packages {
        cursor: pointer;
        width: 100%;
        background-color: #572598;
        color: white;
        display: grid;
        grid-template-areas:
            '1fr 1fr'
            '1fr 1fr';

        .package {
            box-sizing: border-box;
            align-items: center;
            justify-content: space-around;
            padding: 10px 20px;
            display: flex;
            height: 100px;
            border: 0.5px solid black;

            .helper-text {
                margin-top: 6px;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                background-color: #b685f6;
                border-radius: 20px;
                padding: 4px 8px;
            }

            .duration {
                font-size: 18px;
            }

            .pricing {
                font-weight: 500;
                letter-spacing: 1px;
            }
        }

        .selected-package {
            border: 1px solid #ffc75a;
            background-color: #8137e3;

            .helper-text {
                background-color: #ffc75a;
                color: black;
            }
        }
    }

    .cancel-anytime-text {
        text-align: center;
        margin: 15px;
        font-size: 13px;

        .tos {
            text-decoration: underline;
        }

        a:active,
        a:visited,
        a:hover {
            color: white;
        }
    }

    .cta {
        position: absolute;
        bottom: -30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }

        .text {
            font-size: 30px;
            font-weight: bold;
        }

        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .primary {
            padding: 20px 0 10px;

            .price-text {
                margin-top: 14px;
            }

            &::after {
                bottom: -10px;
                height: 10px;
            }
        }
    }
}
</style>
