import SecureApi from '@/flows/Authentication/services/SecureApi';
import { i18n } from '@/lang/translator';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';

export default {
    async setLanguage({ commit, getters }, langCode) {
        await import(`@/lang/${langCode}/${langCode}.json`).then((strings) => {
            i18n.setLocaleMessage(langCode, strings.default || strings);
            i18n.locale = langCode;
            commit('setLanguage', langCode);
            sessionStorage.setItem('language', langCode);
            const user = getters.user;
            if (user && user.locale !== langCode) {
                return SecureApi()
                    .post(`/auth/set/language/${langCode}`)
                    .then((response) => {
                        const responseData = response.data;
                        if (responseData.success) {
                            fetchAndUpdateUser(user);
                            return true;
                        } else {
                            console.error(
                                'sL server error: ',
                                responseData.error.message,
                            );
                            return false;
                        }
                    })
                    .catch((error) => {
                        console.error('sL request error: ', error);
                        return false;
                    });
            }
            return true;
        });
    },
};
