import { Capacitor } from '@capacitor/core';
import { Mixpanel } from '@houseninja/capacitor-mixpanel';
import { App } from '@capacitor/app';

const mixpanelPromises = [];

async function flushMixpanel() {
    await Promise.all(mixpanelPromises);
    mixpanelPromises.length = 0;

    await Mixpanel.flush();
}

export const initCapacitorMixpanel = async function () {
    console.log('=== mixpanel.init');
    const { id: appId } = await App.getInfo();
    // below prod check need to consider both iOS and Android
    const mixpanelToken = ((appId) => {
        if (
            import.meta.env.VITE_API_URL.match(/\.prod/) &&
            appId?.match(/com\.math99\.mobile/)
        ) {
            // TODO identify app installed from app store/google play
            return 'df5e9cf2670326a256d5ddd062ff05e0';
        } else {
            return 'c602f773c92f512a1bd2af88d9dee7a4';
        }
    })(appId);

    try {
        if (Capacitor?.isPluginAvailable('Mixpanel')) {
            await Mixpanel.initialize({
                token: mixpanelToken,
                autotrack: false,
                optOutByDefault: false,
                debug: true,
            });
            window.mixpanel /*: Mixpanel */ = {
                ...window.mixpanel,
                alias: (alias, distinctId) =>
                    distinctId
                        ? Mixpanel.alias({ alias, distinctId })
                        : Mixpanel.alias({ alias }),
                has_opted_out_tracking: () => Mixpanel.hasOptedOutTracking(),
                identify: (distinctId) => Mixpanel.identify({ distinctId }),
                people: {
                    set: (properties) => Mixpanel.setProfile({ properties }),
                    set_once: (properties) =>
                        Mixpanel.setProfileOnce({ properties }),
                    increment: (propertyName, increment = 1) =>
                        Mixpanel.incrementProfile({
                            properties: {
                                [propertyName]: +increment,
                            },
                        }),
                },
                register: (properties) =>
                    Mixpanel.registerSuperProperties({ properties }),
                // TODO?
                register_once: (properties) =>
                    Mixpanel.registerSuperPropertiesOnce({ properties }),
                reset: () => Mixpanel.reset(),
                track: (event, properties) =>
                    Mixpanel.track({ event, properties }),
            };

            const collectPromisesFn = (fn) => {
                return function (...args) {
                    const promise = fn(...args);
                    mixpanelPromises.push(promise);
                    return promise;
                };
            };

            for (const key in window.mixpanel) {
                if (typeof window.mixpanel[key] === 'function') {
                    window.mixpanel[key] = collectPromisesFn(
                        window.mixpanel[key],
                    );
                }
            }

            setInterval(flushMixpanel, 60_000);
            App.addListener('pause', () => flushMixpanel());
        } else {
            console.log('== legacy mixpanel init');
            window.mixpanel.init(mixpanelToken);
        }
    } catch (e) {
        console.warn('cannot initialize mixpanel', e);
    }
};
