/* global mixpanel */
export const STUDENT_WITHOUT_ACCOUNT_EVENTS = {
    STUDENT_SAW_ANONYMOUS_END_GAME: 'studentSawAnonymousEndGame',
    STUDENT_SAW_MORE_99MATH: 'studentSaw99Math',
    STUDENT_SAW_HOME_MODE: 'studentSawHomeMode',
    STUDENT_SAW_SOLO_MODE: 'studentSawSoloMode',
    STUDENT_SAW_PET_PARK: 'studentSawPetPark',
    STUDENT_SAW_COLLECTION: 'studentSawCollection',
    STUDENT_SAW_REWARDS: 'studentSawRewards',
};

export const STUDENT_WITHOUT_ACCOUNT_METHODS = {
    studentSawAnonymousEndGame() {
        mixpanel.track('W/o account end game: student saw anonymous end game');
    },
    studentSaw99Math() {
        mixpanel.track(
            'W/o account end game: student click on "more on 99math',
        );
    },
    studentSawHomeMode() {
        mixpanel.track('W/o account end game: student hover on "Home mode');
    },
    studentSawSoloMode() {
        mixpanel.track('W/o account end game: student hover on "Solo mode');
    },
    studentSawPetPark() {
        mixpanel.track('W/o account end game: student hover on "Pet park');
    },
    studentSawCollection() {
        mixpanel.track('W/o account end game: student hover on "Collection');
    },
    studentSawRewards() {
        mixpanel.track('W/o account end game: student hover on "Rewards');
    },
};
