<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.yourEnergy') }}
            </div>
            <div class="lives">
                <math-lives theme="dark" />
            </div>
        </div>
        <div class="already-subscribed">
            {{ $t('homeGame.alreadySubscribed') }}
        </div>

        <div class="cta" @click="$emit('closeModal')">
            <div class="text">
                {{ $t('homeGame.goPlay') }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits: ['closeModal'],
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        padding: 20px 0;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            font-size: 24px;
            font-weight: bold;
        }

        .lives {
            margin-top: 1rem;
            margin-left: 0.5rem;
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: #482c6e;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .already-subscribed {
        font-size: 29px;
        font-weight: bold;
        margin-top: 100px;
        text-align: center;
    }

    .cta {
        position: absolute;
        bottom: -30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        text-align: center;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }

        .text {
            font-size: 30px;
            font-weight: bold;
        }

        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .secondary {
            box-sizing: border-box;
            .before-after {
                margin-top: 10px;

                .free,
                .premium {
                    .package-name-text {
                        margin-bottom: 6px;
                        font-size: 16px;
                    }
                    img {
                        width: 110px;
                        height: 45px;
                    }
                }
            }
        }
    }
}
</style>
