import Api from '@/core/services/Api.js';
import cardsJSON from '@/core/static-json/cardsData.json';

export default {
    namespaced: true,
    state: {
        parkData: null,
        favoritePet: null,
        petsConfig: cardsJSON,
    },
    getters: {
        allPets: (state) => {
            return state.petsConfig.flatMap((pets) => pets.items);
        },
    },
    mutations: {
        setParkData: (state, next) => {
            state.parkData = next;
        },
        setFavoritePet: (state, next) => {
            state.favoritePet = next;
        },
        hidePet: (state, next) => {
            if (!state.parkData?.hide) state.parkData.hide = [];
            state.parkData.hide.push(next);
        },
        showPet: (state, next) => {
            state.parkData.hide = state.parkData.hide.filter(
                (pet) => pet !== next,
            );
        },
    },
    actions: {
        reset: ({ commit }) => {
            commit('setParkData', null);
            commit('setFavoritePet', null);
        },
        getParkData: async ({ commit }) => {
            try {
                const result = await Api().get('/park');

                const { data, success } = result.data;

                if (success) {
                    commit('setParkData', data);

                    return data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        updatePark: async ({ commit }, payload) => {
            try {
                const result = await Api().put('park', payload);

                const { data, success, error } = result.data;

                if (success) {
                    commit('setParkData', data);

                    return data;
                } else {
                    console.error(`[updatePark] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[updatePark] request error: ${err}`);
            }
        },
        digPlayerTreasure: async ({ commit, dispatch }, userId) => {
            try {
                const result = await Api().post(`/park/treasure/dig/${userId}`);

                const { data, success } = result.data;

                if (success) {
                    commit('setParkData', data.park);
                    dispatch('v2/user/update', data, { root: true });
                }
            } catch (e) {
                console.error(e);
            }
        },
        assignFavoritePet: async ({ commit, dispatch }, pet) => {
            try {
                const result = await Api().post(
                    `home-game-v10SimpleTreeSocial/set-pet/${pet}`,
                );

                const { data, success } = result.data;

                if (success) {
                    commit('setFavoritePet', pet);
                    dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        getFavoritePet: async ({ commit, rootGetters }) => {
            const favoritePet =
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial?.pet;

            if (favoritePet) {
                if (favoritePet === 'null') {
                    commit('setFavoritePet', null);
                } else {
                    commit('setFavoritePet', favoritePet);
                }
            }
        },
        levelUpPet: async ({ dispatch }, payload) => {
            try {
                const result = await Api().post(
                    `home-game/cards/exchange`,
                    payload,
                );

                const { data, success } = result.data;

                if (success) {
                    dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        hidePet: ({ state, commit }, pet) => {
            if (state.parkData?.hide?.includes(pet)) return;

            commit('hidePet', pet);
        },
        showPet: ({ commit }, pet) => {
            commit('showPet', pet);
        },
    },
};
