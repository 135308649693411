<template>
    <div class="parental-gate">
        <!-- Parental gate question or instruction -->
        <p>{{ question }}</p>

        <!-- Input field for answer -->
        <input
            v-model="userAnswer"
            type="number"
            :class="{ error: showError }"
            :placeholder="showError ? 'Wrong answer' : 'Enter answer'"
            class="answer-input"
            @keypress.enter="verifyAnswer"
        />

        <!-- Submit button -->
        <button class="submit-btn" :disabled="!checked" @click="verifyAnswer">
            <span>Submit</span>
        </button>

        <div class="checkbox">
            <input id="checkbox" v-model="checked" type="checkbox" />
            <label for="checkbox">
                I confirm I have parental permission for the purchase
            </label>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    data() {
        return {
            $v: useVuelidate(),
            question: '',
            correctAnswer: 0,
            userAnswer: null,
            showError: false,
            checked: false,
        };
    },
    created() {
        console.log('=== created ParentalGate');

        this.generateQuestion();
    },
    mounted() {
        new TrackingService().track(EVENTS.SEES_PARENTAL_GATE);
    },
    methods: {
        generateQuestion() {
            const N = Math.random() < 0.5 ? 2 : 3; // N is 2 or 3

            const A = Math.floor(Math.random() * 9) + 1; // A is a random number between 1 and 9

            // Ensuring A + B <= 11
            const maxB = 11 - A; // Maximum value for B based on the value of A

            const B = Math.floor(Math.random() * Math.min(maxB, 9)) + 1; // B is a random number between 1 and min(maxB, 9)

            this.question = { N, A, B };

            this.correctAnswer = N * (A + B);

            this.question = `${N} x (${A} + ${B}) = ?`;
        },
        verifyAnswer() {
            console.log('=== verifyAnswer', this.userAnswer);

            this.showError = true;

            if (
                this.userAnswer?.toString() === this.correctAnswer?.toString()
            ) {
                this.showError = false;

                this.generateQuestion();

                console.log('=== correct answer', this.userAnswer);

                new TrackingService().track(EVENTS.SOLVED_PARENTAL_GATE);

                this.$emit('gatePassed');
            }
            this.userAnswer = null;
        },
    },
};
</script>

<style lang="scss">
.parental-gate {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem;
    font-size: 1.5rem;
    font-style: normal;
    overflow: hidden;

    .answer-input {
        height: 42px;
        width: 100%;
        max-width: 226px;
        padding: 0;
        border: 1px solid #828282;
        border-bottom-width: 2px;
        border-radius: 10px;
        text-align: center;
        color: #333333;
        font-size: 18px;

        &::placeholder {
            font-size: 18px;
            font-style: normal;
            font-weight: bold;
        }
    }

    .offline-img {
        max-height: 40vh;
        max-width: 40vw;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    h3 {
        margin-bottom: 10px;
    }

    .error {
        border: 1px solid red;
        color: red;

        &::placeholder {
            color: #ee9999;
        }
    }

    .submit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 184px;
        height: 54px;
        margin: 20px;
        background: #10ff8f;
        border: 3px solid #00c437;
        box-shadow:
            0px -10px 0px 0px #00c437 inset,
            0px 10px 0px 0px rgba(255, 255, 255, 0.45) inset;
        filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.3));
        transform: skewX(-3deg);
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
            background: #08d25f;
        }

        &:disabled {
            background: #676867;
            border: 3px solid #a4a4a4;
            box-shadow:
                0px -10px 0px 0px #888888 inset,
                0px 10px 0px 0px rgba(255, 255, 255, 0.45) inset;

            & span {
                color: #e1e1e1;
            }
        }

        & span {
            transform: skewX(3deg);
            color: #007a36;
            text-align: center;
            font-family: $secondary-font-family;
            font-size: 26px;
            font-style: italic;
            font-weight: 800;
            line-height: 16px;
        }
    }
    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            width: 30px;
            height: 30px;
            border: none;
            margin-right: 1rem;
        }
    }
}
</style>
