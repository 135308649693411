/* global mixpanel */
export const UNAUTHORIZED_PREMIUM_SUBSCRIPTION_EVENTS = {
    PARENT_TRIES_TO_BUY_SUBSCRIPTION: 'unauthorizedSubscriptionAttempt',
    PARENT_FAILS_TO_BUY_SUBSCRIPTION: 'unauthorizedSubscriptionAttemptFail',
};

export const UNAUTHORIZED_PREMIUM_SUBSCRIPTION_MP_METHODS = {
    unauthorizedSubscriptionAttempt() {
        mixpanel.track('User tries to get premium subscription via link');
    },
    unauthorizedSubscriptionAttemptFail() {
        mixpanel.track('Premium subscription buying attempt fails via link');
    },
};
