<template>
    <div class="ui-dialog-background">
        <div class="ui-dialog-window">
            <h2 class="ui-dialog-message">
                {{ $store.state.v2.ui.dialogs.confirm.message }}
            </h2>
            <div class="ui-dialog-buttons">
                <MathButton
                    class="ui-dialog-button"
                    @click="resolveDialog(true)"
                >
                    Ok
                </MathButton>
                <MathButton
                    class="ui-dialog-button"
                    theme="plain"
                    @click="resolveDialog(false)"
                >
                    Cancel
                </MathButton>
            </div>
        </div>
    </div>
</template>

<script>
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';

export default {
    name: 'DialogConfirm',
    components: {
        MathButton,
    },
    methods: {
        resolveDialog(val) {
            this.$store.commit('v2/ui/resolveConfirm', val);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/components/components.ui-dialogs';
</style>
