export const patchConsoleWarn = () => {
    console.log('== patching console.warn ...');
    const originalConsoleWarn = console.warn;
    window.originalConsoleWarn = originalConsoleWarn;
    const warningMappings = {
        '[Vue warn]:': 'muting all vue logs on mobile.',
        // '[Vue warn]: Avoid app logic that relies on enumerating keys on a component instance':
        //     'Known vue component instance keys enumeration issue detected.',
        // '[Vue warn]: Extraneous non-props attributes':
        //     'Known vue extraneous non-props attributes issue.',
    };

    console.warn = function () {
        let args = Array.from(arguments);
        if (args.length > 0 && typeof args[0] === 'string') {
            for (const [verboseStart, shortMessage] of Object.entries(
                warningMappings,
            )) {
                if (args[0].startsWith(verboseStart)) {
                    args = [shortMessage]; // Replace with custom short message
                    break; // Exit the loop after finding the first match
                }
            }
        }
        originalConsoleWarn.apply(console, args);
    };
};
