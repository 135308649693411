import { AB_TESTS } from '@/core/abTests.js';
import { mapGetters } from 'vuex';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';
import CONSTANTS from '@/core/helpers/constants';

const isLocalUserFromUSA = !!localStorage
    .getItem('country_code3')
    ?.includes('USA');

export const tests = {
    [AB_TESTS.AI_TUTOR_ACCESS]: {
        filter: (user) => {
            const isStudent = user.role === 'student';
            return !!(
                isStudent &&
                user.country &&
                user.country.includes('USA')
            );
        },
    },
    [AB_TESTS.DEMO_VIDEO]: {
        filter: (user) => {
            const grades = user.teachingGrades;
            if (!grades || grades.length !== 1) return;
            const isGradeCorrect = [2, 6].includes(grades[0]);
            const isTeacher = user.role === 'teacher';
            return !!(
                isTeacher &&
                user.country &&
                (user.country.includes('USA') || isLocalUserFromUSA) &&
                isGradeCorrect
            );
        },
    },
    [AB_TESTS.HOME_PLAY_VIEW_2]: {
        filter: (user) => {
            return user.role === 'teacher';
        },
    },
    [AB_TESTS.REAL_DEMO]: {
        filter: (user) => {
            const grades = user.teachingGrades;
            if (!grades || grades.length !== 1) return;
            const isGradeCorrect = [3, 4, 5].includes(grades[0]);
            const isTeacher = user.role === 'teacher';
            return !!(
                isTeacher &&
                user.country &&
                (user.country.includes('USA') || isLocalUserFromUSA) &&
                isGradeCorrect
            );
        },
    },
    [AB_TESTS.TEACHER_DASHBOARD_10]: {
        filter: (user) => {
            const isTeacher = user.role === 'teacher';

            if (
                user.country &&
                (user.country.includes('USA') || isLocalUserFromUSA) &&
                isTeacher
            ) {
                return true;
            }

            console.log(user.country);

            return false;
        },
    },
    [AB_TESTS.TEACHER_DASHBOARD_11]: {
        filter: (user) => {
            const isTeacher = user.role === 'teacher';

            if (
                user.country &&
                (user.country.includes('USA') || isLocalUserFromUSA) &&
                isTeacher
            ) {
                return true;
            }

            console.log(user.country);

            return false;
        },
    },
    [AB_TESTS.TEACHER_DASHBOARD_12]: {
        filter: (user) => {
            const isTeacher = user.role === 'teacher';

            if (
                user.country &&
                (user.country.includes('USA') || isLocalUserFromUSA) &&
                isTeacher
            ) {
                return true;
            }

            console.log(user.country);

            return false;
        },
    },
    [AB_TESTS.TIME_LIMITED_PARK]: {
        filter: (user) => {
            const isStudent = user.role === 'student';

            const isInTestStates = [...CONSTANTS.TEST_GROUPS.GROUP_1].includes(
                user.country,
            );

            return isStudent && isInTestStates;
        },
    },
};
export const ABTesting = {
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        async getPublicTest(testId) {
            const response = await SecureApi().get(`ab-tests/public/${testId}`);

            const { success, data } = response.data;

            if (success && data) {
                return data;
            } else {
                return {};
            }
        },
        async initTest(testId) {
            // register user and/or check version
            const test = tests[testId];

            if (!test) {
                console.log('Test not found');
            }

            if (this.user) {
                // do not init test for users with disabled tracking
                const optedOut = await window.mixpanel.has_opted_out_tracking();

                if (optedOut) {
                    return false;
                }

                // should user be included in test
                /*
                // We need a better way to handle this.
                // since in QA this sets tests to be true for all roles,
                // even if the filter returns false for the role.
                const inQA =
                    window.location.hostname === 'localhost' ||
                    window.location.hostname === 'qa.99math.com';*/
                const inQA = false;

                // const isTeacher = this.user.role === 'teacher';

                const shouldIncludeUser = test.filter(this.user) || inQA;

                const hasTestAlready =
                    this.user &&
                    this.user.abTests &&
                    !!this.user.abTests[testId];

                console.log(
                    `[AbTest] Test: ${testId} Filter: ${shouldIncludeUser} HasTestAlready: ${hasTestAlready}`,
                );

                if (!shouldIncludeUser || hasTestAlready) {
                    return false;
                }

                const response = await SecureApi().post(
                    `ab-tests/subscribe/${testId}`,
                );

                const { success, data } = response.data;

                if (success && data) {
                    new TrackingService(this.user).track(EVENTS.ADD_AB_TEST, {
                        testId,
                        testValue: data.version,
                    });
                }

                await fetchAndUpdateUser(this.user);
            } else {
                console.log('User not found');
            }
        },
        async initTestQA(testId) {
            // register user and/or check version
            if (this.user) {
                // should user be included in test
                const response = await SecureApi().post(
                    `ab-tests/qa-subscribe/${testId}`,
                );

                const { success, data } = response.data;

                if (success && data) {
                    fetchAndUpdateUser(this.user);
                }
            } else {
                console.log('User not found');
            }
        },
        async resetAbTests() {
            if (this.user) {
                await SecureApi().post('ab-tests/qa-reset');

                fetchAndUpdateUser(this.user);
            }
        },
        // async createClassABTest(testId, classCode) {
        //     if (
        //         !this.user?.abTests[testId] &&
        //         typeof this.user !== 'undefined' &&
        //         classCode
        //     ) {
        //         const result = await SecureApi().post(
        //             `/ab-tests/class/${classCode}/add/${testId}`,
        //         );
        //
        //         const { data, success, error } = result.data;
        //
        //         if (success) {
        //             this.$store.commit('setAbTest', { [testId]: data.version });
        //         } else {
        //             // console.error(error);
        //         }
        //     }
        // },
    },
};
