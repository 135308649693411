/* global mixpanel */
export const MATH_RUNNER_MP_EVENTS = {
    CHOSE_GAME_MODE_IN_SOLO_TEST: 'inSoloTestChoseGameMode',
    CREATED_MATH_RUNNER: 'createdMathRunnerGame',
    JOINED_MATH_RUNNER: 'joinedMathRunnerGame',
    STARTED_PLAYING_RUNNER: 'startedPlayingRunner',
    FINISHED_RUNNER_GAME: 'finishedRunnerGame',
    CLICKED_PLAY_AGAIN_IN_RUNNER: 'clickedPlayAgainRunner',
    JOINED_BOT_HOSTED_RUNNER: 'joinedBotHostedRunnerGame',
    ATTEMPTED_TO_INVITE_FRIEND: 'attemptedToInviteFriend',
    ACCEPTED_FRIEND_REQUEST: 'acceptedFriendRequest',
    SET_FRIENDS_NUMBER: 'setFriendsNumber',
    SEES_OUT_OF_ENERGY: 'seesOutOfEnergy',
    CLICKS_CTA_IN_OUT_OF_ENERGY: 'clicksCtaInOutOfEnergy',
    SEES_INSTRUCTION: 'seesInstruction',
    CLICKS_CTA_IN_INSTRUCTION: 'clicksCtaInInstruction',
    ARENA_GAME_INVITATION: 'arenaGameInvitation',
    DECLINE_ARENA_GAME_INVITATION: 'declineArenaGameInvitation',
};

export const MATH_RUNNER_MP_METHODS = {
    inSoloTestChoseGameMode(choice) {
        mixpanel.track('Chose Game mode in solo test', {
            'Game mode': choice,
        });
    },
    createdMathRunnerGame({ gameCode, isSolo }) {
        mixpanel.track('Created Math Runner game', {
            'Game code': gameCode,
            'Solo game': isSolo,
        });
    },
    joinedMathRunnerGame({ gameCode }) {
        mixpanel.track('Joined math runner game', {
            'Game code': gameCode,
        });
    },
    startedPlayingRunner({
        gameCode,
        isSolo,
        isCreator,
        numberOfPlayers,
        totalPlayers,
        numberOfBots,
        hadOutsidePlayers,
        numberOfPlayersWithClassOutsideOfClass,
        numberOfPlayersFromSameClass,
        numberOfPlayersWithoutClass,
        gameMode,
    }) {
        mixpanel.track('Started playing Math Runner', {
            'Game code': gameCode,
            'Solo game': isSolo,
            'Is creator': isCreator,
            'Nr of players': numberOfPlayers,
            'Bots + players': totalPlayers,
            'Had outside players': hadOutsidePlayers,
            'Nr of bots': numberOfBots,
            'Nr of players with class outside the class':
                numberOfPlayersWithClassOutsideOfClass,
            'Nr of players from same class': numberOfPlayersFromSameClass,
            'Nr of players without a class': numberOfPlayersWithoutClass,
            'Game Mode': gameMode,
        });
    },
    finishedRunnerGame({ gameCode, isSolo, isCreator, xp }) {
        mixpanel.track('Finished Math Runner game', {
            'Game code': gameCode,
            'Solo game': isSolo,
            'XP gained': xp,
        });
    },
    clickedPlayAgainRunner({ gameCode, wasHost, isNewGameHost }) {
        mixpanel.track('Clicked play again', {
            'Game code': gameCode,
            'Was host': wasHost,
            'Is new game host': isNewGameHost,
        });
    },
    joinedBotHostedRunnerGame({ gameCode }) {
        mixpanel.track('Joined bot created game', {
            'Game code': gameCode,
        });
    },
    attemptedToInviteFriend({ success }) {
        mixpanel.track('Attempted to invite friend', {
            success,
        });
    },
    acceptedFriendRequest({ success }) {
        mixpanel.track('Accepted friend request', {
            success,
        });
    },
    setFriendsNumber({ friendsNumber }) {
        mixpanel.register({
            'friends number': friendsNumber,
        });
        mixpanel.people.set({
            'friends number': friendsNumber,
        });
    },
    seesOutOfEnergy() {
        mixpanel.track('Student sees arena out of energy modal');
    },
    clicksCtaInOutOfEnergy() {
        mixpanel.track('Student clicks cta in arena out of energy modal');
    },
    seesInstruction() {
        mixpanel.track('Student sees arena instruction modal');
    },
    clicksCtaInInstruction() {
        mixpanel.track('Student clicks cta in arena instruction modal');
    },
    arenaGameInvitation({ gameType }) {
        mixpanel.track('Arena game invitation', {
            gameType,
        });
    },
    declineArenaGameInvitation({ gameType }) {
        mixpanel.track('Player declines arena game', {
            gameType,
        });
    },
};
