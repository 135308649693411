/* global mixpanel */
export const TEACHER_ONBOARDING_MP_EVENTS = {
    TEACHER_ONBOARDING_JOINS_GAME: 'teacherOnboardingJoinGame',
    TEACHER_ONBOARDING_START_GAME: 'teacherOnboardingStartGame',
    TEACHER_ONBOARDING_FINISH_ROUND1: 'teacherOnboardingFinishRound1',
    TEACHER_ONBOARDING_START_ROUND2: 'teacherOnboardingStartRound2',
    TEACHER_ONBOARDING_FINISH_GAME: 'teacherOnboardingFinishGame',
    TEACHER_ONBOARDING_VIEW_GAME_REPORT: 'teacherOnboardingViewGameReport',
    TEACHER_ONBOARDING_FINISHED: 'teacherOnboardingFinished',
    TEACHER_ONBOARDING_MODALS_1: 'teacherOnboardingModals1',
    TEACHER_ONBOARDING_MODALS_2: 'teacherOnboardingModals2',
    TEACHER_ONBOARDING_MODALS_3: 'teacherOnboardingModals3',
    TEACHER_ONBOARDING_MODALS_4: 'teacherOnboardingModals4',
    TEACHER_ONBOARDING_MODALS_5: 'teacherOnboardingModals5',
    TEACHER_ONBOARDING_MODALS_6: 'teacherOnboardingModals6',
    TEACHER_ONBOARDING_MODALS_FINISHED: 'teacherOnboardingModalsFinished',
    TEACHER_ONBOARDING_MODALS_CLOSED: 'teacherOnboardingModalsClosed',
    TEACHER_ONBOARDING_MODALS_PREVIOUS_SLIDE:
        'teacherOnboardingModalsPreviousSlide',
    TEACHER_ONBOARDING_MODALS_SEE_AGAIN: 'teacherOnboardingModalsSeeAgain',
    TEACHER_ONBOARDING_MODALS_EXPLORE_ON_MY_OWN:
        'teacherOnboardingModalsExploreOnyMyOwn',
    TEACHER_CLICKED_HOW_TO_BLOG: 'teacherOpenedHowToBlog',
    TEACHER_ONBOARDING_MOBILE_MODALS_CLICKED_LETS_GO:
        'teacherOnboardingMobileModalsClickedLetsGo',
    TEACHER_ONBOARDING_INTRO_VID_CLICKED_CTA_AFTER_VIDEO:
        'teacherOnboardingIntroVideoClickedCtaAfterVideo',
    TEACHER_ONBOARDING_DEMO_CLICKED_SEE_DEMO:
        'teacherOnboardingDemoClickedSeeDemo',
    TEACHER_ONBOARDING_DEMO_WATCHED_VIDEO: 'teacherOnboardingDemoWatchedVideo',
    TEACHER_ONBOARDING_DEMO_CLICKED_WATCH_VIDEO_AGAIN:
        'teacherOnboardingDemoClickedWatchVideoAgain',
    TEACHER_ONBOARDING_DEMO_CLICKED_PLAY_LIVE_IN_CLASS:
        'teacherOnboardingDemoClickedPlayLiveInClass',
    TEACHER_ONBOARDING_DEMO_CLICKED_PLAY_DEMO_AS_STUDENT:
        'teacherOnboardingDemoClickedPlayDemoAsStudent',
    TEACHER_ONBOARDING_DEMO_COMPLETED_DEMO:
        'teacherOnboardingDemoCompletedDemo',
    TEACHER_ONBOARDING_DEMO_SAW_DEMO_REPORT:
        'teacherOnboardingDemoSawDemoReport',
    TEACHER_ONBOARDING_DEMO_CLICKED_WATCH_VIDEO:
        'teacherOnboardingDemoClickedWatchVideo',
    TEACHER_ONBOARDING_DEMO_CLICKED_START_DEMO:
        'teacherOnboardingDemoClickedStartDemo',
    TEACHER_ONBOARDING_SEES_REAL_SKILL_DEMO_MODAL:
        'teacherOnboardingSeesRealSkillDemoModal',
    TEACHER_ONBOARDING_PLAYS_REAL_SKILL_DEMO:
        'teacherOnboardingPlaysRealSkillDemo',
    TEACHER_ONBOARDING_DEMO_JOINED_STUDENT:
        'teacherOnboardingDemoJoinedStudent',
    TEACHER_ONBOARDING_DEMO_STARTED_DEMO: 'teacherOnboardingDemoStartedDemo',
    TEACHER_ONBOARDING_DEMO_SAW_RESULTS: 'teacherOnboardingDemoSawResults',
    TEACHER_ONBOARDING_DEMO_ENDED_DEMO: 'teacherOnboardingDemoEndedDemo',
    TEACHER_ONBOARDING_DEMO_CLICKED_SEE_REPORT:
        'teacherOnboardingDemoClickedSeeReport',
    TEACHER_ONBOARDING_DEMO_CLICKED_EXIT_DEMO:
        'teacherOnboardingDemoClickedExitDemo',

    TEACHER_STATS_GET_MORE_STUDENTS: 'teacherStatsGetMoreStudents',
    TEACHER_STATS_FEEDBACK_SENT: 'teacherStatsFeedbackSent',
};

export const TEACHER_ONBOARDING_METHODS = {
    teacherOnboardingJoinGame() {
        mixpanel.track('T-OB: enters code and joins game');
    },
    teacherOnboardingStartGame() {
        mixpanel.track('T-OB: starts game');
    },
    teacherOnboardingFinishRound1() {
        mixpanel.track('T-OB: finishes 1st round');
    },
    teacherOnboardingStartRound2() {
        mixpanel.track('T-OB: starts 2nd round');
    },
    teacherOnboardingFinishGame() {
        mixpanel.track('T-OB: finishes game');
    },
    teacherOnboardingViewGameReport() {
        mixpanel.track('T-OB: views game report');
    },
    teacherOnboardingFinished() {
        mixpanel.track('T-OB: finished');
    },
    teacherOnboardingModals1() {
        mixpanel.track('T-OB: modals - saw 1st modal');
    },
    teacherOnboardingModals2() {
        mixpanel.track('T-OB: modals - saw 2nd modal');
    },
    teacherOnboardingModals3() {
        mixpanel.track('T-OB: modals - saw 3rd modal');
    },
    teacherOnboardingModals4() {
        mixpanel.track('T-OB: modals - saw 4th modal');
    },
    teacherOnboardingModals5() {
        mixpanel.track('T-OB: modals - saw 5th modal');
    },
    teacherOnboardingModals6() {
        mixpanel.track('T-OB: modals - saw 6th modal');
    },
    teacherOnboardingModalsFinished(time) {
        mixpanel.track('T-OB: modals - finished', {
            'Watch time': time,
        });
    },
    teacherOnboardingModalsClosed(time) {
        mixpanel.track('T-OB: modals - closed', {
            'Watch time': time,
        });
    },
    teacherOnboardingModalsPreviousSlide() {
        mixpanel.track('T-OB: modals - previous slide');
    },
    teacherOnboardingModalsSeeAgain() {
        mixpanel.track('T-OB: modals - clicked to see again');
    },
    teacherOnboardingModalsExploreOnyMyOwn(time) {
        mixpanel.track('T-OB: modals - clicked explore on my own', {
            'Watch time': time,
        });
    },
    teacherOpenedHowToBlog({ location }) {
        mixpanel.track('T-OB: Teacher clicked how to blog', {
            Location: location,
        });
    },
    teacherOnboardingMobileModalsClickedLetsGo(time) {
        mixpanel.track('T-OB: mobile modals - clicked lets go', {
            'Watch time': time,
        });
    },
    teacherOnboardingIntroVideoClickedCtaAfterVideo(time) {
        mixpanel.track('T-OB: modals - clicked cta after video', {
            'Watch time': time,
        });
    },
    teacherOnboardingDemoClickedSeeDemo(locationInUI) {
        mixpanel.track('T-OB: Clicked "Play demo game"', {
            Location: locationInUI,
        });
    },
    teacherOnboardingDemoClickedWatchVideo(locationInUI) {
        mixpanel.track('T-OB: Clicked "Video overview"', {
            Location: locationInUI,
        });
    },
    teacherOnboardingDemoWatchedVideo() {
        mixpanel.track('T-OB: Watched video');
    },
    teacherOnboardingDemoClickedWatchVideoAgain(locationInUI) {
        mixpanel.track('T-OB: Clicked "Watch video again"', {
            Location: locationInUI,
        });
    },
    teacherOnboardingDemoClickedPlayLiveInClass() {
        mixpanel.track('T-OB: Clicked "Play live in class"');
    },
    teacherOnboardingDemoClickedPlayDemoAsStudent() {
        mixpanel.track('T-OB: Clicked "Play demo as student"');
    },
    teacherOnboardingDemoCompletedDemo() {
        mixpanel.track('T-OB: Completed demo');
    },
    teacherOnboardingDemoSawDemoReport() {
        mixpanel.track('T-OB: Saw demo report');
    },
    teacherOnboardingDemoClickedStartDemo() {
        mixpanel.track('T-Demo: Clicked start demo');
    },
    teacherOnboardingSeesRealSkillDemoModal() {
        mixpanel.track('T-Demo: Sees real skill demo modal');
    },
    teacherOnboardingPlaysRealSkillDemo() {
        mixpanel.track('T-Demo: Plays real skill demo');
    },
    teacherOnboardingDemoJoinedStudent() {
        mixpanel.track('T-Demo: Joined as a student');
    },
    teacherOnboardingDemoStartedDemo() {
        mixpanel.track('T-Demo: Started demo');
    },
    teacherOnboardingDemoSawResults() {
        mixpanel.track('T-Demo: Saw results');
    },
    teacherOnboardingDemoEndedDemo() {
        mixpanel.track('T-Demo: Ended demo');
    },
    teacherOnboardingDemoClickedSeeReport() {
        mixpanel.track('T-Demo: Clicked on see report');
    },
    teacherOnboardingDemoClickedExitDemo() {
        mixpanel.track('T-Demo: Exited demo');
    },
    teacherStatsGetMoreStudents() {
        mixpanel.track('Teacher Stats: Get more student clicked');
    },
    teacherStatsFeedbackSent(feedbackText) {
        mixpanel.track('Teacher submitted feedback', {
            Feedback: feedbackText,
        });
    },
};
