import { i18n } from '@/lang/translator';
export const LocalizeTimeConversion = {
    methods: {
        translateUnit(unit, useLongVersion = false) {
            if (useLongVersion) {
                return i18n.t(`host.create.time.units.${unit}Long`);
            }

            return i18n.t(`host.create.time.units.${unit}`);
        },
        localizeUnit(unit) {
            const units = unit.split(' <-> ');

            return `${this.translateUnit(
                units[0],
                true,
            )} <-> ${this.translateUnit(units[1], true)}`;
        },
        localizeLevelOption(option) {
            const translateUnit = (unit) => this.translateUnit(unit);

            // sec -> a/b min
            if (
                /^([a-z]{0,3}) -> ([a-z]{0,3})(\/)([a-z]{0,3}) ([a-z]{0,3})$/.test(
                    option,
                )
            ) {
                const words = option.split(' -> ');

                const secondUnit = words[1].split(' ');

                return `${translateUnit(words[0])} -> a/b ${translateUnit(
                    secondUnit[1],
                )} `;
            }

            // a/b min -> sec
            if (
                /^([a-z]{0,3})(\/)([a-z]{0,3}) ([a-z]{0,3}) -> ([a-z]{0,3})$/.test(
                    option,
                )
            ) {
                const words = option.split(' -> ');

                const firstUnit = words[0].split(' ');

                return `a/b ${translateUnit(firstUnit[1])} -> ${translateUnit(
                    words[1],
                )}`;
            }

            // h+min -> min
            if (/^([a-z]{0,3})(\+)([a-z]{0,3}) -> ([a-z]{0,3})$/.test(option)) {
                const words = option.split(' -> ');

                const firstUnits = words[0].split('+');

                return `${translateUnit(firstUnits[0])}+${translateUnit(
                    firstUnits[1],
                )} -> ${translateUnit(words[1])}`;
            }

            // min -> h+min
            if (/^([a-z]{0,3}) -> ([a-z]{0,3})(\+)([a-z]{0,3})$/.test(option)) {
                const words = option.split(' -> ');

                const secondUnits = words[1].split('+');

                return `${translateUnit(words[0])} -> ${translateUnit(
                    secondUnits[0],
                )}+${translateUnit(secondUnits[1])}`;
            }

            // min -> s
            if (/^([a-z]{0,3}) -> ([a-z]{0,3})$/.test(option)) {
                const words = option.split(' -> ');

                return `${translateUnit(words[0])} -> ${translateUnit(
                    words[1],
                )}`;
            }

            console.error(
                'lLO error: no matching option. Structure has changed: ' +
                    option,
            );
        },
    },
};
