export const OUR_FB_GROUP_COMMUNITY_LINKS = {
    en: 'https://www.facebook.com/groups/317824748946434',
    et: 'https://www.facebook.com/groups/209396336409336',
    es: 'https://www.facebook.com/groups/2845072828952333',
    pt: 'https://www.facebook.com/groups/2927112527415594',
};

export const OUR_TWITTER_LINKS = {
    en: 'https://twitter.com/99mathgame',
    pt: 'https://twitter.com/99mathBrasil',
};

export const OUR_FB_PAGE_LINKS = {
    en: 'https://www.facebook.com/99math',
    es: 'https://www.facebook.com/99math.latam',
    pt: 'https://www.facebook.com/99math.brasil',
};

export const OUR_PRIVACY_POLICY = {
    en: 'https://legal.99math.com/privacy-policy',
};

export const OUR_TERMS_OF_USE = {
    en: 'https://legal.99math.com/terms-of-use',
};

export const MOBILE_APP_LINKS = {
    ios: 'https://apps.apple.com/pl/app/99math-master-math-facts/id6472210505',
    android: 'https://play.google.com/store/apps/details?id=com.math99.mobile',
};
