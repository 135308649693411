import EventEmitter from 'events';
import Api from '@/core/services/Api';
import moment from 'moment';
import { io } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';
import { objectHash, randomIntFromRange } from '@/core/helpers/utils';

export class Bot extends EventEmitter {
    constructor(name, avatar) {
        super();
        this._gameCode = null;
        this._name = name;
        this._avatar = avatar;
        this._socket = null;
        this._joinResolve = null;
        this._client = null;
        this._api = Api();
        this._play = false;
        this._questions = [];
        this._info = null;
    }

    id() {
        return this._client.clientId;
    }

    // uncomment for debugging
    log(/*...msg*/) {
        // console.log(`BOT [${this._name}] log:`, ...msg);
    }

    error(...msg) {
        console.error(`BOT [${this._name}] error:`, ...msg);
    }

    async join(gameCode) {
        this._gameCode = gameCode;
        await this.waitMs(this.randomInt(50, 200));
        const canJoin = await this._api.get(`/join/${gameCode}`);
        if (
            !canJoin.data ||
            !canJoin.data.success ||
            !canJoin.data.data ||
            !canJoin.data.data.canJoin
        ) {
            this.error('cant join game', gameCode);
            return false;
        }
        await this.waitMs(this.randomInt(50, 200));
        const joinData = await this._api.post(`/join/${gameCode}`, {
            joined: moment().format(),
            name: this._name,
            userId: this._name,
            bot: true,
            avatar: this._avatar,
        });

        if (!joinData.data.success || !joinData.data.data) {
            this.error('join game error', joinData);
            return false;
        }
        this.log('joined');
        this._info = joinData.data.data.info;
        this._gameCode =
            joinData.data.data.info?.code || joinData.data.data.gameCode;
        return joinData;

        //  Call these in your override.

        // try {
        //     this._client = jwtDecode(joinData.data.data.token);
        // } catch (jwtError) {
        //     this.error('jwt decoding error', jwtError);
        //     return false;
        // }
        // this._wsIoUrl = joinData.data.data.wsServerUrl || null;
        // let ioUrl =
        //     this._wsIoUrl ||
        //     import.meta.env[`VITE_${this._client.region}_TBL_API_URL`];
        // if (!ioUrl.endsWith('/')) ioUrl += '/';
        // ioUrl += this._gameMode;
        // this._socket = io(ioUrl, {
        //     autoConnect: false,
        //     transports: ['websocket'],
        //     query: {
        //         jwt: joinData.data.data.token,
        //     },
        //     forceNew: true,
        // });
        // this._socket.on('connect', async () => {
        //     this.log('connected');
        //     if (this._joinResolve) {
        //         this._socket.emit('selectedAvatar', this._avatar);
        //         this._joinResolve(true);
        //         this._joinResolve = null;
        //     }
        // });
        // this._socket.on('disconnect', () => {
        //     this.log('disconnected');
        // });
        // await this.waitMs(this.randomInt(50, 200));
        // this._socket.open();
        // return new Promise((resolve) => {
        //     this._joinResolve = resolve;
        // });
    }

    close() {
        if (this._socket) {
            this._socket.offAny();
            this._socket.close();
            this._socket = null;
        }
    }

    waitMs(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    randomInt(from, to) {
        return randomIntFromRange(from, to);
    }

    generateRandomAnswer(question) {
        return this.randomInt(0, 100) > 25
            ? question.answer
            : question.answer + 1;
    }
}
