import io from 'socket.io-client';
const MATH99_REGIONS = (import.meta.env.VITE_MATH99_REGIONS || '').split(' ');

/**
 *  module designed to manage websockets sockets and managers
 *  it has pre-built functionality for jwt authorization via query
 *  but can work with empty jwt if backend connection doesn't require it
 *
 *  to open socket you need define io manager: dispatch('io', { code, url, jwt? })
 *  and open socket: socket = dispatch('socket', { code, ioCode, nsp })
 *  codes are user defined string keys to get later access to required socket or manager
 *
 *  you can also use additional params and open socket with io manager in single call:
 *  socket = dispatch('socket', { code, ioCode, nsp, url, jwt? })
 *
 *  later you can get required socket by it's code from state:
 *  socket = store.state.sockets[code].socket
 */
export default {
    namespaced: true,
    state: {
        // io managers for different jwt connections
        io: {},
        // sockets
        sockets: {},
    },
    mutations: {
        setIo: (state, { io, region }) => {
            state.io[region] = io;
        },
        io: (state, { code, url, jwt, manager }) => {
            if (!state.io[code]) {
                state.io[code] = {
                    reconnects: 0,
                    manager,
                    jwt,
                };
            } else {
                state.io[code].jwt = jwt;

                state.io[code].url = url;
            }
        },
        ioJwt: (state, { ioCode, jwt }) => {
            if (!state.io[ioCode]) return;

            state.io[ioCode].jwt = jwt || '';
        },
        ioUpdate: (state, ioCode) => {
            if (!state.io[ioCode]) return;

            state.io[ioCode].manager.io.opts.transports = ['websocket'];

            state.io[ioCode].manager.io.opts.query.CC =
                state.io[ioCode].reconnects;

            state.io[ioCode].manager.io.opts.query.jwt = state.io[ioCode].jwt;
        },
        ioReconnect: (state, ioCode) => {
            if (!state.io[ioCode]) return;

            state.io[ioCode].reconnects++;
        },
        socket: (
            state,
            { code, ioCode, nsp = '', open = false, cache = 'none' },
        ) => {
            if (!state.io[ioCode]) return;

            if (state.sockets[code]) return state.sockets[code];

            const socket = state.io[ioCode].manager.io.socket(`/${nsp}`, {
                forceNew: true,
            });

            state.sockets[code] = {
                open,
                connected: false,
                code,
                ioCode,
                socket,
                cache,
                cacheConnect: false,
                reconnects: 0,
            };

            socket.on('connect', () => {
                state.sockets[code].connected = true;

                state.sockets[code].reconnects = 0;
            });

            socket.on('disconnect', () => {
                state.sockets[code].connected = false;
            });

            socket.on('connect_error', () => {
                state.sockets[code].reconnects =
                    state.sockets[code].reconnects + 1;
            });
        },
        close: (state, { code, remove = true, off = true }) => {
            if (!state.sockets[code]) return;

            state.sockets[code].socket.close();

            if (off) {
                state.sockets[code].socket.off();
            }

            if (remove) {
                delete state.sockets[code];
            }
        },
        healthCheckRegion: (state, { region, data }) => {
            if (!state.healthCheck) {
                state.healthCheck = {};
            }

            state.healthCheck[region] = data;
        },
    },
    actions: {
        leave: (store) => {
            console.debug('store::io::leave');

            const socket = store.getters.currentSocket?.socket;

            if (socket) {
                socket.emit('leaveGame');
                socket.close();
                socket.off();
            }
        },
        io: (store, { code, url, jwt = '' }) => {
            const manager = io(url, {
                autoConnect: false,
                transports: ['websocket'],
                cors: [url],
                // query fields JWT and CC are applied in store/modules/io.js
                // but query object should be defined for easy later access
                // sending JWT in query, cuz
                // "In a browser environment, the extraHeaders option will be ignored if you only enable the WebSocket transport, since the WebSocket API in the browser does not allow providing custom headers." (https://socket.io/docs/v4/client-options/#extraheaders)
                // but token should be accessible on allowRequest
                query: {},
                multiplex: false,
                forceNew: true,
            });

            manager.on('reconnect_attempt', () => {
                store.commit('ioReconnect', code);
                store.commit('ioUpdate', code);
            });

            store.commit('io', { code, url, jwt, manager });

            store.commit('ioUpdate', code);
        },
        /**
         *  open socket
         */
        open: async (
            store,
            { code, ioCode, url, nsp = '', jwt = undefined, cache = 'none' },
        ) => {
            if (!store.state.io[ioCode]) {
                if (!url) {
                    console.error(
                        'store::io::socket error - requested socket without io or io url',
                        code,
                        ioCode,
                        url,
                        nsp,
                        jwt,
                    );

                    return null;
                }

                store.commit('io', { code: ioCode, url, jwt });
            } else {
                if (typeof jwt !== 'undefined') {
                    store.commit('ioJwt', { ioCode, jwt });
                }
            }

            store.commit('ioUpdate', code);

            store.commit('socket', { code, ioCode, nsp, cache });

            return store.state.sockets[code].socket;
        },
        close: (store, data) => {
            console.debug('store::io::close', data);

            if (!store.state.sockets[data.code]) return;

            const defs = {
                code: '',
                remove: true,
                off: true,
            };

            const opts =
                typeof data === 'string'
                    ? { ...defs, code: data }
                    : { ...defs, ...data };

            store.commit('close', opts);
        },
        healthCheck: (store, nsp) => {
            if (!nsp) {
                nsp = 'live';
            }

            const connects = [];

            MATH99_REGIONS.forEach((region) => {
                console.log('healthCheck', nsp, region);

                const hc = {
                    socket: null,
                    resolve: null,
                };

                const socket = store.state.io[region].socket(`/${nsp}`, {
                    forceNew: true,
                });

                hc.socket = socket;

                store.state.io[region].opts.query.jwt = 'healthcheck';

                socket.on('connect', () => {
                    // hc.connected = true;
                    if (hc.resolve) {
                        hc.resolve();

                        hc.resolve = null;
                    }

                    store.commit('healthCheckRegion', {
                        region,
                        data: hc,
                    });

                    store.commit('setWebsocketConnectionStatus', true, {
                        root: true,
                    });
                });
                connects.push(
                    new Promise((resolve) => {
                        hc.resolve = resolve;

                        store.commit('healthCheckRegion', {
                            region,
                            data: hc,
                        });

                        socket.open();
                    }),
                );
            });

            return Promise.all(connects);
        },
        emit: (store, payload) => {
            if (!store.getters.currentSocket?.socket) {
                console.debug('store::io emit error: NO SOCKET', payload);

                return;
            }

            let args = [];

            if (typeof payload === 'string') {
                args.push(payload);
            }

            if (typeof payload === 'object') {
                if (payload.data) {
                    if (Array.isArray(payload.data)) {
                        args = [...payload.data];
                    } else {
                        args = [payload.data];
                    }
                }

                args.unshift(payload.event);
            }

            store.getters.currentSocket?.socket?.emit(...args);
        },
    },
    getters: {
        currentSocket(state, getters, rootState) {
            return state.sockets?.[rootState?.v2?.game?.code] || null;
        },
    },
};
