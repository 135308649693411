import { SafeArea } from 'capacitor-plugin-safe-area';
import { ScreenOrientation } from '@capacitor/screen-orientation';

export async function fixSafeArea(isAndroid) {
    const orientationToNotchSide = {
        'landscape-primary': 'left',
        'landscape-secondary': 'right',
        'portrait-primary': 'top',
        'portrait-secondary': 'bottom',
    };
    const maxInsets = {
        'landscape-primary': {},
        'landscape-secondary': {},
        'portrait-primary': {},
        'portrait-secondary': {},
    };

    const previousInsets = {};

    const fixInsets = async ({ insets }, screenOrientation) => {
        // one problem with default safe areas on iOS is that in horizontal they
        // are symmetric mirroring notch on the other side - this is too much space wasted

        const { type: orientation } =
            screenOrientation || (await ScreenOrientation.orientation());

        const notchSide = orientationToNotchSide[orientation];
        insets[notchSide] = Math.max(
            // apparently the plugin intermittently does not consider the max of both values
            insets[notchSide],
            (await SafeArea.getStatusBarHeight()).statusBarHeight,
        );

        if (orientation.match(/landscape/)) {
            insets.top = 0; // it happens that the plugin reports top inset in landscape
            if (orientation.match(/-primary/)) {
                // notch on the left
                insets.right = Math.floor(insets.left * 0.25);
            } else {
                // notch on the right
                insets.left = Math.floor(insets.right * 0.25);
            }
        } else if (insets.top > 0 && isAndroid) {
            // portrait tweaks for android

            insets.top += 4;
        }

        // Update maxInsets for the current orientation
        for (const [key, value] of Object.entries(insets)) {
            if (
                !maxInsets[orientation][key] ||
                value > maxInsets[orientation][key]
            ) {
                maxInsets[orientation][key] = value;
            }
        }

        // Use maxInsets for the current orientation
        const maxInsetsForOrientation = maxInsets[orientation];

        if (
            Object.values(maxInsetsForOrientation).every(
                (v) => previousInsets[v] === v,
            )
        ) {
            return;
        }
        console.log(
            '=== setting new safe area insets:',
            orientation,
            maxInsetsForOrientation,
        );

        for (const [key, value] of Object.entries(maxInsetsForOrientation)) {
            document.documentElement.style.setProperty(
                `--safe-area-inset-${key}`,
                `${value}px`,
            );
        }
    };

    await fixInsets(await SafeArea.getSafeAreaInsets(), screen?.orientation);

    // apparently chrome and safari fires events differently and below is the way to handle it
    // if (isAndroid) {
    //     SafeArea.addListener('safeAreaChanged', async (insets) =>
    //         setTimeout(
    //             async () =>
    //                 fixInsets(
    //                     await SafeArea.getSafeAreaInsets(),
    //                     screen?.orientation,
    //                 ),
    //             50,
    //         ),
    //     );
    // } else {
    await ScreenOrientation.addListener(
        'screenOrientationChange',
        async (orientation) =>
            fixInsets(
                await SafeArea.getSafeAreaInsets(),
                orientation, // || screen?.orientation,
            ),
    );
    // }
}

window.checkSafeAreaInset = function getSafeAreaInset(direction) {
    // Create a new dummy element
    const dummyElement = document.createElement('div');

    // Apply a style using the env() function to the dummy element
    dummyElement.style.setProperty(
        `padding-${direction}`,
        `var(--safe-area-inset-${direction})`,
    );

    // Append the dummy element to the body
    document.body.appendChild(dummyElement);

    // Get the computed style of the dummy element
    const computedStyle = window.getComputedStyle(dummyElement);

    // Get the value of the padding property
    const padding = computedStyle.getPropertyValue(`padding-${direction}`);

    // Remove the dummy element from the body
    document.body.removeChild(dummyElement);

    // Return the computed padding value
    return padding;
};
