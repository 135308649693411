import moment from 'moment';
import lodash from 'lodash';
import {
    OUR_FB_GROUP_COMMUNITY_LINKS,
    OUR_FB_PAGE_LINKS,
    OUR_PRIVACY_POLICY,
    OUR_TERMS_OF_USE,
    OUR_TWITTER_LINKS,
} from '@/core/helpers/99math-links';
import { DEFAULT_LANGUAGE } from '@/lang/languages';
import CONSTANTS from '@/core/helpers/constants';
import Api from '@/core/services/Api';
import { createVNode, render } from 'vue';
import store from '@/store/index';
import languageSwitcherGetters from '@/core/components/LanguageSwitcher/store/LanguageSwitcherGetters';

export const getLocale = () => {
    const user = store.getters.user;

    return (
        (user && user.locale) ||
        languageSwitcherGetters.getClientBrowserLanguage()
    );
};

export const clientJwt = () => {
    const userToken = localStorage.getItem('authToken');

    const guestToken = localStorage.getItem('guestToken');

    return userToken || guestToken;
};

// by https://stackoverflow.com/questions/39282873/object-hasownproperty-yields-the-eslint-no-prototype-builtins-error-how-to
export const hasOwnProp = (src, field) =>
    !!Object.getOwnPropertyDescriptor(src, field);

// https://stackoverflow.com/a/43467144
export const isValidUrl = (string) => {
    try {
        new URL(string);

        return true;
    } catch (_) {
        return false;
    }
};

export const getLocalTimezone = () => {
    const timezoneOffset = new Date().getTimezoneOffset();

    return (timezoneOffset / 60) * -1;
};

export const sortPointsDesc = (a, b) => {
    if (a.score > b.score) {
        return -1;
    }

    if (a.score < b.score) {
        return 1;
    }

    return 0;
};

export const calculateStatsFromSPMatch = (match) => {
    const correctAnswersCount = match.results.reduce(
        (accumulator, result) =>
            result.correct ? accumulator + 1 : accumulator,
        0,
    );

    return {
        score: correctAnswersCount,
        correctAnswers: correctAnswersCount,
        wrongAnswers: match.results.length - correctAnswersCount,
        playedTime: match.playedTime,
        results: match.results.map((result) => {
            return { questionBody: result };
        }),
    };
};

export const getMatchesByPlayer = (playerInfo) => {
    const rounds = {};

    if (!playerInfo) {
        return [];
    }
    playerInfo.results.forEach((result) => {
        if (!rounds[result.sessionInfoId]) {
            rounds[result.sessionInfoId] = [];
        }

        rounds[result.sessionInfoId].push(result);
    });
    playerInfo.playerSessions = [...playerInfo.playerSessions].map(
        (playerSession) => {
            const round = rounds[playerSession.sessionId] || [];

            playerSession.results = [...round];

            playerSession.playedTime = playerInfo.playedTime;

            playerSession.score = playerSession.results.reduce(
                (result, acum) => (result.correct ? 1 : 0) + acum,
                0,
            );

            return playerSession;
        },
    );

    return [...playerInfo.playerSessions];
};

export const calculatePlayerTasksPerMinute = (game, player, gameType) => {
    const score = player.score || 0;

    if (
        game.flag === 'spg' ||
        game.gameType === 'spg' ||
        game.constructor.modelName === 'SelfPacedGame' ||
        gameType === 'spg'
    ) {
        const { gameVariant, options } = game;

        if (gameVariant === 'questions') {
            // The playedTime can be zero, so that's why the fallback.
            return Math.round((60 / player.playedTime) * score) || 0;
        }
        if (gameVariant === 'time') {
            return Math.round((1 / options.duration) * score);
        }

        throw new Error('Unsupported SPG options type');
    }

    // LIVE
    return Math.round(
        (60 / (game.round.rounds * game.round.roundDuration)) * score,
    );
};

export const createClassScore = (game, gameType = '') => {
    // Class Score is sum of Skill Level of all players in a game
    // Skill is calculated as tasks per minute
    // we should fetch last 50 games
    // const game = { ...gameInput };

    let skillLevels = [];

    const isSelfPaced = game.flag === 'spg' || gameType === 'spg';

    if (game.creatorRole === 'teacher' && isSelfPaced) {
        // for older games pre SPG sessions the players array is used as default
        skillLevels = game.players.map((player) => {
            let bestMatch = {
                score: player.score || 0,
                playedTime: player.playedTime || 0,
            };

            if (player.playerSessions && player.playerSessions.length) {
                bestMatch = getMatchesByPlayer(player)
                    .map((match) => {
                        return {
                            ...calculateStatsFromSPMatch(match),
                        };
                    })
                    .sort(sortPointsDesc)[0];
            }

            return Math.round(
                calculatePlayerTasksPerMinute(game, bestMatch, gameType),
            );
        });
    } else {
        for (const player of game.players) {
            skillLevels.push(
                Math.round(
                    calculatePlayerTasksPerMinute(game, player, gameType),
                ),
            );
        }
    }

    const classScore = skillLevels.reduce((a, b) => a + b, 0) || 0;

    return classScore * CONSTANTS.CLASS_SCORE_MULTIPLIER;
};

export const getFormattedLocalTime = () => {
    const now = new Date();

    const formattedHour =
        now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();

    const formattedMinutes =
        now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();

    return `${formattedHour}${formattedMinutes}`;
};

export const getDate = () => {
    return moment().format('DD-MM-YYYY');
};

export function formatShortDate(date, locale) {
    return moment(date).format('MMM D YYYY', locale);
}

export function formatDate(date, locale) {
    switch (locale) {
        case 'et':
            moment.updateLocale('et', {
                calendar: {
                    lastDay: '[Eile kell] LT',
                    sameDay: '[Täna kell] LT',
                    nextDay: '[Homme kell] LT',
                    lastWeek: '[Eelmine] dddd [kell] LT',
                    nextWeek: 'dddd [kell] LT',
                    sameElse: 'L',
                },
            });
            break;

        case 'es':
            moment.updateLocale('es', {
                calendar: {
                    lastDay: '[Ayer a las] LT',
                    sameDay: '[Hoy a las] LT',
                    nextDay: '[Mañana a las] LT',
                    lastWeek: '[El] dddd [pasado a las] LT',
                    nextWeek: 'dddd [a las] LT',
                    sameElse: 'L',
                },
            });
            break;

        default:
            moment.updateLocale('en', {
                calendar: {
                    lastDay: '[Yesterday at] LT',
                    sameDay: '[Today at] LT',
                    nextDay: '[Tomorrow at] LT',
                    lastWeek: '[Last] dddd [at] LT',
                    nextWeek: 'dddd [at] LT',
                    sameElse: 'L',
                },
            });
            break;
    }

    return moment(date).calendar();
}

export function toFloat(num, decimalPlaces) {
    if (isNaN(num)) {
        return NaN;
    }

    const base = 10;

    const pow = base ** decimalPlaces;

    const rounded = Math.round(num * pow) / pow;

    return rounded.toFixed(decimalPlaces);
}

export function isObject(arg) {
    return Object.prototype.toString.call(arg) === '[object Object]';
}

export function isSameByValue(arg1, arg2) {
    return lodash.isEqual(arg1, arg2);
}

export function allowNumbers(event) {
    event = event ? event : window.event;

    const charCode = event.which ? event.which : event.keyCode;

    const COMMA = 44;

    const MINUS = 45;

    const PERIOD = 46;

    const NUM_0 = 48;

    const NUM_9 = 57;

    if (
        charCode > 31 &&
        (charCode < NUM_0 || charCode > NUM_9) &&
        ![COMMA, MINUS, PERIOD].includes(charCode)
    ) {
        event.preventDefault();

        return false;
    }

    return true;
}

export function allowCompareSymbols(event) {
    event = event ? event : window.event;

    const charCode = event.which ? event.which : event.keyCode;

    const smaller = 60;

    const equal = 61;

    const bigger = 62;

    if (charCode === smaller || charCode === equal || charCode === bigger) {
        return true;
    }

    event.preventDefault();

    return false;
}

export function firstToUpper(text) {
    if (typeof text !== 'string') {
        return;
    }

    return (
        text.substring(0, 1).toUpperCase() +
        text.substring(1, text.length).toLowerCase()
    );
}

export function randomArrayElement(arr) {
    if (!arr || !Array.isArray(arr)) {
        return;
    }

    return arr[Math.floor(Math.random() * arr.length)];
}

// https://stackoverflow.com/a/27232658
export function supportWebPImage() {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // Was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }

    // Very old browser like IE 8, canvas not supported
    return false;
}

/**
 * Creates a range of numbers, optionally excluding number(s) and changing step
 * @param {number} start
 * @param {number} end
 * @param {number|Array|boolean} exclude false to not exclude anything but to change step
 * @param {number} step
 */
export function range(start, end, exclude = false, step = 1) {
    const arr = Array.from(
        { length: (end - start) / step + 1 },
        (_, i) => start + i * step,
    );

    if (!exclude && exclude !== 0) {
        return arr;
    }

    if (Array.isArray(exclude)) {
        return arr.filter((n) => exclude.every((e) => e !== n));
    }

    return arr.filter((n) => n !== exclude);
}

/**
 * Function is inclusive, for range (1,2) it will return 1 or 2
 */
export function randomIntFromRange(min, max, count = 0) {
    if (isNaN(min) || isNaN(max)) {
        return 0;
    }

    const random = Math.floor(Math.random() * (max - min + 1) + min);

    // There still seems to be a problem in this algorithm,
    // that the generated random number is out of range in some odd case.
    // Quick fix.
    if (count < 10 && (random < min || random > max)) {
        return randomIntFromRange(min, max, count + 1);
    }

    return random;
}

export function randomFromRange(min, max, round = 0) {
    const random = Math.random() * (max - min) + min;

    return random.toFixed(round);
}

export function shuffleArray(array) {
    return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
}

/**
 * Padding number function for pad(1, 2) returns 01 string
 */
export function pad(num, size) {
    let s = '000000000' + num;

    return s.substr(s.length - size);
}

export function checkFraction(answer, question) {
    if (isSameByValue(answer, question)) {
        return true;
    }

    const reductionCondition1 =
        question.b % answer.b === 0 || answer.b % question.b === 0;

    const reductionCondition2 =
        question.a % answer.a === 0 || answer.a % question.a === 0;

    const reductionCondition3 = question.b / answer.b === question.a / answer.a;

    return reductionCondition1 && reductionCondition2 && reductionCondition3;
}

function getLocalizedLink(linksObject, language) {
    return linksObject[language] ?? linksObject[DEFAULT_LANGUAGE];
}

export function getFbGroupLink(language) {
    return getLocalizedLink(OUR_FB_GROUP_COMMUNITY_LINKS, language);
}

export function getTwitterLink(language) {
    return getLocalizedLink(OUR_TWITTER_LINKS, language);
}

export function getFbPageLink(language) {
    return getLocalizedLink(OUR_FB_PAGE_LINKS, language);
}

export function getPrivacyPolicyLink(language) {
    return getLocalizedLink(OUR_PRIVACY_POLICY, language);
}

export function getTermsOfUseLink(language) {
    return getLocalizedLink(OUR_TERMS_OF_USE, language);
}

export const NUMBERS_AND_LETTERS_REGEX = /^[a-zA-Z0-9_]*$/;

export function round(num, decimalPlaces = 0) {
    const rounded =
        Math.round(num + 'e' + decimalPlaces) + 'e-' + decimalPlaces;

    return Number(rounded);
}

export function getDecimalPlaces(number) {
    if (isNaN(number) || typeof number === 'object') {
        return 0;
    }

    const nr = typeof number === 'number' ? number.toString() : number;

    const decimals = nr.split('.')[1];

    if (decimals) {
        return decimals.length;
    } else {
        const zeros = Number(number).toString().match(/0.*$/);

        return (zeros && zeros[0].length) || 0;
    }
}

export function calculateValuePercentageChange(oldVal, newVal) {
    // handle infinity and NaN dividing 0 and by 0
    const difference = parseInt(oldVal) - parseInt(newVal);

    const delimiter = parseInt(oldVal) || 1;

    return Math.round((difference / delimiter) * 100) || 0;
}

export function convertObjectToUrlQueryParams(object) {
    return !object
        ? ''
        : Object.entries(object)
              .map((data, index) => {
                  const key = data[0];

                  const value = data[1];

                  if (index === 0) {
                      return `?${key}=${value}`;
                  }

                  return `&${key}=${value}`;
              })
              .join('');
}

export function isLiveGame(gameCodeLength) {
    return (
        gameCodeLength === CONSTANTS.LIVE_GAME_JOIN_CODE_LENGTH ||
        gameCodeLength === CONSTANTS.LIVE_GAME_PERMANENT_CODE_LENGTH
    );
}

export function isSelfPacedGame(gameCodeLength) {
    return (
        gameCodeLength === CONSTANTS.SELF_PACED_GAME_JOIN_CODE_LENGTH ||
        gameCodeLength === CONSTANTS.SELF_PACED_GAME_PERMANENT_CODE_LENGTH
    );
}

export function isSpLiveGame(gameCodeLength) {
    return (
        gameCodeLength === CONSTANTS.SELF_PACED_LIVE_GAME_JOIN_CODE_LENGTH ||
        gameCodeLength === CONSTANTS.SELF_PACED_LIVE_PERMANENT_CODE_LENGTH
    );
}

export function isMathRunnerGame(gameCodeLength) {
    return gameCodeLength === CONSTANTS.MATH_RUNNER_GAME_CODE_LENGTH;
}

export function gameModeByCode(gameCode) {
    if (!gameCode || !gameCode.length) {
        return 'none';
    }

    if (isLiveGame(gameCode.length)) {
        return 'live';
    }

    if (isSelfPacedGame(gameCode.length)) {
        return 'sp';
    }

    if (isSpLiveGame(gameCode.length)) {
        return 'splive';
    }

    return 'unknown';
}

export async function gameInfoFromApi(gameCode) {
    const gameMode = gameModeByCode(gameCode);

    let url = null;

    if (gameMode === 'live') {
        url = `game/${gameCode}/info`;
    }

    if (gameMode === 'sp') {
        url = `self-paced-game/gameType/${gameCode}`;
    }

    console.log('H:U:gIFA', gameCode, gameMode, url);

    if (!url) {
        return null;
    }

    const response = await Api().get(url);

    console.log(
        'H:U:gIFA',
        gameCode,
        gameMode,
        url,
        JSON.stringify(response.data),
    );

    if (!response || !response.data || !response.data.success) {
        return null;
    }

    return response.data.data;
}

export function fluencyLiveSimplified(score, roundsCount, roundDuration) {
    const time = roundDuration * roundsCount;

    const points = (60 / time) * score;

    return toFloat(points, 0);
}

export function fluency(player, gameInfo, gameMode) {
    if (gameMode === 'live') {
        const roundDuration = gameInfo.roundDuration;

        const roundCount = gameInfo.rounds;

        const time = roundDuration * roundCount;

        const points = (60 / time) * player.score;

        return toFloat(points, 0);
    }
    if (gameMode === 'self-paced') {
        const { playedTime } = player;

        const { gameVariant, options } = gameInfo;

        // Escape from Infinity
        if (playedTime === 0) {
            return '0';
        }

        if (gameVariant === 'questions') {
            // Tasks per 60 seconds
            const points = (60 / playedTime) * player.score;

            return toFloat(points, 0) || '0';
        }

        if (gameVariant === 'time') {
            // Tasks per 1 minute
            const points = (1 / options.duration) * player.score;

            return toFloat(points, 0) || '0';
        }

        return '0';
    }
}

export function calculateAnswersBar(player, type) {
    const { correctAnswers, wrongAnswers } = player;

    const totalAnswers = correctAnswers + wrongAnswers || 1;

    const correct = (correctAnswers / totalAnswers) * 100;

    const wrong = (wrongAnswers / totalAnswers) * 100;

    if (type === 'correct') {
        return Math.round(correct);
    }

    return Math.round(wrong);
}

export const classAccuracyPercent = (game) => {
    const noAFKs = game.players.filter(
        (player) => player.correctAnswers + player.wrongAnswers !== 0,
    );

    if (game.gameMode === 'self-paced' || game.flag === 'spg') {
        let results = { correctAnswers: 0, totalAnswers: 0 };

        noAFKs.forEach((player) => {
            const sess = player.playerSessions.length
                ? player.playerSessions[player.playerSessions.length - 1]
                : { correctAnswers: 0, wrongAnswers: 0 };

            results.totalAnswers += sess.correctAnswers + sess.wrongAnswers;

            results.correctAnswers += sess.correctAnswers;
        });

        return (
            Math.round((results.correctAnswers / results.totalAnswers) * 100) ||
            0
        );
    } else {
        return Math.round(
            noAFKs
                .map((player) => calculateAnswersBar(player, 'correct'))
                .reduce((a, b) => a + b, 0) / noAFKs.length || 0,
        );
    }
};

export function replaceUmlauts(value) {
    return value
        .replace(/ä/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/õ/g, 'o')
        .replace(/ü/g, 'y')
        .replace(/ß/g, 'ss')
        .replace(/Ä/g, 'A')
        .replace(/Ö/g, 'o')
        .replace(/Ü/g, 'Y')
        .replace(/Õ/g, 'õ');
}

// gives memory of an object based on metric, defaults to bytes
export function sizeof(obj, metric = 'b') {
    callTextEncoderPolyfill();

    const size = new TextEncoder().encode(JSON.stringify(obj)).length;
    const kiloBytes = size / 1024;

    const megaBytes = kiloBytes / 1024;

    return metric === 'mb' ? megaBytes : metric === 'kb' ? kiloBytes : size;
}

function callTextEncoderPolyfill() {
    if (typeof TextEncoder === 'undefined') {
        // eslint-disable-next-line
        TextEncoder = function TextEncoder() {};
        // eslint-disable-next-line
        TextEncoder.prototype.encode = function encode(str) {
            'use strict';

            let Len = str.length,
                resPos = -1;

            // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
            //  takes up the equivelent space of 3 UTF-8 characters to encode it properly. However, Array's
            //  have an auto expanding length and 1.5x should be just the right balance for most uses.
            let resArr =
                typeof Uint8Array === 'undefined'
                    ? new Array(Len * 1.5)
                    : new Uint8Array(Len * 3);

            for (let point = 0, nextcode = 0, i = 0; i !== Len; ) {
                (point = str.charCodeAt(i)), (i += 1);

                if (point >= 0xd800 && point <= 0xdbff) {
                    if (i === Len) {
                        resArr[(resPos += 1)] = 0xef /*0b11101111*/;

                        resArr[(resPos += 1)] = 0xbf /*0b10111111*/;

                        resArr[(resPos += 1)] = 0xbd /*0b10111101*/;

                        break;
                    }
                    // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
                    nextcode = str.charCodeAt(i);

                    if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
                        point =
                            (point - 0xd800) * 0x400 +
                            nextcode -
                            0xdc00 +
                            0x10000;
                        i += 1;

                        if (point > 0xffff) {
                            resArr[(resPos += 1)] =
                                (0x1e /*0b11110*/ << 3) | (point >>> 18);

                            resArr[(resPos += 1)] =
                                (0x2 /*0b10*/ << 6) |
                                ((point >>> 12) & 0x3f) /*0b00111111*/;

                            resArr[(resPos += 1)] =
                                (0x2 /*0b10*/ << 6) |
                                ((point >>> 6) & 0x3f) /*0b00111111*/;

                            resArr[(resPos += 1)] =
                                (0x2 /*0b10*/ << 6) |
                                (point & 0x3f) /*0b00111111*/;

                            continue;
                        }
                    } else {
                        resArr[(resPos += 1)] = 0xef /*0b11101111*/;

                        resArr[(resPos += 1)] = 0xbf /*0b10111111*/;

                        resArr[(resPos += 1)] = 0xbd /*0b10111101*/;

                        continue;
                    }
                }

                if (point <= 0x007f) {
                    resArr[(resPos += 1)] = (0x0 /*0b0*/ << 7) | point;
                } else if (point <= 0x07ff) {
                    resArr[(resPos += 1)] =
                        (0x6 /*0b110*/ << 5) | (point >>> 6);

                    resArr[(resPos += 1)] =
                        (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/;
                } else {
                    resArr[(resPos += 1)] =
                        (0xe /*0b1110*/ << 4) | (point >>> 12);

                    resArr[(resPos += 1)] =
                        (0x2 /*0b10*/ << 6) |
                        ((point >>> 6) & 0x3f) /*0b00111111*/;

                    resArr[(resPos += 1)] =
                        (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/;
                }
            }

            if (typeof Uint8Array !== 'undefined')
                return resArr.subarray(0, resPos + 1);

            // else // IE 6-9
            resArr.length = resPos + 1; // trim off extra weight

            return resArr;
        };

        TextEncoder.prototype.toString = function () {
            return '[object TextEncoder]';
        };

        try {
            // Object.defineProperty only works on DOM prototypes in IE8
            Object.defineProperty(TextEncoder.prototype, 'encoding', {
                get: function () {
                    if (
                        Object.prototype.prototype.isPrototypeOf.call(
                            TextEncoder,
                            this,
                        )
                    ) {
                        return 'utf-8';
                    } else {
                        throw TypeError('Illegal invocation');
                    }
                },
            });
        } catch (e) {
            /*IE6-8 fallback*/
            TextEncoder.prototype.encoding = 'utf-8';
        }
        if (typeof Symbol !== 'undefined')
            TextEncoder.prototype[Symbol.toStringTag] = 'TextEncoder';
    }
}

export const retryNameChange = (name) => {
    if (!name) return;

    let baseName = name.slice(0, -1);

    const lastChar = name.slice(-1);

    let trailingNumber = parseInt(lastChar, 10);

    if (isNaN(trailingNumber)) {
        baseName = name;

        trailingNumber = 2;
    } else {
        trailingNumber += 1;
    }

    return baseName + trailingNumber;
};

export function nameToPlayerName(firstName, lastName) {
    const name = firstName.length < 12 ? firstName : firstName.slice(0, 12);

    const surname = lastName.slice(0, 1);

    return `${name} ${surname}.`;
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function jclone(value) {
    return JSON.parse(JSON.stringify(value));
}

export function waitSec(sec, ms = false) {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                resolve();
            },
            ms ? sec : sec * 1000,
        );
    });
}

/**
 *  Simply stringify object with keys sorting to be sure about keys order.
 *  Used by objectHash function. One way.
 *
 *  @param {Object} obj - object to stringify, required to be "simple object" with
 *                        simple data type - objects, arrays (of), numbers, strings,
 *                        or other toString() stringifyable type.
 *                        dates converted to getTime() number
 */
export function objectStringify(obj) {
    const val2str = (val) => {
        if (typeof val === 'object') {
            if (val instanceof Date) {
                return `${val.getTime()}`;
            }

            if (Array.isArray(val)) {
                return val.reduce((acc, el) => acc + val2str(el), '');
            }

            if (val === null) {
                return 'null';
            }

            return `${objectStringify(val)}`;
        }

        return `${val}`;
    };

    let ret = '';

    let keys = Object.keys(obj);

    keys.sort().forEach((key) => {
        const val = obj[key];

        ret += `${key}${val2str(val)}`;
    });

    return ret;
}

/**
 *  Calculates "simple object" hash.
 *  stringify object with objectStringify() function
 *  and generates sha256 hash string of string
 *
 *  source https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
export async function objectHash(obj) {
    let str = objectStringify(obj);

    // console.log('STR', str);

    // encode as (utf-8) Uint8Array
    const msgUint8 = new TextEncoder().encode(str);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);

    // convert buffer to byte array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function pad_array(arr, len, fill) {
    return arr.concat(Array(len).fill(fill)).slice(0, len);
}

export function roundUpToNearest10(num) {
    return Math.ceil(num / 10) * 10;
}

export function getQuestionKey(question) {
    const cleanedQuestion = { ...question };

    // Removes things that are unique on the player answer.
    delete cleanedQuestion.playerAnswer;

    delete cleanedQuestion.time;

    delete cleanedQuestion.correct;

    return JSON.stringify(cleanedQuestion);
}

export function mountVueComponent(
    component,
    { props, children, element, app } = {},
) {
    let el = element;

    let vNode = createVNode(component, props, children);

    if (app && app._context) {
        vNode.appContext = app._context;
    }

    if (el) {
        render(vNode, el);
    } else if (typeof document !== 'undefined') {
        render(vNode, (el = document.createElement('div')));
    }

    const destroy = () => {
        if (el) {
            render(null, el);
        }

        el = null;

        vNode = null;
    };

    return { vNode, destroy, el };
}

export function simpleStringify(object) {
    // stringify an object, avoiding circular structures
    // https://stackoverflow.com/a/31557814
    let simpleObject = {};

    for (let prop in object) {
        if (!hasOwnProp(object, prop)) {
            continue;
        }

        if (typeof object[prop] === 'object') {
            continue;
        }

        if (typeof object[prop] === 'function') {
            continue;
        }

        simpleObject[prop] = object[prop];
    }

    return JSON.stringify(simpleObject); // returns cleaned up JSON
}

export function createHtml(link) {
    return `
<!DOCTYPE html>
<html>
    <head>
        <title>Fun Math Teacher Award</title>
        <meta content="${link.data.data}" property="og:image" />

    </head>
    <body>
        <img src="${link.data.data}" alt='' />
        <script> window.location = 'https://99math.com/'; </script>
    </body>
</html>
`;
}

export function calculateGameAccuracy(players) {
    if (!players || !Array.isArray(players) || players.length === 0) {
        return 100;
    }

    let wrongAnswers = 0,
        totalAnswers = 0;

    const hasCorrectAnswerProperty = hasOwnProp(players[0], 'correctAnswers');

    for (let i = 0; i < players.length; i++) {
        const player = players[i];

        wrongAnswers += player.wrongAnswers;

        totalAnswers += player.wrongAnswers;

        if (hasCorrectAnswerProperty) {
            totalAnswers += player.correctAnswers;
        } else {
            totalAnswers += player.wrongAnswers + player.score;
        }
    }

    let wrongPercentage =
        wrongAnswers > 0
            ? parseInt(Math.round((wrongAnswers * 100) / totalAnswers))
            : 0;

    if (wrongPercentage > 0) {
        if (wrongPercentage > 100) {
            return 0;
        } else {
            return 100 - wrongPercentage;
        }
    }

    return 100;
}

export const calculateGameState = (info, isHost) => {
    if (!info) {
        return 'none';
    }

    if (!Array.isArray(info.gameRounds) || !info.gameRounds.length) {
        if (isHost) {
            return 'tbl/host/created';
        }

        return 'tbl/player/join';
    }

    const role = isHost ? 'host' : 'player';

    if (info.finished) {
        return `tbl/${role}/gameend`;
    }

    const current = info.gameRounds[info.gameRounds.length - 1];

    if (current.hasEnded) {
        return `tbl/${role}/roundend`;
    }

    if (current.started) {
        return `tbl/${role}/round`;
    }

    if (current.round === 1) {
        return isHost ? 'tbl/host/prepared' : 'tbl/player/join';
    }

    return `tbl/${role}/roundend`;
};

export const inCapacitorBuildEnv = () => {
    return window.Capacitor?.isNativePlatform();
};

export const mobilePaymentsEnabled = () => {
    return window.Capacitor?.isPluginAvailable('Purchases');
};

export const inLocalEnv = () => {
    return !inCapacitorBuildEnv() && window.location.hostname === 'localhost';
};

export const inProductionEnv = () => {
    return (
        (inCapacitorBuildEnv()
            ? import.meta.env.VITE_API_URL.match(/\.prod/)
            : window.location.hostname !== 'localhost' &&
              window.location.hostname !== 'qa.99math.com' &&
              window.location.hostname !== 'test.99math.com') &&
        !localStorage.getItem('dev-tools')
    );
};

export const parentalGateEnabled = () => true;
