import {
    getDecimalPlaces,
    hasOwnProp,
    randomArrayElement,
    randomIntFromRange,
    round,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import {
    isCorrectFractionAnswer,
    getFractionLayout,
    FractionsOperations,
} from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class AdditionTopic extends TopicsBaseClass {
    static code = 'TYPE_ADDITION';

    static icon = '+';

    static gameTypeNameTranslationKey = 'game.gameTypeTitle.addition';

    static generateQuestion(numberGenerator) {
        const { topic, type, scale } = numberGenerator;

        const isFractionsTopic = topic === 'fractions';

        let number1 = 0;

        let number2 = 0;

        let fractionOperationAnswer = 0;

        if (!topic || topic === 'integers') {
            switch (type) {
                case 'custom':
                    const { wholeNumber } = numberGenerator;

                    if (wholeNumber) {
                        number1 = randomIntFromRange(1, 9) * wholeNumber;

                        number2 =
                            randomIntFromRange(
                                1,
                                (wholeNumber * 10 - number1) / wholeNumber,
                            ) * wholeNumber;
                    } else {
                        number1 = randomIntFromRange(
                            parseInt(numberGenerator.nr1Min),
                            parseInt(numberGenerator.nr1Max),
                        );

                        number2 = randomIntFromRange(
                            parseInt(numberGenerator.nr2Min),
                            parseInt(numberGenerator.nr2Max),
                        );
                    }

                    break;
                case 'scale':
                default:
                    number1 = randomIntFromRange(
                        numberGenerator.nr1r,
                        numberGenerator.nr2r,
                    );

                    number1 = randomIntFromRange(
                        numberGenerator.nr1r,
                        numberGenerator.nr2r,
                    );

                    if (hasOwnProp(numberGenerator, 'nr3r')) {
                        number2 = randomIntFromRange(
                            numberGenerator.nr3r,
                            numberGenerator.nr2 - number1,
                        );
                    } else {
                        number2 = randomIntFromRange(
                            numberGenerator.nr1,
                            numberGenerator.nr2 - number1,
                        );
                    }

                    break;
            }
        }

        if (topic === 'fractions') {
            const fractionsOperations = new FractionsOperations(
                numberGenerator,
            );

            const { d1, d2 } =
                fractionsOperations.getDenominators(numberGenerator);

            const { n1, n2 } =
                fractionsOperations.getNominators(numberGenerator);

            const { w1, w2 } =
                fractionsOperations.getWholeNumber(numberGenerator);

            fractionOperationAnswer =
                fractionsOperations.getFractionAdditionAnswer(
                    { n1, d1, w1 },
                    { n2, d2, w2 },
                );

            number1 = { numerator: n1, denominator: d1, wholeNumber: w1 };

            number2 = { numerator: n2, denominator: d2, wholeNumber: w2 };
        }

        if (topic === 'decimals') {
            const createFractional = (param) => {
                const dividend = 1 / param;

                const randomInt = randomIntFromRange(1, dividend - 1);

                const result = randomInt / dividend;

                return round(result, 4);
            };

            const createDecimal = (param) => {
                const dividend = 1 / param;

                const randomInt1 = randomIntFromRange(0, 9);

                const randomInt2 = randomIntFromRange(1, dividend - 1);

                const result = randomInt1 + randomInt2 / dividend;

                return round(result, 4);
            };

            const createNumbers = (params) => {
                // Flip a coin. -1 ==> 0.x, 1 ==> x.x;
                const fractionalOrDecimal = randomArrayElement([-1, 1]);

                const decimals = params.filter((n) => n !== 1);

                // Integer and decimal
                if (decimals.length === 1) {
                    const decimal = params.find((n) => n !== 1);

                    const int = randomIntFromRange(1, 9);

                    return fractionalOrDecimal < 0
                        ? [int, createFractional(decimal)]
                        : [int, createDecimal(decimal)];
                }

                // Only decimals
                if (decimals.length > 1) {
                    const [x, y] = params;

                    return fractionalOrDecimal < 0
                        ? [createFractional(x), createFractional(y)]
                        : [createDecimal(x), createDecimal(y)];
                }
            };

            const { addend1, addend2 } = scale;

            const a = randomArrayElement(addend1);

            const b = randomArrayElement(addend2);

            [number1, number2] = createNumbers([a, b]);

            number1 = number1.toFixed(getDecimalPlaces(a));

            number2 = number2.toFixed(getDecimalPlaces(b));
        }

        // Round the answer because adding decimals may return
        // something like 1.2300000000001
        let answer = +number1 + +number2;

        answer = round(answer, 4);

        return {
            topic: topic || 'integers',
            number1,
            number2,
            answer: isFractionsTopic ? fractionOperationAnswer : answer,
            ...(isFractionsTopic && fractionOperationAnswer),
        };
    }

    static formatQuestion(questionData, skill, calledIn) {
        const isFractionsTopic =
            questionData?.topic === 'fractions' ||
            questionData?.data?.topic === 'fractions';

        if (!isFractionsTopic) {
            return super.formatQuestion(questionData, skill, calledIn);
        } else {
            const number1 =
                questionData?.number1 || questionData?.data?.number1;

            const number2 =
                questionData?.number2 || questionData?.data?.number2;

            return `
                <div style='width: 100%; display: flex; align-items: center; justify-content: center; column-gap: 5px'>
                    ${getFractionLayout(number1, false, false, calledIn)}
                    +
                    ${getFractionLayout(number2, false, false, calledIn)}
                </div>
                `;
        }
    }

    static getAnswerInputType(numberGenerator) {
        const { topic } = numberGenerator;

        const isMixedAnswerInput = topic === 'fractions';

        return isMixedAnswerInput
            ? ANSWER_INPUT_TYPE.MIXED
            : ANSWER_INPUT_TYPE.REGULAR;
    }

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const { numberGenerator } = skill;

        const topicName = this.t(`game.gameTypeTitle.${numberGenerator.topic}`);

        if (numberGenerator.topic === 'decimals') {
            const scales = [
                ...new Set([
                    ...numberGenerator.scale.addend1,
                    ...numberGenerator.scale.addend2,
                ]),
            ].sort((a, b) => b - a);

            const scaleStrings = scales.map((value) => {
                switch (value) {
                    case 0.001:
                        return this.t(
                            'host.create.fractions.thousandths',
                        ).toLowerCase();
                    case 0.01:
                        return this.t(
                            'host.create.fractions.hundredths',
                        ).toLowerCase();
                    case 0.1:
                        return this.t(
                            'host.create.fractions.tenths',
                        ).toLowerCase();
                    case 1:
                        return this.t(
                            'game.gameTypeTitle.integers',
                        ).toLowerCase();
                    default:
                        return;
                }
            });

            return `${topicName}: ${scaleStrings.join(', ')}`;
        }

        if (numberGenerator.topic === 'integers') {
            return (
                topicName +
                ': ' +
                numberGenerator.nr1 +
                '...' +
                numberGenerator.nr2
            );
        }

        if (numberGenerator.type === 'custom') {
            return (
                numberGenerator.nr1Min +
                '...' +
                numberGenerator.nr1Max +
                ' + ' +
                numberGenerator.nr2Min +
                '...' +
                numberGenerator.nr2Max
            );
        }
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.topic === 'fractions') {
            return isCorrectFractionAnswer(answer, question);
        }

        return super.isAnswerCorrect(question, answer);
    }

    static answerData(question, answer, skill) {
        if (skill.numberGenerator.topic === 'fractions') {
            const {
                numerator,
                denominator,
                wholeNumber,
                answer,
                number1,
                number2,
            } = question;

            const {
                type,
                numberGenerator: { topic, subtopic },
            } = skill;

            return {
                number1,
                number2,
                numerator,
                denominator,
                wholeNumber,
                topic,
                subtopic,
            };
        }

        return super.answerData(question, answer, skill);
    }

    static generatePlayerAnswerHtml(playerAnswer, numberGenerator, question) {
        const isWrongAnswer = !question?.correct;

        if (
            numberGenerator.topic === 'fractions' ||
            numberGenerator?.numberGenerator?.topic === 'fractions' ||
            question.topic === 'fractions'
        ) {
            return getFractionLayout(playerAnswer, isWrongAnswer, true);
        }

        return super.generatePlayerAnswerHtml(
            playerAnswer,
            numberGenerator,
            question,
        );
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        const topic =
            question?.topic ||
            numberGenerator?.topic ||
            numberGenerator?.numberGenerator?.topic;

        if (topic === 'fractions') {
            const correctAnswer = question.answer || question.data;

            return getFractionLayout(correctAnswer, false, true, calledIn);
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static showArrowButtons(numberGenerator) {
        return numberGenerator.topic === 'fractions';
    }

    static resetPlayerAnswer(numberGenerator) {
        const isFractionsTopic = numberGenerator.topic === 'fractions';

        if (isFractionsTopic) {
            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        return '';
    }
}
