/* global mixpanel */
export const STUDENT_ONBOARDING_MP_EVENTS = {
    STUDENT_ONBOARDING_STARTED: 'startedOnboarding',
    STUDENT_ONBOARDING_STEP1_PROCEED: 'step1Proceeded',
    STUDENT_ONBOARDING_STEP2_PROCEED: 'step2Proceeded',
    STUDENT_ONBOARDING_QUIT_SCREEN: 'quitScreen',
    STUDENT_ONBOARDING_QUIT_ONBOARDING: 'quitOnboarding',
    STUDENT_ONBOARDING_QUIT_SCREEN_CONTINUE: 'quitScreenContinuedOnboarding',
    STUDENT_ONBOARDING_STEP3_PROCEED: 'step3Proceeded',
    STUDENT_ONBOARDING_STARTED_FIRST_GAME: 'startedFirstGame',
    STUDENT_ONBOARDING_FINISHED_FIRST_GAME: 'finishedFirstGame',
    STUDENT_ONBOARDING_VIEWED_LEADERBOARD: 'viewsLeaderboard',
    STUDENT_ONBOARDING_LEADERBOARD_PROCEED: 'proceedsInLeaderboard',
    STUDENT_ONBOARDING_STARTED_SECOND_GAME: 'startedSecondGame',
    STUDENT_ONBOARDING_FINISHED_SECOND_GAME: 'finishedSecondGame',
    STUDENT_ONBOARDING_STARTED_THIRD_GAME: 'startedThirdGame',
    STUDENT_ONBOARDING_STEP4_PROCEED: 'step4Proceeded',
    STUDENT_ONBOARDING_FINISHED: 'studentOnboardingFinished',
    STUDENT_ONBOARDING_STEP5_PROCEED: 'step5Proceeded',
    STUDENT_GRADE_SELECT: 'gradeSelect',
};

export const STUDENT_ONBOARDING_MP_METHODS = {
    startedOnboarding() {
        mixpanel.track('Student onboarding started');
    },
    step1Proceeded() {
        mixpanel.track('Student onboarding - step 1 - proceeded');
    },
    step2Proceeded() {
        mixpanel.track('Student onboarding - step 2 - proceeded');
    },
    quitScreen() {
        mixpanel.track('Student onboarding - quit screen');
    },
    quitOnboarding() {
        mixpanel.track('Student onboarding - quit onboarding');
    },
    quitScreenContinuedOnboarding() {
        mixpanel.track(
            'Student onboarding - quit screen - continued onboarding',
        );
    },
    step3Proceeded() {
        mixpanel.track('Student onboarding - step 3 - proceeded');
    },
    startedFirstGame() {
        mixpanel.track('Student onboarding - started 1st game');
    },
    finishedFirstGame(gameScore) {
        mixpanel.track('Student onboarding - finished 1st game', {
            GameScore: gameScore,
        });
    },
    viewsLeaderboard() {
        mixpanel.track('Student onboarding - views leaderboard');
    },
    proceedsInLeaderboard() {
        mixpanel.track('Student onboarding - proceeds in leaderboard');
    },
    startedSecondGame() {
        mixpanel.track('Student onboarding - started 2nd game');
    },
    finishedSecondGame(gameScore) {
        mixpanel.track('Student onboarding - finished 2nd game', {
            GameScore: gameScore,
        });
    },
    startedThirdGame() {
        mixpanel.track('Student onboarding - started 3rd game');
    },
    step4Proceeded() {
        mixpanel.track('Student onboarding - step 4 - proceeded');
    },
    studentOnboardingFinished() {
        mixpanel.track('Student onboarding finished');
    },
    step5Proceeded() {
        mixpanel.track('Student onboarding - step 5 - proceeded');
    },
    gradeSelect({ grade, timeStamp }) {
        mixpanel.people.set({ Grade: grade, GradeTimeStamp: timeStamp });
    },
};
