<template>
    <div
        class="c-super-awesome-modal"
        :class="{ 'c-super-awesome-modal--dark': dark }"
    >
        <div
            v-click-outside="handleClickOutside"
            class="c-super-awesome-modal__content"
        >
            <span
                v-if="xBack"
                class="c-super-awesome-modal__back"
                @click="handleBackClick"
            >
                <svg
                    v-if="bigArrow"
                    width="17"
                    height="27"
                    viewBox="0 0 17 27"
                    fill="none"
                    opacity="0.5"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.387 24.2684L3.35706 13.4935L14.387 2.71851"
                        stroke="#572598"
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                </svg>

                <svg
                    v-else
                    width="14"
                    height="21"
                    viewBox="0 0 14 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.7066 18.0845L3.45703 10.0257L11.7066 1.9668"
                        stroke="black"
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                </svg>

                {{
                    !hideBackText
                        ? backText
                            ? backText
                            : $t('host.create.back')
                        : ''
                }}
            </span>
            <span
                v-if="xClose"
                class="c-super-awesome-modal__close"
                @click="clickedX"
            >
                {{ closeText }}

                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                        fill="#AFAFAF"
                    />
                </svg>
            </span>

            <slot />
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        xClose: {
            type: Boolean,
            default: false,
        },
        xBack: {
            type: Boolean,
            default: false,
        },
        backText: {
            type: String,
            default: null,
        },
        closeText: {
            type: String,
            default: null,
        },
        hideBackText: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        bigArrow: {
            type: Boolean,
            default: false,
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['clickedBack', 'modalClosed', 'clickedX'],
    mounted() {
        this.makeScrollableIfContentIsLongerThanModal();

        window.addEventListener(
            'resize',
            this.makeScrollableIfContentIsLongerThanModal,
        );
    },
    updated() {
        this.makeScrollableIfContentIsLongerThanModal();
    },
    unmounted() {
        window.removeEventListener(
            'resize',
            this.makeScrollableIfContentIsLongerThanModal,
        );
    },
    methods: {
        handleBackClick() {
            this.$emit('clickedBack');
        },
        handleClickOutside() {
            if (!this.closeOnClickOutside) {
                return;
            }

            this.$emit('modalClosed');
        },
        clickedX() {
            this.$emit('clickedX');

            this.$emit('modalClosed');
        },
        makeScrollableIfContentIsLongerThanModal() {
            const contentElement = document.querySelector(
                '.c-super-awesome-modal__content',
            );

            if (!contentElement) {
                return console.error('SAM content class name has been renamed');
            }

            const contentHeight = contentElement.clientHeight;

            // We care about window height, as body has the full length of the
            // content, but we care about the length of the window, not content.
            const windowHeight = window.innerHeight;

            // Content element has max-height: 90vh, so to understand if the
            // content is bigger than the window, lets calculate the full height.
            const vh = windowHeight * 0.01;

            const extra10Vh = 10 * vh;

            const contentFullHeight = Math.ceil(contentHeight + extra10Vh);

            if (windowHeight <= contentFullHeight || this.scrollable) {
                contentElement.style.overflowY = 'scroll';
            } else {
                contentElement.style.overflowY = 'inherit';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.c-super-awesome-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background: rgba(25, 25, 25, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--dark {
        background: rgba(0, 0, 0, 0.6);
    }

    &__content {
        display: flex;
        justify-content: center;
        background: #ffffff;
        border-radius: 20px;
        width: fit-content;
        height: fit-content;
        max-height: 90vh;
        max-width: 90vw;
        position: relative;
        margin: auto;
    }

    &__close {
        position: absolute;
        top: 5%;
        right: 3%;
        padding: 0.5rem;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;

        svg {
            margin-left: 1rem;
        }

        @include mq($w-max-sm, max) {
            padding: 1rem;
        }

        @include mq($w-max-phone, max) {
            padding: 0 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__back {
        position: absolute;
        top: 5%;
        left: 3%;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        padding: 0.5rem;

        svg {
            margin-right: 1rem;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
</style>
