<template>
    <div class="heading-block-wrapper">
        <span
            v-if="isGetPremium"
            class="heading-block-heading heading-block-heading__get-premium"
        >
            Skyrocket your class progress.
            <br />
            Upgrade to Premium.
        </span>
        <div v-else class="heading-block-container">
            <div class="heading-block-upgrade">
                <img src="/images/crown.svg" alt="crown" />
                <span>Upgrade to Premium</span>
            </div>
            <span
                class="heading-block-heading heading-block-heading__upgrade-to-premium"
            >
                {{ headingText }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isGetPremium: Boolean,
        isMixMultiple: Boolean,
        isMostCommonMistakes: Boolean,
        isPrintableReports: Boolean,
        isSoloMode: Boolean,
        isAssignTrack: Boolean,
        isMistakes: Boolean,
    },
    computed: {
        headingText() {
            if (this.isMixMultiple) {
                return 'Progress in multiple skills at once!';
            }
            if (this.isMostCommonMistakes) {
                return 'See most common mistakes over time!';
            }
            if (this.isPrintableReports) {
                return 'Share and save your mastery progress';
            }
            if (this.isSoloMode) {
                return 'Reach mastery and see how your students progress!';
            }
            if (this.isAssignTrack) {
                return 'Differentiate your math practice and reach mastery!';
            }
            if (this.isMistakes) {
                return 'See mistakes and understand what needs attention!';
            }

            return 'Upgrade to Premium';
        },
    },
};
</script>
<style lang="scss" scoped>
.heading-block-wrapper {
    display: flex;
    flex-direction: column;
}
.heading-block-heading {
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 32px;
    font-weight: 700;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 28px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 17px;
    }

    &__get-premium {
        margin: 26px 0 32px;

        @media screen and (max-width: $w-max-phone) {
            margin: 10px 0;
        }
    }

    &__upgrade-to-premium {
        margin: 40px 0 6px;
        font-size: 26px;

        @media screen and (max-width: $w-max-desktop) {
            font-size: 24px;
        }

        @media screen and (max-width: $w-max-phone) {
            font-size: 17px;
            margin: 10px 0 6px;
        }
    }
}
.heading-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading-block-upgrade {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 17px;

    @media screen and (max-width: $w-max-phone) {
        margin-top: 10px;
    }

    img {
        width: 30px;
        height: 26px;

        @media screen and (max-width: $w-max-desktop) {
            width: 20px;
            height: 16px;
        }
    }

    span {
        color: var(--Identity-Accent-Live-games, #ffc75a);
        text-align: center;
        font-family: $inter-font-family;
        font-size: 24px;
        font-weight: 700;

        @media screen and (max-width: $w-max-desktop) {
            font-size: 18px;
        }

        @media screen and (max-width: $w-max-phone) {
            font-size: 16px;
        }
    }
}
</style>
