/* global mixpanel */
export const HOST_SPG_MP_EVENTS = {
    SPG_CREATED: 'createdSPG',
    SPG_JOINED_GAME: 'joinedSPGAsPlayer',
    SPG_CODE_VIEW: 'selfPacedGameCodeView',
    SPG_CLICKED_MY_REPORTS_BTN_IN_CODE_VIEW:
        'spgClickedViewMyReportsBtnInCodeView',
    SPG_COPIED_INVITE_LINK: 'copiedSPGInviteLink',
    SPG_FINISHED_PLAYING: 'finishedPlayingSPG',
    SPG_STARTED_PLAYING: 'startedPlayingSPG',
};

export const HOST_SPG_MP_METHODS = {
    createdSPG({
        gameCode,
        gameMode,
        options,
        taskType,
        taskScale,
        fromPlayAgain,
        location,
        locationDetail,
    }) {
        const currentDateTime = new Date();
        mixpanel.people.increment('games_played');

        mixpanel.people.set_once({
            $first_game_played: currentDateTime.toISOString(),
        });

        mixpanel.people.set({
            $last_game_played: currentDateTime.toISOString(),
        });

        mixpanel.track('Created Self Paced Game', {
            Gamecode: gameCode,
            Gamemode: gameMode,
            Duration: options.duration || 0,
            Questions: options.questionCount || 0,
            'Task type': taskType,
            'Task scale': taskScale,
            'From play again': fromPlayAgain ? true : false,
            'Local timestamp': Date.now(),
            Location: location,
            'Location detail': locationDetail,
        });
    },
    joinedSPGAsPlayer({ gameCode, gameCreatorRole }) {
        mixpanel.track('Joined any time game', {
            Gamecode: gameCode,
            'Game creator role': gameCreatorRole,
            'Local timestamp': Date.now(),
        });
    },
    selfPacedGameCodeView({
        gameCode,
        gameMode,
        options,
        taskType,
        taskScale,
    }) {
        mixpanel.track('Self Paced Code view', {
            Gamecode: gameCode,
            Gamemode: gameMode,
            Duration: options.duration || 0,
            Questions: options.questionCount || 0,
            'Task type': taskType,
            'Task scale': taskScale,
        });
    },
    spgClickedViewMyReportsBtnInCodeView(gameCode) {
        mixpanel.track('Clicked view My Reports in SPG Code view', {
            GameCode: gameCode,
        });
    },
    copiedSPGInviteLink(gameCode) {
        mixpanel.track('Copied SPG invite link', {
            Gamecode: gameCode,
        });
    },
    finishedPlayingSPG({ gameCode, newXp }) {
        mixpanel.people.increment('XP from games', newXp);

        mixpanel.track('Finished SPG', {
            Gamecode: gameCode,
            'New XP': newXp,
        });
    },
    startedPlayingSPG({ gameCode, taskType, taskScale, creatorRole, island }) {
        mixpanel.track('Started SPG', {
            Gamecode: gameCode,
            'Task type': taskType,
            'Task scale': taskScale,
            'Game creator role': creatorRole,
            'Started from island': island,
        });
    },
};
