import { randomIntFromRange, randomArrayElement } from '@/core/helpers/utils';
import TopicsBaseClass from '@/core/math-topics/TopicsBaseClass';

/**
 * @extends TopicsBaseClass
 */
export default class PercentageTopic extends TopicsBaseClass {
    static code = 'TYPE_PERCENTAGE';
    static icon = '%';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.percentage';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        // type is legacy before multiple selection of types
        const { numberGenerator } = skill;

        return numberGenerator.type
            ? '- ' +
                  this.t(`host.create.percentage.${numberGenerator.type}`) +
                  numberGenerator.percentages.map((pct) => {
                      return ` ${pct}%`;
                  })
            : numberGenerator.types
                  .map(
                      (topic) =>
                          `${this.t(`host.create.percentage.${topic.name}`)} ${
                              numberGenerator.percentages[topic.name]
                                  ? numberGenerator.percentages[topic.name].map(
                                        (pct) => {
                                            return ` ${pct}%`;
                                        },
                                    )
                                  : ''
                          }`,
                  )
                  .join(', ');
    }

    static generateQuestion(numberGenerator) {
        const topicIndex = randomIntFromRange(
            0,
            numberGenerator.types.length - 1,
        );

        const chosenTopic = numberGenerator.types[topicIndex];

        const isShare = chosenTopic.name === 'share';

        const isWhole = chosenTopic.name === 'whole';

        const isRelation = chosenTopic.name === 'relation';

        const isComparison = chosenTopic.name === 'comparison';

        const chosenConfig = chosenTopic.config;

        // If chosen topic is either "share" or "whole"
        // calculate questions based on selected percentage(s)
        if (isShare || isWhole) {
            // User selected percentages
            const pcts = numberGenerator.percentages[chosenTopic.name];

            // Randomly select one of the user selected percentages
            const pct = randomArrayElement(pcts);

            // Take configuration of this pct
            const config = chosenConfig.find((item) => {
                return item.pct === pct;
            });

            const { base1, base2, multiplier1, multiplier2 } = config;

            if (isShare) {
                const pctMultiplier = pct / 100;

                const random1 = randomIntFromRange(base1, base2) * multiplier1;

                const random2 = random1 * multiplier2;

                // If this pct configuration also has a second multiplier,
                // pick one of the random numbers as the final number.
                const random = random2
                    ? randomArrayElement([random1, random2])
                    : random1;

                const answer = random * pctMultiplier;

                return {
                    number1: pct,
                    number2: random,
                    answer,
                    type: chosenTopic.name,
                };
            }

            if (isWhole) {
                let random1;
                let random2;
                let random;
                let answer;

                switch (pct) {
                    case 75:
                        random1 =
                            randomIntFromRange(base1, base2) * multiplier1;

                        random2 = random1 * multiplier2;

                        random = random2
                            ? randomArrayElement([random1, random2])
                            : random1;

                        answer = (random / multiplier1) * 4;

                        break;
                    case 150:
                        random1 =
                            randomIntFromRange(base1, base2) * multiplier1;

                        random2 = random1 * multiplier2;

                        random = random2
                            ? randomArrayElement([random1, random2])
                            : random1;

                        answer = (random / multiplier1) * 2;

                        break;
                    default:
                        random1 = randomIntFromRange(base1, base2);

                        random2 = random1 * multiplier2;

                        random = random2
                            ? randomArrayElement([random1, random2])
                            : random1;

                        answer = random * multiplier1;

                        break;
                }

                return {
                    number1: pct,
                    number2: random,
                    answer,
                    type: chosenTopic.name,
                };
            }
        }

        if (isRelation || isComparison) {
            const { selection } = numberGenerator;

            const configs = chosenConfig.map((item) => item);

            // Randomly select a percentage configuration object
            const pctConfig = randomArrayElement(configs);

            const { pct, base1, base2, multiplier1, multiplier2 } = pctConfig;

            let random1;
            let random2;
            let randomA;
            let randomB;
            let answer;

            if (isRelation) {
                switch (pct) {
                    case 1:
                        randomA = randomIntFromRange(base1, base2);

                        randomB = randomA * multiplier1;

                        break;
                    case 10:
                        random1 = randomIntFromRange(base1, base2);

                        random2 =
                            randomIntFromRange(base1, base2) * multiplier1;

                        randomA = randomArrayElement([random1, random2]);

                        randomB = randomA * multiplier1;

                        break;
                    case 20:
                        random1 = randomIntFromRange(base1, base2);

                        random2 =
                            randomIntFromRange(base1, base2) * multiplier2;

                        randomA = randomArrayElement([random1, random2]);

                        randomB = randomA * multiplier1;

                        break;
                    case 25:
                        random1 = randomIntFromRange(base1, base2);

                        random2 =
                            randomIntFromRange(base1, base2) * multiplier2;

                        randomA = randomArrayElement([random1, random2]);

                        randomB = randomA * multiplier1;

                        break;
                    case 50:
                        random1 = randomIntFromRange(base1, base2);

                        random2 =
                            randomIntFromRange(base1, base2) * multiplier2;

                        randomA = randomArrayElement([random1, random2]);

                        randomB = randomA * multiplier1;

                        break;
                    case 200:
                        random1 =
                            randomIntFromRange(base1, base2) * multiplier1;

                        random2 =
                            randomIntFromRange(base1, base2) *
                            multiplier1 *
                            multiplier2;

                        randomA = randomArrayElement([random1, random2]);

                        randomB = randomA / multiplier1;

                        break;
                    default:
                        break;
                }

                answer = (randomA / randomB) * 100;
            }

            if (isComparison) {
                // Join to escape Observer.
                const selections = selection.join(',');

                randomB = randomIntFromRange(base1, base2) * multiplier1;

                switch (selections) {
                    case '<':
                        randomA = randomB - randomB / multiplier1;

                        answer = ((randomB - randomA) / randomB) * 100;

                        break;
                    case '>':
                        randomA = randomB / multiplier1 + randomB;

                        answer = ((randomA - randomB) / randomB) * 100;

                        break;
                    default:
                        const rndSelection = randomArrayElement(['<', '>']);

                        randomA =
                            rndSelection === '<'
                                ? randomB - randomB / multiplier1
                                : randomB / multiplier1 + randomB;

                        answer =
                            rndSelection === '<'
                                ? ((randomB - randomA) / randomB) * 100
                                : ((randomA - randomB) / randomB) * 100;
                        break;
                }
            }

            return {
                number1: randomA,
                number2: randomB,
                answer,
                type: chosenTopic.name,
            };
        }

        return null;
    }

    static formatQuestion(question, skill, calledIn) {
        const n1 = question.number1 || question.nr1;

        const n2 = question.number2 || question.nr2;

        let result;

        let subtype = question.type;

        if (question.type === 'TYPE_PERCENTAGE') {
            subtype = question.data.type;
        }

        switch (subtype) {
            case 'share':
                result = this.t('host.create.percentage.questionShare', {
                    number1: n1,
                    number2: n2,
                });

                break;
            case 'whole':
                result = this.t('host.create.percentage.questionWhole', {
                    number1: n1,
                    number2: n2,
                });

                break;
            case 'relation':
                result = this.t('host.create.percentage.questionRelation', {
                    number1: n1,
                    number2: n2,
                });

                break;
            case 'comparison':
                const smallerOrLarger =
                    n1 < n2
                        ? this.t('host.create.percentage.smaller')
                        : this.t('host.create.percentage.larger');

                result = this.t('host.create.percentage.questionComparison', {
                    number1: n1,
                    number2: n2,
                    smallerOrLarger,
                });

                break;
            default:
                break;
        }

        return result;
    }

    static answerData(question, answer, skill) {
        return {
            type: question.type,
        };
    }
}
