<template>
    <div class="form-wrapper">
        <div class="payment-modal-back-button" @click="handlePrevStep">
            <img src="/images/arrow-left.svg" alt="back" />
        </div>
        <h4 class="title">Subscribe to 99math</h4>
        <Payment
            v-if="productStripeInfo"
            :product-stripe-info="productStripeInfo"
            :discount="discount"
            :unauthorized-account="unauthorizedAccount"
            :trial-days="hasParentSignupFlow ? 7 : null"
            @closed="$emit('closed')"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Payment from './Payment.vue';

export default {
    components: { Payment },
    props: {
        productStripeInfo: { type: Object, required: true },
        discount: { type: Object, required: false },
        unauthorizedAccount: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['closed', 'prevStep'],
    computed: {
        ...mapGetters(['user']),
        hasParentSignupFlow() {
            return this.user?.flags?.parentSignupFacebook;
        },
    },
    methods: {
        handlePrevStep() {
            this.$emit('prevStep');
        },
    },
};
</script>
<style lang="scss" scoped>
.form-wrapper {
    position: relative;
    overflow: auto;
    box-shadow:
        0 0 0 0.5px rgba(50, 50, 93, 0.5),
        0 2px 5px 0 rgba(50, 50, 93, 0.2),
        0 1px 1.5px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    transform: translateY(-40px);

    @media screen and (max-height: $h-max-under-chromebook) {
        transform: unset;
    }
}
.title {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 73px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    @include mq($w-max-tablet, max) {
        font-size: 28px;
        line-height: 24px;
    }

    @media screen and (min-width: $w-min-tablet) and (max-width: $w-max-desktop) {
        margin-bottom: 10px;
        font-size: 34px;
        line-height: 36px;
    }
}
.payment-modal-back-button {
    position: absolute;
    top: 20px;
    left: 18px;
    z-index: 9999;
    cursor: pointer;

    img {
        width: 25px;
        opacity: 0.5;
    }
}
</style>
<style lang="scss">
.form-wrapper {
    overflow-y: auto !important;
}
</style>
