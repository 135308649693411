import audio from '@/store/modules/ui.audio';
import { mobileViewHeightFix } from '@/core/helpers/MobileHelpers';
const STUDENT_MUSIC_PATH = 'https://n99math.github.io';

export default {
    namespaced: true,
    modules: {
        audio,
    },
    state: {
        window: {
            size: '0x0',
            width: 0,
            height: 0,
            scroll: 0,
        },
        loading: false,
        dialogs: {
            confirm: {
                message: null,
                resolve: null,
            },
            alert: {
                message: null,
                resolve: null,
            },
            choice: {
                options: null,
                withCancel: false,
                message: null,
                resolve: null,
            },
        },
        isHalloweenEnabledStorage: true,
        isWinterThemeEnabled: true,
    },
    getters: {
        isMobile: (state) => state.window.width <= 1024,
        isPhone: (state) => state.window.width <= 600,
        isHalloweenEnabled: (state, getters, rootState, rootGetters) => {
            // TODO: enable this next Halloween
            return false;
            // if (rootGetters['v2/user/isTeacher'])
            //     return rootGetters.user?.themes?.halloween;
            //
            // return state.isHalloweenEnabledStorage;
        },
        isWinterThemeEnabled: (state) => {
            return state.isWinterThemeEnabled;
        },
    },
    mutations: {
        resize: (state) => {
            state.window.width = window.innerWidth;

            state.window.height = window.innerHeight;

            state.window.scroll = window.scrollY;

            state.window.size = `${state.window.width}x${state.window.height}`;
        },
        scroll: (state) => {
            state.window.scroll = window.scrollY;
        },
        loading: (state, next) => {
            state.loading = !!next;
        },
        dialogConfirm: (state, { message, resolve }) => {
            if (!message) {
                state.dialogs.confirm.message = null;

                if (state.dialogs.confirm.resolve) {
                    state.dialogs.confirm.resolve(false);

                    state.dialogs.confirm.resolve = null;
                }

                return;
            }
            state.dialogs.confirm.message = message;

            if (state.dialogs.confirm.resolve) {
                state.dialogs.confirm.resolve(false);
            }

            state.dialogs.confirm.resolve = resolve;
        },
        resolveConfirm: (state, value) => {
            if (state.dialogs.confirm.resolve) {
                state.dialogs.confirm.resolve(!!value);
            }

            state.dialogs.confirm.resolve = null;

            state.dialogs.confirm.message = null;
        },
        dialogAlert: (state, { message, resolve }) => {
            if (!message) {
                state.dialogs.alert.message = null;

                if (state.dialogs.alert.resolve) {
                    state.dialogs.alert.resolve();

                    state.dialogs.alert.resolve = null;
                }

                return;
            }

            state.dialogs.alert.message = message;

            if (state.dialogs.alert.resolve) {
                state.dialogs.alert.resolve();
            }

            state.dialogs.alert.resolve = resolve;
        },
        resolveAlert: (state) => {
            if (state.dialogs.alert.resolve) {
                state.dialogs.alert.resolve();
            }

            state.dialogs.alert.resolve = null;

            state.dialogs.alert.message = null;
        },
        dialogChoice: (
            state,
            { message, options, resolve, withCancel = false },
        ) => {
            if (state.dialogs.choice.resolve) {
                state.dialogs.choice.resolve(null);
            }

            state.dialogs.choice = { message, resolve, withCancel, options };
        },
        resolveChoice: (state, value) => {
            if (state.dialogs.choice.resolve)
                state.dialogs.choice.resolve(value);

            state.dialogs.choice = {
                message: null,
                resolve: null,
                withCancel: false,
                options: null,
            };
        },
        setHalloweenTheme(state, value) {
            state.isHalloweenEnabledStorage = value;
        },
        setWinterTheme(state, value) {
            state.isWinterThemeEnabled = value;
        },
    },
    actions: {
        init: async (store) => {
            console.log('store::ui::init');

            window.addEventListener('resize', () => {
                store.commit('resize');
            });

            window.addEventListener('scroll', () => {
                store.commit('scroll');
            });

            store.commit('resize');

            store.commit('scroll');

            await store.dispatch('audio/init');
        },
        mobileViewHeightFix: () => {
            mobileViewHeightFix();
        },
        confirm: (store, message) =>
            new Promise((resolve) => {
                store.commit('dialogConfirm', { message, resolve });
            }),
        alert: (store, message) =>
            new Promise((resolve) => {
                store.commit('dialogAlert', { message, resolve });
            }),
        choose: (store, { message, options, withCancel = true }) =>
            new Promise((resolve) => {
                store.commit('dialogChoice', {
                    message,
                    options,
                    withCancel,
                    resolve,
                });
            }),
        toggleHalloweenTheme(store) {
            const value = store.state.isHalloweenEnabledStorage ? 0 : 1;

            localStorage.setItem('halloweenTheme', value);

            store.dispatch('loadHalloweenTheme');
        },
        loadHalloweenTheme(store) {
            const value =
                parseInt(localStorage.getItem('halloweenTheme')) !== 0;
            store.commit('setHalloweenTheme', value);
        },
        toggleWinterTheme(store) {
            const value = !store.state.isWinterThemeEnabled;

            localStorage.setItem('isWinterTheme', `${value}`);

            store.commit('setWinterTheme', value);
        },
        loadWinterTheme(store) {
            const isWinterTheme = localStorage.getItem('isWinterTheme');

            if (isWinterTheme === 'false') {
                store.commit('setWinterTheme', false);
            }
        },
        // Sound
        playClickSound({ dispatch }) {
            dispatch(
                'setAudio',
                './music/student-account/ui/SFX_UI_Click_Generic_1.wav',
                {
                    root: true,
                },
            );
        },
        playClickSecondSound({ dispatch }) {
            dispatch(
                'setAudio',
                './music/student-account/ui/SFX_UI_Click_Generic_3.wav',
                {
                    root: true,
                },
            );
        },
        playMouseHoverSound({ dispatch }) {
            dispatch(
                'setAudio',
                './music/student-account/ui/SFX_UI_Swipe_1.wav',
                {
                    root: true,
                },
            );
        },
        playConfettiSound({ dispatch }) {
            dispatch(
                'setAudio',
                './music/student-account/ui/SFX_UI_Objective_Complete_Small_2.wav',
                {
                    root: true,
                },
            );
        },
        playClaimItemSound({ dispatch }) {
            if (localStorage.getItem('parentSignupFacebook')) {
                return;
            }

            dispatch(
                'setAudio',
                './music/student-account/ui/SFX_UI_Collect_Levelup_Tier_2.wav',
                {
                    root: true,
                },
            );
        },
        playGameEndSuccessSound({ dispatch }) {
            dispatch(
                'setAudio',
                './music/student-account/ui/STGR_Success_1.wav',
                {
                    root: true,
                },
            );
        },
        playGameEndFailSound({ dispatch }) {
            dispatch('setAudio', './music/student-account/ui/STGR_Fail_1.wav', {
                root: true,
            });
        },
        playGameBackgroundSound({ dispatch }) {
            dispatch(
                'setAudio',
                `${STUDENT_MUSIC_PATH}/MUSC_Cute_Menu_Loop_2.mp3`,
                {
                    root: true,
                },
            );
        },
        playMainBackgroundSound({ dispatch, rootState }) {
            dispatch(
                'setAudio',
                `${STUDENT_MUSIC_PATH}/MUSC_Cute_Menu_Loop_1.mp3`,
                {
                    root: true,
                },
            );
        },
        playParkBackgroundSound({ dispatch }) {
            dispatch(
                'setAudio',
                `${STUDENT_MUSIC_PATH}/MUSC_Cute_Ingame_Loop_1.mp3`,
                {
                    root: true,
                },
            );
        },
    },
};
