<template>
    <div class="ui-dialog-background" @click="resolveDialog()">
        <div class="ui-dialog-window" @click="resolveDialog()">
            <div class="ui-dialog-message" @click="resolveDialog()">
                {{ $store.state.v2.ui.dialogs.alert.message }}
            </div>
            <div class="ui-dialog-buttons" @click="resolveDialog()">
                Click anywhere to continue.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DialogAlert',
    methods: {
        resolveDialog() {
            this.$store.commit('v2/ui/resolveAlert');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/components/components.ui-dialogs';
</style>
