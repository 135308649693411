<template>
    <div class="gpm-wrapper">
        <div class="gpm-container">
            <img
                src="/images/quests-widget/close-btn.svg"
                alt="close"
                class="gpm-close-btn"
                @click="closeModal"
            />
            <HeadingBlock
                :is-get-premium="isGetPremium"
                :is-mix-multiple="isMixMultiple"
                :is-most-common-mistakes="isMostCommonMistakes"
                :is-printable-reports="isPrintableReports"
                :is-solo-mode="isSoloMode"
                :is-assign-track="isAssignTrack"
                :is-mistakes="isMistakes"
            />
            <div v-if="isGetPremium" class="gpm-block-container">
                <SoloAndLiveBlock :is-get-premium="isGetPremium" />
                <MasteryGrowthBlock />
                <ShareWithAdminBlock
                    :is-get-premium="isGetPremium"
                    :is-get-premium-modal="true"
                />
                <SeePricingBlock />
            </div>
            <div v-else class="gpm-block-container">
                <MasteryGrowthBlock />
                <ShareWithAdminBlock :is-get-premium-modal="true" />
                <SeePricingBlock />
                <SoloAndLiveBlock />
            </div>
        </div>
    </div>
</template>
<script>
import SoloAndLiveBlock from '@/teacher/flows/TeacherPremium/Modals/components/SoloAndLiveBlock.vue';
import HeadingBlock from '@/teacher/flows/TeacherPremium/Modals/components/HeadingBlock.vue';
import MasteryGrowthBlock from '@/teacher/flows/TeacherPremium/Modals/components/MasteryGrowthBlock.vue';
import ShareWithAdminBlock from '@/teacher/flows/TeacherPremium/Modals/components/ShareWithAdminBlock.vue';
import { mapActions, mapGetters } from 'vuex';
import SeePricingBlock from '@/teacher/flows/TeacherPremium/Modals/components/SeePricingBlock.vue';
import {
    TrackingService,
    EVENTS,
} from '@/core/services/TrackingService/TrackingService';

export default {
    components: {
        SeePricingBlock,
        ShareWithAdminBlock,
        MasteryGrowthBlock,
        HeadingBlock,
        SoloAndLiveBlock,
    },
    props: {
        mode: {
            type: String,
        },
    },
    computed: {
        ...mapGetters(['user']),
        isGetPremium() {
            return this.mode === 'getPremium';
        },
        isMixMultiple() {
            return this.mode === 'mixMultiple';
        },
        isMostCommonMistakes() {
            return this.mode === 'mostCommonMistakes';
        },
        isPrintableReports() {
            return this.mode === 'printableReports';
        },
        isSoloMode() {
            return this.mode === 'soloMode';
        },
        isAssignTrack() {
            return this.mode === 'assignTrack';
        },
        isMistakes() {
            return this.mode === 'mistakes';
        },
        modalName() {
            switch (this.mode) {
                case 'getPremium':
                    return 'Menu';
                case 'mixMultiple':
                    return 'Mixing skills';
                case 'mostCommonMistakes':
                    return 'Common mistakes';
                case 'printableReports':
                    return 'Print reports';
                case 'soloMode':
                    return 'ST data';
                case 'assignTrack':
                    return 'ST assign path';
                case 'mistakes':
                    return 'ST mistakes';
                default:
                    return 'Menu';
            }
        },
    },
    created() {
        new TrackingService().track(EVENTS.OPEN_DISCOVER_PREMIUM_MODAL, {
            location: this.$route.path,
            mode: this.modalName,
            version: 'send-to-admin',
        });
    },
    methods: {
        ...mapActions('v2/teacherPremium', ['setPremiumModalMode']),
        closeModal() {
            this.setPremiumModalMode(null);
        },
    },
};
</script>
<style lang="scss" scoped>
.gpm-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
}
.gpm-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 706px;
    height: 560px;
    border-radius: 10px;
    border: 2px solid rgba(255, 208, 91, 0.5);
    background: linear-gradient(180deg, #360773 60%, #582699 80%);

    @media screen and (max-width: $w-max-desktop) {
        height: 520px;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 90%;
        height: 90%;
    }
}
.gpm-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gpm-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: translate(-10px, 10px);
    cursor: pointer;

    @media screen and (max-width: $w-max-phone) {
        width: 30px;
        height: 30px;
        transform: translate(-6px, 6px);
    }
}
</style>
