import CONSTANTS from '@/core/helpers/constants';

export const RedirectPlayerIfNeededMixin = {
    computed: {
        v2() {
            return this.$store.state.v2.game.mode === CONSTANTS.LIVE_GAME_MODE;
        },
        gameMode() {
            const path = this.v2 ? 'v2/game/mode' : 'getGameMode';

            return this.$store.getters[path];
        },
        gameInfo() {
            const path = this.v2 ? 'v2/game/info' : 'getGameInfo';

            return this.$store.getters[path];
        },
        gameCode() {
            const path = this.v2 ? 'v2/game/code' : 'getGameCode';

            return this.$store.getters[path];
        },
        creatorRole() {
            const path = this.v2 ? 'v2/game/creatorRole' : 'getGameCreatorRole';

            return this.$store.getters[path];
        },
        currentRoute() {
            return this.$route.name;
        },
    },
    methods: {
        getGameInfo() {
            const path = this.v2 ? 'v2/game/getGameInfo' : 'getGameInfo';

            return this.$store.dispatch(path);
        },
        redirectPlayerIfNeeded() {
            console.log('IsConnectedToBackEnd: redirect player if needed');

            if (!this.gameCode) {
                console.error(
                    'Game code missing. Unexpected state - rounting to /',
                );

                alert('Game not exists! Please, check again.');

                return this.$router.push({ name: 'home' });
            }

            if (!this.v2) {
                this.joinBackToGameSocketRoom();
            }

            if (this.gameMode === CONSTANTS.LIVE_GAME_MODE) {
                return this.redirectPlayerIfNeededInLiveGame();
            }
        },
        async redirectPlayerIfNeededInLiveGame() {
            console.log(
                'IsConnectedToBackEnd: redirect player if needed in live game',
            );

            const PLAYER_LIVE_GAME_ROUTES = {
                GAME_LOBBY: 'livegame.preview',
                WARM_UP: 'livegame.warmup',
                IN_GAME: 'livegame.gameview',
                GAME_ENDED: 'livegame.results',
            };

            const isTeacherCreatorRole =
                (this.creatorRole || this.gameInfo.creatorRole) ===
                CONSTANTS.CREATOR_ROLE.TEACHER;

            await this.getGameInfo();

            if (this.currentRoute === PLAYER_LIVE_GAME_ROUTES.GAME_ENDED) {
                console.log(
                    'IsConnectedToBackEnd: already in game end, no need to redirect',
                );

                return;
            }

            if (
                this.currentRoute === PLAYER_LIVE_GAME_ROUTES.GAME_LOBBY &&
                !this.gameInfo.started
            ) {
                console.log(
                    'IsConnectedToBackEnd: already in game lobby, no need to redirect',
                );

                return;
            }

            if (
                this.currentRoute === PLAYER_LIVE_GAME_ROUTES.WARM_UP &&
                !this.gameInfo.started
            ) {
                console.log(
                    'IsConnectedToBackEnd: game not started, in warm up, no need to redirect',
                );

                return;
            }

            if (
                this.gameCode &&
                this.gameInfo?.finished &&
                this.currentRoute !== PLAYER_LIVE_GAME_ROUTES.GAME_ENDED &&
                isTeacherCreatorRole
            ) {
                console.log(
                    'IsConnectedToBackEnd: Redirect player to the game ended view',
                );

                return this.$router.push({
                    name: PLAYER_LIVE_GAME_ROUTES.GAME_ENDED,
                    params: {
                        gameCode: this.gameCode,
                    },
                });
            }

            if (
                this.gameCode &&
                this.gameInfo?.gameRounds?.length > 0 &&
                this.currentRoute !== PLAYER_LIVE_GAME_ROUTES.IN_GAME &&
                isTeacherCreatorRole
            ) {
                console.log(
                    'IsConnectedToBackEnd: Redirect player to the game view',
                );

                return this.$router.push({
                    name: PLAYER_LIVE_GAME_ROUTES.IN_GAME,
                    params: {
                        gameCode: this.gameCode,
                    },
                });
            }

            console.log('IsConnectedToBackEnd: no need to redirect.');
        },
        /**
         * Just to make sure on reconnect, connect the player back to the
         * Socket room for the game. Socketio rooms are Sets in JS, so
         * even if we join the user multiple time into the same room,
         * the client gets added to the room only once.
         */
        joinBackToGameSocketRoom() {
            let eventName;

            switch (this.gameMode) {
                case CONSTANTS.LIVE_GAME_MODE:
                    eventName = 'gameRoomJoin';
                    break;

                case CONSTANTS.SELF_PACED_GAME_MODE:
                    eventName = 'selfPacedGameRoomJoin';
                    break;
            }

            if (eventName) {
                this.$socket.client.emit(eventName, this.gameCode);
            }
        },
    },
};
