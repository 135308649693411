<template>
    <div class="plan-container">
        <Part5
            v-if="currentPart === 5"
            :class="{ 'reversed-content': currentPart === 5 }"
            @advance="advanceModal"
        />
        <AlreadySubscribed
            v-else-if="alreadySubscribed"
            @closeModal="$emit('closed')"
        />
        <Part1
            v-else-if="currentPart === 1"
            :product-stripe-info="priceTest && priceTest.products[0].stripe"
            @advance="advanceModal"
        />
        <Part2 v-else-if="currentPart === 2" @advance="advanceModal" />
        <Part3
            v-else-if="currentPart === 3"
            @advance="advanceModal"
            @selectedPlanChange="changePlan"
        />
        <Part4
            v-else-if="currentPart === 4"
            :selected-plan="selectedPlan"
            @advance="advanceModal"
        />
    </div>
</template>
<script>
import AlreadySubscribed from './AlreadySubscribed.vue';
import Part1 from './Part1.vue';
import Part2 from './Part2.vue';
import Part3 from './Part3.vue';
import Part4 from './Part4.vue';
import Part5 from './Part5.vue';
import { mapGetters } from 'vuex';

export default {
    components: { AlreadySubscribed, Part1, Part2, Part3, Part4, Part5 },
    emits: ['closed'],
    data() {
        return {
            currentPart: 1,
            selectedPlan: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            priceTest: 'getUsersPriceTest',
        }),
        alreadySubscribed() {
            return this.user?.subscriptionStatus === 'active';
        },
    },
    updated() {
        if (this.currentPart === 6) {
            this.$emit('closed');
        }
    },
    methods: {
        changePlan(planId) {
            this.selectedPlan = planId;
        },
        async advanceModal() {
            this.currentPart = this.currentPart + 1;
        },
    },
};
</script>
<style lang="scss" scoped>
.plan-container {
    height: 100%;
}
.reversed-content {
    height: 100%;
    border-radius: 20px;
    border: 2px solid white;
    background-color: white;
}
</style>
