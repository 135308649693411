import { randomIntFromRange } from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import { scaleAndColor, drawNumberLine } from '@/core/math-topics/utils/draw';
import {
    getFractionLayout,
    isCorrectFractionAnswer,
} from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class NumberLineTopic extends TopicsBaseClass {
    static code = 'TYPE_NUMBER_LINE';
    static icon = '';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.numberLine';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const string =
            ' - ' +
            this.t(
                `host.create.numberLine.${skill.numberGenerator.numberLineBase}`,
            );

        // Add a bit more context for the teacher in the report view.
        const reportRoutes = [
            'host.live-analytics',
            'host.self-paced-analytics',
            'host.reports',
        ];

        if (this.$route && reportRoutes.includes(this.$route.name)) {
            const { numberLineBase } = skill.gameInfo.numberGenerator;

            if (
                numberLineBase === 'integer' ||
                numberLineBase === 'realIntegers'
            ) {
                return (
                    string +
                    ' (' +
                    skill.numberGenerator.intervals.join(', ') +
                    ')'
                );
            } else {
                return `${string} (${skill.numberGenerator.fractionType} ${skill.numberGenerator.wholeNumber})`;
            }
        }

        return string;
    }

    static keyboardAnswerInput(
        answer,
        input,
        numberGenerator = undefined,
        activeInput = undefined,
    ) {
        if (
            numberGenerator.fractionType === 'improper' &&
            numberGenerator.numberLineBase === 'fraction'
        ) {
            if (input === 'delete') {
                if (
                    !answer ||
                    !answer[activeInput] ||
                    answer[activeInput] === '' ||
                    !answer[activeInput].length
                ) {
                    return '';
                }

                answer[activeInput] = answer[activeInput].slice(0, -1);
            } else {
                answer[activeInput] = `${answer[activeInput] || ''}${input}`;
            }

            return answer;
        }
        return super.keyboardAnswerInput(
            answer,
            input,
            numberGenerator,
            activeInput,
        );
    }

    static resetPlayerAnswer(numberGenerator, question = null) {
        if (
            (numberGenerator.fractionType === 'improper' &&
                numberGenerator.numberLineBase === 'fraction') ||
            (numberGenerator.fractionType === 'mixed' &&
                numberGenerator.numberLineBase === 'fraction')
        ) {
            return {
                wholeNumber: '',
                numerator: '',
                denominator: '',
            };
        }

        return super.resetPlayerAnswer(numberGenerator, question);
    }

    static getAnswerInputType(numberGenerator) {
        if (
            numberGenerator.fractionType === 'improper' &&
            numberGenerator.numberLineBase === 'fraction'
        ) {
            return ANSWER_INPUT_TYPE.IMPROPER_FRACTION;
        }

        if (
            numberGenerator.fractionType === 'mixed' &&
            numberGenerator.numberLineBase === 'fraction'
        ) {
            return ANSWER_INPUT_TYPE.MIXED;
        }

        if (numberGenerator.distanceBetweenTwoPoints === false) {
            return ANSWER_INPUT_TYPE.BOXED;
        }

        return super.getAnswerInputType(numberGenerator);
    }

    static isAnswerCorrect(question, answer, numberGenerator = undefined) {
        if (
            (numberGenerator.fractionType === 'improper' &&
                numberGenerator.numberLineBase === 'fraction') ||
            (numberGenerator.fractionType === 'mixed' &&
                numberGenerator.numberLineBase === 'fraction')
        ) {
            return isCorrectFractionAnswer(answer, question.answer);
        }

        return super.isAnswerCorrect(question, answer, numberGenerator);
    }

    static generateQuestion(numberGenerator) {
        const { scale, numberLineBase } = numberGenerator;

        if (numberLineBase === 'integer' || numberLineBase === 'realIntegers') {
            const { intervals, hideSomeNumber, distanceBetweenTwoPoints } =
                numberGenerator;

            const numbersToGenerate = 5;

            const interval =
                intervals[randomIntFromRange(0, intervals.length - 1)];

            const dotLocation = randomIntFromRange(1, numbersToGenerate);

            const minMax = scale.split('..');

            const min = parseInt(minMax[0]);

            const max = parseInt(minMax[1]);

            const numbers = {};

            const number1Random = randomIntFromRange(
                min,
                max - numbersToGenerate * interval,
            );

            numbers.number1 = Math.round(number1Random / interval) * interval;

            numbers.number2 = numbers.number1 + interval;

            numbers.number3 = numbers.number2 + interval;

            numbers.number4 = numbers.number3 + interval;

            numbers.number5 = numbers.number4 + interval;

            let answer, Alocation, Blocation;

            if (distanceBetweenTwoPoints) {
                Blocation = randomIntFromRange(2, numbersToGenerate);

                Alocation = randomIntFromRange(1, Blocation - 1);

                const B = numbers[`number${Blocation}`];

                const A = numbers[`number${Alocation}`];

                answer = B - A;
            } else {
                answer = numbers[`number${dotLocation}`];

                numbers[`number${dotLocation}`] = '?';
            }

            if (hideSomeNumber) {
                const numbersToHide = randomIntFromRange(1, 2);

                for (let i = 0; i < numbersToHide; i++) {
                    let dotToHide = randomIntFromRange(1, numbersToGenerate);

                    if (dotToHide === dotLocation) {
                        dotToHide =
                            dotToHide + 1 >= 5 ? dotToHide - 1 : dotToHide + 1;
                    }

                    numbers[`number${dotToHide}`] = '';
                }
            }

            return {
                ...numbers,
                data: {
                    Alocation,
                    Blocation,
                },
                answer,
            };
        }
        const { wholeNumber, fractionType } = numberGenerator;

        let questionWholeNumber, nextWholeNumber, partsBetween, pointLocation;
        const answer = {
            wholeNumber: 0,
            numerator: 0,
            denominator: 0,
        };

        switch (wholeNumber) {
            case '0..1':
                questionWholeNumber = 0;
                partsBetween = randomIntFromRange(2, 10);
                break;
            case '0..5':
                questionWholeNumber = randomIntFromRange(0, 5);
                partsBetween = randomIntFromRange(2, 5);
                break;
            case '0..10':
                questionWholeNumber = randomIntFromRange(0, 10);
                partsBetween = randomIntFromRange(2, 10);
                break;
        }

        nextWholeNumber = questionWholeNumber + 1;

        pointLocation = randomIntFromRange(1, partsBetween - 1);

        answer.denominator = partsBetween;

        answer.numerator = pointLocation;

        answer.wholeNumber = questionWholeNumber;

        if (fractionType === 'improper') {
            answer.numerator =
                questionWholeNumber * partsBetween + pointLocation;

            answer.wholeNumber = null;
        }

        return {
            wholeNumber: questionWholeNumber,
            nextWholeNumber,
            partsBetween,
            pointLocation,
            answer,
        };
    }

    static formatQuestion(questionData, skill, calledIn) {
        return drawNumberLine(
            skill.numberGenerator,
            questionData,
            calledIn,
            ...scaleAndColor(),
        );
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        if (typeof question.answer === 'object') {
            return this.generatePlayerAnswerHtml(
                question.answer,
                numberGenerator,
                question,
                calledIn,
            );
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static generatePlayerAnswerHtml(
        answer,
        numberGenerator,
        question,
        calledIn,
    ) {
        if (typeof answer !== 'object')
            return super.generatePlayerAnswerHtml(
                answer,
                numberGenerator,
                question,
                calledIn,
            );

        return getFractionLayout(
            answer,
            'correct' in question ? !question.correct : false,
            true,
            calledIn,
        );
    }

    static questionViewIsInline(skill) {
        return false;
    }

    static answerData(question, answer, skill) {
        return {
            wholeNumber: question.wholeNumber,
            nextWholeNumber: question.nextWholeNumber,
            partsBetween: question.partsBetween,
            pointLocation: question.pointLocation,
            Alocation: question.data && question.data.Alocation,
            Blocation: question.data && question.data.Blocation,
        };
    }

    static formatBoxedQuestion(question, skill) {
        if (skill.numberGenerator.distanceBetweenTwoPoints) {
            return null;
        }

        return '? = ';
    }

    static showArrowButtons(numberGenerator) {
        const numberLineBase = numberGenerator.numberLineBase;

        const fractionType = numberGenerator.fractionType;

        return (
            numberLineBase === 'fraction' &&
            (fractionType === 'improper' || fractionType === 'mixed')
        );
    }
}
