<template>
    <p>
        <svg
            id="ehRpT9iux641"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 90 90"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
        >
            <g
                id="ehRpT9iux642"
                transform="matrix(.1 0 0 0.1 43.545-.000004)"
                opacity="0"
            >
                <path
                    d="M14.4,0.8c0,0-12.6,21-12.6,27.5s5.7,11.8,12.7,11.7c7,0,12.7-5.3,12.7-11.8c0-6.4-12.8-27.4-12.8-27.4Z"
                    fill="#8ce0ff"
                />
                <path
                    d="M14.6,40.9C7.1,40.9,1,35.3,1,28.3c0-6.6,12.2-27,12.7-27.9.1-.2.4-.4.7-.4s.6.2.8.4c.5.9,12.9,21.2,12.9,27.8c0,7-6.1,12.7-13.5,12.7Zm5.5-28.4c-2.2-4.2-4.5-8.1-5.6-10-1.1,1.9-3.3,5.8-5.5,10-5.1,9.9-6.2,14.1-6.2,15.8c0,6.1,5.3,11,11.9,10.9c6.5,0,11.8-5,11.8-11-.1-1.7-1.2-5.9-6.4-15.7Z"
                />
                <path
                    d="M14.5,36.5c-1.7,0-3.3-.4-4.7-1.3-1.4-.8-2.5-1.9-3.2-3.3-.2-.4,0-.9.4-1.1s.9,0,1.2.4c1.2,2.3,3.7,3.7,6.4,3.7.5,0,.9.4.9.8-.1.4-.5.8-1,.8Z"
                />
                <path d="M6.6,29.9c-1.1,0-1.1-1.6,0-1.6s1.1,1.6,0,1.6Z" />
            </g>
        </svg>
    </p>
</template>
<script>
export default {
    name: 'Drop',
    // https://www.svgator.com/help/getting-started/add-animated-svg-to-vue-js
    mounted() {
        (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
            (a = document.getElementById(i.root)).svgatorPlayer = {
                ready: (function (a) {
                    b = [];
                    return function (c) {
                        return c ? (b.push(c), a.svgatorPlayer) : b;
                    };
                })(a),
            };
            w[o] = w[o] || {};
            w[o][s] = w[o][s] || [];
            w[o][s].push(i);
            e = d.createElementNS(n, t);
            e.async = true;
            e.setAttributeNS(
                x,
                'href',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            e.setAttributeNS(
                null,
                'src',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            p = d.getElementsByTagName(t)[0];
            p.parentNode.insertBefore(e, p);
        })(
            '91c80d77',
            {
                root: 'ehRpT9iux641',
                version: '2022-05-04',
                animations: [
                    {
                        elements: {
                            ehRpT9iux642: {
                                transform: {
                                    data: { t: { x: -14.55, y: 0.000005 } },
                                    keys: {
                                        o: [
                                            {
                                                t: 0,
                                                v: {
                                                    x: 45,
                                                    y: -0.000005,
                                                    type: 'corner',
                                                },
                                            },
                                            {
                                                t: 2800,
                                                v: {
                                                    x: 45,
                                                    y: 9.099993,
                                                    type: 'corner',
                                                },
                                            },
                                        ],
                                        s: [
                                            { t: 0, v: { x: 0.1, y: 0.1 } },
                                            { t: 700, v: { x: 1.8, y: 1.8 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 0, v: 0 },
                                    { t: 200, v: 1 },
                                    { t: 2800, v: 1 },
                                    { t: 3000, v: 0 },
                                ],
                            },
                        },
                        s: 'MDBA1M2JiNjJhNQGI1YjJhMWI0TYTlhZmFlNjJNON2E3MzcwNzLA3MDZjNjJhNJGE5YjJhNWEzMUWI0YTlhZmFWlNjI3YTcxNmSM2MmE5YjRhNMWIyYTFiNGE5FYWZhZWIzNjIE3YTcxNmM2MmBE2YTlhY2FjNPjJNN2FVNzE2DYzYyYTFhY2IP0YTViMmFlYTXFiNGE1NjI3YQUdhNmExYWNiPM2E1NmM2MmIPzYjBhNWE1YTIQ2MjdhNzFiZFA|',
                    },
                ],
                options:
                    'MDIAxMDgyMjk3YUTdiNjg3OTdiDMjk0MTI5NzMA3NjY4TzZiMjRk4NA|',
            },
            'https://cdn.svgator.com/ply/',
            '__SVGATOR_PLAYER__',
            '2022-05-04',
            window,
            document,
            'script',
            'http://www.w3.org/2000/svg',
            'http://www.w3.org/1999/xlink',
        );
    },
};
</script>
