/* global tracking */

import {
    AnonymousMixpanelService,
    ANONYMOUSEVENTS as _ANONYMOUSEVENTS,
} from '@/core/services/MixPanelService/AnonymousMixPanelService';

export const ANONYMOUSEVENTS = _ANONYMOUSEVENTS;

export const AnonymousTrackingService = (function () {
    /** Constructor */
    function AnonymousTrackingService() {}

    AnonymousTrackingService.prototype.track = function (
        event = '',
        args = {},
    ) {
        new AnonymousMixpanelService().track(event, args);
    };

    return AnonymousTrackingService;
})();
