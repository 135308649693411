export default {
    namespaced: true,
    state: () => ({
        hasChat: false,
        hasFeedback: false,
        showFeedback: false,
        showChat: false,
    }),
    mutations: {
        hasChat: (state, next) => {
            state.hasChat = next;
        },
        hasFeedback: (state, next) => {
            state.hasFeedback = next;
        },
        showFeedback: (state, next) => {
            state.showFeedback = next;

            if (next) {
                window.FreshworksWidget('show', 'launcher');
            } else {
                window.FreshworksWidget('hide', 'launcher');
            }
        },
        showChat: (state, next) => {
            state.showChat = next;

            if (next) {
                window.fcWidget.show();
            } else {
                window.fcWidget.hide();
            }
        },
    },
    actions: {
        init: async () => {
            // await store.dispatch('initFeedbackWidget');
            // await store.dispatch('initFreshdeskChat');
        },
        setUser: (store) => {
            const profile = store.rootGetters.user;

            if (!profile) return;

            window.fcWidget.user.create({
                externalId: profile.userId,
                firstName: profile.firstName,
                lastName: profile.lastName,
                email: profile.email,
            });
        },

        showFeedback: (store, next) => {
            if (!store.state.hasFeedback) return;

            store.commit('showFeedback', next);

            if (!next) return;

            const profile = store.rootGetters.user;

            const name = `${profile.firstName} ${profile.lastName}`.trim();

            const email = `${profile.email}`;

            const country = `${profile.country}`;

            window.FreshworksWidget('identify', 'ticketForm', {
                name,
                email,
            });

            window.FreshworksWidget('hide', 'ticketForm', ['subject']);

            window.FreshworksWidget('prefill', 'ticketForm', {
                custom_fields: {
                    cf_country: country,
                },
            });
        },
        showChat: (store, next) => {
            if (!store.state.hasChat) return;

            store.commit('showChat', next);

            if (!next) return;

            store.dispatch('setUser');
        },
        initFeedbackWidget: (store) => {
            return new Promise((resolve) => {
                const widgetId =
                    parseInt(
                        import.meta.env.VITE_FRESHDESK_FEEDBACK_WIDGET_ID,
                        10,
                    ) || 0;

                if (!widgetId) {
                    resolve(false);

                    return;
                }

                window.fwSettings = { widget_id: widgetId };

                if (typeof window.FreshworksWidget !== 'function') {
                    const n = function () {
                        n.q.push(arguments);
                    };

                    n.q = [];

                    window.FreshworksWidget = n;
                }

                const widgetScript = document.createElement('script');

                widgetScript.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;

                widgetScript.onload = () => {
                    store.commit('showFeedback', false);

                    resolve(true);
                };

                const body = document.getElementsByTagName('body')[0];

                body.appendChild(widgetScript);

                store.commit('hasFeedback', true);
            });
        },
        initFreshdeskChat: (store, { showChat = false }) => {
            return new Promise((resolve) => {
                const chatToken = import.meta.env.VITE_FRESHDESK_CHAT_TOKEN;

                const chatDomain = import.meta.env.VITE_FRESHDESK_CHAT_DOMAIN;

                if (!chatDomain || !chatToken || !chatToken.length) {
                    resolve(false);

                    return;
                }

                const chatScript = document.createElement('script');

                chatScript.src = 'https://wchat.freshchat.com/js/widget.js';

                chatScript.async = true;

                chatScript.id = 'freshchat-js-sdk';

                chatScript.chat = true;

                chatScript.onload = () => {
                    window.fcWidget.init({
                        token: chatToken,
                        host: chatDomain,
                    });

                    store.dispatch('setUser');

                    store.commit('showChat', showChat);

                    resolve();
                };

                const body = document.getElementsByTagName('body')[0];

                body.appendChild(chatScript);

                store.commit('hasChat', true);
            });
        },
        destroyFreshDeskChat: () => {
            window.fcWidget?.destroy();
        },
        sendFreshDeskEvent: (store, event) => {
            const { eventName, options } = event;

            console.log('Sending ' + eventName);

            if (window.fcWidget) {
                console.log('Sent ' + eventName);

                try {
                    window.fcWidget.track(eventName, options || {});
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
};
