import { getMathAnalogClockHtml } from '@/core/ui/_legacy/MathComponents/MathAnalogClock.js';
import {
    checkFraction,
    isObject,
    isSameByValue,
    pad,
    randomArrayElement,
    randomIntFromRange,
    shuffleArray,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
    KEYBOARD_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import { LocalizeTimeConversion } from '@/core/mixins/LocalizeTimeConversion';
import { getFractionLayout } from '@/core/math-topics/utils/fractions';

/**
 * @extends TopicsBaseClass
 */
export default class TimeTopic extends TopicsBaseClass {
    static code = 'TYPE_TIME';
    static icon = 'x';
    static gameTypeNameTranslationKey = 'game.gameTypeTitle.time';

    static getNumberGeneratorName(gameInfo) {
        const { numberGenerator } = gameInfo;

        const { topic, subtopic, levels } = numberGenerator;

        const topicName = this.t(`host.create.time.${topic}`);

        const subtopicName = this.t(`host.create.time.${subtopic}`);

        if (topic === 'conversion') {
            const localizedLevels = levels.map((level) =>
                LocalizeTimeConversion.methods.localizeLevelOption(level, true),
            );

            return `${topicName} - ${LocalizeTimeConversion.methods.localizeUnit(
                subtopic,
                true,
            )} (${localizedLevels.join(', ')})`;
        }

        return (
            `${topicName} - ${subtopicName} ` +
            `(${levels
                .map((level) =>
                    this.t(`host.create.time["${level}"]`, { hour: '' }),
                )
                .join(', ')})`
        );
    }

    static generateQuestion(numberGenerator) {
        if (!numberGenerator) {
            return;
        }

        const { topic, subtopic, levels, clockType, timeFormat } =
            numberGenerator;

        const level = randomArrayElement(levels);

        const locale = this.locale();

        if (topic === 'clock') {
            const hours =
                timeFormat === 24
                    ? randomIntFromRange(0, 23)
                    : randomIntFromRange(1, 12);

            let minutes;

            switch (subtopic) {
                case 'writingTime':
                    switch (level) {
                        case 'hours':
                            minutes = 0;
                            break;
                        case 'half-hours':
                            minutes = 30;
                            break;
                        case 'quarters':
                            minutes = randomArrayElement([15, 45]);
                            break;
                        case 'hours-minutes':
                            minutes = randomIntFromRange(0, 11) * 5;
                            break;
                    }

                    return {
                        topic,
                        subtopic,
                        level,
                        hours,
                        minutes,
                        clockType,
                        timeFormat,
                        answer: { hours, minutes },
                    };

                case 'timeWords':
                    switch (level) {
                        case 'o-clock':
                            minutes = 0;
                            break;
                        case 'quarter-past':
                            minutes = 15;
                            break;
                        case 'half-past':
                            minutes = 30;
                            break;
                        case 'quarter-to':
                            minutes = 45;
                            break;
                    }

                    let first = hours - 1;

                    let last = hours + 1;

                    if (first <= 0) {
                        first = timeFormat - 1;
                    }

                    if (hours === 12) {
                        last = 1;
                    }

                    let options = [first, hours, last];

                    options = shuffleArray(options);

                    let answer = hours;

                    // If hours is 12, set the aswer to 1, as in quarter to 1
                    if (level === 'quarter-to') {
                        answer = hours === 12 ? 1 : hours + 1;
                    }

                    // 12:15, 12:30
                    // In English we say quarter past 12 or half past 12.
                    // In Estonian, we say quarter 1 and half 1.
                    if (['quarter-past', 'half-past'].includes(level)) {
                        if (['et'].includes(locale)) {
                            answer = hours === 12 ? 1 : hours + 1;
                        }
                    }

                    return {
                        topic,
                        subtopic,
                        level,
                        hours,
                        minutes,
                        clockType,
                        timeFormat,
                        options,
                        answer,
                    };
            }
        } else if (topic === 'conversion') {
            let from,
                b,
                to = {};

            switch (level) {
                // hours, minutes, seconds
                // level 1
                case 'h -> min':
                    from = { h: randomIntFromRange(1, 12) };

                    to = { min: from.h * 60 };

                    break;

                case 'min -> sec':
                    from = { min: randomIntFromRange(1, 12) };

                    to = { sec: from.min * 60 };

                    break;

                case 'min -> h':
                    from = { min: randomIntFromRange(1, 10) * 60 };

                    to = { h: from.min / 60 };

                    break;

                case 'sec -> min':
                    from = { sec: randomIntFromRange(1, 10) * 60 };

                    to = { min: from.sec / 60 };

                    break;

                // level 2
                case 'h+min -> min':
                    from = {
                        h: randomIntFromRange(1, 5),
                        min: randomIntFromRange(0, 59),
                    };

                    to = { min: from.h * 60 + from.min };

                    break;

                case 'min+sec -> sec':
                    from = {
                        min: randomIntFromRange(1, 5),
                        sec: randomIntFromRange(0, 59),
                    };

                    to = { sec: from.min * 60 + from.sec };

                    break;

                case 'min -> h+min':
                    const h = randomIntFromRange(1, 5);

                    from = { min: h * 60 + randomIntFromRange(0, 59) };

                    to = { h, min: from.min - h * 60 };

                    break;

                case 'sec -> min+sec':
                    const min = randomIntFromRange(1, 5);

                    from = { sec: min * 60 + randomIntFromRange(0, 59) };

                    to = { min, sec: from.sec - min * 60 };

                    break;

                // level 3
                case 'a/b h -> min':
                    b = randomArrayElement([2, 3, 4, 5, 6, 10, 12]);

                    from = { h: { a: randomIntFromRange(1, b - 1), b } };

                    to = { min: (60 / b) * from.h.a };

                    break;

                case 'a/b min -> sec':
                    b = randomArrayElement([2, 3, 4, 5, 6, 10, 12]);

                    from = { min: { a: randomIntFromRange(1, b - 1), b } };

                    to = { sec: (60 / b) * from.min.a };

                    break;

                case 'min -> a/b h':
                    b = randomArrayElement([2, 3, 4, 5, 6, 10, 12]);

                    to = { h: { a: randomIntFromRange(1, b - 1), b } };

                    from = { min: (60 / b) * to.h.a };

                    break;

                case 'sec -> a/b min':
                    b = randomArrayElement([2, 3, 4, 5, 6, 10, 12]);

                    to = { min: { a: randomIntFromRange(1, b - 1), b } };

                    from = { sec: (60 / b) * to.min.a };

                    break;
                // hours,  days, weeks
                // level 1
                case 'd -> h':
                    from = { d: randomIntFromRange(1, 10) };

                    to = { h: from.d * 24 };

                    break;

                case 'w -> d':
                    from = { w: randomIntFromRange(1, 10) };

                    to = { d: from.w * 7 };

                    break;

                case 'h -> d':
                    from = { h: randomIntFromRange(1, 10) * 24 };

                    to = { d: from.h / 24 };

                    break;

                case 'd -> w':
                    from = { d: randomIntFromRange(1, 10) * 7 };

                    to = { w: from.d / 7 };

                    break;

                // level 2
                case 'd+h -> h':
                    from = {
                        d: randomIntFromRange(1, 5),
                        h: randomIntFromRange(0, 23),
                    };

                    to = { h: from.d * 24 + from.h };

                    break;

                case 'w+d -> d':
                    from = {
                        w: randomIntFromRange(1, 10),
                        d: randomIntFromRange(1, 6),
                    };

                    to = { d: from.w * 7 + from.d };

                    break;

                case 'h -> d+h':
                    from = {
                        h:
                            randomIntFromRange(1, 5) * 24 +
                            randomIntFromRange(0, 23),
                    };

                    to = {
                        d: Math.floor(from.h / 24),
                        h: from.h % 24,
                    };

                    break;

                case 'd -> w+d':
                    const w = randomIntFromRange(1, 10);

                    from = {
                        d: w * 7 + randomIntFromRange(0, 6),
                    };

                    to = { w, d: to.d - w * 7 };

                    break;

                // level 3
                case 'a/b d -> h':
                    b = randomArrayElement([2, 3, 4, 6, 8, 12, 24]);

                    from = { d: { a: randomIntFromRange(1, b - 1), b } };

                    to = { h: (24 / b) * from.d.a };

                    break;

                case 'h -> a/b d':
                    b = randomArrayElement([2, 3, 4, 6, 8, 12, 24]);

                    to = { d: { a: randomIntFromRange(1, b - 1), b } };

                    from = { h: Math.round((24 / b) * to.d.a) };

                    break;
            }

            return {
                topic,
                subtopic,
                level,
                from,
                to,
            };
        }
    }

    static formatQuestion(question, skill, calledIn) {
        const questionData =
            !['spLiveAnswer', 'inGame'].includes(calledIn) && question.answer
                ? question.answer
                : question.from;

        if (question.topic === 'conversion') {
            const getFraction = ({ a, b }) =>
                getFractionLayout({ numerator: a, denominator: b });

            const questionString = questionData.a
                ? getFraction(questionData) +
                  '&nbsp' +
                  Object.keys(questionData)
                : `${Object.keys(questionData)
                      .map(
                          (key) =>
                              `<div style="display: inline-flex; justify-items: center; align-items: center; margin-right: 3px">${
                                  isObject(questionData[key])
                                      ? `${getFraction(
                                            questionData[key],
                                        )}&nbsp${this.t(
                                            `host.create.time.units.${key}`,
                                        )}`
                                      : `${questionData[key]} ${this.t(
                                            `host.create.time.units.${key}`,
                                        )}`
                              }</div>`,
                      )
                      .join(' ')}`;

            if (calledIn === 'examples') {
                const units = Object.keys(question.to);

                if (units.length === 2) {
                    return `<div style="display: inline-flex; justify-items: center; align-items: center;">${questionString} = __${this.t(
                        `host.create.time.units.${units[0]}`,
                    )} __${this.t(`host.create.time.units.${units[1]}`)}</div>`;
                }

                return `<div style="display: inline-flex; justify-items: center; align-items: center;">${questionString} = __${this.t(
                    `host.create.time.units.${units[0]}`,
                )}</div>`;
            }

            if (['inGame', 'inPhoneStudentViewExample'].includes(calledIn)) {
                return `<div style="display: inline-flex; justify-items: center; align-items: center">${questionString} = ?</div>`;
            }

            return questionString;
        }

        let options;

        if (
            question.subtopic === 'timeWords' &&
            question.options &&
            question.options.length === 3 &&
            ['examples', 'report', 'playerStats'].includes(calledIn)
        ) {
            const lvl = question.level;

            options = `<div class="select-options">
                <div class="select-option">${this.t(
                    `host.create.time["${lvl}"]`,
                    {
                        hour: question.options[0],
                    },
                )}</div>
                <div class="select-option">${this.t(
                    `host.create.time["${lvl}"]`,
                    {
                        hour: question.options[1],
                    },
                )}</div>
                <div class="select-option">${this.t(
                    `host.create.time["${lvl}"]`,
                    {
                        hour: question.options[2],
                    },
                )}</div>
            </div>`;
        }

        if (question.clockType === 'analog' && calledIn !== 'report') {
            const clockHtml = getMathAnalogClockHtml(
                question.hours,
                question.minutes,
            );

            if (options) {
                return `
                    <div class="clock-wrapper clock-wrapper--options">
                        ${clockHtml + options}
                    </div>
                `;
            }

            const ampm = () => {
                if (question.timeFormat !== 24) {
                    return '';
                }

                return question.hours < 12
                    ? this.t('host.create.time.am')
                    : this.t('host.create.time.pm');
            };

            const exampleClass = [
                'inPhoneStudentViewExample',
                'examples',
            ].includes(calledIn)
                ? 'clock-example'
                : '';

            const inGameClass = calledIn === 'inGame' ? 'in-game' : '';

            return `
                <div class="clock-wrapper ${inGameClass}">
                    <div class="am-pm">${ampm()}</div>
                    <div class="clock ${exampleClass}">
                        ${clockHtml}
                    </div>
                </div>
            `;
        }

        if (options) {
            return `<div class="clock-example clock-example--options"><div class="clock-example-digital">${pad(
                questionData.hours || question.hours,
                2,
            )}:${pad(
                questionData.minutes || question.minutes,
                2,
            )}</div> ${options}</div>`;
        }

        return `${pad(question.hours, 2)}:${pad(question.minutes, 2)}`;
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        let playerAnswerInNumbers;

        const { hours, minutes } = {
            hours: Number(question.hours),
            minutes: Number(question.minutes),
        };

        const { topic } = numberGenerator;

        if (topic === 'conversion') {
            // prettier-ignore
            const flatQuestion = Object.assign({}, ...function _flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? _flatten(o[k]) : ({[k]: o[k]})))}(question.to))

            let flatAnswer = {};

            Object.keys(flatQuestion).forEach((key) => {
                flatAnswer[key] = 0;
            });

            Object.keys(answer).forEach((key) => {
                if (answer[key]) {
                    flatAnswer[key] = Number(answer[key] || 0);
                }
            });

            // if answer is fraction
            if (Object.keys(question.to).some((key) => question.to[key].a)) {
                return checkFraction(flatAnswer, flatQuestion);
            }

            return isSameByValue(flatAnswer, flatQuestion);
        }
        if (question.subtopic === 'writingTime') {
            playerAnswerInNumbers = {
                hours: Number(answer.hours),
                minutes: Number(answer.minutes),
            };

            return (
                playerAnswerInNumbers.hours === hours &&
                playerAnswerInNumbers.minutes === minutes
            );
        }

        if (question.subtopic === 'timeWords') {
            return question.answer.toString() === answer;
        }

        return false;
    }

    static formatPlayerAnswerData(
        question,
        playerAnswer,
        gameInfo,
        timer,
        timerPreviousAnswer,
    ) {
        const isCorrectAnswer = this.isAnswerCorrect(
            question,
            playerAnswer,
            gameInfo.numberGenerator,
        );

        let playerAnswerInNumbers;

        const clockType = question.clockType;

        const { hours, minutes } = {
            hours: Number(question.hours),
            minutes: Number(question.minutes),
        };

        const {
            type,
            numberGenerator: { topic, subtopic },
        } = gameInfo;
        let questionBody = {};
        if (topic === 'conversion') {
            // prettier-ignore
            const flatQuestion = Object.assign({}, ...function _flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? _flatten(o[k]) : ({[k]: o[k]})))}(question.to))

            let flatAnswer = {};

            Object.keys(playerAnswer)
                .filter((key) => !playerAnswer[key] || playerAnswer[key] !== '')
                .forEach((key) => {
                    if (playerAnswer[key]) {
                        flatAnswer[key] = Number(playerAnswer[key]);
                    }
                });

            questionBody = {
                type,
                topic,
                subtopic,
                from: question.from,
                answer: flatQuestion,
                level: question.level,
                clockType,
                playerAnswer: flatAnswer,
                correct: isCorrectAnswer,
                time: timer - timerPreviousAnswer,
            };
        } else {
            if (question.subtopic === 'writingTime') {
                playerAnswerInNumbers = {
                    hours: Number(playerAnswer.hours),
                    minutes: Number(playerAnswer.minutes),
                };

                questionBody = {
                    type,
                    topic,
                    subtopic,
                    answer: {
                        hours,
                        minutes,
                    },
                    hours,
                    minutes,
                    clockType,
                    timeFormat: question.timeFormat,
                    level: question.level,
                    playerAnswer: playerAnswerInNumbers,
                    correct: isCorrectAnswer,
                    time: timer - timerPreviousAnswer,
                };
            } else if (question.subtopic === 'timeWords') {
                questionBody = {
                    type,
                    topic,
                    subtopic,
                    answer: question.answer,
                    hours,
                    minutes,
                    options: question.options,
                    level: question.level,
                    clockType,
                    playerAnswer: playerAnswer,
                    correct: isCorrectAnswer,
                    time: timer - timerPreviousAnswer,
                };
            }
        }

        return { playerAnswer, isCorrectAnswer, questionBody };
    }

    static resetPlayerAnswer(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        if (topic === 'conversion') {
            return {
                sec: '',
                min: '',
                h: '',
                d: '',
                w: '',
                a: '',
                b: '',
            };
        }

        if (topic === 'clock' && subtopic === 'writingTime') {
            return { hours: '', minutes: '' };
        }

        return '';
    }

    static generatePlayerAnswerHtml(answer, numberGenerator, question) {
        const { topic, subtopic, clockType } =
            numberGenerator.numberGenerator || numberGenerator;

        const wrongSelector = question?.correct ? '' : 'wrong-answer';

        if (topic === 'clock' && subtopic === 'timeWords') {
            return `<span>${this.t(`host.create.time["${question.level}"]`, {
                hour: answer,
            })}</span>`;
        }

        if (topic === 'conversion') {
            const questionKeys = Object.keys(question.to);

            Object.keys(answer).forEach((key) => {
                if (
                    !questionKeys.includes(key) &&
                    !Object.keys(answer[key]).length
                ) {
                    delete answer[key];
                }
            });

            return `= <span class="${wrongSelector}">${
                answer.a
                    ? getFractionLayout(
                          { numerator: answer.a, denominator: answer.b },
                          !question?.correct,
                      )
                    : Object.keys(answer)
                          .map(
                              (key) =>
                                  `${
                                      answer[key] || 0
                                  } ${LocalizeTimeConversion.methods.translateUnit(
                                      key,
                                      true,
                                  )}`,
                          )
                          .join(' + ')
            }</span>`;
        }

        if (
            topic === 'clock' &&
            clockType === 'analog' &&
            subtopic === 'writingTime'
        ) {
            return ` = <span class="${wrongSelector}">${Object.keys(answer)
                .map((key) => {
                    if (key === 'minutes') {
                        return String(answer[key]).padStart(2, '0');
                    } else {
                        return answer[key];
                    }
                })
                .join(':')}</span>`;
        }

        return ` = <span class="${wrongSelector}">${Object.keys(answer)
            .map((key) => `${answer[key]} ${key}`)
            .join(', ')}</span>`;
    }

    static generateCorrectAnswerHtml(question) {
        const { topic, subtopic, clockType } = question;

        if (topic === 'clock' && subtopic === 'timeWords') {
            return `<span>${this.t(`host.create.time["${question.level}"]`, {
                hour: question.answer,
            })}</span>`;
        }
        const fractionsAnswer = ({ a, b }) =>
            getFractionLayout({ numerator: a, denominator: b });

        if (topic === 'conversion') {
            if (question?.answer?.a) {
                return fractionsAnswer(question.answer);
            } else if (question?.to) {
                const answer = question.to;

                const symbol = Object.keys(answer)[0];

                if (answer?.[symbol]?.a && answer?.[symbol]?.b) {
                    return fractionsAnswer(answer[symbol]);
                } else {
                    return `= <span>${answer[symbol]}${symbol}</span>`;
                }
            } else {
                return `= <span>${Object.keys(question.answer)
                    .map(
                        (key) =>
                            `${
                                question.answer[key]
                            } ${LocalizeTimeConversion.methods.translateUnit(
                                key,
                            )}`,
                    )
                    .join('+')}</span>`;
            }
        }

        if (
            topic === 'clock' &&
            clockType === 'analog' &&
            subtopic === 'writingTime'
        ) {
            console.log(question);

            return ` = <span>${Object.keys(question.answer)
                .map((key) => {
                    if (key === 'minutes') {
                        return String(question.answer[key]).padStart(2, '0');
                    } else {
                        return question.answer[key];
                    }
                })
                .join(':')}</span>`;
        }

        return ` = <span>${Object.keys(question.answer)
            .map((key) => `${question.answer[key]} ${key}`)
            .join(', ')}</span>`;
    }

    static getAnswerInputType(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        if (topic === 'conversion') {
            return ANSWER_INPUT_TYPE.TIME_CONVERSION;
        }

        if (topic === 'clock' && subtopic === 'writingTime') {
            return ANSWER_INPUT_TYPE.TIME;
        }

        return ANSWER_INPUT_TYPE.SELECT;
    }

    static getKeyboardType(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        if (topic === 'conversion') {
            return KEYBOARD_TYPE.NUMERIC;
        }

        if (topic === 'clock' && subtopic === 'writingTime') {
            return KEYBOARD_TYPE.NUMERIC;
        }

        return KEYBOARD_TYPE.ENTER;
    }

    static showArrowButtons(numberGenerator) {
        const { topic, subtopic } = numberGenerator;

        if (topic === 'conversion') {
            // check if there is only single field for conversion
            // and not show arrows at this case
            return numberGenerator.levels.some(
                (l) => l.includes('+') || l.includes('/'),
            );
        }
        return topic && 'clock' && subtopic === 'writingTime';
    }
}
