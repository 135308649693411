/* global mixpanel */
export const HOST_LIVE_GAME_MP_EVENTS = {
    LIVE_GAME_CREATED: 'createdLiveGame',
    LIVE_GAME_CODE_VIEW: 'liveGameCodeView',
    LIVE_GAME_COPIED_INVITE_LINK: 'copiedLiveGameInviteLink',
    LIVE_GAME_JOINED_GAME: 'joinedLiveGameAsPlayer',
    LIVE_GAME_PREGAME_VIEW: 'preLiveGameView',
    LIVE_GAME_STARTED: 'startedLiveGame',
    LIVE_GAME_OPENED_LIVE_LEADERBOARD: 'openedLiveLeaderboard',
    LIVE_GAME_ROUND_LEADERBOARD: 'loadedRoundLeaderboard',
    LIVE_GAME_FINISHED: 'finishedLiveGame',
    LIVE_GAME_STARTED_PLAYING: 'startedPlayingInLiveGame',
    LIVE_GAME_FINISHED_PLAYING: 'finishedPlayingInLiveGame',
    LIVE_GAME_CHANGE_COMMON_MISTAKES_STATE: 'changeCommonMistakesState',
    LIVE_GAME_CLICKED_SHOW_COMMON_MISTAKES: 'clickedShowCommonMistakes',
    LIVE_GAME_CLICKED_HIDE_COMMON_MISTAKES: 'clickedHideCommonMistakes',
    LIVE_GAME_CLICKED_REPEAT_GAME: 'clickedRepeatGame',
    REGISTERED_MATH_MADNESS_TEAM: 'registeredMathMadnessTeam',
};

export const HOST_LIVE_GAME_MP_METHODS = {
    createdLiveGame({
        gameCode,
        fromPlayAgain,
        numberOfSkillSelected,
        whereClicked,
    }) {
        mixpanel.track('Created live game', {
            Gamecode: gameCode,
            'From play again': !!fromPlayAgain,
            'Number of skills selected': numberOfSkillSelected,
            'Where clicked on play button': whereClicked,
        });
    },
    liveGameCodeView({ gameCode }) {
        mixpanel.track('Lobby view', {
            Gamecode: gameCode,
        });
    },
    copiedLiveGameInviteLink(gameCode) {
        mixpanel.track('Copied live game invite link', {
            Gamecode: gameCode,
        });
    },
    joinedLiveGameAsPlayer({ gameCode, gameCreatorRole }) {
        mixpanel.track('Joined live game', {
            Gamecode: gameCode,
            'Game creator role': gameCreatorRole,
        });
    },
    preLiveGameView(gameCode) {
        mixpanel.track('Pre game view', {
            Gamecode: gameCode,
        });
    },
    startedLiveGame({
        gameCode,
        playerCount,
        rounds,
        roundLength,
        taskType,
        taskScale,
        className,
        location,
        locationDetail,
        loggedInPlayerCount,
    }) {
        const currentDateTime = new Date();
        let dataLiveGame = {
            Gamecode: gameCode,
            'Player count': playerCount,
            'Logged in player count': loggedInPlayerCount,
            Rounds: rounds,
            'Round length': roundLength,
            'Task type': taskType,
            'Task scale': taskScale,
            'Played with class': !!className,
            'Class name': className || '',
            'Local timestamp': Date.now(),
            Location: location,
            'Location detail': locationDetail,
        };

        mixpanel.track('Started game', { ...dataLiveGame });

        mixpanel.people.increment('games_played');

        mixpanel.people.set_once({
            $first_game_played: currentDateTime.toISOString(),
        });

        mixpanel.people.set({
            $last_game_played: currentDateTime.toISOString(),
        });
    },
    openedLiveLeaderboard(gameCode) {
        mixpanel.track('Opened live leaderboard', {
            Gamecode: gameCode,
        });
    },
    loadedRoundLeaderboard(round) {
        mixpanel.track(`Loaded Leaderboard ${round}`);
    },
    finishedLiveGame({ totalRounds, className, counter, problemsSolved }) {
        if (counter) {
            mixpanel.people.set({
                'Game Day Nr': counter,
            });
        }
        mixpanel.track('Finished game', {
            'Number of rounds': totalRounds,
            'Played with class': !!className,
            'Class name': className || '',
            'Problems solved': problemsSolved,
        });
    },
    startedPlayingInLiveGame({ gameCode, taskType, taskScale, creatorRole }) {
        mixpanel.track('Started playing in live game', {
            Gamecode: gameCode,
            'Task type': taskType,
            'Task scale': taskScale,
            'Game creator role': creatorRole,
        });
    },
    finishedPlayingInLiveGame({ gameCode, newXp }) {
        mixpanel.people.increment('XP from games', newXp);

        mixpanel.track('Finished playing live game', {
            Gamecode: gameCode,
            'New XP': newXp,
        });
    },
    changeCommonMistakesState(isOpened) {
        mixpanel.people.set({
            'Common mistakes state': isOpened ? 'Open' : 'Closed',
        });
    },
    clickedShowCommonMistakes({ gameCode }) {
        mixpanel.track('Clicked Show Common mistakes', {
            Gamecode: gameCode,
        });
    },
    clickedHideCommonMistakes({ gameCode }) {
        mixpanel.track('Clicked Hide Common mistakes', {
            Gamecode: gameCode,
        });
    },
    clickedRepeatGame() {
        mixpanel.track('Clicked Repeat Game btn');
    },
    registeredMathMadnessTeam(teamName) {
        mixpanel.track('Registered Math Madness team', {
            'Team name': teamName,
        });
    },
};
