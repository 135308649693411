/* global mixpanel */
export const AB_TEST_MP_EVENTS = {
    V4_VIDEO_TEACHER_SEES_MODAL: 'v4VideoSeesModal',
    V4_VIDEO_TEACHER_SEES_VIDEO: 'v4VideoSeesVideo',
    V4_VIDEO_TEACHER_CLOSES_MODAL: 'v4VideoClosesModal',
    VIDEO_FINISHED: 'videoFinished',
    VIDEO_STARTED: 'videoStarted',
    CLICKED_VIDEO_TUTORIAL_BUTTON: 'clickedVideoTutorialButton',
    CLICKED_GOT_IT_VIDEO_TUTORIAL_BUTTON: 'clickedGotItVideoTutorialButton',
    CLICKED_IN_VIDEO_TUTORIAL_BUTTON: 'clickedInVideoTutorialButton',
    CLICKED_COUPON_WIDGET: 'clickedCouponWidget',
    COUPON_APPLIED: 'couponApplied',
};

export const AB_TEST_MP_METHODS = {
    v4VideoSeesModal() {
        mixpanel.track('V4 teacher sees modal');
    },
    v4VideoSeesVideo() {
        mixpanel.track('V4 teacher sees video');
    },
    v4VideoClosesModal(time) {
        mixpanel.track('V4 teacher closes modal', {
            'Watch time': time,
        });
    },
    videoStarted(title) {
        mixpanel.track('V11 teacher starts video', {
            'Video title': title,
        });
    },
    videoFinished(time) {
        mixpanel.track('V11 teacher finishes video', {
            'Watch time': time,
        });
    },
    clickedVideoTutorialButton() {
        mixpanel.track('V11 Clicked "See how it works" video tutorial button');
    },
    clickedGotItVideoTutorialButton() {
        mixpanel.track('V11 Clicked "Got it" video tutorial button');
    },
    clickedInVideoTutorialButton() {
        mixpanel.track('V11 Clicked post video tutorial cta button');
    },
    clickedCouponWidget() {
        mixpanel.track('Clicked coupon widget');
    },
    couponApplied() {
        mixpanel.track('Coupon applied');
    },
};
