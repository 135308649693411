<template>
    <div v-if="!showTimer" class="revamp-energy">
        <div class="battery-row">
            <img
                class="energy-icon"
                src="/images/lightning-vertical.svg"
                alt="energy"
            />
            <div class="revamp-energy-current">{{ currentEnergy }}</div>
            <img
                alt="buy energy"
                class="revamp-energy-plus"
                src="/images/currency/plus-circle.svg"
                @click="openShop()"
            />
        </div>
        <div class="revamp-energy-timer">
            <template v-if="secondsToGetOneEnergyFormatted === 'MAX'">
                MAX
            </template>
            <template v-else>
                +1
                <img
                    class="energy-icon-timer"
                    src="/images/lightning-vertical.svg"
                    alt="energy"
                />
                in
                {{ secondsToGetOneEnergyFormatted }}
            </template>
        </div>
    </div>
    <div v-else class="energy-timer">
        {{ secondsToGetOneEnergyFormatted ?? 0 }}
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment/moment';

/**
 * TODO: Cloned from RevampEnergy component. Consider refactoring for re-use.
 */
export default {
    props: {
        showTimer: {
            type: Boolean,
            default: false,
        },
        withTimer: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: '',
        },
    },
    emits: ['timer', 'open-energy-modal'],
    data: function () {
        return {
            secondsToGetOneEnergy: null,
            interval: null,
        };
    },
    computed: {
        ...mapGetters(['user', 'subscribedUser']),
        currentEnergy() {
            return this.user?.studentInfo?.arenaEnergy?.current;
        },
        maxEnergy() {
            return this.user?.studentInfo?.arenaEnergy?.max;
        },
        energyRegenBegin() {
            return this.user?.studentInfo?.arenaEnergy?.regenBegin;
        },
        energyRefreshInSeconds() {
            return this.user?.studentInfo?.arenaEnergy?.regenSeconds;
        },
        secondsToGetOneEnergyFormatted() {
            if (moment && this.secondsToGetOneEnergy) {
                this.$emit(
                    'timer',
                    moment(
                        moment('2015-01-01')
                            .startOf('day')
                            .seconds(this.secondsToGetOneEnergy),
                    ).format('mm:ss'),
                );
            }

            if (this.secondsToGetOneEnergy <= 0) {
                return 'MAX';
            }

            return moment && this.secondsToGetOneEnergy
                ? moment(
                      moment('2015-01-01')
                          .startOf('day')
                          .seconds(this.secondsToGetOneEnergy),
                  ).format('mm:ss')
                : null;
        },
        isEmpty() {
            return this.currentEnergy < 1;
        },
    },
    watch: {
        user() {
            this.setTimeToLife();
        },
    },
    async created() {
        if (
            (!this.user?.studentInfo?.arenaEnergy &&
                this.user?.studentInfo?.arenaEnergy?.current !== 0) ||
            (this.subscribedUser &&
                this.user?.studentInfo?.arenaEnergy?.max === 3)
        ) {
            await this.$store.dispatch('v2/mathRunner/refillEnergy');
        }
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    mounted() {
        this.setTimeToLife();
    },
    methods: {
        ...mapActions('v2/user', ['fetchStudentInfo']),
        openShop() {
            this.$emitter.emit(
                'openHomeGamePaymentModal',
                'arena-energy-widget',
            );
        },
        setTimeToLife() {
            if (
                this.energyRegenBegin &&
                this.currentEnergy !== this.maxEnergy
            ) {
                const difference = moment().diff(moment(this.energyRegenBegin));

                const elapsedSeconds = Math.floor(
                    moment.duration(difference).asSeconds(),
                );

                const elapsed = elapsedSeconds % this.energyRefreshInSeconds;

                this.secondsToGetOneEnergy =
                    this.energyRefreshInSeconds - elapsed;

                clearInterval(this.interval);

                this.interval = setInterval(() => {
                    this.secondsToGetOneEnergy--;

                    if (this.secondsToGetOneEnergy === 0) {
                        this.secondsToGetOneEnergy =
                            this.energyRefreshInSeconds;

                        this.fetchStudentInfo();
                    }
                }, 1000);
            } else {
                this.secondsToGetOneEnergy = null;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.revamp-energy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
}
.battery-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.energy-icon {
    width: 33px;
    height: 33px;
    position: absolute;
    left: -15px;
    z-index: 2;

    @include mq($w-max-tablet, max) {
        width: 28px;
        height: 28px;
    }
}
.energy-icon-timer {
    width: 16px;
    height: 16px;

    @include mq($w-max-tablet, max) {
        width: 14px;
        height: 14px;
    }
}
.battery {
    @include mq($w-max-tablet, max) {
        height: 26px;
    }
}
.revamp-energy-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 16px;
    font-weight: 700;
    background: #191319;
    min-width: 90px;

    .revamp-energy-counter {
        width: 40px;
        margin-left: 4px;
    }

    @include mq($h-max-under-chromebook, max, height) {
        font-size: 14px;
    }
}
.revamp-energy-current {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 2px 8px;
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 16px;
    font-weight: 700;
    background: #191319;
    min-width: 60px;

    @include mq($h-max-under-chromebook, max, height) {
        font-size: 14px;
    }
}
.energy-timer {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    background: #191319;
    width: 100px;
    padding: 0 2px;
}
.revamp-energy-plus {
    width: 33px;
    height: 33px;
    cursor: pointer;

    @include mq($w-max-tablet, max) {
        width: 28px;
        height: 28px;
    }
}
</style>
