<template>
    <div class="content">
        <div class="primary">
            <div class="text">
                {{ $t('homeGame.getPremium') }}
            </div>
            <div class="secondary-text">
                {{ $t('homeGame.andBoost') }}
            </div>
            <div class="price-text">$5 {{ $t('homeGame.perMonth') }}</div>
            <div class="cancel-text">
                {{ $t('homeGame.cancel') }}
            </div>
        </div>

        <div class="big-text">
            {{ $t('homeGame.wohoo') }}
        </div>

        <div class="kinda-big-text">
            {{ $t('homeGame.thankYou') }}
        </div>
        <div class="big-text" />

        <div class="cta" @click="$emit('advance')">
            <div class="text">
                {{ $t('homeGame.goPlay') }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits: ['advance'],
};
</script>

<style lang="scss" scoped>
.content {
    color: #572598;
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        color: white;
        padding: 30px 0 0;
        width: 100%;
        position: relative;
        background-color: #482c6e;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .text {
            font-size: 29px;
            font-weight: bold;
        }

        .secondary-text {
            font-size: 21px;
            margin-top: 10px;
        }

        .price-text {
            margin-top: 24px;
            font-size: 21px;
            font-weight: 500;
        }

        .cancel-text {
            margin-top: 6px;
            font-size: 17px;
            color: rgba($color: #fff, $alpha: 0.6);
        }

        &::after {
            position: absolute;
            bottom: -20px;
            z-index: 100;
            content: '';
            width: 100%;
            height: 20px;
            background-color: #482c6e;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    .small-text {
        font-size: 15px;
        font-weight: normal;
    }
    .kinda-big-text {
        font-size: 24px;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
        color: #43157f;
    }
    .big-text {
        font-size: 28px;
        font-weight: bold;
        margin: 26px 0;
        text-align: center;
    }
    .credit-card-info {
        text-align: center;
        margin-top: 26px;
    }
    .cta {
        color: white;
        position: absolute;
        bottom: -30px;
        background-color: #ef8a4f;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 18px 20px;
        height: 70px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);

        @include mq($w-min-desktop, min) {
            max-width: 340px;
            margin: auto;
        }

        &:hover {
            background-color: darken($color: #ef8a4f, $amount: 5);
            cursor: pointer;
        }
        .text {
            font-size: 30px;
            font-weight: bold;
        }
        img {
            margin-left: 22px;
        }
    }
}
@include mq($w-max-phone, max) {
    .content {
        .kinda-big-text {
            font-size: 20px;
            font-weight: bold;
            margin: 12px 0;
            text-align: center;
            color: #43157f;
        }

        .big-text {
            font-size: 24px;
            font-weight: bold;
            margin: 16px 0;
            text-align: center;
        }
    }
}
</style>
