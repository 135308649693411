export const BrowserDetect = {
    methods: {
        // https://dev.to/shwetadanej/detect-browser-and-its-version-in-javascript-dg9
        getBrowserInfo() {
            var navUserAgent = navigator.userAgent;
            var browserName = navigator.appName;
            var browserVersion = '' + parseFloat(navigator.appVersion);
            var majorVersion = parseInt(navigator.appVersion, 10);
            var tempNameOffset, tempVersionOffset, tempVersion;

            if ((tempVersionOffset = navUserAgent.indexOf('Opera')) != -1) {
                browserName = 'Opera';
                browserVersion = navUserAgent.substring(tempVersionOffset + 6);

                if ((tempVersionOffset = navUserAgent.indexOf('Version')) != -1)
                    browserVersion = navUserAgent.substring(
                        tempVersionOffset + 8,
                    );
            } else if (
                (tempVersionOffset = navUserAgent.indexOf('MSIE')) != -1
            ) {
                browserName = 'Microsoft Internet Explorer';

                browserVersion = navUserAgent.substring(tempVersionOffset + 5);
            } else if (
                (tempVersionOffset = navUserAgent.indexOf('Chrome')) != -1
            ) {
                browserName = 'Chrome';

                browserVersion = navUserAgent.substring(tempVersionOffset + 7);
            } else if (
                (tempVersionOffset = navUserAgent.indexOf('Safari')) != -1
            ) {
                browserName = 'Safari';

                browserVersion = navUserAgent.substring(tempVersionOffset + 7);

                if (
                    (tempVersionOffset = navUserAgent.indexOf('Version')) != -1
                ) {
                    browserVersion = navUserAgent.substring(
                        tempVersionOffset + 8,
                    );
                }
            } else if (
                (tempVersionOffset = navUserAgent.indexOf('Firefox')) != -1
            ) {
                browserName = 'Firefox';

                browserVersion = navUserAgent.substring(tempVersionOffset + 8);
            } else if (
                (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
                (tempVersionOffset = navUserAgent.lastIndexOf('/'))
            ) {
                browserName = navUserAgent.substring(
                    tempNameOffset,
                    tempVersionOffset,
                );

                browserVersion = navUserAgent.substring(tempVersionOffset + 1);

                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }

            // trim version
            if ((tempVersion = browserVersion.indexOf(';')) != -1) {
                browserVersion = browserVersion.substring(0, tempVersion);
            }

            if ((tempVersion = browserVersion.indexOf(' ')) != -1) {
                browserVersion = browserVersion.substring(0, tempVersion);
            }

            return {
                BrowserName: browserName,
                Version: browserVersion,
                MajorVersion: majorVersion,
            };
        },
        // https://medium.com/@justenphelps/mobile-os-detection-1f14b46565cc
        getOSInfo() {
            // This script sets OSName variable as follows:
            // "Windows"    for all versions of Windows
            // "MacOS"      for all versions of Macintosh OS
            // "Linux"      for all versions of Linux
            // "UNIX"       for all other UNIX flavors
            // "Unknown OS" indicates failure to detect the OS

            var OSName = 'Unknown OS';

            var version = null;

            if (navigator.appVersion.indexOf('Win') != -1) {
                OSName = 'Windows';
            }

            if (navigator.appVersion.indexOf('Mac') != -1) {
                OSName = 'MacOS';
            }

            if (navigator.appVersion.indexOf('X11') != -1) {
                OSName = 'UNIX';
            }

            if (navigator.appVersion.indexOf('Linux') != -1) {
                OSName = 'Linux';
            }

            if (navigator.appVersion.indexOf('iPhone') != -1) {
                OSName = 'iOS';
            }

            if (navigator.appVersion.indexOf('Android') != -1) {
                OSName = 'Android';
            }

            if (OSName === 'iOS') {
                version = this.getiOSversion();
            }

            if (OSName === 'Android') {
                version = this.getAndroidVersion();
            }

            return {
                OS: OSName,
                version: version,
            };
        },
        // https://stackoverflow.com/questions/7184573/pick-up-the-android-version-in-the-browser-by-javascript
        getAndroidVersion() {
            var ua = (ua || navigator.userAgent).toLowerCase();

            var match = ua.match(/android\s([0-9.]*)/i);

            return match ? match[1] : undefined;
        },
        // https://gist.github.com/ngoclt/2857281
        getiOSversion() {
            var agent = window.navigator.userAgent,
                start = agent.indexOf('OS ');

            if (
                (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
                start > -1
            ) {
                return window.Number(
                    agent.substr(start + 3, 3).replace('_', '.'),
                );
            }

            return 0;
        },
    },
};
