/* global mixpanel */
export const PREMIUM_EVENTS = {
    OPEN_DISCOVER_PREMIUM_MODAL: 'openDiscoverPremiumModal',
    OPEN_PREMIUM_TRIAL_END_MODAL: 'openPremiumTrialEndModal',
    OPEN_PREMIUM_WELCOME_MODAL: 'openPremiumWelcomeModal',
    OPEN_PREMIUM_PRICING_PAGE: 'openPremiumPricingPage',
    SEND_PREMIUM_TO_ADMIN: 'sendPremiumToAdmin',
    STARTED_PREMIUM_TRIAL: 'startedPremiumTrial',
    WELCOME_PREMIUM_TRIAL_MODAL_OPENED_BY_LINK:
        'welcomePremiumTrialModalOpenedByLink',
    PREMIUM_TRIAL_END_MODAL_OPENED_BY_LINK: 'premiumTrialEndModalOpenedByLink',
    PREMIUM_TRIAL_DISCOVER_MODAL_OPENED_BY_LINK:
        'premiumTrialDiscoverModalOpenedByLink',
    PREMIUM_TRIAL_WELCOME_OR_PREMIUM_MODAL_OPENED_BY_LINK:
        'premiumTrialWelcomeOrPremiumModalOpenedByLink',
    CLICK_ON_TRIAL_DROPDOWN_OPTION: 'clickOnTrialDropdownOption',
    CLICK_ON_PRICING_OPTION: 'clickOnPricingOption',
    STUDENT_CLICK_ON_ST_BUTTON_ON_THE_CITY_HUB:
        'studentClickOnSTButtonOnTheCityHub',
    STUDENT_CLICK_ON_AT_BUTTON_ON_THE_CITY_HUB:
        'studentClickOnATButtonOnTheCityHub',
};

export const PREMIUM_METHODS = {
    openDiscoverPremiumModal({ location, mode, version }) {
        mixpanel.track('Sees Premium pop-up', {
            Location: location,
            'Modal Name': mode,
            'Modal Version': version,
        });
    },
    sendPremiumToAdmin({ location, email }) {
        mixpanel.track('Send Premium to admin', {
            Location: location,
            Email: email,
        });
    },
    startedPremiumTrial({ location }) {
        mixpanel.track('Started trial', {
            Location: location,
        });
    },
    openPremiumTrialEndModal({ location }) {
        mixpanel.track('Sees Premium finished trial modal', {
            Location: location,
        });
    },
    openPremiumWelcomeModal({ location }) {
        mixpanel.track('Sees Premium welcome modal', {
            Location: location,
        });
    },
    openPremiumPricingPage({ location }) {
        mixpanel.track('Sees Premium pricing page', {
            Location: location,
        });
    },
    welcomePremiumTrialModalOpenedByLink({ location }) {
        mixpanel.track('Premium welcome trial modal opened by link', {
            Location: location,
        });
    },
    premiumTrialEndModalOpenedByLink({ location }) {
        mixpanel.track('Premium finished trial modal opened by link', {
            Location: location,
        });
    },
    premiumTrialDiscoverModalOpenedByLink({ location }) {
        mixpanel.track('Premium discover trial modal opened by link', {
            Location: location,
        });
    },
    premiumTrialWelcomeOrPremiumModalOpenedByLink({ location, modal }) {
        mixpanel.track('Premium welcome or premium modal opened by link', {
            Location: location,
            Modal: modal,
        });
    },
    clickOnTrialDropdownOption({ location, option }) {
        mixpanel.track('Click on trial dropdown option', {
            Location: location,
            Option: option,
        });
    },
    clickOnPricingOption({ option, country }) {
        mixpanel.track('Click on pricing option', {
            Option: option,
            Country: country,
        });
    },
    studentClickOnSTButtonOnTheCityHub() {
        mixpanel.track('Student click on ST button on the city hub');
    },
    studentClickOnATButtonOnTheCityHub() {
        mixpanel.track('Student click on AT button on the city hub');
    },
};
