<template>
    <button
        :disabled="disabled"
        :class="`math-button math-button--${theme} math-button--${size} ${
            clickedTheme ? `math-button--${theme}--clicked` : ''
        }`"
        :type="type"
        @click="$emit('click')"
    >
        <template v-if="theme === 'register'">
            <div class="math-button__icon">
                <slot name="icon" />
            </div>
            <div class="math-button__content">
                <slot />
            </div>
        </template>
        <template v-else-if="theme === 'with-icon'">
            <span><slot /></span>
        </template>
        <template v-else>
            <slot />
        </template>
        <svg
            v-if="clickedTheme"
            class="checkmark"
            width="12"
            height="11"
            viewBox="0 0 12 11"
            :fill="themeBasedColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.59503 10.1147C4.48048 10.2299 4.3242 10.2942 4.16185 10.2942C3.99951 10.2942 3.84323 10.2299 3.72868 10.1147L0.26926 6.65467C-0.0897532 6.29565 -0.0897532 5.71349 0.26926 5.35515L0.702431 4.92187C1.06156 4.56286 1.64304 4.56286 2.00206 4.92187L4.16185 7.08178L9.99794 1.24558C10.3571 0.886565 10.9391 0.886565 11.2976 1.24558L11.7307 1.67886C12.0898 2.03787 12.0898 2.61992 11.7307 2.97838L4.59503 10.1147Z"
                :fill="themeBasedFill"
            />
        </svg>
    </button>
</template>
<script>
export default {
    props: {
        theme: { type: String, default: 'primary' },
        size: { type: String, default: 'md' },
        clickedTheme: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        type: { type: String, default: 'button' },
    },
    emits: ['click'],
    computed: {
        themeBasedColor() {
            return this.theme === 'secondary' ? 'white' : 'none';
        },
        themeBasedFill() {
            return this.theme === 'secondary' ? '#FFFFFF' : '#333333';
        },
    },
};
</script>

<style lang="scss" scoped>
.math-button {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    background: #f08a4f;
    box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
    border-radius: 10px;
    min-height: 58px;
    padding: 0 32px;
    border: 0;

    &:hover {
        text-decoration: none;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    .checkmark {
        padding-left: 6px;
    }

    &--primary {
        background: #f08a4f;

        &:hover,
        &:active {
            background-color: darken($color: #f08a4f, $amount: 10%);
        }
    }

    &--secondary {
        background: #a166f0;

        &--clicked {
            background: #572598;
        }

        &:hover,
        &:active {
            background: #572598;
        }

        &[disabled] {
            opacity: 0.5;
            cursor: default;
        }
    }

    &--tertiary {
        color: #000000;
        height: 40px;
        background-color: #ffc75a;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
        padding: 0 32px;

        &--clicked {
            background: darken($color: #ffc75a, $amount: 10%);
        }
    }

    &--quaternary {
        background: #572598;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
        border-radius: 4px;
        font-weight: normal;

        &:hover,
        &:active {
            background-color: darken($color: #572598, $amount: 10%);
        }
    }

    &--quaternary-inverse {
        background: transparent;
        color: #572598;
        border-color: #572598;
        box-shadow: 0 4px 10px rgba(87, 37, 152, 0.2);
        border-radius: 4px;
        font-weight: normal;

        &.active,
        &:hover,
        &:active {
            background-color: #572598;
            color: #ffffff;
        }
    }

    &--lg {
        min-height: 72px;
        border-radius: 10px;
        font-size: 23px;
    }

    &--sm {
        min-height: 40px;
        border-radius: 4px;
    }

    &--register {
        background: #ffffff;
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #4d4d4d;
        padding: 18px 22px;

        .math-button__icon {
            margin-right: auto;
            width: 22px;
            height: 22px;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .math-button__content {
            width: 100%;
        }
    }

    &--plain {
        text-decoration-line: underline;
        background-color: transparent;
        color: #fff;
        box-shadow: unset;
    }

    &--green {
        background-color: #14c1bf;

        &:hover,
        &:active {
            background-color: darken($color: #14c1bf, $amount: 10%);
        }
    }

    &--danger {
        background-color: #c11414;

        &:hover,
        &:active {
            background-color: darken($color: #c11414, $amount: 10%);
        }
    }

    &--banner {
        border-radius: 4px;
        border: 2px solid #b98727;
        background: transparent;
        color: #000;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
}
</style>
