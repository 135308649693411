import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import store from '@/store';
import { isObject, randomIntFromRange } from '@/core/helpers/utils';
import { jwtDecode, InvalidTokenError } from 'jwt-decode';
import { replaceUmlauts } from '@/core/helpers/utils';

export function fetchAndUpdateUser() {
    return SecureApi()
        .post(`/auth/get/user`)
        .then((response) => {
            const result = response.data;

            const { userDataToken, authToken } = result.data;

            const user = getUserFromJwtToken(userDataToken);

            if (user) {
                localStorage.setItem('userDataToken', userDataToken);
                localStorage.setItem('authToken', authToken);

                if (user.playerName) {
                    sessionStorage.setItem('playerName', user.playerName);
                    store.commit('setPlayerName', { name: user.playerName });
                }

                store.dispatch('setUserInfoToStore', user);
                store.dispatch('v2/user/setActiveClassCode', {
                    classCode: user.activeClass?.classCode,
                });
            }
        })
        .catch((error) => {
            console.error('fetchAndUpdateUser error: ', error);

            localStorage.removeItem('authToken');
        });
}

export const getUserFromJwtToken = (token) => {
    try {
        const tokenData = jwtDecode(token);

        if (tokenData) {
            return { ...tokenData };
        }
    } catch (e) {
        // Log out only unknown errors.
        if (e instanceof InvalidTokenError) {
            // Invalid token is an expected error when the token is invalid.
        } else {
            console.error('vAGUFJT error: ', e);
        }
    }
    return false;
};

export const isTeacher = (user) => {
    if (!user || !isObject(user)) {
        return false;
    }

    if (Object.prototype.hasOwnProperty.call(user, 'role')) {
        return user.role === 'teacher';
    }

    return user.roles.includes('teacher');
};

export const isParent = (user) => {
    if (!user || !isObject(user)) {
        return false;
    }

    if (Object.prototype.hasOwnProperty.call(user, 'role')) {
        return user.role === 'parent';
    }

    return user.roles.includes('parent');
};

export const isStudent = (user) => {
    if (!user || !isObject(user)) {
        return false;
    }

    if (Object.prototype.hasOwnProperty.call(user, 'role')) {
        return user.role === 'student';
    }

    return user.roles.includes('student');
};

export const clearUpFirstName = (user) => {
    return user.firstName.replace(/\s+/g, '_');
};

export const getRandomPlayerName = (firstName) => {
    const randomNumber = randomIntFromRange(10, 99);

    // The randomNumber will always be either 1 or 2 digits long,
    // so make sure the name doesn't exceed 15 chars.
    const firstNameChopped =
        String(firstName).length > 13
            ? String(firstName).slice(0, 13)
            : firstName;

    const playerName = firstNameChopped + randomNumber;

    return playerName.includes('69')
        ? getRandomPlayerName(firstName)
        : playerName;
};

export const createUsername = (firstName, lastName = '') => {
    const last = replaceUmlauts(lastName)
        .replace(/\s/g, '') // Remove spaces.
        .replace(/[^0-9|^a-zA-Z]*/g, '') // Remove any special chars.
        .slice(0, 1)
        .toLowerCase();

    const first = replaceUmlauts(firstName)
        .trim()
        .slice(0, 12)
        .replace(/\s/g, '') // Remove spaces.
        .replace(/[^0-9|^a-zA-Z]*/g, '') // Remove any special chars.
        .toLowerCase();

    // The randomNumber will always be either 1 or 2 digits long,
    // so make sure the name doesn't exceed 15 chars .
    const randomNumber = randomIntFromRange(10, 99);

    const username = first + last + randomNumber;

    return username.includes('69')
        ? createUsername(firstName, lastName)
        : username;
};

// Uses a very simple email check to make sure they at
// least put in the effort to make it look like an email.
export const SIMPLE_EMAIL_REGEX = /\S+@\S+\.\S+/;
