import { googleSdkLoaded } from 'vue3-google-login';
import { app } from '@/main';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

export const requestGoogleToken = async ({
    clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID,
    iosClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID,
    scopes = 'profile email',
}) => {
    if (!clientId) {
        throw new Error('google clientId is required');
    }

    // Check if running on a native platform
    if (window.Capacitor?.isNativePlatform()) {
        try {
            // Initialize GoogleAuth for native platforms
            await GoogleAuth.initialize({
                serverClientId: clientId,
                clientId,
                androidClientId: clientId,
                iosClientId,
                scopes: scopes.split(' '),
                grantOfflineAccess: true,
                // forceCodeForRefreshToken: true,
            });

            // Sign in and get the response
            const response = await GoogleAuth.signIn();

            // Assuming the response contains an access token
            if (response.authentication?.accessToken) {
                return { access_token: response.authentication.accessToken };
            } else {
                throw new Error('No access token received');
            }
        } catch (error) {
            // Handle errors for native platforms
            console.error('GoogleAuth error:', error);

            throw error;
        }
    } else {
        // Web version of requesting Google token
        return new Promise((resolve, reject) => {
            googleSdkLoaded((google) => {
                google.accounts.oauth2
                    .initTokenClient({
                        client_id: clientId,
                        scope: scopes,
                        error_callback: (err) => {
                            if (
                                err.type === 'popup_closed' ||
                                err.type === 'popup_failed_to_open'
                            ) {
                                app.config.globalProperties.$emitter.emit(
                                    'googleAuthError',
                                    err.type,
                                );
                            }
                        },
                        callback: (response) => {
                            if (response.access_token) {
                                resolve(response);
                            } else {
                                reject(response);
                            }
                        },
                    })
                    .requestAccessToken();
            });
        });
    }
};

export const requestGoogleAuthCode = ({
    clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID,
    scopes = 'profile email',
    redirectUrl,
    state,
}) => {
    return new Promise((resolve, reject) => {
        googleSdkLoaded((google) => {
            if (!clientId) {
                throw new Error('google clientId is required');
            }

            google.accounts.oauth2
                .initCodeClient({
                    client_id: clientId,
                    scope: scopes,
                    ux_mode: 'redirect',
                    redirect_uri: redirectUrl,
                    state,
                    error_callback: (err) => {
                        console.log('google err: ', err);

                        if (
                            err.type === 'popup_closed' ||
                            err.type === 'popup_failed_to_open'
                        ) {
                            app.config.globalProperties.$emitter.emit(
                                'googleAuthError',
                                err.type,
                            );
                        }
                    },
                    callback: (response) => {
                        console.log('google res: ', response);

                        if (response.code) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    },
                })
                .requestCode();
        });
    });
};
