import store from '@/store';
import { getUserFromJwtToken } from '@/flows/Authentication/helpers/AuthHelpers';
import { BrowserDetect } from '@/core/mixins/BrowserDetect';
import io from 'socket.io-client';
import DebugReportApi from './AdminApi';
import { inProductionEnv, simpleStringify } from '@/core/helpers/utils';

export const START_DEBUG_PROBABILITY_IN_PERCENT = 10;

export const DebugReportService = (function () {
    /** Constructor */
    function DebugReportService(socket, start = false) {
        this.inQA = !inProductionEnv();

        this.isStartDebugService =
            Math.random() < 0.01 * START_DEBUG_PROBABILITY_IN_PERCENT ||
            !!start;

        if (this.inQA) {
            return;
        }

        if (!this.isStartDebugService) {
            return;
        }
        console.log('DebugReport: initializing');

        let userId =
            store.getters.getUserId ||
            getUserFromJwtToken(localStorage.getItem('userDataToken'))?.userId;

        this.adminSocket = io(import.meta.env.VITE_ADMIN_API_URL, {
            autoConnect: false,
            transports: ['websocket'],
            cors: [import.meta.env.VITE_ADMIN_API_URL],
        });

        this.adminSocket.on('reconnect_attempt', () => {
            this.adminSocket.io.opts.transports = ['websocket'];
        });

        this.adminSocket.on('reconnect', () => {
            privateMethods.checkForBufferAndSend(
                this.adminSocket,
                this.gameCode,
                this.playerName,
                this.gameMode,
            );
        });

        this.userId = userId;

        this.socket = socket;
    }

    DebugReportService.prototype.startSession = function (
        gameMode,
        gameCode,
        playerName,
    ) {
        if (this.inQA) {
            return;
        }

        if (!this.isStartDebugService) {
            return;
        }

        if (!gameCode) {
            gameCode = store.getters.getGameCode;
        }

        if (!playerName) {
            playerName =
                store.getters.getPlayerName ||
                sessionStorage.getItem('playerName');
        }

        if (!gameMode) {
            gameMode = store.getters.getGameMode;
        }

        this.gameMode = gameMode;

        this.playerName = playerName;

        this.gameCode = gameCode;

        privateMethods.bindSocketListenersToConsole(this.socket);

        privateMethods.bindConsoleToEmitter();

        privateMethods.startListening(
            this.adminSocket,
            this.gameCode,
            this.playerName,
            this.gameMode,
        );

        console.log(
            `DebugReport: Start session track ${this.gameMode} ${this.gameCode} ${this.playerName}`,
        );
    };

    DebugReportService.prototype.labelReport = async function (gameCode) {
        if (this.inQA) {
            return;
        }

        console.log(`DebugReport: Detected crash and sending debug report`);

        await DebugReportApi().post(`debug-reports/label/${gameCode}`, {
            surveyAnswer: 'yes',
            email: store?.getters?.user?.email,
        });
    };

    DebugReportService.prototype.endSession = function () {
        console.log(`DebugReport: Ending session track'`);

        window.localDebugReportLogData = [];

        document.removeEventListener(
            'consoleEvent',
            window['debugReportEventListener'],
        );
    };
    DebugReportService.prototype.cleanUp = function () {
        console.log(`DebugReport: Cleanup`);

        window.localDebugReportLogData = [];

        document.removeEventListener(
            'consoleEvent',
            window['debugReportEventListener'],
        );

        this.adminSocket?.close();

        this.adminSocket = null;
    };

    const privateMethods = {
        bindConsoleToEmitter() {
            if (!console || console.collecting) {
                return;
            }

            // polyfill for IE
            (function () {
                if (typeof window.CustomEvent === 'function') return false;

                function CustomEvent(event, params) {
                    params = params || {
                        bubbles: false,
                        cancelable: false,
                        detail: null,
                    };

                    var evt = document.createEvent('CustomEvent');

                    evt.initCustomEvent(
                        event,
                        params.bubbles,
                        params.cancelable,
                        params.detail,
                    );
                    return evt;
                }

                window.CustomEvent = CustomEvent;
            })();
            // polyfill for IE

            var logInstance = console.log;

            window.log = console.log;

            console.log = function () {
                document.dispatchEvent(
                    new CustomEvent('consoleEvent', {
                        detail: [
                            Date.now(),
                            ...Array.prototype.slice.call(arguments),
                            'log',
                        ],
                    }),
                );

                logInstance.apply(this, Array.prototype.slice.call(arguments));
            };

            var errorInstance = console.error;

            window.error = console.error;

            console.error = function () {
                document.dispatchEvent(
                    new CustomEvent('consoleEvent', {
                        detail: [
                            Date.now(),
                            ...Array.prototype.slice.call(arguments),
                            'error',
                        ],
                    }),
                );

                errorInstance.apply(
                    this,
                    Array.prototype.slice.call(arguments),
                );
            };

            console.collecting = true;
        },
        bindSocketListenersToConsole(socket) {
            if (window._onevent) {
                return;
            }

            const _emit = socket.emit;

            const _onevent = socket.onevent;

            window._emit = socket.emit;

            window._onevent = socket.onevent;

            socket.emit = function () {
                //Override outgoing
                console.log('socket.io', 'emit', arguments);
                _emit.apply(socket, arguments);
            };

            socket.onevent = function (packet) {
                //Override incoming
                if (
                    packet &&
                    packet.data &&
                    packet.data.length &&
                    packet.data[0] !== 'timerSync'
                ) {
                    console.log('socket.io', 'onevent', packet);
                }
                _onevent.call(socket, packet);
            };
        },
        startListening(adminSocket, gameCode, playerName, gameMode) {
            if (!window.localDebugReportLogData) {
                window.localDebugReportLogData = [];
            }

            if (!adminSocket.connected) {
                adminSocket.open();
            }

            const eventHandler = (e) => {
                if (!window.localDebugReportLogData) {
                    window.localDebugReportLogData = [];
                }

                if (adminSocket.connected) {
                    adminSocket.emit('log', {
                        log: simpleStringify(e.detail),
                        gameCode: gameCode,
                        playerName: playerName,
                        gameMode: gameMode,
                    });
                } else {
                    window.localDebugReportLogData.push(
                        simpleStringify(e.detail),
                    );
                }
            };

            window['debugReportEventListener'] = eventHandler;

            document.addEventListener('consoleEvent', eventHandler);
        },
        checkForBufferAndSend(adminSocket, gameCode, playerName, gameMode) {
            if (
                !window.localDebugReportLogData ||
                window.localDebugReportLogData.length === 0 ||
                !adminSocket.connected
            ) {
                return;
            }

            while (
                window.localDebugReportLogData.length &&
                adminSocket.connected
            ) {
                adminSocket.emit('log', {
                    log: window.localDebugReportLogData.shift(),
                    gameCode: gameCode,
                    playerName: playerName,
                    gameMode: gameMode,
                });
            }
        },
        getStoreData() {
            return {
                gameCode: store.getters.getGameCode,
                playerName: store.getters.getPlayerName,
                country: store.getters.user?.country,
            };
        },
        getMetaData() {
            return {
                device: {
                    browserInfo: BrowserDetect.methods.getBrowserInfo(),
                    operatingSystemInfo: BrowserDetect.methods.getOSInfo(),
                },
            };
        },
    };

    return DebugReportService;
})();
