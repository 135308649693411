<template>
    <Modal v-if="this.showModal" class="offline-modal">
        <div>
            Oooops!
            <br />
            You are not connected to the internet.
            <br />
            <img class="offline-img" src="/images/offline.svg" alt="" />
            <br />
            Please connect and try again.
            <br />
        </div>
    </Modal>
</template>

<script>
import Modal from '@/core/ui/Modal/Modal.vue';
import { inCapacitorBuildEnv } from '@/core/helpers/utils';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';
import { debounce } from 'lodash';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            showModal: false,
            allowedRouteNames: [
                'host',
                'old.host',
                'join',
                'home-game.v10.main-page',
            ],
        };
    },
    mounted() {
        this.updateNetworkStatus(); // Initial check

        if (inCapacitorBuildEnv() || Capacitor?.getPlatform()) {
            window.addEventListener('offline', this.updateNetworkStatus);

            window.addEventListener('online', this.updateNetworkStatus);

            Network.addListener(
                'networkStatusChange',
                this.updateNetworkStatus,
            );
        }
    },
    beforeUnmount() {
        window.removeEventListener('offline', this.updateNetworkStatus);

        window.removeEventListener('online', this.updateNetworkStatus);

        Network.removeAllListeners();
    },
    methods: {
        updateNetworkStatus: debounce(async function () {
            const status = await Network.getStatus();

            const isOnline = navigator.onLine && status.connected;

            console.log(
                'Network status updated:',
                isOnline ? 'Online' : 'Offline',
            );
        }, 1000),
    },
};
</script>

<style lang="scss">
.offline-modal {
    .c-modal--content {
        border-radius: 1rem;
        padding: 1rem;
        font-size: 1.5rem;

        font-style: normal;
        overflow: hidden;
    }
    .offline-img {
        max-height: 40vh;
        max-width: 40vw;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
}
</style>
