/* global mixpanel */
export const TEAM_MP_EVENTS = {
    STARTED_CREATING_TEAM: 'startedCreating',
    JOINED_TEAM: 'joinedTeam',
    COPIED_TEAM_INVITE_LINK: 'copiedTeamInviteLink',
    CLICKED_INVITE_NEW_MEMBERS: 'clickedOnInviteNewMembers',
    VIEWED_TEAM_CREATED_NOTIFICATION: 'viewedTeamCreatedNotification',
    VIEWED_TEAM: 'viewedTeam',
    VIEWED_MY_TEAM: 'viewedMyTeam',
};

export const TEAM_MP_METHODS = {
    startedCreating(teamName) {
        mixpanel.people.set({
            'Team name': teamName,
            'Team is created?': false,
            'Team member count': 1,
        });
        mixpanel.track('Started creating team', {
            'Team name': teamName,
        });
    },
    joinedTeam(teamName) {
        mixpanel.people.set({ 'Team name': teamName });
        mixpanel.track('Joined team', {
            'Team name': teamName,
        });
    },
    copiedTeamInviteLink() {
        mixpanel.track('Copied team link');
    },
    clickedOnInviteNewMembers() {
        mixpanel.track('Clicked on "Invite new members"');
    },
    viewedTeamCreatedNotification() {
        mixpanel.track('Viewed successful team creation modal');
    },
    viewedTeam(teamName) {
        mixpanel.track('Viewed another team', {
            'Team name': teamName,
        });
    },
    viewedMyTeam(teamName) {
        mixpanel.track('Viewed my team', {
            'Team name': teamName,
        });
    },
};
