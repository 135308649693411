export default {
    setPlayerName({ playerState }, payload) {
        playerState.playerName = payload.name || '';
    },
    setPlayerGuestSession({ playerState }, payload) {
        playerState.playerGuestSession = payload || '';
    },
    setPlayerNameError({ playerState }, payload) {
        playerState.playerNameError = payload;
        sessionStorage.removeItem('playerName');
    },
    setGameFullError({ playerState }, payload) {
        playerState.gameIsFullError = payload;
    },
    setPlayerNameProfanityError({ playerState }, payload) {
        playerState.playerNameProfanityError = payload;
    },
    /**
     * Used after the player answered a question.
     */
    updatePlayerScore({ playerState }, { isCorrectAnswer, round }) {
        playerState.stats.answeredQuestions++;

        if (isCorrectAnswer) {
            playerState.score++;

            playerState.stats.correctAnswers++;
        } else {
            if (playerState.score - 1 < 0) {
                playerState.score = 0;
            } else {
                playerState.score--;
            }

            playerState.stats.wrongAnswers++;
        }
        const getHowManyPointsToAddForCorrectAnswer = () => {
            return 1;
        };
        const getHowManyPointsToAddForWrongAnswer = () => {
            return -1;
        };

        if (round && playerState.stats.rounds) {
            const roundIndex = round - 1;

            const correctInc = getHowManyPointsToAddForCorrectAnswer();

            const wrongInc = getHowManyPointsToAddForWrongAnswer();

            if (!playerState.stats.rounds[roundIndex]) {
                playerState.stats.rounds[roundIndex] = {
                    // Do not allow the score to go below 0, so
                    // if the score was not set, set it to 0.
                    score: isCorrectAnswer ? correctInc : 0,
                    correctAnswers: isCorrectAnswer ? 1 : 0,
                    wrongAnswers: isCorrectAnswer ? 0 : 1,
                    answeredQuestions: 1,
                };
            } else {
                playerState.stats.rounds[roundIndex].score += isCorrectAnswer
                    ? correctInc
                    : wrongInc;

                if (playerState.stats.rounds[roundIndex].score < 0) {
                    playerState.stats.rounds[roundIndex].score = 0;
                }

                playerState.stats.rounds[roundIndex].correctAnswers +=
                    isCorrectAnswer ? 1 : 0;

                playerState.stats.rounds[roundIndex].wrongAnswers +=
                    isCorrectAnswer ? 0 : 1;

                playerState.stats.rounds[roundIndex].answeredQuestions += 1;
            }
        }
    },
    updatePlayerSessionScore({ playerState }, isCorrectAnswer, typeScore) {
        const currentScore = playerState.currentPlayerSession.score;

        let newScore = isCorrectAnswer ? currentScore + 1 : currentScore - 1;

        if (newScore < 0) {
            newScore = 0;
        }

        playerState.currentPlayerSession.score = newScore;

        localStorage.setItem('playerSessionScore', newScore);

        if (typeScore !== 'task') {
            playerState.currentPlayerSession.stats.answeredQuestions++;

            if (isCorrectAnswer) {
                playerState.currentPlayerSession.stats.correctAnswers++;
            } else {
                playerState.currentPlayerSession.stats.wrongAnswers++;
            }
        }
    },
    /**
     * Used when the server sends back the results.
     */
    setPlayerScore({ playerState }, payload) {
        playerState.score = payload;
    },
    setPlayerStats(state, payload) {
        if (!payload.rounds) {
            payload.rounds = [];
        }

        const { currentRound } = state.gameState.round;

        if (payload.rounds && payload.rounds.length < currentRound) {
            for (let i = 0; i < currentRound; i++) {
                if (payload.rounds[i]) {
                    // If some round exists, do not override it.
                    continue;
                }

                payload.rounds[i] = {
                    correctAnswers: 0,
                    wrongAnswers: 0,
                    score: 0,
                };
            }
        }

        state.playerState.stats = payload;
    },
    setGameCodeError(state, payload) {
        state.gameCodeError = payload;
    },
    setActiveInputType(state, payload) {
        state.playerState.activeInputType = payload;
    },
    setGameCreatorRole(state, payload) {
        state.gameCreatorRole = payload;
    },
    setSPGCurrentPlayerSession({ playerState }, payload) {
        playerState.currentPlayerSession.id = payload;

        localStorage.setItem('spgPlayerSessionId', payload);
    },
    setPlayerSessionScore({ playerState }, newScore) {
        playerState.currentPlayerSession.score = newScore;

        localStorage.setItem('playerSessionScore', newScore);
    },
    setPlayerSessionStats(
        { playerState },
        newVal = {
            answeredQuestions: 0,
            playedTime: 0,
            correctAnswers: 0,
            wrongAnswers: 0,
            results: [],
        },
    ) {
        playerState.currentPlayerSession.stats = { ...newVal };

        localStorage.setItem(
            'playerSessionStats',
            JSON.stringify({ ...newVal }),
        );
    },
    setAdditionalQuestionsToTheGame(state, payload) {
        const { gameState } = state;

        const { currentRound, gameInfo, round } = gameState;

        if (!currentRound && !round.currentRound) {
            console.error('Broken state. Unable to add new questions');
            return;
        }

        const roundName = `round${currentRound || round.currentRound}`;

        gameInfo.questions[roundName].push(...payload);
    },
};
