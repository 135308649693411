<template>
    <span class="see-pricing">
        or
        <span class="see-pricing__link" @click="goToPremiumPage">
            see pricing
        </span>
        to get more information
    </span>
</template>
<script>
import { mapActions } from 'vuex';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default {
    methods: {
        ...mapActions('v2/teacherPremium', {
            handleGoToPremiumPage: 'goToPremiumPage',
        }),
        goToPremiumPage() {
            new TrackingService().track(EVENTS.OPEN_PREMIUM_PRICING_PAGE, {
                location: this.$route.path,
            });

            this.handleGoToPremiumPage();
        },
    },
};
</script>
<style lang="scss" scoped>
.see-pricing {
    margin-top: 7px;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 14px;
    }

    &__link {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
