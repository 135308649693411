import {
    randomIntFromRange,
    getDecimalPlaces,
    range,
    randomArrayElement,
    randomFromRange,
    isObject,
} from '@/core/helpers/utils';
import TopicsBaseClass, {
    ANSWER_INPUT_TYPE,
    KEYBOARD_TYPE,
} from '@/core/math-topics/TopicsBaseClass';
import { hasOwnProp } from '@/core/helpers/utils';

/**
 * @extends TopicsBaseClass
 */
export default class ComparingNumbersTopic extends TopicsBaseClass {
    static code = 'TYPE_COMPARING_NUMBERS';

    static icon = '';

    static gameTypeNameTranslationKey =
        'game.gameTypeTitle.comparingAndOrdering';

    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const { type, topic, subtopic, scale, from, to } =
            skill.numberGenerator;

        const topicName = this.t(`game.gameTypeTitle.${topic}`);

        const subtopicName = this.t(`game.gameTypeTitle.${subtopic}`);

        if (subtopic === 'numberSequence') {
            return ` - ${subtopicName}: ${scale}`;
        }

        // Natural numbers comparing & configure
        if (type) {
            if (!subtopic) {
                return type === 'configure'
                    ? `${topicName} ${from}...${to}`
                    : `${topicName} ${type}`;
            } else {
                return type === 'configure'
                    ? `${topicName} - ${subtopicName} ${from}...${to}`
                    : `${topicName} - ${subtopicName} ${type}`;
            }
        }

        // Natural numbers ordering
        // Decimals comparing & ordering
        if (scale) {
            if (topic === 'decimals' && subtopic === 'comparing') {
                const scaleNumber = (scale) => {
                    if (scale === 1) {
                        return scale;
                    }

                    const float = 1 / scale;

                    return float.toLocaleString(this.locale);
                };

                if (Array.isArray(scale)) {
                    const num1 = scale[0];

                    const num2 = scale[1];

                    return scaleNumber(num1) + `...` + scaleNumber(num2);
                } else {
                    const num = scaleNumber(scale);

                    return num + `...` + num;
                }
            }

            if (topic === 'integers' && subtopic === 'comparing') {
                return `${topicName} - ${subtopicName} ${scale.from}...${scale.to}`;
            }

            if (subtopic === 'ordering') {
                if (topic === 'naturalNumbers') {
                    return `${topicName} - ${subtopicName}: 1...${scale}`;
                }

                if (topic === 'decimals') {
                    const thousandths = this.t(
                        'host.create.fractions.thousandths',
                    ).toLowerCase();

                    const hundredths = this.t(
                        'host.create.fractions.hundredths',
                    ).toLowerCase();

                    const tenths = this.t(
                        'host.create.fractions.tenths',
                    ).toLowerCase();

                    const integers = this.t(
                        'game.gameTypeTitle.integers',
                    ).toLowerCase();

                    const scaleString = [scale]
                        .flat()
                        .sort((a, b) => b - a)
                        .map((value) => {
                            switch (value) {
                                case 0.001:
                                    return thousandths;
                                case 0.01:
                                    return hundredths;
                                case 0.1:
                                    return tenths;
                                case 1:
                                    return integers;
                                default:
                                    return;
                            }
                        })
                        .join(', ');

                    return `${topicName} - ${subtopicName}: ${scaleString}`;
                }
            }
        }
    }

    static getKeyboardType(numberGenerator) {
        if (numberGenerator.subtopic === 'comparing') {
            return KEYBOARD_TYPE.COMPARING;
        }

        if (numberGenerator.subtopic === 'ordering') {
            return KEYBOARD_TYPE.ENTER;
        }

        return super.getKeyboardType(numberGenerator);
    }

    static getAnswerInputType(numberGenerator) {
        if (numberGenerator.subtopic === 'numberSequence') {
            return ANSWER_INPUT_TYPE.TWO_BOX;
        }

        if (numberGenerator.subtopic === 'ordering') {
            return ANSWER_INPUT_TYPE.ORDER;
        }

        if (numberGenerator.subtopic === 'comparing') {
            return ANSWER_INPUT_TYPE.NONE;
        }

        return ANSWER_INPUT_TYPE.REGULAR;
    }

    static resetPlayerAnswer(numberGenerator, question) {
        if (numberGenerator.subtopic === 'ordering') {
            if (!question) {
                return [];
            }

            return [question.number1, question.number2, question.number3];
        }

        if (numberGenerator.subtopic === 'numberSequence')
            return {
                leftBox: '',
                rightBox: '',
            };

        return super.resetPlayerAnswer(numberGenerator, question);
    }

    // ok
    static generateNumberSequenceQuestion(numberGenerator) {
        if (!numberGenerator) {
            return;
        }

        const { intervals, scale } = numberGenerator;

        const missingNumbers = 2;

        const numbersToGenerate = 5;

        const interval = randomArrayElement(intervals);

        const dotLocations = randomArrayElement([
            [1, 2],
            [numbersToGenerate, numbersToGenerate - 1],
        ]);

        const minMax = scale.split('..');

        const min = parseInt(minMax[0]);

        const max = parseInt(minMax[1]);

        const numbers = {
            number1: randomIntFromRange(
                min,
                max - numbersToGenerate * interval,
            ),
        };

        if (numbers.number1 > max || numbers.number1 < min) {
            return this.generateNumberSequenceQuestion(numberGenerator);
        }

        numbers.number2 = numbers.number1 + interval;

        numbers.number3 = numbers.number2 + interval;

        numbers.number4 = numbers.number3 + interval;

        numbers.number5 = numbers.number4 + interval;

        if (numbers.number5 > max || numbers.number5 < min) {
            return this.generateNumberSequenceQuestion(numberGenerator);
        }

        let answer = {};

        for (let i = 0; i < missingNumbers; i++) {
            answer[`number${dotLocations[i]}`] =
                numbers[`number${dotLocations[i]}`];

            numbers[`number${dotLocations[i]}`] = '...';
        }

        return {
            ...numbers,
            answer,
            subtopic: 'numberSequence',
            missingNumbers,
        };
    }

    // ok
    static generateQuestion(numberGenerator) {
        const { topic, subtopic, order, type, scale } = numberGenerator;

        const randomOrder = order && randomArrayElement(order);

        let nr1, nr2, nr3, answer;

        if (subtopic === 'numberSequence') {
            return this.generateNumberSequenceQuestion(numberGenerator);
        }

        // Natural numbers comparing & configure
        if (type) {
            if (!subtopic || subtopic === 'comparing') {
                if (type === 'configure') {
                    const from = numberGenerator.from;

                    const to = numberGenerator.to;

                    nr1 = randomIntFromRange(from, to);

                    nr2 = randomIntFromRange(from, to);
                } else {
                    const nrs = type.split('..');

                    nr1 = randomIntFromRange(nrs[0], nrs[1]);

                    nr2 = randomIntFromRange(nrs[0], nrs[1]);
                }
            }

            if (subtopic === 'ordering' && type === 'configure') {
                const from = numberGenerator.from;

                const to = numberGenerator.to;

                nr1 = randomIntFromRange(from, to);

                nr2 = randomIntFromRange(from, to);

                nr3 = randomIntFromRange(from, to);
            }
        }

        // Natural numbers ordering
        // Decimals comparing & ordering
        if (scale) {
            if (topic === 'naturalNumbers' && subtopic === 'ordering') {
                const arr1 = range(1, scale);

                nr1 = randomArrayElement(arr1);

                const arr2 = range(1, scale, nr1);

                nr2 = randomArrayElement(arr2);

                const arr3 = range(1, scale, [nr1, nr2]);

                nr3 = randomArrayElement(arr3);
            }

            if (topic === 'decimals') {
                if (!subtopic || subtopic === 'comparing') {
                    const wholeNumber = randomIntFromRange(0, 9);

                    const randomByScale = (max) => {
                        return randomIntFromRange(1, max - 1);
                    };

                    // Single scale selection
                    if (typeof scale === 'number') {
                        if (scale === 1) {
                            nr1 = randomIntFromRange(0, 9);

                            nr2 = randomIntFromRange(0, 9);
                        } else {
                            const random1 = randomByScale(scale);

                            const random2 = randomByScale(scale);

                            const decimals = getDecimalPlaces(scale);

                            nr1 = wholeNumber + random1 / scale;

                            nr1 = nr1.toFixed(decimals);

                            nr2 = wholeNumber + random2 / scale;

                            nr2 = nr2.toFixed(decimals);
                        }
                    }

                    // Multiple scale selections
                    if (Array.isArray(scale)) {
                        let scale1 = randomArrayElement(scale);
                        const scale2 = randomArrayElement(scale);

                        // Do not compare two whole numbers
                        if (scale1 === 1 && scale2 === 1) {
                            const decimalScale = scale.filter((s) => s !== 1);

                            scale1 = randomArrayElement(decimalScale);
                        }

                        const scales = [scale1, scale2];

                        const createFloat = (whole, relation) => {
                            const decimals = getDecimalPlaces(relation);

                            let float =
                                whole + randomByScale(relation) / relation;

                            return float.toFixed(decimals);
                        };

                        const createVaryingInt = (num) => {
                            const n1 = randomIntFromRange(1, 10);

                            const n2 = Math.ceil(num);

                            const n3 = Math.floor(num);

                            return randomArrayElement([n1, n2, n3]);
                        };

                        if (scale1 === scale2) {
                            nr1 = createFloat(wholeNumber, scale1);

                            nr2 = createFloat(wholeNumber, scale1);
                        }

                        if (scales.includes(1) && scales.includes(10)) {
                            nr1 = createFloat(wholeNumber, 10);

                            nr2 = createVaryingInt(nr1);
                        }

                        if (scales.includes(1) && scales.includes(100)) {
                            nr1 = createFloat(wholeNumber, 100);

                            nr2 = createVaryingInt(nr1);
                        }

                        if (scales.includes(1) && scales.includes(1000)) {
                            nr1 = createFloat(wholeNumber, 1000);

                            nr2 = createVaryingInt(nr1);
                        }

                        if (scales.includes(10) && scales.includes(100)) {
                            nr1 = createFloat(wholeNumber, 10);

                            nr2 = createFloat(wholeNumber, 100);
                        }

                        if (scales.includes(10) && scales.includes(1000)) {
                            nr1 = createFloat(wholeNumber, 10);

                            nr2 = createFloat(wholeNumber, 1000);
                        }

                        if (scales.includes(100) && scales.includes(1000)) {
                            nr1 = createFloat(wholeNumber, 100);

                            nr2 = createFloat(wholeNumber, 1000);
                        }
                    }
                }

                if (subtopic === 'ordering') {
                    const pickScales = (array) => {
                        const el1 = randomArrayElement(array);

                        const el2 = randomArrayElement(array);

                        let el3 = randomArrayElement(array);

                        const allIntegers = [el1, el2, el3].every(
                            (el) => el === 1,
                        );

                        // // Avoid picking all integer scales
                        if (allIntegers) {
                            const filtered = array.filter((item) => item !== 1);

                            el3 = randomArrayElement(filtered);
                        }

                        return [el1, el2, el3];
                    };

                    const createNumber = (number, exclude = false) => {
                        if (exclude !== false) {
                            exclude = Array.isArray(exclude)
                                ? exclude.map((n) => Number(n))
                                : Number(exclude);
                        }

                        if (number === 1) {
                            const arr = range(0, 9, exclude);

                            return randomArrayElement(arr);
                        } else {
                            const decimalPart = 1 / number - 1;

                            const decimalPlaces = `${decimalPart}`.length;

                            const max = parseFloat(`9.${decimalPart}`);

                            const nr = randomFromRange(
                                number,
                                max,
                                decimalPlaces,
                            );

                            // Generate each number once
                            return [exclude].flat().includes(Number(nr))
                                ? createNumber(number, exclude)
                                : nr;
                        }
                    };

                    const createNumbers = () => {
                        let num1, num2, num3;

                        if (Array.isArray(scale)) {
                            const [scale1, scale2, scale3] = pickScales(scale);

                            num1 = createNumber(scale1);

                            num2 = createNumber(scale2, num1);

                            num3 = createNumber(scale3, [num1, num2]);
                        } else {
                            num1 = createNumber(scale);

                            num2 = createNumber(scale, num1);

                            num3 = createNumber(scale, [num1, num2]);
                        }

                        return [num1, num2, num3];
                    };

                    [nr1, nr2, nr3] = createNumbers(scale);
                }
            }

            if (topic === 'integers') {
                if (subtopic === 'comparing') {
                    const { from, to } = scale;

                    const random = randomIntFromRange(1, 10);

                    const x1 = 0.6 * from;

                    const x2 = 0.7 * from;

                    nr1 = randomIntFromRange(from, to);

                    if (
                        (from === -10 && to === 0) ||
                        (from === -100 && to === 0) ||
                        (from === -10 && to === 10) ||
                        (from === -100 && to === 100)
                    ) {
                        if (random === 1) {
                            nr2 = nr1;
                        } else {
                            nr2 = randomIntFromRange(from, to);
                        }
                    }

                    if (
                        (from === -1000 && to === 0) ||
                        (from === -10000 && to === 0) ||
                        (from === -1000 && to === 1000) ||
                        (from === -10000 && to === 10000)
                    ) {
                        if (random === 1) {
                            nr2 = nr1;
                        } else if (nr1 < x1 && nr1 > x2) {
                            nr2 = randomIntFromRange(x2, x1);
                        } else {
                            nr2 = randomIntFromRange(from, to);
                        }
                    }

                    if (
                        (from === -100000 && to === 0) ||
                        (from === -100000 && to === 100000)
                    ) {
                        const y1 = 0.95 * from;
                        const y2 = 0.96 * from;

                        if (random === 1) {
                            nr2 = nr1;
                        } else if (nr1 < x1 && nr1 > x2) {
                            nr2 = randomIntFromRange(-70000, -60000);
                        } else if (nr1 < y1 && nr1 > y2) {
                            nr2 = randomIntFromRange(y2, y1);
                        } else {
                            nr2 = randomIntFromRange(from, to);
                        }
                    }
                }
            }
        }

        if (!subtopic || subtopic === 'comparing') {
            if (+nr1 === +nr2) {
                answer = '=';
            } else if (+nr1 > +nr2) {
                answer = '>';
            } else if (+nr1 < +nr2) {
                answer = '<';
            }
        } else {
            if (randomOrder === 'ascending') {
                answer = [nr1, nr2, nr3].sort((a, b) => a - b);
            } else {
                answer = [nr1, nr2, nr3].sort((a, b) => b - a);
            }
        }

        return {
            topic,
            subtopic,
            order: randomOrder,
            number1: nr1,
            number2: nr2,
            number3: nr3,
            answer: answer,
        };
    }

    // ok
    static formatQuestion(question, skill, calledIn) {
        if (
            question &&
            (question.subtopic === 'numberSequence' ||
                question.data?.subtopic === 'numberSequence')
        ) {
            let { number1, number2, number3, number4, number5, answer } =
                question;

            if (number1 === undefined || number1 === null) {
                number1 = question.nr1;

                number2 = question.nr2;

                number3 = question.nr3;

                number4 = question.nr4;

                number5 = question.nr5;
            }

            if (calledIn === 'report') {
                let n1, n2, n4, n5;
                n1 =
                    number1 === '...'
                        ? `<u>${answer.number1}</u>` || 'x'
                        : number1;

                n2 =
                    number2 === '...'
                        ? `<u>${answer.number2}</u>` || 'x'
                        : number2;

                n4 =
                    number4 === '...'
                        ? `<u>${answer.number4}</u>` || 'x'
                        : number4;

                n5 =
                    number5 === '...'
                        ? `<u>${answer.number5}</u>` || 'x'
                        : number5;

                return `${n1}, ${n2}, ${number3}, ${n4}, ${n5}`;
            } else {
                return `${number1}, ${number2}, ${number3}, ${number4}, ${number5}`;
            }
        }

        let { number1, number2, number3, subtopic, order } = question;

        if (number1 === undefined || number1 === null) {
            number1 = question.nr1;

            number2 = question.nr2;

            number3 = question.nr3;
        }

        const nr1String = this.formatNumber(number1);

        const nr2String = this.formatNumber(number2);

        const nr3String = this.formatNumber(number3);

        if (!subtopic || subtopic === 'comparing') {
            return `${nr1String} _ ${nr2String}`;
        }

        if (subtopic === 'ordering') {
            if (calledIn === 'inPhoneStudentViewExample') {
                return `
                    <div>${
                        order === 'ascending'
                            ? this.t('host.create.arrangeNumbersAsc')
                            : this.t('host.create.arrangeNumbersDesc')
                    }</div>
                    <div class="drag-and-drop-example">
                        <div class="item">${nr1String}</div>
                        <div class="item">${nr2String}</div>
                        <div class="item">${nr3String}</div>
                    </div>
                `;
            }

            if (calledIn === 'inGame') {
                return `
                    <div class="margin-top-bottom-20">${
                        order === 'ascending'
                            ? this.t('host.create.arrangeNumbersAsc')
                            : this.t('host.create.arrangeNumbersDesc')
                    }</div>
                `;
            }

            // Report
            // Example tasks
            const numbers = [nr1String, nr2String, nr3String];

            return `
                <div class="question--ordering">
                    ${numbers.join('\xa0\xa0\xa0')}
                </div>
            `;
        }
    }

    static generateCorrectAnswerHtml(question, numberGenerator, calledIn) {
        const subtopic = numberGenerator?.subtopic || question?.subtopic;

        if (subtopic === 'numberSequence') {
            const answer = question.answer;

            const leftBox = answer.number1 || answer.number4;

            const rightBox = answer.number2 || answer.number5;

            return `<span class="correct-answer">${leftBox ? leftBox : ''}, ${
                rightBox ? rightBox : ''
            }</span>`;
        }

        if (subtopic === 'ordering') {
            return `<span class="correct-answer">${question.answer.join(
                ' ',
            )}</span>`;
        }

        return super.generateCorrectAnswerHtml(
            question,
            numberGenerator,
            calledIn,
        );
    }

    static generatePlayerAnswerHtml(answer, skill, question) {
        let numberGenerator = skill;

        if (hasOwnProp(skill, 'numberGenerator')) {
            numberGenerator = skill.numberGenerator;
        }

        if (numberGenerator.subtopic === 'ordering') {
            const numbers = answer.map((num) => this.formatNumber(num));

            return `=
                <span class="wrong-answer">
                    ${numbers.join('\xa0\xa0\xa0')}
                </span>
            `;
        }

        if (numberGenerator.subtopic === 'numberSequence') {
            const { leftBox, rightBox } = answer;

            if (leftBox || rightBox) {
                return ` = <span class="wrong-answer">${
                    leftBox ? leftBox : ''
                }, ${rightBox ? rightBox : ''}</span>`;
            }

            return ` = <span class="wrong-answer">&nbsp;&nbsp;&nbsp;&nbsp;</span>`;
        }

        return super.generatePlayerAnswerHtml(
            answer,
            numberGenerator,
            question,
        );
    }

    static keyboardAnswerInput(answer, input, numberGenerator, activeInput) {
        if (numberGenerator) {
            if (numberGenerator.subtopic === 'ordering') {
                return [];
            }
            if (numberGenerator.subtopic === 'numberSequence') {
                const next = isObject(answer)
                    ? { ...answer }
                    : { leftBox: '', rightBox: '' };

                next[activeInput] = super.keyboardAnswerInput(
                    next[activeInput],
                    input,
                    numberGenerator,
                    activeInput,
                );

                return next;
            }
        }

        return super.keyboardAnswerInput(
            answer,
            input,
            numberGenerator,
            activeInput,
        );
    }

    static isAnswerCorrect(question, answer, numberGenerator) {
        if (numberGenerator.subtopic === 'numberSequence') {
            const left = question.answer.number1 || question.answer.number4;

            const right = question.answer.number2 || question.answer.number5;

            return (
                left === Number(answer.leftBox) &&
                right === Number(answer.rightBox)
            );
        }

        if (numberGenerator.subtopic === 'ordering') {
            return question.answer.every(
                (number, index) => number === answer[index],
            );
        }

        if (numberGenerator.subtopic === 'comparing') {
            return question.answer === answer;
        }

        return super.isAnswerCorrect(question, answer, numberGenerator);
    }

    static answerData(question, answer, skill) {
        const missingNumbers =
            skill.numberGenerator.missingNumbers || question.missingNumbers;

        return {
            subtopic: skill.numberGenerator.subtopic,
            missingNumbers,
        };
    }

    static showArrowButtons(numberGenerator) {
        return numberGenerator.subtopic === 'numberSequence';
    }
}
