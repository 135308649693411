import HostMutations from '@/flows/TimeBasedLiveGame/Host/store/HostMutations';
import Auth from '@/store/modules/auth.js';
import PlayerMutations from '@/flows/TimeBasedLiveGame/Player/store/PlayerMutations';
import TimerMutations from '@/core/components/Timer/store/TimerMutations';
import { initialState } from './index';
import LanguageSwitcherMutations from '@/core/components/LanguageSwitcher/store/LanguageSwitcherMutations';
import ClassListMutations from '@/teacher/flows/ClassLists/ClassListMutations';

export default {
    ...HostMutations,
    ...Auth.mutations,
    ...PlayerMutations,
    ...TimerMutations,
    ...LanguageSwitcherMutations,
    ...ClassListMutations,
    setGameCode(state, payload) {
        state.gameCode = payload;
    },
    setJoinGameCode(state, payload) {
        state.joinGameCode = payload;
    },
    setCurrentRound({ gameState }, payload) {
        gameState.round.currentRound = payload;

        gameState.round.roundFinished = false;
    },
    setRoundsCount({ gameState }, payload) {
        gameState.round.roundsCount = payload;
    },
    setCurrentRoundDuration({ gameState }, payload) {
        gameState.round.duration = payload;
    },
    setCurrentRoundEndTime({ gameState }, payload) {
        gameState.round.endTime = payload;
    },
    setGameInfo({ gameState }, gameInfo) {
        gameState.gameInfo = gameInfo;
    },
    setGameStarted({ gameState }, gameDate) {
        gameState.started = gameDate;
    },
    resetStateM(state) {
        const { user, language, reports } = state;

        state.nextPath = { path: null, params: null };

        // This montrosity is needed to do a deep clone and to keep the vuex reactivity.
        Object.keys(state).forEach((key) => {
            // v2 store module cares of its reset by dispatch('v2/reset')
            // and should not be touched here
            if (key === 'v2') return;

            if (key !== 'language' && initialState[key]) {
                try {
                    Object.assign(state[key], initialState[key]);
                } catch (e) {
                    console.error('rSM error: ', key, e);
                }
            } else {
                state[key] = null;
            }
        });

        // Some store elements are not playing nice, let's force it.
        state.gameState.players = [];

        state.leaderboard = [];

        // Keep some bits of the state.
        state.user = user;

        state.language = language;

        state.reports = user ? reports : [];

        state.joinGameCode = null;
    },
    setClientType(state, payload) {
        state.clientType = payload;
    },
    setAudio(state, payload) {
        const playerIndex = state.audioPlayers.findIndex(
            (audio) =>
                audio.src?.split('/')?.pop() === payload?.split('/')?.pop(),
        );

        if (playerIndex > -1) {
            state.audio = state.audioPlayers[playerIndex];
        } else {
            state.audio = payload ? new Audio(payload) : payload;

            if (state.audio) {
                state.audioPlayers.push(state.audio);
            }
        }
    },
    removeAudioPlayer(state, playerIndex) {
        state.audioPlayers.splice(playerIndex, 1);
    },
    setReferral(state, payload) {
        state.referral = payload;
    },
    setTeachingGrades(state, payload) {
        state.teachingGrades = payload;
    },
    setGameMode(state, payload) {
        state.gameState.mode = payload;
    },
    setGameVariant(state, payload) {
        state.gameState.variant = payload;
    },
    setGameOptions(state, payload) {
        state.gameState.options = payload;
    },
    setReferringUserId(state, payload) {
        state.referralUserId = payload;
    },
    setCurrentRoundFinished({ gameState }) {
        gameState.round.roundFinished = true;
    },
    setWebsocketConnectionStatus(state, payload) {
        const { status } = state.websocket;

        status.isConnected = payload;

        if (payload === true) {
            status.hasBeenConnected = true;

            status.reconnectCounter = 0;
        }
    },
    increaseWebsocketReconnectCounter(state) {
        state.websocket.status.reconnectCounter++;
    },
    setWebsocketShouldBeConnected(state, payload) {
        state.websocket.status.shouldBeConnected = payload;
    },
    switchIsBefore3PM(state, payload) {
        state.forcedBefore3PM = payload;

        sessionStorage.setItem('isBefore3pm', payload);
    },
    setGameSoundPlaying(state, payload) {
        state.gameSoundPlaying = payload;
    },
};
