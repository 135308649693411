<template>
    <button class="button-medium" :class="classes">
        <div class="contents">
            <div class="text-wrapper">
                <div class="first-line">
                    <img v-if="imageUrl" :src="imageUrl" alt="" class="icon" />
                    <span>
                        {{ text }}
                    </span>
                </div>
                <template v-if="textSecondLine">
                    <span class="second-line">
                        <img
                            v-if="secondLineImageUrl"
                            :src="secondLineImageUrl"
                            alt=""
                            class="icon"
                        />
                        {{ textSecondLine }}
                    </span>
                </template>
            </div>
        </div>
    </button>
</template>

<script>
export const ButtonType = {
    Primary: 'primary', // default
    Secondary: 'secondary',
    Destructive: 'destructive',
    Info: 'info',
};
export const ButtonState = {
    Disabled: 'disabled',
    Pressed: 'pressed',
};
export default {
    name: 'ButtonMedium',
    props: {
        type: {
            type: String,
            default: ButtonType.Primary,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pressed: {
            type: Boolean,
            default: false,
        },
        imageUrl: {
            default: false,
        },
        secondLineImageUrl: {
            default: false,
        },
        text: {
            type: String,
            default: 'TEXT',
        },
        textSecondLine: {
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'button-medium__secondary': this.type === ButtonType.Secondary,
                'button-medium__destructive':
                    this.type === ButtonType.Destructive,
                'button-medium__info': this.type === ButtonType.Info,
                'button-medium__disabled': this.disabled,
                'button-medium__pressed': this.pressed,
            };
        },
    },
};
</script>

<style lang="scss">
@import '../swatches.css';
.button-medium {
    min-width: 120px;
    max-width: fit-content; // todo: @nenad: why?
    align-items: center;
    border: 2px solid $--Neutral-100;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    background-color: $--Green-300;
    box-shadow:
        inset 0 6px 0 rgba(255, 255, 255, 0.25),
        inset 0px -6px 0px rgba(0, 0, 0, 0.25),
        0 0 0 2px $--Neutral-700,
        0px 2px 0px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: translateY(1px);
    }
}

.button-medium .contents {
    align-items: center;
    border-radius: 8px;
    display: flex;
    height: 56px;
    justify-content: center;
    position: relative;
    text-align: center;
}

.button-medium .text-wrapper {
    color: $--Green-700;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    line-height: 24px;
    padding: 0 16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-medium .icon {
    margin-right: 8px;
    display: inline-flex;
    width: 32px;
    height: 32px;
}

.button-medium__disabled {
    background-color: $--Neutral-300;

    .text-wrapper {
        color: $--Neutral-500;
    }
}

.button-medium__pressed {
    background-color: $--Green-500;

    .text-wrapper {
        color: $--Green-700;
    }
}

.button-medium__secondary {
    background-color: $--Yellow-300;

    .text-wrapper {
        color: $--Yellow-900;
    }
    &.button-medium__pressed {
        background-color: $--Yellow-600;

        .text-wrapper {
            color: $--Yellow-900;
        }
    }
    &.button-medium__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}

.button-medium__destructive {
    background-color: $--Red-300;

    .text-wrapper {
        color: $--Red-700;
    }
    &.button-medium__pressed {
        background-color: $--Red-500;

        .text-wrapper {
            color: $--Red-700;
        }
    }
    &.button-medium__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}

.button-medium__info {
    background-color: $--Blue-300;

    .text-wrapper {
        color: $--Blue-700;
    }
    &.button-medium__pressed {
        background-color: $--Blue-500;

        .text-wrapper {
            color: $--Blue-700;
        }
    }
    &.button-medium__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}
.first-line {
    display: flex;
    align-items: center;
}
.second-line {
    display: flex;
    align-items: center;
}
</style>
