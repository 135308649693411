<template>
    <div
        class="global-app-div"
        :class="{ 'theme--halloween': isHalloweenEnabled }"
    >
        <!-- TODO: enable it for the next Halloween -->
        <!-- <Halloween v-if="$isTeacher" /> -->
        <!--        <img-->
        <!--            v-if="showHalloweenToggle"-->
        <!--            class="toggle-halloween"-->
        <!--            src="/images/halloween/pumpkin3.svg"-->
        <!--            @click="toggleHalloweenTheme"-->
        <!--        />-->
        <TestingTool v-if="$showTestTool" />
        <IdleModal />
        <MobileAppUpdateRequiredModal />
        <OfflineModal />
        <IsConnectedToBackendViaWebSockets />
        <ArenaOutOfEnergyModal
            v-if="user && showArenaOutOfEnergyModal"
            @close="closeArenaOutOfEnergyModal"
        />
        <HomeGamePaymentModal
            v-if="user"
            :is-open="isHomeGamePaymentModalOpen"
            :location-opened="locationOpenedPaymentModal"
            @close="closeHomeGamePaymentModal"
        />
        <PaymentModal
            v-if="user"
            :is-open="isPaymentModalOpen"
            @close="closePaymentModal"
        />
        <DialogConfirm v-if="$store.state.v2.ui.dialogs.confirm.resolve" />
        <DialogAlert v-if="$store.state.v2.ui.dialogs.alert.resolve" />
        <DialogChoice v-if="$store.state.v2.ui.dialogs.choice.resolve" />
        <LatestTeacherOnboardingVideo
            v-if="showVideo"
            :video-id="videoId"
            @close="closeVideo"
        />
        <CustomerSupportModal v-if="user" />
        <MathRunnerInviteModal
            v-if="user && user.role !== 'teacher' && !isOnboarding"
        />
        <TeacherPremiumEventsController />
        <Loading v-if="loading" display="absolute" />
        <router-view v-else />
    </div>
</template>
<script>
import { onDouble4tapGesture } from '@/capacitor/initGestures';
import IsConnectedToBackendViaWebSockets from '@/core/components/IsConnectedToBackend/IsConnectedToBackendViaWebSockets.vue';
import { ABTesting } from '@/core/mixins/ABTesting';
import CustomerSupportModal from '@/core/modals/CustomerSupportModal/CustomerSupportModal.vue';
import IdleModal from '@/core/modals/IdleModal.vue';
import OfflineModal from '@/core/modals/OfflineModal.vue';
import { DebugReportService } from '@/core/services/DebugReportService';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import Loading from '@/core/ui/_legacy/MathComponents/MathLoadingSpinner.vue';
import DialogAlert from '@/core/ui/Modal/DialogAlert.vue';
import DialogChoice from '@/core/ui/Modal/DialogChoice.vue';
import DialogConfirm from '@/core/ui/Modal/DialogConfirm.vue';
import {
    clearUpFirstName,
    createUsername,
    getRandomPlayerName,
} from '@/flows/Authentication/helpers/AuthHelpers';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import ArenaOutOfEnergyModal from '@/flows/MathRunner/components/ArenaOutOfEnergyModal.vue';
import HomeGamePaymentModal from '@/flows/PaymentModal2/PaymentModal.vue';
import MathRunnerInviteModal from '@/flows/MathRunner/components/MathRunnerInviteModal.vue';
import PaymentModal from '@/student/flows/PaymentModal/PaymentModal.vue';
import TeacherPremiumEventsController from '@/teacher/flows/TeacherPremium/TeacherPremiumEventsController.vue';
import LatestTeacherOnboardingVideo from '@/teacher/modals/LatestTeacherOnboardingVideo.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MobileAppUpdateRequiredModal from '@/core/modals/MobileAppUpdateRequiredModal.vue';
import { app } from '@/main';
import TestingTool from '@/core/_debug/TestingTool.vue';
import { AppSSE } from '@/core/mixins/AppSSE.js';
// import Halloween from '@/widgets/HolidayDecorations/Halloween/Halloween.vue';g

export default {
    components: {
        MobileAppUpdateRequiredModal,
        TestingTool,
        TeacherPremiumEventsController,
        Loading,
        PaymentModal,
        HomeGamePaymentModal,
        IsConnectedToBackendViaWebSockets,
        CustomerSupportModal,
        MathRunnerInviteModal,
        LatestTeacherOnboardingVideo,
        DialogConfirm,
        DialogAlert,
        DialogChoice,
        IdleModal,
        ArenaOutOfEnergyModal,
        OfflineModal,
        // Halloween,
    },
    mixins: [ABTesting, AppSSE],
    data() {
        return {
            loading: false,
            isPaymentModalOpen: false,
            isHomeGamePaymentModalOpen: false,
            isOneTimePurchaseModalOpen: false,
            oneTimePurchaseStripeInfo: null,
            locationOpenedPaymentModal: null,
            locationOpenedPurchaseModal: null,
            showVideo: false,
            videoId: '0PJpSVaf3Os',
            showArenaOutOfEnergyModal: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            currentLanguage: 'getCurrentLanguage',
            clientLanguage: 'getClientBrowserLanguage',
        }),
        ...mapGetters('v2/ui', ['isHalloweenEnabled']),
        isOnboarding() {
            const notPickedAvatar = sessionStorage.getItem(
                'studentFirstTimeToPickAvatar',
            );

            const inOnboarding = sessionStorage.getItem(
                'studentFirstTimeSession',
            );

            return !!notPickedAvatar && !!inOnboarding;
        },
        // TODO: enable this next Halloween
        // showHalloweenToggle() {
        //     return false;
        //
        //     const hiddenRoutes = [
        //         'home-game.v10.program',
        //         'home-game.v10.solo-game-island',
        //         'home-game.v10.battle-pass',
        //         'home-game.v10.zoo',
        //     ];
        //
        //     if (hiddenRoutes.includes(this.$route.name)) {
        //         return false;
        //     }
        //
        //     return this.$isStudent || this.$isGuest;
        // },
    },
    watch: {
        user(newVal, oldVal) {
            if (!oldVal && newVal) {
                // We want the check to be done only during the initialization.
                app.config.globalProperties.$debugReportService =
                    new DebugReportService(
                        this.$socket,
                        this.user?.flags?.debugUser,
                    );
            }
        },
    },
    async created() {
        await this.checkCleverState();
    },
    mounted() {
        console.log(
            '99math is running on version ',
            import.meta.env.VITE_APP_VERSION,
        );

        onDouble4tapGesture(() => {
            this.forceDebug = !this.forceDebug;

            console.log('=== switching debug to', this.forceDebug);
        });

        this.$emitter.on('openPaymentModal', this.openPaymentModal);
        this.$emitter.on(
            'openHomeGamePaymentModal',
            this.openHomeGamePaymentModal,
        );
        this.$emitter.on(
            'openOneTimePurchaseModal',
            this.openOneTimePurchaseModal,
        );
        this.$emitter.on(
            'openArenaOutOfEnergyModal',
            this.openArenaOutOfEnergyModal,
        );

        if (window.location.search.indexOf('showVideo') !== -1) {
            const splitShowVideo = window.location.search.split('=');

            if (splitShowVideo.length === 2 && splitShowVideo[1] !== 'true') {
                this.videoId = splitShowVideo[1];
            }

            this.showVideo = true;
        }
        if (!this.user) {
            const chosenLanguage = sessionStorage.getItem('language');

            if (chosenLanguage) {
                this.setLanguage(chosenLanguage);
            } else if (this.clientLanguage !== this.currentLanguage) {
                this.setLanguage(this.clientLanguage);
            }
        }

        document.addEventListener('touchstart', this.updateLastActivity);
        document.addEventListener('mousedown', this.updateLastActivity);
        document.addEventListener('visibilitychange', this.updateLastActivity);

        let referredHistory = sessionStorage.getItem('referral');

        if (referredHistory) {
            this.setReferral(referredHistory);
        }
        // DebugService is started without any user info yet, as it's not
        // available yet.
        app.config.globalProperties.$debugReportService =
            new DebugReportService(this.$socket);

        this.$socket.client.on('connect', this.logOutSocketId);
        this.$socket.client.io.on('reconnect', this.logOutSocketId);

        // Catch postMessage events
        window.addEventListener(
            'message',
            async (event) => {
                const message = event.data;

                if (
                    message === 'DELETE_DEMO_USER' &&
                    !!this.user.flags?.demoStudent // make sure that the user is demo
                ) {
                    // @todo: move to store
                    await SecureApi().delete(`/user`);
                    await this.$store.dispatch('removeUserData');
                    await this.$store.dispatch('resetStoreState');
                }
            },
            false,
        );

        const guestIn = this.$store.dispatch('checkGuestJwt', null, {
            root: true,
        });

        if (!guestIn) {
            this.$store.dispatch('generateGuestJwt', null, {
                root: true,
            });
        }

        this.loadHalloweenTheme();
        this.loadWinterTheme();
    },
    async beforeUnmount() {
        this.$socket.client.close();
        this.$socket.client.removeEventListener('connect');
        this.$socket.client.removeEventListener('reconnect');

        document.removeEventListener('touchstart', this.updateLastActivity);
        document.removeEventListener('mousedown', this.updateLastActivity);
        document.removeEventListener(
            'visibilitychange',
            this.updateLastActivity,
        );

        this.$debugReportService.cleanUp();
    },
    methods: {
        ...mapActions([
            'setLanguage',
            'updateLastActivity',
            'performCleverRedirection',
            'retrieveCleverAuthToken',
            'retrieveFullCleverProfileInfo',
            'loginUserViaProvider',
            'registerUserViaProvider',
            'postRegistrationSteps',
            'postLoginSteps',
        ]),
        ...mapActions('v2/ui', [
            'toggleHalloweenTheme',
            'loadHalloweenTheme',
            'loadWinterTheme',
        ]),
        ...mapMutations(['setReferral']),
        openArenaOutOfEnergyModal() {
            this.showArenaOutOfEnergyModal = true;
        },
        closeArenaOutOfEnergyModal() {
            this.showArenaOutOfEnergyModal = false;
        },
        async openPaymentModal() {
            this.isPaymentModalOpen = true;
        },
        async openHomeGamePaymentModal(location) {
            if (this.user.flags?.demoStudent) {
                return false;
            }

            if (this.user && this.user.subscriptionStatus !== 'active') {
                new TrackingService(this.user).track(
                    EVENTS.PREMIUM_VIEW,
                    location,
                );
            }
            sessionStorage.setItem('paymentOpenLocation', location);

            this.locationOpenedPaymentModal = location;
            this.isHomeGamePaymentModalOpen = true;
        },
        async openOneTimePurchaseModal({ stripeInfo, location }) {
            if (this.user.flags?.demoStudent) {
                return false;
            }

            this.locationOpenedPurchaseModal = location;
            this.isOneTimePurchaseModalOpen = true;
            this.oneTimePurchaseStripeInfo = stripeInfo;
        },
        closePaymentModal() {
            this.isPaymentModalOpen = false;
        },
        closeHomeGamePaymentModal() {
            this.isHomeGamePaymentModalOpen = false;
        },
        logOutSocketId() {
            console.log(`Socket client id: ${this.$socket.client.id}`);
        },
        async checkCleverState() {
            console.log('URL IN CHECK', window.location);

            if (
                window.location.search.indexOf('code') !== -1 &&
                window.location.search.indexOf('fromClever') !== -1
            ) {
                const cleverCode = window.location.search
                    .replace('?', '')
                    .replace('fromClever=true&', '')
                    .split('=')
                    .pop();

                if (!cleverCode) {
                    console.log(
                        'Expecting fromClever and code query url parameters and that they are missing',
                    );

                    return;
                }
                try {
                    window.opener.popupCallback({ cleverCode });

                    window.close();
                } catch (e) {
                    console.log(`Cors fail: ${e}`);

                    await this.$store.dispatch('removeUserData');
                    await this.$store.dispatch('resetStoreState');

                    const guestIn = await this.$store.dispatch(
                        'checkGuestJwt',
                        null,
                        {
                            root: true,
                        },
                    );

                    if (!guestIn) {
                        await this.$store.dispatch('generateGuestJwt', null, {
                            root: true,
                        });
                    }

                    await this.signUp(cleverCode);
                }
            }
        },
        async signUp(cleverCode) {
            this.loading = true;

            const data = await this.retrieveFullCleverProfileInfo(cleverCode);

            console.log('Clever data', data);

            if (!data.error) {
                this.cleverSignUp({
                    role: data.role,
                    email: data.email,
                    cleverId: data.id,
                    name: {
                        firstName: data?.name?.firstName || 'firstName',
                        lastName: data?.name?.lastName || 'lastName',
                    },
                });
            } else {
                this.loading = false;
            }
        },
        async cleverSignUp(cleverData) {
            const { role, email, name, cleverId } = cleverData;
            const { firstName, lastName } = name;

            console.log('cleverData', cleverData);

            const playerName = getRandomPlayerName(
                clearUpFirstName({ firstName }),
            );

            // if no email (students) then set username;
            const username = !email
                ? createUsername(firstName, lastName)
                : null;

            // have to use Dto as username has to be there for registration
            let usernameDto = {};

            if (username) {
                usernameDto = { username };
            }

            const response = await this.registerUserViaProvider({
                provider: 'clever',
                role,
                email,
                ...usernameDto,
                teachingGrades: [],
                playerName: playerName,
                locale: this.currentLanguage,
                firstName,
                lastName,
                cleverId,
                referredBy: 'clever-library',
            });

            const { success } = response;

            if (!success) {
                this.googleSubmitLoading = false;

                alert(
                    'Unable to create an account. ' +
                        'Please feel free to contact us to fix this!',
                );
            }

            const {
                authToken,
                userDataToken,
                isUserExists = false,
            } = response.data;

            this.googleSubmitLoading = false;

            if (success && isUserExists) {
                // user should be logged in
                this.postLoginSteps({
                    authToken,
                    userDataToken,
                    provider: 'clever',
                });

                sessionStorage.removeItem('teacherOnboardingSurvey');

                this.loading = false;

                return;
            }

            this.postRegistrationSteps({
                authToken,
                userDataToken,
                provider: 'clever',
            });

            this.loading = false;
        },
        closeVideo() {
            this.showVideo = false;
        },
    },
};
</script>

<style lang="scss">
@import 'scss/main.scss';
@import 'scss/global/_themes.halloween.scss';

.toggle-halloween {
    position: fixed;
    width: 100px;
    z-index: 6;
    right: 50px;
    bottom: 50px;
    cursor: pointer;
    filter: grayscale(1);

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        width: 50px;
        right: 1rem;
        bottom: 1rem;
    }
}

.theme--halloween .toggle-halloween {
    filter: none;
}
</style>
