/* global mixpanel */
export const TEACHER_ONBOARDING_REGISTER_MP_EVENTS = {
    TEACHER_ONBOARDING_OPENED_SLIDE_1: 'teacherOnboardingOpenedSlide1',
    TEACHER_ONBOARDING_OPENED_SLIDE_2: 'teacherOnboardingOpenedSlide2',
    TEACHER_ONBOARDING_OPENED_SLIDE_3: 'teacherOnboardingOpenedSlide3',
    TEACHER_ONBOARDING_OPENED_SLIDE_4: 'teacherOnboardingOpenedSlide4',
    TEACHER_ONBOARDING_OPENED_SLIDE_5: 'teacherOnboardingOpenedSlide5',
    TEACHER_ONBOARDING_OPENED_SLIDE_6: 'teacherOnboardingOpenedSlide6',
    TEACHER_ONBOARDING_OPENED_SLIDE_7: 'teacherOnboardingOpenedSlide7',
    TEACHER_ONBOARDING_OPENED_SLIDE_8: 'teacherOnboardingOpenedSlide8',
    TEACHER_ONBOARDING_OPENED_DETAILS_ENTRY:
        'teacherOnboardingOpenedDetailsEntry',
    TEACHER_ONBOARDING_ANSWERED_GRADES: 'teacherOnboardingAnsweredGrades',
    TEACHER_ONBOARDING_ANSWERED_WHERE_DID_YOU_HEAR:
        'teacherOnboardingAnsweredWhereDidYouHear',
    TEACHER_ONBOARDING_ANSWERED_WHAT_DO_YOU_NEED_THE_MOST:
        'teacherOnboardingAnsweredWhatDoYouNeedTheMost',
    TEACHER_ONBOARDING_SIGN_UP: 'teacherOnboardingSignUp',
    TEACHER_ONBOARDING_SIGNUP_SET_ALIAS: 'teacherOnboardingSingupSetAlias',
    TEACHER_ONBOARDING_REGISTER_FINISH: 'teacherOnboardingRegisterFinish',
    TEACHER_ONBOARDING_WATCHED_LINKED_VIDEO:
        'teacherOnboardingWatchedLinkedVideo',
    TEACHER_ONBOARDING_WATCHED_LINKED_VIDEO_TILL_END:
        'teacherOnboardingWatchedLinkedVideoTillEnd',
    TEACHER_ONBOARDING_SIGN_UP_SLIDE_VIDEO: 'teacherOnboardingSignUpSlideVideo',
};

export const TEACHER_ONBOARDING_REGISTER_MP_METHODS = {
    teacherOnboardingOpenedSlide1() {
        mixpanel.track('T-OB: opened grade');
    },
    teacherOnboardingOpenedSlide2() {
        mixpanel.track('T-OB: opened game mode descriptions');
    },
    teacherOnboardingOpenedSlide3() {
        mixpanel.track('T-OB: opened 1-minute game setup');
    },
    teacherOnboardingOpenedSlide4() {
        mixpanel.track('T-OB: opened students insert the game code');
    },
    teacherOnboardingOpenedSlide5() {
        mixpanel.track('T-OB: opened see every student progressing');
    },
    teacherOnboardingOpenedSlide6() {
        mixpanel.track('T-OB: opened celebrate the wins');
    },
    teacherOnboardingOpenedSlide7() {
        mixpanel.track('T-OB: opened Sign up');
    },
    teacherOnboardingOpenedSlide8() {
        mixpanel.track('T-OB: opened where did you hear');
    },
    teacherOnboardingOpenedDetailsEntry() {
        mixpanel.track('T-OB: opened details entry');
    },
    teacherOnboardingAnsweredGrades(data) {
        mixpanel.track('T-OB: answered grades', {
            Grades: data,
        });
    },
    teacherOnboardingSignUp() {
        mixpanel.track('T-OB: Sign up');
    },
    teacherOnboardingAnsweredWhereDidYouHear(data) {
        mixpanel.track('T-OB: answered where did you hear', {
            'Where did you hear about 99math': data,
        });
    },
    teacherOnboardingAnsweredWhatDoYouNeedTheMost(data) {
        mixpanel.track('T-OB: answered what do you need the most', {
            'What do you need the most': data,
        });
        mixpanel.people.set({
            'What do you need the most': data,
        });
    },
    async teacherOnboardingSingupSetAlias(data) {
        await mixpanel.alias(data);
        mixpanel.identify(data);
    },
    teacherOnboardingRegisterFinish(data) {
        mixpanel.track('T-OB: opened dashboard');
        mixpanel.people.set({
            Grades: data.grades,
            'Where did you hear about 99math': data.hearingAbout99math,
        });
    },
    teacherOnboardingWatchedLinkedVideo() {
        mixpanel.people.set({
            'Watched Video': true,
        });
    },
    teacherOnboardingWatchedLinkedVideoTillEnd() {
        mixpanel.people.set({
            'Watched Video Till End': true,
        });
    },
    teacherOnboardingSignUpSlideVideo() {
        mixpanel.track('T-OB: sign up slide video');
    },
};
