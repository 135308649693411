<template>
    <div class="suggest-feature">
        <h2>Suggest a new feature</h2>

        <template v-if="!user || !user.email">
            <p>Contact email (*)</p>
            <input v-model="email" type="text" />
        </template>

        <p>Please describe the idea in as many details as possible (*)</p>
        <textarea
            v-model="featureDescription"
            :class="{ error: featureDescriptionError }"
        />

        <p v-if="ticketCreationError" class="error-text">
            Something went wrong. Please check the form or reach out to us via
            hello@99math.com
        </p>
        <p v-if="featureDescriptionError" class="error-text">
            Feature description is required.
        </p>
        <MathButton @click="createTicket">Submit</MathButton>
    </div>
</template>

<script>
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        MathButton,
    },
    props: {
        ticketCreationError: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['ticketDataSubmit'],
    data() {
        return {
            email: this.user?.email,
            featureDescription: null,
            featureDescriptionError: false,
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch: {
        featureDescription(newVal) {
            this.featureDescriptionError = newVal === '';
        },
    },
    mounted() {
        if (this.user && !this.email) {
            this.email = this.user.email;
        }
    },
    methods: {
        async createTicket() {
            if (!this.featureDescription) {
                this.featureDescriptionError = true;

                return;
            }

            this.$emit('ticketDataSubmit', {
                email: this.email,
                featureDescription: this.featureDescription,
            });
        },
    },
};
</script>
<style scoped lang="scss">
.suggest-feature {
    text-align: center;
    padding: 0 15px;
    max-width: 90vw;
    width: 100%;
    box-sizing: border-box;

    * {
        color: #000;
    }

    h2 {
        margin-top: 3rem;
    }

    select,
    input,
    textarea {
        min-width: 300px;
        border: 1px solid #000;
        background-color: #fff;
        height: 2rem;
        width: 100%;

        @include mq($w-max-phone, max) {
            min-width: 280px;
        }
    }

    .error {
        border-color: red;

        &-text {
            color: red;
        }
    }

    textarea {
        height: 3.5rem;
    }

    p {
        margin: 1.5rem 0 0.5rem;
    }

    .math-button {
        margin: 1rem auto;
        color: #fff;
    }
}
</style>
