/* global mixpanel */
export const STUDENT_SCHOOL_TAB_MP_EVENTS = {
    ST_OPENED_SCHOOL_TAB: 'stOpenedSchoolTab',
    ST_CHANGED_GRADE: 'stChangedGrade',
    ST_STARTED_GAME: 'stStartedGame',
    ST_FINISHED_GAME: 'stFinishedGame',
    ST_CLICKED_PLAY_AGAIN: 'stClickedPlayAgain',
    ST_CLICKED_NEXT_GAME: 'stClickedNextGame',
    ST_CLICKED_HOME: 'stClickedHome',
};

export const STUDENT_SCHOOL_TAB_MP_METHODS = {
    stOpenedSchoolTab() {
        mixpanel.track('ST: opened school tab');
    },
    stChangedGrade(grade) {
        mixpanel.track('ST: changed grade', { 'Grade to': grade });
    },
    stStartedGame() {
        mixpanel.track('ST: started game');
    },
    stFinishedGame() {
        mixpanel.track('ST: finished game');
    },
    stClickedPlayAgain() {
        mixpanel.track('ST: clicked Play again');
    },
    stClickedNextGame() {
        mixpanel.track('ST: clicked Next game');
    },
    stClickedHome() {
        mixpanel.track('ST: clicked Home');
    },
};
