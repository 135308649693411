export const LANGUAGES = {
    en: { title: 'English', key: 'en', iso: 'us' },
    es: { title: 'Español', key: 'es', iso: 'es' },
    pt: { title: 'Português', key: 'pt', iso: 'pt' },
    et: { title: 'Eesti', key: 'et', iso: 'ee' },
    lv: { title: 'Latviešu', key: 'lv', iso: 'lv' },
    tr: { title: 'Türkçe', key: 'tr', iso: 'tr' },
    mk: { title: 'Македонски', key: 'mk', iso: 'mk' },
    ro: { title: 'Limba română', key: 'ro', iso: 'ro' },
    lt: { title: 'Lietuvių', key: 'lt', iso: 'lt' },
    hu: { title: 'Magyar', key: 'hu', iso: 'hu' },
    hr: { title: 'Hrvatski', key: 'hr', iso: 'hr' },
    ka: { title: 'ქართული', key: 'ka', iso: 'ge' },
    // de: { title: 'Deutsch', key: 'de', iso: 'de' },
};

export const DEFAULT_LANGUAGE = 'en';

export const SUPPORTED_LANGUAGES = Object.keys(LANGUAGES);
