<template>
    <div class="plan-container">
        <Part4
            v-if="currentPart === 4"
            :class="{ 'reversed-content': currentPart === 4 }"
            @advance="advanceModal"
        />
        <AlreadySubscribed
            v-else-if="alreadySubscribed"
            @closeModal="$emit('closed')"
        />
        <Part1
            v-else-if="currentPart === 1"
            :product-stripe-info="priceTest && priceTest.products[0].stripe"
            @advance="advanceModal"
        />
        <Part2 v-else-if="currentPart === 2" @advance="advanceModal" />
        <Part3
            v-else-if="currentPart === 3"
            :product-stripe-info="priceTest && priceTest.products[0].stripe"
            @advance="advanceModal"
        />
    </div>
</template>
<script>
import AlreadySubscribed from './AlreadySubscribed.vue';
import Part1 from './Part1.vue';
import Part2 from './Part2.vue';
import Part3 from './Part3.vue';
import Part4 from './Part4.vue';
import { mapGetters } from 'vuex';

export default {
    components: { AlreadySubscribed, Part1, Part2, Part3, Part4 },
    emits: ['closed'],
    data() {
        return {
            currentPart: 1,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            priceTest: 'getUsersPriceTest',
        }),
        alreadySubscribed() {
            return this.user?.subscriptionStatus === 'active';
        },
    },
    updated() {
        if (this.currentPart === 5) {
            this.$emit('closed');
        }
    },
    methods: {
        advanceModal() {
            this.currentPart = this.currentPart + 1;
        },
    },
};
</script>
<style lang="scss" scoped>
.plan-container {
    height: 100%;
}
.reversed-content {
    height: 100%;
    border-radius: 20px;
    border: 2px solid white;
    background-color: white;
}
</style>
