<template>
    <p>
        <svg
            id="ea76qRx8tDn1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 90 90"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
        >
            <g
                id="ea76qRx8tDn2"
                transform="translate(24.100001 28.208299)"
                opacity="0"
            >
                <path
                    d="M29.6,1.1C35.4,1.2,40,5.8,40,11.6c0,10.5-19.2,20.9-19.2,20.9s-19.1-10.6-19.1-20.9C1.7,5.8,6.4,1.2,12.1,1.2c3.5,0,6.8,1.7,8.7,4.7c2-3,5.3-4.8,8.8-4.8Z"
                    fill="#d7443e"
                />
                <path
                    d="M20.9,33.5c-.2,0-.4-.1-.5-.2C19.6,33,0.7,22.4,0.7,11.6C0.7,5.3,5.8,0.2,12.1,0.2c3.4,0,6.6,1.4,8.7,4C25,-0.7,32.2,-1.3,37,2.8c2.6,2.2,4.1,5.4,4.1,8.8c0,11-18.9,21.4-19.7,21.8-.2.1-.3.1-.5.1ZM12.1,2.2c-5.2,0-9.4,4.2-9.4,9.4c0,8.8,15.2,18,18.1,19.8c3-1.7,18.1-10.9,18.1-19.8c0-5.2-4.2-9.4-9.4-9.4-3.2,0-6.1,1.6-7.9,4.3-.3.5-1,.6-1.5.2-.1-.1-.2-.1-.2-.2-1.6-2.8-4.6-4.4-7.8-4.3Z"
                />
            </g>
        </svg>
    </p>
</template>
<script>
export default {
    name: 'Heart',
    // https://www.svgator.com/help/getting-started/add-animated-svg-to-vue-js
    mounted() {
        (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
            (a = document.getElementById(i.root)).svgatorPlayer = {
                ready: (function (a) {
                    b = [];
                    return function (c) {
                        return c ? (b.push(c), a.svgatorPlayer) : b;
                    };
                })(a),
            };
            w[o] = w[o] || {};
            w[o][s] = w[o][s] || [];
            w[o][s].push(i);
            e = d.createElementNS(n, t);
            e.async = true;
            e.setAttributeNS(
                x,
                'href',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            e.setAttributeNS(
                null,
                'src',
                [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''),
            );
            p = d.getElementsByTagName(t)[0];
            p.parentNode.insertBefore(e, p);
        })(
            '91c80d77',
            {
                root: 'ea76qRx8tDn1',
                version: '2022-05-04',
                animations: [
                    {
                        elements: {
                            ea76qRx8tDn2: {
                                transform: {
                                    data: {
                                        o: { x: 45, y: 45, type: 'corner' },
                                        t: { x: -20.899999, y: -16.791701 },
                                    },
                                    keys: {
                                        s: [
                                            { t: 0, v: { x: 1, y: 1 } },
                                            { t: 300, v: { x: 1.5, y: 1.5 } },
                                            { t: 800, v: { x: 1.2, y: 1.2 } },
                                            { t: 1500, v: { x: 2, y: 2 } },
                                        ],
                                    },
                                },
                                opacity: [
                                    { t: 0, v: 0 },
                                    { t: 200, v: 1 },
                                    { t: 1500, v: 1 },
                                    { t: 3000, v: 0 },
                                ],
                            },
                        },
                        s: 'MDIA1M2JlNjVhNI2I4YjVhNGI3FYWNiMmIxNjUF3ZDc2STczNzVM3MzZmNjVhNN2FjSWI1YThhWNmI3YWNiMmISxNjU3ZDc0NmYY2NWFjYjdhOEGI1YTRNYjdhSY2IyYjFMYjZOGNjU3ZDc0NmGY2NWE5YWNhZEmFmNjU3ZDc0DNmY2NWE0YWZXOYjdhOGI1YjUFSYTRGYjdhOFDY1N2RhOWE0OYWZiNmE4NmYW2NWI2YjNhOGFE4YTc2NTdkNYzRjMA|',
                    },
                ],
                options:
                    'MDEAxMDgyMjk3YUTdiNjhYNzk3MYlAyOTQxTDIE5NzM3NjY4NmLIyOTg0',
            },
            'https://cdn.svgator.com/ply/',
            '__SVGATOR_PLAYER__',
            '2022-05-04',
            window,
            document,
            'script',
            'http://www.w3.org/2000/svg',
            'http://www.w3.org/1999/xlink',
        );
    },
};
</script>
