import { DEFAULT_LANGUAGE } from './languages';
import { createI18n } from 'vue-i18n';
import en from './en/en';

export const i18n = createI18n({
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: DEFAULT_LANGUAGE,
    messages: { en },
    silentTranslationWarn: true,
    warnHtmlInMessage: 'off',
}).global;
