import Api from '@/core/services/Api';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import moment from 'moment';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import mathRunnerIo from '@/store/modules/math-runner.io';
import { isEqual, pick } from 'lodash';
import { app } from '@/main';
import { avatarsShuffled } from '@/core/helpers/bots/botsUtils';
import {
    inProductionEnv,
    jclone,
    randomIntFromRange,
} from '@/core/helpers/utils';
import { MathRunnerBot } from '@/core/helpers/bots/MathRunnerBot';
import store from '@/store';
import { router } from '@/main';
import CONSTANTS from '@/core/helpers/constants';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import CrashReportsApi from '@/core/services/AdminApi';
import { ANIMAL_NAMES } from '@/landing/flows/ExperimentalLandingPage/animal-names';
import { createUsername } from '@/flows/Authentication/helpers/AuthHelpers';

export const MATH_RUNNER_GAME_STATES = Object.freeze({
    NONE: 'none',
    LOBBY: 'math-runner/lobby',
    IN_GAME: 'math-runner/inGame',
    MAGIC_TIME: 'math-runner/magicTime',
    GAME_ENDED: 'math-runner/gameEnded',
    CANCELLED: 'math-runner/cancelled',
});

export const MATH_RUNNER_CONSTANTS = Object.freeze({
    LIVES: 3,
    SOLO: {
        MIN_PLAYERS_TO_START: 1,
        // Smallest to biggest order is important in MAGI C_TIME_STARTS_ON_QUESTIONS.
        // MAGIC_TIME_STARTS_ON_QUESTIONS: [3, 6, 9], // Faster debug
        MAGIC_TIME_STARTS_ON_QUESTIONS: [10, 20, 30, 40, 50],
        XP_MULTIPLIER: 15,
    },
    MULTIPLAYER: {
        MIN_PLAYERS_TO_START: 6,
        MAX_PLAYERS: 12,
        // Smallest to biggest order is important in MAGIC_TIME_STARTS_ON_QUESTIONS.
        // MAGIC_TIME_STARTS_ON_QUESTIONS: [2, 4, 8], // Faster debug
        MAGIC_TIME_STARTS_ON_QUESTIONS: [5, 15, 30],
        XP_MULTIPLIER: 10,
    },
    MAX_PLAYERS: {
        '1v1': 2,
        '2v2': 4,
        '3v3': 6,
        together: 6,
    },
    // MAGIC_TIME_LENGTH_IN_SECONDS: 5, // Faster debug
    MAGIC_TIME_LENGTH_IN_SECONDS: 10,
    VIEW_HEIGHT_PX: 300,
    FINISH_LINE_FROM_BOTTOM_PX: 60,
});

const CACHE_SAVE_DELAY_IN_MS = 2000;

// Subtract the CACHE_SAVE_DELAY_IN_MS to allow the first call to save the cache.
let lastCacheUpdateTime = Date.now() - CACHE_SAVE_DELAY_IN_MS;

const mathRunnerStore = {
    namespaced: true,
    modules: {
        mathRunnerIo,
    },
    state: {
        state: MATH_RUNNER_GAME_STATES.NONE,
        noCache: false,
        initialized: false,
        playerName: null,
        gameCode: null,
        isSoloGame: null,
        stats: {
            playedTime: 0,
            started: null,
            correctAnswers: 0,
            wrongAnswers: 0,
            score: 0,
            answers: [],
        },
        info: null,
        math: null,
        timerSync: null,
        players: [],
        playerAnswers: [],
        currentQuestionIndex: 0,
        currentQuestionAnswers: [],
        usedSpells: [],
        magicInterval: null,
        magicTimeElapsed: 0,
        questionDisplayTimeSecBase: 12.5,
        questionDisplayMultiplier: 0.98,
        nextQuestionAppearOffsetSec: 6.25,
        nextQuestionAppearMultiplier: 0.98,
        spawnedQuestions: [],
        runway:
            MATH_RUNNER_CONSTANTS.VIEW_HEIGHT_PX -
            MATH_RUNNER_CONSTANTS.FINISH_LINE_FROM_BOTTOM_PX,
        magicTimeStartTimeout: null,
        elapsedTime: 0,
        bots: {},
        playerWithoutLivesUntilTheEndOfTheRound: false,
        localTimerTicking: false,
        localTimerId: null,
        sentInvitesToGame: [],
        invitedToGames: [],

        timeCorrection: 0,
    },
    getters: {
        state: (state) => state.state,
        score: (state) => state.stats?.score || 0,
        isMagicTime: (state) =>
            state.state === MATH_RUNNER_GAME_STATES.MAGIC_TIME,
        gameCode: (state) => state.gameCode,
        math: (state) => state.math,
        initialized: (state) => state.initialized,
        playerName: (state) => state.playerName,
        stats: (state) => state.stats,
        questions: (state) => state.info?.questions,
        players: (state) => state.players,
        playerAnswers: (state) => state.playerAnswers,
        playerCount: (state) => state.players.length,
        currentQuestionAnswers: (state) => state.currentQuestionAnswers,
        currentQuestionIndex: (state) => state.currentQuestionIndex,
        usedSpells: (state) => state.usedSpells,
        lives: (state) =>
            state.players.find((player) => player.name === state.playerName)
                ?.lives,
        info: (state) => state.info,
        magicTimeElapsed: (state) => state.magicTimeElapsed,
        localTimerId: (state) => state.localTimerId,
        localTimerTicking: (state) => state.localTimerTicking,
        lastSpawnIndex: (state) => state.info.lastSpawnIndex || 0,
        questionDisplayMultiplier: (state) => state.questionDisplayMultiplier,
        questionDisplayTimeSecBase: (state) => state.questionDisplayTimeSecBase,
        questionDisplayTimeSec: (state) =>
            state.questionDisplayTimeSecBase +
            (state.info?.spells?.slowDown || 0),
        nextQuestionAppearMultiplier: (state) =>
            state.nextQuestionAppearMultiplier,
        nextQuestionAppearOffsetSec: (state) =>
            state.nextQuestionAppearOffsetSec +
            (state.info?.spells?.slowDown || 0),
        runway: (state) => state.runway,
        spawnedQuestions: (state) => state.spawnedQuestions,
        bots: (state) => state.bots,
        botCount: (state) => state.players.filter((p) => p.bot).length,
        myStats: (state, getters, rootState, rootGetters) => {
            return state.players.find(
                (player) => player.userId === rootGetters['v2/user/uid'],
            );
        },
        isGameCreator: (state, getters) => getters.myStats?.isGameCreator,
        startTime: (state) => state.info?.startTime || 0,
        elapsedTime: (state) => state.elapsedTime,
        magicInterval: (state) => state.magicInterval,
        magicTimeStartTimeout: (state) => state.magicTimeStartTimeout,
        playerWithoutLivesUntilTheEndOfTheRound: (state) =>
            state.playerWithoutLivesUntilTheEndOfTheRound,
        gainXp: (state) => {
            return state.info?.spells?.gainXp || 0;
        },
        totalCorrectAnswers: (state, getters) => {
            return (
                getters.players?.reduce(
                    (totalScore, player) =>
                        player.correctAnswers
                            ? totalScore + player.correctAnswers
                            : totalScore,
                    0,
                ) || 0
            );
        },
        team1CorrectAnswers: (state, getters) => {
            const team1 = getters.players.filter((p) => p.team === 'team1');

            return (
                team1?.reduce(
                    (totalScore, player) =>
                        player.correctAnswers
                            ? totalScore + player.correctAnswers
                            : totalScore,
                    0,
                ) || 0
            );
        },
        team1Xp: (state, getters) => {
            const multiplier = MATH_RUNNER_CONSTANTS.MULTIPLAYER.XP_MULTIPLIER;

            return (
                getters.team1CorrectAnswers * multiplier +
                getters.team1CorrectAnswers * getters.gainXp
            );
        },
        team2CorrectAnswers: (state, getters) => {
            const team2 = getters.players.filter((p) => p.team === 'team2');

            return (
                team2?.reduce(
                    (totalScore, player) =>
                        player.correctAnswers
                            ? totalScore + player.correctAnswers
                            : totalScore,
                    0,
                ) || 0
            );
        },
        team2Xp: (state, getters) => {
            const multiplier = MATH_RUNNER_CONSTANTS.MULTIPLAYER.XP_MULTIPLIER;

            return (
                getters.team2CorrectAnswers * multiplier +
                getters.team2CorrectAnswers * getters.gainXp
            );
        },
        teamXp: (state, getters) => {
            const multiplier = getters.isSoloGame
                ? MATH_RUNNER_CONSTANTS.SOLO.XP_MULTIPLIER
                : MATH_RUNNER_CONSTANTS.MULTIPLAYER.XP_MULTIPLIER;

            return (
                getters.totalCorrectAnswers * multiplier +
                getters.totalCorrectAnswers * getters.gainXp
            );
        },
        isSoloGame: (state) =>
            state.isSoloGame === 'true' || state.isSoloGame === true,
        isMultiplayerGame: (state) =>
            state.isSoloGame === 'false' || state.isSoloGame === false,
        gameMode: (state) => state.info?.gameMode || 'together',
        isMathRunnerGame: (state) =>
            state.state !== MATH_RUNNER_GAME_STATES.NONE,
    },
    mutations: {
        localTimerTicking(state, next) {
            state.localTimerTicking = next;
        },
        localTimerId(state, next) {
            state.localTimerId = next;
        },
        setElapsedTime(state, next) {
            state.elapsedTime = next;
        },
        resetElapsedTime(state) {
            state.elapsedTime = 0;
        },
        setStartTime(state, next) {
            if (state.info) {
                state.info.startTime = next;
            }
        },
        set: (state, { key, value }) => {
            state[key] = value;
        },
        state: (state, next) => {
            state.state = next;
        },
        initialized: (state, next) => {
            if (state.initialized === next) return;

            state.initialized = next;
        },
        noCache: (state, next) => {
            if (state.noCache === next) return;

            state.noCache = next;
        },
        gameCode: (state, next) => {
            if (state.gameCode === next) return;

            state.gameCode = next;
        },
        isSoloGame: (state, next) => {
            state.isSoloGame = next;
        },
        playerName: (state, next) => {
            if (next) {
                sessionStorage.setItem('playerName', next);
            }

            state.playerName = next;
        },
        stats: (state, next) => {
            state.stats = next;
        },
        info: (state, next) => {
            state.info = next;
        },
        math: (state, next) => {
            state.math = next;
        },
        timerSync: (state, next) => {
            state.timerSync = next;
        },
        incrementBatch: (state) => {
            if (state.info) {
                state.info.batch += 1;
            }
        },
        players: (state, next) => {
            const currentUserId = store.getters['v2/user/uid'];

            // Sort alphabetically to avoid the players list jumping around.
            next.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            const currentIndex = next.findIndex(
                (player) => player.userId === currentUserId,
            );
            const currentPlayer = next.splice(currentIndex, 1)[0];
            if (currentPlayer) {
                // Move "me" to the front of the list.
                next.unshift(currentPlayer);
            }

            state.players = next;

            if (!store.getters['v2/mathRunner/isGameCreator']) return;

            const bots = next.filter((player) => player.bot);

            bots.forEach(async (bot) => {
                if (state.bots[bot.name]) return;

                const botObject = new MathRunnerBot(bot.name, bot.avatar);

                await botObject.resumeBot(state.gameCode);

                state.bots[botObject._name] = botObject;
            });
        },
        addPlayer: (state, player) => {
            // console.log('store::game/addPlayer', player, state);

            const ppos = state.players.findIndex(
                (p) => p.userId === player.userId,
            );

            if (ppos === -1) {
                state.players.push(player);
            } else {
                state.players[ppos] = player;
            }
        },
        updatePlayer: (state, player) => {
            const ppos = state.players.findIndex(
                (p) => p.userId === player.userId,
            );

            if (ppos === -1) {
                state.players.push(player);
            } else {
                state.players[ppos] = player;
            }
        },
        playerLostLife: (state, player) => {
            const ppos = state.players.findIndex(
                (p) => p.userId === player.userId,
            );

            if (ppos !== -1) {
                state.players[ppos].lives--;
            }
        },
        removePlayer: (state, player) => {
            state.players = state.players.filter((p) => p.userId !== player);
        },
        playerAnswer: (state, answer) => {
            if (!state.stats) {
                console.error('playerAnswewr - NO STATS');

                return;
            }

            state.stats.answers.push(answer);

            if (answer.questionBody.correct) {
                state.stats.correctAnswers++;

                state.stats.score++;
            } else {
                state.stats.wrongAnswers++;

                state.stats.score--;

                if (state.stats.score < 0) {
                    state.stats.score = 0;
                }
            }
        },
        otherPlayerAnswer: (state, data) => {
            state.playerAnswers.push(data);
        },
        lives: (state, next) => {
            state.lives = next;
        },
        removeLife: (state) => {
            if (state.lives === 0) return;

            state.lives--;
        },
        resetCurrentQuestionAnswers: (state) => {
            state.currentQuestionAnswers = [];
        },
        otherPeopleAnsweredCurrentQuestion: (state, answer) => {
            state.currentQuestionAnswers.push(answer);
        },
        playerUsedSpells: (state, data) => {
            state.usedSpells = data;
        },
        playerUsedSpell: (state, data) => {
            state.usedSpells.push(data);
        },
        resetUsedSpells: (state) => {
            state.usedSpells = [];
        },
        setMagicInterval: (state, data) => {
            state.magicInterval = data;
        },
        magicTimeTick: (state) => {
            state.magicTimeElapsed++;
        },
        magicTimeReset: (state) => {
            state.magicTimeElapsed = 0;
        },
        magicTimeElapsed: (state, next) => {
            state.magicTimeElapsed = next;
        },
        setMagicTimeStart: (state, next) => {
            state.info.magicTimeStart = next;
        },
        setMagicTimeEnd: (state, next) => {
            state.info.magicTimeEnd = next;
        },
        spawnQuestion: (state, data) => {
            state.spawnedQuestions.push(data);
        },
        updateQuestionsInInfo: (state, data) => {
            state.info.questions = data;
        },
        speedUpQuestion: (state, { i, speedUp }) => {
            if (
                !state.spawnedQuestions?.[i] ||
                state.spawnedQuestions?.[i]?.spedUp
            )
                return;

            state.spawnedQuestions[i].speed *= speedUp;
            state.spawnedQuestions[i].spedUp = true;
        },
        spawnedQuestions: (state, next) => {
            state.spawnedQuestions = next;
        },
        revealQuestion: (state, i) => {
            const index = state.spawnedQuestions.findIndex(
                (q) => q.index === i,
            );

            state.spawnedQuestions[index].revealed = true;
        },
        magicTimeStartTimeout: (state, next) => {
            state.magicTimeStartTimeout = next;
        },
        spawnBot: (state, bot) => {
            state.bots[bot._name] = bot;
        },
        despawnBot: (state, botId) => {
            state.bots[botId].close();
            delete state.bots[botId];
        },
        switchGameStarted: (state, next) => {
            state.stats.gameStarted = next;
        },
        switchPlayerWithoutLivesUntilTheEndOfRound: (state, next) => {
            state.playerWithoutLivesUntilTheEndOfTheRound = next;
        },
        setCurrentQuestionIndex: (state, next) => {
            state.currentQuestionIndex = next;
        },
        resetCurrentQuestionIndex: (state) => {
            state.currentQuestionIndex = 0;
        },
        setTimeCorrection: (state, next) => {
            state.timeCorrection = next;
        },
        setSentInvitesToGame: (state, next) => {
            state.sentInvitesToGame = next;
        },
        setInvitedToGames: (state, next) => {
            state.invitedToGames = next;
        },
    },
    actions: {
        init: async (store, payload) => {
            if (store.getters.initialized) return;

            console.log('Not initialized');

            console.log({ payload });

            console.log(
                store.getters.gameCode !== null &&
                    store.getters.gameCode !== payload.gameCode,
            );

            if (
                store.getters.gameCode !== null &&
                store.getters.gameCode !== payload.gameCode
            ) {
                await store.dispatch('reset');
            } else {
                await store.dispatch('loadCache');
            }

            console.log(`store::MathRunner.init ${payload.gameCode}`);

            if (!store.getters.gameCode) {
                await store.commit('gameCode', payload.gameCode);
            }

            if (payload.isSoloGame) {
                store.commit('isSoloGame', payload.isSoloGame);
            }

            const playerName =
                store.rootGetters.user?.playerName ??
                sessionStorage.getItem('playerName');

            await store.commit('playerName', playerName);

            await store.dispatch('mathRunnerIo/init');

            if (!store.getters.math && !payload.math) {
                await store.dispatch('syncronize', payload.gameCode);
            } else if (payload.math) {
                await store.commit('math', payload.math);
            }

            if (!store.getters.info && !payload.info) {
                await store.dispatch('syncronize', payload.gameCode);
            } else if (payload.info) {
                await store.commit('info', payload.info);
            }

            await store.commit('initialized', true);

            console.log(
                `store::MathRunner.init ${payload.gameCode} initialized`,
            );

            const magicTime = await store.dispatch('reInitMagicTime');

            if (!magicTime) {
                await store.dispatch('initQuestions');
            }

            await store.dispatch('saveCache');
        },
        reset: async (store) => {
            console.debug('store::MathRunner.reset');

            store.dispatch('mathRunnerIo/reset');
            store.commit('initialized', false);
            store.commit('gameCode', null);
            store.commit('playerName', null);
            store.commit('stats', {
                playedTime: 0,
                started: null,
                correctAnswers: 0,
                wrongAnswers: 0,
                answers: [],
            });
            store.commit('info', null);
            store.commit('math', null);
            store.commit('timerSync', null);
            store.commit('players', []);

            if (store.getters.magicInterval) {
                clearInterval(store.getters.magicInterval);

                store.commit('setMagicInterval', null);
            }

            store.commit('spawnedQuestions', []);
            store.commit('resetCurrentQuestionIndex');
            store.commit('resetCurrentQuestionAnswers');
            store.commit('state', MATH_RUNNER_GAME_STATES.NONE);
            store.commit('lives', MATH_RUNNER_CONSTANTS.LIVES);
            store.dispatch('stopLocalTimer');
            store.commit('setStartTime', 0);
            store.dispatch('removeAllBots');
            store.dispatch('clearCache');
            store.commit('setSentInvitesToGame', []);
        },
        saveCache: (store, force) => {
            if (store.state.noCache) {
                // console.log('store::MathRunner.saveCache NOCACHE');

                return;
            }
            if (
                force ||
                Date.now() - lastCacheUpdateTime > CACHE_SAVE_DELAY_IN_MS
            ) {
                console.log('store::MathRunner save cache');

                // const str = JSON.stringify(
                //     omit(store.state, ['mathRunnerIo', 'math', 'bots']),
                // );

                const str = JSON.stringify(
                    pick(store.state, ['sentInvitesToGame', 'invitedToGames']),
                );

                sessionStorage.setItem('store/cache/mathRunner', str);

                // console.log('store::MathRunner cache saved');

                lastCacheUpdateTime = Date.now();
            }
        },
        loadCache: async (store) => {
            const str = sessionStorage.getItem('store/cache/mathRunner');

            let cache;

            try {
                cache = JSON.parse(str);
            } catch (err) {
                console.error(
                    'store::MathRunner uncaching error',
                    'math-runner',
                    str,
                    err,
                );

                cache = null;
            }

            // console.log('store::MathRunner loading cache', cache);

            if (!cache) return;

            let cacheIsOK = true;

            let problemKey;

            for (const [key] of Object.entries(cache)) {
                cacheIsOK = isEqual(cache[key], store.state[key]);

                if (!cacheIsOK) {
                    problemKey = key;

                    break;
                }
            }

            console.log('store::MathRunner load cache');

            store.commit('noCache', true);

            for (const [key, value] of Object.entries(cache)) {
                store.commit('set', { key, value });
            }

            let math = null;

            if (cache.info && cache.info.type) {
                math = TopicsFactory.getTopicObject(cache.info);
            }

            store.commit('math', math);

            store.dispatch('mathRunnerIo/init');

            store.commit('localTimerTicking', false);

            store.dispatch('startLocalTimer');

            store.commit('noCache', false);
        },
        clearCache: (store) => {
            console.log('store::MathRunner clear cache');

            sessionStorage.removeItem('store/cache/mathRunner');

            store.dispatch('mathRunnerIo/clearCache');
        },
        createGame: async (store, payload) => {
            try {
                const { skill, isSoloGame, classCode, bot, gameMode } = payload;

                console.log(`store::MathRunner.createGame`, payload);

                const math = TopicsFactory.getTopicObject(skill);

                const isSolo = isSoloGame === 'true' || isSoloGame === true;

                const runnerMode = isSolo ? 'SOLO' : 'MULTIPLAYER';

                const magicQuestions =
                    MATH_RUNNER_CONSTANTS[runnerMode]
                        .MAGIC_TIME_STARTS_ON_QUESTIONS;

                const magicQuestionCount = magicQuestions.length;

                const lastMagicTimeQuestionNumber =
                    magicQuestions[magicQuestionCount - 1];

                const questions = math.generateQuestions(
                    lastMagicTimeQuestionNumber,
                    false,
                );

                if (inProductionEnv()) {
                    void CrashReportsApi().post(
                        `crash-reports/increase-daily-counter/runnerGame/createCalls`,
                    );
                }

                const result = await Api().post('/math-runner/create', {
                    skill,
                    questions: questions.map((q, i) => {
                        return { ...q, index: i };
                    }),
                    gameCode: payload.previousGameCode,
                    magicQuestions,
                    magicTimeDuration:
                        MATH_RUNNER_CONSTANTS.MAGIC_TIME_LENGTH_IN_SECONDS,
                    isSoloGame: isSolo,
                    classCode,
                    maxPlayers: MATH_RUNNER_CONSTANTS.MAX_PLAYERS[gameMode],
                    bot,
                    gameMode,
                });

                const { data, serverTime, error } = result.data;

                if (error) {
                    console.error(
                        'store::MathRunner.createGame: request error: ',
                        error,
                    );

                    return false;
                }

                store.dispatch('setTimeCorrection', serverTime);

                const { gameCode } = data;

                try {
                    new TrackingService().track(EVENTS.CREATED_MATH_RUNNER, {
                        gameCode,
                        isSolo,
                    });
                } catch (error) {
                    console.log('Error in create runner MP event: ', error);
                }

                await store.dispatch('init', {
                    gameCode,
                    info: { ...skill, questions, spells: {} },
                    math,
                    isSoloGame,
                    classCode,
                });

                console.log(
                    `store::MathRunner.createGame successfully created ${gameCode}`,
                );

                return true;
            } catch (error) {
                console.error('store::MathRunner.createGame: error: ', error);
            }

            return false;
        },
        syncronize: async (store, gameCode) => {
            console.log(`store::MathRunner.syncronize ${gameCode}`);

            const info = await store.dispatch('mathRunnerIo/request', {
                event: 'getGameInfo',
                data: gameCode,
            });

            console.log('store::MathRunners.syncronize io game info', info);

            if (!info) {
                console.error(
                    'store::MathRunner.syncronize: no game info received',
                );

                await store.dispatch('reset');

                await store.dispatch('v2/ui/alert', 'Game is finished', {
                    root: true,
                });

                await router.push({ name: 'home-game.v10.main-page' });

                return;
            }

            const players = [...info.players];

            delete info.players;

            store.commit('info', info);

            const playerName =
                store.getters.user?.playerName ??
                sessionStorage.getItem('playerName');

            store.commit('playerName', playerName);

            const math = TopicsFactory.getTopicObject(info);

            store.commit('math', math);

            store.commit('isSoloGame', info.isSoloGame);

            if (players.length) {
                store.commit('players', players);
            }

            store.dispatch('saveCache');
        },
        reInitMagicTime: (store) => {
            if (store.getters.info.magicTimeStart) {
                console.log('Magic time in progress');

                store.commit('state', MATH_RUNNER_GAME_STATES.MAGIC_TIME);

                const magicTimeElapsed =
                    moment()
                        .add(store.state.timeCorrection)
                        .diff(
                            moment(store.getters.info.magicTimeStart),
                            'seconds',
                        ) - 1;

                console.log({ magicTimeElapsed });

                store.commit('magicTimeElapsed', magicTimeElapsed);

                store.commit(
                    'setMagicInterval',
                    setInterval(() => {
                        store.commit('magicTimeTick');

                        if (
                            MATH_RUNNER_CONSTANTS.MAGIC_TIME_LENGTH_IN_SECONDS <=
                            store.getters.magicTimeElapsed
                        ) {
                            if (store.getters.isGameCreator) {
                                store.dispatch('initMagicTimeEnd');
                            } else {
                                store.dispatch('endMagicTime', {
                                    startTime: store.getters.info.startTime,
                                });
                            }
                        }
                    }, 1000),
                );

                return true;
            }

            return false;
        },
        startGame: async (store) => {
            store.commit('state', MATH_RUNNER_GAME_STATES.IN_GAME);
            store.commit('switchGameStarted', true);

            await store.dispatch('consumeEnergy');

            if (store.getters.isGameCreator) {
                await store.dispatch('setStartTime');
            }

            setTimeout(() => {
                store.dispatch('syncronize', store.getters.gameCode);
            }, 500);

            // await store.dispatch('spawnQuestions');

            try {
                const ppos = store.getters.players.findIndex(
                    (p) => p.isGameCreator,
                );

                const creator = store.getters.players[ppos] || null;

                const numberOfPlayers =
                    store.getters.playerCount - store.getters.botCount;

                const playersWithClassOutsideOfClass =
                    store.getters.players.filter(
                        (p) =>
                            creator?.classCode &&
                            p?.classCode &&
                            !p.bot &&
                            p.classCode !== creator.classCode,
                    ) || [];

                const numberOfPlayersWithClassOutsideOfClass =
                    playersWithClassOutsideOfClass.length || 0;

                const playersWithSameClass =
                    store.getters.players.filter(
                        (p) =>
                            creator?.classCode &&
                            p?.classCode &&
                            !p.bot &&
                            p.classCode === creator.classCode,
                    ) || [];

                const numberOfPlayersFromSameClass =
                    playersWithSameClass.length || 0;

                const playersWithoutClass =
                    store.getters.players.filter(
                        (p) => !p.classCode && !p.bot,
                    ) || [];

                const numberOfPlayersWithoutClass =
                    playersWithoutClass.length || 0;

                const hadOutsidePlayers =
                    numberOfPlayersWithClassOutsideOfClass +
                        numberOfPlayersWithoutClass <
                    0;

                new TrackingService().track(EVENTS.STARTED_PLAYING_RUNNER, {
                    gameCode: store.getters.gameCode,
                    isSolo: store.getters.isSoloGame,
                    isCreator: store.getters.isGameCreator,
                    numberOfPlayers,
                    totalPlayers: store.getters.playerCount,
                    numberOfBots: store.getters.botCount,
                    numberOfPlayersWithClassOutsideOfClass,
                    numberOfPlayersFromSameClass,
                    numberOfPlayersWithoutClass,
                    hadOutsidePlayers,
                    gameMode: store.getters.gameMode,
                });

                const started = moment().format();

                await Promise.all([
                    store.state.sentInvitesToGame.map((invite) => {
                        if (invite.declined) return;

                        store.dispatch(
                            'v2/sse/emitEvent',
                            {
                                channel: 'app',
                                data: {
                                    receiverId: invite.receiverId,
                                    gameCode: store.getters.gameCode,
                                    type: 'runnerStarted',
                                    started,
                                },
                            },
                            { root: true },
                        );
                    }),
                ]);

                store.commit('stats', { ...store.state.stats, started });
                store.commit('setSentInvitesToGame', []);
            } catch (error) {
                console.log('Error in start game runner MP event: ', error);
            }

            if (
                window.location.hostname !== 'localhost' &&
                window.location.hostname !== 'qa.99math.com' &&
                window.location.hostname !== 'test.99math.com'
            ) {
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/runnerGame/startedGames`,
                );
            }

            store.dispatch('saveCache');
        },
        setStartTime: async (store) => {
            console.log('Host set start time');

            return await store.dispatch('mathRunnerIo/emit', {
                event: 'setStartTime',
                data: {
                    timestamp: moment()
                        .add(store.state.timeCorrection)
                        .format(),
                    gameCode: store.state.gameCode,
                },
            });
        },
        playerAnswer: async (store, answer) => {
            console.debug('store:mathRunner.playerAnswer', answer);

            store.commit('playerAnswer', answer);
            store.commit('resetCurrentQuestionAnswers');
            store.dispatch('mathRunnerIo/emit', {
                event: 'playerAnswer',
                data: answer,
            });
            store.commit(
                'setCurrentQuestionIndex',
                answer.questionBody.index + 1,
            );
            store.dispatch('saveCache');
        },
        updateQuestions: async (store, { questions, startTime }) => {
            console.debug(`update questions`, questions);

            store.commit('spawnedQuestions', []);
            await store.commit('updateQuestionsInInfo', questions);
            await store.commit('setStartTime', startTime);
            store.dispatch('initQuestions');
        },
        startMagicTime: (store, data) => {
            console.debug(
                `Starting magic time from ${data.info.magicTimeStart} to ${data.info.magicTimeEnd}`,
            );

            sessionStorage.removeItem('usedSpell');

            store.commit('setMagicTimeStart', data.info.magicTimeStart);
            store.commit('setMagicTimeEnd', data.info.magicTimeEnd);

            if (store.getters['lives'] < 1) {
                store.commit(
                    'switchPlayerWithoutLivesUntilTheEndOfRound',
                    true,
                );
            }

            store.commit('resetUsedSpells');

            console.debug('Magic time STARTED');

            store.commit('magicTimeStartTimeout', null);

            setTimeout(() => {
                store.commit('state', MATH_RUNNER_GAME_STATES.MAGIC_TIME);
                store.commit(
                    'setMagicInterval',
                    setInterval(() => {
                        store.commit('magicTimeTick');

                        if (
                            MATH_RUNNER_CONSTANTS.MAGIC_TIME_LENGTH_IN_SECONDS ===
                            store.getters.magicTimeElapsed
                        ) {
                            if (store.getters.isGameCreator) {
                                store.dispatch('initMagicTimeEnd');
                            }
                        }
                    }, 1000),
                );
            }, 1500);

            store.dispatch('saveCache');
        },
        initMagicTimeEnd: (store) => {
            console.log('initMagicTimeEnd called');

            store.dispatch('mathRunnerIo/emit', {
                event: 'magicTimeEnd',
                data: { gameCode: store.getters.gameCode },
            });

            store.commit('magicTimeReset');

            clearInterval(store.getters.magicInterval);
        },
        endMagicTime: (store, data) => {
            console.log('ENDING MAGIC TIME');

            store.commit('setStartTime', data.startTime);
            store.commit('setMagicTimeStart', null);
            store.commit('setMagicTimeEnd', null);
            store.commit('state', MATH_RUNNER_GAME_STATES.IN_GAME);

            clearInterval(store.getters.magicInterval);

            store.commit('setMagicInterval', null);
            store.commit('magicTimeReset');
            if (store.getters['lives'] > 0) {
                store.commit(
                    'switchPlayerWithoutLivesUntilTheEndOfRound',
                    false,
                );
            }

            store.dispatch('saveCache');
        },
        setElapsedTime: async (store) => {
            // console.log({elapsedTimeIf: store.getters.startTime});

            if (store.getters.startTime) {
                await store.commit(
                    'setElapsedTime',
                    moment()
                        .add(store.state.timeCorrection)
                        .diff(moment(store.getters.startTime), 'seconds'),
                );
            }
        },
        startLocalTimer: async (store) => {
            if (store.getters.localTimerTicking) return;

            await store.dispatch('setElapsedTime');
            store.commit('localTimerTicking', true);

            const timerId = setInterval(
                () => store.dispatch('setElapsedTime'),
                1000,
            );

            console.debug('Timer started');

            store.commit('localTimerId', timerId);
        },
        stopLocalTimer: (store) => {
            store.commit('localTimerTicking', false);
            store.commit('resetElapsedTime');

            clearInterval(store.getters.localTimerId);
        },
        initQuestions: async (store) => {
            console.log('Init questions');

            const spellData = sessionStorage.getItem('usedSpell');

            if (spellData) {
                store.commit('playerUsedSpell', JSON.parse(spellData));
            }

            const runnerMode = store.getters.isSoloGame
                ? 'SOLO'
                : 'MULTIPLAYER';

            const magicQuestions =
                MATH_RUNNER_CONSTANTS[runnerMode]
                    .MAGIC_TIME_STARTS_ON_QUESTIONS;

            await store.dispatch('setElapsedTime');

            await store.dispatch('startLocalTimer');

            const elapsedTime = parseInt(store.getters.elapsedTime, 10);

            const batchStartIndex =
                [0, ...magicQuestions][store.getters.info.batch - 1] || 0;

            const batch = [...store.getters.questions]
                .slice(batchStartIndex)
                .filter((q) => q.secondsTillSpawn);

            console.log({ magicQuestions });

            console.log({ batchesLoaded: store.getters.info.batch });

            console.log({ batchStartIndex });

            console.log({ batch });

            const currentQuestionIndexArr = batch.filter(
                (q) =>
                    q.secondsTillSpawn &&
                    (elapsedTime - q.secondsTillSpawn) * q.speed + 34 <
                        store.getters.runway,
            );

            const currentQuestionIndex = currentQuestionIndexArr.length
                ? currentQuestionIndexArr[0].index
                : batchStartIndex;

            store.commit('setCurrentQuestionIndex', currentQuestionIndex);

            console.log({ currentQuestionIndex });

            const startBatchIndex =
                [...magicQuestions]
                    .reverse()
                    .find((index) => index <= currentQuestionIndex) || 0;

            const spawned = store.getters.questions
                .slice(startBatchIndex)
                .filter(
                    (q) =>
                        q.secondsTillSpawn && q.secondsTillSpawn <= elapsedTime,
                );

            await store.commit('spawnedQuestions', spawned);

            const toBeSpawned = store.getters.questions
                .slice(currentQuestionIndex)
                .filter(
                    (q) =>
                        q.secondsTillSpawn && q.secondsTillSpawn > elapsedTime,
                );

            toBeSpawned.forEach((q) => {
                console.log(
                    `Spawning Q ${q.index} with ${q.secondsTillSpawn} in ${
                        q.secondsTillSpawn - elapsedTime
                    }`,
                );

                setTimeout(
                    () => {
                        store.commit('spawnQuestion', q);
                    },
                    (q.secondsTillSpawn - elapsedTime) * 1000,
                );
            });
        },
        revealQuestion: (store, i) => {
            console.log(`Revealed question ${i}`);

            store.commit('revealQuestion', i);

            app.config.globalProperties.$emitter.emit('question-revealed', i);

            store.dispatch('saveCache');
        },
        pendingPlayerAnswer: async (store, answer) => {
            // console.log('store:mathRunner.pendingPlayerAnswer', answer);

            store.dispatch('mathRunnerIo/emit', {
                event: 'pendingPlayerAnswer',
                data: answer,
            });
        },
        useSpell: async (store, { spell, usedOnPlayerId, healAmount }) => {
            console.log('store:mathRunner.usedSpell', spell);

            const data = {
                playerName: store.state.playerName,
                userId: store.rootGetters['v2/user/uid'],
                gameCode: store.state.gameCode,
                healedPlayerId: usedOnPlayerId,
                healAmount,
            };

            store.dispatch('mathRunnerIo/emit', {
                event: `${spell}Spell`,
                data,
            });

            if (store.getters.isSoloGame) {
                store.dispatch('initMagicTimeEnd');
            }

            sessionStorage.setItem('usedSpell', JSON.stringify(data));
        },
        botUseSpell: async (
            store,
            { playerName, userId, spell, usedOnPlayerId, healAmount },
        ) => {
            if (
                store.getters.state !== MATH_RUNNER_GAME_STATES.MAGIC_TIME ||
                !store.getters.isGameCreator
            ) {
                return;
            }

            console.log('store:mathRunner.botUseSpell', userId, spell);

            store.dispatch('mathRunnerIo/emit', {
                event: `${spell}Spell`,
                data: {
                    playerName,
                    userId,
                    gameCode: store.state.gameCode,
                    healedPlayerId: usedOnPlayerId,
                    healAmount,
                },
            });
        },
        playerUsedSpell: async (store, data) => {
            console.log('store:mathRunner.playerUsedSpell', data.spell);

            store.commit('playerUsedSpell', data);

            if (
                data.spell === 'healPlayer' &&
                data.healedPlayerId === store.rootGetters['v2/user/uid']
            ) {
                const lives = store.rootGetters['v2/mathRunner/lives'];

                store.commit('lives', lives + data.healAmount);
            }

            store.dispatch('saveCache');
        },
        otherPlayersPendingAnswer: async (store, data) => {
            console.log('store:mathRunner.otherPlayersPendingAnswer', data);

            store.commit('otherPeopleAnsweredCurrentQuestion', data);

            if (await store.dispatch('allPlayersAnsweredCurrentQuestion')) {
                const index = store.getters.currentQuestionIndex;

                const spawnedQuestions = store.getters.spawnedQuestions;

                const indexInSpawned = spawnedQuestions.findIndex(
                    (q) => q.index === index,
                );

                store.commit('speedUpQuestion', {
                    i: indexInSpawned,
                    speedUp: 1.5,
                });
            }

            store.dispatch('saveCache');
        },
        allPlayersAnsweredCurrentQuestion: (store) => {
            const answeredQuestion = (player) =>
                store.getters.currentQuestionAnswers?.some(
                    (answeredPlayer) =>
                        answeredPlayer.playerName === player.name,
                );

            const playersWhoAnswered =
                store.getters.players.filter(answeredQuestion);

            return (
                store.getters.players.length &&
                playersWhoAnswered.length === store.getters.players.length
            );
        },
        otherPlayersAnswer: async (store, data) => {
            console.debug('store:mathRunner.otherPlayersAnswer', data);

            const player = store.state.players.find(
                (p) => p.name === data.playerName,
            );

            if (!player) return;

            let score = player?.score ?? 0;

            if (data.questionBody?.correct) {
                score++;

                player.correctAnswers++;
            } else {
                score--;

                if (score < 0) score = 0;
            }

            store.commit('updatePlayer', {
                ...player,
                score,
            });

            store.dispatch('saveCache');
        },
        removeLife: async (store) => {
            store.commit('removeLife');

            store.dispatch('mathRunnerIo/emit', {
                event: 'removeLife',
                data: {
                    playerName: store.state.playerName,
                    userId: store.rootGetters['v2/user/uid'],
                    gameCode: store.state.gameCode,
                },
            });

            store.dispatch('saveCache');
        },
        playerFinished: async (store, playerName) => {
            const player = store.state.players.find(
                (p) => p.name === playerName,
            );

            store.commit('updatePlayer', {
                ...player,
                lives: 0,
            });
            store.commit('stats', {
                ...store.state.stats,
                playedTime: moment().diff(store.state.stats.started, 'seconds'),
            });

            store.dispatch('saveCache');
        },
        endTheGame: async (store) => {
            store.commit('state', MATH_RUNNER_GAME_STATES.GAME_ENDED);
            store.commit('setClientType', CONSTANTS.CLIENT_TYPES.PLAYER, {
                root: true,
            });

            try {
                const skill = jclone(store.state.math._skill);
                const savedTopic = sessionStorage.getItem('topicSaved');
                const topic = savedTopic?.name || skill.name;

                const requestData = {
                    skill: {
                        name: skill.name,
                        topic,
                        type: skill.type,
                        numberGenerator: skill.numberGenerator,
                    },
                    // only accuracy stats are used by BE for home games
                    // so there is no need to send more data then used
                    stats: {
                        correctAnswers: store.state.stats.correctAnswers,
                        wrongAnswers: store.state.stats.wrongAnswers,
                        playedTime: store.state.stats.playedTime,
                    },
                    gameMode: CONSTANTS.MATH_RUNNER_GAME_MODE,
                };

                const response = await Api().post(
                    'home-game-v10SimpleTreeSocial/endgame',
                    requestData,
                );

                if (response.data?.success && response?.data?.data) {
                    store.dispatch('v2/user/update', response.data?.data, {
                        root: true,
                    });
                }

                void store.dispatch('v2/homegame/loadGamesHistory', null, {
                    root: true,
                });
            } catch (e) {
                console.error(`Failed to save accuracy: ${e}`);
            }

            try {
                new TrackingService().track(EVENTS.FINISHED_RUNNER_GAME, {
                    gameCode: store.getters.gameCode,
                    isSolo: store.getters.isSoloGame,
                    xp: store.getters.teamXp,
                });
            } catch (error) {
                console.log('Error in start game runner MP event: ', error);
            }

            if (inProductionEnv()) {
                void CrashReportsApi().post(
                    `crash-reports/increase-daily-counter/runnerGame/finishedGames`,
                );
            }

            store.dispatch('stopLocalTimer');
            clearTimeout(store.getters.magicTimeStartTimeout);
            store.commit('magicTimeStartTimeout', null);

            clearInterval(store.getters.magicInterval);
            store.commit('setMagicInterval', null);

            store.commit('switchGameStarted', false);
            store.dispatch('saveCache');
        },
        spawnBot: async (store) => {
            console.log('store::MathRunner::spawnBot');

            const avatars = avatarsShuffled();

            const randomIndex = randomIntFromRange(0, avatars.length - 1);

            const firstName =
                ANIMAL_NAMES[Math.floor(Math.random() * ANIMAL_NAMES.length)];

            const lastName =
                ANIMAL_NAMES[Math.floor(Math.random() * ANIMAL_NAMES.length)];

            const username = createUsername(firstName, lastName);

            const bot = new MathRunnerBot(username, avatars[randomIndex]);

            await bot.join(`${store.state.gameCode}`);

            store.commit('spawnBot', bot);

            store.dispatch('saveCache');
        },
        despawnBot: async (store) => {
            console.log('store::MathRunner::despawnBot');

            const keys = Object.keys(store.state.bots);

            const lastBot = store.state.bots[keys[keys.length - 1]];

            console.log(
                `store::MathRunner::despawnBot with id: ${lastBot._name}`,
            );

            store.commit('despawnBot', lastBot._name);

            store.dispatch('saveCache');
        },
        connectHostBot: async (store, bot) => {
            const botObject = new MathRunnerBot(bot.name, bot.avatar);

            await botObject.resumeBot(store.state.gameCode);

            store.state.bots[botObject._name] = botObject;
        },
        removeAllBots: async (store) => {
            console.log('sotre::MathRunner::removeAllBots');

            Object.keys(store.state.bots).forEach((botId) => {
                store.commit('despawnBot', botId);
            });
        },
        leaveGame: async (store) => {
            console.debug('store::MathRunner::leaveGame');

            store.dispatch('mathRunnerIo/emit', {
                event: 'leaveGame',
                data: {
                    gameCode: store.getters.gameCode,
                    userId: store.getters.myStats?.userId,
                },
            });
        },
        playerLeftGame: async (store, userId) => {
            console.log('store::MathRunner::playerLeftGame', userId);

            store.commit('removePlayer', userId);

            store.dispatch('saveCache');
        },
        hostLeftGame: async (store) => {
            store.commit('state', MATH_RUNNER_GAME_STATES.CANCELLED);
        },
        setTimeCorrection: (store, serverTime) => {
            const currentTime = moment().utc();
            const serverDiffTime = moment(serverTime).diff(currentTime);

            store.commit('setTimeCorrection', serverDiffTime);
        },
        async consumeEnergy(store) {
            const response = await Api().post(
                'home-game-v10SimpleTreeSocial/arena-energy/remove',
            );

            const { success, data } = response.data;

            if (success) {
                store.dispatch(
                    'v2/user/update',
                    { studentInfo: data },
                    { root: true },
                );
            }
        },

        async refillEnergy(store) {
            const response = await Api().post(
                'home-game-v10SimpleTreeSocial/arena-energy/refill',
            );

            const { success, data } = response.data;

            if (success) {
                store.dispatch(
                    'v2/user/update',
                    { studentInfo: data },
                    { root: true },
                );
            }
        },
        fetchOnlinePlayers: async (store, { limit = 25 } = {}) => {
            try {
                const result = await Api().get(
                    `/math-runner/online?limit=${limit}`,
                );

                const { success, data, error } = result.data;

                if (success && data) {
                    return data;
                } else {
                    console.error('[fetchOnlinePlayers] server error:' + error);
                    return [];
                }
            } catch (err) {
                console.error('[fetchOnlinePlayers] request error:' + err);
            }
        },
        addInvite: (store, invite) => {
            if (invite.receiverId === store.rootGetters['v2/user/uid']) {
                store.commit('setInvitedToGames', [
                    invite,
                    ...store.state.invitedToGames,
                ]);
            } else {
                store.commit('setSentInvitesToGame', [
                    invite,
                    ...store.state.sentInvitesToGame,
                ]);
            }

            store.dispatch('saveCache', true);
        },
        updateInvites: (store, data) => {
            const invite = store.state.invitedToGames.find(
                (el) => el.gameCode === data.gameCode,
            );

            if (!invite) return;

            invite.started = data.started;

            store.commit('setInvitedToGames', store.state.invitedToGames);

            store.dispatch('saveCache', true);
        },
        updateSentInvites: (store, data) => {
            const invite = store.state.sentInvitesToGame.find(
                (el) => el.receiverId === data.invitedPlayerId,
            );

            if (!invite) return;

            invite.declined = data.declined;

            store.commit('setSentInvitesToGame', store.state.sentInvitesToGame);

            store.dispatch('saveCache', true);
        },
        delInvite: async (store, data) => {
            store.commit(
                'setInvitedToGames',
                store.state.invitedToGames.reduce(
                    (acc, curr) =>
                        curr.gameCode === data.gameCode ? acc : [curr, ...acc],
                    [],
                ),
            );

            store.dispatch('saveCache', true);
        },
    },
};

export default mathRunnerStore;
