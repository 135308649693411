export const getClaimableItemDetails = ({ id, name, type } = {}) => {
    if (!id) return {};
    let url;

    switch (type) {
        case 'avatar':
            url = `/images/avatars/home-game/${name}.png`;
            break;
        case 'monster':
            url = `/images/cards/${id}.svg`;
            break;
        case 'background':
            url = `/images/avatars/home-game/backgrounds/${id}.svg`;
            break;
        case 'frame':
            url = `/images/avatars/home-game/frames/${id}.svg`;
            break;
        case 'emoji-pack':
            url = '/images/battle-pass/emoji.svg';
            break;
        case 'mbucks':
            url = '/images/currency/mbucks.svg';
            break;
        case 'chest':
            url = '/images/zoo/mystery-avatar.svg';
            break;
        case 'card':
            url = '/images/zoo/mystery-pet.svg';
            break;
        case 'decoration':
            url = '/images/zoo/mystery-item.svg';
            break;
        default:
            url = '/images/home-game/chest-new.svg';
            break;
    }

    return {
        url,
        name,
    };
};
