import axios from 'axios';
import { getFormattedLocalTime, getLocalTimezone } from '@/core/helpers/utils';

export default () => {
    return axios.create({
        baseURL: import.meta.env.VITE_ADMIN_API_URL,
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Local-Time': getFormattedLocalTime(),
            'X-Timezone': getLocalTimezone(),
        },
    });
};
