export default {
    getPlayers({ gameState }) {
        return gameState.players;
    },
    getNumberOfPlayers({ gameState }) {
        return gameState.numberOfPlayers;
    },
    getJoinGameMetaData({ gameState }) {
        return gameState.joinGameMetaData;
    },
    getRequiresLogin({ gameState }) {
        return gameState.requiresLogin;
    },
    getPlayersCount(state, getters) {
        return getters.getPlayers.length;
    },
    hasPlayers(state, getters) {
        return getters.getPlayersCount > 0;
    },
    getGameStatistics({ gameState }) {
        return gameState.gameStatistics;
    },
    getSelfPacedPlaylist({ gameState }) {
        return gameState.selfPacedPlaylist;
    },
    getGameTypes({ createGame: { gameTypes } }) {
        return gameTypes;
    },
    getTopicPickerTree({ createGame: { topicPickerTree } }) {
        return topicPickerTree;
    },
    getGameStartedDate({ gameState }) {
        return gameState.started;
    },
    getSelectedGameType({ createGame: { selectedGameType } }) {
        return selectedGameType;
    },
    getSelectedNumberGenerator({ createGame: { selectedNumberGenerator } }) {
        return selectedNumberGenerator;
    },
    getSelectedGameOptions({ createGame }) {
        return createGame.options;
    },
    getPlayedAgainStatus({ createGame }) {
        return createGame.playedAgain;
    },
    getNewGameRoundInfo({ createGame: { round } }) {
        return round;
    },
    getExamples({ createGame }) {
        return createGame.exampleQuestions;
    },
    getLeaderboard(state) {
        const sortPoints = (a, b) => {
            if (a.score > b.score) {
                return -1;
            }

            if (a.score < b.score) {
                return 1;
            }

            return 0;
        };

        return state.leaderboard.sort(sortPoints);
    },
    getMaxScore(state) {
        return state.maxScore;
    },
    /**/
    getReports(state) {
        return state.reports;
    },
    getReportsFromSkills(state) {
        return state.reportsFromSkills;
    },
    getReportCategories(state) {
        return { ...state.reportCategories };
    },
    /**/
    getLastActive(state) {
        return state.lastActive;
    },
    /**/
    getSelfPacedGamesPlayed({ user }) {
        return user?.selfPacedGamesPlayed;
    },
    getLiveGamesPlayed({ user }) {
        return user?.liveGamesPlayed;
    },
    getSelfPacedGamesMin4PlayersPlayed({ user }) {
        return user?.selfPacedGamesMin4PlayersPlayed;
    },
    getLiveGamesMin4PlayersPlayed({ user }) {
        return user?.liveGamesMin4PlayersPlayed;
    },
    getShowPlayerNames({ user }) {
        return user?.liveLeaderboardSeeStudents;
    },
    getGamesPlayedTotalCount(state, getters) {
        return getters.getLiveGamesPlayed + getters.getSelfPacedGamesPlayed;
    },
    getGamesPlayedWithStudents(state, getters) {
        return (
            getters.getLiveGamesMin4PlayersPlayed +
            getters.getSelfPacedGamesMin4PlayersPlayed
        );
    },
    getCurriculumSet({ createGame: { curriculumSet } }) {
        return curriculumSet;
    },
    getSelectedGamePresetName({ createGame: { selectedGamePresetName } }) {
        return selectedGamePresetName;
    },
    getGameOriginInUI({ createGame: { gameOriginInUI } }) {
        return gameOriginInUI;
    },
    getGameOriginInUIDetail({ createGame: { gameOriginInUIDetail } }) {
        return gameOriginInUIDetail;
    },
    getIsOnboardingDemo(_store) {
        return _store.isOnboardingDemo;
    },
    getLiveGameOptions({ createGame: { liveGameOptions } }) {
        return liveGameOptions;
    },
    getActiveClass({ user }) {
        return user?.activeClass;
    },
    getCountry({ country }) {
        return country || localStorage.getItem('country_code3');
    },
};
